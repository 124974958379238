import Supplyheader from "./Supplyheader";
import "./Supplypage.css";
import { useState, useEffect } from "react";
import Paginations from "../Paginations/Paginations";
import Supplierpaginations from "../Paginations/Supplierpagination";
import Table from "react-bootstrap/Table";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import { MultiSelect } from "react-multi-select-component";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../../assets/LoginIcons/edit.svg";
import { FiSettings, FiEye, FiUserPlus, FiFilter } from "react-icons/fi";
import AddManualSupplier from "./Popup/AddManualSupplier";
import ProjectDetails from "./Popup/Editsupplyquality";
import RequestStatus from "./Popup/RequestStatus";
import SupplierInfo from "./Popup/SupplierInfo";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SpinnerLoader from "../UI/Spinner";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiReset } from "react-icons/bi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableSortLabel } from "@mui/material";
import { TableCell, TableRow, TableBody } from "@mui/material";
import moment from "moment";
const Surveydetail = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [dataLength, setDataLength] = useState();
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [see, setSee] = useState(false);
  const [setting, setSetting] = useState(false);

  const [selecteduser, setSelectedUser] = useState([]);
  const [selecteduser1, setSelectedUser1] = useState([]);
  const [reverSign, setReverSign] = useState(0);
  const [allsurveydata, setAllsurveydata] = useState([]);
  const [surveyId, setSurveyId] = useState();
  const [surveyIdm, setSurveyIdm] = useState();
  const [userStatus, setUserStatus] = useState([]);
  const [sc, setSC] = useState("");
  const [quota, setQUOTA] = useState("");
  const [userId, setUserId] = useState();
  const [userDept, setUserDept] = useState();
  const [loder, setLoder] = useState(false);
  const [refreshapi, setRefreshApi] = useState(false);
  const [sid, setSID] = useState();
  const [requestStatusId, setRequestStatusId] = useState();
  const [filter_status, setFilter_status] = useState(false);
  const [supplier_type, setSupplier_type] = useState([]);
  const pname = "Supply";
  const headers = [
    {
      id: "supply_request_owner.name",
      label: "Req Owner",
    },
    {
      id: "supply_request_status.name",
      label: "Req Status",
    },
    {
      id: "project_name",
      label: "Project Name",
    },
    {
      id: "sc_id",
      label: "SC #",
    },
    {
      id: "Survey Name",
      label: "Survey Name",
    },
    {
      id: "client.name",
      label: "Account Name",
    },
    {
      id: "PM",
      label: "PM",
    },

    {
      id: "Survey Status",
      label: "Survey Sta.",
    },
    {
      id: "n",
      label: "Quota",
    },
    {
      id: "Manual Supp",
      label: "Manual Supp",
    },
    {
      id: "Date Opened",
      label: "Date Opened",
    },
    {
      id: "Action",
      label: "Action",
    },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  useEffect(() => {
    if (filter_status === false) {
      getFilter("_id");
    } else {
      getFilter_form();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);
  useEffect(() => {
    if (refreshapi === true) {
      getFilterrefresh();
    }
  }, [refreshapi]);
  const getFilterrefresh = async () => {
    setLoder(false);
    setSelectedUser([]);
    setSelectedUser1([]);
    setValue("SC", "");
    setValue("Quota", "");
    setSC("");
    setQUOTA("");

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });

    try {
      const response = await axiosPrivate.get(
        `/supplyinfo/survey-supplier-list?page=${nextPage}&size=${pagesize}&sort=-_id`,
        {}
      );
      setAllsurveydata(response.data.items);
      setFilter_status(false);
      setDataLength(response.data.total);
      setRefreshApi(false);
      reset();
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  useEffect(() => {
    if (setting == false) {
      getFilter("_id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting]);

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  useEffect(() => {
    getFilter();
    requestStatus();
    get_Supplier_type();
    getId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestStatus = async () => {
    try {
      const response = await axiosPrivate.get(
        `/lookup/supplier-request-status`,
        {}
      );
      const userdata = response.data.all_status.map((e, index) => ({
        value: e.id,
        label: e.name,
      }));

      setUserStatus(userdata);
    } catch (err) {
      console.error(err);
    }
  };
  const getId = async () => {
    try {
      const response = await axiosPrivate.get(`/user/user-department/9`, {});

      const userdata = response.data.all_users.map((e, index) => ({
        value: e._id,
        label: e.name,
      }));

      setUserDept(userdata);
    } catch (err) {
      console.error(err);
    }
  };

  const getFilter = async () => {
    setLoder(false);

    // e.preventDefault();

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });

    try {
      const response = await axiosPrivate.get(
        `/supplyinfo/survey-supplier-list?page=${nextPage}&size=${pagesize}&sort=-_id&query_filter=req_status%3D${reqstatus}%26req_owner%3D${reqowner}%26n%3D${quota}%26sc%3D${sc}`,
        {}
      );
      setFilter_status(false);
      setAllsurveydata(response.data.items);
      setDataLength(response.data.total);
      setRefreshApi(false);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  const getFilter_form = async (e) => {
    setLoder(false);
    setIsSubmitting(true);

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });
    let quota_value;
    if (e?.Quota && e?.Quota !== undefined) {
      quota_value = e?.Quota;
      setQUOTA(e?.Quota);
    } else if (e?.Quota === "") {
      quota_value = e?.Quota;
      setQUOTA(e?.Quota);
    } else {
      quota_value = quota;
    }
    let SC_value;
    if (e?.SC && e?.SC !== undefined) {
      SC_value = e?.SC;
      setSC(e?.SC);
    } else if (e?.SC === "") {
      SC_value = e?.SC;
      setSC(e?.SC);
    } else {
      SC_value = sc;
    }

    if (
      reqstatus.length !== 0 ||
      reqowner.length !== 0 ||
      SC_value !== "" ||
      quota_value !== ""
    ) {
      try {
        const response = await axiosPrivate.get(
          `/supplyinfo/survey-supplier-list?page=${nextPage}&size=${pagesize}&sort=-_id&query_filter=req_status%3D${reqstatus}%26req_owner%3D${reqowner}%26n%3D${quota_value}%26sc%3D${SC_value}`,
          {}
        );
        setAllsurveydata(response.data.items);
        setDataLength(response.data.total);
        setRefreshApi(false);
        setNextPage(nextPage);
        setPagesize(pagesize);
      } catch (err) {
        console.error(err);
      }
    }
    setLoder(true);
    setIsSubmitting(false);
  };

  const get_Supplier_type = async () => {
    try {
      const response = await axiosPrivate.get("lookup/supplier-type");
      setSupplier_type(response.data.all_status);
    } catch (err) {
      console.error(err);
    }
  };
  const handleSort = async (sorttype) => {
    setReverSign(reverSign + 1);
    if (reverSign === 0) {
      try {
        const response = await axiosPrivate.get(
          `/supplyinfo/survey-supplier-list?page=${nextPage}&size=${pagesize}&sort=${sorttype}`,
          {}
        );
        setAllsurveydata(response.data.items);

        setDataLength(response.data.total);

        setReverSign(1);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const response = await axiosPrivate.get(
          `/supplyinfo/survey-supplier-list?page=${nextPage}&size=${pagesize}&sort=-${sorttype}`,
          {}
        );
        setAllsurveydata(response.data.items);

        setDataLength(response.data.total);
        setReverSign(0);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      {setting ? (
        <>
          <RequestStatus
            show={setting}
            onHide={() => setSetting(false)}
            setSetting={setSetting}
            userId={userId}
            setRefreshApi={setRefreshApi}
          />
        </>
      ) : (
        <></>
      )}
      {add ? (
        <>
          <AddManualSupplier
            show={add}
            onHide={() => setAdd(false)}
            surveyId={surveyId}
            getFilterrefresh={getFilterrefresh}
            setAdd={setAdd}
            requestStatusId={requestStatusId}
            setRefreshApi={setRefreshApi}
          />
        </>
      ) : (
        <></>
      )}

      {update ? (
        <>
          <ProjectDetails
            show={update}
            onHide={() => setUpdate(false)}
            sid={sid}
          />
        </>
      ) : (
        <></>
      )}

      {see ? (
        <>
          <SupplierInfo
            show={see}
            onHide={() => setSee(false)}
            setSee={setSee}
            surveyIdm={surveyIdm}
          />
        </>
      ) : (
        <></>
      )}

      <Supplyheader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Survey
                Details
              </h3>
            </div>

            <FiFilter
              className="supplyIconColr"
              title="Filter"
              style={{
                float: "right",
                margin: "5px 0px",
                marginRight: "-5px",
                fontSize: "30px",
                padding: "2px 5px",
                color: "white",
                backgroundColor: "#1a1a1a",
                border: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => {
                setShow(!show);
                setFilter_status(false);
              }}
            />
          </div>

          <hr />
          {show ? (
            <>
              <form onSubmit={handleSubmit(getFilter_form)}>
                <Row
                  className="supply_filterbox"
                  style={{
                    backgroundColor: "#f1efef",
                    // margin: "15px",
                    paddingBottom: "10px",
                  }}
                >
                  <Col lg={10} md={9} xs={11} className="supply_col">
                    <Row>
                      <Col lg={3} md={3} xs={6} className="col ">
                        <label
                          for="status"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Req Status
                        </label>
                        <MultiSelect
                          className="multiselectoption"
                          style={{ width: "45px" }}
                          options={userStatus}
                          value={selecteduser}
                          onChange={(e) => {
                            setSelectedUser(e);
                          }}
                          labelledBy={"Req Status"}
                          placeholder={"Req Status"}
                          isCreatable={true}
                          disableSearch
                        />
                      </Col>
                      <Col lg={3} md={3} xs={6} className="col">
                        <label
                          for="status"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Req Owner
                        </label>
                        <MultiSelect
                          className="multiselectoption"
                          style={{ width: "35px" }}
                          options={userDept}
                          value={selecteduser1}
                          onChange={(e) => {
                            setSelectedUser1(e);
                          }}
                          labelledBy={"Req Owner"}
                          isCreatable={true}
                          disableSearch
                        />
                      </Col>
                      <Col lg={3} md={3} xs={6} className="col">
                        <label
                          for="status"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          SC#
                        </label>
                        <input
                          className="formtext"
                          placeholder="SC"
                          {...register("SC", {
                            pattern: {
                              value: /^\s*[0-9]+\s*$/,
                              message: "Only Integer are allowed",
                            },
                          })}
                        />
                        {errors.SC && (
                          <small className="survey_det_error validationErrorMsg">
                            {errors.SC.message}
                          </small>
                        )}
                      </Col>
                      <Col lg={3} md={3} xs={6} className="col">
                        <label
                          for="status"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          Quota(result above entered value)
                        </label>
                        <input
                          className="formtext"
                          placeholder="Quota"
                          {...register("Quota", {
                            pattern: {
                              value: /^\s*[0-9]+\s*$/,
                              message: "Only Integer are allowed",
                            },
                          })}
                        />
                        {errors.Quota && (
                          <small className="survey_det_error validationErrorMsg">
                            {errors.Quota.message}
                          </small>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={2}
                    md={3}
                    xs={12}
                    className="col"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      className="cbtn saveBtn"
                      style={{
                        maxWidth: "100%",
                        marginTop: "20px",
                      }}
                      onClick={(e) => {
                        setFilter_status(true);
                        setNextPage(1);
                        setPagesize(10);
                        e.stopPropagation();
                      }}
                    >
                      {isSubmitting && (
                        <Spinner
                          style={{ color: "white" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      &nbsp;&nbsp; Submit
                    </button>
                    <BiReset
                      title="Reset"
                      size={30}
                      style={{
                        marginTop: "20px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        setShow(!show);
                        getFilterrefresh();
                      }}
                    />{" "}
                  </Col>
                </Row>
              </form>
            </>
          ) : null}
          <div className="mainContent mainContent2">
            {loder ? (
              <>
                <div className="table_container ">
                  <Table
                    bordered={false}
                    hover
                    size="sm"
                    className="customTable"
                  >
                    <thead className="thead">
                      <TableRow>
                        {headers.map((header, index) => {
                          return (
                            <TableCell key={header.id}>
                              {reverSign === 0 ? (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropUpIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropDownIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </thead>
                    <tbody>
                      {allsurveydata.length === 0 ? (
                        <tr>No data Found</tr>
                      ) : (
                        allsurveydata.map((e, eindex) => (
                          <tr key={eindex}>
                            {e.supply_request_owner === undefined ||
                            e.supply_request_owner === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{e.supply_request_owner.name}</td>
                              </>
                            )}

                            {e.supply_request_status === undefined ||
                            e.supply_request_status === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <span className="stanBadge">
                                    {e.supply_request_status.name}
                                  </span>
                                </td>
                              </>
                            )}

                            {e.project_name === undefined ||
                            e.project_name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td title={e.project_name}>{e.project_name}</td>
                              </>
                            )}

                            {e.sc_id === undefined || e.sc_id === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{e.sc_id}</td>
                              </>
                            )}

                            {e.survey_name === undefined ||
                            e.survey_name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td title={e.survey_name}>{e.survey_name}</td>
                              </>
                            )}

                            {e.client.name === undefined ||
                            e.client.name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{e.client.name.substr(0, 10)}</td>
                              </>
                            )}

                            <td>
                              {e.project_manager.primary_project_manager.name}
                            </td>
                            {e.survey_status === undefined ||
                            e.survey_status === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {e.survey_status.name === "Live" ? (
                                    <>
                                      <span className="live">Live</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Pending" ? (
                                    <>
                                      <span className="pending">Pending</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Test Mode" ? (
                                    <>
                                      <span className="testmode">
                                        Test Mode
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Paused" ? (
                                    <>
                                      <span className="Paused">Paused</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Id Awaited" ? (
                                    <>
                                      <span className="IdAwaited">
                                        Id Awaited
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Billed" ? (
                                    <>
                                      <span className="billed">Billed</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {e.survey_status.name === "Cancelled" ? (
                                    <>
                                      <span className="cancelled">
                                        Cancelled
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </>
                            )}

                            {e.n === undefined || e.n === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{e.n}</td>
                              </>
                            )}

                            <td>
                              {e.manual_supp !== 0 ? (
                                <>{e.manual_supp}</>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td>
                              {e.survey_track_time === null ? (
                                <>-</>
                              ) : (
                                moment(e.survey_track_time).format("D/MM/YYYY")
                              )}
                            </td>
                            <td>
                              <FiUserPlus
                                title="Add"
                                className="SupIcon sdIcon"
                                onClick={() => {
                                  setAdd(true);
                                  setSurveyId(e._id);
                                  if (e.supply_request_owner === null) {
                                    setRequestStatusId(undefined);
                                  } else {
                                    setRequestStatusId(
                                      e.supply_request_owner.name
                                    );
                                  }
                                }}
                              />
                              <img
                                title="Project des."
                                src={EditIcon}
                                alt="EditIcon"
                                className="SupIcon sdIcon"
                                onClick={() => {
                                  setUpdate(true);
                                  setSID(e._id);
                                }}
                              />
                              <FiEye
                                title="Supplier Info."
                                className="SupIcon sdIcon"
                                onClick={() => {
                                  setSee(true);
                                  setSurveyIdm(e._id);
                                }}
                              />
                              <FiSettings
                                title="Request S/O"
                                className="SupIcon sdIcon"
                                onClick={() => {
                                  setSetting(true);
                                  setUserId(e._id);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <SpinnerLoader />
            )}
            <Supplierpaginations
              userlist={dataLength}
              getPageSize={getPageSize}
              handlePageChnage={handlePageChnage}
              page={nextPage}
              pagesize={pagesize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Surveydetail;
