import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import "./ExclusionSetting.css";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const ExclusionSetting = () => {
  const navigate = useNavigate();
  const pname = "Exclusion Setting";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [irStatus, setIrStatus] = useState(false);
  const [loiStatus, setLoiStatus] = useState(false);
  const [conversionStatus, setConversionStatus] = useState(false);
  const [cpiStatus, setCpiStatus] = useState(false);
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    formStatus: "",
    Irstatus: "",
    min_ir: "",
    min_hits_ir: "",
    min_hits_conversion: "",
    min_hits_loi: "",
    repeated_ir_hits: "",
    Loistatus: "",
    max_loi: "",
    repeated_loi_hits: "",
    Cpistatus: "",
    min_cpi: "",

    Conversionstatus: "",
    repeated_conversion_hits: "",
    conversion: "",
  });
  const notifysucces = () => toast.success("Updated successfully");
  useEffect(() => {
    get_ProjectType_api();
  }, []);

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);

      // setData(response.data.supplier);
      if (
        response.data?.supplier?.exclusion_settings === null ||
        response.data?.supplier?.exclusion_settings === undefined
      ) {
        setShowForm(false);
      } else {
        setFormData((prevData) => ({
          ...prevData,

          min_ir: response.data?.supplier?.exclusion_settings?.ir?.min_ir,
          repeated_ir_hits:
            response.data?.supplier?.exclusion_settings?.ir?.repeated_ir_hits,
          min_hits_ir:
            response.data?.supplier?.exclusion_settings?.ir?.min_hits,
          max_loi: response.data?.supplier?.exclusion_settings?.loi?.max_loi,
          repeated_loi_hits:
            response.data?.supplier?.exclusion_settings?.loi?.repeated_loi_hits,
          min_hits_loi:
            response.data?.supplier?.exclusion_settings?.loi?.min_hits,

          min_cpi: response.data?.supplier?.exclusion_settings?.cpi?.min_cpi,

          repeated_conversion_hits:
            response.data?.supplier?.exclusion_settings?.conversion
              ?.repeated_conversion_hits,
          conversion:
            response.data?.supplier?.exclusion_settings?.conversion?.conversion,
          min_hits_conversion:
            response.data?.supplier?.exclusion_settings?.conversion?.min_hits,
        }));
        if (response.data.supplier.exclusion_settings.status === 1) {
          setShowForm(true);
        } else {
          setShowForm(false);
        }
        if (response.data.supplier.exclusion_settings.ir.status === 1) {
          setIrStatus(true);
        } else {
          setIrStatus(false);
        }
        if (response.data.supplier.exclusion_settings.loi.status === 1) {
          setLoiStatus(true);
        } else {
          setLoiStatus(false);
        }
        if (response.data.supplier.exclusion_settings.cpi.status === 1) {
          setCpiStatus(true);
        } else {
          setCpiStatus(false);
        }
        if (response.data.supplier.exclusion_settings.conversion.status === 1) {
          setConversionStatus(true);
        } else {
          setConversionStatus(false);
        }
      }

      setLoder(true);
    } catch (err) {
      console.error(err);
    }
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        .post(
          `/supplyinfo/supplier-exclusion-settings/${id}`,
          JSON.stringify({
            ir: {
              status: irStatus,
              min_ir: parseInt(e.MinIr),

              repeated_ir_hits: parseInt(e.ReapeatedIrHits),
              min_hits: parseInt(e.Min_hits_Ir),
            },
            loi: {
              status: loiStatus,
              max_loi: parseInt(e.MaxLoi),
              repeated_loi_hits: parseInt(e.ReapeatedLoiHits),
              min_hits: parseInt(e.Min_hits_Loi),
            },
            cpi: {
              status: cpiStatus,
              min_cpi: parseInt(e.MinCpi),
            },
            conversion: {
              status: conversionStatus,
              repeated_conversion_hits: parseInt(e.RepeatedConversionHits),
              conversion: parseInt(e.Conversion),
              min_hits: parseInt(e.min_hits_conversion),
            },
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };

  return (
    <>
      <SettingHeader pname={pname} id={id} />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(postData)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img
                    src={userIco}
                    className="headingIcons"
                    alt="Add Department"
                  />
                  Exclusion Setting
                </h3>
              </div>
              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={(e) => {
                    navigate("/supplier-details");

                    e.preventDefault();
                  }}
                >
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && (
                    <Spinner
                      style={{ color: "white" }}
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp;&nbsp; Save
                </button>
              </div>
            </div>

            <hr />
            {loder ? (
              <>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <>
                    <div className="settingHeading">
                      <p className="pBlockHead">Conversion</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("min_hits_conversion", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_conversion}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.min_hits_conversion && (
                                  <small className="supplier_setting_error">
                                    {errors.min_hits_conversion.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Conversion*</label>
                            <input
                              {...register("Conversion", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Conversion"
                              className="CsformText supplier_setting"
                              defaultValue={formData.conversion}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.Conversion && (
                                  <small className="supplier_setting_error">
                                    {errors.Conversion.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Conversion Hits
                            </label>
                            <input
                              {...register("RepeatedConversionHits", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder=" Repeated Conversion Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_conversion_hits}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.RepeatedConversionHits && (
                                  <small className="supplier_setting_error">
                                    {errors.RepeatedConversionHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setConversionStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={conversionStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="settingHeading">
                      <p className="pBlockHead">IR</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("Min_hits_Ir", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_ir}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.Min_hits_Ir && (
                                  <small className="supplier_setting_error">
                                    {errors.Min_hits_Ir.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Ir*</label>
                            <input
                              {...register("MinIr", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Ir"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_ir}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.MinIr && (
                                  <small className="supplier_setting_error">
                                    {errors.MinIr.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Ir Hits
                            </label>
                            <input
                              {...register("ReapeatedIrHits", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Repeated Ir Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_ir_hits}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.ReapeatedIrHits && (
                                  <small className="supplier_setting_error">
                                    {errors.ReapeatedIrHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setIrStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={irStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="settingHeading">
                      <p className="pBlockHead">LOI</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("Min_hits_Loi", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_loi}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.Min_hits_Loi && (
                                  <small className="supplier_setting_error">
                                    {errors.Min_hits_Loi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Max Loi*</label>
                            <input
                              {...register("MaxLoi", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Max Loi"
                              className="CsformText supplier_setting"
                              defaultValue={formData.max_loi}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.MaxLoi && (
                                  <small className="supplier_setting_error">
                                    {errors.MaxLoi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Loi Hits
                            </label>
                            <input
                              {...register("ReapeatedLoiHits", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Repeated Loi Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_loi_hits}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.ReapeatedLoiHits && (
                                  <small className="supplier_setting_error">
                                    {errors.ReapeatedLoiHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setLoiStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={loiStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="settingHeading">
                      <p className="pBlockHead">Supplier cost</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Cost*</label>
                            <input
                              {...register("MinCpi", {
                                required: cpiStatus
                                  ? "This field is Required"
                                  : false,

                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Cost"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_cpi}
                            />
                            {cpiStatus === true ? (
                              <>
                                {errors.MinCpi && (
                                  <small className="supplier_setting_error">
                                    {errors.MinCpi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setCpiStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={cpiStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ExclusionSetting;
