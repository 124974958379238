import Modal from 'react-bootstrap/Modal';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import '../../CSModule/Tracker/Trackerpopup/SurveyTracking.css';
function EditSupplier(props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleSaveButton = (e) => {
        e.preventDefault();
    };

    const handleClose = () => {
        props.props.setProject(true);
        props.setEditSupplier(false);
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px', paddingLeft: '3px' }}
                >
                    Edit Supplier
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleSaveButton)}>
                    <div>
                        <Row>
                            <Col lg={3} md={12} sm={12} xs={12} className="col">
                                <label className="formlabelviewzone">
                                    Supplier Name
                                </label>
                                <input
                                    {...register('SupplierName', {
                                        required: 'This field is Required',
                                    })}
                                    placeholder="Supplier Name"
                                    className="formtextviewzone"
                                    type="text"
                                />
                                {errors.SupplierName && (
                                    <small className="errmsg ">
                                        {errors.SupplierName.message}
                                    </small>
                                )}
                            </Col>

                            <Col lg={2} md={4} sm={12} xs={12} className="col">
                                <label className="formlabelviewzone">IR</label>
                                <input
                                    {...register('IR', {
                                        required: 'This field is Required',
                                    })}
                                    placeholder="IR"
                                    className="formtextviewzone"
                                    type="text"
                                />
                                {errors.IR && (
                                    <small className="errmsg editsuperror">
                                        {errors.IR.message}
                                    </small>
                                )}
                            </Col>
                            <Col lg={2} md={4} sm={12} xs={12} className="col">
                                <label className="formlabelviewzone">LOI</label>
                                <input
                                    {...register('LOI', {
                                        required: 'This field is Required',
                                    })}
                                    placeholder="LOI"
                                    className="formtextviewzone"
                                    type="text"
                                />
                                {errors.LOI && (
                                    <small className="errmsg editsuperror">
                                        {errors.LOI.message}
                                    </small>
                                )}
                            </Col>
                            <Col lg={2} md={4} sm={12} xs={12} className="col">
                                <div className="rrr"></div>
                                <label className="formlabelviewzone">
                                    Quota
                                </label>
                                <input
                                    {...register('Quota', {
                                        required: 'This field is Required',
                                    })}
                                    placeholder="Quota"
                                    className="formtextviewzone"
                                    type="text"
                                />
                                {errors.Quota && (
                                    <small className="errmsg editsuperror">
                                        {errors.Quota.message}
                                    </small>
                                )}
                            </Col>

                            <Col lg={3} md={12} sm={12} xs={12} className="col">
                                <label
                                    className="formlabelviewzone projlabel"
                                    style={{
                                        marginBottom: '0px',
                                    }}
                                >
                                    Supplier Cost
                                </label>
                                <input
                                    {...register('SupplierCost', {
                                        required: 'This field is Required',
                                    })}
                                    placeholder="Supplier Cost"
                                    className="formtextviewzone"
                                    type="text"
                                />
                                {errors.SupplierCost && (
                                    <small className="errmsg ">
                                        {errors.SupplierCost.message}
                                    </small>
                                )}
                            </Col>
                        </Row>
                        <Row style={{}}>
                            <Col className=" " lg={3} md={4} sm={4} xs={12}>
                                <label className="formlabelviewzone">
                                    Variable 1
                                </label>
                                <input
                                    placeholder="RID"
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                            <Col className=" " lg={3} md={4} sm={4} xs={12}>
                                <label className="formlabelviewzone">
                                    Variable 2
                                </label>
                                <input
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                            <Col className=" " lg={3} md={4} sm={4} xs={12}>
                                <label className="formlabelviewzone">
                                    Variable 3
                                </label>
                                <input
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                            <Col className=" " lg={3} md={4} sm={4} xs={12}>
                                <label className="formlabelviewzone">
                                    Variable 4
                                </label>
                                <input
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                        </Row>
                        <Row>
                            {' '}
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Qualiﬁcation screen
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Screening
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Country Check
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Proxy Check
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Duplicate IP
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Soft Launch
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div style={{ height: '10px' }}></div>
                        <Row>
                            {' '}
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Screen Captcha
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Red Herring Screen
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={4} xs={6}>
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Speeder Terminate
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={2}
                                md={4}
                                sm={4}
                                xs={6}
                                className="suppliersetup"
                            >
                                <div className="rrr"></div>
                                <div className="pswtchbtn  q_edit_switcbtn">
                                    <label className="formlabelviewzone">
                                        Exception Link
                                    </label>
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="ON"
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div style={{ height: '10px' }}></div>
                        <Row>
                            {' '}
                            <Col
                                className="suppliersetup"
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                            >
                                <div className="rrr"></div>

                                <label className="formlabelviewzone">
                                    Completion Link
                                </label>
                                <input
                                    placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="suppliersetup"
                            >
                                <div className="rrr"></div>
                                <label className="formlabelviewzone">
                                    Termination Link
                                </label>
                                <input
                                    placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                            <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="suppliersetup"
                            >
                                <div className="rrr"></div>
                                <label className="formlabelviewzone">
                                    Quotafull Link
                                </label>
                                <input
                                    placeholder="https://samplicity.wiseworksresearch.com/demand-api/transaction?transaction_id=[%transaction_id%]&v_id=[%vendor_id%]&s_id=[%survey_id%]&token=[%sc_hash%]&status=1"
                                    className="formtextviewzone"
                                    type="text"
                                />
                            </Col>
                        </Row>
                        <div style={{ height: '20px' }}></div>
                        <hr />

                        <Row style={{ margin: '0px' }}>
                            <Col>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {' '}
                                    <button
                                        className="quota_surtrakbtn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.props.setProject(true);
                                            props.setEditSupplier(false);
                                        }}
                                    >
                                        Survey tracking
                                    </button>
                                    <div style={{ width: '20px' }}></div>
                                    <button
                                        className="quota_savebtn"
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}
export default EditSupplier;
