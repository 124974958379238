import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

import { useForm } from "react-hook-form";
import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import axios from "axios";
import { axiosPrivate } from "../../../../../api/axios";

const Showzipdata = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [loder, setLoder] = useState(true);
  const [zipData, setZipdata] = useState([]);
  const [is_all_selected, setIs_All_Selected] = useState();
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    if (props.zipQyalid) {
      getZipData();
    }
  }, [props.zipQyalid]);
  const getZipData = async () => {
    setLoder(false);
    const url = `qualification/get-zipcode-qualification/${props.zipQyalid}`;

    await axiosPrivate
      .get(url)
      .then(async (response) => {
        setZipdata(response.data.qualification.ans_code);
        setIs_All_Selected(response.data.qualification.is_all_selected);
        setLogs(response.data.qualification.qualification_log);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
    setLoder(true);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs16">
            Uploaded Zip
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loder ? (
            <>
              {!is_all_selected ? (
                <>
                  <div className="pb-3">
                    <p className="fs16 m-0 pb-2">
                      <b>Zip Codes</b>
                    </p>

                    {zipData.join(", ")}
                  </div>
                </>
              ) : (
                <>
                  <p className="fs16 m-0 pb-2">
                    <b>All ZIP CODES ARE SELECTED</b>
                  </p>
                </>
              )}
              <Modal.Footer className="pb-0">
                <button
                  className="cbtn closeBtn pbtn "
                  onClick={(e) => {
                    // e.preventDefault();
                    props.setZipPopUp(false);
                  }}
                >
                  Close
                </button>
              </Modal.Footer>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Showzipdata;
