import Modal from "react-bootstrap/Modal";
import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import SpinnerLoader from "../../../UI/Spinner";

import { useState } from "react";
function SupplierLink(props) {
  const handleClose = () => {
    props.props.setSetting(true);
    props.setSupplierLink(false);
  };

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (props.linkData.completeLink != undefined) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [props]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Supplier Links (PN# Other study)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loader ? (
          <>
            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
                <label className="formlabelviewzone">Completion Link</label>
              </Col>
              <Col lg={10} md={12} sm={12} xs={12}>
                {" "}
                <h6 className="quta_link_text">
                  {props.linkData.completeLink}
                </h6>
              </Col>
            </Row>
            <div style={{ height: "10px" }}></div>
            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
                <label className="formlabelviewzone">Termination Link</label>
              </Col>
              <Col lg={10} md={12} sm={12} xs={12}>
                {" "}
                <h6 className="quta_link_text">
                  {props.linkData.terminationLink}
                </h6>
              </Col>
            </Row>
            <div style={{ height: "10px" }}></div>
            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
                <label className="formlabelviewzone">Quotafull Link</label>
              </Col>
              <Col lg={10} md={12} sm={12} xs={12}>
                <h6 className="quta_link_text">
                  {props.linkData.quotafullLink}
                </h6>
              </Col>
            </Row>
            <div style={{ height: "10px" }}></div>
            <Row>
              <Col lg={2} md={12} sm={12} xs={12}>
                <label className="formlabelviewzone">Security Link</label>
              </Col>
              <Col lg={10} md={12} sm={12} xs={12}>
                <h6 className="quta_link_text">
                  {props.linkData.securityLink}
                </h6>
              </Col>
            </Row>
            <hr />
            <Row style={{ margin: "0px" }}>
              <Col>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {" "}
                  <button
                    className="quota_surtrakbtn"
                    style={{
                      marginRight: "-15px",
                    }}
                    onClick={() => {
                      props.props.setSetting(true);
                      props.setSupplierLink(false);
                    }}
                  >
                    Survey tracking
                  </button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <SpinnerLoader />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
export default SupplierLink;
