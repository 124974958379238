import './BidsSubHeader.css';
import { useNavigate } from 'react-router';
import useAuth from '../../../hooks/useAuth';
const BidsSubHeader = (props) => {
    const { auth } = useAuth();

    const superUser = auth.superuser;
    const all_permission = auth.permission;
    const navigate = useNavigate();
    const cptype = props.pname;

    return (
        <>
            <div className="Mail_to_account">
                {superUser === true ? (
                    <>
                        <span
                            className={cptype === 'mailbox' ? 'active' : ''}
                            onClick={() => {
                                navigate('/mailbox');
                            }}
                        >
                            Mailbox
                        </span>
                    </>
                ) : (
                    <>
                        {all_permission.includes('mail_box_tab') ? (
                            <>
                                <span
                                    className={
                                        cptype === 'mailbox' ? 'active' : ''
                                    }
                                    onClick={() => {
                                        navigate('/mailbox');
                                    }}
                                >
                                    Mailbox
                                </span>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}

                <span
                    className={cptype === 'biddetails' ? 'active' : ''}
                    onClick={() => {
                        navigate('/bid-details');
                    }}
                >
                    Bid Details
                </span>

                {superUser === true ? (
                    <>
                        <span
                            className={cptype === 'clientlist' ? 'active' : ''}
                            onClick={() => {
                                navigate('/clients');
                            }}
                        >
                            Account Details
                        </span>
                    </>
                ) : (
                    <>
                        {all_permission.includes('account_detail_tab') ? (
                            <>
                                <span
                                    className={
                                        cptype === 'clientlist' ? 'active' : ''
                                    }
                                    onClick={() => {
                                        navigate('/clients');
                                    }}
                                >
                                    Account Details
                                </span>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default BidsSubHeader;
