import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../../../UI/Spinner";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import "./Quotanesting.css";
import { Prev } from "react-bootstrap/esm/PageItem";

const QuotaNesting = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [spinner, setSpinner] = useState(false);
  const [nestingValues, setNestingValues] = useState([]);
  const [loder, setLoder] = useState(true);
  const [question_data, setQuestion_data] = useState([]);
  const [answer_data, setAnswer_data] = useState([]);
  const [rawQuestionCode, setRawQuestionCode] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [renderdata_Header, setRenderdata_Header] = useState([]);
  const [allocatedId, setAllocatedId] = useState([]);
  const [nested_id, setNested_id] = useState([]);
  const [nestedQuotaCode, setNestedQuotaCode] = useState([]);
  const [dropdownloader, setDropDownLoader] = useState(true);
  const [data_render, setData_Render] = useState([]);
  const [childLoder, setChildLoder] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const {
    formState: { errors },
  } = useForm();

  useEffect(() => {
    get_quotaNesting();
  }, []);
  useEffect(() => {
    if (question_data.length !== 0) {
      setChildLoder(false);
      destructureDataToShow_second(data_render);
    } else if (dropdownloader === false) {
      setNoDataFound(true);
    } else {
      setChildLoder(true);
    }
  }, [answer_data, dropdownloader]);
  const get_quotaNesting = () => {
    setDropDownLoader(true);
    const url = `qualification/get-quota-nesting/${props.survey_ID}`;
    return axiosPrivate(url, {
      method: "GET",
    }).then((res) => {
      let data = res.data;

      const yourVariable = data.qualification.quota;
      setAllocatedId(data.qualification.allocated_q_id);
      setRawQuestionCode(data.qualification.quota);
      const convertedArray = Object.entries(yourVariable).map(
        ([id, ansArray]) => ({
          questionId: parseInt(id),
          ans: ansArray,
        })
      );
      get_questinList(
        data.qualification.allocated_q_id,
        convertedArray,
        data.qualification.nested_q_id
      );

      setData_Render(data.qualification.nested_quota);

      // destructureDataToShow_second(data.qualification.nested_quota);
    });
  };

  const get_questinList = (question_list, questionCode, select_id) => {
    const url = `qualification/get-question-list/${props.survey_ID}`;
    return axiosPrivate(url, {
      method: "GET",
    }).then((data) => {
      var questions = data.data;
      const filteredQuestions = questions.question
        .filter((question) =>
          question_list.includes(question.question_title.q_id)
        )
        .map(({ q_name, question_title }) => ({
          label: q_name,
          value: question_title.q_id,
        }));

      const selectedQuestions = questions.question
        .filter((question) => select_id.includes(question.question_title.q_id))
        .map(({ q_name, question_title }) => ({
          label: q_name,
          value: question_title.q_id,
        }));
      setQuestion_data(filteredQuestions);
      setNestingValues(selectedQuestions);
      // /api/v1/qualification/get-quota-nesting/{survey_id}
      setDropDownLoader(false);

      filteredQuestions.map((e, index) => {
        get_answerList(e.value, questionCode[index]);
      });
    });
  };
  const get_answerList = (e, questionCode) => {
    const url = `qualification/get-answer-list/${props.survey_ID}/${e}`;
    return axiosPrivate(url, {
      method: "GET",
    }).then((data) => {
      var allAnswer = data.data.answer;
      const ansIds = questionCode.ans.map((item) => item.ans_code);
      // Filter allAnswer based on matching ans_id values
      const filteredAnswer = allAnswer.filter((item) =>
        ansIds.includes(item.ans_code)
      );
      setAnswer_data((prevQuestionData) => [
        ...prevQuestionData,
        ...filteredAnswer,
      ]);
      var data1 = questionCode;
      var data2 = filteredAnswer;

      const { ans, questionId } = data1;

      // Map and create a new array with the desired format

      ans.map(({ ans_code, perc }) => {
        const matchingData2 = data2.find((item) => item.ans_code === ans_code);
        return {
          questionId,
          ans: {
            title: matchingData2.answer_title.title,
            ans_code,
            perc,
          },
        };
      });
    });
  };

  const selectAppendDataListing = (prop) => {
    if (prop.length != 0) {
      setChildLoder(false);
      const valuesArray = prop.map((item) => item.value);
      setNested_id(valuesArray);
      const filteredQuestionCode = {};
      prop.forEach((property) => {
        const propValue = property.value;
        if (rawQuestionCode.hasOwnProperty(propValue)) {
          filteredQuestionCode[propValue] = rawQuestionCode[propValue];
        }
      });
      getCombinationApi(filteredQuestionCode);
    } else {
      setRenderData([]);
      setRenderdata_Header([]);
    }
  };

  const getCombinationApi = async (payloadManual) => {
    const payload = {
      n: 100,
      quota: payloadManual,
    };
    try {
      const url = "qualification/get-combination";
      const response = await axiosPrivate(url, {
        method: "PATCH",
        data: JSON.stringify(payload), // Use 'data' instead of 'body'
      });
      const data = await response.data; // Use response.data instead of response.json()
      setNestedQuotaCode(data.qualification);
      destructureDataToShow(data.qualification);
    } catch (error) {
      throw error;
    }
  };

  const destructureDataToShow = (data) => {
    const questionDataMapping = {};
    question_data.forEach(({ value, label }) => {
      questionDataMapping[value.toString()] = label;
    });
    const destructuredData = data.map(({ allData, n, percentage }) => {
      setRenderdata_Header([]);
      const updatedAllData = allData.map(({ q_id, ans_code }) => ({
        q_name: questionDataMapping[q_id.toString()] || q_id,
        q_id,
        ans_code,
      }));

      setRenderdata_Header(updatedAllData);

      return { allData: updatedAllData, n, percentage };
    });

    destructuredData.forEach((data) => {
      data.allData.forEach((item) => {
        const correspondingAnswer = answer_data.find(
          (answer) =>
            answer.ans_code === item.ans_code && answer.q_id == item.q_id
        );
        if (correspondingAnswer) {
          item.ans_title = correspondingAnswer.answer_title.title;
        }
      });
    });
    // Concatenate all updatedAllData arrays into a single array

    setRenderData(destructuredData);
    setChildLoder(true);
  };
  const destructureDataToShow_second = (data) => {
    setChildLoder(false);
    const questionDataMapping = {};
    question_data.forEach(({ value, label }) => {
      questionDataMapping[value.toString()] = label;
    });
    const destructuredData = data.map(({ allData, n, percentage }) => {
      setRenderdata_Header([]);
      const updatedAllData = allData.map(({ q_id, ans_code }) => ({
        q_name: questionDataMapping[q_id.toString()] || q_id,
        q_id,
        ans_code,
      }));

      setRenderdata_Header(updatedAllData);
      return { allData: updatedAllData, n, percentage };
    });

    destructuredData.forEach((data) => {
      data.allData.forEach((item) => {
        const correspondingAnswer = answer_data.find(
          (answer) =>
            answer.ans_code === item.ans_code && answer.q_id == item.q_id
        );
        if (correspondingAnswer) {
          item.ans_title = correspondingAnswer.answer_title.title;
        }
      });
    });

    setRenderData(destructuredData);
    setNoDataFound(false);
    setChildLoder(true);
  };
  const handleSubmitForm = async () => {
    var payload = {
      survey_id: props.survey_ID,
      allocated_q_id: allocatedId,
      nested_q_id: nested_id,
      nested_quota: nestedQuotaCode,
      status: true,
    };

    if (nested_id.length > 0) {
      setSpinner(true);
      try {
        const url = "qualification/add-quota-nesting";
        const response = await axiosPrivate(url, {
          method: "POST",
          data: JSON.stringify(payload), // Use 'data' instead of 'body'
          headers: {
            "Content-Type": "application/json", // Add Content-Type header
          },
        });
        if (response.status === 200) {
          props.showNestedquotapopup(false);
          toast.success("Nested Quota added successfully");
        }
        const data = await response.data; // Use response.data instead of response.json()
      } catch (error) {
        notifyerror1();
        throw error;
      }
      setSpinner(false);
    } else {
      setSpinner(false);
      notifyerror();
    }
  };

  const notifyerror1 = () => toast.error("Api Fail");
  const notifyerror = () => toast.error("Choose Question");
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs16">
            Quota Nesting
          </Modal.Title>
        </Modal.Header>
        {loder ? (
          <>
            <Modal.Body>
              <Row></Row>
              <Row>
                <Col
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="col csmulti option-height"
                >
                  <MultiSelect
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    value={nestingValues}
                    onChange={(e) => {
                      setNestingValues(e);
                      selectAppendDataListing(e);
                      setNoDataFound(false);
                    }}
                    options={question_data}
                    isMulti
                    isClearable
                    // components={{ ValueContainer }}
                    allowSelectAll={true}
                    isSearchable={false}
                    // onBlur={handleformSubmission}
                    noOptionsMessage={() => "No Data Found"}
                    isLoading={dropdownloader}
                  />
                </Col>
              </Row>

              {childLoder === false ? (
                <SpinnerLoader />
              ) : noDataFound === true && answer_data.length === 0 ? (
                <>
                  <div
                    class="alert alert-danger mt-3"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                    role="alert"
                  >
                    No Nesting Found!
                  </div>
                </>
              ) : (
                <>
                  <table
                    className="nesting-table mt-2"
                    cellPadding={2}
                    cellSpacing={2}
                  >
                    <thead className="thead">
                      <tr>
                        {renderdata_Header.map((e) => (
                          <>
                            <td>{e.q_name}</td>
                          </>
                        ))}

                        {nestingValues.length !== 0 ? (
                          <>
                            <td>N</td>
                            <td>%</td>
                          </>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {renderData.map((allStructure) => (
                        <>
                          <tr className="pt-3 px-1rem">
                            {allStructure.allData.map((e, index) =>
                              e.q_name == "AGE" ? (
                                <>
                                  <td>
                                    {e.ans_code.min_age}- {e.ans_code.max_age}
                                  </td>
                                </>
                              ) : (
                                <td> {e.ans_title}</td>
                              )
                            )}
                            <td>{allStructure.n.toFixed(3)}</td>
                            <td>{allStructure.percentage.toFixed(3)}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </Modal.Body>

            <Modal.Footer>
              <button
                className="cbtn saveBtn m-0"
                type="submit"
                onClick={() => {
                  handleSubmitForm();
                }}
              >
                {spinner && (
                  <Spinner
                    as="span"
                    style={{ color: "white" }}
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                &nbsp;Apply Nesting
              </button>
            </Modal.Footer>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Modal>
    </>
  );
};

export default QuotaNesting;
