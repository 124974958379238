import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import "./QuestionPopup.css";
import Table from "react-bootstrap/Table";
import { GrFormAdd } from "react-icons/gr";
import { FaRegDotCircle } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import EmailQuestion from "./QuestionModule/EmailQuestion";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import SpinnerLoader from "../../UI/Spinner";
import Screeninglogs from "./QuestionModule/Screeninglogs";
function QuestionPopup(props) {
  const [surveyId, setSurveyId] = useState();
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setSurveyId(props.selectedItem[0].value);
  }, [props.selectedItem]);
  const [screeningStatus, setScreeningStatus] = useState(false);
  const [showQuestion, setShowQuestions] = useState([]);
  const [questionShow, setQuestionShow] = useState(1);
  const [show, setShow] = useState(false);
  const [deleteModel, setDeleteModel] = useState();
  const [selected_Vendor, setSelected_Vendor] = useState([]);
  const [exclude_vendor_opt, setExcluded_Vendor_opt] = useState([]);
  const [activeQuestions, setActiveQuestions] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [question_error, setQuestion_Error] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [error_number, setError_Number] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm();
  const [questionStorage, setQuestionStorage] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [switchApi, SetSwitchApi] = useState(true);
  const [statefinalPayload, setstateFinalPayload] = useState([]);
  const [questionAPi, setQuestionAPi] = useState(false);
  const [loaderBody, setLoaderBody] = useState(true);
  const [allowToFetch, setAllowToFetch] = useState(false);
  const [checkedI, setCheckedI] = useState(true);
  const [finalPayload, setFinalPayload] = useState([]);

  const handleClose = () => {
    setShow(false);
    var data = document.getElementById("checkkkkk");
    data.classList.remove("blur");
  };

  useEffect(() => {
    unregister(`Question${deleteModel}`);
  }, [deleteModel, unregister]);

  const handleCloseWithYes = (front_question_id) => {
    setShow(false);
    var data = document.getElementById("checkkkkk");
    data.classList.remove("blur");

    setShowQuestions((prevQuestions) => {
      // Filter out the item to be deleted
      const updatedQuestions = prevQuestions.filter(
        (item) => item.front_question_id !== parseInt(front_question_id)
      );

      // Re-index the remaining items
      return updatedQuestions.map((item, index) => ({
        ...item,
        index: index, // Update the index of each item
      }));
    });

    setQuestionStorage((end) => {
      // Copy the array to avoid mutating state directly
      const updatedQuestions = [...end];
      // Find the index matched object
      const matchedIndex = updatedQuestions.findIndex(
        (item, index) =>
          parseInt(item.front_question_id) === parseInt(front_question_id)
      );
      // If found, update the visible_question property
      if (matchedIndex !== -1) {
        updatedQuestions[matchedIndex].status = 0;
      }
      setstateFinalPayload(updatedQuestions);
      return updatedQuestions;
    });

    setActiveQuestions((prevActiveQuestions) => {
      const filteredQuestions = prevActiveQuestions.filter(
        (id) => parseInt(id) !== parseInt(front_question_id)
      );

      // Return the filtered array
      setQuestionShow(filteredQuestions[0]);
      return filteredQuestions;
    });
  };
  const handleBlur = () => {
    var data = document.getElementById("checkkkkk");
    data.classList.add("blur");
  };

  const ShowvenderList = async () => {
    // survey/get-survey-supplier/65d8c58c04e379f7ebd66fb9
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-supplier/${props.selectedItem[0].value}`
      );
      if (response.data.survey.length !== 0) {
        const surveyData = response.data.survey.map((e, index) => ({
          value: e.supplier.id,
          label: e.supplier.name,
        }));

        setExcluded_Vendor_opt(surveyData);
      }

      setQuestionAPi(true);
    } catch (err) {}
  };

  const ShowQuestionNumber = (e) => {
    setQuestionShow(e);

    document
      .querySelectorAll(".questionShow")
      .forEach((el) => el.classList.remove("active"));
    document.getElementById(`question_Td${e}`).classList.add("active");
  };

  const handleShowQuestion = (e) => {
    const newInput = [
      {
        answer_id: 1,
        answer_logic: "1",
        status: 1,
        answer_title: "",
      },
      {
        answer_id: 2,
        answer_logic: "1",
        status: 1,
        answer_title: "",
      },
    ];
    const newQuestionId =
      questionStorage.length > 0
        ? Math.max(
            ...questionStorage.map((question) => question.front_question_id)
          ) + 1
        : 1;

    setShowQuestions((prevShowQuestions) => [
      ...prevShowQuestions,
      {
        question_id: null,
        front_question_id: parseInt(newQuestionId),
        value: e.target.value,

        question_type: e.target.value,
        question_title: "",

        mandatory: true,
        randomize: true,
        answer_options:
          e.target.value === "S" || e.target.value === "M" ? newInput : [],
        exclude_vendor: [],
        status: 1,
      },
    ]);

    setQuestionStorage((prevQuestionStorage) => [
      ...prevQuestionStorage,
      {
        question_id: null,
        front_question_id: parseInt(newQuestionId),
        question_type: e.target.value,
        question_title: "",
        value: e.target.value,

        mandatory: true,
        randomize: true,
        answer_options:
          e.target.value === "S" || e.target.value === "M" ? newInput : [],
        exclude_vendor: [],

        status: 1,
      },
    ]);

    setActiveQuestions((prevActiveQuestions) => [
      ...prevActiveQuestions,
      parseInt(newQuestionId),
    ]);
    setQuestionShow(parseInt(newQuestionId));
  };

  const notifyerror = (err) => toast.error("Error: " + err);
  const notifysucces = () => toast.success("Form  successfully submited");

  const handledeleteQuestion = (front_question_id, index) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      message: `Do you want to delete this question?`,
      messageStyle: {
        color: "#ff0000", // Set your desired color value
      },
      buttons: [
        {
          label: "YES",
          onClick: () => {
            handleCloseWithYes(front_question_id, index);
          },
        },
        {
          label: "NO",
        },
      ],
    });
  };

  useEffect(() => {
    ShowvenderList();
  }, [props.surveyidselect]);
  useEffect(() => {
    if (questionAPi === true) {
      get_Screening_Question();
    }
  }, [questionAPi]);

  const get_Screening_Question = async () => {
    setLoaderBody(false);

    try {
      const response = await axiosPrivate.get(
        `screening/get-prescreening/${props.selectedItem[0].value}`
      );

      if (response.data.screening != null) {
        if (response.data.screening.questions.length !== 0) {
          setQuestionStorage([]);
          setShowQuestions([]);
          setActiveQuestions([]);

          response.data.screening.questions.map((e) => {
            if (e.status !== 0) {
              let newQuestionId = e.question_id;
              setQuestionStorage((prevQuestionStorage) => [
                ...prevQuestionStorage,
                {
                  question_id: parseInt(e.question_id),
                  front_question_id: parseInt(e.question_id),
                  question_type: e.question_type,
                  question_title: e.question_title,
                  value: e.question_type,

                  mandatory: e.mandatory,
                  randomize: e.randomize,
                  answer_options: e.answer_options,
                  exclude_vendor:
                    exclude_vendor_opt.length !== 0
                      ? e.exclude_vendor.map((value) =>
                          exclude_vendor_opt.find(
                            (option) => option.value === value
                          )
                        )
                      : [],
                  status: e.status,
                },
              ]);
              setShowQuestions((prevQuestionStorage) => [
                ...prevQuestionStorage,
                {
                  question_id: parseInt(e.question_id),
                  front_question_id: parseInt(e.question_id),
                  question_type: e.question_type,
                  question_title: e.question_title,

                  mandatory: e.mandatory,
                  randomize: e.randomize,
                  answer_options: e.answer_options,
                  exclude_vendor:
                    exclude_vendor_opt.length !== 0
                      ? e.exclude_vendor.map((value) =>
                          exclude_vendor_opt.find(
                            (option) => option.value === value
                          )
                        )
                      : [],
                  value: e.question_type,
                  status: e.status,
                },
              ]);

              setActiveQuestions((prevActiveQuestions) => {
                // Create a new array with the updated question ID added
                const updatedQuestions = [
                  ...prevActiveQuestions,
                  parseInt(e.question_id),
                ];

                setQuestionShow(updatedQuestions[0]);
                // Return the updated array
                return updatedQuestions;
              });
            }
          });
          setQuestionAPi(false);
          setLoaderBody(true);
          setNoDataFound(true);
        }
        setQuestionAPi(false);
        setLoaderBody(true);
        setNoDataFound(true);
      } else {
        setQuestionAPi(false);
        setLoaderBody(true);
        setNoDataFound(true);
        SetSwitchApi(false);
      }
    } catch (err) {
      console.error(err);
      notifyerror(err.response.status);
    }
  };
  const paretsFormData = () => {
    let conditionMet = false; // Flag variable to track if any condition is met

    const updatedQuestionStorage = questionStorage.map((question, index) => {
      if (question.exclude_vendor) {
        // Map over exclude_vendor array and extract only the value property
        const updatedExcludeVendor = question.exclude_vendor.map(
          (item) => item.value
        );
        // Return the updated object with updated exclude_vendor
        question = {
          ...question,
          exclude_vendor: updatedExcludeVendor,
        };
      }

      // Check if question_type is not "S" nor "M" and status is 1
      if (
        question.question_type !== "S" &&
        question.question_type !== "M" &&
        question.status === 1
      ) {
        if (question.question_title === "") {
          setError_Number(index); // Set the index in error_number
          setQuestion_Error(true);
          conditionMet = true; // Set flag to true
          // return null; // Remove the question
        } else {
          return question;
        }
      }

      // Check if question_type is "S" or "M" and status is 1
      if (
        (question.question_type === "S" || question.question_type === "M") &&
        question.status === 1
      ) {
        // Check if question_title is empty
        if (question.question_title.trim() === "") {
          setError_Number(index); // Set the index in error_number
          setQuestion_Error(true);
          conditionMet = true; // Set flag to true
          // return null; // Remove the question
        } else {
          if (question.answer_options.length !== 0 && question.status === 1) {
            // Check if any answer_option has status 0
            const hasStatusZero = question.answer_options.every((option) => {
              return option.status === 0;
            });

            if (hasStatusZero) {
              setError_Number(index);
              setQuestion_Error(true);
              conditionMet = true;
            } else {
              // Check if any answer_option has empty answer_title when status is 1
              const hasEmptyTitle = question.answer_options.some((option) => {
                return option.status === 1 && option.answer_title === "";
              });

              if (hasEmptyTitle) {
                setError_Number(index);
                setQuestion_Error(true);
                conditionMet = true;
              } else {
                return question;
              }
            }
          } else {
            setError_Number(index);
            setQuestion_Error(true);
            conditionMet = true;
            // return null; // Remove the question
          }
        }
      }

      // Keep the question if the conditions are not met
      return question;
    });

    // Reset error states if no condition is met

    // Filter out null values from the updatedQuestionStorage array
    const filteredQuestionStorage = updatedQuestionStorage.filter(
      (question) => question !== null
    );

    const reorderedQuestions = activeQuestions
      .map((questionId) =>
        filteredQuestionStorage.find(
          (question) => question.front_question_id === questionId
        )
      )
      .filter((question) => question !== undefined);

    // Filter out any remaining questions not present in activeQuestions
    const remainingQuestions = filteredQuestionStorage.filter(
      (question) => !activeQuestions.includes(question.front_question_id)
    );

    const finalQuestions = [...reorderedQuestions, ...remainingQuestions];
    if (!conditionMet) {
      setError_Number(""); // Reset error_number
      setQuestion_Error(false); // Set question_error to false
      CallingApi(finalQuestions);
    }
  };
  const CallingApi = async (finalQuestions) => {
    setLoader(false);
    const payload = {
      survey_id: surveyId,
      questions: finalQuestions,
    };

    try {
      await axiosPrivate
        .post("screening/add-prescreening", payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          toast.success("Question updated successfully");
          props.setIsScreening(true);
          setAllowToFetch(false);
          setLoader(true);
          props.setQuestionPopup(false);
        });
    } catch (err) {
      notifyerror(err.response.status);
    }
  };

  // Function to reorder questions
  const reorderQuestions = (startIndex, endIndex) => {
    const updatedQuestions = Array.from(activeQuestions);
    const [removed] = updatedQuestions.splice(startIndex, 1);
    updatedQuestions.splice(endIndex, 0, removed);
    setActiveQuestions(updatedQuestions);
    const updated_show_Questions = [...showQuestion];
    // Remove the dragged item from the array and insert it at the new position
    const [removed_show] = updated_show_Questions.splice(startIndex, 1);
    updated_show_Questions.splice(endIndex, 0, removed_show);

    setShowQuestions(updated_show_Questions);
    setQuestionShow(questionShow);
  };
  // Define handleDragEnd function to handle drag and drop events
  const handleDragEnd = (result) => {
    // Destructure properties from the result
    const { destination, source } = result;

    // Check if there's no destination or the destination is the same as the source
    if (!destination || destination.index === source.index) {
      return;
    }
    reorderQuestions(source.index, destination.index);
  };

  return (
    <>
      {screeningStatus ? (
        <>
          <Screeninglogs
            show={screeningStatus}
            onHide={() => setScreeningStatus(false)}
            survey_id={surveyId}
          />
        </>
      ) : (
        <></>
      )}
      <div>
        <Modal
          id="checkkkkk"
          className="questionpopup"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="modelRTitle ps-0"
            >
              Prescreening Questions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="Button_row">
              <Col className="givepadding" lg={2} md={3} sm={6} xs={12}>
                <select
                  className="headingSelect"
                  id="QuestionSelect"
                  // class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    handleShowQuestion(e);
                    e.target.blur();
                  }}
                  value={"Select Value"}
                >
                  <option selected>
                    <GrFormAdd />
                    Add Questions
                  </option>
                  <option value="S">Single Select</option>
                  <option value="M">Multi Select</option>
                  <option value="O">Open End</option>
                  <option value="I">Message Box</option>
                  <option value="E">Email</option>
                  <option value="P">Phone</option>
                  <option value="N">Name</option>
                  <option value="A">Address</option>
                </select>
              </Col>

              <Col lg={2} md={3} sm={6} xs={12}>
                <button
                  className="addTemplateButton"
                  onClick={() => {
                    setScreeningStatus(true);
                  }}
                >
                  Logs
                </button>
              </Col>
            </Row>
            {loaderBody === false ? (
              <SpinnerLoader />
            ) : noDataFound === true && questionStorage.length === 0 ? (
              <div
                className="alert alert-danger center-margin-container m-0"
                role="alert"
              >
                No Question Found!
              </div>
            ) : (
              <>
                <Row>
                  <Col lg={2} md={12} sm={12} xs={12}>
                    <Row>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Table>
                          <Droppable droppableId="tbody">
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="texta respques"
                              >
                                {showQuestion.map((e, index) => (
                                  <React.Fragment key={e.front_question_id}>
                                    {e.status === 1 ? (
                                      <Draggable
                                        key={String(e.front_question_id)}
                                        draggableId={String(
                                          e.front_question_id
                                        )}
                                        index={showQuestion.findIndex(
                                          (q) =>
                                            q.front_question_id ===
                                            e.front_question_id
                                        )}
                                      >
                                        {(provided) => (
                                          <tr
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            key={e.front_question_id}
                                          >
                                            <td
                                              // className="questionShow"
                                              className={
                                                questionShow ===
                                                e.front_question_id
                                                  ? "questionShow active"
                                                  : "questionShow"
                                              }
                                              id={`question_Td${e.front_question_id}`}
                                              onClick={() => {
                                                ShowQuestionNumber(
                                                  e.front_question_id
                                                );
                                              }}
                                            >
                                              <span>
                                                Question No.
                                                {activeQuestions.indexOf(
                                                  e.front_question_id
                                                ) + 1}
                                                {/* {e.front_question_id} */}
                                              </span>
                                            </td>
                                          </tr>
                                        )}
                                      </Draggable>
                                    ) : null}
                                  </React.Fragment>
                                ))}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </Table>
                      </DragDropContext>
                    </Row>
                  </Col>

                  <Col lg={10} md={12} sm={12} xs={12}>
                    {/* <form onSubmit={handleSubmit(paretsFormData, onErrors)}> */}
                    <form onSubmit={handleSubmit(paretsFormData)}>
                      {questionStorage.map((e, index) => (
                        <>
                          {e.length === 0 ? (
                            <>Add question</>
                          ) : (
                            <>
                              {e.front_question_id === questionShow ? (
                                <>
                                  <Row>
                                    <Col>
                                      {e.status === 1 ? (
                                        <>
                                          <Row className="questionCountCss">
                                            <Col
                                              lg={11}
                                              md={11}
                                              sm={11}
                                              xs={11}
                                            >
                                              Question No.
                                              {/* {e.front_question_id} */}
                                              {activeQuestions.indexOf(
                                                e.front_question_id
                                              ) + 1}
                                              <FaRegDotCircle
                                                size={12}
                                                className="dotCircle"
                                              />
                                              {e.value === "I" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    "Message Box"
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "M" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Multiple Choice
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "S" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Single Select
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "O" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Open-end
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "E" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Email
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "P" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Phone
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "N" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Name
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              {e.value === "A" &&
                                              e.status === 1 ? (
                                                <>
                                                  <span className="showQuestion">
                                                    Address
                                                  </span>
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </Col>
                                            <Col lg={1} md={1} sm={1} xs={1}>
                                              {activeQuestions.length === 1 ? (
                                                <></>
                                              ) : (
                                                <>
                                                  <span className="actionButton">
                                                    <img
                                                      title="Delete"
                                                      src={DeleteIcon}
                                                      alt="Delete"
                                                      onClick={() => {
                                                        handledeleteQuestion(
                                                          e.front_question_id,
                                                          index
                                                        );
                                                      }}
                                                    />
                                                  </span>
                                                </>
                                              )}
                                            </Col>
                                          </Row>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </Col>

                                    <Col lg={12} md={12} sm={12} xs={12}>
                                      <div
                                        className="showQuestion"
                                        id={index + "deleteQuestion"}
                                      >
                                        {e.status === 1 &&
                                        (e.value === "M" || e.value === "S") ? (
                                          <>
                                            <EmailQuestion
                                              exclude_vendor_opt={
                                                exclude_vendor_opt
                                              }
                                              register={register}
                                              setValue={setValue}
                                              unregister={unregister}
                                              dex={e.front_question_id}
                                              questionStorage={e}
                                              inputs={inputs}
                                              setInputs={setInputs}
                                              setQuestionStorage={
                                                setQuestionStorage
                                              }
                                            />
                                          </>
                                        ) : e.status === 1 ? (
                                          <>
                                            <>
                                              <div
                                                id={index + "deleteQuestion"}
                                              >
                                                <Row className="messageBoxRow">
                                                  <Col
                                                    className="messageTop"
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                  >
                                                    <Row>
                                                      <textarea
                                                        textarea
                                                        id="w3review"
                                                        rows="4"
                                                        cols="50"
                                                        value={e.question_title}
                                                        className={
                                                          e.value !== "I"
                                                            ? "messageBoxTextArea_height"
                                                            : "messageBoxTextArea"
                                                        }
                                                        placeholder="Enter Message"
                                                        type="text"
                                                        onChange={(e) => {
                                                          setValue(
                                                            `Question${e.front_question_id}`,
                                                            e.target.value
                                                          );
                                                          const questionIndex =
                                                            questionStorage.findIndex(
                                                              (question) =>
                                                                question.front_question_id ===
                                                                questionStorage[
                                                                  index
                                                                ]
                                                                  .front_question_id
                                                            );

                                                          if (
                                                            questionIndex !== -1
                                                          ) {
                                                            const updatedQuestion =
                                                              {
                                                                ...questionStorage[
                                                                  questionIndex
                                                                ],
                                                                question_title:
                                                                  e.target
                                                                    .value,
                                                              };

                                                            const updatedQuestionStorage =
                                                              [
                                                                ...questionStorage,
                                                              ];
                                                            updatedQuestionStorage[
                                                              questionIndex
                                                            ] = updatedQuestion;
                                                            setQuestionStorage(
                                                              updatedQuestionStorage
                                                            );
                                                          }
                                                        }}
                                                      />
                                                    </Row>
                                                  </Col>
                                                  <div className="boxgap"></div>
                                                  <Row className="csmulti">
                                                    <Col
                                                      lg={4}
                                                      md={6}
                                                      sm={6}
                                                      xs={6}
                                                    >
                                                      <label className="formlabel">
                                                        Exclude Vendor
                                                      </label>
                                                      <MultiSelect
                                                        className="multiselectoption  "
                                                        options={
                                                          exclude_vendor_opt
                                                        }
                                                        value={
                                                          questionStorage[index]
                                                            .exclude_vendor
                                                        }
                                                        onChange={(e) => {
                                                          setSelected_Vendor(e);
                                                          setQuestionStorage(
                                                            (
                                                              prevQuestionStorage
                                                            ) => {
                                                              const updatedQuestionStorage =
                                                                [
                                                                  ...prevQuestionStorage,
                                                                ];
                                                              updatedQuestionStorage[
                                                                index
                                                              ].exclude_vendor =
                                                                e; // Update the title of the input at the specified index
                                                              return updatedQuestionStorage; // Return the updated questionStorage
                                                            }
                                                          );
                                                        }}
                                                        labelledBy={"Select"}
                                                        isCreatable={true}
                                                        disableSearch
                                                      />
                                                    </Col>
                                                    <Col
                                                      lg={4}
                                                      md={6}
                                                      sm={6}
                                                      xs={6}
                                                      className="centerstyle"
                                                    >
                                                      <label className="formlabel">
                                                        Mandatory
                                                      </label>
                                                      <span className="pswtchbtn spacebutton">
                                                        <BootstrapSwitchButton
                                                          width={65}
                                                          className="switch btn on btn-outline-success btn-xs"
                                                          size="xs"
                                                          onlabel="ON"
                                                          offlabel="OFF"
                                                          onstyle="outline-success"
                                                          offstyle="outline-danger"
                                                          checked={e.mandatory}
                                                          onChange={(e) => {
                                                            setQuestionStorage(
                                                              (
                                                                prevQuestionStorage
                                                              ) => {
                                                                const updatedQuestionStorage =
                                                                  [
                                                                    ...prevQuestionStorage,
                                                                  ];
                                                                updatedQuestionStorage[
                                                                  index
                                                                ].mandatory = e; // Update the title of the input at the specified index
                                                                return updatedQuestionStorage; // Return the updated questionStorage
                                                              }
                                                            );
                                                          }}
                                                        />
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                </Row>
                                              </div>
                                            </>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </>
                      ))}

                      {showQuestion.length > 0 ? (
                        <>
                          <div className="buttonPosition">
                            <button
                              className="cbtn saveBtn m-0"
                              type="submit"

                              // className="submitButtonQuestion butttonRadious"
                            >
                              {loader === false ? (
                                <>
                                  <Spinner
                                    as="span"
                                    style={{
                                      color: "white",
                                    }}
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                              &nbsp; Submit
                            </button>
                          </div>
                          {question_error === true ? (
                            <>
                              <small className="errmsg">
                                Fields cannot be left empty
                              </small>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </form>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
        </Modal>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body className="deleteMOdule">
            Do you want to delete ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="buttonbackground"
              variant="secondary"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="primary"
              className="buttonbackground"
              onClick={handleCloseWithYes}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
export default QuestionPopup;
