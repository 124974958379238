import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SpinnerLoader from "../../UI/Spinner";
import { useNavigate } from "react-router";
import { tab } from "@testing-library/user-event/dist/tab";
const SuplierCostInfoChild = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [tableData, setTableData] = useState([]);
  const [tableDataTwo, setTableDataTwo] = useState([]);
  const [surveyId, setSurveyId] = useState("");
  const [projectStatusStorage, setProjetStatusStorage] = useState([]);
  const [allSurveyProject, setAllSurveyProject] = useState([]);

  const [loder, setLoder] = useState(false);
  const [subLoader, setSubloader] = useState(false);
  const { id, layoutId, survey_Id } = useParams();
  useEffect(() => {
    get_ProjectData_Params();
  }, [id]);

  const get_SurveyProject = async (eid) => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-project-id?project_id=${eid}`
      );
      setAllSurveyProject(response.data.survey);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  const get_ProjectData_Params = async () => {
    var pid;
    try {
      const response = await axiosPrivate.get(`project/get-project/${id}`);
      setProjetStatusStorage(response.data.project);
      pid = response.data.project.id;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    get_SurveyProject(pid);
  };

  useEffect(() => {
    get_supplycostinfo_api();
  }, [surveyId]);

  const get_supplycostinfo_api = async (e) => {
    setSubloader(false);
    if (surveyId == "") {
    } else {
      try {
        const response = await axiosPrivate.get(
          `invoice/get-supply-cost-info?survey_id=${surveyId}`
        );
        setTableData(response.data.billingsurvey.supplier_info);
        setTableDataTwo(response.data.billingsurvey.total_summary);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setSubloader(true);
  };

  const [activeSections, setActiveSections] = useState([]);
  const [supplyInfoChildId, setSupplierInfoChildId] = useState("");

  const handleShowSect = (eid) => {
    let activeRow = document.getElementById(eid);
    document
      .querySelectorAll(".dropeTablesupply")
      .forEach((el) => el.classList.remove("open"));
    if (activeRow.classList.contains("active")) {
      activeRow.classList.remove("active");
    } else {
      document
        .querySelectorAll(".mainrow")
        .forEach((el) => el.classList.remove("active"));
      activeRow.classList.add("active");
      document.querySelector("." + eid).classList.add("open");
    }
    const index = activeSections.indexOf(eid);
    if (index === -1) {
      setActiveSections([eid]);
    } else {
      activeSections.splice(index, 1);
      setActiveSections([activeSections]);
    }
  };

  useEffect(() => {
    if (survey_Id !== "1") {
      if (loder === true) {
        handleShowSect(`iconSectm${survey_Id}`);
        setSupplierInfoChildId(survey_Id);
      }
    } else if (survey_Id === "1") {
      if (loder === true) {
        handleShowSect(`iconSectm${allSurveyProject[0].id}`);
        setSupplierInfoChildId(allSurveyProject[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loder]);

  return (
    <>
      {loder ? (
        <>
          {allSurveyProject.length == 0 ? (
            <>
              <div
                class="alert alert-danger"
                style={{ textAlign: "center", margin: "15px" }}
                role="alert"
              >
                No Survey Found!
              </div>
            </>
          ) : (
            <>
              {allSurveyProject.map((e, index) => (
                <>
                  <div className=" wrapperBelow" style={{}}>
                    <div
                      className="Sfirstbox"
                      style={{
                        position: "relative",
                        borderLeft: "1px solid #f5f3f3",
                        borderRight: "1px solid #f5f3f3",
                      }}
                    >
                      <div
                        className="SArrowHeead mainrow"
                        id={`iconSectm${e.id}`}
                        onClick={() => {
                          setSupplierInfoChildId(e.id);
                          handleShowSect(`iconSectm${e.id}`);
                          setSurveyId(e.id);
                          navigate(`/project-detail/${id}/${layoutId}/${e.id}`);
                        }}
                      >
                        <p className="supply_country">
                          {e.survey_name}---{e.sc_id}
                        </p>
                        <KeyboardArrowUpIcon
                          className={`SIconUpnew icon-${e.id}-down`}
                          style={{
                            display: activeSections.includes(`iconSectm${e.id}`)
                              ? "block"
                              : "none",
                          }}
                        />
                        <KeyboardArrowDownIcon
                          className={`SDropIconNew  icon-${e.id}-up`}
                          style={{
                            display: activeSections.includes(`iconSectm${e.id}`)
                              ? "none"
                              : "block",
                          }}
                        />
                      </div>

                      <>
                        {" "}
                        <div className={`dropeTablesupply iconSectm${e.id}`}>
                          {subLoader ? (
                            <div
                              className="cost_tmjhgfdable"
                              style={{
                                paddingLeft: "8px",
                                paddingRight: "10px",
                              }}
                            >
                              <Table bordered={false} hover size="sm">
                                <thead className="sup_thkjhgfdsead">
                                  <tr>
                                    <td>Supplier</td>
                                    <td>Hits</td>
                                    <td>Completes</td>
                                    <td>Approved Counts</td>
                                    <td>Incentive</td>
                                    <td>Revenue</td>
                                  </tr>
                                </thead>
                                {tableData.length !== 0 ? (
                                  <>
                                    <tbody>
                                      {tableData.map((supplier_info, index) => (
                                        <>
                                          <tr>
                                            <td>
                                              {supplier_info.supplier.name}
                                            </td>
                                            <td>{supplier_info.total_count}</td>
                                            <td>{supplier_info.sc_count}</td>
                                            <td>{supplier_info.cl_count}</td>
                                            <td>{supplier_info.cpi}</td>
                                            <td>{supplier_info.revenue}</td>
                                          </tr>
                                        </>
                                      ))}
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                      <tr>
                                        <td>-</td>
                                        <td>{tableDataTwo.total_count}</td>
                                        <td>KR</td>
                                        <td>{tableDataTwo.cl_count}</td>
                                        <td>-</td>
                                        <td>{tableDataTwo.revenue}</td>
                                      </tr>
                                    </tfoot>
                                  </>
                                ) : (
                                  <>
                                    <td colSpan={6}>
                                      <div
                                        class="alert alert-danger center-margin-container"
                                        role="alert"
                                      >
                                        No data Found!
                                      </div>
                                    </td>
                                  </>
                                )}
                              </Table>
                            </div>
                          ) : (
                            <>
                              <SpinnerLoader />
                            </>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
        </>
      ) : (
        <>
          <SpinnerLoader />
        </>
      )}
    </>
  );
};

export default SuplierCostInfoChild;
