import React, { useState, useEffect } from "react";
import "./Update_department.css";
import { useNavigate } from "react-router";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useParams } from "react-router";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../UI/Spinner";

const UpdateDepartment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [users, setUsers] = useState([]);
  const [depart, setDepart] = useState();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUser();
    getId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getId = async () => {
    try {
      const response = await axiosPrivate.get(`/department/${id}`, {});

      setDepart(response.data.department.head_of_dept);
      setName(response.data.department.name);
      setDescription(response.data.department.description);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      if (err.response.status === 404) {
        navigate("/404");
      }
    }
  };

  const getUser = async () => {
    let isMounted = true;
    try {
      const response = await axiosPrivate.get("user/head-department", {});
      isMounted && setUsers(response.data.all_users);
    } catch (err) {}
  };

  const onSubmit = async (e) => {
    setIsSubmitting(true);

    const payload = {
      name: e.name,
      description: e.description,
      head_of_dept: e.head_of_department,
      dept_id: id,
    };

    try {
      await axiosPrivate
        .patch("/department/update-department", payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (e.status === 200) {
            localStorage.setItem("updatedepartment", "1");
            navigate("/department");
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
      }
    }
    setIsSubmitting(false);
  };

  const hodReg = register("head_of_department", {
    required: "This field is Required",
  });

  return (
    <>
      {/* <Header></Header> */}
      <div className="top_container">
        <div className="main_container ">
          {isLoading ? (
            <SpinnerLoader />
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img src={userIco} className="headingIcons" alt="Update" />{" "}
                    Update {name}
                  </h3>
                </div>
                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={() => {
                      navigate("/department");
                    }}
                  >
                    {" "}
                    Close
                  </button>

                  <button className="cbtn saveBtn" type="submit">
                    {isSubmitting && (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      />
                    )}
                    &nbsp; Save
                  </button>
                </div>
              </div>

              <hr />

              <div className="mainContent">
                <p className="small note">* denotes required fields</p>

                <Row>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Name*</label>
                    <input
                      type="text"
                      className="formtext"
                      {...register("name", {
                        required: "This field is Required",
                        pattern: {
                          value:
                            /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                          message: "Only letters are allowed",
                        },
                      })}
                      defaultValue={name}
                    />{" "}
                    {errors.name && (
                      <small className="errmsg">{errors.name.message}</small>
                    )}
                  </Col>
                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Description*</label>
                    <input
                      type="text"
                      className="formtext"
                      {...register("description", {
                        required: "This field is Required",
                        pattern: {
                          value:
                            /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                          message: "Only letters are allowed",
                        },
                      })}
                      defaultValue={description}
                    />{" "}
                    {errors.description && (
                      <small className="errmsg">
                        {errors.description.message}
                      </small>
                    )}
                  </Col>

                  <Col lg={3} md={4} sm={6} xs={12} className="col">
                    <label className="formlabel">Head of Department*</label>
                    <select
                      className="formtext"
                      {...hodReg}
                      onChange={(e) => {
                        hodReg.onChange(e);
                        setDepart(e.target.value);
                      }}
                      value={depart}
                    >
                      <option value="">Select Option</option>
                      {users.map((user) => (
                        <option key={user.first_name} value={user._id}>
                          {user.name}
                        </option>
                      ))}
                    </select>

                    {errors.head_of_department && (
                      <small className="errmsg">
                        {errors.head_of_department.message}
                      </small>
                    )}
                  </Col>
                </Row>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};
export default UpdateDepartment;
