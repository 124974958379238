import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useEffect } from "react";
import { GrSubtractCircle } from "react-icons/gr";
import { BsFillPlayFill } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import "../QuestionModule/EmailQuestions.css";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useForm } from "react-hook-form";
import { Terminal } from "@mui/icons-material";
import SpinnerLoader from "../../../UI/Spinner";

const EmailQuestion = (prop) => {
  // const register = prop.register;
  // const setValue = prop.setValue;
  const { register, setValue, unregister } = prop;
  const count = prop.dex;
  const q_storage = prop.questionStorage;
  const [randomToggle, setRandomToggle] = useState(q_storage.randomize);
  const [mandatoryToggle, setMandatoryToggle] = useState(q_storage.mandatory);
  const [loder, setLoder] = useState(true);

  const [selecteduser, setSelectedUser] = useState([]);

  function handleAddInput() {
    prop.setQuestionStorage((prevQuestionStorage) => {
      // Find the index of the question with the specified front_question_id
      const questionIndex = prevQuestionStorage.findIndex(
        (question) => question.front_question_id === count
      );

      // If the question with the specified front_question_id is found
      if (questionIndex !== -1) {
        const updatedQuestionStorage = [...prevQuestionStorage];
        const newInput = {
          answer_id:
            updatedQuestionStorage[questionIndex].answer_options.length + 1,
          answer_logic: "1",
          status: 1,
          answer_title: "",
        }; // Create a new input object with an empty title
        updatedQuestionStorage[questionIndex].answer_options.push(newInput); // Add the new input to the answer_options array
        return updatedQuestionStorage; // Return the updated questionStorage
      } else {
        // If the question with the specified front_question_id is not found, return the previous questionStorage as is
        return prevQuestionStorage;
      }
    });
  }
  var data = [
    { value: "Paffffffffnel", label: "Panel" },
    { value: "Fulddddddddddddcrum", label: "Fulcrum SH1" },
  ];

  function handleRemoveInput(index) {
    prop.setQuestionStorage((prevQuestionStorage) => {
      // Find the index of the question with the specified front_question_id
      const questionIndex = prevQuestionStorage.findIndex(
        (question) => question.front_question_id === count
      );

      // If the question with the specified front_question_id is found
      if (questionIndex !== -1) {
        const updatedQuestionStorage = [...prevQuestionStorage];
        updatedQuestionStorage[questionIndex].answer_options[index].status = 0; // Set status to 0
        updatedQuestionStorage[questionIndex].answer_options.splice(index, 1); // Remove the input at the specified index
        return updatedQuestionStorage; // Return the updated questionStorage
      } else {
        // If the question with the specified front_question_id is not found, return the previous questionStorage as is
        return prevQuestionStorage;
      }
    });

    unregister(`answer${count}${index}`); // Unregister the input
  }

  function handleChangeInput(index, value) {
    prop.setQuestionStorage((prevQuestionStorage) => {
      const questionIndex = prevQuestionStorage.findIndex(
        (question) => question.front_question_id === count
      );

      // Update the title of the input at the specified index
      if (questionIndex !== -1) {
        const updatedQuestionStorage = [...prevQuestionStorage];
        updatedQuestionStorage[questionIndex].answer_options[
          index
        ].answer_title = value;
        return updatedQuestionStorage;
      } else {
        // If the question with the specified front_question_id is not found, return the previous questionStorage as is
        return prevQuestionStorage;
      }
    });
  }

  useEffect(() => {
    var data = [];
    q_storage?.answer_options.map((e) => {
      var statusQ;
      if (e.answer_logic === "1") {
        statusQ = "Screening"; // Use '=' for assignment, not '=='
      } else if (e.answer_logic === "2") {
        statusQ = "pass";
      } else {
        statusQ = "terminate";
      }
      data.push({ title: e.answer_title, answer_logic: statusQ });
    });

    if (data.length == 0) {
      prop.setInputs([""]);
    } else {
      prop.setInputs(data);
    }
    setLoder(false);
  }, [register]);

  return (
    <>
      {loder ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {" "}
          <Row className="firstRowborder">
            <Col lg={12} md={12} sm={12} xs={12}>
              <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                  <label className="formlabelviewzone">Question</label>
                  <input
                    // {...register(`Question${count}`, { required: true })}
                    id={`singleQuestion${count}`}
                    placeholder="Enter Question"
                    className="width100 "
                    value={q_storage?.question_title || ""}
                    onChange={(e) => {
                      // setValue(`Question${count}`, e.target.value);
                      prop.setQuestionStorage((prevQuestionStorage) => {
                        // Find the index of the question with the specified front_question_id
                        const questionIndex = prevQuestionStorage.findIndex(
                          (question) => question.front_question_id === count
                        );

                        // Update the title of the input at the specified index
                        if (questionIndex !== -1) {
                          const updatedQuestionStorage = [
                            ...prevQuestionStorage,
                          ];
                          updatedQuestionStorage[questionIndex].question_title =
                            e.target.value;
                          return updatedQuestionStorage;
                        } else {
                          // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                          return prevQuestionStorage;
                        }
                      });
                    }}
                  />
                </Col>
                <div className="emailquestionGap"></div>

                <Col>
                  {prop.questionStorage.answer_options.map((input, index) => (
                    <div>
                      {input.status === 1 ? (
                        <>
                          <div key={input.id}>
                            <tbody>
                              <tr className="radiohidden">
                                {/* <td>{index + 1}.</td> */}
                                <>
                                  <td className="width100">
                                    <input
                                      // {...register(`answer${count}${index}`, {
                                      //   required: true,
                                      // })}
                                      type="text"
                                      value={input.answer_title}
                                      id={count + "childinput" + index}
                                      className="width100"
                                      placeholder="Enter Options"
                                      onChange={(event) =>
                                        handleChangeInput(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    />
                                  </td>
                                </>

                                <div id="prescreen" className="radio-block">
                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    <GrSubtractCircle
                                      title="Delete"
                                      type="button"
                                      style={{
                                        marginLeft: "-24px",
                                      }}
                                      onClick={(e) => {
                                        handleRemoveInput(index);
                                      }}
                                    />
                                  </td>
                                  {/* =================== icon ======================== */}
                                  <td className="iconalliment">
                                    <div className="radio-content">
                                      <input
                                        {...register(
                                          `Status${count}${index}`,
                                          {}
                                        )}
                                        defaultChecked={
                                          input.answer_logic
                                            ? input.answer_logic === "1"
                                            : true
                                        }
                                        type="radio"
                                        id={`radio${index}11${count}`}
                                        value="1"
                                        onChange={(e) => {
                                          setValue(
                                            `Status${count}${index}`,
                                            "1"
                                          );
                                          prop.setQuestionStorage(
                                            (prevQuestionStorage) => {
                                              // Find the index of the question with the specified front_question_id
                                              const questionIndex =
                                                prevQuestionStorage.findIndex(
                                                  (question) =>
                                                    question.front_question_id ===
                                                    count
                                                );

                                              // If the question with the specified front_question_id is found
                                              if (questionIndex !== -1) {
                                                const updatedQuestionStorage = [
                                                  ...prevQuestionStorage,
                                                ];
                                                updatedQuestionStorage[
                                                  questionIndex
                                                ].answer_options[
                                                  index
                                                ].answer_logic = "1"; // Update the answer_logic of the input at the specified index
                                                return updatedQuestionStorage; // Return the updated questionStorage
                                              } else {
                                                // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                                                return prevQuestionStorage;
                                              }
                                            }
                                          );
                                        }}
                                      />
                                      <label
                                        className="play"
                                        htmlFor={`radio${index}11${count}`}
                                      >
                                        <BsFillPlayFill title="Pass" />
                                      </label>
                                    </div>
                                  </td>

                                  {/* Second radio button (Pass) */}
                                  <td className="iconalliment">
                                    <div className="radio-content">
                                      <input
                                        {...register(
                                          `Status${count}${index}`,
                                          {}
                                        )}
                                        defaultChecked={
                                          input.answer_logic === "2"
                                        }
                                        type="radio"
                                        id={`radio${index}33${count}`}
                                        value="2"
                                        onChange={(e) => {
                                          setValue(
                                            `Status${count}${index}`,
                                            "2"
                                          );
                                          prop.setQuestionStorage(
                                            (prevQuestionStorage) => {
                                              // Find the index of the question with the specified front_question_id
                                              const questionIndex =
                                                prevQuestionStorage.findIndex(
                                                  (question) =>
                                                    question.front_question_id ===
                                                    count
                                                );

                                              // If the question with the specified front_question_id is found
                                              if (questionIndex !== -1) {
                                                const updatedQuestionStorage = [
                                                  ...prevQuestionStorage,
                                                ];
                                                updatedQuestionStorage[
                                                  questionIndex
                                                ].answer_options[
                                                  index
                                                ].answer_logic = "2"; // Update the answer_logic of the input at the specified index
                                                return updatedQuestionStorage; // Return the updated questionStorage
                                              } else {
                                                // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                                                return prevQuestionStorage;
                                              }
                                            }
                                          );
                                        }}
                                      />
                                      <label
                                        className="circle"
                                        htmlFor={`radio${index}33${count}`}
                                      >
                                        <BsFillArrowUpRightCircleFill
                                          title="Screen In"
                                          size={15}
                                        />
                                      </label>
                                    </div>
                                  </td>

                                  {/* Third radio button (Terminate) */}
                                  <td className="iconalliment">
                                    <div className="radio-content">
                                      <input
                                        {...register(
                                          `Status${count}${index}`,
                                          {}
                                        )}
                                        defaultChecked={
                                          input.answer_logic === "3"
                                        }
                                        type="radio"
                                        id={`radio${index}22${count}`}
                                        value="3"
                                        onChange={(e) => {
                                          setValue(
                                            `Status${count}${index}`,
                                            "3"
                                          );
                                          prop.setQuestionStorage(
                                            (prevQuestionStorage) => {
                                              // Find the index of the question with the specified front_question_id
                                              const questionIndex =
                                                prevQuestionStorage.findIndex(
                                                  (question) =>
                                                    question.front_question_id ===
                                                    count
                                                );

                                              // If the question with the specified front_question_id is found
                                              if (questionIndex !== -1) {
                                                const updatedQuestionStorage = [
                                                  ...prevQuestionStorage,
                                                ];
                                                updatedQuestionStorage[
                                                  questionIndex
                                                ].answer_options[
                                                  index
                                                ].answer_logic = "3"; // Update the answer_logic of the input at the specified index
                                                return updatedQuestionStorage; // Return the updated questionStorage
                                              } else {
                                                // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                                                return prevQuestionStorage;
                                              }
                                            }
                                          );
                                        }}
                                      />
                                      <label
                                        className="cross"
                                        htmlFor={`radio${index}22${count}`}
                                      >
                                        <ImCross title="Terminate" size={13} />
                                      </label>
                                    </div>
                                  </td>
                                </div>
                              </tr>
                            </tbody>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))}
                </Col>
              </Row>

              <div className="emailquestionGap"></div>
              <button
                className="addButtonOPtions"
                onClick={handleAddInput}
                type="button"
              >
                Add Answer
              </button>
            </Col>
            <div className="emailquestionGap"></div>
            <Row>
              {" "}
              <Col lg={3} md={6} sm={6} xs={12}>
                <label className="formlabel"> Mandatory single</label>
                <span className="pswtchbtn">
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    checked={prop.questionStorage.mandatory}
                    width={60}
                    onlabel="ON "
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                    onChange={(e) =>
                      prop.setQuestionStorage((prevQuestionStorage) => {
                        const questionIndex = prevQuestionStorage.findIndex(
                          (question) => question.front_question_id === count
                        );

                        // Update the title of the input at the specified index
                        if (questionIndex !== -1) {
                          const updatedQuestionStorage = [
                            ...prevQuestionStorage,
                          ];
                          updatedQuestionStorage[questionIndex].mandatory = e;
                          return updatedQuestionStorage;
                        } else {
                          // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                          return prevQuestionStorage;
                        }
                      })
                    }
                  />
                </span>
              </Col>
              <Col lg={3} md={6} sm={6} xs={12}>
                <label className="formlabel"> Randomize Answer</label>
                <span className="pswtchbtn">
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    size="xs"
                    width={60}
                    checked={prop.questionStorage.randomize}
                    onlabel="ON"
                    offlabel="OFF"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                    onChange={(e) =>
                      prop.setQuestionStorage((prevQuestionStorage) => {
                        const questionIndex = prevQuestionStorage.findIndex(
                          (question) => question.front_question_id === count
                        );

                        // Update the title of the input at the specified index
                        if (questionIndex !== -1) {
                          const updatedQuestionStorage = [
                            ...prevQuestionStorage,
                          ];
                          updatedQuestionStorage[questionIndex].randomize = e;
                          return updatedQuestionStorage;
                        } else {
                          // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                          return prevQuestionStorage;
                        }
                      })
                    }
                  />
                </span>
              </Col>
              <Col lg={3} md={6} sm={6} xs={12}>
                <label className="formlabel"> Add Predefined Answer</label>
                <div className="radiouscolorchanges">
                  <select
                    {...register(`Add_predefined_answer${count}`)}
                    id="addPredefinedstyle"
                    className="form-select"
                    value=""
                    onChange={(end) => {
                      setValue(
                        `Add_predefined_answer${count}`,
                        end.target.value
                      );
                      prop.setInputs((prevInputs) => [
                        ...prevInputs,
                        {
                          title: end.target.value,
                          answer_logic: "terminate",
                        },
                        // end.target.value,
                      ]);

                      prop.setQuestionStorage((prevQuestionStorage) => {
                        // Find the index of the question with the specified front_question_id
                        const questionIndex = prevQuestionStorage.findIndex(
                          (question) => question.front_question_id === count
                        );

                        // If the question with the specified front_question_id is found
                        if (questionIndex !== -1) {
                          const updatedQuestionStorage = [
                            ...prevQuestionStorage,
                          ];
                          const newInput = {
                            answer_id:
                              updatedQuestionStorage[questionIndex]
                                .answer_options.length + 1,
                            answer_logic: "3",
                            status: 1,
                            answer_title: end.target.value,
                          }; // Create a new input object with an empty title
                          updatedQuestionStorage[
                            questionIndex
                          ].answer_options.push(newInput); // Add the new input to the answer_options array
                          return updatedQuestionStorage; // Return the updated questionStorage
                        } else {
                          // If the question with the specified front_question_id is not found, return the previous questionStorage as is
                          return prevQuestionStorage;
                        }
                      });
                    }}
                  >
                    <option value="" disabled selected>
                      Add Predefined Answer
                    </option>
                    <option value="None of the above">None of the above</option>
                    <option value="I don't know">I don't know</option>
                    <option value="Prefer not to answer">
                      Prefer not to answer
                    </option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </Col>
              <Col lg={3} md={6} sm={6} xs={12} className="csmulti">
                {" "}
                <div className="multiselectoption">
                  <label className="formlabelviewzone">Exclude Supplier</label>
                  <MultiSelect
                    className="prescreenMultiselect"
                    options={prop.exclude_vendor_opt}
                    value={prop.questionStorage.exclude_vendor}
                    onChange={(e) => {
                      setSelectedUser(e);

                      prop.setQuestionStorage((prevQuestionStorage) => {
                        const questionIndex = prevQuestionStorage.findIndex(
                          (question) => question.front_question_id === count
                        );

                        // Update the title of the input at the specified index
                        if (questionIndex !== -1) {
                          const updatedQuestionStorage = [
                            ...prevQuestionStorage,
                          ];
                          updatedQuestionStorage[questionIndex].exclude_vendor =
                            e;
                          return updatedQuestionStorage;
                        } else {
                          // If the question with the specified question_id is not found, return the previous questionStorage as is
                          return prevQuestionStorage;
                        }
                      });
                    }}
                    labelledBy={"Select"}
                    isCreatable={true}
                    disableSearch
                  />
                </div>
              </Col>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

export default EmailQuestion;
