import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import "../../SupplyInfo/PopUp/SetupSupplier.css";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../../UI/Spinner";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const BuildClientUrlTest = (props, setTerminationlinkedit) => {
  const inputRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(true);
  const [checkQmark, setCheckQmark] = useState(true);
  const [completeUrl, setCompleteUrl] = useState(props.baseurl);
  const [baseUrl, setBaseUrl] = useState(props.baseurl);
  const [combinedIds, setCombinedIds] = useState([]);
  const [clientVar, setClientVar] = useState([]);
  const [fixedVar, setFixedVar] = useState([]);
  const [customVar, setCustomVar] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [ridStat, setRidStat] = useState(false);
  const [flag, setFlag] = useState(props.show);
  const [isBaseUrl, setIsBaseUrl] = useState(true);
  const [fixedIds, setFixedIds] = useState(true);
  const [allIds, setAllIds] = useState([]);
  const [checkFlag, setCheckFlag] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [multiselectValues, setMultiSelectValues] = useState([]);



  // const dummydata = [
  //   {
  //     name: "age",
  //     var_format: "[%age%]",
  //     is_variable: true,
  //     key: "age",
  //     status: true,
  //   },
  //   {
  //     name: "gender",
  //     var_format: "[%gender%]",
  //     is_variable: true,
  //     key: "gender",
  //     status: true,
  //   },
  // ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();



  useEffect(() => {

    combinedIds.forEach((combinedItem) => {
      if (combinedItem.key === undefined) {
      if (combinedItem.var_format === "[%tid%]") {
        combinedItem.key = fixedVar.find(item => item.var_format === "[%tid%]")?.key;
        combinedItem.name = fixedVar.find(item => item.var_format === "[%tid%]")?.name;
      }
      else if (combinedItem.var_format === "[%pid%]") {
        
        combinedItem.key = fixedVar.find(item => item.var_format === "[%pid%]")?.key;
        combinedItem.name = fixedVar.find(item => item.var_format === "[%pid%]")?.name;
      } else {

        combinedItem.key = customVar.find(item => item.var_format === combinedItem.var_format)?.key;
        combinedItem.name = customVar.find(item => item.var_format === combinedItem.var_format)?.name;
        // multiselectValues.forEach((multiItem) => {
        //   if (combinedItem.var_format === multiItem.value) {
        //     combinedItem.key = multiItem.name;
        //   }
        // });
      }
    }
    if (combinedItem.name === undefined) {
      if (combinedItem.var_format === "[%tid%]") {       
        combinedItem.name = fixedVar.find(item => item.var_format === "[%tid%]")?.name;
      }
      else if (combinedItem.var_format === "[%pid%]") {      
        combinedItem.name = fixedVar.find(item => item.var_format === "[%pid%]")?.name;
      } else {  
        combinedItem.name = customVar.find(item => item.var_format === combinedItem.var_format)?.name;
      }
    }
  });

  }, [multiselectValues]);

  const get_clientlink_api = async () => {
    try {
      const response = await axiosPrivate.get(`survey/client-link-format`);
      setClientVar(response.data.clientlinkformat);

      const fixedArr = response.data.clientlinkformat.filter(
        (item) => item.is_variable === false
      );
      setFixedVar(fixedArr);

      let fixedVarArr = [];
      let ids = [];
      response.data.clientlinkformat.map((ite) => {
        ids.push(ite.var_format);
        if (ite.is_variable === false) {
          fixedVarArr.push(ite.var_format);
        }
      });
      setAllIds(ids);
      setFixedIds(fixedVarArr);

      const customArr = response.data.clientlinkformat.filter(
        (item) => item.is_variable === true
      );
      setCustomVar([]);
      setCustomVar(customArr);

      customArr.map((ite) => {
        ids.push(ite.var_format);
      });
      setAllIds(ids);
      // customArr.map((ite) => {
      //   setCustomVar((val) => [
      //     ...val,
      //     {
      //       name: ite.name,
      //       value: ite.name,
      //       label: ite.name,
      //     },
      //   ]);
      // });
      customArr.map((ite) => {
        setMultiSelectValues((val) => [
          ...val,
          {
            name: ite.key,
            value: ite.var_format,
            label: ite.name,
          },
        ]);
      });

      //Hide/show variable input fields based on checkmark
      if (!checkQmark) {
        document.querySelectorAll(".vtttinput").forEach(function (el) {
          el.style.visibility = "hidden";
        });
      } else {
        document.querySelectorAll(".vtttinput").forEach(function (el) {
          el.style.visibility = "visible";
        });
      }
    } catch (err) {
      // console.error(err);
    }
    setLoader(false);
    //makeCompleteUrl();
  };


  useEffect(() => {
    get_clientlink_api();

    

    if (
      baseUrl &&
      baseUrl != null &&
      baseUrl != "null" &&
      baseUrl != undefined
    ) {
      const checkq = baseUrl.includes("?");
      setIsBaseUrl(true);

      var url = "";
      var urlvariable = "";
      var keyvalpair = [];
      if (checkq) {
        setCheckQmark(true);
        var pathArray = baseUrl.split("?");
        url = pathArray[0];
        urlvariable = pathArray[1];
        keyvalpair = urlvariable.split("&");
        handleCombinedIds(keyvalpair, "", 1);
      } else {
        var pathArray = baseUrl.split("/");
        var protocol = pathArray[0];
        var host = pathArray[2];
        url = protocol + "//" + host;
        urlvariable = baseUrl.replace(url, "");
        var partBeforeBrackets = getUrlBeforeBrackets(urlvariable);
        url = url + partBeforeBrackets;
        if (pathArray.length > 3) {
          setCheckQmark(false);
          var keyvalpair = findAllElementsInBrackets(urlvariable);
        }
        handleCombinedIds("", keyvalpair, 1);
      
      }
      setBaseUrl(url);
      setCompleteUrl(baseUrl);
      if (urlvariable === "") {
        if (!checkQmark) {
          setCompleteUrl(baseUrl + "/[%tid%]");
        } else {
          setCompleteUrl(baseUrl + "?tid=[%tid%]");
        }
      }
    } else {
      setIsBaseUrl(false);

      setCompleteUrl(baseUrl);
    }

    setRidStat(true);
  }, []);

  function getUrlBeforeBrackets(str) {
    var parts = str.split("[");
    var partBeforeBrackets = parts[0].replace(/\/$/, "");
    return partBeforeBrackets;
  }

  function findAllElementsInBrackets(str) {
    var regex = /\[([^\]]+)\]/g;
    var matches = [];
    var match;
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[0]);
    }
    return matches;
  }

  
  

  useEffect(() => {
    if (!checkQmark) {
      document.querySelectorAll(".vtttinput").forEach(function (el) {
        el.style.visibility = "hidden";
      });
    } else {
      document.querySelectorAll(".vtttinput").forEach(function (el) {
        el.style.visibility = "visible";
      });
    }
    
    makeCompleteUrl();
  }, [checkQmark, loader, checkFlag]);

  useEffect(() => {
    if (completeUrl !== "") {
      setShowError(false);
    }
  }, [completeUrl]);

  const handleVarCheckbox = (event, var_format, name) => {
    const elem = event.target.name;
    const checked = event.target.checked;

    const value = document.getElementById("input_" + elem).value;

    setCombinedIds((prevCombinedId) => {
      let updatedCombinedId = [...prevCombinedId];
      console.error(updatedCombinedId)
      if (checked) {
        event.target.checked = true;
        // Append the value to the combinedId if it doesn't exist
        const isValueExists = updatedCombinedId.some(
          (param) => param.value === value
        );
        if (!isValueExists) {
          const paramToAdd = {
            name,
            key: value,
            var_format,
          };
          updatedCombinedId.push(paramToAdd);
        }
      } else {
        event.target.checked = false;
        // Remove the value from the combinedId
        updatedCombinedId = updatedCombinedId.filter(
          (param) => param.var_format !== var_format
        );

      }

      return updatedCombinedId;
    });
    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.var_format,
      })),
    ]);
    setCheckFlag(!checkFlag);

    makeCompleteUrl();
  };

  const handleVarPlaceholder = (event, var_format, name) => {
    const inputValue = event.target.value;
    setCombinedIds((prevCombinedId) => {
      const updatedCombinedId = prevCombinedId.map((option) => {
        if (option.var_format === var_format) {
          return {
            ...option,
            key: inputValue,
          };
        }
        return option;
      });
      return updatedCombinedId;
    });

    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.var_format,
      })),
    ]);
    setCheckFlag(!checkFlag);

    makeCompleteUrl();
  };

  const handleMultiSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => ({
        name: option.label,
        value: option.value,
      })
    );

    setCombinedIds((prevCombinedId) => [
      ...prevCombinedId,
      ...selectedOptions.map((option) => ({
        key: clientVar.find((q) => q.var_format === option.value)?.key || null,
        var_format: option.value,
        name: clientVar.find((q) => q.var_format === option.value)?.name || null
        //name: dummydata.find((q) => q.var_format === option.value)?.name || null,
      })),
    ]);

    setSelectedIds((prevIds) => [
      ...prevIds,
      ...selectedOptions.map((option) => ({
        value: option.value,
      })),
    ]);
    setCheckFlag(!checkFlag);
    makeCompleteUrl();
  };

  const handleUrlInputChange = (event) => {
    setCompleteUrl(event.target.value);
    const inputValue = event.target.value;

    const paramValue = inputValue.split("?");
    const paramValuePairs = paramValue[1].split("&");
    handleCombinedIds(paramValuePairs, "", 0);
  };

  const handleCombinedIds = (valuepairs, singlevalue, makeurl) => {
    let valarray = [];
    let newIds = [];

    if (valuepairs !== "") {
      valuepairs.forEach((pair, i) => {
        if (pair != "") {
          // Split each pair into parameter and value
          const [param, value] = pair.split("=");
          if (value != undefined || value != "undefined") {
            valarray[i] = value;
          }
          const paramToAdd = {
            key: param,
            var_format: value,
            // name: clientVar.find((q) => q.var_format === option.value)?.q_name || null
            name:
            customVar.find((q) => q.var_format === value)?.name ||
              clientVar.find((q) => q.var_format === value)?.name,
          };
          newIds.push(paramToAdd);
        }
      });
    }
    if (singlevalue !== "") {
      singlevalue.forEach((value, i) => {
        if (value !== "") {
          valarray[i] = value;
        }
        const paramToAdd = {
          key:
            customVar.find((q) => q.var_format === value)?.key ||
            clientVar.find((q) => q.var_format === value)?.key,
          var_format: value,
          // name: clientVar.find((q) => q.var_format === option.value)?.q_name || null
          name:
            customVar.find((q) => q.var_format === value)?.name ||
            clientVar.find((q) => q.var_format === value)?.name,
        };
        newIds.push(paramToAdd);
      });
    }

  //   

    setCombinedIds(newIds);
    setCheckedIds(valarray);
    setSelectedIds(() => [
      ...newIds.map((option) => ({
        value: option.var_format,
      })),
    ]);
    setCheckFlag(!checkFlag);

    if (makeurl) {
      makeCompleteUrl();
    }
  };

  const makeCompleteUrl = () => {

    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.var_format,
      })),
    ]);
    let url = baseUrl;
    const len = combinedIds.length;
    if (checkQmark) {
      if (len > 0) {
        url += "?";
      }
    } else {
      if (len > 0) {
        url = url.trim("/");
        url += "/";
      }
    }

    combinedIds.map((item, index) => {
      if (checkQmark) {
        url += item.key + "=" + item.var_format;
      } else {
        if (item !== "") {
          url += item.var_format;
        }
      }

      if (index < len - 1) {
        if (checkQmark) {
          url += "&";
        } else {
          url += "/";
        }
      }
    });
    setCompleteUrl(url);
  };

  const handleClose = () => {
    setFlag(!flag);
    setCombinedIds([]);
    setBaseUrl("");

    props.setlink(props.baseurl);

    props.setbuildurledit(false);

    reset();
  };

  const formsubmission = (e) => {
    if (completeUrl) {
      props.settermination(`${props.keyvalue}`, completeUrl);
      props.setlink(completeUrl);
      props.setbuildurledit(false);

      reset();
    } else {
      setShowError(true);
    }
  };

  const compUrl = register("compUrl", {
    required: "This field is Required",
  });

  const setBaseUrlfunction = (val) => {
    if (
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        val
      )
    ) {
      setBaseUrl(val);

      if (!checkQmark) {
        setCompleteUrl(val + "/[%tid%]");
      } else {
        let cvar = fixedVar.find(item => item.var_format === "[%tid%]")?.key;
        setCompleteUrl(val + "?"+cvar+"=[%tid%]");
      }
      setIsBaseUrl(true);
    }
    setCombinedIds([]);
    setCombinedIds((prevCombinedId) => {
      return [
        ...prevCombinedId,
        {
          var_format: clientVar[0].var_format,
          name: clientVar[0].name,
          key: clientVar[0].key,
        },
      ];
    });
    setSelectedIds([]);
    setSelectedIds((prevIds) => {
      return [
        ...prevIds,
        {
          value: clientVar[0].var_format,
        },
      ];
    });
  };

  

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Build Test URL
        </Modal.Title>
      </Modal.Header>
      {loader ? (
        <SpinnerLoader />
      ) : (
        <>
          <Modal.Body>
            <Row>
              <Col lg={8} md={12} sm={12} xs={12} className="">
                <p
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  What's your survey's URL?
                </p>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12} className="">
                <input
                  type="checkbox"
                  placeholder=""
                  defaultChecked={checkQmark}
                  onChange={(e) => {
                    setCheckQmark(e.target.checked);
                    //handleQuestionmark(e);
                  }}
                />
                <label
                  className="formlabelviewzone"
                  style={{
                    width: "95%",
                    paddingLeft: "10px",
                  }}
                >
                  Check question mark format
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="">
                {isBaseUrl ? (
                  <>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                      }}
                      // {...compUrl}
                      value={completeUrl}
                      onChange={(e) => {
                        //compUrl.onBlur(e);
                        handleUrlInputChange(e);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <small className="">
                      <font color="red">
                        Please enter a base url like "https://samplicity.com"
                      </font>
                    </small>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "red",
                      }}
                      onBlur={(event) => {
                        setBaseUrlfunction(event.target.value);
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} sm={6} xs={12} className="col">
                <h6
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Variables
                </h6>
                <p style={{ margin: "10px 0px 20px 0px" }}>
                  These are URL parameters for respondent or survey-level data
                  that will be passed along when a respondent gets sent to your
                  survey.
                </p>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="col"
                style={{
                  marginTop: "40px",
                }}
              >
                {" "}
                {isBaseUrl ? (
                  <>
                    {customVar.length === 0 ? (
                      <></>
                    ) : (
                      <div className="variable_select">
                        <select
                          className="variable_select__select"
                          onChange={(event) => {
                            handleMultiSelectChange(event);
                          }}
                          defaultValue=""
                        >
                          <option value="" disabled hidden>
                            Select Variable
                          </option>
                          {/* {customVar.map((option) => ( */}
                          {multiselectValues.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={combinedIds.some((element) => {
                                return element.var_format === option.value;
                              })}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row>
              {isBaseUrl ? (
                <>
                  {fixedVar.map((item, index) => (
                    <Col
                      className="Quality_Col"
                      key={"col" + index}
                      lg={2}
                      md={4}
                      sm={4}
                      xs={6}
                    >
                      <label
                        className="formlabel"
                        htmlFor={"check_" + item.value}
                      >
                        {item.name}
                      </label>
                      <input
                        className=" box_width "
                        type="checkbox"
                        id={"check_" + item.var_format}
                        name={item.var_format}
                        checked={selectedIds.some(
                          (sid) => sid.value && sid.value === item.var_format
                        )}
                        onChange={(event) => {
                          handleVarCheckbox(event, item.var_format, item.name);
                        }}
                      />
                      <input style={{ border: "1px solid green", }}
                        className="vtttinput"
                        type="text"
                        name={"input_" + item.var_format}
                        id={"input_" + item.var_format}
                        value={combinedIds.find((q) => q.var_format === item.var_format)?.key !== undefined
      ? combinedIds.find((q) => q.var_format === item.var_format)?.key : item.key }
                        onChange={(event) => {
                          handleVarPlaceholder(event, item.var_format, item.name);
                        }}
                      />
                    </Col>
                  ))}

                  {combinedIds.map((option, index) =>
                    fixedIds.includes(option.var_format) ? (
                      <></>
                    ) : (
                      <>
                        {allIds.includes(option.var_format) ? (
                          <Col
                            key={"custom" + index}
                            className="Quality_Col"
                            lg={2}
                            md={4}
                            sm={4}
                            xs={6}
                          >
                            <div>
                              <label
                                className="formlabel"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {/* {multiselectValues.find((obj) => (obj.value == option.value))?.label || option.value
                              } */}
                                {option.name}
                              </label>
                              <input
                                type="checkbox"
                                className=""
                                value={option.var_format}
                                name={option.var_format}
                                onChange={(event) => {
                                  handleVarCheckbox(
                                    event,
                                    option.var_format,
                                    option.name
                                  );
                                }}
                                checked={selectedIds.some(
                                  (item) => item.value === option.var_format
                                )}
                              />
                              <input
                                style={{
                                  border: "1px solid red",
                                }}
                                className="vtttinput"
                                type="text"
                                name={"input_" + option.var_format}
                                id={"input_" + option.var_format}
                                value={combinedIds[option.key] || option.key}
                                onChange={(event) => {
                                  handleVarPlaceholder(
                                    event,
                                    option.var_format,
                                    option.name
                                  );
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="col"
                style={{
                  marginTop: "20px",
                }}
              >
                <form
                  onSubmit={handleSubmit(formsubmission)}
                  id="buildurl"
                  name="buildurl"
                >
                  <label className="formlabel">Completion Link</label>
                  <textarea
                    type="text"
                    style={{ width: "100%" }}
                    value={completeUrl}
                    readOnly={true}
                    {...register("surveyType", {
                      required: "This field is Required",
                    })}
                  />
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {showError ? (
              <>
                <p
                  style={{
                    textAlign: "left",
                    color: "#ff0000",
                  }}
                >
                  Completion Link cannot be left empty!
                </p>
              </>
            ) : (
              <></>
            )}
            <button
              className="cbtn saveBtn m-0"
              style={{
                marginBottom: "10px",
                marginRight: "15px",
              }}
              type="submit"
              form="buildurl"
              onClick={(event) => {
                formsubmission(event);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default BuildClientUrlTest;
