import { useWatch } from "react-hook-form";

function totalCal(results) {
  let totalValue = 0;

  for (const key in results) {
    let amtN = parseInt(results[key]["n"]);
    let cpi = parseFloat(results[key]["cpi"]);

    amtN = Number.isNaN(amtN) ? 0 : amtN;
    cpi = Number.isNaN(cpi) ? 0 : cpi;
    totalValue = totalValue + amtN * cpi;
  }
  return totalValue;
}

export const CalcBidAmt = ({ control, setValue, setBidAmt }) => {
  const results = useWatch({ control, name: "opportunities" });
  const output = totalCal(results);

  setValue("bid_amount", output);
  setBidAmt(output);
};
