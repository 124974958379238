import Header from "../../UI/Header/Header";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "../BidsTable/BidsTable.css";
import Table from "react-bootstrap/Table";
import pageIcon from "../../../assets/BidsIcons/bids.svg";
import addIcon from "../../../assets/BidsIcons/add.svg";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import SpinnerLoader from "../../UI/Spinner";
import { TableCell, TableRow, TableBody } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Paginations from "../../Paginations/Paginations";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import moment from "moment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableSortLabel } from "@mui/material";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import Unauthorized from "../../auth/Unauthorized";
const Bidsdetail = () => {
  moment.locale("en");
  const [dataLoader, setDataLoader] = useState(true);
  const [userdata, setUserdata] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const [reverSign, setReverSign] = useState(0);
  const navigate = useNavigate();
  const [dataLength, setDataLength] = useState();
  const [searchdata, setSearchdata] = useState("");
  const { auth } = useAuth();

  const superUser = auth.superuser;
  const all_permission = auth.permission;
  const notifySuccess = (msg) => {
    toast.success((t) => (
      <span style={{ color: "white" }}>
        {msg}
        <button className="closeToaster" onClick={() => toast.dismiss(t.id)}>
          x
        </button>
      </span>
    ));
  };

  useEffect(() => {
    let addBid = localStorage.getItem("newbid");
    if (addBid === "1") {
      notifySuccess("Bid created successfully");
    }
    localStorage.setItem("newbid", 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBidList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBidList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage, searchdata]);

  const getPageSize = (size) => {
    setPagesize(size);
    setNextPage(1);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  const getBidList = async () => {
    try {
      const response = await axiosPrivate.get(
        `bid/bid-list?query_filter=${searchdata}&page=${nextPage}&size=${pagesize}&sort=-_id`
      );

      setDataLoader(false);
      setUserdata(response.data.items);
      setDataLength(response.data.total);
    } catch (err) {}
  };

  const headers = [
    {
      id: "bid_number",
      label: "Bid #",
    },
    {
      id: "client.client_contact_name",
      label: "Contact Name",
    },
    {
      id: "project_name",
      label: "Email Subject",
    },
    {
      id: "client.client_name",
      label: "Account Name",
    },
    {
      id: "bid_owner.name",
      label: "Bid Owner",
    },
    {
      id: "user_status_updated_updated_date",
      label: "Date Opened",
    },
    {
      id: "bid_status.name",
      label: "Status",
    },

    {
      id: "Action",
      label: "Action",
    },
  ];

  const handlesort = async (prop) => {
    setReverSign(reverSign + 1);
    if (reverSign === 0) {
      const response = await axiosPrivate.get(
        `/bid/bid-list?query_filter=${searchdata}&page=1&size=${pagesize}&sort=${prop}`
      );
      setUserdata(response.data.items);
      setReverSign(1);
    } else {
      const response = await axiosPrivate.get(
        `/bid/bid-list?query_filter=${searchdata}&page=1&size=${pagesize}&sort=-${prop}`,
        {}
      );
      setUserdata(response.data.items);
      setReverSign(0);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="biddetails" />
      {superUser === true ? (
        <>
          <div className="top_container">
            <div className="main_container ">
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img src={pageIcon} className="headingIcons" alt="" /> Bids
                  </h3>
                </div>
                <input
                  type="search"
                  className="tableSearch"
                  placeholder="Search..."
                  onChange={(e) => setSearchdata(e.target.value)}
                />
                {superUser === true ? (
                  <>
                    <button

                      className="cbtn addBtn"
                      onClick={() => {
                        navigate("/create-bid");
                      }}
                    >
                      <img src={addIcon} alt="" /> &nbsp; Create Bid
                    </button>
                  </>
                ) : (
                  <>
                    {all_permission.includes("bid.bid_create") ? (
                      <>
                        <button
                          className="cbtn addBtn"
                          onClick={() => {
                            navigate("/create-bid");
                          }}
                        >
                          <img src={addIcon} alt="" /> &nbsp; Create Bid
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
              <hr />
              <div className="mainContent mainContent2">
                {dataLoader ? (
                  <SpinnerLoader />
                ) : userdata.length === 0 ? (
                  <>
                    {" "}
                    <div style={{ textAlign: "center" }}>No data Found</div>
                  </>
                ) : (
                  <>
                    <div className="table_container">
                      <Table
                        bordered={false}
                        hover
                        size="sm"
                        className="customTable bidDetails"
                      >
                        <thead className="thead">
                          <TableRow>
                            {headers.map((header) => {
                              return (
                                <TableCell key={header.id}>
                                  {reverSign === 0 ? (
                                    <>
                                      {" "}
                                      <TableSortLabel
                                        active={false}
                                        direction={"asc"}
                                        onClick={() => {
                                          handlesort(header.id);
                                        }}
                                        IconComponent={ArrowDropUpIcon}
                                      >
                                        {header.label}
                                      </TableSortLabel>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <TableSortLabel
                                        active={false}
                                        direction={"asc"}
                                        onClick={() => {
                                          handlesort(header.id);
                                        }}
                                        IconComponent={ArrowDropDownIcon}
                                      >
                                        {header.label}
                                      </TableSortLabel>
                                    </>
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </thead>
                        <TableBody id="mailbox">
                          {userdata.map((e, i) => (
                            <>
                              <TableRow key={e.id}>
                                <TableCell>{e.bid_number}</TableCell>
                                <TableCell>
                                  {e.client.client_contact_name}
                                </TableCell>
                                <TableCell>{e.project_name}</TableCell>
                                <TableCell>{e.client.client_name}</TableCell>
                                <TableCell>{e.bid_owner.name}</TableCell>
                                <TableCell>
                                  {moment(
                                    e.user_status_updated.updated_date
                                  ).format("D/MM/YYYY, h:mm a")}
                                </TableCell>
                                <TableCell>{e.bid_status.name}</TableCell>
                                <TableCell>
                                  <td>
                                    {superUser === true ? (
                                      <>
                                        <span
                                          className="actionButton"
                                          onClick={() => {
                                            navigate(`/update-bid/${e.id}`);
                                          }}
                                        >
                                          <img src={EditIcon} alt="" />
                                        </span>
                                        <span className="actionButton">
                                          <img alt="" />
                                        </span>
                                        <span className="actionButton"></span>
                                      </>
                                    ) : (
                                      <>
                                        {all_permission.includes(
                                          "bid.bid_update"
                                        ) ||
                                        all_permission.includes(
                                          "bid.bid_get"
                                        ) ? (
                                          <>
                                            <span
                                              className="actionButton"
                                              onClick={() => {
                                                navigate(`/update-bid/${e.id}`);
                                              }}
                                            >
                                              <img src={EditIcon} alt="" />
                                            </span>
                                            <span className="actionButton">
                                              <img alt="" />
                                            </span>
                                            <span className="actionButton"></span>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}{" "}
                                  </td>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <Paginations
                      userlist={dataLength}
                      getPageSize={getPageSize}
                      page={nextPage}
                      pagesize={pagesize}
                      handlePageChnage={handlePageChnage}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {all_permission.includes("bid_detail_tab") ? (
            <>
              <div className="top_container">
                <div className="main_container ">
                  <div className="user_serachbox">
                    <div style={{ float: "left" }}>
                      <h3 className="tableHeading">
                        <img src={pageIcon} className="headingIcons" alt="" />{" "}
                        Bids
                      </h3>
                    </div>
                    <input
                      type="search"
                      className="tableSearch"
                      placeholder="Search..."
                      onChange={(e) => setSearchdata(e.target.value)}
                    />
                    {superUser === true ? (
                      <>
                        <button
                          className="cbtn addBtn"
                          onClick={() => {
                            navigate("/create-bid");
                          }}
                        >
                          <img src={addIcon} alt="" /> &nbsp; Create Bid
                        </button>
                      </>
                    ) : (
                      <>
                        {all_permission.includes("bid.bid_create") ? (
                          <>
                            <button
                              className="cbtn addBtn"
                              onClick={() => {
                                navigate("/create-bid");
                              }}
                            >
                              <img src={addIcon} alt="" /> &nbsp; Create Bid
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                  <hr />
                  <div className="mainContent mainContent2">
                    {dataLoader ? (
                      <SpinnerLoader />
                    ) : userdata.length === 0 ? (
                      <>
                        {" "}
                        <div style={{ textAlign: "center" }}>No data Found</div>
                      </>
                    ) : (
                      <>
                        <div className="table_container ">
                          <Table
                            bordered={false}
                            hover
                            size="sm"
                            className="customTable"
                          >
                            <thead className="thead">
                              <TableRow>
                                {headers.map((header) => {
                                  return header.label === "Email Subject" ? (
                                    <TableCell
                                      key={header.id}
                                      width="200"
                                      style={{ width: "200px" }}
                                    >
                                      {reverSign === 0 ? (
                                        <>
                                          {" "}
                                          <TableSortLabel
                                            active={false}
                                            direction={"asc"}
                                            onClick={() => {
                                              handlesort(header.id);
                                            }}
                                            IconComponent={ArrowDropUpIcon}
                                          >
                                            {header.label}
                                          </TableSortLabel>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <TableSortLabel
                                            active={false}
                                            direction={"asc"}
                                            onClick={() => {
                                              handlesort(header.id);
                                            }}
                                            IconComponent={ArrowDropDownIcon}
                                          >
                                            {header.label}
                                          </TableSortLabel>
                                        </>
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell key={header.id}>
                                      {reverSign === 0 ? (
                                        <>
                                          {" "}
                                          <TableSortLabel
                                            active={false}
                                            direction={"asc"}
                                            onClick={() => {
                                              handlesort(header.id);
                                            }}
                                            IconComponent={ArrowDropUpIcon}
                                          >
                                            {header.label}
                                          </TableSortLabel>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <TableSortLabel
                                            active={false}
                                            direction={"asc"}
                                            onClick={() => {
                                              handlesort(header.id);
                                            }}
                                            IconComponent={ArrowDropDownIcon}
                                          >
                                            {header.label}
                                          </TableSortLabel>
                                        </>
                                      )}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </thead>
                            <TableBody id="mailbox">
                              {userdata.map((e, i) => (
                                <>
                                  <TableRow key={e.id}>
                                    <TableCell>{e.bid_number}</TableCell>
                                    <TableCell>
                                      {e.client.client_contact_name}
                                    </TableCell>
                                    <TableCell>{e.project_name}</TableCell>
                                    <TableCell>
                                      {e.client.client_name}
                                    </TableCell>

                                    <TableCell>{e.bid_owner.name}</TableCell>
                                    <TableCell>
                                      {moment(
                                        e.user_status_updated.updated_date
                                      ).format("D/MM/YYYY, h:mm a")}
                                    </TableCell>
                                    <TableCell>{e.bid_status.name}</TableCell>
                                    <TableCell>
                                      <td>
                                        {superUser === true ? (
                                          <>
                                            <span
                                              className="actionButton"
                                              onClick={() => {
                                                navigate(`/update-bid/${e.id}`);
                                              }}
                                            >
                                              <img src={EditIcon} alt="" />
                                            </span>
                                            <span className="actionButton">
                                              <img alt="" />
                                            </span>
                                            <span className="actionButton"></span>
                                          </>
                                        ) : (
                                          <>
                                            {all_permission.includes(
                                              "bid.bid_update"
                                            ) ||
                                            all_permission.includes(
                                              "bid.bid_get"
                                            ) ? (
                                              <>
                                                <span
                                                  className="actionButton"
                                                  onClick={() => {
                                                    navigate(
                                                      `/update-bid/${e.id}`
                                                    );
                                                  }}
                                                >
                                                  <img src={EditIcon} alt="" />
                                                </span>
                                                <span className="actionButton">
                                                  <img alt="" />
                                                </span>
                                                <span className="actionButton"></span>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}{" "}
                                      </td>
                                    </TableCell>
                                  </TableRow>
                                </>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                        <Paginations
                          userlist={dataLength}
                          getPageSize={getPageSize}
                          page={nextPage}
                          pagesize={pagesize}
                          handlePageChnage={handlePageChnage}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Unauthorized />
            </>
          )}
        </>
      )}
    </>
  );
};
export default Bidsdetail;
