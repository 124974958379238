import RequireAuth from "./RequireAuth";
import PersistLogin from "./PersistLogin";
import { Routes, Route } from "react-router-dom";
import Login from "../auth/Login";
import Forgotpassword from "./ForgotPassword";
import UpdatePassword from "./UpdatePassword";
import Unauthorized from "./Unauthorized";
import Missing from "../Missing";
import Layout from "../Layout";
import Editor from "../BidsModule/Editor/Editor";
import SampleLayout from "../UI/SampleLayout";
import UserListing from "../AdminModule/Tables/UserListing";
import UpdateUser from "../AdminModule/User/UpdateUser";
import NewUser from "../AdminModule/User/NewUser.jsx";
import RolesListing from "../AdminModule/Tables/RolesListing";
import AddRole from "../AdminModule/Role/AddRole";
import UpdateRole from "../AdminModule/Role/UpdateRole";
import DepartmentListing from "../AdminModule/Tables/DepartmentListing";
import UpdateDepartment from "../AdminModule/Department/UpdateDepartment";
import NewDepartment from "../AdminModule/Department/NewDepartment";
import Clientcontact from "../BidsModule/BidsTable/Clientcontact";
import FinanceTable from "../BidsModule/BidsTable/Finance";
import ClientlistingTable from "../BidsModule/BidsTable/Clientlisting";
import Bidsdetail from "../BidsModule/BidsTable/Bidsdetail";
import CreateBid from "../BidsModule/CreateBid/CreateBid";
import AddClient from "../BidsModule/BidsTable/AddClient";
import AddFinanceDetails from "../BidsModule/BidsTable/AddFinanceDetails";
import AddUpdateFinanceDetails from "../BidsModule/BidsTable/AddUpdateFinance";
import AddClientContact from "../BidsModule/BidsTable/AddClientContact";
import UpdateClientContact from "../BidsModule/BidsTable/UpdateClientContact";
import EditBid from "../BidsModule/BidsTable/EditBid/EditBid";
import AddOpportunity from "../BidsModule/CreateBid/AddOpportunity";
import UpdateClient from "../BidsModule/BidsTable/UpdateClient";
import EditOpportunity from "../BidsModule/CreateBid/EditOpportunity";
import BidsDashboard from "../BidsModule/Dashboard/Dashboard";
import Compose from "../BidsModule/Mailbox/Compose";
import Mailbox from "../BidsModule/Mailbox/Mailbox";
import MailDescription from "../BidsModule/Mailbox/MailDescription";
import Permissions from "../PermissionsModule/Permissions";
import MailboxConfigList from "../BidsModule/Mailbox/MailboxConfig/MailboxConfigList";
import UpdateMailboxConfig from "../BidsModule/Mailbox/MailboxConfig/UpdateMailboxConfig";
import SaleReport from "../BidsModule/SaleReport/SaleReport";
import RegionListing from "../BidsModule/BidsTable/Region/RegionList";
import AddRegion from "../BidsModule/BidsTable/Region/AddRegion";
import UpdateRegion from "../BidsModule/BidsTable/Region/UpdateRegion";
import ResetPassword from "./ResetPassword";
import ChangeSignature from "./ChangeSignature";
import SurveyListing from "../CSModule/SurveyListing/SurveyListing";
import CommonLayout from "../CSModule/Commoncomponent";
import SupplierDet from "../SupplyModule/AddNewSupplier/Addnewsupplier";
import Supplyreq from "../SupplyModule/Supplyreq";
import Supplierdetail from "../SupplyModule/Supplierdetail";
import Surveydetail from "../SupplyModule/Supply";
import Projectquality from "../QualityModule/Projectquality";
import Projectqualityreport from "../QualityModule/Projectqualityreport";
import Demo from "../QualityModule/PopUpPage/Callpopup";
import Reconcillation from "../ReconcillationPage/Reconcillation";
import Samplewastage from "../QualityModule/Samplewastage";
import Reconsilitionupdate from "../ReconcillationPage/ReconcilitionUpdate";
import MyComponent from "../CSModule/Screening/Test";
import SettingHeader from "../SupplyModule/SupplierSetting/SettingHeader";
import ExclusionSetting from "../SupplyModule/SupplierSetting/ExclusionSetting";
import RedirectURL from "../SupplyModule/SupplierSetting/RedirectUrl";
import VariableMapping from "../SupplyModule/SupplierSetting/VariableMapping";
import Hashing from "../SupplyModule/SupplierSetting/Hashing";
import Editsupplyquality from "../SupplyModule/Editsupplierquality";
import Pricetype from "../SupplyModule/Popup/Pricetype";
import Error from "./Error";
import ShowQuestion from "../RespondentFrontEnd/RespondentPrescreening/ShowQuestion.jsx";
import Recaptcha from "../RespondentFrontEnd/Recaptcha/Recaptcha.jsx";
import Redhering from "../RespondentFrontEnd/Red_hearing/RedHearing.jsx";
import Qualification from "../RespondentFrontEnd/Qualification/Qualification.jsx";
import TopTenAccounts from "../BidsModule/BidReportfile/TopTenAccounts.jsx";
import BidVolume from "../BidsModule/BidReportfile/BidVolume.jsx";
import BidBookingAmount from "../BidsModule/BidReportfile/BidBookingAmount.jsx";

const Navigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<Forgotpassword />}></Route>
        <Route
          path="/reset-password/:token"
          element={<UpdatePassword />}
        ></Route>
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/qualification/:respid" element={<Qualification />} />
        {/* {/ {/ .................not done ...... /} /} */}
        <Route path="/top-ten-accounts" element={<TopTenAccounts />}></Route>
        <Route path="/bid-volume" element={<BidVolume />}></Route>

        <Route path="/bid-booking" element={<BidBookingAmount />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/sample" element={<SampleLayout />}></Route>
        <Route path="/edit" element={<Editor />}></Route>

        <Route path="/sale-report" element={<SaleReport />}></Route>

        {/* {/ we want to protect these routes /} */}
        <Route element={<PersistLogin />}>
          {/*  <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>> 
               <<<<<<<<<<<<<<----------Admin--------->>>>>>>>>>>>>>>>>   
               <<<<<<<<<<<<<<----------Role --------->>>>>>>>>>>>>>>>>   
               <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route element={<RequireAuth allowedRoles={["user.role_get_all"]} />}>
            <Route path="/roles" element={<RolesListing />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["user.role_update"]} />}>
            <Route path="/update-role/:id" element={<UpdateRole />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["user.role_create"]} />}>
            <Route path="/new-role" element={<AddRole />}></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<----------User---------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route element={<RequireAuth allowedRoles={["user.user_all"]} />}>
            <Route path="/users" element={<UserListing />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={["user.user_update"]} />}>
            <Route path="/update-user/:id" element={<UpdateUser />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["permission.permission_get"]} />
            }
          >
            <Route path="/permissions/:id" element={<Permissions />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["user.user_create"]} />}>
            <Route path="/new-user" element={<NewUser />}></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<--------Department------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["user.department_get"]} />}
          >
            <Route path="/department" element={<DepartmentListing />}></Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["user.department_create"]} />}
          >
            <Route path="/new-department" element={<NewDepartment />}></Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["user.department_update"]} />}
          >
            <Route
              path="/update-department/:id"
              element={<UpdateDepartment />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<-----MailboxConfig------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["mailbox_config_menu"]} />}
          >
            <Route
              path="/mailboxconfig"
              element={<MailboxConfigList />}
            ></Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["mailbox.mailbox_update"]} />}
            o
          >
            <Route
              path="/update-mailbox-config/:id"
              element={<UpdateMailboxConfig />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<---------Region--------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_add_region"]} />
            }
          >
            <Route path="/add-region/:id" element={<AddRegion />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_update_region"]} />
            }
          >
            <Route
              path="/update-region/:id/:regionid"
              element={<UpdateRegion />}
            ></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_region_list"]} />
            }
          >
            <Route path="/region/:id" element={<RegionListing />} />
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<-------Bid Details-------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route element={<RequireAuth allowedRoles={["bid.bid_get_list"]} />}>
            <Route path="/bid-details" element={<Bidsdetail />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["bid.bid_update", "bid.bid_get"]} />
            }
          >
            <Route path="/update-bid/:id" element={<EditBid />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["bid.bid_create"]} />}>
            <Route path="/create-bid/:id" element={<CreateBid />}></Route>
            <Route path="/create-bid" element={<CreateBid />}></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<--------Mailbox--------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["mailbox.mailbox_list"]} />}
          >
            <Route path="/mailbox" element={<Mailbox />}></Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["mailbox.mailbox_create"]} />}
          >
            <Route path="/compose" element={<Compose />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["mailbox.mailbox_message_desc"]} />
            }
          >
            <Route
              path="/message-detail/:id"
              element={<MailDescription />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<--------Dashboard------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["sales_dashboard_menu"]} />}
          >
            <Route path="/dashboard" element={<BidsDashboard />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["mailbox.mailbox_message_details"]} />
            }
          >
            <Route
              path="/message-detail/:id"
              element={<MailDescription />}
            ></Route>
          </Route>{" "}
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<---Change Signature----->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["user_change_signature"]} />}
          >
            <Route
              path="/change-signature"
              element={<ChangeSignature />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<-----AccountDetails----->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["client.client_list"]} />}
          >
            <Route path="/clients" element={<ClientlistingTable />}></Route>
          </Route>
          <Route element={<RequireAuth allowedRoles={["client.client_add"]} />}>
            <Route path="/add-client" element={<AddClient />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "client.client_update",
                  "client.client_get_list",
                ]}
              />
            }
          >
            <Route path="/update-client/:id" element={<UpdateClient />}></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<---------Finance-------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_finance_list"]} />
            }
          >
            <Route path="/finance/:id" element={<FinanceTable />}></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_add_finance"]} />
            }
          >
            <Route
              path="/add-finance-details/:id"
              element={<AddFinanceDetails />}
            ></Route>
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "client.client_update_finance",
                  "client.client_get_finance_list",
                ]}
              />
            }
          >
            <Route
              path="/update-finace/:client/:id"
              element={<AddUpdateFinanceDetails />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<-----client_contact----->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_contact_list"]} />
            }
          >
            <Route
              path="/client-contact/:id"
              element={<Clientcontact />}
            ></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_add_contact"]} />
            }
          >
            <Route
              path="/add-client-contact/:id"
              element={<AddClientContact />}
            ></Route>
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[
                  "client.client_update_contact",
                  "client.client_get_contact_list",
                ]}
              />
            }
          >
            <Route
              path="/update-client-contact/:id/:clientid"
              element={<UpdateClientContact />}
            ></Route>
          </Route>
          <Route
            element={
              <RequireAuth allowedRoles={["client.client_update_contact"]} />
            }
          >
            <Route
              path="/update-client-contact/:id/:clientid"
              element={<UpdateClientContact />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------Opportunity------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          <Route
            element={<RequireAuth allowedRoles={["bid.bid_add_opportunity"]} />}
          >
            <Route
              path="/add-opportunity/:id"
              element={<AddOpportunity />}
            ></Route>
          </Route>
          <Route
            element={<RequireAuth allowedRoles={["bid.bid_get_opportunity"]} />}
          >
            <Route
              path="/edit-opportunity/:bidid/:oppid"
              element={<EditOpportunity />}
            ></Route>
          </Route>
          {/* <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<-----Without Permission------->>>>>>>>>>>>>>>>>   
              <<<<<<<<<<<<<<------------------------>>>>>>>>>>>>>>>>>    */}
          {/* ........................ */}
          <Route path="test" element={<MyComponent />}></Route>
          <Route path="/demo" element={<Demo />}></Route>
          <Route path="/supplier" element={<SupplierDet />} />
          <Route
            path="/project-detail/:id/:layoutId/:survey_Id"
            element={<CommonLayout />}
          ></Route>
          <Route path="/supply-req-detail" element={<Supplyreq />}></Route>
          <Route path="/supply" element={<Surveydetail />}></Route>
          <Route path="/priceratecard/:id" element={<Pricetype />}></Route>
          <Route path="/supplier-details" element={<Supplierdetail />}></Route>
          <Route path="/project-quality" element={<Projectquality />}></Route>
          <Route path="/setting" element={<SettingHeader />}></Route>
          {/* Editsupplyquality */}
          <Route
            path="/edit_supplier/:id"
            element={<Editsupplyquality />}
          ></Route>
          <Route
            path="/exclusion-setting/:id"
            element={<ExclusionSetting />}
          ></Route>
          <Route path="/redirect-url/:id" element={<RedirectURL />}></Route>
          <Route
            path="/variable-mapping/:id"
            element={<VariableMapping />}
          ></Route>
          <Route path="/hashing/:id" element={<Hashing />}></Route>
          {/* <Route path="/SurveyTracking" element={<SurveyTracking />}></Route> */}
          <Route
            path="/project-quality-report"
            element={<Projectqualityreport />}
          ></Route>
          <Route
            path="/reconsilition-update"
            element={<Reconsilitionupdate />}
          ></Route>
          <Route path="/survey-listing" element={<SurveyListing />}></Route>
          <Route path="/sample-wastage" element={<Samplewastage />}></Route>
          <Route path="/reconciliation" element={<Reconcillation />}></Route>
        </Route>
        <Route path="/prescreening/:respondent_id" element={<ShowQuestion />} />
        <Route path="/redherring/:respondent_id" element={<Redhering />} />
        <Route path="/404" element={<Missing />} />
        <Route path="/500" element={<Error />} />
        <Route path="*" element={<Missing />} />
        <Route path="/screen-recaptcha/:id" element={<Recaptcha />}></Route>
      </Route>
    </Routes>
  );
};

export default Navigation;
