import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
const Editquota = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const notifySuccess = (e) =>
    toast.success(`Quota for ${e} updated successfully`);
  const [loder, setLoder] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [data, setData] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [questions, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [answersPer, setAnswersPer] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [all_data, setAll_data] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  useEffect(() => {
    getData();
    ANwerPer();
  }, []);
  const EditSubmit = (e) => {
    setSpinner(false);
    if (totalPercentage === 100) {
      const allocatedAnsCode = [];

      // Iterate over addQuotaValues array
      answersPer.forEach((item) => {
        // Push each item as an object to the allocatedAnsCode array
        allocatedAnsCode.push({
          ans_code: item.ans_code,
          perc: item.perc,
        });
      });
      // Define the URL endpoint for the POST request

      const requestBody = {
        qualify_id: props.single_qualif.quali_id,
        q_id: props.single_qualif.q_id,
        ans_code: answersPer.map((item) => item.ans_code),
        is_allocated: true,
        allocated_ans_code: allocatedAnsCode,
        is_all_selected: false,
        order_by: 4,
      };

      // Make the POST request using Axios
      axiosPrivate
        .patch(`qualification/update-qualification`, requestBody)

        .then((response) => {
          if (response.status === 200) {
            notifySuccess(props.addOrEditname);
            props.showEditquotapopup(false);
          }
          // Handle successful response
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        });
    }
    setSpinner(true);
  };
  const fetchAnswers = async (questionId, ansCode, perc) => {
    setLoder(false); // Assuming this is a typo, I changed setLoder to setLoader
    setAnswers([]);

    try {
      const response = await axiosPrivate.get(
        `qualification/get-answer-list/${props.survey_ID}/${questionId}`
      );

      setAnswers(response.data.answer);
      const questionAns = response.data.answer.find(
        (q) => q.ans_code === ansCode
      );

      return {
        answerTitle: questionAns.answer_title.title,
        answ_Code: questionAns.ans_code,
        perc: perc,
      };
      // return questionAns;
    } catch (error) {
      console.error("Error fetching data:", error);
      console.error(
        `Error fetching answers for question ${questionId}:`,
        error
      );
    }

    setLoder(true);
  };

  const getQuestionList = async (id) => {
    try {
      const response = await axiosPrivate.get(
        `qualification/get-question-list/${props.survey_ID}`
      );
      const question = response.data.question.find((q) => q.q_code === id);
      return question ? question.q_name : null;
    } catch (error) {
      console.error("Error fetching question:", error);
      return null;
    }
  };
  const ANwerPer = async () => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `qualification/get-general-qualification/${props.single_qualif.quali_id}`
      );

      if (response.status === 200) {
        setData(response.data.qualification.allocated_ans_code);
        setAnswersPer([]);
        response.data.qualification.allocated_ans_code.map((e) => {
          setAnswersPer((prevState) => [
            ...prevState,
            { ans_code: e.ans_code, perc: e.perc },
          ]);
          let sum = 0;
          response.data.qualification.allocated_ans_code.forEach((item) => {
            // Check if the value is a valid number
            if (!isNaN(parseFloat(item.perc))) {
              sum += parseFloat(item.perc);
            }
          });

          // Set total sum
          setTotalPercentage(sum);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoder(true);
  };
  const getData = async () => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `qualification/get-general-qualification/${props.single_qualif.quali_id}`
      );

      if (response.status === 200) {
        setData(response.data.qualification.allocated_ans_code);

        const q_name = await getQuestionList(response.data.qualification.q_id);
        const answerDetailsPromises =
          response.data.qualification.allocated_ans_code.map(
            async ({ ans_code, perc }) => {
              return await fetchAnswers(
                response.data.qualification.q_id,
                ans_code,
                perc
              );
            }
          );

        Promise.all(answerDetailsPromises).then((answerDetails) => {
          setAll_data((prevData) => ({
            ...prevData,
            is_all_selected: response.data.qualification.is_all_selected,
            is_allocated: response.data.qualification.is_allocated,
            order_by: response.data.qualification.order_by,
            q_id: response.data.qualification.q_id,
            q_name: q_name,
            allocated_ans_code: answerDetails, // Set allocated_ans_code to the array of fetched answer details
          }));
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoder(true);
  };

  const handleCloseUserMenu = () => {
    props.showEditquotapopup(false);
  };

  const handleInputChange = (event, index, ansCode) => {
    const newValue = parseFloat(event.target.value);

    if (
      parseFloat(event.target.value) < 0 ||
      parseFloat(event.target.value) > 100
    ) {
      event.target.value = "";
    } else {
      let result = [...answersPer]; //<- copy roomRent into result

      result = result.map((x) => {
        if (x.ans_code === ansCode) {
          x.perc = event.target.value;

          return x;
        } else {
          return x;
        }
      });

      setAnswersPer(result);

      let sum = 0;
      result.forEach((item) => {
        if (!isNaN(parseFloat(item.perc))) {
          sum += parseFloat(item.perc);
        }
      });

      setTotalPercentage(sum);
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseUserMenu}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "3px" }}
          >
            Edit Quota - {props.addOrEditname}
          </Modal.Title>
        </Modal.Header>
        {loder && all_data?.allocated_ans_code ? (
          <>
            <form onSubmit={handleSubmit(EditSubmit)}>
              <Modal.Body>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6}></Col>
                  <Col lg={6} md={6} sm={6} xs={6} className="col">
                    <label className="formlabel">COMPLETES</label>
                  </Col>
                </Row>

                {all_data.allocated_ans_code.map((ansItem, answIndex) => {
                  return (
                    <Row key={ansItem.answ_Code}>
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="col flex items-center"
                      >
                        <label className="formlabel m-0">
                          {ansItem.answerTitle}
                        </label>

                        <input
                          placeholder={ansItem.answerTitle}
                          type="text"
                          className="CsformText"
                          name={ansItem.answerTitle}
                          id={ansItem.answerTitle}
                          value={answersPer[answIndex].perc}
                          onChange={(e) =>
                            handleInputChange(e, answIndex, ansItem.answ_Code)
                          }
                        />

                        {errors.Name && (
                          <small className="errmsg perrmsg">
                            {errors.Name.message}
                          </small>
                        )}
                      </Col>
                    </Row>
                  );
                })}
                {totalPercentage !== 100 && (
                  <div className="error-message errmsg">
                    Total percentage must be 100%
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <button
                  className="cbtn closeBtn pbtn m-0 me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    props.showEditquotapopup(false);
                  }}
                >
                  Close
                </button>
                <button className="cbtn saveBtn m-0" type="submit">
                  {spinner && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Submit
                </button>
              </Modal.Footer>
            </form>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Modal>
    </>
  );
};

export default Editquota;
