import Header from '../../UI/Header/Header';
import '../Department/Update_department.css';
import { useNavigate, useLocation } from 'react-router';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import userIco from '../../../assets/LoginIcons/users.svg';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../UI/Spinner';

const UpdateRole = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [editName, setEditNamae] = useState(); //
    const [all_departments, setAll_department] = useState([]);
    const [edit_department, setEdit_department] = useState();
    const [descriptions, setDescriptions] = useState(); //
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loder, setLoder] = useState(false);

    useEffect(() => {
        getUser();
        findDepartment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {}, [setEditNamae]);

    const getUser = async () => {
        let isMounted = true;
        try {
            const response = await axiosPrivate.get(`/role/${id}`, {});
            isMounted && setEditNamae(response.data.role.name);
            setDescriptions(response.data.role.description);
            setEdit_department(response.data.role.dept_id);
            setLoder(true);
        } catch (err) {
            if (err.response.status === 404) {
                navigate('/404');
            }
        }
        return () => {
            isMounted = false;
        };
    };

    const handleSaveButton = async (e) => {
        setIsSubmitting(true);
        try {
            await axiosPrivate
                .patch(
                    '/role/update-role',
                    JSON.stringify({
                        name: e.RoleName,
                        description: e.Description,
                        dept_id: e.Department,
                        role_id: id,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((e) => {
                    if (e.statusText === 'OK') {
                        localStorage.setItem('updaterole', '1');
                        navigate('/roles');
                    } else {
                    }
                });
        } catch (err) {
            notifyerror(err.response.statusText);
        }
        setIsSubmitting(false);
    };
    const notifyerror = (e) => toast.error(`${e} Error occured`);

    const findDepartment = async () => {
        let isMounted = true;
        try {
            const response = await axiosPrivate.get('/department/all-departments', {});
            isMounted && setAll_department(response.data.all_departments);
        } catch (err) {
            console.error(err);
            navigate('/login', { state: { from: location }, replace: true });
        }
        return () => {
            isMounted = false;
        };
    };

    return (
        <>
            <div className="users"></div>
            {/* <Header /> */}
            <div className="top_container">
                <div className="main_container ">
                    <form onSubmit={handleSubmit(handleSaveButton)}>
                        <div className="user_serachbox">
                            <div style={{ float: 'left' }}>
                                <h3 className="tableHeading">
                                    <img src={userIco} className="headingIcons" alt="Update" /> Update {editName}
                                </h3>
                            </div>
                            <div className="right-pane">
                                <button
                                    className="cbtn closeBtn"
                                    onClick={() => {
                                        navigate('/roles');
                                    }}
                                >
                                    {' '}
                                    Close
                                </button>

                                <button
                                    className="cbtn saveBtn"
                                    onClick={() => {
                                        handleSaveButton();
                                    }}
                                >
                                    {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                    &nbsp; Save
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="mainContent">
                            <p className="small note">* denotes required fields</p>

                            {loder ? (
                                <>
                                    {' '}
                                    <Row>
                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Role Name*</label>
                                            <input
                                                type="text"
                                                placeholder="Role Name"
                                                className="formtext"
                                                {...register('RoleName', {
                                                    required: 'This field is Required',
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ,.-]*$/,
                                                        message: 'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={editName}
                                            />
                                            {errors.RoleName && <small className="errmsg">{errors.RoleName.message}</small>}
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Department*</label>

                                            <select
                                                className="formtext"
                                                {...register('Department', {
                                                    required: 'This field is Required',
                                                })}
                                                defaultValue={edit_department}
                                            >
                                                {all_departments.map((e) => (
                                                    <option value={e._id}>{e.name}</option>
                                                ))}
                                            </select>
                                            {errors.Department && <small className="errmsg">{errors.Department.message}</small>}
                                        </Col>
                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Descriptions*</label>
                                            <input
                                                type="text"
                                                className="formtext"
                                                placeholder="Enter Descriptions"
                                                {...register('Description', {
                                                    required: 'This field is Required',
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                                        message: 'Use Only String',
                                                    },
                                                })}
                                                defaultValue={descriptions}
                                            />
                                            {errors.Description && <small className="errmsg">{errors.Description.message}</small>}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </>
                            ) : (
                                <SpinnerLoader />
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default UpdateRole;
