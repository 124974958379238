import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Select from "react-select";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../Spinner";
import { useEffect } from "react";
import { useState } from "react";
import { ModalBody } from "react-bootstrap";
function ViewAllNotifiaction(props) {
  const axiosPrivate = useAxiosPrivate();
  const [projDetail, setProjDetail] = useState([]);
  const [surveyDetail, setSurveyDetail] = useState([]);
  const [loder, setLoder] = useState(false);

  const [getNotificationData_Second, setGetNotificationData_Second] = useState(
    []
  );

  useEffect(() => {
    getNotificationDataApi_second();
    props.handleClose(true);
  }, []);

  const accesstoken = "Bearer " + localStorage.getItem("atoken");
  const getNotificationDataApi_second = async () => {
    setLoder(true);
    try {
      const response = await axiosPrivate.get(`notification/get-followup`, {
        headers: {
          "Content-Type": "application/json",
          "access-token": accesstoken,
        },
      });

      setGetNotificationData_Second(response.data.notification);
    } catch (error) {}
    setLoder(false);
  };

  // Function to wrap specified words with span elements with corresponding CSS classes
  const getHighlightedText = (text) => {
    const wordsToHighlight = {
      Live: "live",
      Pending: "pending",
      "Test Mode": "testmode",
      Paused: "Paused",
      "Id Awaited": "IdAwaited",
      Billed: "billed",
      Cancelled: "cancelled",
    };

    const regex = new RegExp(
      `(${Object.keys(wordsToHighlight).join("|")})`,
      "gi"
    );
    const parts = text.split(regex);

    return parts.map((part, index) => {
      const lowerPart = part;

      if (wordsToHighlight[lowerPart]) {
        return (
          <span key={index} className={wordsToHighlight[lowerPart]}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          All Notification
        </Modal.Title>
      </Modal.Header>
      {loder === false ? (
        <>
          <ModalBody
            style={{
              overflow: "scroll",
              "overflow-y": "scroll",
              height: "450px",
            }}
          >
            <>
              <Row>
                {getNotificationData_Second.map((item, index) => (
                  <>
                    <Col lg={4} md={6} sm={6} xs={12} className="csmulti">
                      <div className="ViewAllNotification_div">
                        <p>Project Id:-{item.project_id}</p>
                        <p>Survey Id:-{item.survey_id}</p>

                        <p>
                          {getHighlightedText(
                            item.message,
                            item.survey_status.name
                          )}
                        </p>
                      </div>
                    </Col>
                  </>
                ))}
              </Row>
            </>
          </ModalBody>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </Modal>
  );
}
export default ViewAllNotifiaction;
