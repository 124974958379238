import React from 'react';
import './Login.css';
import Spinner from 'react-bootstrap/Spinner';
import samplicitytagline from '../../assets/SamplicityBlackLogo.svg';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import toast, { Toaster } from 'react-hot-toast';
import 'react-confirm-alert/src/react-confirm-alert.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
const Forgotpassword = () => {
    let navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const axiosPrivate = useAxiosPrivate();

    const handleForgetEmail = async (enter) => {
        setIsSubmitting(true);
        const payload = {
            email: enter.email,
        };
        try {
            await axiosPrivate
                .post('/user/forgot-password', payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    if (e.status === 200) {
                        notifysuccess();
                    } else {
                    }
                });
        } catch (err) {
            notifyerror(err.message);
        }
        setIsSubmitting(false);
    };
    const notifysuccess = (e) => toast.success('Check Your Mail');
    const notifyerror = (e) => toast.error(`${e} Error occured`);

    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{ top: 30 }}
                toastOptions={{
                    className: '',
                    duration: 2000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}
            />
            <div className="login_container">
                <div>
                    <img className="login_torfacimg" alt="" />
                </div>
                <div>
                    <img className="login_samplicity_logo" src={samplicitytagline} alt="samplicity" />
                </div>

                <div className="login_form">
                    <>
                        <p className="msg">Enter your email and instructions will be sent to you!</p>
                        <form onSubmit={handleSubmit(handleForgetEmail)}>
                            <Col>
                                <input
                                    className="forgot_email input-text"
                                    placeholder="Enter Email"
                                    type="text"
                                    {...register('email', {
                                        required: 'This field is Required',
                                        pattern: {
                                            value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, //eslint-disable-line
                                            message: 'Enter Valid Email',
                                        },
                                    })}
                                />
                                {errors.email && <small className="errmsg">{errors.email.message}</small>}
                            </Col>

                            <button disabled={isSubmitting} id="loginbutton">
                                {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                &nbsp;RESET
                            </button>
                        </form>
                        <p
                            className="login_forget_text"
                            onClick={() => {
                                navigate('/login');
                            }}
                        >
                            Back to Login
                        </p>
                    </>
                </div>
            </div>
        </>
    );
};
export default Forgotpassword;
