import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../UI/Spinner";
function SupplierLink(props) {
  const id = props.childId;
  const axiosPrivate = useAxiosPrivate();
  const [allLink, setAllLink] = useState([]);
  const [loder, setLoder] = useState(false);

  useEffect(() => {
    if (id == undefined) {
    } else {
      getSurveyLink();
    }
  }, [id]);

  const getSurveyLink = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `survey-supplier/get-entry-link-survey-supplier?survey_id=${id}`
      );
      setAllLink(response.data.survey_supplier);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "12px" }}
        >
          <i className="bi bi-person-plus"></i>
          Supplier Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          margin: "0px 0px 0px 10px",
        }}
      >
        {/* {loder ? (
          <>
            {allLink.map((e) => (
              <>
                <Row>
                  <Col lg={2} md={7} sm={12} xs={12}>
                    <label
                      className="formlabelviewzone"
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      {e.name}
                    </label>
                  </Col>
                  <Col lg={10} md={7} sm={12} xs={12}>
                    <h6 className="quta_link_text">{e.link}</h6>
                  </Col>
                </Row>
              </>
            ))}
          </>
        ) : (
          <>
            <SpinnerLoader />
          </>
        )} */}
        {loder ? (
          allLink.length !== 0 ? (
            <>
              {allLink.map((e) => (
                <>
                  <Row>
                    <Col lg={2} md={7} sm={12} xs={12}>
                      <label
                        className="formlabelviewzone"
                        style={{
                          fontWeight: "bolder",
                        }}
                      >
                        {e.name}
                      </label>
                    </Col>
                    <Col lg={10} md={7} sm={12} xs={12}>
                      <h6 className="quta_link_text">{e.link}</h6>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <div
                className="alert alert-danger center-margin-container"
                role="alert"
              >
                No data Found!
              </div>
            </>
          )
        ) : (
          <SpinnerLoader />
        )}
      </Modal.Body>
    </Modal>
  );
}
export default SupplierLink;
