import React, { useState, useEffect } from 'react';
import SupplierLinkModal from './SupplierLinkModal';
import ClientLinkModal from './ClientLinkModal';
import SurveyLogModal from './SurveyLogModal';
import { BiUserPlus, BiPurchaseTag, BiEditAlt } from 'react-icons/bi';
import { IoEyeOutline, IoLink } from 'react-icons/io5';
import { CgDollar } from 'react-icons/cg';
import { MdOutlineSettings, MdUpdate } from 'react-icons/md';
import { GoFileSymlinkFile } from 'react-icons/go';
import { IoIosLink } from 'react-icons/io';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import SpinnerLoader from '../../UI/Spinner';
import SurveyInnerModal from './SurveyInnerModal';
import { useNavigate } from 'react-router';
import MyVerticallyCenteredModal from '../SupplyInfo/PopUp/APRequest';

const SurveyInnerTable = (props) => {
    const id = props.projId;
    let project_status;
    let userString;
    let project_type_string;
    let project_manage_string;
    const [tableData, setTableData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    const [loder, setLoder] = useState(false);
    const [surveyInnerModal, setSurveyInnerModal] = useState(false);
    const [supplierLink, setSupplierLink] = useState(false);
    const [clientLink, setClientLink] = useState(false);
    const [surveyLogModal, setSurveyLogModal] = useState(false);
    const [clientId, setClientID] = useState();
    const [supplierId, setSupplierId] = useState();
    const [survey_Id, setSuuvey_id] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        if (id != '') {
            get_ProjectList_api();
        }
    }, [id]);

    const get_ProjectList_api = async () => {
        setLoder(false);
        if (id != '') {
            if (props.clientNameValue.length !== 0) {
                const data = props.clientNameValue.map((prop) => prop.value);
                userString = `${data.join(',')}`;
            } else {
                userString = '';
            }
            if (props.projectTypeValue.length !== 0) {
                const project_type = props.projectTypeValue.map(
                    (prop) => prop.value
                );
                project_type_string = `${project_type.join(',')}`;
            } else {
                project_type_string = '';
            }
            if (props.projectManagerValue.length !== 0) {
                const project_manag = props.projectManagerValue.map(
                    (prop) => prop.value
                );
                project_manage_string = `${project_manag.join(',')}`;
            } else {
                project_manage_string = '';
            }
            if (props.projectStatusValue.length !== 0) {
                const project_manag = props.projectStatusValue.map(
                    (prop) => prop.value
                );
                project_status = `${project_manag.join(',')}`;
            } else {
                project_status = '';
            }
            try {
                const response = await axiosPrivate.get(
                    `survey/get-ops-survey-project?project_id=${id}&query_filter=start_date%3D${props.selectedDate_start}%26end_date%3D${props.selectedDate_end}%26client_id%3D${userString}%26project_type%3D${project_type_string}%26project_manager%3D${project_manage_string}%26project_status%3D${project_status}%26search%3D${props.search_name}%26filter_by%3D${props.label_num}%26survey_filter_by%3D${props.selected_filter}`
                );
                setTableData(response.data.survey);
                setLoder(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        setLoder(true);
    };

    return (
        <>
            {surveyInnerModal ? (
                <>
                    <SurveyInnerModal
                        show={surveyInnerModal}
                        surveyInnerModal={surveyInnerModal}
                        survey_Id={survey_Id}
                        setSurveyInnerModal={setSurveyInnerModal}
                        onHide={() => setSurveyInnerModal(false)}
                        tableData={tableData}
                        setTableData={setTableData}
                        props={props}
                    />
                </>
            ) : (
                <></>
            )}

            {supplierLink ? (
                <>
                    <SupplierLinkModal
                        show={supplierLink}
                        onHide={() => setSupplierLink(false)}
                        supplierId={supplierId}
                    />
                </>
            ) : (
                <></>
            )}

            {clientLink ? (
                <>
                    <ClientLinkModal
                        show={clientLink}
                        onHide={() => setClientLink(false)}
                        clientId={clientId}
                    />
                </>
            ) : (
                <></>
            )}

            {surveyLogModal ? (
                <>
                    <SurveyLogModal
                        show={surveyLogModal}
                        survey_Id={survey_Id}
                        onHide={() => setSurveyLogModal(false)}
                    />
                </>
            ) : (
                <></>
            )}

            {modalShow ? (
                <>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        setModalShow={setModalShow}
                        projectId={id}
                    />
                </>
            ) : (
                <></>
            )}

            {loder ? (
                <>
                    <table className="bgc">
                        {tableData.length == 0 ? (
                            <>
                                <div
                                    class="alert alert-danger"
                                    style={{
                                        textAlign: 'center',
                                        marginBottom: '0px',
                                        marginLeft: '-42px',
                                    }}
                                    role="alert"
                                >
                                    No Survey Found!
                                </div>
                            </>
                        ) : (
                            <>
                                {tableData.map((items, index) => (
                                    <>
                                        <tr className="bdr">
                                            <td
                                                colspan="1"
                                                className="inner-border-right"
                                            >
                                                <div className="flex flex-direction flex-gap">
                                                    <small
                                                        className="pointer"
                                                        // style={{ fontSize: "13px" }}
                                                    >
                                                        {items.sc_id}
                                                    </small>
                                                    <small
                                                        className="pointer"
                                                        onClick={() => {
                                                            navigate(
                                                                `/project-detail/${id}/1/${items._id}`
                                                            );
                                                        }}
                                                    >
                                                        {items.survey_name}
                                                    </small>
                                                    <span>
                                                        {tableData ? (
                                                            <>
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Live' ? (
                                                                    <>
                                                                        <span className="live">
                                                                            Live
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Pending' ? (
                                                                    <>
                                                                        <span className="pending">
                                                                            Pending
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Test Mode' ? (
                                                                    <>
                                                                        <span className="testmode">
                                                                            Test
                                                                            Mode
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Paused' ? (
                                                                    <>
                                                                        <span className="Paused">
                                                                            Paused
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Id Awaited' ? (
                                                                    <>
                                                                        <span className="IdAwaited">
                                                                            Id
                                                                            Awaited
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Billed' ? (
                                                                    <>
                                                                        <span className="billed">
                                                                            Billed
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {items
                                                                    .survey_status
                                                                    .name ===
                                                                'Cancelled' ? (
                                                                    <>
                                                                        <span className="cancelled">
                                                                            Cancelled
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </span>
                                                </div>

                                                <div className="inner-icons show-icons">
                                                    <div className="flex items-center justify-evenly">
                                                        <BiEditAlt
                                                            onClick={() => {
                                                                navigate(
                                                                    `/project-detail/${id}/1/${items._id}`
                                                                );
                                                            }}
                                                            className="fs pointer"
                                                            title="Survey Edit"
                                                        />
                                                        <BiUserPlus
                                                            className="fs pointer"
                                                            title="Assign Supplier"
                                                            onClick={() => {
                                                                setModalShow(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                        <GoFileSymlinkFile
                                                            onClick={() => {
                                                                navigate(
                                                                    `/project-detail/${id}/3/${items._id}`
                                                                );
                                                            }}
                                                            className="fs pointer"
                                                            title="Survey Tracking"
                                                        />
                                                        <CgDollar
                                                            onClick={() => {
                                                                navigate(
                                                                    `/project-detail/${id}/5/${items._id}`
                                                                );
                                                            }}
                                                            className="fs pointer"
                                                            title="Invoicing"
                                                        />
                                                        <IoIosLink
                                                            className="fs pointer"
                                                            title="Supplier Link"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setSupplierLink(
                                                                    true
                                                                );
                                                                setSupplierId(
                                                                    items._id
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex items-center justify-evenly">
                                                        <IoLink
                                                            className="fs pointer"
                                                            title="Client Link"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setClientLink(
                                                                    true
                                                                );
                                                                setClientID(
                                                                    items._id
                                                                );
                                                            }}
                                                        />

                                                        <IoEyeOutline
                                                            onClick={() => {
                                                                navigate(
                                                                    `/project-detail/${id}/2/${items._id}`
                                                                );
                                                            }}
                                                            className="fs pointer"
                                                            title="Prescreening"
                                                        />
                                                        <MdOutlineSettings
                                                            onClick={() => {
                                                                navigate(
                                                                    `/project-detail/${id}/8/${items._id}`
                                                                );
                                                            }}
                                                            className="fs pointer"
                                                            title="Target Audience"
                                                        />
                                                        <MdUpdate
                                                            className="fs pointer mdupdate-size"
                                                            onClick={() => {
                                                                setSuuvey_id(
                                                                    items._id
                                                                );
                                                                setSurveyInnerModal(
                                                                    true
                                                                );
                                                            }}
                                                            title="Survey Tracker"
                                                        />
                                                        <BiPurchaseTag
                                                            className="fs pointer"
                                                            title="Log"
                                                            onClick={() => {
                                                                setSurveyLogModal(
                                                                    true
                                                                );
                                                                setSuuvey_id(
                                                                    items._id
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td colspan="13" className="pbzero">
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <th title="Starts">
                                                                ST
                                                            </th>
                                                            <th title="Reached Client Side">
                                                                RCS
                                                            </th>
                                                            <th title="Return">
                                                                RT
                                                            </th>
                                                            <th title="Abandon">
                                                                AB
                                                            </th>
                                                            <th title="Complete">
                                                                CO
                                                            </th>
                                                            <th title="Terminate">
                                                                TE
                                                            </th>
                                                            <th title="Quotafull">
                                                                QF
                                                            </th>
                                                            <th title="Quality Terminate">
                                                                QT
                                                            </th>
                                                            <th title="Speeder">
                                                                SP
                                                            </th>
                                                            <th title="Country Check">
                                                                IP
                                                            </th>
                                                            <th title="Wise Check">
                                                                WC
                                                            </th>
                                                            <th title="Abandon/Terminate/Quotafull">
                                                                SC AB/TE/QF-
                                                            </th>
                                                        </tr>

                                                        <tr>
                                                            <td>{items.st}</td>
                                                            <td>{items.rcs}</td>
                                                            <td>{items.rt}</td>
                                                            <td>{items.ab}</td>
                                                            <td>{items.co}</td>
                                                            <td>{items.te}</td>
                                                            <td>{items.qf}</td>
                                                            <td>{items.qt}</td>
                                                            <td>{items.sp}</td>
                                                            <td>{items.ip}</td>
                                                            <td>{items.wc}</td>
                                                            <td>
                                                                {
                                                                    items.sc_ab_te_qf
                                                                }
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th title="Abandon%">
                                                                AB%
                                                            </th>
                                                            <th title="Quotafull%">
                                                                QF%
                                                            </th>
                                                            <th title="Starts in Last 24 Hours">
                                                                L24S
                                                            </th>
                                                            <th title="Completes in Last 24 Hours">
                                                                L24C
                                                            </th>
                                                            <th title="Last 24 Hours Conversion">
                                                                CO24%
                                                            </th>
                                                            <th>LOI</th>
                                                            <th>CPI</th>
                                                            <th title="Required Completes">
                                                                N
                                                            </th>
                                                            <th>IR%</th>
                                                            <th title="Conversion %">
                                                                CO%
                                                            </th>
                                                            <th title="Margin">
                                                                MR
                                                            </th>
                                                            <th></th>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                {items.ab_perc}
                                                            </td>
                                                            <td>
                                                                {items.qf_perc}
                                                            </td>
                                                            <td>
                                                                {items.l24s}
                                                            </td>
                                                            <td>
                                                                {items.l24c}
                                                            </td>
                                                            <td>0</td>
                                                            <td>{items.loi}</td>
                                                            <td>{items.cpi}</td>
                                                            <td>{items.n}</td>
                                                            <td>
                                                                {items.ir_perc}
                                                            </td>
                                                            <td>
                                                                {items.oco_perc}
                                                            </td>
                                                            <td>
                                                                {items.margin}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </>
                        )}
                    </table>
                </>
            ) : (
                <SpinnerLoader />
            )}
        </>
    );
};

export default SurveyInnerTable;
