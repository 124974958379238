import "../../ProjectDetail/ProjectDetail.css";
import { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SetupSupplier from "./SetupSupplier";
import { useState } from "react";
import MyVerticallyCenteredModal from "./APRequest";
import SupplierLink from "./SupplierLink ";
import { FiUserPlus } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../UI/Spinner";
import SupplyInfoChild from "./SupplyInfoChild";
import { useParams } from "react-router";
import linkIcon from "../../../../assets/CSicons/icons/link.svg";
import AddDiysupplier from "./AddDiysupplier";
import { useNavigate } from "react-router";

const SupplyInfo = (props) => {
  const { id, layoutId, survey_Id } = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [supplierLink, setSupplierLink] = useState(false);
  const [loder, setLoder] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [supplierInfo, setSUpplierInfo] = useState([]);
  const [supplyInfoChildId, setSupplierInfoChildId] = useState("");
  const [supplierId, setSupplierId] = useState();
  const [activeSections, setActiveSections] = useState([]);
  const [refresh_api, setRefresh_api] = useState(false);
  const [childId, setChildData] = useState();
  const [addmaule, setAddmanule] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const handleShowSect = (eid) => {
    let activeRow = document.getElementById(eid);
    document
      .querySelectorAll(".dropeTablesupply")
      .forEach((el) => el.classList.remove("open"));
    if (activeRow.classList.contains("active")) {
      activeRow.classList.remove("active");
    } else {
      document
        .querySelectorAll(".mainrow")
        .forEach((el) => el.classList.remove("active"));
      activeRow.classList.add("active");
      document.querySelector("." + eid).classList.add("open");
    }
    const index = activeSections.indexOf(eid);
    if (index === -1) {
      setActiveSections([eid]);
    } else {
      activeSections.splice(index, 1);
      setActiveSections([activeSections]);
    }
  };

  useEffect(() => {
    if (survey_Id !== "1") {
      if (loder === true) {
        handleShowSect(`iconSectm${survey_Id}`);
        setSupplierInfoChildId(survey_Id);
      }
    } else if (survey_Id === "1") {
      if (loder === true) {
        handleShowSect(`iconSectm${supplierInfo[0].id}`);
        setSupplierInfoChildId(supplierInfo[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loder]);

  useEffect(() => {
    get_SupplierList_api();
  }, []);
  useEffect(() => {
    if (props.idFromFilter || refresh_api === true) {
      get_SupplierList_api();
      setRefresh_api(false);
    }
  }, [props.idFromFilter, refresh_api]);

  const get_SupplierList_api = async () => {
    setLoder(false);
    var temp;
    if (props.idFromFilter) {
      temp = props.idFromFilter;
    } else {
      temp = id;
    }
    try {
      const response = await axiosPrivate.get(`survey/get-survey-name/${temp}`);
      setSUpplierInfo(response.data.survey);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };
  return (
    <>
      {supplierLink ? (
        <>
          <SupplierLink
            show={supplierLink}
            onHide={() => setSupplierLink(false)}
            childId={childId}
          />
        </>
      ) : (
        <></>
      )}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        projectId={id}
      />
      {addmaule ? (
        <>
          <AddDiysupplier
            show={addmaule}
            supplierId={supplierId}
            onHide={() => setAddmanule(false)}
            setAddmanule={setAddmanule}
            setRefresh_api={setRefresh_api}
            setOpenPopup={setOpenPopup}
          />
        </>
      ) : (
        <></>
      )}

      <div className="saveclsebtn supply_btn">
        <button
          className="cbtn saveBtn pbtn"
          type="submit"
          onClick={() => setModalShow(true)}
        >
          + Add Partner Req.
        </button>
      </div>
      <hr className="csHR" />
      {loder ? (
        <>
          {supplierInfo.length == 0 ? (
            <>
              <div
                className="alert alert-danger"
                style={{ textAlign: "center", margin: "15px" }}
                role="alert"
              >
                No survey Found!
              </div>
            </>
          ) : (
            <>
              <div
                className="Sfirstbox"
                style={{ boxShadow: "0 0 0 1px #2684ff00" }}
              >
                {supplierInfo.map((e, index) => (
                  <>
                    <div
                      className="wrapperBelow"
                      style={{ paddingBottom: "5px" }}
                    >
                      <div className="supplyinforelatin">
                        <div
                          className="SArrowHeead mainrow"
                          id={`iconSectm${e.id}`}
                          onClick={() => {
                            setSupplierInfoChildId(e.id);
                            handleShowSect(`iconSectm${e.id}`);
                            navigate(
                              `/project-detail/${id}/${layoutId}/${e.id}`
                            );
                          }}
                        >
                          <p className="supply_country">
                            {e.survey_name}---{e.sc_id}
                          </p>

                          <div className="right-area">
                            <a
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setSupplierLink(true);
                                setChildData(e.id);
                              }}
                            >
                              <img
                                src={linkIcon}
                                alt="linkIcon"
                                srcset=""
                                className="SupplyIcon"
                              />
                              <p className="supply_country m-0 p-0">
                                {"Survey Entry Link"}
                              </p>
                            </a>
                            <a
                              onClick={(event) => {
                                setSupplierId(e.id);
                                event.preventDefault();
                                event.stopPropagation();
                                setAddmanule(true);
                                // setChildData(e.id);
                              }}
                            >
                              <img
                                src={linkIcon}
                                alt="linkIcon"
                                srcset=""
                                className="SupplyIcon"
                              />
                              <p className="supply_country m-0 p-0">
                                {"DIY Supplier"}
                              </p>
                            </a>
                            <KeyboardArrowUpIcon
                              className={`SIconUpnew icon-${e.id}-down`}
                              style={{
                                display: activeSections.includes(
                                  `iconSectm${e.id}`
                                )
                                  ? "block"
                                  : "none",
                              }}
                            />
                            <KeyboardArrowDownIcon
                              className={`SDropIconNew  icon-${e.id}-up`}
                              style={{
                                display: activeSections.includes(
                                  `iconSectm${e.id}`
                                )
                                  ? "none"
                                  : "block",
                              }}
                            />
                          </div>
                        </div>
                        <div className={`dropeTablesupply iconSectm${e.id}`}>
                          {supplyInfoChildId ? (
                            <>
                              {" "}
                              <SupplyInfoChild
                                supplyInfoChildId={supplyInfoChildId}
                                idFromFilter={props.idFromFilter}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <SpinnerLoader />
        </>
      )}
    </>
  );
};

export default SupplyInfo;
