import { useState, useEffect } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Spinner } from "react-bootstrap";
import "./AgeOption.css";

import { useForm, useFieldArray, Controller } from "react-hook-form";
import { confirmAlert } from "react-confirm-alert";

import { CloseButton } from "react-bootstrap";

import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
const AgeOption = (props) => {
  const axiosPrivate = useAxiosPrivate();

  const [ageOption, setAgeOption] = useState("");
  const notifySuccess = (e) => toast.success(`Age added successfully`);
  const [spinnerLoder, setSpinnerLoder] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  var flag = false;
  var cflag = false;

  const ageData = props.ageQual_data[1]
    ? props.ageQual_data[1].ansCode
    : props.ageQual_data[0].ansCode;

  const {
    register,
    getValues,
    control,
    handleSubmit,
    reset,
    trigger,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ageRange: ageData.map((e) => ({
        min: e.min_age,
        max: e.max_age,
      })) || [
        {
          min: "",
          max: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "ageRange",
  });

  const checkRange = (e, index) => {
    let currval = e.target.value;
    let cname = e.target.className;
    e.preventDefault();

    if (cname === "min") {
      let maxval = document.getElementById("max" + index).value;

      if (maxval != "" && currval >= maxval) {
        cflag = true;
      } else {
        cflag = false;
        document.getElementById("min" + index).style.color = "#333";
      }
    } else {
      let minval = document.getElementById("min" + index).value;

      if (minval != "" && currval <= minval) {
        e.target.style.color = "#f99";
        cflag = true;
      } else {
        cflag = false;
        document.getElementById("max" + index).style.color = "#333";
      }
    }

    for (let index = 0; index < fields.length; index++) {
      let min = getValues("ageRange[" + index + "].min");
      let max = getValues("ageRange[" + index + "].max");

      if (currval >= min && currval <= max) {
        flag = true;
        break;
      } else {
        flag = false;
      }
    }

    if (flag || cflag) {
      // e.target.style.color = "red";
      e.target.style.color = "#666";
    } else {
      e.target.style.color = "#666";
    }
  };

  const onSubmitTarget = (e) => {
    setSpinnerLoder(true);
    const formattedAgeData = e.ageRange.map((item, index) => ({
      min_age: parseInt(item.min, 10),
      max_age: parseInt(item.max, 10),
      status: 1,
    }));

    const requestBody = {
      qualify_id: props.ageQual_data[0].qualification_id,
      q_id: 1,
      ans_code: formattedAgeData,
      is_allocated: false,
      order_by: 0,
    };

    axiosPrivate
      .post(`qualification/update-age-qualification`, requestBody)
      .then((response) => {
        if (response.status === 200) {
          props.setAgeQual_data((prevState) => {
            if (prevState.length > 0) {
              prevState[0].ansCode = [...formattedAgeData];
            }
            return prevState;
          });
          notifySuccess();
          props.setAge_OPt(false);
          setSpinnerLoder(false);
        } else if (response.status === 422) {
          setSpinnerLoder(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(error.response.data.return_status.message[0]);
        setSpinnerLoder(false);
      });
  };

  return (
    <>
      <Modal
        // className="targetMain"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <ModalTitle
            style={{
              fontSize: "14px",
            }}
          >
            Add Age
          </ModalTitle>
          <CloseButton
            onClick={(e) => {
              reset({
                ageRange: [
                  {
                    min: "",
                    max: "",
                  },
                ],
              });
              props.setAge_OPt(false);
            }}
          />
        </ModalHeader>
        <>
          <form onSubmit={handleSubmit(onSubmitTarget)}>
            <ModalBody>
              <div className="age-container">
                <ul className="p-0">
                  {fields.map((item, index) => (
                    <Row key={item.id} className="mb-1 m-0">
                      <Col lg={5} md={5} sm={5} xs={5} className="ps-0">
                        <Controller
                          name={`ageRange.[${index}].min`}
                          control={control}
                          rules={{
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <input
                                id={`min${index}`}
                                className="min w-100 me-1"
                                placeholder="Min Age"
                                value={field.value}
                                ref={field.ref}
                                onChange={(e) => {
                                  field.onChange(e);
                                  checkRange(e, index);
                                }}
                              />
                              {error && (
                                <small
                                  className="topzero supply_errormsg m-0"
                                  id="topzero"
                                >
                                  {error.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </Col>

                      <Col lg={5} md={5} sm={5} xs={5} className="ps-0">
                        <Controller
                          name={`ageRange.[${index}].max`}
                          control={control}
                          rules={{
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <input
                                id={`max${index}`}
                                className="max w-100 me-1"
                                placeholder="Max Age"
                                value={field.value}
                                ref={field.ref}
                                onChange={(e) => {
                                  field.onChange(e);
                                  checkRange(e, index);
                                }}
                              />
                              {error && (
                                <small
                                  className="topzero supply_errormsg m-0"
                                  id="topzero"
                                >
                                  {error.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </Col>

                      <Col lg={2} md={2} sm={2} xs={2} className="mt-1 ps-0">
                        {index !== 0 ? (
                          <>
                            <CloseButton
                              title="Delete"
                              style={{
                                fontSize: "0.8em4 ",
                              }}
                              alt="Delete Row"
                              className="deleteBtn"
                              onClick={() => {
                                setIsOpen(false);
                                confirmAlert({
                                  closeOnEscape: false,
                                  closeOnClickOutside: false,
                                  message:
                                    "Are you sure, you want to delete this?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => {
                                        remove(index);
                                        setIsOpen(true);
                                      },
                                    },
                                    {
                                      label: "No",
                                      onClick: () => {
                                        setIsOpen(true);
                                      },
                                    },
                                  ],
                                });
                              }}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  ))}
                </ul>{" "}
              </div>
              <button
                className="add-Quota-btn"
                type="button"
                onClick={() => append({ min: "", max: "" })}
              >
                + Add Range
              </button>
            </ModalBody>
            <ModalFooter>
              <button className="targetSubmit" type="submit">
                {spinnerLoder === true ? (
                  <Spinner
                    style={{ color: "white" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                &nbsp; Save
              </button>
            </ModalFooter>
          </form>
        </>
      </Modal>
    </>
  );
};

export default AgeOption;
