import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";

import SpinnerLoader from "../../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
const Editquotaage = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const notifySuccess = (e) =>
    toast.success(`Quota for age updated successfully`);
  const [loder, setLoder] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [answersPer, setAnswersPer] = useState([]);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [all_data, setAll_data] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();
  useEffect(() => {
    if (props.addData) getData(props.addData.qualification_id);
  }, [props.addData]);

  const EditSubmit = (e) => {
    if (totalPercentage === 100) {
      setSpinner(true);
      const allocatedAnsCode = [];
      const ansCode_send = [];

      answersPer.forEach((item) => {
        allocatedAnsCode.push({
          min_age: parseInt(item.min_age),
          max_age: parseInt(item.max_age),
          status: 1,
          perc: parseInt(item.perc),
        });
      });

      answersPer.forEach((item, index) => {
        ansCode_send.push({
          min_age: parseInt(item.min_age),
          max_age: parseInt(item.max_age),
          status: 1,
        });
      });

      const requestBody = {
        qualify_id: props.addData.qualification_id,
        q_id: 1,
        ans_code: ansCode_send,
        is_allocated: true,
        allocated_ans_code: allocatedAnsCode,
        is_all_selected: false,
        order_by: 4,
      };

      axiosPrivate
        .post(`qualification/update-age-qualification`, requestBody)

        .then((response) => {
          if (response.status === 200) {
            notifySuccess();
            props.setEditagePop(false);
            setSpinner(false);
          }
          // Handle successful response
        })
        .catch((error) => {
          // Handle error
          setSpinner(false);
          console.error("Error:", error);
        });
    }
  };

  const getData = async (quali_ID) => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `qualification/get-age-qualification/${quali_ID}?question_id=${1}`
      );

      if (response.status === 200) {
        const answerDetailsPromises =
          response.data.qualification[0].allocated_ans_code.map(
            (item, index) => ({
              min_age: item.min_age,
              max_age: item.max_age,
              perc: item.perc,
              status: 1,
              qualification_id: item._id,
              survey_id: item.survey_id,
            })
          );

        setAll_data(answerDetailsPromises);

        setAnswersPer([]);
        response.data.qualification[0].allocated_ans_code.map((item, index) => {
          setAnswersPer((prevState) => [
            ...prevState,
            {
              min_age: item.min_age,
              max_age: item.max_age,
              perc: item.perc,
              status: 1,
            },
          ]);
          let sum = 0;
          response.data.qualification[0].allocated_ans_code.forEach((item) => {
            // Check if the value is a valid number
            if (!isNaN(parseFloat(item.perc))) {
              sum += parseFloat(item.perc);
            }
          });

          // Set total sum
          setTotalPercentage(sum);
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoder(true);
  };

  const handleCloseUserMenu = () => {
    props.setEditagePop(false);
  };

  const handleInputChange = (event, index, ansCode) => {
    const newValue = parseFloat(event.target.value);

    if (
      parseFloat(event.target.value) < 0 ||
      parseFloat(event.target.value) > 100
    ) {
      event.target.value = "";
    } else {
      let result = [...answersPer]; //<- copy roomRent into result

      result = result.map((x) => {
        //<- use map on result to find element to update using id
        if (x.max_age === ansCode) {
          x.perc = event.target.value;

          return x;
        } else {
          return x;
        }
      });

      setAnswersPer(result);

      // Calculate the total sum
      let sum = 0;
      result.forEach((item) => {
        // Check if the value is a valid number
        if (!isNaN(parseFloat(item.perc))) {
          sum += parseFloat(item.perc);
        }
      });

      // Set total sum
      setTotalPercentage(sum);
      // Update the answer percentage for the specified answer code
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleCloseUserMenu}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs-16">
            Edit Quota - Age
          </Modal.Title>
        </Modal.Header>
        {loder && all_data ? (
          <>
            <form onSubmit={handleSubmit(EditSubmit)}>
              <Modal.Body>
                <Row className="mb-2">
                  <Col lg={3} md={6} sm={6} xs={6}>
                    Age
                  </Col>
                  <Col lg={3} md={6} sm={6} xs={6} className="col">
                    <label className="formlabel m-0">Completes %</label>
                  </Col>
                </Row>

                {all_data.map((ansItem, answIndex) => {
                  return (
                    <Row
                      key={ansItem.answ_Code}
                      className="flex items-center mb-2"
                    >
                      <Col lg={3} md={6} sm={6} xs={6} className="col">
                        <label className="formlabel m-0">
                          {ansItem.min_age}-{ansItem.max_age}
                          {/* Render label or title from ansItem */}
                        </label>
                      </Col>

                      <Col lg={3} md={6} sm={6} xs={6} className="col">
                        <input
                          placeholder="Age Perc"
                          type="text"
                          className="CsformText m-0"
                          name={ansItem.answerTitle}
                          id={ansItem.answerTitle}
                          value={answersPer[answIndex].perc}
                          onChange={(e) =>
                            handleInputChange(e, answIndex, ansItem.max_age)
                          }
                        />
                      </Col>

                      {errors.Name && (
                        <small className="errmsg perrmsg">
                          {errors.Name.message}
                        </small>
                      )}
                    </Row>
                  );
                })}
                {totalPercentage !== 100 && (
                  <div className="error-message errmsg">
                    Total percentage must be 100%
                  </div>
                )}
              </Modal.Body>

              <Modal.Footer>
                <button
                  className="cbtn closeBtn pbtn m-0 me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    props.setEditagePop(false);
                    // props.showEditquotapopup(false);
                  }}
                >
                  Close
                </button>
                <button className="cbtn saveBtn m-0" type="submit">
                  {spinner === true && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Submit
                </button>
              </Modal.Footer>
            </form>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Modal>
    </>
  );
};

export default Editquotaage;
