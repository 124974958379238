import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import toast from "react-hot-toast";
const AddQuotaModal = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [quotaToggle, setQuotaToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const token = "Bearer " + localStorage.getItem("atoken");
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const qnameReg = register("qname", { required: "This field is required" });
  const quotaReg = register("quota", { required: "This field is required" });

  const onSubmit = async (data) => {
    setSpinner(true);
    try {
      await axiosPrivate
        .post(
          "quota/add-quota",
          JSON.stringify({
            survey_id: props.sid,
            name: data.qname,
            n: data.quota,
          }),
          {
            headers: { "access-token": token },
          }
        )
        .then((e) => {
          if (e.status === 200) {
            toast.success("Quota added successfully");
            reset();
            if (props.flag) {
              props.setFlag(false);
            } else {
              props.setFlag(true);
            }
            props.onHide();
          }
        });
    } catch (err) {
      toast.error(err.response.status);
    }
    setSpinner(false);
  };

  const handleClose = () => {
    reset();
    props.onHide();
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs16">
            Add Quota
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Row>
              <Col lg={8} md={8} xs={12} className="col">
                <label className="formlabel">Name*</label>
                <input
                  type="text"
                  className="CsformText m-0"
                  placeholder="Q Name"
                  {...qnameReg}
                />
                {errors.qname?.type === "required" && (
                  <small className="errmsg errmsgFix m-0">Required</small>
                )}
              </Col>
              <Col lg={4} md={4} xs={12} className="col">
                <label className="formlabel">Quota*</label>
                <input
                  type="number"
                  className="CsformText m-0"
                  placeholder="Quota"
                  {...quotaReg}
                />
                {errors.quota?.type === "required" && (
                  <small className="errmsg errmsgFix m-0">Required</small>
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="supply_setupbtn m-0">
              {spinner && (
                <Spinner
                  as="span"
                  style={{ color: "white" }}
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              &nbsp; Submit
            </button>
            <Button
              className="supply_setupbtn m-0 ms-2 btn-bg-grey"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddQuotaModal;
