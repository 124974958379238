import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Paginations from '../../Paginations/Paginations';
import { FiFilter } from 'react-icons/fi';
import Table from 'react-bootstrap/Table';
import SpinnerLoader from '../../UI/Spinner';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import { BiReset } from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';
import './Viewpop.css';
import toast from 'react-hot-toast';
const Viewpopup = (props) => {
   
    const axiosPrivate = useAxiosPrivate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [show, setShow] = useState(false);
    const [dataLength, setDataLength] = useState();
    const [pagesize, setPagesize] = useState(10);
    const [nextPage, setNextPage] = useState(1);
    const [loder, setLoder] = useState(false);
    const [allsurveydata, setAllsurveydata] = useState([]);
    const [used_value, setUsed_Value] = useState('');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (props.viewsurveyId && props.surveyStorage !== 1) {
            const get_radiobutton_data = async (sidname) => {
                setLoder(false);
                try {
                    const response = await axiosPrivate.get(`survey/get-survey-client-link?survey_id=${props.viewsurveyId}&page=${nextPage}&size=${pagesize}&sort=-_id`);
                    setAllsurveydata(response.data.items);
                    setDataLength(response.data.total);
                } catch (err) {
                    console.error(err);
                }
                setLoder(true);
            };
            get_radiobutton_data();
        }
    }, [props.viewsurveyId, nextPage, pagesize, props.surveyStorage]);

    const getPageSize = (size) => {
        setPagesize(size);
    };

    const handlePageChnage = (pagesize) => {
        setNextPage(pagesize + 1);
    };

    const Viewfilter = async (sidname) => {
        setIsSubmitting(true);
        if (used_value !== 'undefined') {
            setLoder(false);
            try {
                const response = await axiosPrivate.get(`survey/get-survey-client-link?survey_id=${props.viewsurveyId}&page=${nextPage}&size=${pagesize}&sort=-_id&query_filter=is_used%3D${used_value}`);

                setAllsurveydata(response.data.items);
                setDataLength(response.data.total);
            } catch (err) {
                console.error(err);
            }
            setLoder(true);
        } else {
            toast.error('Please select one filter');
        }
        setIsSubmitting(false);
    };
    const View_refresh_filter = async (sidname) => {
        if (used_value !== 'undefined') {
            setLoder(false);
            try {
                const response = await axiosPrivate.get(`survey/get-survey-client-link?survey_id=${props.viewsurveyId}&page=${nextPage}&size=${pagesize}&sort=-_id`);

                setAllsurveydata(response.data.items);
                setDataLength(response.data.total);
            } catch (err) {
                console.error(err);
            }
            setLoder(true);
        } else {
            toast.error('Please select one filter');
        }
    };
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="filter_header" closeButton>
                <Modal.Title className="filter_title" id="contained-modal-title-vcenter">
                    View File
                </Modal.Title>
                <div className="filterIcon">
                    <FiFilter className="supplyIconColr viewpopIcon" onClick={() => setShow(!show)} />
                </div>
            </Modal.Header>
            <Modal.Body>
                {show ? (
                    <>
                        {/* <form onSubmit={handleSubmit()}> */}
                        <Row
                            className="supply_filterbox"
                            style={{
                                backgroundColor: '#f1efef',
                                // margin: "15px",
                                paddingBottom: '10px',
                            }}
                        >
                            <Col lg={10} md={9} xs={11} className="supply_col">
                                <Row>
                                    <Col lg={6} md={6} xs={6} className="col">
                                        <label
                                            for="status"
                                            style={{
                                                fontSize: '12px',
                                            }}
                                        >
                                            Used
                                        </label>
                                        <select
                                            className="formtext"
                                            value={used_value}
                                            onChange={(e) => {
                                                setUsed_Value(e.target.value);
                                            }}
                                        >
                                            <option value="">Select</option>
                                            <option value="0">False</option>
                                            <option value="1">True</option>
                                        </select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                lg={2}
                                md={3}
                                xs={12}
                                className="col"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <button
                                    className="cbtn saveBtn"
                                    style={{
                                        maxWidth: '100%',
                                        marginTop: '20px',
                                    }}
                                    onClick={(e) => {
                                        Viewfilter();
                                        e.stopPropagation();
                                    }}
                                >
                                    {isSubmitting === true && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                                    &nbsp; &nbsp; Submit
                                </button>
                                <BiReset
                                    size={30}
                                    style={{
                                        marginTop: '20px',
                                    }}
                                    onClick={(e) => {
                                        setUsed_Value('');
                                        View_refresh_filter();
                                    }}
                                />
                            </Col>
                        </Row>
                        {/* </form> */}
                    </>
                ) : null}

                <div className="mainContent mainContent2">
                    {loder ? (
                        <>
                            <div className="table_container ">
                                <Table responsive bordered={false} hover size="sm">
                                    {props.surveyStorage === 2 ? (
                                        <>
                                            <thead className="thead">
                                                <tr>
                                                    {/* <td>Survggey Id</td> */}
                                                    <td style={{ width: '50%' }}>Unique Link</td>
                                                    <td>Resp Id</td>
                                                    <td>Supplier TID</td>
                                                    <td>Used</td>
                                                </tr>
                                            </thead>
                                        </>
                                    ) : props.surveyStorage === 3 ? (
                                        <>
                                            <thead className="thead">
                                                <tr>
                                                    <td>Resp Id</td>
                                                    <td>Supplier Id</td>
                                                    <td>Supplier TID</td>

                                                    <td>Used</td>
                                                </tr>
                                            </thead>
                                        </>
                                    ) : props.surveyStorage === 4 ? (
                                        <>
                                            <thead className="thead">
                                                <tr>
                                                    <td>Unique Link</td>

                                                    <td>Re-Contact Resp Id</td>
                                                    <td>Resp Id</td>
                                                    <td>Supplier TID</td>
                                                    <td>Used</td>
                                                </tr>
                                            </thead>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {allsurveydata.length === 0 ? (
                                        <>
                                            <div class="alert alert-danger center-margin-container screening_view_nodata" role="alert">
                                                No Data Found!
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {props.surveyStorage === 2 ? (
                                                <>
                                                    <tbody>
                                                        {allsurveydata.map((item, index) => (
                                                            <>
                                                                <tr>
                                                                    {/* <td>{item.survey_id}</td> */}
                                                                    <td>{item.unique_link}</td>
                                                                    <td>{item.resp_id}</td>
                                                                    <td>{item.supplier_transaction_id}</td>
                                                                    <td>{item.is_used.toString()}</td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </>
                                            ) : props.surveyStorage === 3 ? (
                                                <>
                                                    <tbody>
                                                        {allsurveydata.map((item, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{item.resp_id}</td>
                                                                    <td>{item.survey_id}</td>
                                                                    <td>{item.supplier_transaction_id}</td>

                                                                    <td>{item.is_used.toString()}</td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </>
                                            ) : props.surveyStorage === 4 ? (
                                                <>
                                                    <tbody>
                                                       
                                                        {allsurveydata.map((item, index) => (
                                                            <>
                                                                <tr>
                                                                    <td>{item.unique_link}</td>

                                                                    <td>{item.recontact_resp_id}</td>
                                                                    <td>{item.resp_id}</td>
                                                                    <td>{item.supplier_transaction_id}</td>
                                                                    <td>{item.is_used.toString()}</td>
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    )}
                                </Table>
                            </div>
                        </>
                    ) : (
                        <SpinnerLoader />
                    )}
                    <Paginations userlist={dataLength} getPageSize={getPageSize} handlePageChnage={handlePageChnage} page={nextPage} pagesize={pagesize} />
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default Viewpopup;
