import { useEffect, useState } from 'react';
import './ProjectDetail.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import { MultiSelect } from 'react-multi-select-component';

const CloneServey = (prop) => {
    const { id } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [toggeleSwitchButton, setToggleSwitchButton] = useState(false);
    const [selected_ex_api, setSelected_ex_api] = useState(prop.selected_ex_api);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    useEffect(() => {
        setValue('MarginNewThreshold', prop.defaultValueMargin.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    const handleNewCloneFormSubmit = async (data) => {
        let ans_code = selected_ex_api.map((option) => option.value);
        setIsSubmitting(true);
        if (data.toString() === undefined) {
        } else {
            const payload = {
                country_id: prop.dataStorage.country.id,
                language_id: 1,
                ir: Number(data.CloneNewIr),
                loi: Number(data.CloneNewLoi),
                n: Number(data.CloneNewQuota),
                cpi: Number(prop.dataStorage.cpi),
                margin_threshhold: data.MarginNewThreshold,
                survey_status_id: prop.dataStorage.survey_status.id,
                project_survey_type_id: prop.dataStorage.project_survey_type.id,
                exclude_api: ans_code,
            };
            try {
                await axiosPrivate
                    .patch(`survey/update-survey-project?survey_id=${prop.sid}`, payload, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((e) => {
                        toast.success('Updated successfully');
                    });
            } catch (err) {
                notifyerror(err.response.status);
            }
        }
        setIsSubmitting(false);
    };

    const handleTargetSwitch = async (e, ID) => {
        setToggleSwitchButton(e);
        const payload = {
            status: e ? 1 : 0,
        };

        try {
            await axiosPrivate
                .patch(`survey/target-audience/${ID}`, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((end) => {
                    toast.success('Project updated successfully');
                });
        } catch (err) {
            setToggleSwitchButton(toggeleSwitchButton);
            let x = 500;
            if (err.response.status.toString() === x.toString()) {
                notifyerror('Service');
            } else {
                toast.error(err.response.data.return_status.message[0]);
            }
        }
    };
    const notifyerror = (e) => toast.error(`${e} Error occured`);

    return (
        <>
            <form onSubmit={handleSubmit(handleNewCloneFormSubmit)}>
                <div className="first firstone" id="boxContainerBorder">
                    <div className="ScreeningHead pjt_arrow_icon flex items-center">
                        <p className="pBlockHead m-0"> {prop.dataStorage.sc_id}- Clone </p>
                        <div className="rankPain projbtns">
                            <button
                                type="button"
                                className="PSaveBtn m-0"
                                style={{ width: '125px' }}
                                onClick={() => {
                                    navigate(`/project-detail/${id}/8/${prop.survey_id}`);
                                }}
                            >
                                Target Audience
                            </button>
                            <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                size="xs"
                                width={60}
                                onlabel="ON"
                                offlabel="OFF"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={toggeleSwitchButton}
                                onChange={(e) => {
                                    handleTargetSwitch(e, prop.sid);
                                }}
                            />
                            <span>|</span>
                            <button className="PSaveBtn m-0" type="submit">
                                {isSubmitting && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                                &nbsp; Save
                            </button>
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                backgroundColor: 'white',
                            }}
                            className="csmulti"
                        >
                            <input type="hidden" value={prop.sid} {...register('sid')} />
                            <Row className="prj_rowtwo">
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">IR*</label>
                                    <input
                                        {...register('CloneNewIr', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.dataStorage.ir}
                                        type="text"
                                        placeholder="IR"
                                        className="CsformText  m-0"
                                    />
                                    {errors.CloneNewIr && <small className="supply_errormsg  m-0">{errors.CloneNewIr.message}</small>}
                                </Col>
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">LOI*</label>
                                    <input
                                        {...register('CloneNewLoi', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.dataStorage.loi}
                                        type="text"
                                        placeholder="LOI"
                                        className="CsformText  m-0"
                                    />
                                    {errors.CloneNewLoi && <small className="supply_errormsg  m-0">{errors.CloneNewLoi.message}</small>}
                                </Col>
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">Quota*</label>
                                    <input
                                        {...register('CloneNewQuota', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.dataStorage.n}
                                        type="text"
                                        placeholder="Quota"
                                        className="CsformText  m-0"
                                    />
                                    {errors.CloneNewQuota && <small className="supply_errormsg  m-0">{errors.CloneNewQuota.message}</small>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Margin Threshold*</label>
                                    <input
                                        {...register('MarginNewThreshold', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^(?:100|[1-9][0-9]?)$/,
                                                message: 'Only numbers from 1 to 100 are allowed',
                                            },
                                        })}
                                        defaultValue={prop.dataStorage.margin_threshhold}
                                        type="text"
                                        placeholder="Margin Threshold"
                                        className="CsformText"
                                    />
                                    {errors.MarginNewThreshold && <small className="errmsg">{errors.MarginNewThreshold.message}</small>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label
                                        className="formlabel"
                                        style={{
                                            marginBottom: '0px',
                                        }}
                                    >
                                        Exclude api's
                                    </label>

                                    <MultiSelect
                                        className="multiselectoption "
                                        style={{ width: '29px' }}
                                        options={prop.exclude_api}
                                        value={selected_ex_api}
                                        onChange={(e) => {
                                            setSelected_ex_api(e);
                                        }}
                                        labelledBy="Filter by user"
                                        isCreatable={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CloneServey;
