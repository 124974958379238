import { useEffect, useState } from 'react';
import './ProjectDetail.css';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import ProjectDetailMulipalForm from './ProjectDetailMulipalForm';
import Select from 'react-select';
import SpinnerLoader from '../../UI/Spinner';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import moment from 'moment';
const ProjectDetail = (props) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { id } = useParams();
    const [projectTypeStorage, setProjectTypeStorage] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [projectStatusStorage, setProjetStatusStorage] = useState([{}]);
    const [clientListStorage, setClientListStorage] = useState([{}]);
    const [salePersionStorage, setCsalePersionStorage] = useState([{}]);
    const [pPManagerStorage, setPpManagerStorage] = useState([{}]);
    const [sPManagerStorage, setSpManagerStorage] = useState([{}]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [surveyData, setsurveyData] = useState([]);
    const [marginModify, setMarginModify] = useState([]);
    const [statusdata, setStatusdata] = useState([]);
    const [modifyData, setModifyData] = useState([]);
    const [modifyDataLanguage, setModifyDataLanguage] = useState([]);
    const [projectTypeValue, setProjecTypeValue] = useState();
    const [clientNameValue, setClientValue] = useState();
    const [salePersionValue, setPersionValue] = useState();
    const [ppManger, setPpManger] = useState();
    const [spManger, setSpmanager] = useState();
    const [status, setStatus] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [surveyType, setSurveyType] = useState([]);
    const [qualificationData, setQualificationData] = useState([]);
    const [projectData, setProjectData] = useState([
        {
            ProjectName: '',
            ProjectSc: '',
            ProjectType: {},
            ClientName: {},
            ClientNumber: '',
            SalePerson: {},
            PPManager: {},
            SPManager: {},
            Status: {},
            StartDate: '',
            EndDate: '',
            EstRevenue: '',
            RevistRevenue: '',
        },
    ]);

    const [spinloder, setSpinloder] = useState(false);

    useEffect(() => {
        get_ProjectType_api();
        get_ClintList_api();
        get_SalePersion_api();
        get_ProjectStatus_api();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const project = projectData[0];
    useEffect(() => {
        setValue('ClientName', project.ClientName);
        setValue('SalesPerson', project.SalePerson);
        setValue('PrimaryProjectManager', project.PPManager);
        setValue('SecondaryProjectManager', project.SPManager);
        setValue('Status', project.Status);
        setValue('projectName', project.ProjectName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [project.ClientName, project.SalePerson, project.PPManager, project.SPManager, project.Status, project.ProjectName]);

    useEffect(() => {
        get_ProjectData_Params();
        get_Multiform_api();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idFromFilter, props.flag]);

    useEffect(() => {
        props.setShowSubHeader(1);
        props.setSelectedHeader('project');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStatus = (e) => {
        setStatus(e.value);
    };

    const handleSecoundPManager = (e) => {
        setSpmanager(e.value);
    };

    const handlePPManager = (e) => {
        setPpManger(e.value);
    };
    const handleSalePersion = (e) => {
        setPersionValue(e.value);
    };

    const handleClientName = (e) => {
        setClientValue(e.value);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        trigger,
    } = useForm();

    const get_Multiform_api = async () => {
        setSpinloder(true);
        try {
            const response = await axiosPrivate.get(`survey/get-survey-project-id?project_id=${id}`);
            setsurveyData(response.data.survey);
            const qualification = response.data.survey.map((e, index) => ({
                id: e.id,
                is_qualify_launch: e.qualification.is_qualify_launch,
                target_aud_status: e.qualification.target_aud_status,
                clone: e.clone_survey.map((cloneItem, cloneIndex) => ({
                    cloneid: cloneItem._id,
                    clone_is_qualify_launch: cloneItem.qualification.is_qualify_launch,
                    clone_target_aud_status: cloneItem.qualification.target_aud_status,
                })),
            }));

            setQualificationData(qualification);
            const userdata2 = response.data.survey.map((e, index) => ({
                value: e.margin_threshhold,
                label: e.margin_threshhold,
            }));
            setMarginModify(userdata2);

            const userdata = response.data.survey.map((e, index) => ({
                value: e.country.id,
                label: e.country.name,
            }));
            const language = response.data.survey.map((e, index) => ({
                value: e.language.id,
                label: e.language.name,
            }));
            const statusdata = response.data.survey.map((e, index) => ({
                value: e.survey_status.id,
                label: e.survey_status.name,
            }));
            const surveyType = response.data.survey.map((e, index) => ({
                value: e.project_survey_type.id,
                label: e.project_survey_type.name,
            }));

            setStatusdata(statusdata);
            setSurveyType(surveyType);
            setModifyData(userdata);

            setModifyDataLanguage(language);
            setSpinloder(false);
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
        setSpinloder(false);
    };

    const get_ProjectType_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-project-type');
            let data = response.data.all_status;

            var ptype = [...data];
            const projType = ptype.map((item, i) =>
                Object.assign(item, {
                    value: parseInt(item.id),
                    label: item.name,
                })
            );
            setProjectTypeStorage(projType);
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const get_ProjectStatus_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-project-status');
            response.data.all_status.forEach((e) => {
                setProjetStatusStorage((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const get_ClintList_api = async () => {
        try {
            const response = await axiosPrivate.get('client/client-list');
            response.data.all_client.forEach((e) => {
                setClientListStorage((val) => [...val, { label: e.name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const get_SalePersion_api = async () => {
        try {
            const response = await axiosPrivate.get('user/all-users');
            response.data.all_users.forEach((e) => {
                setCsalePersionStorage((val) => [...val, { label: e.first_name + ' ' + e.last_name, value: e._id }]);
            });

            var dataContainer = [];

            response.data.all_users.forEach((e) => {
                dataContainer.push({
                    label: e.first_name + ' ' + e.last_name,
                    value: e._id,
                });
            });

            let x = 133;

            if (projectData && projectData[0] && projectData[0].PPManager && projectData[0].PPManager.value === x) {
                setPpManagerStorage(dataContainer);
                setSpManagerStorage(dataContainer);
            } else {
                const filteredData = dataContainer.filter((item) => item.value !== x.toString());
                setPpManagerStorage(filteredData);
                setSpManagerStorage(filteredData);
            }
        } catch (err) {
         
            if (err.response && err.response.status) {
                notifyerror(err.response.status);
            }
        }
    };

    const handleProjectClose = (e) => {
        navigate('/survey-listing');
        e.preventDefault();
    };

    const handleProjectForm = async (event) => {
        setIsSubmitting(true);
        const a = moment(startDate).format('YYYY-MM-D');
        const b = moment(endDate).format('YYYY-MM-D');
        const payload = {
            project_name: event.projectName,
            project_type_id: projectTypeValue,
            client_id: clientNameValue,
            client_no: event.ClientNumber,
            sales_person_id: salePersionValue,
            primary_pm_id: ppManger,
            secondary_pm_id: spManger,
            project_status_id: status,
            start_date: a,
            end_date: b,
            est_revenue: event.EstRevenue,
            revised_revenue: event.RevisedEstRevenue,
        };

        try {
            await axiosPrivate
                .patch(`project/update-project?project_id=${id}`, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    toast.success('Project updated successfully');
                    localStorage.setItem('ProjectDetail', 'true');
                });
        } catch (err) {
            notifyerror(err.response.status);
        }
        setIsSubmitting(false);
        props.setReRender(true);
        event.preventDefault();
    };

    const notifyerror = (e) => toast.error(`${e} Error occured`);

    const handleProjectType = (e) => {
        setProjecTypeValue(e.id);
    };

    const get_ProjectData_Params = async () => {
        setSpinner(true);
        reset();

        try {
            const response = await axiosPrivate.get(`project/get-project/${id}`);
            setProjecTypeValue(response.data.project.project_type.id);
            setClientValue(response.data.project.client.id);
            setPersionValue(response.data.project.sale_representive.id);
            setPpManger(response.data.project.project_manager.primary_project_manager.id);
            setSpmanager(response.data.project.project_manager.secondary_project_manager.id);
            setStatus(response.data.project.project_status.id);

            setValue(`ClientNumber`, response.data.project.client.client_number);
            setValue(`EstRevenue`, response.data.project.revenue.est_revenue);
            setValue(`RevisedEstRevenue`, response.data.project.revenue.revised_est_revenue);

            setProjectData((prevProjectData) => [
                {
                    ...prevProjectData[0],
                    ProjectName: response.data.project.project_name,
                    ProjectSc: response.data.project.pro_sc,

                    ProjectType: {
                        label: response.data.project.project_type.name,
                        value: parseInt(response.data.project.project_type.id),
                    },
                    ClientName: {
                        label: response.data.project.client.name,
                        value: response.data.project.client.id,
                    },
                    ClientNumber: response.data.project.client.client_number,
                    SalePerson: {
                        label: response.data.project.sale_representive.name,
                        value: response.data.project.sale_representive.id,
                    },
                    PPManager: {
                        label: response.data.project.project_manager.primary_project_manager.name,
                        value: response.data.project.project_manager.primary_project_manager.id,
                    },
                    SPManager: {
                        label: response.data.project.project_manager.secondary_project_manager.name,
                        value: response.data.project.project_manager.secondary_project_manager.id,
                    },
                    Status: {
                        label: response.data.project.project_status.name,
                        value: response.data.project.project_status.id,
                    },

                    EstRevenue: response.data.project.revenue.est_revenue,
                    RevistRevenue: response.data.project.revenue.revised_est_revenue,
                },
            ]);

            setStartDate(new Date(response.data.project.start_date));
            setEndDate(new Date(response.data.project.end_date));
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
        setSpinner(false);
    };

    return (
        <>
            {spinner ? (
                <>
                    <SpinnerLoader />
                </>
            ) : (
                <>
                    <div className="saveclsebtn mSet ">
                        <button
                            className="cbtn closeBtn pbtn"
                            onClick={() => {
                                handleProjectClose();
                            }}
                        >
                            Close
                        </button>
                    </div>
                    <form onSubmit={handleSubmit(handleProjectForm)}>
                        <div className="saveclsebtn mSet">
                            <button className="cbtn saveBtn pbtn" type="submit">
                                {isSubmitting && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                                Save
                            </button>
                        </div>

                        <hr className="csHR" />
                        <div className="projectTop">
                            <Row className="prj_rowone">
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Project Name*</label>
                                    <input
                                        type="text"
                                        placeholder="Project Name"
                                        className="CsformText"
                                        {...register('projectName', {
                                            required: 'This field is Required',
                                        })}
                                        defaultValue={projectData[0].ProjectName}
                                        onChange={(e) => {
                                            setValue(`projectName`, e.target.value);
                                            trigger(`projectName`);
                                        }}
                                    />

                                    {errors.projectName && <small className="errmsg perrmsg">{errors.projectName.message}</small>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col pjt_select">
                                    <label className="formlabel">Project Type*</label>
                                    <Select className="formtext" classNamePrefix="select" isDisabled={false} isLoading={false} isRtl={false} isSearchable={true} name="color" options={projectTypeStorage} onChange={handleProjectType} defaultValue={projectData[0].ProjectType} />
                                    {projectTypeStorage || projectTypeValue ? <></> : <>{errors.Project && <small className="errmsg perrmsg">{errors.Project.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Client Name*</label>

                                    <Select
                                        {...register('ClientName', {
                                            required: 'This field is Required',
                                        })}
                                        placeholder="Select..."
                                        className="pjt_select formtext"
                                        classNamePrefix="select"
                                        options={clientListStorage}
                                        defaultValue={projectData[0].ClientName}
                                        onChange={handleClientName}
                                        noOptionsMessage={() => 'No Data Found'}
                                    />
                                    {projectData[0].ClientName || clientNameValue ? <></> : <>{errors.ClientName && <small className="errmsg perrmsg">{errors.ClientName.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Client Number (PO)#*</label>
                                    <input
                                        {...register('ClientNumber', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[a-zA-Z0-9\s]+$/,
                                                message: 'Only numbers and letters are allowed',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Client Number (PO)#"
                                        className="CsformText"
                                        defaultValue={projectData[0].ClientNumber}
                                        onChange={(e) => {
                                            setValue(`ClientNumber`, e.target.value);
                                            trigger(`ClientNumber`);
                                        }}
                                    />
                                    {errors.ClientNumber && <small className="errmsg perrmsg">{errors.ClientNumber.message}</small>}
                                </Col>
                            </Row>
                            <Row className="prj_rowone projectMargintopzero">
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Sales Person*</label>
                                    <Select
                                        {...register('SalesPerson', {
                                            required: 'This field is Required',
                                        })}
                                        placeholder="Select..."
                                        className="pjt_select formtext"
                                        classNamePrefix="select"
                                        options={salePersionStorage}
                                        defaultValue={projectData[0].SalePerson}
                                        onChange={handleSalePersion}
                                        noOptionsMessage={() => 'No Data Found'}
                                    />
                                    {projectData[0].SalePerson || salePersionValue ? <></> : <>{errors.SalesPerson && <small className="errmsg perrmsg">{errors.SalesPerson.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel projlabel m-0">Primary Project Manager*</label>

                                    <Select
                                        {...register('PrimaryProjectManager', {
                                            required: 'This field is Required',
                                        })}
                                        placeholder="Select..."
                                        className="pjt_select formtext"
                                        classNamePrefix="select"
                                        options={pPManagerStorage}
                                        defaultValue={projectData[0].PPManager}
                                        onChange={handlePPManager}
                                        noOptionsMessage={() => 'No Data Found'}
                                    />
                                    {projectData[0].PPManager || ppManger ? <></> : <>{errors.PrimaryProjectManager && <small className="errmsg perrmsg">{errors.PrimaryProjectManager.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel projlabel m-0" title="Secondary Project Manager">
                                        Secondary Project Manager*
                                    </label>

                                    <Select
                                        {...register('SecondaryProjectManager', {
                                            required: 'This field is Required',
                                        })}
                                        placeholder="Select..."
                                        className="pjt_select formtext"
                                        classNamePrefix="select"
                                        options={sPManagerStorage}
                                        defaultValue={projectData[0].SPManager}
                                        noOptionsMessage={() => 'No Data Found'}
                                        onChange={handleSecoundPManager}
                                    />
                                    {projectData[0].SPManager || spManger ? <></> : <>{errors.SecondaryProjectManager && <small className="errmsg perrmsg">{errors.SecondaryProjectManager.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label
                                        className="formlabel"
                                        style={{
                                            marginBottom: '0px',
                                        }}
                                    >
                                        Status*
                                    </label>

                                    <Select
                                        {...register('Status', {
                                            required: 'This field is Required',
                                        })}
                                        placeholder="Select..."
                                        className="pjt_select formtext"
                                        classNamePrefix="select"
                                        options={projectStatusStorage}
                                        defaultValue={projectData[0].Status}
                                        onChange={handleStatus}
                                        noOptionsMessage={() => 'No Data Found'}
                                        isDisabled
                                    />
                                    {projectData[0].Status || status ? <></> : <>{errors.Status && <small className="errmsg perrmsg">{errors.Status.message}</small>}</>}
                                </Col>
                            </Row>
                            <Row
                                className="prj_rowone "
                                style={{
                                    marginTop: '7px',
                                }}
                            >
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Start Date*</label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className="formtext"
                                        {...register('received_date', {
                                            valueAsDate: true,
                                        })}
                                        autoComplete="off"
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date);
                                        }}
                                        maxDate={endDate} // Set maxDate to the current value of the end date
                                        defaultValue={projectData[0].StartDate}
                                    />
                                    {startDate || projectData[0].StartDate ? <></> : <>{errors.received_date && <small className="errmsg perrmsg">{errors.received_date.message}</small>}</>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">End Date*</label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className="formtext"
                                        {...register('end_date', {
                                            valueAsDate: true,
                                        })}
                                        autoComplete="off"
                                        selected={endDate}
                                        onChange={(date) => {
                                            setEndDate(date);
                                        }}
                                        minDate={startDate} // Set minDate to the current value of the start date
                                        defaultValue={projectData[0].EndDate}
                                    />
                                    {endDate || projectData[0].EndDate ? <></> : <>{errors.end_date && <small className="errmsg perrmsg">{errors.end_date.message}</small>}</>}
                                </Col>

                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Est. Revenue*</label>
                                    <input
                                        {...register('EstRevenue', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Est. Revenue"
                                        className="CsformText"
                                        defaultValue={projectData[0].EstRevenue}
                                        onChange={(e) => {
                                            setValue('EstRevenue', e.target.value);
                                            trigger('EstRevenue');
                                        }}
                                    />
                                    {errors.EstRevenue && <small className="errmsg perrmsg">{errors.EstRevenue.message}</small>}
                                </Col>

                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Revised Est. Revenue*</label>
                                    <input
                                        {...register('RevisedEstRevenue', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        type="text"
                                        placeholder="Revised Est. Revenue"
                                        className="CsformText"
                                        defaultValue={projectData[0].RevistRevenue}
                                        onChange={(e) => {
                                            setValue('RevisedEstRevenue', e.target.value);
                                            trigger('RevisedEstRevenue');
                                        }}
                                    />
                                    {errors.RevisedEstRevenue && <small className="errmsg perrmsg">{errors.RevisedEstRevenue.message}</small>}
                                </Col>
                            </Row>
                        </div>
                    </form>
                </>
            )}

            {spinner ? (
                <></>
            ) : (
                <>
                    <div className="PrightLower">
                        <div
                            style={{
                                backgroundColor: '#929594',
                                lineHeight: '2.1',
                            }}
                        >
                            <p className=" Pheading">
                                {projectData[0].ProjectSc} - {projectData[0].ProjectName}
                            </p>
                        </div>

                        {spinloder ? (
                            <>
                                <SpinnerLoader />
                            </>
                        ) : (
                            <>
                                {surveyData.length === 0 ? (
                                    <>
                                        <div
                                            class="alert alert-danger"
                                            style={{
                                                textAlign: 'center',
                                                margin: '15px',
                                            }}
                                            role="alert"
                                        >
                                            No Survey Found!
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {surveyData.map((e, index) => (
                                            <ProjectDetailMulipalForm key={index} index={index} surveyData={surveyData} modifyData={modifyData} modifyDataLanguage={modifyDataLanguage} marginModify={marginModify} surveyType={surveyType} statusdata={statusdata} props={props} qualificationData={qualificationData} setQualificationData={setQualificationData} setSelectedElem={props.setSelectedElem} setsurveyData={setsurveyData} setFlag={props.setFlag} flag={props.flag} projectStatusStorage={projectStatusStorage} />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default ProjectDetail;
