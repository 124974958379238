import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";

import { BiUpload, BiPlus } from "react-icons/bi";
const Reconsilitionupload = (props) => {
  const [uploadImgname, setUploadImgName] = useState();

  const uploadDoc = (e) => {
    const formData = new FormData();
    formData.append("doc", e.target.files[0]);
    setUploadImgName(e.target.files[0].name);
  };
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "10px" }}
          >
            <i className="bi bi-person-plus"></i>
            Please upload RID's only
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ paddingBottom: "50px" }}>
            <Col lg={2} md={7} sm={12} xs={12}>
              <div className="r_profile">
                <form method="HTTP_METHOD" encType="multipart/form-data">
                  <label htmlFor="doc" className="r_uploadPic">
                    <BiPlus className="r_Plusimg" />
                    <p
                      style={{
                        width: "auto",
                        fontSize: "14px",
                        color: "white",
                        marginTop: "5px",
                      }}
                    >
                      {uploadImgname ? <>{uploadImgname}</> : <>Attach File</>}
                    </p>
                  </label>
                  <input
                    id="doc"
                    name="doc"
                    type="file"
                    onChange={uploadDoc}
                    accept="image/*"
                    style={{
                      display: "none",
                    }}
                  />
                </form>
                <span className="recon_upload_text">
                  {" "}
                  please upload CSV, Excel ﬁle only !
                </span>
                {uploadImgname ? (
                  <>
                    <button type="submit" className="R_uploadbtn">
                      <BiUpload
                        style={{
                          fill: "white",
                          marginRight: "5px",
                        }}
                      />
                      Upload
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Reconsilitionupload;
