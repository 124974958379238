import React from "react";
import "./Common.css";

const CommonFooter = () => {
  return (
    <>
      <div bgColor="light" className="text-center text-lg-start text-muted">
        <footer>
          <small>Copyright © 2024 Samplicity 3.0. All Rights Reserved.</small>
        </footer>
      </div>
    </>
  );
};

export default CommonFooter;
