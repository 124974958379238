import './CreateBid.css';
import Header from '../../UI/Header/Header';
import BidsSubHeader from '../BidsSubHeader/BidsSubHeader';
import { useState, useEffect } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Select from 'react-select';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router';
import userIco from '../../../assets/LoginIcons/users.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment/moment';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import DeleteIcon from '../../../assets/LoginIcons/delete.svg';
import 'react-accessible-accordion/dist/fancy-example.css';
import BidOwnerList from '../BidDetails/CommonServices/BidOwnerList';
import PMList from '../BidDetails/CommonServices/PMList';
import ProjectType from '../BidDetails/CommonServices/ProjectType';
import toast from 'react-hot-toast';
import ButtonSpinner from '../../UI/ButtonSpinner';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useParams } from 'react-router';
import SpinnerLoader from '../../UI/Spinner';
import OppStatus from '../BidDetails/CommonServices/OppStatus';
import Modal from 'react-modal';
import { ModalBody, ModalHeader } from 'react-bootstrap';
import BidMarginPercent from '../BidDetails/CommonServices/BidMarginPercent';
import { CalcAmtBid } from './CalcAmtBid';

const CreateBid = () => {
    const navigate = useNavigate();

    var currentdate = new Date();
    var today = new Date();
    today.setUTCHours(15, 59, 59, 999);
    var nextweek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7
    );
    nextweek.setUTCHours(15, 59, 59, 999);
    var nextmonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        today.getDate()
    );
    nextmonth.setUTCHours(15, 59, 59, 999);

    const [projectName, setProjectName] = useState();
    const [bidOwner, setBidOwner] = useState();
    const [receivedDate, setReceivedDate] = useState(today);
    const [closeDate, setCloseDate] = useState(nextmonth);
    const [projectStartDate, setProjectStartDate] = useState(today);
    const [projectEndDate, setProjectEndDate] = useState(nextweek);
    const [editorData, setEditorData] = useState('');
    const [bookedAmt, setBookedAmt] = useState(0);
    const [bidAmt, setBidAmt] = useState(0);
    const [bidPercentage, setBidPercentage] = useState('100');
    const [dollarAmount, setDollarAmount] = useState(0);
    const [projectType, setProjectType] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [serviceType, setServiceType] = useState([]);
    const [clientContact, setClientContact] = useState([]);
    const [clientId, setClientId] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientContactName, setClientContactName] = useState('');
    const showOpportunity = ['2', '3', 2, 3, ''];
    const [geography, setGeography] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [emailCont, setEmailCont] = useState();
    const [subject, setSubject] = useState();
    const [clientRegion, setClientRegion] = useState();
    const [showRegionInput, setShowRegionInput] = useState(false);
    const [ccLoading, setCcLoading] = useState(false);
    const [clients, setClients] = useState({ data: [], isLoading: true });
    const [multiselect, setMultiselect] = useState();
    const [contact_multiselect, setContact_Multiselect] = useState();
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [contact_nameLoader, setContact_NameLoader] = useState(false);

    const { id } = useParams();
    const defaultValues = {
        project_name: '',
        client: {
            client_id: '',
            client_contact_id: '',
            client_contact_email: '',
        },
        bid_owner: {
            id: '',
            name: '',
            initial: '',
        },
    };

    useEffect(() => {
        fetchUser();
    }, []);
    const fetchUser = async () => {
        try {
            const response = await axiosPrivate.get('/user', {});
            if (response.status === 200) {
                setUserId(response.data.user.id);
                setUserName(response.data.user.first_name);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const [bidData, setbidData] = useState(defaultValues);

    const notifyerr = (msg) => toast.error(msg);
    const axiosPrivate = useAxiosPrivate();

    const {
        register,
        getValues,
        handleSubmit,
        control,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            opportunities: [
                {
                    lineItem: '',
                    category: '',
                    geography: '',
                    ir: '',
                    loi: '',
                    n: '',
                    cpi: '',
                    is_project_created: 0,
                },
            ],
        },
    });

    const oppStatus = watch('opp_status');
    const pReason = watch('pass_reason');
    const cEmail = watch('cemail');
    const bidDetail = watch('bid_detail');

    useEffect(() => {
        mailDetail();
        if (id) {
            emailDetail();
        } else {
            setIsLoading(false);
        }
        getServiceType();
        getCountryList();
        getClientsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {}, [multiselect]);

    const getCountryList = async () => {
        try {
            const response = await axiosPrivate.get('/lookup/country-list', {});
            let data = response.data.all_countries;
            var countryList = [...data];
            countryList.map((item, i) =>
                Object.assign(item, {
                    value: parseInt(item._id),
                    label: item.country_name,
                })
            );
            let countryOptions = countryList.map((obj) => {
                delete obj.country_name;
                delete obj.country_code;
                delete obj.status;
                delete obj._id;
                delete obj.all_states;
                return obj;
            });
            setGeography(countryOptions);
        } catch (err) {
            console.error(err);
        }
    };

    const mailDetail = async () => {
        let url = '/mailbox/get-bid-details/';
        if (id) {
            url = `/mailbox/get-bid-details/${id}`;
        }
        try {
            const response = await axiosPrivate.get(url, {});
            setbidData(response.data.mailbox_detail);
            setProjectName(response.data.mailbox_detail.project_name);
            setAccountId(response.data.mailbox_detail.client.client_id);
            setClientEmail(
                response.data.mailbox_detail.client.client_contact_email
            );
            setBidOwner(response.data.mailbox_detail.bid_owner.id);
            getClientContact(
                response.data.mailbox_detail.client.client_id,
                response.data.mailbox_detail.client.client_contact_id
            );
            setIsLoading(false);
        } catch (err) {}
        return () => {};
    };
    const getServiceType = async () => {
        try {
            const response = await axiosPrivate.get(
                'lookup/get-service-type',
                {}
            );
            setServiceType(response.data.all_status);
        } catch (err) {
            console.error(err);
        }
    };

    const emailDetail = async () => {
        const response = await axiosPrivate.get(
            `mailbox/message-detail/${id}`,
            {}
        );
        setSubject(response.data.subject);
        setEmailCont(response.data.body_html);
    };

    useEffect(() => {
        setValue('cid', clientId);
        setValue('cemail', clientEmail);
        setValue('pname', projectName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientEmail, clientId, clientContactName, bidData, projectName]);

    const getClientContact = async (accountid, cId) => {
        setCcLoading(true);
        getRegion(accountid);
        try {
            const response = await axiosPrivate.get(
                `/client/client-contact-list/${accountid}`,
                {}
            );
            let data = response.data.all_client_contact;

            const clientdata = data.map((item, i) =>
                Object.assign(item, {
                    value: parseInt(item.id),
                    label: item.name,
                })
            );
            setClientContact(clientdata);
            setCcLoading(false);
            data.map((ccid) => {
                if (ccid.id === cId) {
                    setClientContactName(ccid.name);
                }
            });
        } catch (err) {}
        return () => {};
    };
    const getRegion = async (accountid) => {
        try {
            const response = await axiosPrivate.get(
                `client/client-region-list/${accountid}`,
                {}
            );
            let data = response.data.all_client_region;
            if (data.length > 0) {
                setClientRegion(data);
                setShowRegionInput(true);
            } else {
                setShowRegionInput(false);
            }
        } catch (err) {}
        return () => {};
    };

    const handleClient = (value) => {
        setClientId(value);
        clientContact.map((ccid) => {
            if (ccid.id.toString() === value.toString()) {
                setClientEmail(ccid.email);
                setClientContactName(ccid.name);
            }
        });
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'opportunities',
    });

    const updateCloseDate = (rdate) => {
        let date = new Date(rdate);
        date.setDate(date.getDate() + 30);
        setCloseDate(date);
        setValue('close_date', date);
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        var opp = [...data.opportunities];

        opp.map((oppitem, i) => {
            Object.assign(oppitem, {
                status: 1,
                is_project_created: 0,
                id: parseInt(i + 1),
                name: oppitem.lineItem,
                country: { id: parseInt(oppitem.geography.value) },
                category: { id: parseInt(oppitem.category), name: 'string' },
            });
        });
        let lineItems = opp.map((obj) => {
            delete obj.lineItem;
            delete obj.geography;
            return obj;
        });

        let recDate = new Date(receivedDate).setUTCHours(10, 59, 59, 999);
        let cloDate = new Date(closeDate).setUTCHours(10, 59, 59, 999);

        let pstartDate = new Date(projectStartDate).setUTCHours(
            10,
            59,
            59,
            999
        );
        let pEndDate = new Date(projectEndDate).setUTCHours(10, 59, 59, 999);

        let bowner = '',
            opportunityStatus = '';
        let pTypeLabel = '',
            pmName = '',
            passReasonLabel = '',
            regionName = '',
            regionId = 0;
        if (data.bowner) {
            let sel = document.getElementById('bowner');
            bowner = sel.options[sel.selectedIndex].text;
        }

        if (data.opp_status) {
            let sel = document.getElementById('oppStatus');
            opportunityStatus = sel.options[sel.selectedIndex].text;
        }
        if (projectType) {
            let sel = document.getElementById('projectType');
            pTypeLabel = sel.options[sel.selectedIndex].text;
        }
        if (data.pm && parseInt(data.opp_status) === 3) {
            let sel = document.getElementById('pm');
            pmName = sel.options[sel.selectedIndex].text;
        }
        if (showRegionInput && data.region) {
            let sel = document.getElementById('region');
            regionName = sel.options[sel.selectedIndex].text;
            regionId = data.region;
        } else {
            regionName = '';
            regionId = 0;
        }

        if (data.pass_reason && parseInt(data.opp_status) === 6) {
            var sel = document.getElementById('passReason');
            passReasonLabel = sel.options[sel.selectedIndex].text;
        }

        const payload = {
            mailbox_message_id: id ? id : '',
            project_name: data.pname,
            bid_owner: {
                id: data.bowner ? parseInt(data.bowner) : 0,
                name: bowner,
            },
            client: {
                client_id: multiselect[0]._id,
                client_name: multiselect[0].name,
                client_contact_id: data.cid ? parseInt(data.cid) : 0,
                client_contact_name: clientContactName,
                client_contact_email: clientEmail,
                client_region: {
                    id: parseInt(regionId),
                    name: regionName,
                },
            },
            bid_status: {
                id: data.opp_status ? parseInt(data.opp_status) : 0,
                name: opportunityStatus,
            },
            user_status_updated: {
                id: userId,
                name: userName,
                updated_date: moment(today).toISOString(),
            },
            follow_up: 1,
            opportunity: [
                {
                    project_name: data.pname,
                    ...(receivedDate && {
                        received_date: recDate,
                    }),
                    ...(closeDate && {
                        closed_date: cloDate,
                    }),
                    status: {
                        id: data.opp_status ? parseInt(data.opp_status) : 0,
                        name: opportunityStatus,
                    },
                    ...(data.pass_reason &&
                        parseInt(data.opp_status) === 6 && {
                            pass_reason: {
                                id: parseInt(data.pass_reason),
                                name: passReasonLabel,
                            },
                        }),
                    ...(data.pass_comment &&
                        parseInt(data.opp_status) === 6 && {
                            pass_comment: data.pass_comment,
                        }),
                    ...(projectType && {
                        project_type: {
                            id: parseInt(projectType),
                            name: pTypeLabel,
                        },
                    }),
                    ...(data.no_of_waves &&
                        parseInt(projectType) === 5 && {
                            no_waves: parseInt(data.no_of_waves),
                        }),
                    ...(data.frequency &&
                        parseInt(projectType) === 5 && {
                            frequency_id: parseInt(data.frequency),
                        }),
                    ...(projectStartDate &&
                        parseInt(data.opp_status) === 3 && {
                            project_start_date: pstartDate,
                        }),
                    ...(projectEndDate &&
                        parseInt(data.opp_status) === 3 && {
                            project_end_date: pEndDate,
                        }),
                    ...(data.pm &&
                        parseInt(data.opp_status) === 3 && {
                            opp_assign_user: {
                                id: data.pm ? parseInt(data.pm) : 0,
                                name: pmName,
                            },
                        }),
                    ...(data.pm_notes && { notes_user: data.pm_notes }),
                    client_bid_details: editorData,
                    bid_amount: data.bid_amount ? parseInt(data.bid_amount) : 0,
                    margin_bid_amount: {
                        id: parseInt(data.bid_percent),
                        name: data.bid_percent,
                    },
                    bid_consideration: {
                        id: parseInt(data.bid_consideration),
                        name: data.bid_consideration === '1' ? 'Yes' : 'No',
                    },
                    ...(data.booked_amount &&
                        parseInt(data.opp_status) === 3 && {
                            booked_amount: parseInt(data.booked_amount),
                        }),
                    is_project_created: 0,
                    ...((data.opp_status === '2' ||
                        data.opp_status === '3') && {
                        line_item: lineItems,
                    }),
                    ...((data.opp_status === '4' ||
                        data.opp_status === '5' ||
                        data.opp_status === '6') && { line_item: [] }),
                },
            ],
        };

        try {
            await axiosPrivate
                .post('/bid/bid-create', payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    if (e.status === 200) {
                        setIsSubmitting(false);
                        localStorage.setItem('newbid', '1');
                        navigate('/bid-details');
                    }
                });
        } catch (err) {
            if (err.response.status === 409) {
                notifyerr(err.response);
            }
        }
        setIsSubmitting(false);
    };
    /************Calculate Final Amount from booked Amount***************/

    const calculateAmount = () => {
        if (oppStatus === '3') {
            const percentage = getValues('bid_percent');
            const amt = getValues('booked_amount');
            var finalamt = parseFloat(amt) * (parseFloat(percentage) / 100);
            finalamt = Number.isNaN(finalamt) ? 0 : finalamt;
            setDollarAmount(finalamt);
            setValue('amount_dollar', finalamt);
        }
    };
    const changeBookedAmount = (e, amt) => {
        setBookedAmt(amt);
    };

    useEffect(() => {
        setBookedAmt(bookedAmt);
        setValue('booked_amount', bookedAmt);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bidAmt]);

    useEffect(() => {
        calculateAmount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookedAmt, bidAmt]);

    /************Calculate Final Amount from booked Amount***************/

    const bidAmtReg = register('bid_amount', {
        required: 'This field is required',
        pattern: {
            value: /^[0-9]+.?[0-9]*/,
            message: 'Only numbers are allowed',
        },
    });
    const pnameReg = register('pname', { required: 'This field is required' });
    const bownerReg = register('bowner', {
        required: 'This field is required',
    });

    const oppStatusReg = register('opp_status', {
        required: 'This field is required',
    });
    const pTypeReg = register('project_type', {
        required: 'This field is required',
    });

    const bidDetailReg = register('bid_detail');

    const cidReg = register('cid', {
        required: 'This field is required',
    });
    const cemailReg = register('cemail', {
        required: 'This field is required',
        pattern: {
            value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
            message: 'Enter valid email address',
        },
    });
    const updateBidAmount = (e, opp) => {
        e.preventDefault();
        if (opp === '4' || opp === '5' || opp === '6') {
            setValue('bid_amount', 0);
        }
    };

    const showMailContent = (e) => {
        e.preventDefault();
        setModalIsOpen(true);
    };
    const setModalIsOpenToFalse = () => {
        setModalIsOpen(false);
    };

    const getClientsList = async () => {
        try {
            const response = await axiosPrivate.get('/client/client-list', {});

            let data = response.data.all_client;

            var clientlist = [...data];
            const clientdata = clientlist.map((item, i) =>
                Object.assign(item, {
                    value: parseInt(item._id),
                    label: item.name,
                })
            );
            setClients({ data: data, isLoading: false });
        } catch (err) {}
    };

    const handleChange = (e) => {
        setContact_NameLoader(true);
        localStorage.setItem('clientname', e.name);

        var data = [];
        data.push(e);
        setClientId('');
        setClientEmail('');
        setValue('cid', '');
        setValue('cemail', '');
        setMultiselect(data);
        setContact_Multiselect('');
        setAccountId(e._id);
        getClientContact(e._id);
        setValue('cid', '');
        setValue('cemail', '');
    };

    const handleChange_clientcontact = (e) => {
        var data = [];
        data.push(e);

        setValue('cid', e.name);
        setContact_Multiselect(data);

        setContact_Multiselect(e);

        setClientId(e.value);
        handleClient(e.value);
        setContact_NameLoader(false);
    };
    const accountdataReg = register('accountdata', {
        required: 'This field is required',
    });
    useEffect(() => {
        setValue('accountdata', multiselect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiselect]);

    const addLineItem = (e) => {
        e.preventDefault();
        let index = parseInt(fields.length) - 1;
        let lineitemname = getValues('pname');
        let cat = getValues('opportunities[' + index + '].category');
        let geog = getValues('opportunities[' + index + '].geography');
        let irval = getValues('opportunities[' + index + '].ir');
        let loival = getValues('opportunities[' + index + '].loi');
        let nval = getValues('opportunities[' + index + '].n');
        let cpival = getValues('opportunities[' + index + '].cpi');
        append({
            lineItem: lineitemname,
            category: cat,
            geography: geog,
            ir: irval,
            loi: loival,
            n: nval,
            cpi: cpival,
            is_project_created: 0,
        });
    };

    const updateOppLineItem = (pname) => {
        let newOppLine = getValues('opportunities');

        newOppLine.map((oppitem, i) => {
            if (oppitem.lineItem === '' || oppitem.lineItem === projectName) {
                Object.assign(oppitem, {
                    lineItem: pname,
                });
            }
        });

        setProjectName(pname);
        setValue('opportunities', newOppLine);
    };

    return (
        <>
            {/* <Header /> */}
            <BidsSubHeader pname="biddetails" />
            <div className="top_container">
                <div className="main_container">
                    {isLoading ? (
                        <SpinnerLoader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="user_serachbox">
                                <div style={{ float: 'left' }}>
                                    <h3 className="tableHeading">
                                        <img
                                            src={userIco}
                                            className="headingIcons"
                                            alt=""
                                        />{' '}
                                        Create Bid/Opportunity
                                    </h3>
                                </div>
                                <div className="right-pane">
                                    <button
                                        className="cbtn closeBtn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(-1);
                                        }}
                                    >
                                        {' '}
                                        Close{' '}
                                    </button>
                                    <button
                                        disabled={isSubmitting}
                                        className="cbtn saveBtn"
                                    >
                                        {isSubmitting && <ButtonSpinner />}{' '}
                                        &nbsp; Save
                                    </button>
                                    {accountId !== 'undefined' &&
                                    accountId !== '' ? (
                                        <button
                                            className="cbtn addBtn"
                                            onClick={() => {
                                                navigate(
                                                    `/add-client-contact/${accountId}`
                                                );
                                            }}
                                        >
                                            + Add Client Contact{' '}
                                        </button>
                                    ) : (
                                        <button
                                            className="cbtn addBtn"
                                            onClick={() => {
                                                navigate(
                                                    `/add-client-contact/${accountId}`
                                                );
                                            }}
                                            disabled
                                            title="Select account/client first"
                                        >
                                            + Add Client Contact
                                        </button>
                                    )}
                                </div>
                            </div>
                            <hr />
                            <div
                                className="mainContent"
                                style={{ overflowX: 'hidden' }}
                            >
                                <Row>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Project name*
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Project name"
                                            className="formtext"
                                            {...pnameReg}
                                            onChange={(e) => {
                                                pnameReg.onChange(e);
                                            }}
                                            onBlur={(e) => {
                                                updateOppLineItem(
                                                    e.target.value
                                                );
                                            }}
                                            defaultValue={projectName}
                                        />
                                        {errors.pname && (
                                            <small className="errmsg">
                                                {errors.pname.message}
                                            </small>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Bid Owner*
                                        </label>
                                        <select
                                            id="bowner"
                                            className="formtext"
                                            {...bownerReg}
                                            onChange={(e) => {
                                                bownerReg.onChange(e);
                                                setBidOwner(e.target.value);
                                            }}
                                            value={bidOwner}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            <BidOwnerList />
                                        </select>
                                        {errors.bowner && (
                                            <small className="errmsg">
                                                {errors.bowner.message}
                                            </small>
                                        )}
                                    </Col>

                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col formCont"
                                    >
                                        <label className="formlabel">
                                            Account*
                                        </label>

                                        <Select
                                            placeholder="Select Option"
                                            className="formtext"
                                            classNamePrefix="select"
                                            {...accountdataReg}
                                            options={clients.data}
                                            onChange={handleChange}
                                            defaultValue={multiselect}
                                            noOptionsMessage={() =>
                                                'No Data Found'
                                            }
                                            isLoading={clients.isLoading}
                                        />
                                        {multiselect === undefined ? (
                                            <>
                                                {' '}
                                                {errors.accountdata && (
                                                    <small className="errmsg">
                                                        {
                                                            errors.accountdata
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Opportunity/Bid Status*
                                        </label>
                                        <select
                                            id="oppStatus"
                                            className="formtext"
                                            {...oppStatusReg}
                                            onChange={(e) => {
                                                oppStatusReg.onChange(e);
                                                updateBidAmount(
                                                    e,
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            <OppStatus />
                                        </select>
                                        {errors.opp_status && (
                                            <small className="errmsg">
                                                {errors.opp_status.message}
                                            </small>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Contact Name*
                                        </label>
                                        {/* <select
                      className="formtext"
                      {...cidReg}
                      onChange={(e) => {
                        cidReg.onChange(e);
                        setClientId(e.target.value);
                        handleClient(e.target.value);
                      }}
                      value={clientId}
                    >
                      {ccLoading ? (
                        <option value="">Loading...</option>
                      ) : clientContact.length === 0 ? (
                        <option value="">No data found</option>
                      ) : (
                        <>
                          <option value="">Select Option</option>
                          {clientContact.map((e) => (
                            <option value={e.id}>{e.name}</option>
                          ))}
                        </>
                      )}
                    </select> */}

                                        <Select
                                            {...cidReg}
                                            placeholder="Select Option"
                                            className="formtext"
                                            classNamePrefix="select"
                                            value={contact_multiselect}
                                            options={clientContact}
                                            onChange={
                                                handleChange_clientcontact
                                            }
                                            noOptionsMessage={() =>
                                                'No Data Found'
                                            }
                                        />
                                        {contact_multiselect === undefined ||
                                        contact_multiselect === '' ? (
                                            <>
                                                {errors.cid && (
                                                    <small className="errmsg">
                                                        {errors.cid.message}
                                                    </small>
                                                )}
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Client Email*
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Client Email"
                                            className="formtext"
                                            {...cemailReg}
                                            onChange={(e) => {
                                                cemailReg.onChange(e);
                                                setClientEmail(e.target.value);
                                            }}
                                            value={clientEmail}
                                        />
                                        {errors.cemail && !cEmail && (
                                            <small className="errmsg">
                                                {errors.cemail.message}
                                            </small>
                                        )}
                                    </Col>
                                    {showRegionInput && (
                                        <Col
                                            lg={3}
                                            md={4}
                                            sm={6}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Region*
                                            </label>
                                            <select
                                                className="formtext"
                                                id="region"
                                                {...register('region', {
                                                    required:
                                                        'This field is required',
                                                })}
                                            >
                                                <option value="">
                                                    Select Option
                                                </option>
                                                {clientRegion.map((e) => (
                                                    <option value={e.id}>
                                                        {e.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.region && (
                                                <small className="errmsg">
                                                    {errors.region.message}
                                                </small>
                                            )}
                                        </Col>
                                    )}
                                </Row>

                                <hr
                                    style={{
                                        marginTop: '5px',
                                        marginBottom: '0px',
                                    }}
                                />

                                <Row>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Received Date
                                        </label>
                                        <DatePicker
                                            className="formtext"
                                            {...register('received_date')}
                                            selected={
                                                receivedDate
                                                    ? new Date(receivedDate)
                                                    : null
                                            }
                                            onChange={(date) => {
                                                setReceivedDate(date);
                                                updateCloseDate(date);
                                            }}
                                            autoComplete="Off"
                                        />
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Close Date
                                        </label>
                                        <DatePicker
                                            className="formtext"
                                            {...register('close_date')}
                                            selected={
                                                closeDate
                                                    ? new Date(closeDate)
                                                    : null
                                            }
                                            onChange={(date) =>
                                                setCloseDate(date)
                                            }
                                            autoComplete="Off"
                                        />
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Project Type*
                                        </label>
                                        <select
                                            className="formtext"
                                            {...pTypeReg}
                                            id="projectType"
                                            onChange={(e) => {
                                                oppStatusReg.onChange(e);
                                                setProjectType(e.target.value);
                                            }}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            <ProjectType />
                                        </select>
                                        {errors.project_type && (
                                            <small className="errmsg">
                                                {errors.project_type.message}
                                            </small>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            No of Waves
                                        </label>
                                        <select
                                            className="formtext"
                                            {...register('no_of_waves')}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            {projectType === '5' ? (
                                                <>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">
                                                        6
                                                    </option>{' '}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </select>
                                        {errors.no_of_waves && (
                                            <small className="errmsg">
                                                {errors.no_of_waves.message}
                                            </small>
                                        )}
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Frequency
                                        </label>
                                        <select
                                            className="formtext"
                                            {...register('frequency')}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            {projectType === '5' ? (
                                                <>
                                                    <option value="1">
                                                        Weekly
                                                    </option>
                                                    <option value="2">
                                                        Monthly
                                                    </option>
                                                    <option value="3">
                                                        Quaterly
                                                    </option>{' '}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </select>
                                    </Col>
                                    <Col
                                        lg={3}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label
                                            className="formlabel"
                                            title="Indicates if the bid should be considered in the total count of bids for this account"
                                        >
                                            Bid Consideration*{' '}
                                        </label>
                                        <select
                                            className="formtext"
                                            {...register('bid_consideration', {
                                                required:
                                                    'This field is required',
                                            })}
                                            defaultValue={1}
                                        >
                                            <option value="">
                                                Select Option
                                            </option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        {errors.bid_consideration && (
                                            <small className="errmsg">
                                                {
                                                    errors.bid_consideration
                                                        .message
                                                }
                                            </small>
                                        )}
                                    </Col>
                                    {oppStatus === '6' && (
                                        <Col lg={3} xs={12} className="col">
                                            <label className="formlabel">
                                                Pass Reason*
                                            </label>
                                            <select
                                                id="passReason"
                                                className="formtext"
                                                {...register('pass_reason', {
                                                    required:
                                                        'This field is required',
                                                })}
                                            >
                                                <option value="">
                                                    Select Option
                                                </option>
                                                <option value="1">
                                                    No Feasibility
                                                </option>
                                                <option value="2">
                                                    CPI Offered Low
                                                </option>
                                                <option value="3">
                                                    Difficult Target
                                                </option>
                                                <option value="4">
                                                    Others
                                                </option>
                                            </select>
                                            {errors.pass_reason && (
                                                <small className="errmsg">
                                                    {errors.pass_reason.message}
                                                </small>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                                {oppStatus === '6' && (
                                    <Row id="passBlock">
                                        {pReason === '4' && (
                                            <Col
                                                lg={12}
                                                xs={12}
                                                className="col"
                                            >
                                                <label className="formlabel"></label>
                                                <input
                                                    type="text"
                                                    placeholder="If Others,  define"
                                                    className="formtext"
                                                    {...register(
                                                        'pass_comment',
                                                        {
                                                            required:
                                                                'This field is required',
                                                        }
                                                    )}
                                                />
                                                {errors.pass_comment && (
                                                    <small className="errmsg">
                                                        {
                                                            errors.pass_comment
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                )}

                                <Row>
                                    <div className="opportunity_main formCont">
                                        {showOpportunity.indexOf(oppStatus) >
                                            -1 && (
                                            <>
                                                <Accordion
                                                    style={{
                                                        marginTop: '10px',
                                                    }}
                                                    preExpanded={[0]}
                                                >
                                                    {fields.map(
                                                        (item, index) => (
                                                            <AccordionItem
                                                                id={index}
                                                                key={item.id}
                                                                uuid={index}
                                                            >
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        <div className="line_item">
                                                                            <div className="itemWrap">
                                                                                <label className="formlabel">
                                                                                    Line
                                                                                    Item
                                                                                    #
                                                                                    {index +
                                                                                        1}

                                                                                    *
                                                                                </label>
                                                                                <input
                                                                                    type="text"
                                                                                    {...register(
                                                                                        `opportunities[${index}].lineItem`,
                                                                                        {
                                                                                            required:
                                                                                                'This field is required',
                                                                                        }
                                                                                    )}
                                                                                    defaultValue={
                                                                                        item.lineItem
                                                                                    }
                                                                                    placeholder=""
                                                                                    className="formtext"
                                                                                />
                                                                            </div>
                                                                            {errors.opportunities &&
                                                                                errors
                                                                                    .opportunities[
                                                                                    `${index}`
                                                                                ] && (
                                                                                    <small
                                                                                        className="topzero"
                                                                                        id="topzero"
                                                                                    >
                                                                                        &nbsp;
                                                                                        &nbsp;
                                                                                        PLease
                                                                                        fill
                                                                                        all
                                                                                        mandatory
                                                                                        fields
                                                                                        in
                                                                                        line
                                                                                        item.
                                                                                    </small>
                                                                                )}
                                                                        </div>
                                                                        <img
                                                                            src={
                                                                                DeleteIcon
                                                                            }
                                                                            alt="Delete Row"
                                                                            className="deleteBtn"
                                                                            onClick={() => {
                                                                                confirmAlert(
                                                                                    {
                                                                                        message:
                                                                                            'Are you sure, you want to delete this?',
                                                                                        buttons:
                                                                                            [
                                                                                                {
                                                                                                    label: 'Yes',
                                                                                                    onClick:
                                                                                                        () => {
                                                                                                            remove(
                                                                                                                index
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                                {
                                                                                                    label: 'No',
                                                                                                },
                                                                                            ],
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <div
                                                                        className="extra_client_input_header"
                                                                        key="1"
                                                                    >
                                                                        <Row>
                                                                            <Col
                                                                                lg={
                                                                                    3
                                                                                }
                                                                                md={
                                                                                    3
                                                                                }
                                                                                sm={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="col"
                                                                            >
                                                                                <label className="formlabel">
                                                                                    Service
                                                                                    Type*{' '}
                                                                                </label>
                                                                                <select
                                                                                    className="formtext"
                                                                                    {...register(
                                                                                        `opportunities[${index}].category`,
                                                                                        {
                                                                                            required:
                                                                                                'This field is required',
                                                                                        }
                                                                                    )}
                                                                                    defaultValue={
                                                                                        item.category
                                                                                    }
                                                                                >
                                                                                    <option value="">
                                                                                        Select
                                                                                        Option
                                                                                    </option>
                                                                                    {serviceType.map(
                                                                                        (
                                                                                            status,
                                                                                            i
                                                                                        ) => (
                                                                                            <option
                                                                                                value={
                                                                                                    status?.id
                                                                                                }
                                                                                                key={
                                                                                                    i
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    status?.name
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </Col>
                                                                            <Col
                                                                                lg={
                                                                                    3
                                                                                }
                                                                                md={
                                                                                    3
                                                                                }
                                                                                sm={
                                                                                    6
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="col"
                                                                            >
                                                                                <label className="formlabel">
                                                                                    {' '}
                                                                                    Geography*{' '}
                                                                                </label>
                                                                                <Controller
                                                                                    name={`opportunities[${index}].geography`}
                                                                                    {...register(
                                                                                        `opportunities[${index}].geography`,
                                                                                        {
                                                                                            required:
                                                                                                'This field is required',
                                                                                        }
                                                                                    )}
                                                                                    control={
                                                                                        control
                                                                                    }
                                                                                    rules={{
                                                                                        required:
                                                                                            'Area is required',
                                                                                    }}
                                                                                    render={({
                                                                                        field,
                                                                                    }) => (
                                                                                        <Select
                                                                                            value={
                                                                                                field.value
                                                                                            }
                                                                                            onChange={
                                                                                                field.onChange
                                                                                            }
                                                                                            ref={
                                                                                                field.ref
                                                                                            }
                                                                                            placeholder="Select Your Area"
                                                                                            options={
                                                                                                geography
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                lg={
                                                                                    6
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                sm={
                                                                                    12
                                                                                }
                                                                                xs={
                                                                                    12
                                                                                }
                                                                                className="col"
                                                                            >
                                                                                <Row>
                                                                                    <Col
                                                                                        lg={
                                                                                            3
                                                                                        }
                                                                                        md={
                                                                                            3
                                                                                        }
                                                                                        sm={
                                                                                            3
                                                                                        }
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        className="col"
                                                                                    >
                                                                                        <label className="formlabel">
                                                                                            IR%*
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="formtext"
                                                                                            {...register(
                                                                                                `opportunities[${index}].ir`,
                                                                                                {
                                                                                                    required:
                                                                                                        'This field is required',
                                                                                                }
                                                                                            )}
                                                                                            defaultValue={
                                                                                                item.ir
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg={
                                                                                            3
                                                                                        }
                                                                                        md={
                                                                                            3
                                                                                        }
                                                                                        sm={
                                                                                            3
                                                                                        }
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        className="col"
                                                                                    >
                                                                                        <label className="formlabel">
                                                                                            LOI(Min)*{' '}
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="formtext"
                                                                                            {...register(
                                                                                                `opportunities[${index}].loi`,
                                                                                                {
                                                                                                    required:
                                                                                                        'This field is required',
                                                                                                }
                                                                                            )}
                                                                                            defaultValue={
                                                                                                item.loi
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg={
                                                                                            3
                                                                                        }
                                                                                        md={
                                                                                            3
                                                                                        }
                                                                                        sm={
                                                                                            3
                                                                                        }
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        className="col"
                                                                                    >
                                                                                        <label className="formlabel">
                                                                                            N*
                                                                                        </label>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="formtext"
                                                                                            {...register(
                                                                                                `opportunities[${index}].n`,
                                                                                                {
                                                                                                    required:
                                                                                                        'This field is required',
                                                                                                }
                                                                                            )}
                                                                                            defaultValue={
                                                                                                item.n
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                    <Col
                                                                                        lg={
                                                                                            3
                                                                                        }
                                                                                        md={
                                                                                            3
                                                                                        }
                                                                                        sm={
                                                                                            3
                                                                                        }
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                        className="col"
                                                                                    >
                                                                                        <label className="formlabel">
                                                                                            CPI*
                                                                                        </label>
                                                                                        <input
                                                                                            type="text"
                                                                                            className="formtext"
                                                                                            {...register(
                                                                                                `opportunities[${index}].cpi`,
                                                                                                {
                                                                                                    required:
                                                                                                        'This field is required',
                                                                                                    pattern:
                                                                                                        {
                                                                                                            value: /^[+-]?([0-9]*[.])?[0-9]+/,
                                                                                                            message:
                                                                                                                'Enter numeric value',
                                                                                                        },
                                                                                                }
                                                                                            )}
                                                                                            defaultValue={
                                                                                                item.cpi
                                                                                            }
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        )
                                                    )}
                                                </Accordion>
                                                {(oppStatus === '3' ||
                                                    oppStatus === '2') && (
                                                    <CalcAmtBid
                                                        control={control}
                                                        setValue={setValue}
                                                        setBookedAmt={
                                                            setBookedAmt
                                                        }
                                                        setBidAmt={setBidAmt}
                                                        style={{
                                                            visibility:
                                                                'hidden',
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}

                                        {showOpportunity.indexOf(oppStatus) >
                                            -1 && (
                                            <button
                                                className="cbtn cbtnMargin addBtn"
                                                onClick={(e) => {
                                                    addLineItem(e);
                                                }}
                                            >
                                                + Add Line Item
                                            </button>
                                        )}
                                        {id ? (
                                            <button
                                                className="cbtn cbtnMargin closeBtn"
                                                onClick={(e) => {
                                                    showMailContent(e);
                                                }}
                                            >
                                                View Mail
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </Row>

                                <Row>
                                    <Col
                                        lg={3}
                                        md={3}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                    >
                                        <label className="formlabel">
                                            Bid Amount*
                                        </label>
                                        <input
                                            type="text"
                                            className="formtext"
                                            {...bidAmtReg}
                                            readOnly={oppStatus === '3'}
                                            onChange={(e) => {
                                                bidAmtReg.onChange(e);
                                                calculateAmount();
                                            }}
                                        />
                                        {errors.bid_amount && (
                                            <small className="errmsg">
                                                {errors.bid_amount.message}
                                            </small>
                                        )}
                                    </Col>

                                    {oppStatus === '3' && (
                                        <>
                                            <Col
                                                lg={3}
                                                md={3}
                                                sm={6}
                                                xs={12}
                                                className="col"
                                            >
                                                <label className="formlabel">
                                                    Booked Amount*
                                                </label>
                                                <Controller
                                                    name={'booked_amount'}
                                                    {...register(
                                                        'booked_amount'
                                                    )}
                                                    control={control}
                                                    rules={{
                                                        required:
                                                            'This field is required',
                                                        pattern: {
                                                            value: /^[0-9]*$/,
                                                            message:
                                                                'Only numbers are allowed',
                                                        },
                                                    }}
                                                    value={bookedAmt}
                                                    render={({ field }) => (
                                                        <input
                                                            type="text"
                                                            className="formtext"
                                                            value={field.value}
                                                            ref={field.ref}
                                                            placeholder="Booked Amount"
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e
                                                                );
                                                                changeBookedAmount(
                                                                    e,
                                                                    e.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {errors.booked_amount && (
                                                    <small className="errmsg">
                                                        {
                                                            errors.booked_amount
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                            <Col
                                                lg={3}
                                                md={3}
                                                sm={6}
                                                xs={12}
                                                className="col"
                                            >
                                                <label className="formlabel">
                                                    % of Booked Amount*
                                                </label>

                                                <Controller
                                                    name={'bid_percent'}
                                                    {...register('bid_percent')}
                                                    control={control}
                                                    rules={{
                                                        required:
                                                            'This field is required',
                                                    }}
                                                    value={bidPercentage}
                                                    render={({ field }) => (
                                                        <select
                                                            className="formtext"
                                                            value={field.value}
                                                            ref={field.ref}
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    e
                                                                );
                                                                setBidPercentage(
                                                                    e.target
                                                                        .value
                                                                );
                                                                calculateAmount();
                                                            }}
                                                        >
                                                            <BidMarginPercent />
                                                        </select>
                                                    )}
                                                />
                                                {errors.bid_percent && (
                                                    <small className="errmsg">
                                                        {
                                                            errors.bid_percent
                                                                .message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                            <Col lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabel">
                                                    Final Amount($)
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder=""
                                                    className="formtext"
                                                    readOnly
                                                    {...register(
                                                        'amount_dollar'
                                                    )}
                                                    defaultValue={dollarAmount}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>

                                {oppStatus === '3' && (
                                    <Row id="bidWon">
                                        <Col
                                            lg={2}
                                            md={4}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Project Start Date*
                                            </label>
                                            <DatePicker
                                                className="formtext"
                                                {...register(
                                                    'project_start_date'
                                                )}
                                                selected={
                                                    projectStartDate
                                                        ? new Date(
                                                              projectStartDate
                                                          )
                                                        : null
                                                }
                                                onChange={(date) =>
                                                    setProjectStartDate(date)
                                                }
                                                autoComplete="Off"
                                            />
                                            {errors.project_start_date && (
                                                <small className="errmsg">
                                                    {
                                                        errors
                                                            .project_start_date
                                                            .message
                                                    }
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={2}
                                            md={4}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Project End Date*
                                            </label>
                                            <DatePicker
                                                className="formtext"
                                                {...register(
                                                    'project_end_date'
                                                )}
                                                selected={
                                                    projectEndDate
                                                        ? new Date(
                                                              projectEndDate
                                                          )
                                                        : null
                                                }
                                                onChange={(date) =>
                                                    setProjectEndDate(date)
                                                }
                                                autoComplete="Off"
                                            />

                                            {errors.project_start_date && (
                                                <small className="errmsg">
                                                    {
                                                        errors
                                                            .project_start_date
                                                            .message
                                                    }
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={2}
                                            md={4}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                PM*
                                            </label>
                                            <select
                                                id="pm"
                                                className="formtext"
                                                {...register('pm')}
                                                readOnly
                                                value="133"
                                            >
                                            {/* // for staging and dev */}
                                                <option value="133" selected>
                                            {/* // for production */}
                                                {/* <option value="126" selected> */}
                                                    CS Team
                                                </option>
                                                {/* <PMList /> */}
                                            </select>
                                            {errors.pm && (
                                                <small className="errmsg">
                                                    {errors.pm.message}
                                                </small>
                                            )}
                                        </Col>

                                        <Col
                                            lg={6}
                                            md={12}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Notes for PM
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="If Others,  define"
                                                className="formtext"
                                                {...register('pm_notes')}
                                                {...register('pm_notes')}
                                            />
                                            {errors.pm_notes && (
                                                <small className="errmsg">
                                                    {errors.pm_notes.message}
                                                </small>
                                            )}
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col>
                                        <label className="formlabel">
                                            Client Bid Details{' '}
                                        </label>
                                        <CKEditor
                                            {...bidDetailReg}
                                            defaultvalue={editorData !== ''}
                                            editor={Editor}
                                            data={editorData}
                                            onChange={(event, editor) => {
                                                setValue(
                                                    'bid_detail',
                                                    editor.getData()
                                                );
                                                setEditorData(editor.getData());
                                            }}
                                        />
                                        {errors.bid_detail && !bidDetail && (
                                            <small className="errmsg">
                                                {errors.bid_detail.message}
                                            </small>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <Modal isOpen={modalIsOpen}>
                <button onClick={setModalIsOpenToFalse} className="closeModal">
                    x{' '}
                </button>
                <ModalHeader>
                    <h5>{subject}</h5>
                    <hr />
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{ __html: emailCont }} />
                </ModalBody>
            </Modal>
        </>
    );
};
export default CreateBid;
