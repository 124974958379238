import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import toast from "react-hot-toast";
import "./ExclusionSetting.css";
const RedirectURL = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const pname = "Redirect URL";
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [formData, setFormData] = useState({
    complete: "",
    Terms: "",
    Over_Quotas: "",
    security_terms: "",
  });
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const notifysucces = () => toast.success("Updated successfully");
  const fetchDataWithToken = async () => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(`/supplyinfo/get-supplier/${id}`);
      setFormData((prevData) => ({
        ...prevData,
        complete: response.data.supplier.redirect_url.complete,
        Terms: response.data.supplier.redirect_url.terms,
        Over_Quotas: response.data.supplier.redirect_url.overquotas,
        security_terms: response.data.supplier.redirect_url.security_terms,
      }));

      setLoder(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);

      if (response.data.supplier.redirect_url !== null) {
        setFormData((prevData) => ({
          ...prevData,
          complete: response.data.supplier.redirect_url.complete,
          Terms: response.data.supplier.redirect_url.terms,
          Over_Quotas: response.data.supplier.redirect_url.overquotas,
          security_terms: response.data.supplier.redirect_url.quality_terms,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          complete: "",
          Terms: "",
          Over_Quotas: "",
          security_terms: "",
        }));
      }

      setLoder(true);
    } catch (err) {
      console.error(err);
    }
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        .post(
          `/supplyinfo/supplier-redirect-url/${id}`,
          JSON.stringify({
            complete: e.Complete,
            terms: e.Terms,
            overquotas: e.OverQuotas,
            quality_terms: e.SecurityTerms,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };
  return (
    <>
      <SettingHeader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(postData)}>
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img
                    src={userIco}
                    className="headingIcons"
                    alt="Add Department"
                  />{" "}
                  Redirect Url
                </h3>
              </div>

              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/supplier-details");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && <ButtonSpinner />} &nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            {loder ? (
              <>
                <div id="dividerow" className="mainContent">
                  <Row className=" redirect_url_row">
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Complete*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("Complete", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Complete"
                        className="CsformText supplier_setting"
                        defaultValue={formData.complete}
                      />
                      {errors.Complete && (
                        <small className="supplier_setting_error">
                          {errors.Complete.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className=" redirect_url_row">
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Terms*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("Terms", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Terms"
                        className="CsformText supplier_setting"
                        defaultValue={formData.Terms}
                      />
                      {errors.Terms && (
                        <small className="supplier_setting_error">
                          {errors.Terms.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className=" redirect_url_row">
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Over Quotas*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("OverQuotas", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Over Quotas"
                        className="CsformText supplier_setting"
                        defaultValue={formData.Over_Quotas}
                      />
                      {errors.OverQuotas && (
                        <small className="supplier_setting_error">
                          {errors.OverQuotas.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row className=" redirect_url_row">
                    <Col lg={2} md={2} xs={12} className="col">
                      <label className="formlabel">Quality Terms*</label>
                    </Col>
                    <Col lg={10} md={10} xs={12} className="col">
                      <input
                        {...register("SecurityTerms", {
                          required: "This field is Required",
                        })}
                        type="text"
                        placeholder="Quality Terms"
                        className="CsformText supplier_setting"
                        defaultValue={formData.security_terms}
                      />
                      {errors.SecurityTerms && (
                        <small className="supplier_setting_error">
                          {errors.SecurityTerms.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default RedirectURL;
