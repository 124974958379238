import { useState, useEffect } from "react";
import Invoiceids from "./popup/Invoiceids";
import InvoicBillingattach from "./popup/InvoiceBillingattach";
import Invoicesurveylogs from "./popup/InvoiceSurveylogs";
import { BiUpload, BiPurchaseTag } from "react-icons/bi";
import { GrAddCircle } from "react-icons/gr";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "bootstrap/dist/css/bootstrap.css";
import "./Invoice.css";
import { useNavigate } from "react-router";
import Editpop from "./popup/Editpop";

import { useParams } from "react-router";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
import NewaddedBox from "./NewaddedBox";
import Projectinvoiceids from "./popup/Projectinvoiceids";
const Invoicing = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { id, layoutId, survey_Id } = useParams();

  const [invoiceidsshow, setInvoiceidsShow] = useState(false);
  const [invoicelogs, setInvoicelogs] = useState(false);
  const [invoiceuploadids, setInvoiceuploadids] = useState(false);
  const [projectinvoiceuploadids, setProjectInvoiceuploadids] = useState(false);
  const [invoiceedit, setInvoiceEdit] = useState(false);

  const [survey_idselected, setSurvey_idselected] = useState("");
  const [loder, setLoder] = useState(false);

  const [data, setData] = useState([]);

  const [addedBoxes, setAddedBoxes] = useState({});
  const [switchbtn, setSwitchbtn] = useState({});

  const [countBox, setCountBox] = useState({});
  const [project_name, setProject_Name] = useState("");
  const [survey_name, setSurvey_Name] = useState("");
  const [activeSections, setActiveSections] = useState([]);

  const [call_api, setCall_Api] = useState(false);
  useEffect(() => {
    get_Multiform_api();
  }, [id]);
  useEffect(() => {
    if (call_api === true) {
      get_Multiform_api();
      setCall_Api(false);
    }
  }, [call_api]);
  const get_Multiform_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `invoice/get-survey-invoice?project_id=${id}`
      );

      setData(response.data.billingsurvey.survey_list);

      setProject_Name(response.data.billingsurvey.project_name);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  const handleAddIconclick = (boxid) => {
    if (addedBoxes[boxid] !== undefined) {
      setAddedBoxes((prevState) => {
        const newState = { ...prevState };
        const index = countBox[boxid] || 0;
        if (!newState[boxid]) {
          newState[boxid] = [{ id: 0, CLCount: "", CPI: "", delete: false }];
        } else {
          newState[boxid] = [
            ...newState[boxid],
            { id: 0, CLCount: "", CPI: "", delete: false },
          ];
        }
        return newState;
      });

      setSwitchbtn((prevState) => {
        const newState = { ...prevState };
        setCountBox((prevCountBox) => ({
          ...prevCountBox,
          [boxid]: prevCountBox[boxid]
            ? prevCountBox[boxid] + 1
            : addedBoxes[boxid].length,
        }));
        const index = addedBoxes[boxid].length || 0;
        if (!newState[boxid]) {
          newState[boxid] = [{ id: `${boxid}${index}`, state: false }];
        } else {
          newState[boxid] = [
            ...newState[boxid],
            { id: `${boxid}${index}`, state: false },
          ];
        }
        return newState;
      });
    }
  };

  const get_additional_cost = async (id) => {
    try {
      await axiosPrivate
        .get(`invoice/get-additional-cost?survey_id=${id}`)
        .then((e) => {
          if (e.status === 200) {
            const newData = e.data.additional_billing.map((item) => ({
              id: item.id || 0,
              CLCount: item.cl_count || "",
              CPI: item.cpi || "",
              REMARKS: item.remark || "",
              delete: false,
            }));
            e.data.additional_billing.map((item, index) => {
              const boxid = id;
              setCountBox((prevCountBox) => ({
                ...prevCountBox,
                [`${boxid}${index}`]: prevCountBox[`${boxid}${index}`]
                  ? prevCountBox[`${boxid}${index}`] + 1
                  : 1,
              }));
            });
            setAddedBoxes((prevState) => ({
              ...prevState,
              [id]: newData,
            }));

            const newDatastate = e.data.additional_billing.map(
              (item, indexitem) => ({
                id: `${id}${indexitem}`,
                state: true,
              })
            );
            setSwitchbtn((prevState) => ({
              ...prevState,
              [id]: newDatastate,
            }));
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowSect = (id) => {
    get_additional_cost(id);

    let element = document.getElementById("header" + id);
    if (element.classList.contains("open")) {
      document
        .querySelectorAll(".table_box")
        .forEach((el) => el.classList.remove("open"));
    } else {
      document
        .querySelectorAll(".table_box")
        .forEach((el) => el.classList.remove("open"));
      element.classList.add("open");
    }
    const index = activeSections.indexOf(id);
    if (index === -1) {
      setActiveSections([id]);
    } else {
      activeSections.splice(index, 1);
      setActiveSections([activeSections]);
    }
  };

  useEffect(() => {
    if (survey_Id !== "1") {
      if (loder === true) {
        handleShowSect(`${survey_Id}`);
      }
    } else if (survey_Id === "1") {
      if (loder === true) {
        handleShowSect(`${data[0].id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loder]);

  return (
    <>
      {InvoicBillingattach ? (
        <>
          <InvoicBillingattach
            show={invoiceidsshow}
            onHide={() => setInvoiceidsShow(false)}
          />
        </>
      ) : (
        <></>
      )}
      {Invoicesurveylogs ? (
        <>
          <Invoicesurveylogs
            show={invoicelogs}
            onHide={() => setInvoicelogs(false)}
          />
        </>
      ) : (
        <></>
      )}
      {invoiceuploadids ? (
        <>
          <Invoiceids
            show={invoiceuploadids}
            onHide={() => setInvoiceuploadids(false)}
            setInvoiceuploadids={setInvoiceuploadids}
            survey_idselected={survey_idselected}
            survey_name={survey_name}
            setCall_Api={setCall_Api}
          />
        </>
      ) : (
        <></>
      )}

      {projectinvoiceuploadids ? (
        <>
          <Projectinvoiceids
            show={projectinvoiceuploadids}
            onHide={() => setProjectInvoiceuploadids(false)}
            setProjectInvoiceuploadids={setProjectInvoiceuploadids}
            survey_idselected={survey_idselected}
            project_name={project_name}
            setCall_Api={setCall_Api}
          />
        </>
      ) : (
        <></>
      )}
      {Editpop ? (
        <>
          <Editpop show={invoiceedit} onHide={() => setInvoiceEdit(false)} />
        </>
      ) : (
        <></>
      )}
      <hr className="csHR" />
      <div className="cost_main_cont">
        {loder ? (
          <>
            <div className="supplyInfocost">
              <p className="supinfoTitle">{project_name}</p>
              <div className="supplyInfoT_icon">
                <BiPurchaseTag
                  title="Logs"
                  className="invoice_two_icon"
                  size={25}
                  onClick={() => {
                    setInvoicelogs(true);
                  }}
                />
                <BiUpload
                  title="Upload"
                  className="invoice_two_icon"
                  size={25}
                  onClick={() => {
                    setProjectInvoiceuploadids(true);
                  }}
                />
              </div>
            </div>
            {data.length == 0 ? (
              <>
                {" "}
                <div
                  className="alert alert-danger"
                  style={{ textAlign: "center", margin: "15px" }}
                  role="alert"
                >
                  No survey Found!
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="borderColorCss">
                  {data.map((itemobj, indexitem) => (
                    <>
                      <div
                        className="table_box csmulti "
                        id={"header" + itemobj.id}
                        key={indexitem}
                      >
                        <div
                          className="InvoiceHead pjt_arrow_icon "
                          id={"iconSect" + itemobj.id}
                          onClick={(e) => {
                            handleShowSect(itemobj.id, e);
                            navigate(
                              `/project-detail/${id}/${layoutId}/${itemobj.id}`
                            );
                          }}
                        >
                          <p className="pBlockHead">
                            {indexitem === data.length + 1 ? (
                              <></>
                            ) : (
                              <>
                                {itemobj.survey_name}---{itemobj.sc_id}
                              </>
                            )}
                          </p>

                          {itemobj.survey_status.name == "Live" ? (
                            <>
                              <p className="liveBtn m-0">Live</p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name == "Pending" ? (
                            <>
                              <p className="pendingBtnagain m-0">Pending</p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name == "Test Mode" ? (
                            <>
                              <p className="testmodeBtnagain m-0">Test Mode</p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name === "Paused" ? (
                            <>
                              <p className="pausedBtnagain m-0">Paused</p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name === "Id Awaited" ? (
                            <>
                              <p className="IdAwaitedBtnagain m-0">
                                Id Awaited
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name === "Billed" ? (
                            <>
                              <p className="billedBtnagain m-0">Billed</p>
                            </>
                          ) : (
                            <></>
                          )}
                          {itemobj.survey_status.name === "Cancelled" ? (
                            <>
                              <p className="cancelledBtnagain m-0">Cancelled</p>
                            </>
                          ) : (
                            <></>
                          )}
                          <div className="supplyInfoT_icon_inside">
                            {(indexitem === data.length + 1) !== "Summary" ? (
                              <>
                                {" "}
                                <GrAddCircle
                                  title="Add"
                                  size={20}
                                  className="invoice_two_icon"
                                  onClick={(e) => {
                                    handleAddIconclick(itemobj.id);
                                    e.stopPropagation();
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}

                            <BiUpload
                              title="Upload"
                              className="invoice_two_icon"
                              size={20}
                              onClick={(e) => {
                                setInvoiceuploadids(true);
                                e.stopPropagation();
                                setSurvey_idselected(itemobj.id);
                                setSurvey_Name(itemobj.survey_name);
                              }}
                            />
                            <div className="pjt_arrow_icon costup_icon">
                              <KeyboardArrowUpIcon
                                className={`SIconUp icon-${itemobj.id}-down`}
                                style={{
                                  display: activeSections.includes(
                                    `${itemobj.id}`
                                  )
                                    ? "block"
                                    : "none",
                                }}
                              />
                              <KeyboardArrowDownIcon
                                className={`SDropIcon  icon-${itemobj.id}-up`}
                                style={{
                                  display: activeSections.includes(
                                    `${itemobj.id}`
                                  )
                                    ? "none"
                                    : "block",
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <>
                          <div
                            style={{ padding: "6px" }}
                            className="borderColorCss "
                          >
                            <Table
                              bordered={false}
                              hover
                              size="sm"
                              style={{ margin: "0px" }}
                              className="invoice_customTable"
                              id={"table" + itemobj.id}
                            >
                              <thead
                                id={"surveyHeaderBottom" + itemobj.id}
                                className=" headerTwodefault"
                                style={{}}
                              >
                                {indexitem === data.length + 1 ? (
                                  <></>
                                ) : (
                                  <>
                                    <tr>
                                      <td>SC Count</td>
                                      <td>Approved Count</td>
                                      <td>CPI</td>
                                      <td>SC Revenue</td>
                                      <td>CL Revenue </td>
                                      <td>Remarks</td>
                                    </tr>
                                  </>
                                )}
                              </thead>

                              <tbody
                                id={"surveyHeaderBody" + itemobj.id}
                                className="headerTwobody headerTwodefaultbody"
                                style={{
                                  backgroundColor: " #f5f3f3",
                                }}
                              >
                                <tr key={itemobj.id}>
                                  <td>{itemobj.total_summary.sc_count}</td>

                                  <td>{itemobj.total_summary.cl_count}</td>
                                  <td>-</td>
                                  <td>{itemobj.total_summary.sc_revenue}</td>
                                  <td>
                                    {itemobj.total_summary.billed_revenue}
                                  </td>

                                  <td>-</td>
                                </tr>
                              </tbody>
                            </Table>
                            <div
                              id={"firstSect" + itemobj.id}
                              className={"cost_table dropeTablesupply"}
                            >
                              {indexitem === data.length + 1 ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    backgroundColor: "#f5f3f3",
                                  }}
                                >
                                  <Table>
                                    <thead>
                                      <tr>
                                        <td
                                          style={{
                                            width: "15%",
                                          }}
                                        >
                                          SC Count
                                        </td>
                                        <td
                                          style={{
                                            width: "15%",
                                            maxWidth: "100px",
                                          }}
                                        >
                                          Approved Count
                                        </td>
                                        <td
                                          style={{
                                            width: "10%",
                                            maxWidth: "50px",
                                          }}
                                        >
                                          CPI
                                        </td>
                                        <td
                                          style={{
                                            width: "15%",
                                            maxWidth: "50px",
                                          }}
                                        >
                                          SC Revenue
                                        </td>
                                        <td
                                          style={{
                                            width: "15%",
                                            maxWidth: "50px",
                                          }}
                                        >
                                          {" "}
                                          CL Revenue
                                        </td>
                                        <td>Remarks</td>

                                        <td></td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {itemobj?.client_summary !== undefined &&
                                        itemobj?.client_summary.map(
                                          (additionalItem) => (
                                            <tr key={itemobj.id}>
                                              <td>{additionalItem.sc_count}</td>
                                              <td>{additionalItem.cl_count}</td>
                                              <td>{additionalItem.cpi}</td>
                                              <td>{`${additionalItem.sc_revenue}`}</td>
                                              <td>{`${additionalItem.cl_revenue}`}</td>

                                              <td>{additionalItem.remark}</td>
                                              <td></td>
                                            </tr>
                                          )
                                        )}
                                      {itemobj?.additional_cost !== undefined &&
                                        itemobj?.additional_cost.map(
                                          (additionalItem) => (
                                            <tr key={itemobj.id}>
                                              <td>Additional cost</td>
                                              <td>
                                                {" "}
                                                {additionalItem.cl_count}
                                              </td>
                                              <td>{additionalItem.cpi}</td>
                                              <td>-</td>
                                              <td>{`${
                                                additionalItem.cl_count *
                                                additionalItem.cpi
                                              }`}</td>

                                              <td>{additionalItem.remark}</td>
                                              <td></td>
                                            </tr>
                                          )
                                        )}
                                    </tbody>
                                    <tfoot
                                      style={{
                                        backgroundColor: "rgb(202 202 203)",
                                      }}
                                    >
                                      <tr key={itemobj.id}>
                                        <td>
                                          {itemobj.total_summary.sc_count}
                                        </td>

                                        <td>
                                          {itemobj.total_summary.cl_count}
                                        </td>
                                        <td>-</td>
                                        <td>
                                          {itemobj.total_summary.sc_revenue}
                                        </td>
                                        <td>
                                          {itemobj.total_summary.billed_revenue}
                                        </td>

                                        <td>-</td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </Table>
                                </div>
                              )}

                              {addedBoxes[itemobj.id] &&
                                addedBoxes[itemobj.id].map((box, boxIndex) => (
                                  <>
                                    {box.delete === false ? (
                                      <>
                                        <div
                                          key={itemobj.id + boxIndex}
                                          id={box.id}
                                          className="boxselect"
                                          style={{
                                            backgroundColor: "#f5f3f3",
                                          }}
                                        >
                                          <NewaddedBox
                                            idb={itemobj.id}
                                            boxIndex={boxIndex}
                                            switchbtn={switchbtn}
                                            setSwitchbtn={setSwitchbtn}
                                            addedBoxes={addedBoxes}
                                            setAddedBoxes={setAddedBoxes}
                                            setData={setData}
                                            data={data}
                                            setLoder_stats={setLoder}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                ))}
                            </div>
                          </div>
                        </>

                        {/* </Table> */}
                      </div>
                    </>
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <SpinnerLoader />
        )}
      </div>
    </>
  );
};

export default Invoicing;
