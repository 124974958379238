import React, { useState, useEffect, useRef } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import toast from 'react-hot-toast';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { BiPlus, BiEditAlt } from 'react-icons/bi';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';

import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import './EditQuotaModal.css';

import { useForm, SubmitHandler } from 'react-hook-form';
import AddConditionValues from './AddConditionValues';

import { confirmAlert } from 'react-confirm-alert';

function EditConditionValues(props) {
    const notifyerror = (e) => toast.error(`${e}`);
    const axiosPrivate = useAxiosPrivate();
    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        reset,
        resetField,
        formState: { errors },
    } = useForm();

    const ref = useRef(null);

    const [addCondition, setAddCondition] = useState(false);
    const [editCondition, setEditCondition] = useState(false);
    const [qualData, setQualData] = useState(props.qualdata);

    const [questionList, setQuestionList] = useState([]);
    const [answerList, setAnswerList] = useState([]);
    const [quotaAnswerList, setQuotaAnswerList] = useState([]);
    const [quotaData, setQuotaData] = useState([]);
    const [quotaQuestionList, setQuotaQuestionList] = useState([]);
    const [currQuota, setCurrQuota] = useState([]);
    const [quotaQuestions, setQuotaQuestions] = useState([]);
    const [currQid, setCurrQid] = useState([]);
    const [qualAnswers, setQualAnswers] = useState([]);
    const [currAnswers, setCurrAnswers] = useState([]);
    const [flag, setFlag] = useState(true);
    const [urlEditLink, setUrlEditLink] = useState();
    const [allQuestions, setAllQuestions] = useState(props.allQuestions);

    const [editConditionspinner, setEditConditionSpinner] = useState(false);
    const [zipErrorEdit, setZipErrorEdit] = useState(false);
    const [showError, setShowError] = useState(false);

    const [activeQualification, setActiveQualification] = useState(props.qualification);
    const qitem = props.qitem;

    useEffect(() => {
        getQuotaList();
    }, [qitem, flag]);

    const getQuotaList = async () => {
        setQuotaData([]);
        try {
            const response = await axiosPrivate.get(`quota/get-quota/${qitem.id}`);
            setQuotaData(response.data.quota);
            let qData = [];
            response.data.quota.map((e) => {
                qData.push(e.q_id);
            });
            let partialDetails = qualData.map(({ q_id, q_name }) => ({
                value: q_id,
                label: q_name,
            }));

            const result = partialDetails.filter(({ value }) => !qData.includes(value));
            setQuestionList(result);
            const result1 = partialDetails.filter(({ value }) => qData.includes(value));
            setQuotaQuestions(result1);

            const updatedQualifications = props.qualification.map((qual) => {
                if (qual.q_id === 1) {
                    const matchedQualData = props.qualdata.find((qd) => qd.q_id === 1);
                    if (matchedQualData) {
                        return { ...qual, ans_code: matchedQualData.ansCode };
                    }
                }

                return qual;
            });
            let qualificationAnswers = updatedQualifications.map(({ q_id, ans_code }) => ({ q_id: q_id, ans_code: ans_code }));
            setQualAnswers(qualificationAnswers);

            setQuotaQuestionList(qData);
        } catch (err) {
            // console.error(err);
        }
    };

    const getAnswerList = (e) => {
        setAnswerList([]);
        setQuotaAnswerList([]);
        resetField('answer');
        resetField('qid');
        setValue('qid', e.value);
        if (e.value !== 3) {
            getFilteredAnswer(e.value);
        }
    };

    /////////////////////Edit Quota
    const editQuota = async (data) => {
        setEditConditionSpinner(true);
        let qid = currQid.value;

        let url = '',
            payload = [];
        if (qid == 1 || qid == '1') {
            if (data.editage === false) {
                document.querySelector('.customerrmsg').style.display = 'block';
                document.getElementById('editBtn').disabled = true;
                setEditConditionSpinner(false);
                return;
            } else {
                document.querySelector('.customerrmsg').style.display = 'none';
                document.getElementById('editBtn').disabled = false;

                url = 'quota/update-age-quota/' + qitem.id + '/' + qid;
                let agedata = '',
                    ageitem = [];

                const checkArr = Array.isArray(data.editage);
                if (!checkArr) {
                    agedata = data.editage.toString();
                    let age = agedata.split('-');
                    ageitem = [{ min_age: age[0], max_age: age[1], status: 1 }];
                } else {
                    const len = data.editage.length;
                    for (let i = 0; i < len; i++) {
                        agedata = data.editage[i].toString();
                        let age = agedata.split('-');
                        ageitem[i] = {
                            min_age: age[0],
                            max_age: age[1],
                            status: 1,
                        };
                    }
                }

                payload = {
                    ans_code: ageitem,
                    order_by: qid,
                };
            }
        } else if (qid === 3 || qid === '3') {
            if (!urlEditLink || urlEditLink === undefined) {
                setEditConditionSpinner(false);
                setZipErrorEdit(true);
                return;
            }
            url = 'quota/update-zipcode/' + qitem.id + '/' + qid;
            payload = {
                target_id: qid,
                q_id: qid,
                upload_zipcode: urlEditLink,
                is_all_selected: false,
                order_by: qid,
            };
        } else {
            let answers = [];
            let key = 'editanswer' + currQid.value;
            let editanswers = data[key];
            if (Array.isArray(editanswers)) {
                answers = editanswers;
            } else {
                answers.push(editanswers);
            }
            url = 'quota/update-general-quota/' + qitem.id + '/' + qid;
            payload = {
                q_id: qid,
                ans_code: answers,
                is_all_selected: false,
                status: 1,
                order_by: qid,
            };
        }

        try {
            await axiosPrivate
                .patch(url, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    if (e.status === 200) {
                        const updatedQuestion = questionList.filter((item) => item.value !== qid);
                        setEditConditionSpinner(false);
                        setZipErrorEdit(false);

                        setQuestionList(updatedQuestion);
                        setFlag(!flag);
                        toast.success(`Quota Updated Successfully`);
                    }
                });
        } catch (err) {
            console.error(err);
            setEditConditionSpinner(false);
            if (err.response?.data?.return_status?.message) {
                toast.error(err.response.data.return_status.message);
            } else if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Sorry! we couldn't process your request. Please Try again");
            }
        }
        setEditConditionSpinner(false);
    };

    const updateStatus = (item, status, qid) => {
        let newObj = [];
        if (qid === 1) {
            newObj = quotaAnswerList.map((obj) => {
                if (obj.min_age == item.min_age) {
                    return { ...obj, checked: status };
                } else return obj;
            });
        } else {
            newObj = quotaAnswerList.map((obj) => {
                if (obj.ans_code == item.ans_code) {
                    return { ...obj, checked: status };
                } else return obj;
            });
        }
        const result = newObj.filter(({ checked }) => checked === true).length;

        if (result === 0) {
            document.querySelector('.customerrmsg').style.display = 'block';
            document.getElementById('editBtn').disabled = true;
        } else {
            document.querySelector('.customerrmsg').style.display = 'none';
            document.getElementById('editBtn').disabled = false;
        }

        setQuotaAnswerList(newObj);
    };

    const editQuotaShow = (qid, quota) => {
        setQuotaAnswerList([]);
        setAddCondition(false);
        setCurrQuota(quota);
        const result = quotaQuestions.filter(({ value }) => value == qid);
        setCurrQid(result[0]);
        setAnswerList([]);

        if (qid !== 3) {
            var element = document.querySelector('.customerrmsg');
            if (typeof element != 'undefined' && element != null) {
                document.querySelector('.customerrmsg').style.display = 'none';
            }
            getFilteredAnswer(qid);
        }

        setEditCondition(true);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const getFilteredAnswer = (qid) => {
        setAnswerList([]);
        setQuotaAnswerList([]);
        const filteredQuota = quotaData.filter((item) => item.q_id === qid);
        const filteredQual = qualAnswers.filter((item) => item.q_id === qid);
        setCurrAnswers(filteredQual[0].ans_code);
        const currentAns = filteredQual[0].ans_code;
        let answers = [];
        let index = qualData.findIndex((x) => x.q_id === qid);
        if (qid === 1) {
            answers = qualData[index].ansCode;
        } else {
            answers = qualData[index].allAnswers;
        }

        const filterdAnswer = answers.filter((ans) => currentAns.includes(ans.ans_code));

        if (qid === 1) {
            setAnswerList(currentAns);
        } else {
            setAnswerList(filterdAnswer);
        }
        let newObj = [];
        let activeAnscode = [];
        quotaData.some((element) => {
            if (element.q_id === qid) {
                activeAnscode = element.ans_code;
                return true; // Exit the loop once a matching element is found
            }
        });

        if (qid === 1) {
            newObj = currentAns.map((object) => {
                const isActive = activeAnscode.some((item) => {
                    return item.min_age === object.min_age && item.max_age === object.max_age;
                });

                return { ...object, checked: isActive };
            });
        } else {
            newObj = filterdAnswer.map((object, index) => {
                if (activeAnscode.includes(object.ans_code)) {
                    return { ...object, checked: true };
                } else {
                    return { ...object, checked: false };
                }
            });
        }

        setQuotaAnswerList(newObj);

        // setNewValue(newObj);
    };
    const setNewValue = (newObj) => {
        setQuotaAnswerList(newObj);
    };

    const uploadFile = (e, stype) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('zipcode_upload', e.target.files[0]);

        const url = `qualification/upload-files?survey_id=${props.sid}`;
        axiosPrivate
            .post(url, formData)
            .then((response) => {
                if (response.status === 200) {
                    setUrlEditLink(response.data.url);
                    setZipErrorEdit(false);
                    toast.success(`ZipCode File uploaded`);
                }
                // Handle successful response
            })
            .catch((error) => {
                // Handle error
                toast.error("Can't upload your file. Please try again");
                console.error('Error:', error);
            });
    };

    const handle_status = async (qid, tid, status) => {
        try {
            await axiosPrivate
                .patch(
                    '/quota/active-inactive-quotatarget',
                    JSON.stringify({
                        quota_id: qitem.id,
                        target_id: qid,
                        new_status: status === true ? 1 : 0,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((e) => {
                    if (e.data.return_status.status_id === 1) {
                        toast.success('Target status updated successfully');
                        setQuotaData((prevData) => {
                            // Map over the previous data array
                            return prevData.map((item) => {
                                // If the qualification ID matches, update the is_allocated field
                                if (item.q_id === qid) {
                                    if (status) {
                                        return { ...item, status: 1 };
                                    } else {
                                        return { ...item, status: 0 };
                                    }
                                }
                                return item;
                            });
                        });
                    }
                });
        } catch (err) {
            //notifyerror(err.response.statusText);
            toast.error(err);
        }
    };

    const callDelay = (qid, quota) => {
        editQuotaShow(qid, quota);
        setTimeout(function () {
            editQuotaShow(qid, quota);
        }, 10);
    };
    return (
        <>
            {questionList && qualAnswers ? <AddConditionValues qList={questionList} qitem={props.qitem} qualdata={props.qualdata} qualAnswers={qualAnswers} setFlag={setFlag} flag={flag} sid={props.sid} tstatus={props.tstatus} /> : <></>}
            <div class="table-responsive mt-3">
                <table class="table mb-0">
                    <thead class="thead-bg-color">
                        <tr>
                            <th>Question Name</th>
                            <th>Status</th>
                            <th>Edit Condition</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotaData.map((quota) => (
                            <>
                                <tr key={quota.q_id}>
                                    <td>
                                        {qualData.find((q) => q.q_id === quota.q_id) ? (
                                            <>{allQuestions.find((q) => q._id === quota.q_id)?.q_name || null}</>
                                        ) : (
                                            <>
                                                {quota.status === 1 ? (
                                                    <>
                                                        <p
                                                            className="m-0"
                                                            style={{
                                                                color: '#ff0000',
                                                            }}
                                                        >
                                                            {allQuestions.find((q) => q._id === quota.q_id)?.q_name || null}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <>{allQuestions.find((q) => q._id === quota.q_id)?.q_name || null}</>
                                                )}
                                            </>
                                        )}
                                    </td>
                                    <td>
                                        <BootstrapSwitchButton
                                            className="switch btn on btn-outline-success btn-xs"
                                            width={60}
                                            size="xs"
                                            onlabel="ON "
                                            offlabel="OFF"
                                            onstyle="outline-success"
                                            offstyle="outline-danger"
                                            checked={quota.status === 1 ? true : false}
                                            onChange={(e) => {
                                                handle_status(quota.q_id, quota, e);
                                            }}
                                            disabled={props.tstatus === 1 || props.tstatus ? true : false}
                                        />
                                    </td>
                                    <td className="pointer quota_fs">
                                        {qualData.find((q) => q.q_id === quota.q_id) ? (
                                            <>
                                                <BiEditAlt
                                                    onClick={(e) => {
                                                        callDelay(quota.q_id, quota);
                                                    }}
                                                    id={`btn${quota.q_id}`}
                                                    title="Edit Condition"
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>

                {editCondition ? (
                    <>
                        <div className="quota-condition-wrapper">
                            <form id="editCondition" name="editCondition" onSubmit={handleSubmit(editQuota)}>
                                <div className="qcw_child ms-5" ref={ref}>
                                    <Row>
                                        <Col lg={4} md={4} xs={12}>
                                            <label className="formlabel">Question Name*</label>
                                            <input
                                                type="text"
                                                {...register('targetname', {
                                                    required: true,
                                                })}
                                                value={currQid.label}
                                            />
                                            <input
                                                type="hidden"
                                                {...register('qid', {
                                                    required: true,
                                                })}
                                                value={currQid.value}
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={8} md={8} xs={12}>
                                            <label className="formlabel">Please select the values or Type the values</label>
                                            <div className="condition_box" id="edit_condition_box">
                                                {currQid.value === 3 ? (
                                                    <>
                                                        <Row>
                                                            <Col>
                                                                <label className="custom-file-upload btnWidth_set m-0 border-0 rounded-1">
                                                                    <input
                                                                        type="file"
                                                                        onChange={(e) => {
                                                                            uploadFile(e, 'edit');
                                                                        }}
                                                                        accept=".csv"
                                                                    />
                                                                    {urlEditLink ? 'File Uploaded' : 'Upload ZipCode'}
                                                                </label>
                                                                {zipErrorEdit && (
                                                                    <p role="alert" className="errMsg">
                                                                        Please upload a csv file
                                                                    </p>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <>
                                                        {quotaAnswerList ? (
                                                            <>
                                                                {quotaAnswerList.map((item, index) => (
                                                                    <>
                                                                        {currQid.value === 1 ? (
                                                                            <>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <input type="checkbox" className="answercheck" {...register('editage')} value={`${item.min_age}-${item.max_age}`} id={`item${index}`} checked={!!item.checked} onChange={(e) => updateStatus(item, !item.checked, currQid.value)} />
                                                                                        <label for={`item${index}`}>
                                                                                            {item.min_age} - {item.max_age}
                                                                                        </label>
                                                                                    </Col>
                                                                                </Row>
                                                                            </>
                                                                        ) : (
                                                                            <Row>
                                                                                <Col>
                                                                                    <input type="checkbox" className="answercheck" {...register('editanswer' + currQid.value)} value={item.ans_code} checked={item.checked} onChange={(e) => updateStatus(item, !item.checked, currQid.value)} id={`item ${index}`} />
                                                                                    <label htmlFor={`item${index}`}>
                                                                                        {item.label}
                                                                                        {item.checked}
                                                                                    </label>
                                                                                </Col>
                                                                            </Row>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        {showError ? (
                                                            <p role="alert" className="errMsg customerrmsg">
                                                                Please select atleast one option
                                                            </p>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <p role="alert" className="errMsg customerrmsg">
                                                            Please select atleast one option
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="flex justify-end mt-2">
                                        <button className="add-Quota-btn" id="editBtn" type="Submit" form="editCondition" disabled={props.tstatus || props.tstatus === 1 ? true : false}>
                                            {editConditionspinner && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                            Save Condition Values
                                        </button>
                                        <button className="add-Quota-btn btn-bg-grey ms-2" onClick={() => setEditCondition(false)}>
                                            <a className="flex items-center">Cancel</a>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                ) : null}
            </div>
        </>
    );
}

export default EditConditionValues;
