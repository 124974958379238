import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
import { MultiSelect } from "react-multi-select-component";

import Spinner from "react-bootstrap/Spinner";
import { confirmAlert } from "react-confirm-alert";
import { BASE_URL } from "../../../api/axios";
function Surveysupplier(props) {
  const [loader, setLoader] = useState(false);
  const [supplier_value, setSupplier_value] = useState([]);
  const [selected_supplier_value, setSelected_Supplier] = useState([]);
  const [filter_value, setFilter_value] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [hrefValue, setHrefValue] = useState("#");
  let project_status;
  let userString;
  let project_type_string;
  let project_manage_string;
  useEffect(() => {
    log_data();
  }, []);
  const log_data = async () => {
    setLoader(true);

    try {
      const response = await axiosPrivate.get(
        `supplyinfo/get-supplier-list?supplier_type_id=0`,
        {}
      );
      response.data.supplier.map((e) => {
        setSupplier_value((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
    setLoader(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Target Logs
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {loader === true ? (
          <SpinnerLoader />
        ) : (
          <>
            <>
              <MultiSelect
                placeholder="Select..."
                className="pjt_select "
                classNamePrefix="select"
                options={supplier_value}
                value={selected_supplier_value}
                onChange={(e) => {
                  setSelected_Supplier(e);
                  let initialFilterValues = e.map((item) => item.value);
                  initialFilterValues = initialFilterValues.join("%2C");
                  setFilter_value(initialFilterValues);
                }}
                labelledBy="Filter by Status"
                isCreatable={true}
              />
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="supply_setupbtn m-0 flex pointer me-2">
          {isSubmitting && (
            <Spinner
              style={{ color: "white" }}
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          <a
            className="text-decoration-none"
            style={{
              color: "white",
            }}
            href={hrefValue}
            onClick={() => {
              if (props.clientNameValue.length !== 0) {
                const data = props.clientNameValue.map((prop) => prop.value);
                userString = `${data.join(",")}`;
              } else {
                userString = "";
              }
              if (props.projectTypeValue.length !== 0) {
                const project_type = props.projectTypeValue.map(
                  (prop) => prop.value
                );
                project_type_string = `${project_type.join(",")}`;
              } else {
                project_type_string = "";
              }
              if (props.projectManagerValue.length !== 0) {
                const project_manag = props.projectManagerValue.map(
                  (prop) => prop.value
                );
                project_manage_string = `${project_manag.join(",")}`;
              } else {
                project_manage_string = "";
              }
              if (props.projectStatusValue.length !== 0) {
                const project_manag = props.projectStatusValue.map(
                  (prop) => prop.value
                );
                project_status = `${project_manag.join(",")}`;
              } else {
                project_status = "";
              }
              if (
                props.selectedDate_start == "" &&
                props.clientNameValue.length === 0 &&
                props.projectTypeValue.length === 0 &&
                props.projectManagerValue.length === 0 &&
                props.projectStatusValue.length === 0
              ) {
                confirmAlert({
                  closeOnEscape: false,
                  closeOnClickOutside: false,
                  message: `Please select one filter`,
                  messageStyle: {
                    color: "#ff0000", // Set your desired color value
                  },
                  buttons: [
                    {
                      label: "OK",
                    },
                  ],
                });
              } else {
                setIsSubmitting(true);
                const newHrefValue =
                  BASE_URL +
                  `/project/project-supplier-list-export?query_filter=start_date%3D${props.selectedDate_start}%26end_date%3D${props.selectedDate_end}%26client_id%3D${userString}%26project_type%3D${project_type_string}%26project_manager%3D${project_manage_string}%26project_status%3D${project_status}%26search%3D${props.search_name}&supplier_id=${filter_value}`;

                setHrefValue(newHrefValue);
                setIsSubmitting(false);
              }
            }}
          >
            Submit
          </a>
        </button>
        <Button
          onClick={() => {
            props.setSurveySuppliershow(false);
          }}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Surveysupplier;
