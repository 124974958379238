import React, { useState, useEffect } from "react";
import "./Permissions.css";
import Header from "../UI/Header/Header";
import pageIcon from "../../assets/BidsIcons/addClient.svg";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import BidsSubHeader from "../../components/BidsModule/BidsSubHeader/BidsSubHeader";
import ButtonSpinner from "../UI/ButtonSpinner";
import { useNavigate } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Permissions from "./Permissions";
import { Container } from "react-bootstrap";
import toast from "react-hot-toast";
import SpinnerLoader from "../UI/Spinner";
export default function Quality(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [all_data, setAll_data] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const id = props.id;
  const [mypayload, setMypayload] = useState([]);
  const [callagain, setCallAgain] = useState(false);
  const [loder, setLoder] = useState(false);
  useEffect(() => {
    findData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callagain]);

  const findData = async () => {
    try {
      const response = await axiosPrivate.get(
        `/permission/get-permission/?module_id=4&user_id=${id}`,
        {}
      );
      setAll_data(response.data.permission);
      var all_data = response.data.permission;
      var check_collection = [];
      all_data.map((e) => {
        if (e.is_selected === 1) {
          check_collection.push(e.code);
        }
        e.childmenu.map((prop) => {
          if (prop.is_selected === 1) {
            check_collection.push(prop.code);
          }
        });
      });
      setMypayload(check_collection);
      all_data.map((e) => {
        var flag = true;
        e.childmenu.map((prop) => {
          if (prop.is_selected === 0) {
            flag = false;
          }
        });
        setCallAgain(true);
        if (flag === true) {
          var value = document.getElementById(e._id);
          value.checked = true;
        }
      });
    } catch (err) {
      console.error(err);
    }
    return () => {};
  };

  const handleCheckBox = (event, id) => {
    var getevent = document.querySelectorAll(`.chilmenu` + id);
    if (event.target.checked === true) {
      for (var i = 0; i < getevent.length; i++) {
        if (getevent[i].type === "checkbox") {
          getevent[i].checked = true;
        }
      }
    } else {
      for (var j = 0; j < getevent.length; j++) {
        if (getevent[j].type === "checkbox") {
          getevent[j].checked = false;
        }
      }
    }

    if (event.target.checked === true) {
      for (let i = 0; i < getevent.length; i++) {
        setMypayload((oldArray) => [...oldArray, getevent[i].value]);
      }
      setMypayload((oldArray) => [...oldArray, event.target.value]);
    } else {
      var filterdata = [];
      filterdata.push(event.target.value);
      for (let i = 0; i < getevent.length; i++) {
        filterdata.push(getevent[i].value);
      }
      const func = mypayload.filter((item) => !filterdata.includes(item));

      setMypayload(func);
    }
  };
  const handleCheckMenuBox = (prop, id) => {
    var all_value = [];
    mypayload.map((e) => {
      all_value.push(e);
    });

    if (prop.target.checked === true) {
      all_value.push(prop.target.value);
    } else {
      const result = mypayload.filter((word) => word !== prop.target.value);
      all_value = result;
    }
    var getdata = document.querySelectorAll(".chilmenu" + id);
    var getdataLength = getdata.length;
    var count = 0;
    for (var i = 0; i < getdata.length; i++) {
      if (getdata[i].checked) {
        count = count + 1;
      } else {
        count = count - 1;
      }
    }
    if (getdataLength === count) {
      var value = document.getElementById(id);
      all_value.push(value.value);
      value.checked = true;
    } else {
      var value = document.getElementById(id);
      const result = all_value.filter((word) => word !== value.value);
      all_value = result;
      value.checked = false;
    }
    var getdata = document.querySelectorAll(".chilmenu" + id);
    var getdataLength = getdata.length;
    var flag = 0;
    for (var k = 0; k < getdata.length; k++) {
      if (getdata[k].checked) {
        flag = flag + 1;
      } else {
        flag = flag - 1;
      }
    }
    if (flag > -getdataLength) {
      var value = document.getElementById(id);
      all_value.push(value.value);
    }
    setMypayload(all_value);
  };

  const handleData = async (e) => {
    setIsSubmitting(true);
    const payload = {
      user_id: parseInt(id),
      module_id: 4,
      permission_list: mypayload,
    };

    try {
      await axiosPrivate
        .patch("permission/update-permission", payload, {
          headers: { "Content-Type": "application/json" },
        })
        .then((e) => {});
    } catch (err) {}
    notifymessage();
    setIsSubmitting(false);
  };

  const notifymessage = (e) =>
    toast.success(`Permissions updated successfully`);

  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3
                className="tableHeading"
                onClick={() => {
                  // navigate("/user");
                }}
              >
                <img src={pageIcon} className="headingIcons" alt="" /> Quality
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={() => {
                  // navigate("/clients");
                  // demofirst();
                  navigate("/users");
                }}
              >
                {" "}
                Close
              </button>

              <button className="cbtn saveBtn">
                {isSubmitting && <ButtonSpinner />} &nbsp; Save
              </button>
            </div>
          </div>

          {loder ? (
            <>
              <SpinnerLoader />
            </>
          ) : (
            <>
              {all_data.length === 0 ? (
                <>
                  <div
                    className="alert alert-danger"
                    style={{ textAlign: "center", margin: "15px" }}
                    role="alert"
                  >
                    No permission Found!
                  </div>
                </>
              ) : (
                <>
                  <div className="permissions-columns">
                    <div className="mainContent">
                      <div className="container-checkbox-fields">
                        <Row>
                          {all_data.map((e) => (
                            <>
                              <div className="permissions-user-box">
                                <span
                                  style={{
                                    marginTop: "10px",
                                    marginleft: "20px;",
                                  }}
                                >
                                  <label>
                                    <input
                                      id={e._id}
                                      type="checkbox"
                                      value={e.code}
                                      onClick={(prop) =>
                                        handleCheckBox(prop, e._id)
                                      }
                                      style={{
                                        marginTop: "10px",
                                        marginLeft: "-40px",
                                        cursor: "pointer",
                                      }}
                                    ></input>{" "}
                                    {e.name}
                                  </label>
                                </span>
                              </div>

                              {e.childmenu.length < 0 ? (
                                <></>
                              ) : (
                                e.childmenu.map((data) => (
                                  <>
                                    <Col sm={6} md={4} lg={3} xs={12}>
                                      <span>
                                        <label>
                                          <input
                                            id="childdata"
                                            defaultChecked={data.is_selected}
                                            style={{
                                              marginLeft: "20px",
                                              cursor: "pointer",
                                            }}
                                            type="checkbox"
                                            name="interest"
                                            className={`user-inputs chilmenu${e._id}`}
                                            value={data.code}
                                            onClick={(prop) =>
                                              handleCheckMenuBox(prop, e._id)
                                            }
                                          ></input>
                                          <span> {data.name.slice(0, 20)}</span>
                                        </label>
                                      </span>
                                    </Col>
                                  </>
                                ))
                              )}
                            </>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
