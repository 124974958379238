import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import SpinnerLoader from '../../UI/Spinner';
import toast from 'react-hot-toast';
const AllStatusModify = (props) => {
    const project_id = props.project_id;
    const pro_status = props.projectStatus;
    const axiosPrivate = useAxiosPrivate();
    const [projectStatusStorage, setProjetStatusStorage] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selected_status, setSelected_status] = useState([]);
    const [loder, setLoder] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        setValue,
        reset,
    } = useForm();

    useEffect(() => {
        get_ProjectStatus_api();
    }, []);

    useEffect(() => {
        reset();
        if (props.show) {
            setValue(`Status`, []);
            setValue(`Remarks`, '');
        }
    }, [props.show]);

    const notifyerror = (e) => toast.error(`${e} `);

    const get_ProjectTracker_api = async (e) => {
        setIsSubmitting(true);
        const payload = {
            survey_status_id: e.Status.value,
            remark: e.Remarks,
        };

        try {
            await axiosPrivate
                .patch(`survey/project-track-update/${project_id}`, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    props.setCallApi(true);
                    toast.success('Survey status updated successfully');
                    props.onHide();
                });

            setIsSubmitting(false);
        } catch (err) {
            setIsSubmitting(false);
            console.error(err);
            notifyerror(err.response.statusText);
        }
    };

    const get_ProjectStatus_api = async () => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(
                'lookup/get-project-status'
            );
            setProjetStatusStorage([]);
            response.data.all_status.map((e) => {
                setProjetStatusStorage((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
            setLoder(true);
        } catch (err) {
            console.error(err);
            notifyerror(err.response.statusText);
        }
    };
    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        className="fs16"
                    >
                        Project Tracker
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loder ? (
                        <>
                            <form
                                onSubmit={handleSubmit(get_ProjectTracker_api)}
                            >
                                <Row>
                                    <Col lg={12} md={12} xs={12}>
                                        <label className="formlabel">
                                            Status*
                                        </label>

                                        <Select
                                            {...register(`Status`, {
                                                required:
                                                    'This field is Required',
                                            })}
                                            placeholder="Status"
                                            className="pjt_select formtext"
                                            classNamePrefix="select"
                                            options={projectStatusStorage}
                                            onChange={(e) => {
                                                setSelected_status(e);
                                                setValue(`Status`, e);
                                                trigger('Status');
                                            }}
                                            noOptionsMessage={() =>
                                                'No Data Found'
                                            }
                                        />
                                        {errors?.Status && (
                                            <small className="errmsg errmsgFix m-0">
                                                {errors.Status.message}
                                            </small>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col lg={12} md={12} xs={12}>
                                        <label className="formlabel">
                                            Remarks*
                                        </label>

                                        <textarea
                                            {...register(`Remarks`, {
                                                required:
                                                    'This field is Required',
                                            })}
                                            rows={4}
                                            style={{ width: '100%' }}
                                            // defaultValue={
                                            //   surveyItem.remark.length !== 0
                                            //     ? surveyItem.remark[surveyItem.remark.length - 1].remark
                                            //     : ""
                                            // }
                                            className="mb--5px"
                                        />
                                        {errors.Remarks && (
                                            <small className="errmsg errmsgFix m-0">
                                                {errors.Remarks.message}
                                            </small>
                                        )}
                                    </Col>
                                </Row>
                                <Modal.Footer className="pb-0 mt-2 pe-0">
                                    <button
                                        className="supply_setupbtn m-0"
                                        type="submit"
                                    >
                                        {isSubmitting && (
                                            <Spinner
                                                style={{ color: 'white' }}
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        &nbsp; Save
                                    </button>
                                    <Button
                                        className="supply_setupbtn m-0 ms-2 btn-bg-grey"
                                        onClick={props.onHide}
                                    >
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </>
                    ) : (
                        <>
                            <SpinnerLoader />
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AllStatusModify;
