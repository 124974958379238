import React, { useState, useEffect } from "react";
import Header from "../../UI/Header/Header";
import { useNavigate } from "react-router";
import pageIcon from "../../../assets/BidsIcons/clientListing.svg";
import addIcon from "../../../assets/BidsIcons/add.svg";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import Bidstopheader from "./Bidstopheader";
import DataTable from "react-data-table-component";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import SpinnerLoader from "../../UI/Spinner";
import { ClientId } from "../../Reduxstore/action";
import Regions from "../../../assets/images/Region.svg";
import "../../AdminModule/Tables/StyleDatatable.css";
import useAuth from "../../../hooks/useAuth";
const ClientlistingTable = () => {
  const navigate = useNavigate();
  const buttonName = "Add Client";
  const name = "Client Listing";
  const [getClients, setGetClients] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [pending, setPending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [filterClients, setFilterClients] = useState(null);
  const { auth } = useAuth();

  const superUser = auth.superuser;
  const all_permission = auth.permission;
  const deleteClients = () => toast.success("client deleted successfully");
  const handletoggleNo = async (id, e) => {
    const payload = {
      client_id: id,
      new_status: e,
    };

    try {
      await axiosPrivate
        .patch("/client/active-inactive-client", payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (e.data.new_status === 1) {
            notifyNotActive();
          } else {
            notifyactive();
          }
        });
    } catch (err) {}
  };

  const notifyNotActive = (e) => toast.success("Client is Active");
  const notifyactive = () => toast.success("Client is InActive");

  useEffect(() => {
    let isMounted = true;
    const getClientsList = async () => {
      try {
        const response = await axiosPrivate.get("/client/client-list", {});
        setPending(false);
        isMounted && setGetClients(response.data.all_client);
      } catch (err) {
        console.error(err);
      }
    };

    getClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      id: "sno",
      name: "Sno.",
      selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
      width: "90px",
      sortable: true,
    },
    {
      id: "name",
      name: "Client Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "User",
      selector: (row) => row.user_ref,
      sortable: true,
    },

    {
      name: "Client Type",
      selector: (row) => row.client_types,
      sortable: true,
    },

    {
      name: "Country",
      selector: (row) => row.country_id,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state_id,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          {superUser === true ? (
            <>
              <BootstrapSwitchButton
                className="switch btn on btn-outline-success btn-xs"
                checked={row.status === 1 ? true : false}
                onChange={(e) => {
                  handletoggleNo(row._id, e);
                }}
                size="xs"
                width={80}
                onlabel="Active"
                offlabel="Inactive"
                onstyle="outline-success"
                offstyle="outline-danger"
              />
            </>
          ) : (
            <>
              {all_permission.includes("client.client_change_status") ? (
                <>
                  {" "}
                  <BootstrapSwitchButton
                    className="switch btn on btn-outline-success btn-xs"
                    checked={row.status === 1 ? true : false}
                    onChange={(e) => {
                      handletoggleNo(row._id, e);
                    }}
                    size="xs"
                    width={80}
                    onlabel="Active"
                    offlabel="Inactive"
                    onstyle="outline-success"
                    offstyle="outline-danger"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ),
      width: "120px",
    },
    {
      name: "Approved",
      selector: (row) => (
        <td>
          {row.is_approved === 1 ? (
            <button
              style={{
                backgroundColor: "#555",
                width: "50px",
                border: "none",
                color: "white",
              }}
            >
              {" "}
              Yes{" "}
            </button>
          ) : (
            <button
              style={{
                backgroundColor: "#aaa",
                width: "50px",
                border: "none",
                color: "white",
              }}
            >
              {" "}
              No{" "}
            </button>
          )}
        </td>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <td>
          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={EditIcon}
                  onClick={() => {
                    localStorage.setItem("clientname", row.name);
                    navigate(`/update-client/${row._id}`);
                    ClientId(row._id);
                  }}
                  alt=""
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("client.client_update") ||
              all_permission.includes("client.client_get_list") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={EditIcon}
                      onClick={() => {
                        localStorage.setItem("clientname", row.name);
                        navigate(`/update-client/${row._id}`);
                        ClientId(row._id);
                      }}
                      alt=""
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {superUser === true ? (
            <>
              <span className="actionButton">
                <img
                  src={DeleteIcon}
                  onClick={() => {
                    confirmAlert({
                      message: "Do you want to delete " + row.name,
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => handleDelete(row._id),
                        },
                        {
                          label: "No",
                        },
                      ],
                    });
                  }}
                  alt=""
                />
              </span>
            </>
          ) : (
            <>
              {all_permission.includes("client.client_delete") ? (
                <>
                  {" "}
                  <span className="actionButton">
                    <img
                      src={DeleteIcon}
                      onClick={() => {
                        confirmAlert({
                          message: "Do you want to delete " + row.name,
                          buttons: [
                            {
                              label: "Yes",
                              onClick: () => handleDelete(row._id),
                            },
                            {
                              label: "No",
                            },
                          ],
                        });
                      }}
                      alt=""
                    />
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <span
            className="actionButton"
            onClick={() => {
              localStorage.setItem("clientname", row.name);
              navigate(`/region/${row._id}`);
            }}
          >
            <img src={Regions} alt="" />
          </span>
        </td>
      ),
    },
  ];

  const showSearch = (searchText) => {
    const copyOfUsers = [...getClients];

    const result = copyOfUsers.filter((points) => {
      if (points.state_id == null || "null") {
        return (
          points.name
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.user_ref
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.client_types
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.country_id
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase())
        );
      } else {
        return (
          points.name
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.user_ref
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.client_types
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.country_id
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase()) ||
          points.state_id
            .toString()
            .toLowerCase()
            .match(searchText.toString().toLowerCase())
        );
      }
    });
    setFilterClients(result);
  };

  const handleDelete = async (id) => {
    const payload = {
      client_id: id,
    };

    try {
      await axiosPrivate.delete(
        "client/delete",
        { data: payload },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
    } catch (err) {}
    const del = getClients.filter((getClients) => id !== getClients._id);
    setGetClients(del);
    deleteClients();
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "40px",
      },
    },
  };
  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="clientlist" />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Client
                Listing
              </h3>
            </div>
            <input
              type="search"
              className="tableSearch"
              placeholder="Search..."
              onChange={(e) => showSearch(e.target.value)}
            />

            {superUser === true ? (
              <>
                <button
                  className="cbtn cbtnMargin addBtn" style={{marginLeft:'0px'}}
                  onClick={() => {
                    navigate("/add-client");
                  }}
                >
                  <img src={addIcon} alt="" /> &nbsp; Add Client
                </button>
              </>
            ) : (
              <>
                {all_permission.includes("client.client_add") ? (
                  <>
                    <button
                      className="cbtn addBtn"
                      onClick={() => {
                        navigate("/add-client");
                      }}
                    >
                      <img src={addIcon} alt="" /> &nbsp; Add Client
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          <hr className="showingHeader" />
          <Bidstopheader name={name} buttonName={buttonName} tid="4" />

          <div className="mainContent dTable">
            <DataTable
              columns={columns}
              data={filterClients || getClients}
              customStyles={customStyles}
              progressPending={pending}
              onChangePage={(page, totalRows) => {
                setCurrentPage(page);
              }}
              onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                setCurrentRowsPerPage(currentRowsPerPage);
              }}
              highlightOnHover
              progressComponent={<SpinnerLoader />}
              defaultSortFieldId="name"
              defaultSortAsc={true}
              pagination
              noDataComponent="No Data Found"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientlistingTable;
