import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";

function SupplierLinkModal(props) {
  const id = props.supplierId;
  const axiosPrivate = useAxiosPrivate();
  const [allLink, setAllLink] = useState([]);
  const [loder, setLoder] = useState(false);

  useEffect(() => {
    if (id == undefined) {
    } else getSupplierLink();
  }, [id]);

  const getSupplierLink = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `survey-supplier/get-entry-link-survey-supplier?survey_id=${id}`
      );
      setAllLink(response.data.survey_supplier);
    } catch (err) {}
    setLoder(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Supplier Wise Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="linksWrapper">
          {loder ? (
            <>
              {allLink == 0 ? (
                <>
                  <div
                    className="alert alert-danger"
                    style={{
                      textAlign: "center",
                      marginBottom: "0px",
                    }}
                    role="alert"
                  >
                    No Supplier added to this survey!
                  </div>
                </>
              ) : (
                <>
                  {allLink.map((items) => (
                    <>
                      <Row className="flex items-center">
                        <Col lg={2} md={4} sm={12} xs={12}>
                          <label>{items.name}</label>
                        </Col>

                        <Col lg={9} md={8} sm={12} xs={12}>
                          <span>
                            {items.link == null ? <>-</> : <>{items.link}</>}
                          </span>
                        </Col>
                      </Row>
                    </>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <SpinnerLoader />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SupplierLinkModal;
