import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../UI/Spinner";

import moment from "moment";
function Screeninglogs(props) {
  const [logData, setLogData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [logdata_value, setLogData_Value] = useState([]);
  const [response_data, setResponse_Data] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    log_data();
  }, []);
  const log_data = async () => {
    setLoader(true);
    try {
      const response = await axiosPrivate.get(
        `screening/get-prescreening-logs/${props.survey_id}`
      );

      if (response.data.length !== 0) {
        setLogData(response.data);
        setResponse_Data(response.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoader(false);
  };
  const toCamelCase = (str) => {
    return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
  };
  const flattenLogData = (data) => {
    return data.flatMap((item, index) => {
      return Object.entries(item.log_data).flatMap(([questionId, value]) => {
        const flattenedLogData = [];

        // Initialize the object for the current question_id
        const questionObject = {
          key: index,
          question_id: questionId,
          data: [],
        };

        for (const [innerKey, innerValue] of Object.entries(value)) {
          if (Array.isArray(innerValue)) {
            if (innerValue.length === 0) {
              // If the array is empty, push an entry with an empty string value
              questionObject.data.push({
                key: innerKey,
                value: " - ",
              });
            } else {
              innerValue.forEach((arrayItem, index) => {
                if (typeof arrayItem === "object") {
                  for (const [arrayKey, arrayValue] of Object.entries(
                    arrayItem
                  )) {
                    let finalKey = arrayKey;
                    if (arrayKey === "status") {
                      finalKey = "answer_status";
                    }
                    questionObject.data.push({
                      key: toCamelCase(finalKey),
                      value: arrayValue,
                    });
                  }
                } else {
                  questionObject.data.push({
                    key: toCamelCase(innerKey),
                    value: arrayItem,
                  });
                }
              });
            }
          } else {
            let finalKey = innerKey;
            if (innerKey === "status") {
              finalKey = "question_status";
            }
            questionObject.data.push({
              key: toCamelCase(finalKey),
              value: innerValue,
            });
          }
        }

        flattenedLogData.push(questionObject);
        return flattenedLogData;
      });
    });
  };

  useEffect(() => {
    const flattenedData = flattenLogData(response_data);
    setLogData_Value(flattenedData);
  }, [response_data]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Pre-Screening Log
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="overflow-auto"
        style={{
          minHeight: "100px",
          maxHeight: "400px",
        }}
      >
        {loader === true ? (
          <SpinnerLoader />
        ) : logData.length === 0 ? (
          <div
            className="alert alert-danger center-margin-container"
            role="alert"
          >
            No Logs Found!
          </div>
        ) : (
          <>
            <>
              <table className="main_log_table">
                <thead className="thead">
                  <tr>
                    <th>Created Date</th>
                    <th>Created By</th>
                    <th>Question Id</th>
                  </tr>
                </thead>
                <tbody>
                  {logData.map((log_Item, logIndex) => (
                    <tr key={logIndex} className="main_log_tr">
                      <td>
                        {moment(log_Item.created_date).format(
                          "D/MM/YYYY h:mm A"
                        )}
                      </td>
                      <td>{log_Item.created_by}</td>
                      {logdata_value.map((logItem, index) => (
                        <React.Fragment key={index}>
                          {logItem.key === logIndex ? (
                            <>
                              <table className="log_table">
                                <tr className="main_log_tr">
                                  <td
                                    className="log_first_td"
                                    style={{
                                      width: "50px",
                                      minWidth: "50px",
                                    }}
                                  >
                                    {logItem.question_id}
                                  </td>
                                  <td>
                                    {logItem.data.map((dataItem, dataIndex) => (
                                      <li
                                        key={dataIndex}
                                        style={{
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {dataItem.key}:
                                        {dataItem.value.toString()}
                                      </li>
                                    ))}
                                  </td>
                                </tr>
                              </table>
                            </>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Screeninglogs;
