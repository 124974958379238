import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "bootstrap/dist/css/bootstrap.css";
import { BiUpload, BiPurchaseTag } from "react-icons/bi";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState, useEffect } from "react";
import SpinnerLoader from "../../UI/Spinner";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import SuplierCostInfoChild from "./SupplierCostInfoChild";
const Supliercostinfo = () => {
  const [tableData, setTableData] = useState([]);
  const [tableDataTwo, setTableDataTwo] = useState([]);
  const [surveyId, setSurveyId] = useState("");
  const [projectStatusStorage, setProjetStatusStorage] = useState([]);
  const [allSurveyProject, setAllSurveyProject] = useState([]);
  const [loder, setLoder] = useState(false);
  const { id } = useParams();
  const [survey_Id, setSurvey_Id] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const handleShowSect = (id) => {
    var element = document.getElementById("firstSect" + id);
    let iconShow = document.getElementById("iconSect" + id);

    if (element.matches(".open")) {
      element.classList.remove("open");
      iconShow.classList.remove("open");
    } else {
      element.classList.add("open");
      iconShow.classList.add("open");
    }
  };

  const get_supplycostinfo_api = async (e) => {
    if (surveyId == "") {
    } else {
      try {
        const response = await axiosPrivate.get(
          `invoice/get-supply-cost-info?survey_id=${surveyId}`
        );
        setTableData(response.data.billingsurvey.supplier_info);
        setTableDataTwo(response.data.billingsurvey.total_summary);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const get_ProjectData_Params = async () => {
    setLoder(false);
    var pid;
    try {
      const response = await axiosPrivate.get(`project/get-project/${id}`);
      setProjetStatusStorage(response.data.project);
      pid = response.data.project.id;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoder(true);
    get_SurveyProject(pid);
  };

  const get_SurveyProject = async (eid) => {
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-project-id?project_id=${eid}`
      );
      setAllSurveyProject(response.data.survey);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    get_SurveyQuota_api();
    get_ProjectData_Params();
  }, []);

  useEffect(() => {
    get_supplycostinfo_api();
  }, [surveyId]);

  const get_SurveyQuota_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`/survey/get-survey-quota/${id}`);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };
  return (
    <>
      <hr className="csHR" />
      {loder ? (
        <>
          <div className="cost_main_cont">
            <div className="supplyInfocost">
              <p className="supinfoTitle">
                {projectStatusStorage.project_name}
              </p>
            </div>

            <SuplierCostInfoChild />
          </div>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </>
  );
};

export default Supliercostinfo;
