import React, { useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "../../UI/DateRangePicker.css";
import "../../AdminModule/Tables/StyleDatatable.css";
import "./SalesReport.css";
import { useNavigate } from "react-router";
import userIco from "../../../assets/LoginIcons/users.svg";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { TableCell, TableRow, TableBody } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableSortLabel } from "@mui/material";
import Paginations from "../../Paginations/Paginations";
import SpinnerLoader from "../../UI/Spinner";
import DownloadIcon from "@mui/icons-material/Download";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../../api/axios";
import ButtonSpinner from "../../UI/ButtonSpinner";
import { components } from "react-select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { MultiSelect } from "react-multi-select-component";
import toast from "react-hot-toast";
import Select from "react-select";
import { useParams } from "react-router";

const SaleReport = () => {
  const params = useParams();
  const [userdata, setUserdata] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const axiosPrivate = useAxiosPrivate();
  const [reverSign, setReverSign] = useState(0);
  const navigate = useNavigate();
  const [dataLength, setDataLength] = useState(0);
  const [createdDateStart, setCreatedDataStart] = useState("");
  const [createdDateStartProj, setCreatedDataStartProj] = useState("");
  const [createdDateEnd, setCreatedDataEnd] = useState("");
  const [createdDateEndProj, setCreatedDataEndProj] = useState("");
  const [closeDateStart, setCloseDataStart] = useState("");
  const [closeDateEnd, setCloseDataEnd] = useState("");
  const [bidderinput, setBidderinput] = useState([]);
  const [status, setStatus] = useState([]);
  const [acount, setAccount] = useState([]);
  const [account_Owner, setAccount_owner] = useState([]);
  const [bid_start, setBid_start] = useState("");
  const [bid_end, setBid_end] = useState("");
  const [booked_start, setBooked_start] = useState("");
  const [booked_end, setBooked_end] = useState("");
  const [loder, setLoder] = useState(false);
  const [string, setString] = useState();
  const [statusString, setStatusString] = useState();
  const [acountString, setAcountString] = useState();
  const [account_OwnerString, setAccount_OwnerString] = useState();
  const [noData, setNoData] = useState(true);
  const [downloadIcon, setDownloadIcon] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [statuselected, setSelectedstatus] = useState([]);
  const [clientselected, setclientSelected] = useState([]);
  const [accountselected, setAccountSelected] = useState([]);
  const notifyerror = (e) => toast.error(`${e}`);

  const headers = [
    {
      id: "sno",
      label: "Sno.",
      width: "70px",
      grow: 0,
    },
    {
      id: "project_name",
      label: "Project Name",
      sortable: true,
    },
    {
      id: "opportunity.created_at",
      label: "Created Date",
      sortable: true,
    },
    {
      id: "opportunity.closed_date",
      label: "Closed Date",
      sortable: true,
    },
    {
      id: "opportunity.project_start_date",
      label: "Project Start Date",
      sortable: true,
    },
    {
      id: "opportunity.status.name",
      label: "Status",
      sortable: true,
      width: "1fr",
    },
    {
      id: "client.client_name",
      label: "Account Name",
      sortable: true,
    },
    {
      id: "bid_owner.name",
      label: "Bidder",
      sortable: true,
    },
    {
      id: "client.sale_representive.name",
      label: "Sales Representative",
      sortable: true,
    },
    {
      id: "follow_up",
      label: "Follow Up",
      sortable: true,
    },
    {
      id: "opportunity.bid_amount",
      label: "Bid Amt",
      sortable: true,
    },
    {
      id: "opportunity.booked_amount",
      label: "Booked Amt",
      sortable: true,
    },
    {
      id: "Action",
      label: "Action",
      sortable: true,
    },
  ];

  useEffect(() => {
    getData();
    getClientList();
    getAccountOwner();
    getBidder();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [bidStatus, setBidStatus] = useState([]);
  const [client, setClient] = useState([]);
  const [accountOwner, setAccountOwner] = useState([]);
  const [bidder, setBidder] = useState([]);
  const Link_value = [
    {
      value: "sale-report",
      label: "Sale Report",
    },
    {
      value: "bid-volume",
      label: "Bid Volume",
    },
    {
      value: "bid-booking",
      label: "Booking amount",
    },
    {
      value: "top-ten-accounts",
      label: "Top Ten Accounts",
    },
  ];
  const getData = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get_bid_status", {});
      let data = response.data.all_status;
      var bidstatuslist = [...data];
      const updateddata = bidstatuslist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item.id),
          label: item.name,
        })
      );
      setBidStatus(updateddata);
    } catch (err) {
      console.error(err);
    }
  };

  const getClientList = async () => {
    try {
      const response = await axiosPrivate.get("/client/client-list", {});

      let data = response.data.all_client;
      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item._id),
          label: item.name,
        })
      );
      setClient(clientdata);
    } catch (err) {
      console.error(err);
    }
  };

  const getAccountOwner = async () => {
    try {
      const response = await axiosPrivate.get(
        "lookup/get-sales-representative",
        {}
      );
      let data = response.data.all_status;

      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item.id),
          label: item.name,
        })
      );

      setAccountOwner(clientdata);
    } catch (err) {
      console.error(err);
    }
  };

  const getBidder = async () => {
    const bid_number = 2;
    try {
      const response = await axiosPrivate.get(
        `user/user-department/${bid_number}`,
        {}
      );

      let data = response.data.all_users;

      var clientlist = [...data];
      const clientdata = clientlist.map((item, i) =>
        Object.assign(item, {
          value: parseInt(item._id),
          label: item.name,
        })
      );

      setBidder(clientdata);
    } catch (err) {
      console.error(err);
    }
  };
  const handlesort = async (id) => {
    var string = "";
    for (var i = 0; i < bidderinput.length; i++) {
      if (i - (bidderinput.length - 1) === 0) {
        string = string + bidderinput[i].id;
      } else {
        string = string + bidderinput[i].id + ",";
      }
    }
    setString(string);

    var statusString = "";
    for (var i = 0; i < status.length; i++) {
      if (i - (status.length - 1) === 0) {
        statusString = statusString + status[i].id;
      } else {
        statusString = statusString + status[i].id + ",";
      }
    }
    setStatusString(statusString);

    var acountString = "";
    for (var i = 0; i < acount.length; i++) {
      if (i - (acount.length - 1) === 0) {
        acountString = acountString + acount[i].id;
      } else {
        acountString = acountString + acount[i].id + ",";
      }
    }
    setAcountString(acountString);

    var account_OwnerString = "";

    for (var i = 0; i < account_Owner.length; i++) {
      if (i - (account_Owner.length - 1) === 0) {
        account_OwnerString = account_OwnerString + account_Owner[i].id;
      } else {
        account_OwnerString = account_OwnerString + account_Owner[i].id + ",";
      }
    }

    setReverSign(reverSign + 1);
    if (reverSign === 0) {
      const response = await axiosPrivate.get(
        `/bid/report-bid?query_filter=receive_date_start%3D${createdDateStart}%26receive_date_end%3D${createdDateEnd}%26closed_date_start%3D${closeDateStart}%26closed_date_end%3D${closeDateEnd}%26project_date_start%3D${createdDateStartProj}%26project_date_end%3D${createdDateEndProj}%26bidder%3D${string}%26status%3D${statusString}%26client%3D${acountString}%26sales_person%3D${account_OwnerString}%26bid_amount_min%3D${bid_start}%26bid_amount_max%3D${bid_end}%26booked_amount_min%3D${booked_start}%26booked_amount_max%3D${booked_end}&page=${nextPage}&size=${pagesize}&sort=_id`
      );
      setUserdata(response.data.items);
      setDataLength(response.data.total);

      setReverSign(1);
    } else {
      const response = await axiosPrivate.get(
        `/bid/report-bid?query_filter=receive_date_start%3D${createdDateStart}%26receive_date_end%3D${createdDateEnd}%26closed_date_start%3D${closeDateStart}%26closed_date_end%3D${closeDateEnd}%26project_date_start%3D${createdDateStartProj}%26project_date_end%3D${createdDateEndProj}%26bidder%3D${string}%26status%3D${statusString}%26client%3D${acountString}%26sales_person%3D${account_OwnerString}%26bid_amount_min%3D${bid_start}%26bid_amount_max%3D${bid_end}%26booked_amount_min%3D${booked_start}%26booked_amount_max%3D${booked_end}&page=${nextPage}&size=${pagesize}&sort=_id`
      );

      setUserdata(response.data.items);
      setDataLength(response.data.total);

      setReverSign(0);
    }
  };

  const getPageSize = (size) => {
    setPagesize(size);
    setNextPage(1);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  useEffect(() => {
    handlePagination();
  }, [pagesize, nextPage]);

  const handlePagination = async (e) => {
    try {
      const response = await axiosPrivate.get(
        `/bid/report-bid?query_filter=receive_date_start%3D${createdDateStart}%26receive_date_end%3D${createdDateEnd}%26closed_date_start%3D${closeDateStart}%26closed_date_end%3D${closeDateEnd}%26project_date_start%3D${createdDateStartProj}%26project_date_end%3D${createdDateEndProj}%26bidder%3D${string}%26status%3D${statusString}%26client%3D${acountString}%26sales_person%3D${account_OwnerString}%26bid_amount_min%3D${bid_start}%26bid_amount_max%3D${bid_end}%26booked_amount_min%3D${booked_start}%26booked_amount_max%3D${booked_end}&page=${nextPage}&size=${pagesize}&sort=_id`
      );
      setUserdata(response.data.items);
      setDataLength(response.data.total);
    } catch (err) {}
  };

  const handleSearch = async (e) => {
    setDownloadIcon(true);
    setLoder(true);
    setIsSubmitting(true);

    var string = "";
    for (var i = 0; i < bidderinput.length; i++) {
      if (i - (bidderinput.length - 1) === 0) {
        string = string + bidderinput[i]._id;
      } else {
        string = string + bidderinput[i]._id + ",";
      }
    }
    setString(string);

    var statusString = "";
    for (var i = 0; i < status.length; i++) {
      if (i - (status.length - 1) === 0) {
        statusString = statusString + status[i].id;
      } else {
        statusString = statusString + status[i].id + ",";
      }
    }
    setStatusString(statusString);

    var acountString = "";
    for (var i = 0; i < acount.length; i++) {
      if (i - (acount.length - 1) == 0) {
        acountString = acountString + acount[i]._id;
      } else {
        acountString = acountString + acount[i]._id + ",";
      }
    }
    setAcountString(acountString);

    var account_OwnerString = "";
    for (var i = 0; i < account_Owner.length; i++) {
      if (i - (account_Owner.length - 1) === 0) {
        account_OwnerString = account_OwnerString + account_Owner[i].id;
      } else {
        account_OwnerString = account_OwnerString + account_Owner[i].id + ",";
      }
    }
    setAccount_OwnerString(account_OwnerString);
    setBid_start(e.bid_start);
    setBid_end(e.bid_end);
    setBooked_start(e.booked_start);
    setBooked_end(e.booked_end);
    if (createdDateStartProj == "" && createdDateStart == "") {
      notifyerror("Please fill created date or project date");
    } else {
      try {
        const response = await axiosPrivate.get(
          `/bid/report-bid?query_filter=receive_date_start%3D${createdDateStart}%26receive_date_end%3D${createdDateEnd}%26closed_date_start%3D${closeDateStart}%26closed_date_end%3D${closeDateEnd}%26project_date_start%3D${createdDateStartProj}%26project_date_end%3D${createdDateEndProj}%26bidder%3D${string}%26status%3D${statusString}%26client%3D${acountString}%26sales_person%3D${account_OwnerString}%26bid_amount_min%3D${e.bid_start}%26bid_amount_max%3D${e.bid_end}%26booked_amount_min%3D${e.booked_start}%26booked_amount_max%3D${e.booked_end}&page=${nextPage}&size=${pagesize}&sort=_id`
        );
        if (response.data.items.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setUserdata(response.data.items);
        setDataLength(response.data.total);
      } catch (err) {}
    }

    setLoder(false);
    setIsSubmitting(false);
  };

  const handleCallbackTeam = (start, end) => {};
  const handleCallbackTeamProject = (start, end) => {};
  const handleCallbackclose = (start, end) => {};

  const ValueContainer = ({ children, getValue, ...props }) => {
    var length = getValue().length;
    return (
      <components.ValueContainer {...props}>
        {length === 0
          ? !props.selectProps.inputValue && `Select Options`
          : !props.selectProps.inputValue && `${length} selected`}

        {React.Children.map(children, (child) => {
          return child && (child.type === components.Input ? child : null);
        })}
      </components.ValueContainer>
    );
  };

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
    setCloseDataStart(picker.startDate.format("YYYY-MM-DD"));
    setCloseDataEnd(picker.endDate.format("YYYY-MM-DD"));
  };

  const handleApplyCreateDate = (event, picker) => {
    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
    var seen = picker.startDate.format("YYYY-MM-DD");

    setCreatedDataStart(picker.startDate.format("YYYY-MM-DD"));
    setCreatedDataEnd(picker.endDate.format("YYYY-MM-DD"));
  };

  const handleApplyProject = (event, picker) => {
    picker.element.val(
      picker.startDate.format("YYYY-MM-DD") +
        " - " +
        picker.endDate.format("YYYY-MM-DD")
    );
    setCreatedDataStartProj(picker.startDate.format("YYYY-MM-DD"));
    setCreatedDataEndProj(picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };
  const handleCancelCreateDate = (event, picker) => {
    picker.element.val("");
    setCreatedDataStart("");
    setCreatedDataEnd("");
  };
  const handleCancelProject = (event, picker) => {
    picker.element.val("");
    setCreatedDataStartProj("");
    setCreatedDataEndProj("");
  };

  return (
    <>
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachbox">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={userIco} className="headingIcons" alt="Roles" /> Sale
                Report
              </h3>
            </div>
            <div
              style={{
                float: "right",
                width: "30%",
              }}
            >
              {downloadIcon ? (
                <>
                  <a
                    href={
                      BASE_URL +
                      `/bid/report-export?query_filter=receive_date_start%3D${createdDateStart}%26receive_date_end%3D${createdDateEnd}%26closed_date_start%3D${closeDateStart}%26closed_date_end%3D${closeDateEnd}%26project_date_start%3D${createdDateStartProj}%26project_date_end%3D${createdDateEndProj}%26bidder%3D${string}%26status%3D${statusString}%26client%3D${acountString}%26sales_person%3D${account_OwnerString}%26bid_amount_min%3D${bid_start}%26bid_amount_max%3D${bid_end}%26booked_amount_min%3D${booked_start}%26booked_amount_max%3D${booked_end}&page=${nextPage}&size=${pagesize}`
                    }
                  >
                    <button className="cbtn addBtn" onClick={() => {}}>
                      <DownloadIcon />
                    </button>
                  </a>
                </>
              ) : (
                <>
                  {" "}
                  <button className="cbtn addBtn" onClick={() => {}}>
                    <DownloadIcon />
                  </button>
                </>
              )}
              <div
                style={{
                  textAlign: "right",
                  marginRight: "10px",
                  float: "right",
                  display: "flex",
                }}
              >
                <label
                  className="formlabel"
                  style={{
                    width: "30%",
                    marginTop: "15px",
                  }}
                >
                  Links &nbsp;
                </label>

                <select
                  value={params["*"]}
                  onChange={(e) => {
                    navigate(`/${e.target.value}`);
                  }}
                >
                  {Link_value.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <hr />

          <div className="mainContent formCont" style={{ marginBottom: "5px" }}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12} className="col bl1">
                  <label className="formlabel">Created Date/Receive Date</label>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        cancelLabel: "Clear",
                        format: "YYYY-MM-DD",
                      },
                    }}
                    onApply={handleApplyCreateDate}
                    onCancel={handleCancelCreateDate}
                    onCallback={handleCallbackTeam}
                  >
                    <input
                      type="text"
                      className="formtext"
                      placeholder="Select Date Range"
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setCreatedDataStart("");
                          setCreatedDataEnd("");
                        }
                      }}
                    />
                  </DateRangePicker>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Close Date</label>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        cancelLabel: "Clear",
                        format: "YYYY-MM-DD",
                      },
                    }}
                    onApply={handleApply}
                    onCancel={handleCancel}
                    onCallback={handleCallbackclose}
                  >
                    <input
                      type="text"
                      className="formtext"
                      placeholder="Select Date Range"
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setCloseDataStart("");
                          setCloseDataEnd("");
                        }
                      }}
                    />
                  </DateRangePicker>
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Project Start Date</label>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        cancelLabel: "Clear",
                        format: "YYYY-MM-DD",
                      },
                    }}
                    onApply={handleApplyProject}
                    onCancel={handleCancelProject}
                    onCallback={handleCallbackTeamProject}
                  >
                    <input
                      type="text"
                      className="formtext"
                      placeholder="Select Date Range"
                      onChange={(e) => {
                        if (e.target.value == "") {
                          setCreatedDataStartProj("");
                          setCreatedDataEndProj("");
                        }
                      }}
                    />
                  </DateRangePicker>
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <div className="form-group">
                    <label className="formlabel">Bidder</label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "29px" }}
                      options={bidder}
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);

                        if (e.length !== 0) {
                          setBidderinput(e);
                        } else if (e.length === 0) {
                          setBidderinput(e);
                        }
                      }}
                      labelledBy={"Select Option"}
                      isCreatable={true}
                      disableSearch={false}
                    />
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <div className="form-group">
                    <label className="formlabel">Status</label>

                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "29px" }}
                      options={bidStatus}
                      value={statuselected}
                      onChange={(e) => {
                        setSelectedstatus(e);

                        if (e.length !== 0) {
                          setStatus(e);
                        } else if (e.length === 0) {
                          setStatus(e);
                        }
                      }}
                      labelledBy={"Select Option"}
                      isCreatable={true}
                      disableSearch={false}
                    />
                  </div>
                </Col>

                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <div className="form-group">
                    <label className="formlabel">Account</label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "29px" }}
                      options={client}
                      value={clientselected}
                      onChange={(e) => {
                        setclientSelected(e);

                        if (e.length !== 0) {
                          setAccount(e);
                        } else if (e.length === 0) {
                          setAccount(e);
                        }
                      }}
                      labelledBy={"Select Option"}
                      isCreatable={true}
                      disableSearch={false}
                    />
                  </div>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <div className="form-group">
                    <label className="formlabel">Account owner</label>
                    <MultiSelect
                      className="multiselectoption"
                      style={{ width: "29px" }}
                      options={accountOwner}
                      value={accountselected}
                      onChange={(e) => {
                        setAccountSelected(e);
                        if (e.length !== 0) {
                          setAccount_owner(e);
                        } else if (e.length === 0) {
                          setAccount_owner(e);
                        }
                      }}
                      labelledBy={"Select Option"}
                      isCreatable={true}
                      disableSearch={false}
                    />
                  </div>
                </Col>
                {/* <form></form> */}
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <label className="formlabel">Bid Amount Range</label>
                  <input
                    placeholder="start"
                    type="text"
                    className="formtext"
                    style={{ width: "45%" }}
                    {...register("bid_start", {
                      pattern: {
                        value: /^[1234567890@#$%&  ]*$/,
                        message: "Only Numbers are allowed",
                      },
                    })}
                  />
                  {errors.bid_start && (
                    <small className="errmsg">{errors.bid_start.message}</small>
                  )}
                  <label
                    style={{
                      width: "10%",
                      textAlign: "center",
                      backgroundColor: "#ccc",

                      height: "30px",
                      paddingTop: "4px",
                      border: "1px solid #ccc",
                    }}
                  >
                    to
                  </label>
                  <input
                    placeholder="end"
                    type="text"
                    className="formtext"
                    style={{ width: "45%" }}
                    {...register("bid_end", {
                      pattern: {
                        value: /^[1234567890@#$%&  ]*$/,
                        message: "Only Numbers are allowed",
                      },
                    })}
                  />
                  {errors.bid_end && (
                    <small className="errmsg">{errors.bid_end.message}</small>
                  )}
                </Col>
                <Col lg={3} md={4} sm={6} xs={12} className="col">
                  <div className="form-group">
                    <label className="formlabel">Booked Amount Range</label>
                    <input
                      placeholder="start"
                      type="text"
                      className="formtext"
                      style={{ width: "45%" }}
                      {...register("booked_start", {
                        pattern: {
                          value: /^[1234567890@#$%&  ]*$/,
                          message: "Only Numbers are allowed",
                        },
                      })}
                    />
                    {errors.booked_start && (
                      <small className="errmsg">
                        {errors.booked_start.message}
                      </small>
                    )}
                    <label
                      style={{
                        width: "10%",
                        textAlign: "center",
                        backgroundColor: "#ccc",
                        height: "30px",
                        paddingTop: "4px",
                        border: "1px solid #ccc",
                      }}
                    >
                      to
                    </label>
                    <input
                      placeholder="end"
                      type="text"
                      className="formtext"
                      style={{ width: "45%" }}
                      {...register("booked_end", {
                        pattern: {
                          value: /^[1234567890@#$%& ]*$/,
                          message: "Only Numbers are allowed",
                        },
                      })}
                    />
                    {errors.booked_end && (
                      <small className="errmsg">
                        {errors.booked_end.message}
                      </small>
                    )}
                  </div>
                </Col>
              </Row>
              <button style={{ margin: "10px 0px" }} className="cbtn addBtn">
                {isSubmitting && <ButtonSpinner />}
                {" SEARCH"}
              </button>
            </form>

            <div className="table_container pd">
              <Table
                bordered={false}
                hover
                size="sm"
                className="customTable salesReport"
              >
                <thead className="thead">
                  <TableRow>
                    {headers.map((header) => {
                      return (
                        <TableCell key={header.id}>
                          {reverSign === 0 ? (
                            <>
                              {" "}
                              <span
                                className="cellHeading"
                                title={header.label}
                              >
                                <TableSortLabel
                                  active={false}
                                  direction={"asc"}
                                  onClick={() => {
                                    handlesort(header.id);
                                  }}
                                  IconComponent={ArrowDropUpIcon}
                                >
                                  {header.label}
                                </TableSortLabel>
                              </span>
                            </>
                          ) : (
                            <>
                              {" "}
                              <span
                                className="cellHeading"
                                title={header.label}
                              >
                                <TableSortLabel
                                  active={false}
                                  direction={"asc"}
                                  onClick={() => {
                                    handlesort(header.id);
                                  }}
                                  IconComponent={ArrowDropDownIcon}
                                >
                                  {header.label}
                                </TableSortLabel>
                              </span>
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </thead>

                {loder === true ? (
                  <>
                    <tr>
                      <td colspan="12">
                        <SpinnerLoader />
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    {noData === true ? (
                      <>
                        <tr>
                          <td colspan="12">No data Found</td>
                        </tr>
                      </>
                    ) : (
                      <>
                        {" "}
                        <TableBody id="mailbox">
                          {userdata.map((e, i) => (
                            <>
                              <TableRow key={e.id}>
                                <TableCell>
                                  {pagesize * (nextPage - 1) + (i + 1)}
                                </TableCell>
                                <TableCell>
                                  {e.project_name.slice(0, 20)}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.created_at == null ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      {moment(e.opportunity.created_at).format(
                                        "D/MM/YYYY"
                                      )}
                                    </>
                                  )}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.closed_date == null ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      {moment(e.opportunity.closed_date).format(
                                        "D/MM/YYYY"
                                      )}
                                    </>
                                  )}{" "}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.project_start_date == null ? (
                                    <></>
                                  ) : (
                                    <>
                                      {" "}
                                      {moment(
                                        e.opportunity.project_start_date
                                      ).format("D/MM/YYYY")}
                                    </>
                                  )}{" "}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.status.name}
                                </TableCell>
                                <TableCell>{e.client.client_name}</TableCell>
                                <TableCell>{e.bid_owner.name}</TableCell>
                                <TableCell>
                                  {e.client.sale_representive.name}
                                </TableCell>
                                <TableCell>
                                  {e.follow_up === 1 ? <>Yes</> : <>No</>}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.bid_amount}
                                </TableCell>
                                <TableCell>
                                  {e.opportunity.booked_amount}
                                </TableCell>
                                <TableCell>
                                  <td>
                                    <span className="actionButton">
                                      <VisibilityIcon
                                        onClick={() => {
                                          navigate(
                                            `/edit-opportunity/${e._id}/${e.opportunity.opportunity_number}`
                                          );
                                        }}
                                      />
                                    </span>
                                  </td>
                                </TableCell>
                              </TableRow>
                            </>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </>
                )}
              </Table>
            </div>
            <Paginations
              userlist={dataLength}
              getPageSize={getPageSize}
              handlePageChnage={handlePageChnage}
              page={nextPage}
              pagesize={pagesize}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SaleReport;
