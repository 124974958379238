import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import Quotafullbuildurl from "../SupplyInfo/PopUp/Quotafullbuildurl";
const YourComponent = () => {
  const [addedBoxes, setAddedBoxes] = useState([]);
    const [qbuildurl, setQBuildUrl] = useState(false);
     
  let count = 1;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();


return(
  
  <>
     <Quotafullbuildurl
        show={qbuildurl}
        onHide={() => setQBuildUrl(false)}
        setQBuildUrl={setQBuildUrl}
        qbuildurl={qbuildurl}
        // setQuotafulllink={setQuotafulllink}
        // quotafulllink={quotafulllink}
        // hideaddsupplier={props.setShowAddSetup}
      />
  <button onClick={()=>{
    setQBuildUrl(true)
  }}>
    bbbbbbbbbbb
  </button>
  </>
)

};

export default YourComponent;
