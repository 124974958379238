import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useForm } from "react-hook-form";
import "./RequestStatus.css";
import toast from "react-hot-toast";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
import Spinner from "react-bootstrap/Spinner";

function AddManualSupplier(props) {
  const axiosPrivate = useAxiosPrivate();
  const [selected, setSelected] = useState([]);
  const [selectedmulti, setSelectedMulti] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notes, setNotes] = useState();
  const [loder, setLoder] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();
  useEffect(() => {
    get_Supplierlist_api();
  }, []);
  useEffect(() => {
    if (props.requestStatusId !== undefined) {
      get_Supplierlist_api();
      setSelected([]);
      setNotes("");
    }
  }, [props.requestStatusId]);
  const notifysucces = () => toast.success("Added supplier successfully");
  const notifyfail = (message) => toast.error(message);
  const get_Supplierlist_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        "supplyinfo/active-suppliers?supplier_type_id=2"
      );
      setSupplierList([]);
      response.data.supplier.map((e) => {
        setSupplierList((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      // console.error(err);
    }
    setLoder(true);
  };
  const handleMultiSelectChange = (selectedOptions) => {
    // Get the current quota values
    const currentQuota = getValues("n");
    const currentSn = getValues("SN");
    const currentCpi = getValues("CPI") || {};
    // Remove data for options that are no longer selected

    selected.forEach((option) => {
      if (!selectedOptions.includes(option)) {
        delete currentSn[option.value];
      }
    });

    selected.forEach((option) => {
      if (!selectedOptions.includes(option)) {
        delete currentQuota[option.value];
      }
    });

    selected.forEach((option) => {
      if (!selectedOptions.includes(option)) {
        delete currentCpi[option.value];
      }
    });

    setSelected(selectedOptions);
  };
  const handleSaveButton = async (e, data) => {
    setIsSubmitting(true);
    const { CPI, SN, n } = e;
    let supplier;
    if (SN === undefined || null || "") {
      supplier = [];
    } else {
      supplier = Object.keys(CPI).map((supplierId) => ({
        supplier_id: supplierId,
        n: n[supplierId],
        supplier_cost: parseFloat(CPI[supplierId]),
      }));
    }
    let bodydata;
    if (supplier.length === 0 && notes !== "") {
      bodydata = {
        survey_id: props.surveyId,

        notes: notes,
      };
    } else if (supplier.length !== 0) {
      bodydata = {
        survey_id: props.surveyId,
        supplier: supplier,
        notes: notes,
      };
    }
    if (bodydata !== undefined || supplier.length !== 0 || notes !== "") {
      try {
        await axiosPrivate
          .post(
            `survey-supplier/add-survey-supplier`,
            JSON.stringify(bodydata),
            {
              headers: { "Content-Type": "application/json" },
            }
          )
          .then((data) => {
            if (data.status === 200) {
              notifysucces();
              handleClose();
              props.setRefreshApi(true);
            }
          });
      } catch (err) {
        notifyfail(err.response.data.return_status.message[0]);

        handleClose();
      }
    } else {
      notifyfail("Please add notes or supplier");
    }

    setIsSubmitting(false);
  };
  const handleClose = () => {
    const currentQuota = getValues("n");
    const currentSn = getValues("SN");
    const currentCpi = getValues("CPI");
    // Remove data for options that are no longer selected

    selectedmulti.forEach((option) => {
      delete currentSn[option.value];
      delete currentSn[option.label];
    });

    selectedmulti.forEach((option) => {
      delete currentQuota[option.value];
    });

    selectedmulti.forEach((option) => {
      delete currentCpi[option.value];
    });

    setSelected([]);
    setNotes("");
    props.setAdd(false);
  };
  const handleshow = () => {
    const currentQuota = getValues("n");
    const currentSn = getValues("SN");
    const currentCpi = getValues("CPI");
    // Remove data for options that are no longer selected

    selectedmulti.forEach((option) => {
      delete currentSn[option.value];
      delete currentSn[option.label];
    });

    selectedmulti.forEach((option) => {
      delete currentQuota[option.value];
    });

    selectedmulti.forEach((option) => {
      delete currentCpi[option.value];
    });

    setNotes("");
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      onShow={handleshow}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Add Manual Supplier
        </Modal.Title>
      </Modal.Header>
      {props.requestStatusId === undefined ? (
        <>
          <Modal.Body>
            <h6> Please fill the request status and request Owner</h6>
          </Modal.Body>
        </>
      ) : loder ? (
        <>
          <Modal.Body>
            <div>
              <form onSubmit={handleSubmit(handleSaveButton)}>
                <Row>
                  <Col lg={4} md={12} sm={12} xs={12} className="csmulti">
                    <label className="formlabelviewzone">Supplier Name </label>
                    <div>
                      <MultiSelect
                        className=""
                        options={supplierList}
                        value={selected}
                        onChange={(e) => {
                          handleMultiSelectChange(e);
                          setSelectedMulti(e);
                        }}
                        labelledBy={"Select"}
                        isCreatable={true}
                      />
                    </div>
                  </Col>
                </Row>
                {selected.map((e, index) => (
                  <>
                    {" "}
                    <div style={{ height: "15px" }}></div>
                    <Row
                      style={{
                        backgroundColor: "whitesmoke",
                        margin: "2px",
                        paddingTop: "10px",
                        paddingBottom: "20px",
                      }}
                      className="addcss"
                    >
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="addsupply_col"
                      >
                        <label className="formlabelviewzone">
                          Supplier Name
                        </label>
                        <input
                          {...register(`SN.${e.value}`, {
                            required: "This field is Required",
                          })}
                          placeholder="Cint"
                          className="formtextviewzone"
                          type="text"
                          defaultValue={e.label}
                          readOnly
                        />
                        {errors?.SN?.[e.value] && (
                          <small className="addnew_supply">
                            {errors.SN[e.value].message}
                          </small>
                        )}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="addsupply_col"
                      >
                        <label className="formlabelviewzone">CPI*</label>
                        <input
                          {...register(`CPI.${e.value}`, {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0-9.]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          placeholder="CPI"
                          className="formtextviewzone"
                          type="text"
                        />
                        {errors?.CPI?.[e.value] && (
                          <small className="addnew_supply">
                            {errors.CPI[e.value].message}
                          </small>
                        )}
                      </Col>
                      <Col
                        lg={4}
                        md={4}
                        sm={4}
                        xs={12}
                        className="addsupply_col"
                      >
                        <label className="formlabelviewzone">Quota*</label>
                        <input
                          {...register(`n.${e.value}`, {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          placeholder="Quota"
                          className="formtextviewzone"
                          type="text"
                        />
                        {errors?.n?.[e.value] && (
                          <small className="addnew_supply">
                            {errors.n[e.value].message}
                          </small>
                        )}
                      </Col>
                    </Row>
                  </>
                ))}

                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="addsupply_col"
                  >
                    <label className="formlabelviewzone">Notes</label>

                    <textarea
                      style={{ height: "100px" }}
                      placeholder="Notes"
                      className="formtextviewzone"
                      type="text"
                      defaultValue={notes}
                      onChange={(e) => {
                        setNotes(e.target.value);
                      }}
                    />
                    {errors?.notes && (
                      <small className="addnew_supply">
                        {errors.notes.message}
                      </small>
                    )}
                  </Col>
                </Row>
                <Col>
                  <button
                    className="cbtn saveBtn"
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{ color: "white" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp; Submit
                  </button>
                </Col>
              </form>
            </div>
          </Modal.Body>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </Modal>
  );
}
export default AddManualSupplier;
