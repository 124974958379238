import Supplyheader from '../Supplyheader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './SupplierDetails.css';
import React, { useEffect, useState } from 'react';

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { useNavigate } from 'react-router';
import userIco from '../../../assets/LoginIcons/users.svg';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import '../Supplypage.css';
function Addnewsupplier() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prescreening, setprescreening] = useState();
    const [qualiﬁcation, setQualiﬁcation] = useState();
    const [screen, setScreen] = useState();
    const [herring, setHerring] = useState();
    const [researchdefender, setResearchdefender] = useState();
    const [speederterminate, setSpeederterminate] = useState();
    const [countrycheck, setCountrycheck] = useState();
    const [proxycheck, setProxycheck] = useState();
    const [duplicateip, setDuplicateip] = useState();
    const [duplicateoctete, setDuplicateoctate] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [countryselect, setCountrySelect] = useState();
    const [countryListStorage, setCountryListStorage] = useState([{}]);
    const [supplier_type, setSupplier_type] = useState([{}]);
    const [supplier_type_Select, setSupplier_type_Select] = useState();
    useEffect(() => {
        setValue('Country', countryselect);
        setValue('SupplierType', supplier_type_Select);
    }, [countryselect, supplier_type_Select]);
    const pname = 'SupplierDetails';
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    useEffect(() => {
        get_Country_api();
        get_Supplier_type();
    }, []);
    const get_Country_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/country-list');
            response.data.all_countries.map((e) => {
                setCountryListStorage((val) => [
                    ...val,
                    { label: e.country_name, value: e._id },
                ]);
            });
        } catch (err) {
            console.error(err);
        }
    };
    const get_Supplier_type = async () => {
        try {
            const response = await axiosPrivate.get('lookup/supplier-type');
            setSupplier_type([]);
            response.data.all_status.map((e) => {
                setSupplier_type((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
        } catch (err) {
            console.error(err);
        }
    };
    const notifysuccess = (msg) => toast.success(msg);

    const handleSaveButton = async (e, data) => {
        setIsSubmitting(true);

        try {
            await axiosPrivate
                .post(
                    'supplyinfo/create-supplier',
                    JSON.stringify({
                        name: e.firstName,
                        supplier_abbr: e.VendorAbbr,
                        email: e.email,
                        phone: e.contactNumber,
                        address: {
                            billing_address: e.BillingAddress,
                            country_id: countryselect,
                            state: e.state,
                            city: e.city,
                            zipcode: e.Zipcode,
                        },
                        screening: {
                            pre_screening: prescreening,
                            qualification_screening: qualiﬁcation,

                            red_herring_screen: herring,
                        },
                        security_check: {
                            screen_captcha: screen,
                            research_defender: researchdefender,
                            speeder_terminate: speederterminate,
                            country_check: countrycheck,
                            proxy_check: proxycheck,
                            duplicate_ip: duplicateip,
                            duplicate_ip3_octet: duplicateoctete,
                        },

                        api: {
                            is_api: e.isApi,
                            is_publisher: e.Publisher,
                        },
                        supplier_type_id: supplier_type_Select,
                        status: e.status,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((data) => {
                    if (data.status === 200) {
                        // notifysuccess("Check your mail for further verification");
                        notifysuccess('Supplier added successfully');
                        navigate('/supplier-details');
                    }
                });
        } catch (err) {}
        setIsSubmitting(false);
    };

    return (
        <>
            <Supplyheader pname={pname} />
            <Row className="supplierParents csmulti">
                <form onSubmit={handleSubmit(handleSaveButton)}>
                    <Row
                        className="suppliyChild"
                        style={{
                            paddingBottom: '20px',
                        }}
                    >
                        <div className="user_serachbox">
                            <div style={{ float: 'left' }}>
                                <h3 className="tableHeading">
                                    <img
                                        src={userIco}
                                        className="headingIcons"
                                        alt=""
                                    />{' '}
                                    New Supplier
                                </h3>
                            </div>
                            <div className="right-pane">
                                <button
                                    className="cbtn closeBtn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        navigate('/supplier-details');
                                    }}
                                >
                                    {' '}
                                    Close
                                </button>

                                <button
                                    // disabled={isSubmitting}
                                    className="cbtn saveBtn"
                                    type="submit"
                                >
                                    {isSubmitting && (
                                        <Spinner
                                            style={{ color: 'white' }}
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                    &nbsp;&nbsp; Save
                                </button>
                            </div>
                        </div>
                        <hr
                            style={{
                                marginTop: '20px',
                            }}
                        />
                        <Row
                            className="addcss flex rowGap-5"
                            style={{ margin: '0px' }}
                        >
                            <Col
                                className="addsupply_col"
                                // style={{
                                //   position: "relative",
                                // }}
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Name*
                                </label>
                                <input
                                    placeholder="Name"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('firstName', {
                                        required: 'This field is Required',
                                    })}
                                />
                                {errors.firstName && (
                                    <small className="addnew_supply">
                                        {errors.firstName.message}
                                    </small>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Email*
                                </label>
                                <input
                                    placeholder="Email"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('email', {
                                        required: 'This field is Required',
                                        pattern: {
                                            value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                                            message: 'Enter Valid Email Id',
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <small className="addnew_supply">
                                        {errors.email.message}
                                    </small>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Phone
                                </label>
                                <input
                                    placeholder="Phone"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('contactNumber', {
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: 'Only numbers are allowed',
                                        },
                                    })}
                                />
                                {errors.contactNumber && (
                                    <small className="addnew_supply">
                                        {errors.contactNumber.message}
                                    </small>
                                )}
                            </Col>{' '}
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Billing Address*
                                </label>
                                <input
                                    placeholder="Billing Address"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('BillingAddress', {
                                        required: 'This field is Required',
                                    })}
                                />
                                {errors.BillingAddress && (
                                    <small className="addnew_supply">
                                        {errors.BillingAddress.message}
                                    </small>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Country*
                                </label>

                                <Select
                                    placeholder="Select..."
                                    className="pjt_select formtext"
                                    classNamePrefix="select"
                                    {...register('Country', {
                                        required: 'This field is Required',
                                    })}
                                    options={countryListStorage}
                                    onChange={(e) => {
                                        setCountrySelect(e.value);
                                    }}
                                    noOptionsMessage={() => 'No Data Found'}
                                />
                                {countryselect ? (
                                    <></>
                                ) : (
                                    <>
                                        {errors.Country && (
                                            <small className="addnew_supply">
                                                {errors.Country.message}
                                            </small>
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    State
                                </label>
                                <input
                                    placeholder="State"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('state', {
                                        pattern: {
                                            value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                            message: 'Only letters are allowed',
                                        },
                                    })}
                                />
                                {errors.state && (
                                    <small className="addnew_supply">
                                        {errors.state.message}
                                    </small>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    City
                                </label>
                                <input
                                    placeholder="City"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('city', {
                                        pattern: {
                                            value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                            message: 'Only letters are allowed',
                                        },
                                    })}
                                />
                                {errors.city && (
                                    <small className="addnew_supply">
                                        {errors.city.message}
                                    </small>
                                )}
                            </Col>{' '}
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Zipcode
                                </label>
                                <input
                                    placeholder="Zipcode"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('Zipcode', {
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: 'Only numbers are allowed',
                                        },
                                    })}
                                />
                                {errors.Zipcode && (
                                    <small className="addnew_supply">
                                        {errors.Zipcode.message}
                                    </small>
                                )}
                            </Col>
                        </Row>{' '}
                        <Row className="addcss mt-2" style={{ margin: '0px' }}>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Supplier Type*
                                </label>

                                <Select
                                    placeholder="Select..."
                                    className="pjt_select formtext"
                                    classNamePrefix="select"
                                    {...register('SupplierType', {
                                        required: 'This field is Required',
                                    })}
                                    options={supplier_type}
                                    onChange={(e) => {
                                        setSupplier_type_Select(e.value);
                                    }}
                                    noOptionsMessage={() => 'No Data Found'}
                                />
                                {supplier_type_Select ? (
                                    <></>
                                ) : (
                                    <>
                                        {errors.SupplierType && (
                                            <small className="addnew_supply">
                                                {errors.SupplierType.message}
                                            </small>
                                        )}
                                    </>
                                )}
                            </Col>
                            <Col
                                className="addsupply_col"
                                lg={3}
                                md={3}
                                sm={6}
                                xs={12}
                            >
                                <label className="formlabelviewzone">
                                    Supplier Abbr*
                                </label>
                                <input
                                    placeholder="Supplier Abbr"
                                    className="formtextviewzone"
                                    type="text"
                                    {...register('VendorAbbr', {
                                        required: 'This field is Required',
                                        pattern: {
                                            value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                            message: 'Only letters are allowed',
                                        },
                                    })}
                                />
                                {errors.VendorAbbr && (
                                    <small className="addnew_supply">
                                        {errors.VendorAbbr.message}
                                    </small>
                                )}
                            </Col>
                            <Col lg={3} md={3} sm={6} xs={12}>
                                <label className="formlabelviewzone">
                                    Status
                                </label>
                                <select
                                    className="formtext"
                                    {...register('Status', {})}
                                >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </select>
                            </Col>
                        </Row>{' '}
                        <Row
                            style={{
                                margin: '0px',
                            }}
                        >
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Pre-Screening
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setprescreening(e);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Qualiﬁcation screen
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Qualiﬁcation')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setQualiﬁcation(e);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Screen Captcha
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setScreen(e);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Red Herring Screen
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setHerring(e);
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Research defender
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setResearchdefender(e);
                                        }}
                                        checked={researchdefender}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Speeder Terminate
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setSpeederterminate(e);
                                        }}
                                        checked={speederterminate}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Country Check
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setCountrycheck(e);
                                        }}
                                        checked={countrycheck}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Proxy Check
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setProxycheck(e);
                                        }}
                                        checked={proxycheck}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Duplicate IP
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setDuplicateip(e);
                                        }}
                                        checked={duplicateip}
                                    />
                                </div>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={6}
                                xs={6}
                                className="supplytoggele_col"
                            >
                                <label className="formlabelviewzone">
                                    Duplicate Ip3 Octet
                                </label>
                                <div className="tooglecenter pswtchbtn supplytoggele">
                                    <BootstrapSwitchButton
                                        {...register('Screening')}
                                        className="switch btn on btn-outline-success btn-xs"
                                        size="xs"
                                        width={60}
                                        onlabel="On "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                        onChange={(e) => {
                                            setDuplicateoctate(e);
                                        }}
                                        checked={duplicateoctete}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Row>
                </form>
            </Row>
        </>
    );
}
export default Addnewsupplier;
