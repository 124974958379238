import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';

import { useForm } from 'react-hook-form';

import SpinnerLoader from '../../../../UI/Spinner';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import axios from 'axios';
import { axiosPrivate } from '../../../../../api/axios';
import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
const Uploadzipcode = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const notifySuccess = (e) => toast.success(`Zip codes added successfully`);
    const notifySuccessFile_uploaded = (e) =>
        toast.success(`Zip File uploaded successfully`);
    const [loder, setLoder] = useState(true);
    const [spinner, setSpinner] = useState(false);
    const [fileUploadstatus, setFileUploadstatus] = useState(false);
    const [url_link, setUrl_Link] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const uploadZip = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('zipcode_upload', e.target.files[0]);

        const url = `qualification/upload-files?survey_id=${props.survey_ID}`;

        axiosPrivate
            .post(url, formData)
            .then((response) => {
                if (response.status === 200) {
                    props.setUploadedFile_name(e.target.files[0].name);
                    setUrl_Link(response.data.url);
                    notifySuccessFile_uploaded();
                }
                // Handle successful response
            })
            .catch((error) => {
                // Handle error
                console.error('Error:', error);
            });
    };

    const addSubmit = () => {
        const url = 'qualification/update-zipcode-qualification';

        let requestBody;
        if (fileUploadstatus === true) {
            requestBody = {
                qualify_id: props.zipQyalid,
                q_id: 3,

                is_all_selected: true,
                order_by: 4,
            };
        } else if (fileUploadstatus === false) {
            requestBody = {
                qualify_id: props.zipQyalid,
                q_id: 3,
                upload_zipcode: url_link,
                is_all_selected: false,
                order_by: 4,
            };
        }
        if (fileUploadstatus === true || url_link) {
            setSpinner(false);
            try {
                axiosPrivate
                    .post(url, requestBody)
                    .then((response) => {
                        if (response.status === 200) {
                            notifySuccess();
                            props.setUpZipState(false);
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            } catch (err) {}
            setSpinner(true);
        }
        // Make the POST request using Axios
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ fontSize: '16px', paddingLeft: '3px' }}
                    >
                        Upload Zipcode
                    </Modal.Title>
                </Modal.Header>
                {loder ? (
                    <>
                        <form onSubmit={handleSubmit(addSubmit)}>
                            <Modal.Body>
                                <Row>
                                    <Col
                                        lg={6}
                                        md={6}
                                        sm={6}
                                        xs={6}
                                        className="flex items-center gap4"
                                    >
                                        <label htmlFor="">Select All</label>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => {
                                                setFileUploadstatus(
                                                    e.target.checked
                                                );
                                            }}
                                            value={fileUploadstatus}
                                        />
                                    </Col>
                                    {!fileUploadstatus ? (
                                        <>
                                            <Col
                                                lg={6}
                                                md={6}
                                                sm={6}
                                                xs={6}
                                                className="col fileUpload"
                                            >
                                                <label className="custom-file-upload btnWidth_set m-0 border-0">
                                                    <input
                                                        type="file"
                                                        onChange={(e) => {
                                                            uploadZip(e);
                                                        }}
                                                        accept=".csv"
                                                    />
                                                    Upload Zipcode
                                                </label>{' '}
                                            </Col>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="cbtn closeBtn pbtn m-0 me-2"
                                    onClick={(e) => {
                                        //   e.preventDefault();
                                        props.setUpZipState(false);
                                    }}
                                >
                                    Close
                                </button>
                                <button
                                    className="cbtn saveBtn m-0"
                                    style={{
                                        marginBottom: '10px',
                                        marginRight: '15px',
                                    }}
                                    type="submit"
                                >
                                    {spinner === true ? (
                                        <Spinner
                                            as="span"
                                            style={{ color: 'white' }}
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    &nbsp; Submit
                                </button>
                            </Modal.Footer>
                        </form>
                    </>
                ) : (
                    <SpinnerLoader />
                )}
            </Modal>
        </>
    );
};

export default Uploadzipcode;
