import React, { useState, useEffect, useRef } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import moment from "moment";
const TeamPerformance = (props) => {
  const [data, setData] = useState([]);
  const [bidStatus, setBiStatus] = useState([]);

  let newCurrentDate = moment().subtract(7, "days").format("YYYY-MM-DD");
  let newEndDate = moment().format("YYYY-MM-DD");
  const axiosPrivate = useAxiosPrivate();

  let startD = useRef();
  let endD = useRef();

  useEffect(() => {
    if (props.teamperfState === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.teamperfState.CurrentDate;
      endD.current = props.teamperfState.EndDate;
    }
    if (props.data) {
      props.setFlag(false);
      let user = "";
      props.data.map((e) => {
        if (props.data[props.data.length - 1]._id !== e._id) {
          user = user + `user${e._id}&`;
        } else {
          user = user + `user${e._id}`;
        }
      });

      Datauser(startD, endD, user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.teamperfState]);

  useEffect(() => {
    if (props.teamperfState === undefined) {
      startD.current = newCurrentDate;
      endD.current = newEndDate;
    } else {
      startD.current = props.teamperfState.CurrentDate;
      endD.current = props.teamperfState.EndDate;
    }

    getDataTeam(startD, endD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.teamperfState]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosPrivate.get("lookup/get_bid_status", {});

        let data = response.data.all_status;

        const newData = Object.keys(data).reduce((result, currentKey) => {
          let fdata = data[currentKey];
          fdata.ckey = currentKey;
          result.push(fdata);
          return result;
        }, []);

        setBiStatus(newData);
      } catch (err) {
      }
    };
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataTeam = async (startD, endD) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/team-performance?start_time=${startD.current}&end_time=${endD.current}`,
        {}
      );

      let data = response.data.team_performance;
      const newData = Object.keys(data).reduce((result, currentKey) => {
        let fdata = data[currentKey];
        fdata.ckey = currentKey;
        result.push(fdata);
        return result;
      }, []);

      setData(newData);
    } catch (err) {}
  };

  const Datauser = async (startD, endD, user) => {
    try {
      const response = await axiosPrivate.get(
        `bid-dashboard/team-performance?start_time=${startD.current}&end_time=${endD.current}&${user}`,
        {}
      );

      let data = response.data.team_performance;
      const newData = Object.keys(data).reduce((result, currentKey) => {
        let fdata = data[currentKey];
        fdata.ckey = currentKey;
        result.push(fdata);
        return result;
      }, []);

      setData(newData);
    } catch (err) {}
  };

  return (
    <div>
      <table className="dbTable">
        <thead>
          <tr>
            <th>Member</th>
            {/* {bidStatus.map((e, index) => (
              <th>{e.name}</th>
            ))} */}
            {bidStatus.map((e, index) =>
              e.ckey == 0 ? (
                <></>
              ) : (
                <>
                  <th>{e.name}</th>
                </>
              )
            )}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((event) => (
            <tr>
              <td>{event.name}</td>
              {bidStatus.map((e, index) =>
                e.ckey == 0 ? (
                  <></>
                ) : (
                  <>
                    <td>{event.bid_status[e.id]}</td>
                  </>
                )
              )}
              {/* {bidStatus.map((e) => (
                <td>{event.bid_status[e.id]}</td>
              ))} */}
              <td>{event.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamPerformance;
