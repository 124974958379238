import { useState, useEffect, useRef } from "react";
import "../QuotaTarget.css";
import "./Quota.css";
import { useForm, Controller } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import Multiselect from "../../../Multiselect/Multiselect";
import NestingAddquota from "../Target_Audience/popup/NestingAddquota";
import Editquota from "../Target_Audience/popup/Editquota";
import QuotaNesting from "./popup/Quotanesting";
import Addmodalselect from "./popup/Addmodalselect";
import Select from "react-select";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../../UI/Spinner";

import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import AgeOption from "./popup/AgeOption";
import Addagequota from "./popup/AddageQuota";
import Editquotaage from "./popup/EditageQuota";
import { AiFillDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Quota from "./Quota";
import Uploadzipcode from "./popup/Uploadzipcode";
import Showzipdata from "./popup/Showzipdata";
import LogsModal from "./popup/logsModal";
const TargetDetailTest = (prop) => {
  const { id, layoutId, survey_Id } = useParams();
  const navigate = useNavigate();
  const accesstoken = "Bearer " + localStorage.getItem("atoken");
  const notifySuccess_Delete = (e) => toast.success(`Quota deleted`);
  const notifySuccess = (e) => toast.success(`${e} selected successfully`);
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(false);
  const [emptyCount, setEmptycount] = useState(4);
  const [excludedIds, setExcludedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [commonState, setCommonState] = useState([]);
  const [addQuotapopup, showAddquotapopup] = useState(false);
  const [editQuotapopup, showEditquotapopup] = useState(false);
  const [nestedQuotapopup, showNestedquotapopup] = useState(false);
  const [surveyidselect, setSurveyIdselect] = useState([]);
  const [dropdownloader, setDropDownLoader] = useState(true);
  const [selectedItem, setSelectedItem] = useState([]);
  const [addQuotaValues, setAddQuotavalues] = useState([]);
  const [addOrEditname, setAddorEditName] = useState();
  const [single_qualif, setSingle_Qualif] = useState({});
  const [selected_Quali_id, setSelected_Quali_id] = useState();
  const [all_data, setAll_data] = useState([]);
  const [all_dataclone, setAll_data_Clone] = useState([]);
  const [upZipstate, setUpZipState] = useState(false);
  const [uploadedFile_name, setUploadedFile_name] = useState("");
  const [zipCodeData, setZipCodeData] = useState([]);
  const [zipPopup, setZipPopUp] = useState(false);
  const [ageQual_data, setAgeQual_data] = useState([]);
  const [survey_ID, setSurvey_ID] = useState();
  const [ageOption, setAgeOption] = useState("");
  const [show_ageOPt, setAge_OPt] = useState(false);
  const [add_ageQuotapopup, setAdd_AgeQuotapopup] = useState(false);
  const [shouldHandleBlur, setShouldHandleBlur] = useState(true);
  const [editagePop, setEditagePop] = useState(false);
  const [allowSelectAll, setAllowSelectAll] = useState(true);
  const [isClearable, setIsClearable] = useState(true);
  const [showSurvey_select, setShowSurvey_select] = useState(false);
  const [is_qualify_launch, setIs_qualify_launch] = useState(false);
  const [targ_aud_status, setTarg_aud_status] = useState(false);
  const [activeQualData, setActiveQualData] = useState([]);
  const [searchFocused, setSearchFocused] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const [logsmodal_status, setLogsmodal_Status] = useState(false);
  const [selected_SC_Id, setSelected_SC_Id] = useState("");
  const preventBlurRef = useRef(true);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setError,
    trigger,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      targetdetails: [
        {
          gender: "",
          age: "",
          ethnicity: "",
          hispanic: "",
          household: "",
          definition: "",
          upload: "",
        },
      ],
    },
  });
  useEffect(() => {
    get_Multiform_api();
    setIsDisabled(true);
  }, [id, survey_Id]);
  const get_Multiform_api = async () => {
    setDropDownLoader(true);
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-project-id?project_id=${id}`
      );

      const surveyData = response.data.survey.map((e, index) => ({
        value: e.id,
        label: e.survey_name,
        scId: e.sc_id,
      }));

      if (survey_Id != undefined && survey_Id != 1) {
        const NotsurveyData = surveyData.filter(
          (item) => survey_Id === item.value
        );
        if (NotsurveyData.length !== 0) {
          const el = surveyData.filter((item) => survey_Id === item.value);

          setSelectedItem(el);
          setSurvey_ID(el.value);
          prop.setSelectedElem(el.value);
          setShowSurvey_select(true);
          setSurveyIdselect(surveyData);
          setSelected_SC_Id(el.scId);
        } else {
          setShowSurvey_select(false);
          getQualifiacation(survey_Id);
          setSurvey_ID(survey_Id);

          setSurveyIdselect(surveyData);
        }
      } else {
        const el = surveyData.filter(
          (item) => surveyData[0].value === item.value
        );

        if (el.length !== 0) {
          setSelected_SC_Id(el.scId);
          setSurvey_ID(el.value);
          setSelectedItem(el);

          setShowSurvey_select(true);
          setSurveyIdselect(surveyData);
        } else {
          setSelectedItem([]);
          setDropDownLoader(false);
          setSurveyIdselect([]);
          setNoDataFound(true);
          setLoader(true);
        }
      }
    } catch (err) {
      setSelectedItem([]);
      setDropDownLoader(false);
      setSurveyIdselect([]);
      setNoDataFound(true);
      setLoader(true);
    }
    setDropDownLoader(false);
  };

  useEffect(() => {
    if (selectedItem[0] && showSurvey_select === true) {
      getQualifiacation(selectedItem[0].value);
      setSurvey_ID(selectedItem[0].value);
    }
  }, [selectedItem[0], showSurvey_select]);

  useEffect(() => {
    if (ageQual_data[0] && ageQual_data[0].ansCode !== null) {
      if (ageQual_data[0] && ageQual_data[0].ansCode.length > 0) {
        const formattedAges = ageQual_data[0].ansCode.map(
          ({ min_age, max_age }) => `${min_age}-${max_age}`
        );
        setAgeOption(formattedAges.join(","));
      }
    }
  }, [ageQual_data, show_ageOPt]);

  const getQuestionListTest = async (survey_ID) => {
    return axiosPrivate
      .get(`qualification/get-question-list/${survey_ID}`)
      .then((response) => {
        setAllQuestions(response.data.question);
        return response.data.question;
      });
  };

  const fetchAnswers = async (survey_ID, questionId, ansCode) => {
    try {
      const response = await axiosPrivate.get(
        `qualification/get-answer-list/${survey_ID}/${questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const allAnswers = response.data.answer.map((answer) => ({
        label: answer.answer_title.en_title,
        value: answer.ans_code,
        ans_code: answer.ans_code,
      }));

      // Filtered answers based on ansCode

      const filteredAnswers = response.data.answer
        .filter((answer) => ansCode.includes(answer.ans_code))
        .map((answer) => ({
          label: answer.answer_title.en_title,
          value: answer.ans_code,
          ans_code: answer.ans_code,
          perc: "",
        }));

      return { allAnswers, filteredAnswers };
    } catch (error) {
      return { allAnswers: [], filteredAnswers: [] };
    }
  };

  const getQualifiacation = async (survey_ID) => {
    setLoader(false);
    setAll_data([]);
    try {
      // Make a GET request using Axios with headers
      await axiosPrivate
        .get(`qualification/get-qualification/${survey_ID}`)
        .then(async (response) => {
          setEmptycount(4);
          setExcludedIds([]);
          setActiveQualData(response.data.qualification.qualify);
          setIs_qualify_launch(
            response.data.qualification.qualification.is_qualify_launch
          );
          setTarg_aud_status(
            response.data.qualification.qualification.target_aud_status
          );

          const filteredAgeData = response.data.qualification.qualify.filter(
            (qualiItem) => qualiItem.q_id === 1
          );

          const Agedata_store = filteredAgeData.map((qualiItem) => ({
            qualification_id: qualiItem.id,
            is_all_selected: qualiItem.is_all_selected,
            is_allocated: qualiItem.is_allocated,
            order_by: qualiItem.order_by,
            q_id: qualiItem.q_id,
            ansCode: qualiItem.ans_code,
            is_default_qualify: qualiItem.is_default_qualify,
            perc: 0,
          }));
          setAgeQual_data(Agedata_store);
          const questionId = response.data.qualification.qualify.map(
            (questionItem) => {
              if (questionItem.q_id) {
                setExcludedIds((prevIds) => [...prevIds, questionItem.q_id]);
              }
            }
          );
          const questionList = await getQuestionListTest(survey_ID);

          const mappedData = await Promise.all(
            response.data.qualification.qualify.map(async (qualiItem) => {
              return {
                qualification_id: qualiItem.id,
                is_default_qualify: qualiItem.is_default_qualify,
                is_all_selected: qualiItem.is_all_selected,
                is_allocated: qualiItem.is_allocated,
                order_by: qualiItem.order_by,
                q_id: qualiItem.q_id,
              };
            })
          );

          const defaultQualifyCount = mappedData.filter(
            (item) => item.is_default_qualify === false
          ).length;

          if (defaultQualifyCount >= 4) {
            setEmptycount(1);
          } else {
            setEmptycount(4 - defaultQualifyCount);
          }

          const filteredData = response.data.qualification.qualify.filter(
            (qualiItem) => qualiItem.q_id === 3
          );

          const Zipdata_store = filteredData.map((qualiItem) => ({
            qualification_id: qualiItem.id,
            is_all_selected: qualiItem.is_all_selected,
            is_allocated: qualiItem.is_allocated,
            order_by: qualiItem.order_by,
            q_id: qualiItem.q_id,
            ansCode: qualiItem.ans_code,
            is_default_qualify: qualiItem.is_default_qualify,
          }));
          setZipCodeData(Zipdata_store);

          const newDataArray = await Promise.all(
            response.data.qualification.qualify.map(async (qualiItem) => {
              // Skip mapping for qualiItem.q_id 1 and 3
              setAll_data([]);
              if (qualiItem.q_id === 1 || qualiItem.q_id === 3) {
                return null;
              }
              const q_name = questionList
                .filter((q) => q.q_code === qualiItem.q_id)
                .map((q) => q.q_name)[0];

              setAll_data([]);
              const { allAnswers, filteredAnswers } = await fetchAnswers(
                survey_ID,
                qualiItem.q_id,
                qualiItem.ans_code
              );

              // Map the data to the desired structure

              return {
                qualification_id: qualiItem.id,
                is_all_selected: qualiItem.is_all_selected,
                is_allocated: qualiItem.is_allocated,
                order_by: qualiItem.order_by,
                q_id: qualiItem.q_id,
                q_name,
                allAnswers,
                filteredAnswers,
                is_default_qualify: qualiItem.is_default_qualify,

                allocated_ans_code: "", // Set allocated_ans_code to the array of fetched answer details
              };
            })
          );

          // setCommonState(newDataArray);
          setAll_data((prevData) => [
            ...newDataArray.filter((item) => item !== null),
          ]);
          setAll_data_Clone((prevData) => [
            ...newDataArray.filter((item) => item !== null),
          ]);
          setIsDisabled(false);
        })

        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("Error fetching data:", error);
        });
    } catch (err) {}

    setLoader(true);
  };

  const handleformSubmission = async (
    quest_name,
    qualifi_id,
    q_id,
    type,
    p
  ) => {
    const index = all_data.findIndex(
      (item) => item.qualification_id === qualifi_id
    );

    let ansCodesArray;
    if (type === "edit") {
      ansCodesArray = p.map((option) => option.ans_code);
    } else if (type === "add") {
      if (all_data[index].filteredAnswers.length === 0) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          message: `You cannot leave this ${quest_name} field empty `,
          messageStyle: {
            color: "#ff0000", // Set your desired color value
          },
          buttons: [
            {
              label: "OK",
              onClick: () => {
                getQualifiacation(survey_ID);
              },
            },
          ],
        });
      } else {
        ansCodesArray = all_data[index].filteredAnswers.map(
          (option) => option.ans_code
        );
      }
    }

    try {
      await axiosPrivate
        .patch(
          `qualification/update-qualification`,
          JSON.stringify({
            qualify_id: qualifi_id,
            q_id: q_id,
            ans_code: ansCodesArray,
            is_all_selected:
              all_data[index].allAnswers.length === ansCodesArray.length
                ? true
                : false,
            order_by: 4,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setSearchFocused(false);
            if (type === "edit") {
              setAll_data((prevData) => {
                return prevData.map((item) => {
                  if (item.qualification_id === qualifi_id) {
                    return {
                      ...item,
                      is_allocated: false,
                      filteredAnswers: p,
                    };
                  }
                  return item;
                });
              });
              handleDeletequoatnesting();
            } else if (type === "add") {
              if (commonState.length === 0) {
                setAll_data((prevData) => {
                  return prevData.map((item) => {
                    if (item.qualification_id === qualifi_id) {
                      return {
                        ...item,
                        is_allocated: false,
                        filteredAnswers: all_data[index].filteredAnswers,
                      };
                    }
                    return item;
                  });
                });
              } else {
                setAll_data((prevData) => {
                  return prevData.map((item) => {
                    if (item.qualification_id === qualifi_id) {
                      return {
                        ...item,
                        is_allocated: false,
                        filteredAnswers: commonState,
                      };
                    }
                    return item;
                  });
                });
              }
            }

            notifySuccess(quest_name);
          }
        });
    } catch (err) {}
  };

  const handleDeletemulti = async (delete_ID, delete_QID) => {
    // const anscodeArray = [];

    const ans_codesend = all_data
      .filter((item) => item.qualification_id === delete_ID) // Filter the items based on qualification_id
      .map((item) => item.filteredAnswers.map((option) => option.value)) // Map to extract values
      .flat();

    try {
      await axiosPrivate
        .patch(
          `qualification/update-qualification`,
          JSON.stringify({
            qualify_id: delete_ID,
            q_id: delete_QID,
            ans_code: ans_codesend,
            is_allocated: false,
            is_all_selected: false,
            order_by: 4,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAll_data((prevData) => {
              // Map over the previous data array
              return prevData.map((item) => {
                // If the qualification ID matches, update the is_allocated field
                if (item.qualification_id === delete_ID) {
                  return { ...item, is_allocated: false };
                }
                return item;
              });
            });
            toast.success("Quota deleted successfully");
            handleDeletequoatnesting();
          }
        });
    } catch (err) {}
  };
  const handleDeletequoatnesting = async () => {
    try {
      await axiosPrivate
        .post(
          `qualification/delete-quota-nesting/${survey_ID}`,

          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            toast.success("Quota nesting deleted successfully");
          }
        });
    } catch (err) {}
  };
  const handleDelete = async (delete_ID, qId, indexToDelete) => {
    const payload = {
      qualify_id: delete_ID,
    };
    try {
      await axiosPrivate
        .delete(
          `qualification/delete-qualification`,
          {
            data: payload,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAll_data((prevData) => {
              // Map over the previous data array
              return prevData.map((item) => {
                // If the qualification ID matches, update the is_allocated field
                if (item.qualification_id === delete_ID) {
                  return { ...item, is_allocated: false };
                }
                return item;
              });
            });
            setAll_data((prevData) => {
              // Filter out the item with the provided index
              return prevData.filter((item, index) => index !== indexToDelete);
            });
            if (excludedIds.includes(qId)) {
              // Remove qId from excludedIds
              setExcludedIds((prevIds) => prevIds.filter((id) => id !== qId));
            }
            toast.success("Qualification deleted successfully");
            handleDeletequoatnesting();
            setEmptycount(emptyCount + 1);
          }
        });
    } catch (err) {
      console.error(err, "errrrrrrrrr");
    }
  };
  const handleDeleteageQuota = async (delete_ID) => {
    const ansCode_send = [];
    const ans_codesend = ageQual_data[0].ansCode.forEach((item, index) => {
      // Push each item as an object to the allocatedAnsCode array
      ansCode_send.push({
        min_age: parseInt(item.min_age),
        max_age: parseInt(item.max_age),
        status: 1,
      });
    });

    try {
      await axiosPrivate
        .post(
          `qualification/update-age-qualification`,
          JSON.stringify({
            qualify_id: delete_ID,
            q_id: 1,
            ans_code: ansCode_send,
            is_allocated: false,
            is_all_selected: false,
            order_by: 4,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAgeQual_data((prevData) => {
              // Map over the previous data array
              return prevData.map((item) => {
                // If the qualification ID matches, update the is_allocated field
                if (item.qualification_id === delete_ID) {
                  return { ...item, is_allocated: false };
                }
                return item;
              });
            });
            toast.success("Quota deleted successfully");
            handleDeletequoatnesting();
          }
        });
    } catch (err) {}
  };
  const handleDelete_definationQuota = async (delete_ID, qId) => {
    const ans_codesend = all_data
      .filter((item) => item.qualification_id === delete_ID) // Filter the items based on qualification_id
      .map((item) => item.filteredAnswers.map((option) => option.value)) // Map to extract values
      .flat();

    try {
      await axiosPrivate
        .patch(
          `qualification/update-qualification`,
          JSON.stringify({
            qualify_id: delete_ID,
            q_id: qId,
            ans_code: ans_codesend,
            is_allocated: false,
            is_all_selected: false,
            order_by: 4,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            setAll_data((prevData) => {
              // Map over the previous data array
              return prevData.map((item) => {
                // If the qualification ID matches, update the is_allocated field
                if (item.qualification_id === delete_ID) {
                  return { ...item, is_allocated: false };
                }
                return item;
              });
            });
            toast.success("Quota deleted successfully");
            handleDeletequoatnesting();
          }
        });
    } catch (err) {}
  };
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const handleAccordionClick = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleTargetSwitch = async (e, ID) => {
    setLoader(false);
    const payload = {
      status: e ? 1 : 0,
    };

    let err; // Declare err variable outside try...catch block

    try {
      await axiosPrivate
        .patch(`survey/target-audience/${ID}`, payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (payload.status === 1) {
            toast.success("Target detail is active");
            setTarg_aud_status(true);
          } else {
            toast.success("Target detail is inactive");
            setTarg_aud_status(false);
          }
        });
    } catch (error) {
      // Change err to error
      err = error; // Assign error to err variable
      if (err.response.data.return_status.status_id === 0) {
        toast.error(err.response.data.return_status.message[0]);
      } else {
        toast.error(err.response.data.return_status.message[0]);
      }
    } finally {
      // Check if err is defined before using it
      if (
        err &&
        err.response &&
        err.response.data &&
        err.response.data.return_status
      ) {
        if (err.response.data.return_status.status_id === 0) {
          setTarg_aud_status(false);
        } else {
          setTarg_aud_status(true);
        }
      }
    }
    setLoader(true);
  };

  const showTarget_status = () => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      message: "Please make your qualification In active",

      buttons: [
        {
          label: "OK",
          onClick: () => getQualifiacation(survey_ID),
        },
      ],
    });
  };
  return (
    <>
      {isOpen ? (
        <>
          <Addmodalselect
            show={isOpen}
            onHide={() => setIsOpen(false)}
            setIsOpen={setIsOpen}
            selected_Quali_id={selected_Quali_id}
            setExcludedIds={setExcludedIds}
            excludedIds={excludedIds}
            setAll_data={setAll_data}
            setEmptycount={setEmptycount}
            allData={all_data}
            emptyCount={emptyCount}
            survey_ID={survey_ID}
          />
        </>
      ) : (
        <></>
      )}
      {upZipstate ? (
        <>
          <Uploadzipcode
            show={upZipstate}
            onHide={() => setUpZipState(false)}
            setUpZipState={setUpZipState}
            zipQyalid={zipCodeData[0].qualification_id}
            setUploadedFile_name={setUploadedFile_name}
            survey_ID={survey_ID}
            // hideaddsupplier={props.setShowAddSetup}
          />
        </>
      ) : (
        <></>
      )}
      {zipPopup ? (
        <>
          <Showzipdata
            show={zipPopup}
            onHide={() => setZipPopUp(false)}
            setZipPopUp={setZipPopUp}
            zipQyalid={zipCodeData[0].qualification_id}
          />
        </>
      ) : (
        <></>
      )}
      {addQuotapopup ? (
        <>
          <NestingAddquota
            show={addQuotapopup}
            onHide={() => showAddquotapopup(false)}
            showAddquotapopup={showAddquotapopup}
            addQuotapopup={addQuotapopup}
            addQuotaValues={addQuotaValues}
            single_qualif={single_qualif}
            addOrEditname={addOrEditname}
            selected_Quali_id={selected_Quali_id}
            setAll_data={setAll_data}
            all_data={all_data}
            // hideaddsupplier={props.setShowAddSetup}
          />
        </>
      ) : (
        <></>
      )}
      {editQuotapopup ? (
        <>
          <Editquota
            show={editQuotapopup}
            onHide={() => showEditquotapopup(false)}
            showEditquotapopup={showEditquotapopup}
            editQuotapopup={editQuotapopup}
            addOrEditname={addOrEditname}
            single_qualif={single_qualif}
            selected_Quali_id={selected_Quali_id}
            survey_ID={survey_ID}
            // hideaddsupplier={props.setShowAddSetup}
          />
        </>
      ) : (
        <></>
      )}
      {nestedQuotapopup ? (
        <>
          <QuotaNesting
            show={nestedQuotapopup}
            onHide={() => showNestedquotapopup(false)}
            showNestedquotapopup={showNestedquotapopup}
            nestedQuotapopup={nestedQuotapopup}
            setAddorEditName={setAddorEditName}
            addOrEditname={addOrEditname}
            survey_ID={survey_ID}

            // hideaddsupplier={props.setShowAddSetup}
          />
        </>
      ) : (
        <></>
      )}
      {/* QuotaNesting */}
      {show_ageOPt && ageQual_data ? (
        <AgeOption
          ageQual_data={ageQual_data}
          setAgeQual_data={setAgeQual_data}
          show={show_ageOPt}
          onHide={() => setAge_OPt(false)}
          setAge_OPt={setAge_OPt}
        />
      ) : (
        <></>
      )}
      {add_ageQuotapopup ? (
        <>
          <Addagequota
            show={add_ageQuotapopup}
            onHide={() => setAdd_AgeQuotapopup(false)}
            setAdd_AgeQuotapopup={setAdd_AgeQuotapopup}
            addData={ageQual_data[0]}
            setAgeQual_data={setAgeQual_data}
          />
        </>
      ) : (
        <></>
      )}
      {/* Editquotaage */}
      {editagePop ? (
        <>
          <Editquotaage
            show={editagePop}
            onHide={() => setEditagePop(false)}
            setEditagePop={setEditagePop}
            addData={ageQual_data[0]}
            setAgeQual_data={setAgeQual_data}
          />
        </>
      ) : (
        <></>
      )}
      {/* logsModal */}
      {logsmodal_status ? (
        <>
          <LogsModal
            show={logsmodal_status}
            onHide={() => setLogsmodal_Status(false)}
            setLogsmodal_Status={setLogsmodal_Status}
            survey_ID={survey_ID}
          />
        </>
      ) : (
        <></>
      )}
      <hr className="csHR mb-0" />
      <div className="quotaMain targetAudience_wrapper p-0">
        <div className="quotaMain targetAudience_wrapper pt-0">
          {showSurvey_select === true ? (
            <>
              <Row>
                <Col lg={10} md={10} sm={10} xs={12} className=" ">
                  <div className="ProjectInput ">
                    <label className="formlabel">Select Survey*</label>

                    <Select
                      className="pjt_select formtext"
                      classNamePrefix="select"
                      isDisabled={isDisabled}
                      isLoading={dropdownloader}
                      isRtl={false}
                      isSearchable={true}
                      name="color"
                      value={selectedItem[0]}
                      options={surveyidselect}
                      onChange={(e) => {
                        setIsDisabled(true);
                        setSelected_SC_Id(e.scId);
                        setSelectedItem(e);
                        navigate(
                          `/project-detail/${id}/${layoutId}/${e.value}`
                        );

                        setSurvey_ID(e.value);

                        // setSurveyName(e.label);
                        prop.setSelectedElem(e.value);
                        // reset();
                      }}
                      noOptionsMessage={() => "No Data Found"}
                    />
                  </div>
                </Col>
                <Col lg={2} md={2} sm={2} xs={12} className="d-flex items-end">
                  <button
                    className="add-Quota-btn py-1 px-3"
                    onClick={() => {
                      setLogsmodal_Status(true);
                    }}
                  >
                    Logs
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}

          {loader ? (
            <>
              {noDataFound == true && surveyidselect.length == 0 ? (
                <>
                  {" "}
                  <div
                    className="alert alert-danger center-margin-container"
                    role="alert"
                  >
                    No Survey Found!
                  </div>
                </>
              ) : (
                <>
                  <Accordion preExpanded={[0]} style={{ marginTop: "10px" }}>
                    <AccordionItem uuid={0}>
                      <AccordionItemHeading>
                        <AccordionItemButton onClick={handleAccordionClick}>
                          <div className="line_item">
                            <div className="flex-container">
                              <div>
                                <div
                                  className="itemWrap"
                                  onClick={(e) => {
                                    // e.defaultPrevented();
                                    e.stopPropagation();
                                  }}
                                >
                                  <label className="formlabel">
                                    Target Details Test - {selected_SC_Id}
                                  </label>
                                </div>
                              </div>

                              <div className="flex-end-container">
                                <>
                                  <BootstrapSwitchButton
                                    className="switch btn on btn-outline-success btn-xs"
                                    size="xs"
                                    width={60}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    onstyle="outline-success"
                                    offstyle="outline-danger"
                                    checked={targ_aud_status}
                                    onChange={(e) => {
                                      handleTargetSwitch(e, survey_ID);
                                    }}
                                  />
                                </>
                              </div>
                            </div>
                            <></>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>
                        <div style={{ padding: "10px" }}>
                          <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                              <Row>
                                {ageQual_data.map((ageItem, ageIndex) => (
                                  <Col lg={3} md={4} sm={6} xs={12}>
                                    <div>
                                      <label className="formlabel">Age</label>
                                      <InputGroup>
                                        <Form.Control
                                          placeholder=""
                                          aria-describedby="basic-addon2"
                                          name="age"
                                          value={ageOption}
                                          className="age_Input h-28"
                                          readOnly
                                        />
                                        <Button
                                          title="Add"
                                          variant="outline-secondary"
                                          className="h-28 flex items-center btnGroup-css "
                                          id="button-addon2"
                                          onClick={() => {
                                            if (targ_aud_status === true) {
                                              showTarget_status();
                                            } else {
                                              if (
                                                ageItem.is_allocated === true
                                              ) {
                                                confirmAlert({
                                                  closeOnEscape: false,
                                                  closeOnClickOutside: false,
                                                  message:
                                                    "Do you want to delete existing quota ",

                                                  buttons: [
                                                    {
                                                      label: "Yes",
                                                      onClick: () =>
                                                        handleDeleteageQuota(
                                                          ageItem.qualification_id,
                                                          true
                                                        ),
                                                    },
                                                    {
                                                      label: "No",
                                                    },
                                                  ],
                                                });
                                              } else {
                                                setAge_OPt(true);
                                              }
                                            }
                                          }}
                                        >
                                          +
                                        </Button>
                                      </InputGroup>
                                      <div className="addEditDelete_wrap flex mt-1">
                                        {ageQual_data &&
                                        ageItem.is_allocated === true &&
                                        is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  setEditagePop(true);
                                                }
                                              }}
                                            >
                                              Edit Quotas
                                            </label>
                                          </>
                                        ) : ageQual_data &&
                                          ageItem.is_allocated === false &&
                                          is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel pointer"
                                              onClick={(e) => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  setAdd_AgeQuotapopup(true);
                                                }
                                              }}
                                            >
                                              Add Quotas
                                            </label>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                        {ageQual_data &&
                                        ageItem.is_allocated === true &&
                                        is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel flex justify-end pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  confirmAlert({
                                                    closeOnEscape: false,
                                                    closeOnClickOutside: false,
                                                    message:
                                                      "Do you want to delete existing quota ",

                                                    buttons: [
                                                      {
                                                        label: "Yes",
                                                        onClick: () =>
                                                          handleDeleteageQuota(
                                                            ageItem.qualification_id,
                                                            true
                                                          ),
                                                      },
                                                      {
                                                        label: "No",
                                                      },
                                                    ],
                                                  });
                                                }
                                              }}
                                            >
                                              Delete Quotas
                                            </label>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                                {all_data.map(
                                  (optionItem, optionIndex) =>
                                    optionItem.is_default_qualify === true && (
                                      <Col lg={3} md={4} sm={6} xs={12}>
                                        <label className="formlabel">
                                          {optionItem.q_name}
                                        </label>
                                        {optionItem.is_allocated === true ? (
                                          <Controller
                                            name={`targetdetails.${optionItem.q_name}`}
                                            control={control}
                                            rules={{
                                              required:
                                                "This field is required",
                                            }}
                                            render={({ field }) => (
                                              <div className="target_select">
                                                <Multiselect
                                                  hideSelectedOptions={false}
                                                  closeMenuOnSelect={false}
                                                  value={
                                                    (field.value =
                                                      optionItem.filteredAnswers)
                                                  }
                                                  options={
                                                    optionItem.allAnswers
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      targ_aud_status === true
                                                    ) {
                                                      showTarget_status();
                                                    } else {
                                                      field.onChange(e);
                                                      if (e.length !== 0) {
                                                        confirmAlert({
                                                          message:
                                                            "Do you want to delete existing quota ",
                                                          closeOnEscape: false,
                                                          closeOnClickOutside: false,
                                                          buttons: [
                                                            {
                                                              label: "Yes",
                                                              onClick: () => {
                                                                setShouldHandleBlur(
                                                                  false
                                                                );

                                                                handleformSubmission(
                                                                  optionItem.q_name,
                                                                  optionItem.qualification_id,
                                                                  optionItem.q_id,
                                                                  "edit",
                                                                  e
                                                                );
                                                              },
                                                            },
                                                            {
                                                              label: "No",
                                                              onClick: () => {
                                                                setShouldHandleBlur(
                                                                  true
                                                                );

                                                                getQualifiacation(
                                                                  survey_ID
                                                                );
                                                              },
                                                            },
                                                          ],
                                                        });
                                                      } else {
                                                        confirmAlert({
                                                          message:
                                                            "You cannot leave the feild empty",
                                                          closeOnEscape: false,
                                                          closeOnClickOutside: false,
                                                          buttons: [
                                                            {
                                                              label: "OK",
                                                              onClick: () => {
                                                                setShouldHandleBlur(
                                                                  false
                                                                );
                                                                getQualifiacation(
                                                                  survey_ID
                                                                );
                                                              },
                                                            },
                                                          ],
                                                        });
                                                      }
                                                    }
                                                  }}
                                                  isMulti={true}
                                                  isClearable={false}
                                                  isSearchable={false}
                                                  allowSelectAll={true}
                                                />
                                              </div>
                                            )}
                                          />
                                        ) : (
                                          <Controller
                                            name={`targetdetails.${optionItem.q_name}`}
                                            control={control}
                                            rules={{
                                              required:
                                                "This field is required",
                                            }}
                                            render={({ field }) => (
                                              <div className="target_select">
                                                <Multiselect
                                                  hideSelectedOptions={false}
                                                  closeMenuOnSelect={false}
                                                  value={
                                                    field.value === undefined ||
                                                    field.value.length === 0
                                                      ? optionItem.filteredAnswers
                                                      : field.value
                                                  }
                                                  options={
                                                    optionItem.allAnswers
                                                  }
                                                  onChange={(e) => {
                                                    if (
                                                      targ_aud_status === true
                                                    ) {
                                                      showTarget_status();
                                                    } else {
                                                      setSearchFocused(false);
                                                      field.onChange(e);
                                                      setAll_data(
                                                        (prevData) => {
                                                          return prevData.map(
                                                            (item) => {
                                                              if (
                                                                item.qualification_id ===
                                                                optionItem.qualification_id
                                                              ) {
                                                                return {
                                                                  ...item,
                                                                  filteredAnswers:
                                                                    e,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          );
                                                        }
                                                      );
                                                      setCommonState(e);

                                                      setSingle_Qualif({
                                                        q_id: optionItem.q_id,
                                                        quali_id:
                                                          optionItem.qualification_id,
                                                      });
                                                    }
                                                  }}
                                                  isMulti={true}
                                                  isClearable={false}
                                                  isSearchable={false}
                                                  allowSelectAll={true}
                                                  onBlur={() => {
                                                    if (!searchFocused) {
                                                      handleformSubmission(
                                                        optionItem.q_name,
                                                        optionItem.qualification_id,
                                                        optionItem.q_id,
                                                        "add",
                                                        ""
                                                      );
                                                    }
                                                  }}
                                                  onFocus={() =>
                                                    setSearchFocused(true)
                                                  }
                                                  // onBlur={() =>
                                                  //   setSearchFocused(false)
                                                  // }
                                                />
                                              </div>
                                            )}
                                          />
                                        )}
                                        {/* Render other components as needed */}

                                        <div className="addEditDelete_wrap flex mt-1">
                                          {optionItem.is_allocated === true &&
                                          is_qualify_launch === false ? (
                                            <label
                                              className="formlabel pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  showEditquotapopup(true);
                                                  setSelected_Quali_id(
                                                    optionItem.qualification_id
                                                  );
                                                  setSingle_Qualif({
                                                    q_id: optionItem.q_id,
                                                    quali_id:
                                                      optionItem.qualification_id,
                                                  });

                                                  setAddorEditName(
                                                    optionItem.q_name
                                                  );
                                                }
                                              }}
                                            >
                                              Edit Quotas
                                            </label>
                                          ) : optionItem.is_allocated ===
                                              false &&
                                            is_qualify_launch === false &&
                                            all_data[optionIndex]
                                              .filteredAnswers.length !== 0 ? (
                                            <label
                                              className="formlabel pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  setAddQuotavalues(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      [optionItem.qualification_id]:
                                                        optionItem.filteredAnswers,
                                                    })
                                                  );
                                                  setSelected_Quali_id(
                                                    optionItem.qualification_id
                                                  );
                                                  setSingle_Qualif({
                                                    q_id: optionItem.q_id,
                                                    quali_id:
                                                      optionItem.qualification_id,
                                                  });
                                                  showAddquotapopup(true);

                                                  setAddorEditName(
                                                    optionItem.q_name
                                                  );
                                                }
                                              }}
                                            >
                                              Add Quotas
                                            </label>
                                          ) : (
                                            <></>
                                          )}
                                          {optionItem.is_allocated === true &&
                                          is_qualify_launch === false ? (
                                            <label
                                              className="formlabel flex justify-end pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  confirmAlert({
                                                    closeOnEscape: false,
                                                    closeOnClickOutside: false,
                                                    message:
                                                      "Do you want to delete existing quota ",

                                                    buttons: [
                                                      {
                                                        label: "Yes",
                                                        onClick: () =>
                                                          handleDeletemulti(
                                                            optionItem.qualification_id,
                                                            optionItem.q_id
                                                          ),
                                                      },
                                                      {
                                                        label: "No",
                                                      },
                                                    ],
                                                  });
                                                }
                                              }}
                                            >
                                              Delete Quotas
                                            </label>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Col>
                                    )
                                )}
                              </Row>
                            </Col>
                          </Row>

                          {zipCodeData.length !== 0 ? (
                            <>
                              <Col
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className="col fileUpload flex items-center justify-end mt-4 mb-2 row-reverse"
                              >
                                <div>
                                  <label
                                    className="custom-file-upload btnWidth_set m-0 border-0"
                                    onClick={() => {
                                      if (targ_aud_status === true) {
                                        showTarget_status();
                                      } else {
                                        setUpZipState(true);
                                      }
                                    }}
                                  >
                                    Upload Zipcode
                                  </label>
                                </div>
                              </Col>
                              {zipCodeData[0].ansCode.length !== 0 ||
                              zipCodeData[0].is_all_selected === true ? (
                                <>
                                  <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="flex items-center justify-end mb-4"
                                  >
                                    <a
                                      className="pointer zipcodebtn pe-0"
                                      onClick={() => {
                                        if (targ_aud_status === true) {
                                          showTarget_status();
                                        } else {
                                          setZipPopUp(true);
                                        }
                                      }}
                                    >
                                      VIEW YOUR ZIP CODES
                                    </a>
                                  </Col>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="border-top-container m-0 border-0 clear-both">
                            <Row className="row-gap">
                              {all_data.map((item, index) => (
                                <>
                                  {item.is_default_qualify === false && (
                                    <Col
                                      lg={3}
                                      md={3}
                                      sm={6}
                                      xs={12}
                                      className="col"
                                      key={index}
                                    >
                                      <div className="input-container">
                                        <input
                                          type="text"
                                          placeholder={item.q_name}
                                          className={`addDefinition mb-0`}
                                          onClick={(e) => {
                                            if (targ_aud_status === true) {
                                              showTarget_status();
                                            } else {
                                              if (item.is_allocated === true) {
                                                confirmAlert({
                                                  closeOnEscape: false,
                                                  closeOnClickOutside: false,
                                                  message:
                                                    "Do you want to delete existing quota ",

                                                  buttons: [
                                                    {
                                                      label: "Yes",
                                                      onClick: () =>
                                                        handleDelete_definationQuota(
                                                          item.qualification_id,
                                                          item.q_id,
                                                          index
                                                        ),
                                                    },
                                                    {
                                                      label: "No",
                                                    },
                                                  ],
                                                });
                                              } else {
                                                setSelected_Quali_id(
                                                  item.qualification_id
                                                );
                                                setIsOpen(true);
                                              }
                                            }
                                          }}
                                          autoComplete="Off"
                                          readOnly
                                        />
                                        <AiFillDelete
                                          title="Delete"
                                          className="delete-icon"
                                          onClick={() => {
                                            if (targ_aud_status === true) {
                                              showTarget_status();
                                            } else {
                                              confirmAlert({
                                                closeOnEscape: false,
                                                closeOnClickOutside: false,
                                                message:
                                                  "Do you want to delete existing Qualification ",

                                                buttons: [
                                                  {
                                                    label: "Yes",
                                                    onClick: () =>
                                                      handleDelete(
                                                        item.qualification_id,
                                                        item.q_id,
                                                        index
                                                      ),
                                                  },
                                                  {
                                                    label: "No",
                                                  },
                                                ],
                                              });
                                            }

                                            // deleteAdddefinition(index, indexitem);
                                          }}
                                        />
                                      </div>
                                      <div className="addEditDelete_wrap flex mt-1 ">
                                        {item.is_allocated === true &&
                                        is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  showEditquotapopup(true);
                                                  setSelected_Quali_id(
                                                    item.qualification_id
                                                  );
                                                  setSingle_Qualif({
                                                    q_id: item.q_id,
                                                    quali_id:
                                                      item.qualification_id,
                                                  });

                                                  setAddorEditName(item.q_name);
                                                }
                                              }}
                                            >
                                              Edit Quotas
                                            </label>
                                          </>
                                        ) : item.is_allocated === false &&
                                          is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  setAddQuotavalues(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      [item.qualification_id]:
                                                        item.filteredAnswers,
                                                    })
                                                  );
                                                  setSelected_Quali_id(
                                                    item.qualification_id
                                                  );
                                                  setSingle_Qualif({
                                                    q_id: item.q_id,
                                                    quali_id:
                                                      item.qualification_id,
                                                  });
                                                  showAddquotapopup(true);

                                                  setAddorEditName(item.q_name);
                                                }
                                              }}
                                            >
                                              Add Quotas
                                            </label>
                                          </>
                                        ) : (
                                          <></>
                                        )}

                                        {item.is_allocated === true &&
                                        is_qualify_launch === false ? (
                                          <>
                                            <label
                                              className="formlabel flex justify-end pointer"
                                              onClick={() => {
                                                if (targ_aud_status === true) {
                                                  showTarget_status();
                                                } else {
                                                  confirmAlert({
                                                    closeOnEscape: false,
                                                    closeOnClickOutside: false,
                                                    message:
                                                      "Do you want to delete existing Quota ",

                                                    buttons: [
                                                      {
                                                        label: "Yes",
                                                        onClick: () =>
                                                          handleDelete_definationQuota(
                                                            item.qualification_id,
                                                            item.q_id,
                                                            index
                                                          ),
                                                      },
                                                      {
                                                        label: "No",
                                                      },
                                                    ],
                                                  });
                                                }
                                              }}
                                            >
                                              Delete Quota
                                            </label>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </Col>
                                  )}
                                </>
                              ))}
                              {/* Render empty input boxes */}
                              {[...Array(emptyCount)].map((_, index) => (
                                <Col
                                  lg={3}
                                  md={3}
                                  sm={6}
                                  xs={12}
                                  className="col"
                                  key={index + all_data.length} // Add definition.length to ensure unique keys
                                >
                                  <div className="input-container">
                                    <input
                                      className={`addDefinition ${
                                        emptyCount > 1 && index !== 0
                                          ? "disabled"
                                          : ""
                                      } `}
                                      type="text"
                                      placeholder="+ Add Definition +"
                                      onClick={(e) => {
                                        if (targ_aud_status === true) {
                                          showTarget_status();
                                        } else {
                                          setSelected_Quali_id("");
                                          setIsOpen(true);
                                        }
                                      }}
                                      autoComplete="Off"
                                      readOnly
                                      disabled={emptyCount > 1 && index !== 0}
                                    />
                                  </div>
                                </Col>
                              ))}
                              {is_qualify_launch === false ? (
                                <>
                                  <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="col fileUpload my-2"
                                  >
                                    <a
                                      className="pointer zipcodebtn pe-0"
                                      onClick={(e) => {
                                        if (targ_aud_status === true) {
                                          showTarget_status();
                                        } else {
                                          e.stopPropagation();
                                          showNestedquotapopup(true);
                                        }
                                      }}
                                    >
                                      Quota Nesting
                                    </a>
                                  </Col>
                                </>
                              ) : (
                                <></>
                              )}
                            </Row>
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </>
              )}
              {survey_ID !== undefined ? (
                <Quota
                  sid={survey_ID}
                  qdata={all_data}
                  age={ageQual_data}
                  zip={zipCodeData}
                  activedata={activeQualData}
                  tstatus={targ_aud_status}
                  allQuestions={allQuestions}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <SpinnerLoader />
          )}
        </div>
      </div>
    </>
  );
};

export default TargetDetailTest;
