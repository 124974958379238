import Modal from "react-bootstrap/Modal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
// import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
// import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
// import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../../assets/LoginIcons/users.svg";

import { useForm } from "react-hook-form";
// import ".././ExclusionSetting.css";
import "../../../SupplyModule/SupplierSetting/ExclusionSetting.css";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import SpinnerLoader from "../../../UI/Spinner";
const SupplyInfoSetting = (props) => {
  const navigate = useNavigate();
  const pname = "Exclusion Setting";
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [irStatus, setIrStatus] = useState(false);
  const [loiStatus, setLoiStatus] = useState(false);
  const [conversionStatus, setConversionStatus] = useState(false);
  const [cpiStatus, setCpiStatus] = useState(false);
  const [loder, setLoder] = useState(true);
  const [exclusion_data, setExclusion_Data] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { id, survey_Id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [formData, setFormData] = useState({
    formStatus: "",
    Irstatus: "",
    min_ir: "",
    repeated_ir_hits: "",
    Loistatus: "",
    max_loi: "",
    repeated_loi_hits: "",
    Cpistatus: "",
    min_cpi: "",

    Conversionstatus: "",
    repeated_conversion_hits: "",
    conversion: "",
  });
  const notifysucces = () => toast.success("Updated successfully");
  // Function to filter data based on ID

  useEffect(() => {
    if (props.supplier_id !== "" && props.supplier_id !== undefined)
      get_SupplierChildData();
  }, [props.supplier_id]);

  const get_SupplierChildData = async () => {
    setLoder(true);

    try {
      const response = await axiosPrivate.get(
        `survey-supplier/get-supplier-list-survey?survey_id=${props.supplier_id}`
      );
      const filteredData = response.data.survey_supplier.survey_supplier.filter(
        (obj) => obj._id === props.childId
      );
      if (filteredData[0].exclusion_settings !== null) {
        setExclusion_Data(filteredData);
        setFormData((prevData) => ({
          ...prevData,

          min_ir: filteredData[0].exclusion_settings.ir.min_ir,
          repeated_ir_hits:
            filteredData[0].exclusion_settings.ir.repeated_ir_hits,
          min_hits_ir: filteredData[0].exclusion_settings.ir.min_hits,
          max_loi: filteredData[0].exclusion_settings.loi.max_loi,
          repeated_loi_hits:
            filteredData[0].exclusion_settings.loi.repeated_loi_hits,
          min_hits_loi: filteredData[0].exclusion_settings.loi.min_hits,

          min_cpi: filteredData[0].exclusion_settings.cpi.min_cpi,

          repeated_conversion_hits:
            filteredData[0].exclusion_settings.conversion
              .repeated_conversion_hits,
          conversion: filteredData[0].exclusion_settings.conversion.conversion,
          min_hits_conversion:
            filteredData[0].exclusion_settings.conversion.min_hits,
        }));
      } else {
        setLoder(false);
      }

      if (filteredData[0].exclusion_settings.ir.status === 1) {
        setIrStatus(true);
      } else {
        setIrStatus(false);
      }
      if (filteredData[0].exclusion_settings.loi.status === 1) {
        setLoiStatus(true);
      } else {
        setLoiStatus(false);
      }
      if (filteredData[0].exclusion_settings.cpi.status === 1) {
        setCpiStatus(true);
      } else {
        setCpiStatus(false);
      }
      if (filteredData[0].exclusion_settings.conversion.status === 1) {
        setConversionStatus(true);
      } else {
        setConversionStatus(false);
      }
    } catch (err) {
      console.error(err);
      // notifyerror(err.response.status);
      // setSupplier_childData([]);
      // setPriorityData([]);
    }
    setLoder(false);
  };
  // Example usage:

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        // /api/v1/survey-supplier/exclusion-settings/{survey_supplier_id}
        .patch(
          `/survey-supplier/exclusion-settings/${props.childId}`,
          JSON.stringify({
            ir: {
              status: irStatus,
              min_ir: parseInt(e.MinIr),

              repeated_ir_hits: parseInt(e.RepeatedIrHits),
              min_hits: parseInt(e.Min_hits_Ir),
            },
            loi: {
              status: loiStatus,
              max_loi: parseInt(e.MaxLoi),
              repeated_loi_hits: parseInt(e.RepeatedLoiHits),
              min_hits: parseInt(e.Min_hits_Loi),
            },
            cpi: {
              status: cpiStatus,
              min_cpi: parseInt(e.MinCpi),
            },
            conversion: {
              status: conversionStatus,
              repeated_conversion_hits: parseInt(e.RepeatedConversionHits),
              conversion: parseInt(e.Conversion),
              min_hits: parseInt(e.min_hits_conversion),
            },
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
            props.setSettingPage(false);
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs16">
            Exclusion Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
          <form onSubmit={handleSubmit(postData)}>
            {loder === false ? (
              <>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <>
                    <div className="settingHeading">
                      <p className="pBlockHead mb-0">Conversion</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("min_hits_conversion", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_conversion}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.min_hits_conversion && (
                                  <small className="supplier_setting_error">
                                    {errors.min_hits_conversion.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Conversion*</label>
                            <input
                              {...register("Conversion", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Conversion"
                              className="CsformText supplier_setting"
                              defaultValue={formData.conversion}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.Conversion && (
                                  <small className="supplier_setting_error">
                                    {errors.Conversion.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Conversion Hits*
                            </label>
                            <input
                              {...register("RepeatedConversionHits", {
                                required: conversionStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder=" Repeated Conversion Hit"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_conversion_hits}
                            />
                            {conversionStatus === true ? (
                              <>
                                {errors.RepeatedConversionHits && (
                                  <small className="supplier_setting_error">
                                    {errors.RepeatedConversionHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setConversionStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={conversionStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="settingHeading">
                      <p className="pBlockHead mb-0">IR</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("Min_hits_Ir", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_ir}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.Min_hits_Ir && (
                                  <small className="supplier_setting_error">
                                    {errors.Min_hits_Ir.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Ir*</label>
                            <input
                              {...register("MinIr", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Ir"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_ir}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.MinIr && (
                                  <small className="supplier_setting_error">
                                    {errors.MinIr.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Ir Hits*
                            </label>
                            <input
                              {...register("RepeatedIrHits", {
                                required: irStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Repeated Ir Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_ir_hits}
                            />
                            {irStatus === true ? (
                              <>
                                {errors.RepeatedIrHits && (
                                  <small className="supplier_setting_error">
                                    {errors.RepeatedIrHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setIrStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={irStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="settingHeading">
                      <p className="pBlockHead mb-0">LOI</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Hits*</label>
                            <input
                              {...register("Min_hits_Loi", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_hits_loi}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.Min_hits_Loi && (
                                  <small className="supplier_setting_error">
                                    {errors.Min_hits_Loi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Max Loi*</label>
                            <input
                              {...register("MaxLoi", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Max Loi"
                              className="CsformText supplier_setting"
                              defaultValue={formData.max_loi}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.MaxLoi && (
                                  <small className="supplier_setting_error">
                                    {errors.MaxLoi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">
                              Repeated Loi Hits*
                            </label>
                            <input
                              {...register("RepeatedLoiHits", {
                                required: loiStatus
                                  ? "This field is Required"
                                  : false,
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Repeated Loi Hits"
                              className="CsformText supplier_setting"
                              defaultValue={formData.repeated_loi_hits}
                            />
                            {loiStatus === true ? (
                              <>
                                {errors.RepeatedLoiHits && (
                                  <small className="supplier_setting_error">
                                    {errors.RepeatedLoiHits.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setLoiStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={loiStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div className="settingHeading">
                      <p className="pBlockHead mb-0">Supplier cost</p>
                    </div>
                    <div style={{ border: "1px solid whiteSmoke" }}>
                      <div className="rowContant">
                        <Row>
                          <Col lg={3} md={3} xs={12} className="col">
                            <label className="formlabel">Min Cost*</label>
                            <input
                              {...register("MinCpi", {
                                required: cpiStatus
                                  ? "This field is Required"
                                  : false,

                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Only numbers are allowed",
                                },
                              })}
                              type="text"
                              placeholder="Min Cpi"
                              className="CsformText supplier_setting"
                              defaultValue={formData.min_cpi}
                            />
                            {cpiStatus === true ? (
                              <>
                                {errors.MinCpi && (
                                  <small className="supplier_setting_error">
                                    {errors.MinCpi.message}
                                  </small>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>

                          <Col
                            lg={3}
                            md={3}
                            xs={12}
                            className="col ssbtn sscolorbtn"
                          >
                            <label className="formlabel">Status*</label>
                            <BootstrapSwitchButton
                              className="switch btn on btn-outline-success btn-xs"
                              onChange={(e) => {
                                setCpiStatus(e);
                              }}
                              size="xs"
                              width={80}
                              onlabel="Active"
                              offlabel="Inactive"
                              onstyle="outline-success"
                              offstyle="outline-danger"
                              checked={cpiStatus}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div className="user_serachbox">
                      <div className="right-pane">
                        <button
                          className="cbtn closeBtn"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.setSettingPage(false);
                          }}
                        >
                          Close
                        </button>

                        <button
                          disabled={isSubmitting}
                          className="cbtn saveBtn"
                        >
                          {isSubmitting && (
                            <Spinner
                              style={{ color: "white" }}
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          &nbsp;&nbsp; Save
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SupplyInfoSetting;
