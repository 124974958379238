import React, { useEffect, useState } from "react";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import BasicMenu from "./BaiscMenu";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const notifications = [
  {
    id: 0,
    label: "First notification",
  },
  //   {
  //     id: 1,
  //     label: "Second notification",
  //   },
];

const NotificationBell = () => {
  const accesstoken = "Bearer " + localStorage.getItem("atoken");
  const [open, setOpen] = React.useState(false);
  const [anchorel, setAnchorEl] = React.useState(null);
  const [getNotificationCount, setGetNotificationCount] = useState([]);
  const [getNotificationData, setGetNotificationData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  // const [notifyTypeOne, setNotifyTypeOne] = useState([]);
  // const [notifyTypeTwo, setNotifyTypeTwo] = useState({});
  // const [notifyTypeThree, setNotifyTypeThree] = useState({});
  // const [notifyTypeFour, setNotifyTypeFour] = useState({});

  const newNotifications = `You have ${notifications.length} new notifications!`;
  const noNotifications = "No new notifications";

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getNotificationCountApi();
    getNotificationDataApi();
  }, [NotificationBell]);

  const getNotificationCountApi = async () => {
    try {
      const response = await axiosPrivate.get(`notification/get-notification`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setGetNotificationCount(response.data.notification);
    } catch (error) {}
  };

  const getNotificationDataApi = async () => {
    try {
      const response = await axiosPrivate.get(
        `notification/get-notification?notify_id=1`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setGetNotificationData(response.data.notification);
    } catch (error) {}
  };

  return (
    <div>
      {/* <Tooltip
        title={notifications.length ? newNotifications : noNotifications}
      > */}
      <IconButton
        style={{ color: "#0d72ba" }}
        onClick={notifications.length ? handleOpen : null}
      >
        <Badge badgeContent={getNotificationCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {/* </Tooltip> */}
      <BasicMenu
        open={open}
        anchorEl={anchorel}
        handleClose={handleClose}
        menuItems={notifications}
        notificationData={getNotificationData}
      />
    </div>
  );
};

export default NotificationBell;
