import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef, useEffect } from 'react';
import addIcon from '../../../../assets/LoginIcons/add.svg';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { CheckCircle, Delete, ErrorRounded } from '@mui/icons-material';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import '../Invoice.css';
import { BiUpload, BiPurchaseTag } from 'react-icons/bi';
import SurveyBilling from './SurveyBilling';

const Uploadattachment = (props) => {
    const [test, setTest] = useState([]);
    const [message, setMessage] = useState([]);
    const [randomNum, setRandomNumber] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null);
    const [overwrite, setOverwrite] = useState(true);
    const [resId, setResId] = useState(true);

    const fileupload = async (file, onUploadProgress) => {
        let formData = new FormData();

        formData.append('attach_upload_file', file);

        setTest((value) => [...value, file]);
        await axiosPrivate
            .post(
                `/invoice/upload-files?survey_id=${props.survey_id}&type_file=email`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress,
                }
            )
            .then((e) => {
                props.setUploadAttachmentfile((prevFiles) => {
                    if (!prevFiles.includes(e.data.url)) {
                        return [...prevFiles, e.data.url];
                    }

                    return prevFiles;
                });
            });
    };

    useEffect(() => {
        let x = Math.floor(Math.random() * 100000000000 + 1);
        setRandomNumber(x);
    }, []);

    const upload = async (idx, file) => {
        let _progressInfos = [...progressInfosRef.current.val];
        return fileupload(file, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            setProgressInfos((vale) => ({ ...vale, val: _progressInfos }));
        })
            .then(() => {
                setMessage((prevMessage) => [...prevMessage, 'success']);
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                setProgressInfos({ val: _progressInfos });
                setMessage((prevMessage) => [...prevMessage, 'error']);
            });
    };

    const uploadFiles = async (event) => {
        const files = Array.from(event.target.files);
        let merged = files.concat(test);
        merged = merged.filter((item, index) => {
            return merged.indexOf(item) === index;
        });

        let _progressInfos = merged.map((file) => ({
            percentage: 0,
            fileName: file.name,
        }));
        progressInfosRef.current = {
            val: _progressInfos,
        };

        const uploadPromises = merged.map((file, i) => upload(i, file));

        setMessage([]);
    };

    const deleteHandler = async (i, e) => {
        const del = progressInfos.val.filter((User, index) => i !== index);
        const el = progressInfos.val.filter((User, index) => i === index);

        setProgressInfos({ val: del });
        const bar = test.filter((User, index) => i !== index);
        setTest(bar);
    };
    return (
        <>
            <div className="attachmentContainer">
                {progressInfos &&
                    progressInfos.val.length > 0 &&
                    progressInfos.val.map((progressInfo, index) => (
                        <div key={index} className="Invoice_attachmentItem">
                            <div className="attachmentContent">
                                <span className="invoice_fileInfo">
                                    {progressInfo.fileName}
                                </span>
                                {message[index] === 'success' ? (
                                    <CheckCircle className="success" />
                                ) : (
                                    <ErrorRounded className="error" />
                                )}

                                <div className="progress">
                                    <div
                                        className="progress-bar progress-bar-default"
                                        role="progressbar"
                                        aria-valuenow={progressInfo.percentage}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                            width:
                                                progressInfo.percentage + '%',
                                        }}
                                    >
                                        {progressInfo.percentage}%
                                    </div>
                                </div>
                                <Delete
                                    className="deleteAttachment"
                                    title="Remove Attachment"
                                    onClick={(e) => deleteHandler(index, e)}
                                />
                            </div>
                        </div>
                    ))}
            </div>

            <label
                className="cbtn addBtn mr10 mb-2"
                style={{
                    cursor: 'pointer',
                    float: 'left',
                }}
            >
                <img src={addIcon} className="headingIcons" alt="" /> Attachment
                <input
                    type="file"
                    multiple
                    onChange={uploadFiles}
                    className="attachInput"
                />
            </label>
        </>
    );
};

export default Uploadattachment;
