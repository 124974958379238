import "./ProjectDetail/ProjectDetail.css";
import "bootstrap/dist/css/bootstrap.css";
import Filter from "./CommonFiles/Filter/Filter";
import ProjectDetail from "./ProjectDetail/ProjectDetail";
import Screening from "./Screening/Screening";
import Tracker from "./Tracker/Tracker";
import SupplyInfo from "./SupplyInfo/PopUp/Supplyinfo";
import Invoicing from "./Invoice/Invoicing";
import Qualityproject from "./Quality_project/Qualityproject";
import { useState, useEffect } from "react";
import searchIcon from "../../assets/images/icons8-search-60.png";
import Supliercostinfo from "./Invoice/Suppliercostinfo";
import { useParams } from "react-router";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import TargetDetailTest from "./Screening/Target_Audience/TargetdetailTest";
import { useNavigate } from "react-router-dom";
import { BiLink } from "react-icons/bi";
import LinksModal from "./SurveyListing/LinksModal";
const CommonLayout = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();
  const { survey_Id } = useParams();
  const { layoutId } = useParams();
  const [links, setLinks] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState();
  const [headerId, setHeaderId] = useState(layoutId);
  const [showScreening, setShowScreening] = useState(false);
  const [idFromFilter, setIdFromFilter] = useState("");
  const [project_name, setProjectName] = useState();
  const [stausName, setStatusName] = useState("");
  const [surveyId, setSurveyId] = useState("");
  const [selectedelem, setSelectedElem] = useState("");
  const [reRender, setReRender] = useState(false);
  const [hideTab, setHideTab] = useState(true);
  const [flag, setFlag] = useState(false);

  const navigate = useNavigate();
  const setShowSubHeader = (idNo) => {};

  useEffect(() => {
    setHeaderId(layoutId);
  }, [layoutId]);

  useEffect(() => {
    navigate(`/project-detail/${id}/${headerId}/${survey_Id}`);
  }, [headerId]);

  useEffect(() => {
    if (reRender === true) {
      get_ProjectData_Params();
    }
  }, [reRender]);

  const handleDataFromFilter = async (data) => {
    setIdFromFilter(data);
    try {
      const response = await axiosPrivate.get(`project/get-project/${data}`);
      setProjectName(response.data.project.project_name);
      setStatusName(response.data.project.project_status.name);
    } catch (err) {
      console.error(err);
    }
  };

  const hideFilter = () => {
    let element = document.getElementById("hideFilter");
    if (element.matches(".open")) {
      element.classList.remove("open");
    } else {
      element.classList.add("open");
    }
  };

  const [dataFromChild, setDataFromChild] = useState("");
  const handleChildFunction = () => {};

  useEffect(() => {
    get_ProjectData_Params();
  }, []);

  useEffect(() => {
    get_ProjectData_Params();
  }, [flag]);

  const get_ProjectData_Params = async () => {
    try {
      const response = await axiosPrivate.get(`project/get-project/${id}`);
      setProjectName(response.data.project.project_name);
      setStatusName(response.data.project.project_status.name);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {links ? (
        <LinksModal show={links} onHide={() => setLinks(false)} />
      ) : null}

      <div className="Ptop_container">
        <div className="PtopHeaderHide">
          <img
            src={searchIcon}
            className="csSearchIcon"
            onClick={() => {
              hideFilter();
            }}
          />
          <div className="left-section">
            <p>{project_name}</p>

            {project_name && (
              <>
                {stausName === "Live" && <button className="live">Live</button>}
                {stausName === "Pending" && (
                  <button className="pending">Pending</button>
                )}
                {stausName === "Test Mode" && (
                  <button className="testmode">Test Mode</button>
                )}
                {stausName === "Paused" && (
                  <button className="paused">Paused</button>
                )}
                {stausName === "Id Awaited" && (
                  <button className="IdAwaited">Id Awaited</button>
                )}
                {stausName === "Billed" && (
                  <button className="billed">Billed</button>
                )}
                {stausName === "Cancelled" && (
                  <button className="cancelled">Cancelled</button>
                )}
              </>
            )}
          </div>

          <div className="ClientLinkIcon">
            <BiLink
              title="Client Link"
              className="ProjectDetail_linkIcon"
              onClick={() => {
                setLinks(true);
              }}
            />
          </div>
        </div>
        <div className="PleftContainer" id="hideFilter">
          <Filter
            setReRender={setReRender}
            reRender={reRender}
            sendDataToParent={handleDataFromFilter}
            setSurveyId={setSurveyId}
            selectedelem={selectedelem}
          />
        </div>
        <div className="PrightContainer">
          {/* <div
            className="PtopHeader"
            style={{
              border: "1px solid red",
            }}
          >
            <p>{project_name}</p>

            {project_name ? (
              <>
                {stausName === "Live" ? (
                  <>
                    <button className="live">Live</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Pending" ? (
                  <>
                    <button className="pending">Pending</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Test Mode" ? (
                  <>
                    <button className="testmode">Test Mode</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Paused" ? (
                  <>
                    <button className="paused">Paused</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Id Awaited" ? (
                  <>
                    <button className="IdAwaited">Id Awaited</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Billed" ? (
                  <>
                    <button className="billed">Billed</button>
                  </>
                ) : (
                  <></>
                )}
                {stausName === "Cancelled" ? (
                  <>
                    <button className="cancelled">Cancelled</button>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
            <div className="ClientLinkIcon">
              <BiLink
                onClick={() => {
                  setLinks(true);
                }}
                style={{
                  width: "50px",
                }}
              />
            </div>
          </div> */}
          <div className="PtopHeader">
            <div className="left-section">
              <p>{project_name}</p>

              {project_name && (
                <>
                  {stausName === "Live" && (
                    <button className="live">Live</button>
                  )}
                  {stausName === "Pending" && (
                    <button className="pending">Pending</button>
                  )}
                  {stausName === "Test Mode" && (
                    <button className="testmode">Test Mode</button>
                  )}
                  {stausName === "Paused" && (
                    <button className="paused">Paused</button>
                  )}
                  {stausName === "Id Awaited" && (
                    <button className="IdAwaited">Id Awaited</button>
                  )}
                  {stausName === "Billed" && (
                    <button className="billed">Billed</button>
                  )}
                  {stausName === "Cancelled" && (
                    <button className="cancelled">Cancelled</button>
                  )}
                </>
              )}
            </div>

            <div className="ClientLinkIcon">
              <BiLink
                title="Client Link"
                className="ProjectDetail_linkIcon"
                onClick={() => {
                  setLinks(true);
                }}
              />
            </div>
          </div>

          <div className="showHeader">
            <select
              value={headerId}
              className="threeLine SelectHeader"
              id="MainMenuCs"
              onChange={(e) => {
                setHeaderId(parseInt(e.target.value));
              }}
            >
              <option value={1}>Project Details</option>
              <option value={2}>Screening</option>
              <option value={8 || "8C"}>Audience</option>
              <option value={3}>Tracker</option>
              <option value={4}>Supply Info</option>
              <option value={5}>Invoicing</option>
              <option value={6}> Quality</option>
              <option value={7}> Supplier Cost</option>
            </select>
            <div className="PrightHeader">
              {headerId != "8C" ? (
                <>
                  {" "}
                  <p
                    className={headerId == 1 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(1);
                    }}
                  >
                    {" "}
                    Project Details
                  </p>
                  <p
                    className={headerId == 2 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(2);
                    }}
                  >
                    {" "}
                    Screening
                  </p>
                  <p
                    className={
                      headerId == 8 || headerId == "8C" ? "activeBorder" : ""
                    }
                    onClick={() => {
                      setHeaderId(8);
                    }}
                  >
                    {" "}
                    Audience
                  </p>
                  <p
                    className={headerId == 3 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(3);
                      setShowScreening(false);
                    }}
                  >
                    {" "}
                    Tracker
                  </p>
                  <p
                    className={headerId == 4 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(4);
                      setShowScreening(false);
                    }}
                  >
                    {" "}
                    Supply Info
                  </p>
                  <p
                    className={headerId == 5 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(5);
                      setShowScreening(false);
                    }}
                  >
                    {" "}
                    Invoicing
                  </p>
                  <p
                    className={headerId == 6 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(6);
                      setShowScreening(false);
                    }}
                  >
                    {" "}
                    Quality
                  </p>
                  <p
                    className={headerId == 7 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(7);
                      setShowScreening(false);
                    }}
                  >
                    {" "}
                    Supplier Cost
                  </p>
                </>
              ) : (
                <>
                  {" "}
                  <p
                    className={headerId == 1 ? "activeBorder" : ""}
                    onClick={() => {
                      setHeaderId(1);
                    }}
                  >
                    Project Details
                  </p>
                  <p
                    className={
                      headerId == 8 || headerId == "8C" ? "activeBorder" : ""
                    }
                    onClick={() => {
                      setHeaderId(8);
                    }}
                  >
                    {" "}
                    Target Audience
                  </p>
                </>
              )}
            </div>
          </div>

          <div>
            {headerId == 5 ? (
              <Invoicing idFromFilter={idFromFilter} />
            ) : headerId == 2 ? (
              <Screening
                idFromFilter={idFromFilter}
                surveyId={surveyId}
                setSelectedElem={setSelectedElem}
              />
            ) : headerId == 8 || headerId == "8C" ? (
              <TargetDetailTest
                idFromFilter={idFromFilter}
                surveyId={surveyId}
                setSelectedElem={setSelectedElem}
              />
            ) : headerId == 3 ? (
              <Tracker idFromFilter={idFromFilter} />
            ) : headerId == 4 ? (
              <SupplyInfo idFromFilter={idFromFilter} />
            ) : headerId == 6 ? (
              <Qualityproject idFromFilter={idFromFilter} />
            ) : headerId == 7 ? (
              <Supliercostinfo idFromFilter={idFromFilter} />
            ) : (
              <>
                {flag === true || flag === false ? (
                  <ProjectDetail
                    setProjectName={setProjectName}
                    setReRender={setReRender}
                    setShowSubHeader={setShowSubHeader}
                    setSelectedHeader={setSelectedHeader}
                    setShowScreening={setShowScreening}
                    idFromFilter={idFromFilter}
                    callParentFunction={handleChildFunction}
                    setSurveyId={setSurveyId}
                    setSelectedElem={setSelectedElem}
                    setFlag={setFlag}
                    flag={flag}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CommonLayout;
