import '../../ProjectDetail/ProjectDetail.css';
import { TableCell, TableRow, TableBody } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Table from 'react-bootstrap/Table';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useParams } from 'react-router';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import SetupSupplier from './SetupSupplier';
import { useForm } from 'react-hook-form';
import { FiUserPlus } from 'react-icons/fi';
import EditSupplier from './EditSupplier';
import SupplyInfoSetting from './SupplyInfoSetting';
import EditIcon from '../../../../assets/LoginIcons/edit.svg';
import SpinnerLoader from '../../../UI/Spinner';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import PRStatusModal from './PRStatusModal';
import toast from 'react-hot-toast';
import { FiSettings } from 'react-icons/fi';
const SupplyInfoChild = (props) => {
    var supplier_id = props.supplyInfoChildId;
    var project_id = props.idFromFilter;
    const [modalShow, setModalShow] = useState(false);
    const [surveyMargin, setSurveyMargin] = useState([{}]);
    const [editsupplier, setEditSupplier] = useState(false);
    const [supplier_childData, setSupplier_childData] = useState([]);
    const [childId, setChildData] = useState();
    const [priorityData, setPriorityData] = useState(false);
    const [settingPage, setSettingPage] = useState(false);
    const [add_setup, setAdd_Setup] = useState(false);
    const [edit_setUp, setEdit_SetUp] = useState(false);
    const [diysupplier, setDiy_supplier] = useState(false);
    const {
        formState: { errors },
    } = useForm();
    const axiosPrivate = useAxiosPrivate();

    const [loder, setLoder] = useState(true);
    const [showaddsetup, setShowAddSetup] = useState(false);
    const [supplier_name, setSupplier_name] = useState('');

    useEffect(() => {
        if (supplier_id == undefined || supplier_id == '') {
        } else {
            setSupplier_childData([]);
            setPriorityData([]);
            get_SupplierChildData();
        }
    }, [supplier_id, edit_setUp]);

    useEffect(() => {
        get_SurveyMargin_api();
    }, []);

    useEffect(() => {
        get_SupplierChildData();
    }, [add_setup]);

    const get_SurveyMargin_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-survey-margin');
            response.data.all_status.map((e) => {
                setSurveyMargin((val) => [...val, { label: e.id, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            // notifyerror(err.response.status);
        }
    };

    const get_SupplierChildData = async () => {
        setLoder(true);
        try {
            const response = await axiosPrivate.get(`survey-supplier/get-supplier-list-survey?survey_id=${supplier_id}`);
            setSupplier_childData(response.data.survey_supplier.survey_supplier);

            setPriorityData(response.data.survey_supplier?.priority);
            if (response.data.survey_supplier.priority.priority == undefined) {
                setPriorityData(false);
            } else {
                setPriorityData(response.data.survey_supplier.priority);
            }
        } catch (err) {
            console.error(err);
            setSupplier_childData([]);
            setPriorityData([]);
        }
        setLoder(false);
    };

    const Subtable = () => {
        return (
            <div
                style={{
                    margin: '0px',
                    padding: '10px',
                }}
            >
                {modalShow ? <PRStatusModal show={modalShow} onHide={() => setModalShow(false)} prioritydata={priorityData} /> : <></>}
                <div className="supplyPartnerReq">
                    <div className="SArrowHeead">
                        <p className="supply_country">Partner Request Status</p>
                    </div>

                    <Table colSpan={3} style={{ margin: '0px' }}>
                        <>
                            {priorityData != false ? (
                                <>
                                    {' '}
                                    <thead className="">
                                        <TableRow>
                                            <TableCell className="supply_theadfont">Priorty</TableCell>
                                            <TableCell className="supply_theadfont">Req Status</TableCell>
                                            <TableCell className="supply_theadfont">Req Owner</TableCell>
                                            <TableCell className="supply_theadfont">Action</TableCell>
                                        </TableRow>
                                    </thead>
                                    <>
                                        <tbody>
                                            <TableRow className="icon-style">
                                                <TableCell className="supply_theadfont">
                                                    {priorityData.priority !== null ? (
                                                        <>
                                                            <span className="stanBadge">{priorityData.priority.name}</span>
                                                        </>
                                                    ) : (
                                                        <>-</>
                                                    )}
                                                </TableCell>
                                                <TableCell className="supply_theadfont">{priorityData.request_status !== null ? <>{priorityData.request_status.name}</> : <>-</>}</TableCell>
                                                <TableCell className="supply_theadfont">{priorityData.request_owner !== null ? <>{priorityData.request_owner.name}</> : <>-</>}</TableCell>
                                                <TableCell className="supply_theadfont">
                                                    <a onClick={() => setModalShow(true)}>
                                                        <MdOutlineRemoveRedEye title="Partner Request Status" />
                                                    </a>
                                                </TableCell>
                                            </TableRow>
                                        </tbody>
                                    </>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    </Table>
                </div>
            </div>
        );
    };

    var data = false;

    supplier_childData.map((e) => {
        if (e.supplier.supplier_type.id === 2) {
            data = true;
        }
    });

    return (
        <>
            {editsupplier ? (
                <>
                    <EditSupplier show={editsupplier} onHide={() => setEditSupplier(false)} setEditSupplier={setEditSupplier} setEdit_SetUp={setEdit_SetUp} childId={childId} />
                </>
            ) : (
                <></>
            )}
            {settingPage ? (
                <>
                    <SupplyInfoSetting childId={childId} show={settingPage} setSettingPage={setSettingPage} onHide={() => setSettingPage(false)} supplier_childData={supplier_childData} supplier_id={supplier_id} />
                </>
            ) : (
                <></>
            )}
            {showaddsetup ? (
                <>
                    <SetupSupplier childId={childId} show={showaddsetup} onHide={() => setShowAddSetup(false)} setShowAddSetup={setShowAddSetup} setAdd_Setup={setAdd_Setup} supplier_name={supplier_name} setDiy_supplier={setDiy_supplier} />
                </>
            ) : (
                <></>
            )}

            {loder ? (
                <>
                    <SpinnerLoader />
                </>
            ) : (
                <>
                    {supplier_childData.length == 0 ? (
                        <>
                            <div className="alert alert-danger" style={{ textAlign: 'center', margin: '15px' }} role="alert">
                                No data Found!
                            </div>
                        </>
                    ) : (
                        <>
                            <Table colSpan={6} style={{ margin: '0px' }}>
                                <thead>
                                    <TableRow>
                                        <TableCell className="supply_theadfont">Supplier Name</TableCell>
                                        <TableCell className="supply_theadfont">Status</TableCell>
                                        <TableCell className="supply_theadfont">Quota</TableCell>
                                        <TableCell className="supply_theadfont">Supplier CPI</TableCell>
                                        <TableCell className="supply_theadfont">Supplier Type</TableCell>
                                        <TableCell className="supply_theadfont">Action</TableCell>
                                    </TableRow>
                                </thead>

                                <>
                                    <TableBody>
                                        {supplier_childData.map((e) => (
                                            <>
                                                <TableRow>
                                                    <TableCell
                                                        style={{
                                                            padding: '0px',
                                                        }}
                                                        className="supplyinfo_text"
                                                    >
                                                        <FiberManualRecordIcon
                                                            style={{
                                                                color: 'green',
                                                                height: '10px',
                                                            }}
                                                        />
                                                        {e.supplier.name}
                                                    </TableCell>
                                                    {e.status == 1 ? (
                                                        <>
                                                            {' '}
                                                            <TableCell className="supplyinfo_text">
                                                                <span className="active-badge">Active</span>
                                                            </TableCell>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            <TableCell className="supplyinfo_text">
                                                                <span className="inactive-badge">In-Active</span>
                                                            </TableCell>
                                                        </>
                                                    )}

                                                    <TableCell className="supplyinfo_text">
                                                        {e.completes} | {e.n}
                                                    </TableCell>
                                                    <TableCell className="supplyinfo_text">${e.supplier_cost}</TableCell>

                                                    {e.supplier.supplier_type ? (
                                                        <>
                                                            <TableCell className="supplyinfo_text">{e.supplier.supplier_type.name}</TableCell>
                                                        </>
                                                    ) : (
                                                        <TableCell className="supplyinfo_text">-</TableCell>
                                                    )}

                                                    <TableCell>
                                                        {e.is_supplier_setup == 0 ? (
                                                            <>
                                                                {' '}
                                                                <FiUserPlus
                                                                    title="Setup Supplier"
                                                                    onClick={() => {
                                                                        setShowAddSetup(true);
                                                                        setChildData(e._id);
                                                                        setAdd_Setup(false);
                                                                        setSupplier_name(e.supplier.name);
                                                                    }}
                                                                    style={{
                                                                        marginRight: '5px',
                                                                        marginBottom: '5px',
                                                                    }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {' '}
                                                                <img
                                                                    title="Edit Supplier"
                                                                    src={EditIcon}
                                                                    alt="EditIcon"
                                                                    srcset=""
                                                                    className="SupplyIcon"
                                                                    onClick={() => {
                                                                        setEditSupplier(true);
                                                                        setChildData(e._id);
                                                                        setEdit_SetUp(false);
                                                                    }}
                                                                />
                                                            </>
                                                        )}
                                                        {e.supplier.supplier_type.id == 3 ? (
                                                            <>
                                                                {' '}
                                                                <FiSettings
                                                                    title="Request S/O"
                                                                    className="SupIcon sdIcon"
                                                                    onClick={() => {
                                                                        setChildData(e._id);
                                                                        setSettingPage(true);
                                                                    }}
                                                                />
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </>
                            </Table>

                            {priorityData == false ? (
                                <></>
                            ) : (
                                <>
                                    {data ? (
                                        <>
                                            {' '}
                                            <Subtable />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default SupplyInfoChild;
