import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./recaptcha.css";
import { useParams } from "react-router";
import axios from "axios";
import { axiosPrivate } from "../../../api/axios";
import Common from "../Common.jsx";
import { Container } from "react-bootstrap";

import "../Common.css";

function Recaptcha() {
  const params = useParams();
  const id = params.id;

  // dummy site key
  // const sitekey = "6Lcc-2goAAAAAP1ig7aVB1sXQdrKYAxpUAl99pQf";

  // Dev site key
  const devSiteKey = "6Le7pAQqAAAAANyaRz-7ZoxICqlYudrwSPnP_f1f";

  const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [respid, setRespid] = useState(id);
  const [captcha_status, setCaptcha_status] = useState(false);
  const [captcha_url, setCaptcha_url] = useState();

  function recaptchaOnChange(value) {
    if (value !== "") {
      setRecaptchaVerified(true);
      setCaptcha_status(true);
    } else {
      setRecaptchaVerified(false);
      setCaptcha_status(false);
    }
  }

  useEffect(() => {
    getCaptch_url();
  }, []);

  const getCaptch_url = async () => {
    await axiosPrivate
      .get(`/get-captcha-url`)
      .then((response) => {
        // Handle success
        setCaptcha_url(response.data);
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <Common />
      <div className="main qual_frontend">
        <Container>
          <div className="recaptcha_card">
            <h2>Checking if the site connection is secure</h2>
            <ReCAPTCHA
              sitekey={devSiteKey}
              onChange={recaptchaOnChange}
              className="recaptchablock"
            />

            <form action={captcha_url} method="POST">
              <button
                type="submit"
                disabled={!recaptchaVerified || !captcha_status}
                className="btn sbmtbtn"
              >
                Next
              </button>
              <input type="hidden" name="respid" value={respid} />
              <input
                type="hidden"
                name="captcha_status"
                value={captcha_status ? "true" : "false"}
              />
            </form>

            <p className="m-0">
              Samplicity needs to review the security of your connection before
              proceeding.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Recaptcha;
