import Header from '../../UI/Header/Header';
import DataTable from 'react-data-table-component';
import './StyleDatatable.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import addIcon from '../../../assets/LoginIcons/add.svg';
import userIco from '../../../assets/LoginIcons/users.svg';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import EditIcon from '../../../assets/LoginIcons/edit.svg';
import DeleteIcon from '../../../assets/LoginIcons/delete.svg';
import KeyIcon from '../../../assets/LoginIcons/key.svg';
import { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import UserIco from '../../../assets/userprofile/icons8-user-30.png';
import SpinnerLoader from '../../UI/Spinner';
import InsufficientPermisiion from '../User/InsufficiantPermission';
import toast from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';

const UserListing = () => {
    const [User, setUser] = useState([]);
    const [filterUser, setFilterUser] = useState(null);
    const [search, setSearch] = useState('');
    const [pending, setPending] = useState(true);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [permission, setPermission] = useState('');
    const [dataLoader, setDataLoader] = useState(true);
    const notifyActive = () => toast.success('User is active');
    const notifyNotActive = () => toast.success('User is inactive');

    const { auth } = useAuth();
    const superUser = auth.superuser;
    const all_permission = auth.permission;

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setDataLoader(true);
        let isMounted = true;
        try {
            const response = await axiosPrivate.get('/user/all-users', {});
            isMounted && setUser(response.data.all_users);

            setPending(false);
        } catch (err) {
            if (
                err.response.data.return_status.message[0] ==
                'Insufficient permissions'
            ) {
                setPermission('Insufficient permissions');
            }
        }
        setDataLoader(false);
        return () => {
            isMounted = false;
        };
    };

    useEffect(() => {
        const item = localStorage.getItem('UserCreated');
        const userSaved = localStorage.getItem('userUpdated');
        const notifysuccess = (e) => toast.success(e);
        if (item === '1') {
            notifysuccess('User created successfully');
            localStorage.setItem('UserCreated', 0);
        } else if (userSaved === '1') {
            notifysuccess('User updated successfully');
            localStorage.setItem('userUpdated', 0);
        }
    });
    const userDeleted = () => toast.success('User deleted successfully');

    const handleDelete = async (id) => {
        const payload = {
            user_id: id,
        };

        try {
            await axiosPrivate.delete(
                '/user/delete',
                { data: payload },
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );
            userDeleted();
        } catch (err) {}
        const del = User.filter((User) => id !== User._id);
        setUser(del);
    };

    const email_api = async (id, e) => {
        const payload = {
            user_id: id.toString(),
            new_status: e,
        };

        try {
            await axiosPrivate
                .patch('/user/active-inactive', payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    if (e.data.new_status === 1) {
                        notifyActive();
                    } else {
                        notifyNotActive();
                    }
                });
        } catch (err) {}
    };

    const showSearch = (searchText) => {
        const copyOfUsers = [...User];

        setSearch(searchText);
        const result = copyOfUsers.filter((points) => {
            return (
                points.first_name
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase()) ||
                points.department
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase()) ||
                points.role
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase()) ||
                points.email
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase()) ||
                points.contact_number
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase()) ||
                points.initial
                    .toString()
                    .toLowerCase()

                    .match(searchText.toString().toLowerCase())
            );
        });
        setFilterUser(result);
    };

    const headers = [
        {
            width: '35px',
            cell: (user) => (
                <>
                    {user.photo ? (
                        <img
                            src={user.photo}
                            className="userprofile"
                            width="36px"
                            alt=""
                        />
                    ) : (
                        <img
                            src={UserIco}
                            className="userprofile"
                            width="36px"
                            alt=""
                        />
                    )}
                </>
            ),
        },
        {
            id: 'name',
            name: 'Name',
            selector: (user) => user.first_name,
            sortable: true,
        },
        {
            id: 'initial',
            name: 'Initial',
            selector: (user) => user.initial,
            sortable: true,
        },
        {
            id: 'dept',
            name: 'Department',
            selector: (user) => user.department,
            sortable: true,
        },
        {
            id: 'designation',
            name: 'Designation',
            selector: (user) => user.role,
            sortable: true,
        },
        {
            id: 'email',
            name: 'Email',
            selector: (user) => user.email,
            sortable: true,
            width: '220px',
        },
        {
            id: 'contact',
            name: 'Contact',
            selector: (user) => user.contact_number,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (user) => (
                <>
                    {superUser === true ? (
                        <>
                            <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                checked={user.status === 1 ? true : false}
                                onChange={(e) => {
                                    email_api(user._id, e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                            />
                        </>
                    ) : (
                        <>
                            {all_permission.includes(
                                'user.user_change_status'
                            ) ? (
                                <>
                                    {' '}
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        checked={
                                            user.status === 1 ? true : false
                                        }
                                        onChange={(e) => {
                                            email_api(user._id, e);
                                        }}
                                        size="xs"
                                        width={80}
                                        onlabel="Active"
                                        offlabel="Inactive"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            ),
            width: '120px',
        },
        {
            name: 'Action',
            selector: (user) => (
                <td>
                    {superUser === true ? (
                        <>
                            <span className="actionButton">
                                <img
                                    src={EditIcon}
                                    onClick={() => {
                                        navigate(`/update-user/${user._id}`);
                                    }}
                                    alt=""
                                />
                            </span>
                        </>
                    ) : (
                        <>
                            {all_permission.includes('user.user_update') ? (
                                <>
                                    <span className="actionButton">
                                        <img
                                            src={EditIcon}
                                            onClick={() => {
                                                navigate(
                                                    `/update-user/${user._id}`
                                                );
                                            }}
                                            alt=""
                                        />
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}

                    <span
                        className="actionButton"
                        onClick={() => navigate(`/permissions/${user._id}`)}
                    >
                        <img src={KeyIcon} alt="" />
                    </span>
                    <span className="actionButton">
                        {superUser === true ? (
                            <>
                                {' '}
                                <img
                                    src={DeleteIcon}
                                    onClick={() => {
                                        confirmAlert({
                                            message:
                                                'Do you want to delete ' +
                                                user.first_name,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () =>
                                                        handleDelete(user._id),
                                                },
                                                {
                                                    label: 'No',
                                                },
                                            ],
                                        });
                                    }}
                                    alt=""
                                />
                            </>
                        ) : (
                            <>
                                {all_permission.includes('user.user_delete') ? (
                                    <>
                                        {' '}
                                        <img
                                            src={DeleteIcon}
                                            onClick={() => {
                                                confirmAlert({
                                                    message:
                                                        'Do you want to delete ' +
                                                        user.first_name,
                                                    buttons: [
                                                        {
                                                            label: 'Yes',
                                                            onClick: () =>
                                                                handleDelete(
                                                                    user._id
                                                                ),
                                                        },
                                                        {
                                                            label: 'No',
                                                        },
                                                    ],
                                                });
                                            }}
                                            alt=""
                                        />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </span>
                </td>
            ),
            width: '120px',
        },
    ];
    return (
        <>
            {dataLoader === true ? (
                <>
                    {' '}
                    <SpinnerLoader />
                </>
            ) : (
                <>
                    {' '}
                    {permission === 'Insufficient permissions' ? (
                        <>
                            {' '}
                            <InsufficientPermisiion />
                        </>
                    ) : (
                        <>
                            {' '}
                            <div className="top_container">
                                <div className="main_container ">
                                    <div className="user_serachbox">
                                        <div style={{ float: 'left' }}>
                                            <h3 className="tableHeading">
                                                <img
                                                    src={userIco}
                                                    className="headingIcons"
                                                    alt=""
                                                />{' '}
                                                Users
                                            </h3>
                                        </div>
                                        <input
                                            type="search"
                                            className="tableSearch"
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(e) =>
                                                showSearch(e.target.value)
                                            }
                                        />
                                        {superUser === true ? (
                                            <>
                                                <button
                                                    className="cbtn addBtn"
                                                    onClick={() => {
                                                        navigate('/new-user');
                                                    }}
                                                >
                                                    <img src={addIcon} alt="" />
                                                    &nbsp; New User
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {all_permission.includes(
                                                    'user.user_create'
                                                ) ? (
                                                    <>
                                                        {' '}
                                                        <button
                                                            className="cbtn addBtn"
                                                            onClick={() => {
                                                                navigate(
                                                                    '/new-user'
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={addIcon}
                                                                alt=""
                                                            />
                                                            &nbsp; New User
                                                        </button>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="mainContent dTable">
                                        <DataTable
                                            columns={headers}
                                            data={filterUser || User}
                                            noDataComponent="No Data Found"
                                            pagination
                                            fixedHeader
                                            highlightOnHover
                                            defaultSortFieldId="name"
                                            defaultSortAsc={true}
                                            progressPending={pending}
                                            progressComponent={
                                                <SpinnerLoader />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default UserListing;
