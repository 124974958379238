import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import './APRequest.css';
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../../UI/Spinner';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import Select from 'react-select';
function MyVerticallyCenteredModal(props) {
    const axiosPrivate = useAxiosPrivate();
    const [selected, setSelected] = useState([]);
    const [targetType, setTargetTYpe] = useState([]);
    const [loder, setLoder] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [projectStatusStorage, setProjetStatusStorage] = useState([]);
    const [projectStatus, setProjetStatus] = useState([]);
    const [deviceTypeStorage, setDeviceTypestorage] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [selectedmulti, setSelectedMulti] = useState([]);
    const [multiloader, setMultiloader] = useState({
        targetdetail: true,
        supplierList: true,
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm();

    const [formData, setFormData] = useState({
        notes: '',
        pmcomments: '',
    });

    // const { id } = useParams();
    const id = props.projectId;

    useEffect(() => {
        if (props.show == false) {
            setTargetTYpe([]);
            setProjetStatus([]);
            setSelected([]);
            setFormData({ notes: '', pmcomments: '' });
            setValue('pmcomments', '');
            setValue('Notes', '');
            setValue('PriorityStatus', '');
            setIsSubmitting(false);
            reset();
        }
    }, [props.show]);
    useEffect(() => {
        get_Supplierlist_api();
        get_ProjectStatus_api();
    }, []);

    useEffect(() => {
        get_SurveyQuota_api();
    }, [id]);

    const notifysucces = () => toast.success('Request added successfully');

    const get_SurveyQuota_api = async () => {
        setLoder(false);
        setMultiloader((prevData) => ({
            ...prevData,
            targetdetail: true,
        }));
        try {
            const response = await axiosPrivate.get(
                `survey/get-survey-name/${id}`
            );
            const deviceTypeStorageData = response.data.survey.map(
                (e, index) => ({
                    label: e.survey_name,
                    value: e.id,
                    quota: e.n,
                    ir: e.ir,
                    loi: e.loi,
                    cpi: e.cpi,
                    survey_id: e.id,
                })
            );

            setDeviceTypestorage(deviceTypeStorageData);
            setLoder(true);
            setMultiloader((prevData) => ({
                ...prevData,

                targetdetail: false,
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleMultiSelectChange = (selectedOptions) => {
        // Get the current quota values
        const currentQuota = getValues('n');
        const currentLoi = getValues('loi');
        const currentIr = getValues('ir');
        const currentCpi = getValues('cpi');
        // Remove data for options that are no longer selected

        targetType.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentQuota[option.survey_id];
            }
        });

        targetType.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentLoi[option.survey_id];
            }
        });
        targetType.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentIr[option.survey_id];
            }
        });
        targetType.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentCpi[option.survey_id];
            }
        });

        // Set the new targetdetail value
        setValue('targetdetail', selectedOptions);

        setTargetTYpe(selectedOptions);
    };

    useEffect(() => {
        setValue('Supplier', selected);
    }, [selected]);

    const get_Supplierlist_api = async () => {
        setMultiloader((prevData) => ({
            ...prevData,

            supplierList: true,
        }));
        try {
            const response = await axiosPrivate.get(
              "supplyinfo/active-suppliers?supplier_type_id=2"
            );
            setSupplierList([]);
            response.data.supplier.map((e) => {
                setSupplierList((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
            setMultiloader((prevData) => ({
                ...prevData,

                supplierList: false,
            }));
        } catch (err) {
            // console.error(err);
        }
    };
    const handleSaveButton = async (e, data) => {
        setIsSubmitting(true);
        const surveyData = [];
        let supplierId = [];
        e.Supplier.forEach((item, index) => {
            supplierId.push(item.value);
        });

        const transformedData = {};

        // Create empty objects with required keys
        Object.keys(e.loi).forEach((id) => {
            transformedData[id] = {
                survey_id: id,
                n: 0,
                ir: 0,
                loi: 0,
                cpi: 0,
            };
        });

        // Populate values from e object
        Object.keys(e).forEach((metricKey) => {
            Object.keys(e[metricKey]).forEach((id) => {
                if (transformedData[id]) {
                    transformedData[id][metricKey] = e[metricKey][id];
                }
            });
        });

        const finalArray = Object.values(transformedData);

        try {
            await axiosPrivate
                .post(
                    `patner-request/create-patner-request`,
                    JSON.stringify({
                        project_id: id,
                        priority_id: projectStatus.value,
                        survey: finalArray,
                        supplier_id: supplierId,
                        comments: e.pmcomments,
                        notes: e.Notes,
                        request_status_id: 1,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((data) => {
                    if (data.status === 200) {
                        setIsSubmitting(false);
                        notifysucces();
                        props.setModalShow(false);
                    }
                });
        } catch (err) {
            toast.error(err.response.data.detail[0].msg);
            setIsSubmitting(false);
        }
    };
    const handleShow = () => {
        const currentQuota = getValues('n');
        const currentLoi = getValues('loi');
        const currentIr = getValues('ir');
        const currentCpi = getValues('cpi');
        // Remove data for options that are no longer selected

        targetType.forEach((option) => {
            if (!selectedmulti.includes(option)) {
                delete currentQuota[option.survey_id];
            }
        });

        targetType.forEach((option) => {
            if (!selectedmulti.includes(option)) {
                delete currentLoi[option.survey_id];
            }
        });
        targetType.forEach((option) => {
            if (!selectedmulti.includes(option)) {
                delete currentIr[option.survey_id];
            }
        });
        targetType.forEach((option) => {
            if (!selectedmulti.includes(option)) {
                delete currentCpi[option.survey_id];
            }
        });
        setTargetTYpe([]);
    };
    const get_ProjectStatus_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/priority');

            setProjetStatusStorage([]);

            response.data.all_status.map((e) => {
                setProjetStatusStorage((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onShow={handleShow}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px' }}
                >
                    +Add Partner Request
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleSaveButton)}>
                    <div className="csmulti">
                        <Row style={{ paddingBottom: '10px' }}>
                            <Col lg={6} md={6} sm={6} xs={12} className="col-3">
                                <label className="formlabelviewzone">
                                    Target Details*
                                </label>
                                <MultiSelect
                                    {...register('targetdetail', {
                                        required: 'This field is Required',
                                    })}
                                    className="multiselectoption"
                                    options={deviceTypeStorage}
                                    value={targetType}
                                    onChange={(e) => {
                                        handleMultiSelectChange(e);
                                        setSelectedMulti(e);
                                    }}
                                    labelledBy={'Select'}
                                    isCreatable={true}
                                    isLoading={multiloader.targetdetail}
                                    disableSearch
                                />
                                {targetType.length !== 0 ? (
                                    <></>
                                ) : (
                                    <>
                                        {errors.targetdetail && (
                                            <small className="apr_errmsg perrmsg">
                                                {errors.targetdetail.message}
                                            </small>
                                        )}
                                    </>
                                )}
                            </Col>{' '}
                            <Col lg={6} md={6} sm={6} xs={12} className="col-3">
                                <label className="formlabelviewzone">
                                    {' '}
                                    Priority Status*
                                </label>

                                <Select
                                    {...register('PriorityStatus', {
                                        required: 'This field is Required',
                                    })}
                                    className="pjt_select formtext"
                                    classNamePrefix="select"
                                    isDisabled={false}
                                    options={projectStatusStorage}
                                    value={projectStatus}
                                    onChange={(e) => {
                                        setProjetStatus(e);
                                        setValue('PriorityStatus', e);
                                    }}
                                    noOptionsMessage={() => 'No Data Found'}
                                />

                                {projectStatus.length !== 0 ? (
                                    <></>
                                ) : (
                                    <>
                                        {errors.PriorityStatus && (
                                            <small className="apr_errmsg perrmsg">
                                                {errors.PriorityStatus.message}
                                            </small>
                                        )}
                                    </>
                                )}
                            </Col>{' '}
                        </Row>

                        {targetType.map((e, index) => (
                            <div
                                key={index}
                                className="container"
                                style={{
                                    border: '1px solid #f2f0f0',
                                    marginBottom: '10px',
                                }}
                            >
                                {loder ? (
                                    <>
                                        <Row>
                                            <p className="apr_loctionP">
                                                {e.label}
                                            </p>
                                        </Row>

                                        <Row style={{ paddingBottom: '10px' }}>
                                            <Col
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                                className="col-3"
                                            >
                                                <label className="formlabelviewzone">
                                                    Quota*
                                                </label>
                                                <input
                                                    placeholder="Quota"
                                                    className="formtextviewzone"
                                                    type="text"
                                                    {...register(
                                                        `n.${e.survey_id}`,
                                                        {
                                                            required:
                                                                'This field is Required',
                                                            pattern: {
                                                                value: /^[0-9]*$/,
                                                                message:
                                                                    'Only numbers are allowed',
                                                            },
                                                        }
                                                    )}
                                                    defaultValue={e.quota}
                                                />
                                                {errors?.n?.[e.survey_id] && (
                                                    <small className="apr_errmsg">
                                                        {
                                                            errors.n[
                                                                e.survey_id
                                                            ].message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                            <Col
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                                className="col-3"
                                            >
                                                <label className="formlabelviewzone">
                                                    IR*
                                                </label>
                                                <input
                                                    placeholder="IR"
                                                    className="formtextviewzone"
                                                    type="text"
                                                    {...register(
                                                        `ir.${[e.survey_id]}`,
                                                        {
                                                            required:
                                                                'This field is Required',
                                                            pattern: {
                                                                value: /^[0-9]*$/,
                                                                message:
                                                                    'Only numbers are allowed',
                                                            },
                                                        }
                                                    )}
                                                    defaultValue={e.ir}
                                                />
                                                {errors?.ir?.[e.survey_id] && (
                                                    <small className="apr_errmsg">
                                                        {
                                                            errors.ir[
                                                                e.survey_id
                                                            ].message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                            <Col
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                                className="col-3"
                                            >
                                                <label className="formlabelviewzone">
                                                    LOI*
                                                </label>
                                                <input
                                                    placeholder="LOI"
                                                    className="formtextviewzone"
                                                    type="text"
                                                    {...register(
                                                        `loi.${[e.survey_id]}`,
                                                        {
                                                            required:
                                                                'This field is Required',
                                                            pattern: {
                                                                value: /^[0-9]*$/,
                                                                message:
                                                                    'Only numbers are allowed',
                                                            },
                                                        }
                                                    )}
                                                    defaultValue={e.loi}
                                                />
                                                {errors?.loi?.[e.survey_id] && (
                                                    <small className="apr_errmsg">
                                                        {
                                                            errors.loi[
                                                                e.survey_id
                                                            ].message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                            <Col
                                                lg={3}
                                                md={6}
                                                sm={6}
                                                xs={12}
                                                className="col-3"
                                            >
                                                <label className="formlabelviewzone">
                                                    CPI*
                                                </label>
                                                <input
                                                    placeholder="CPI"
                                                    className="formtextviewzone"
                                                    type="text"
                                                    {...register(
                                                        `cpi.${[e.survey_id]}`,
                                                        {
                                                            required:
                                                                'This field is Required',
                                                            pattern: {
                                                                value: /^[0-9.]*$/,
                                                                message:
                                                                    'Only numbers are allowed',
                                                            },
                                                        }
                                                    )}
                                                    defaultValue={e.cpi}
                                                />
                                                {errors.cpi?.[e.survey_id] && (
                                                    <small className="apr_errmsg">
                                                        {
                                                            errors.cpi[
                                                                e.survey_id
                                                            ].message
                                                        }
                                                    </small>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <SpinnerLoader />
                                    </>
                                )}
                            </div>
                        ))}

                        <div>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <label className="formlabelviewzone">
                                        Supplier
                                    </label>
                                    <MultiSelect
                                        {...register('Supplier')}
                                        className="multiselectoption"
                                        options={supplierList}
                                        value={selected}
                                        onChange={setSelected}
                                        labelledBy={'Select'}
                                        isCreatable={true}
                                        // disableSearch
                                    />
                                    {selected.length !== 0 ? (
                                        <></>
                                    ) : (
                                        <>
                                            {errors.Supplier && (
                                                <small className="apr_errmsg perrmsg">
                                                    {errors.Supplier.message}
                                                </small>
                                            )}
                                        </>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: '10px' }}></div>

                        <div>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <label className="formlabelviewzone">
                                        PM Comments
                                    </label>
                                    <textarea
                                        {...register('pmcomments')}
                                        style={{ height: '75px' }}
                                        placeholder="PM Comments"
                                        className="formtextviewzone"
                                        type="text"
                                        defaultValue={formData.pmcomments}
                                    />
                                </Col>
                                <Col lg={6} md={6} sm={6} xs={12}>
                                    <label className="formlabelviewzone">
                                        Notes
                                    </label>
                                    <textarea
                                        {...register('Notes')}
                                        style={{ height: '75px' }}
                                        placeholder="Notes"
                                        className="formtextviewzone"
                                        type="text"
                                        defaultValue={formData.notes}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="apr_submitbtn">
                            <button className="supply_setupbtn">
                                {' '}
                                {isSubmitting === true && (
                                    <Spinner
                                        style={{ color: 'white' }}
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
}

export default MyVerticallyCenteredModal;
