import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";

import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

import BootstrapSwitchButton from "bootstrap-switch-button-react";
import "../Invoice.css";

import ProjectsurveyBilling from "./Projectsurveybilling";
import Uploadattachment from "./Uploadattachment";
import Uploadids from "./Uploadids";
import { useForm } from "react-hook-form";
import "url-search-params-polyfill";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
const Projectinvoiceids = (props) => {
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const progressInfosRef = useRef(null);
  const [overwrite, setOverwrite] = useState(true);
  const [resId, setResId] = useState(true);
  const [uploadattachmentfile, setUploadAttachmentfile] = useState([]);
  const [uploadidsfile, setUploadidesfile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [surveyBilling, setSurveyBilling] = useState(false);
  const [surveyInvoicedata, setSurveyInvoicedata] = useState([]);
  const [email_content, setEmail_content] = useState("");
  const [show_proj_Invoiceids, setProj_Invoiceids] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [billingsc_count, setBillingSC_Count] = useState(false);
  const [errorMessage_status, setErrorMessage_status] = useState(false);
  const [recall_api, setREcall_Api] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
  } = useForm();

  const handleFormSubmit = async (e) => {
    if (resId === true && (!uploadidsfile || uploadidsfile.length === 0)) {
      // Show an error message or perform any other error handling

      // You can set a state variable to display the error message in your component
      setErrorMessage("Upload IDs file is required");
      return; // Stop further execution
    }
    const payload = {
      uploaded_status: resId,
      is_overwrite: overwrite,
      uploaded_approved_ids: uploadidsfile,

      is_sc_billing: billingsc_count,
      email_content: email_content,
      email_attachment: uploadattachmentfile,
    };

    const params = new URLSearchParams(payload);
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .patch(
          `/invoice/project-billing-invoice?project_id=${id}`,
          params.toString(),
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setSurveyInvoicedata(response.data.billingsurvey);
            setSurveyBilling(true);
            setProj_Invoiceids(false);
            setREcall_Api(false);
            // props.setInvoiceuploadids(false);
          }
        });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsSubmitting(false);
  };
  useEffect(() => {
    if (resId === true) {
      setErrorMessage_status(true);
    } else {
      setErrorMessage_status(false);
    }
  }, [resId, uploadidsfile]);
  useEffect(() => {
    if (recall_api === true) {
      handleFormSubmit();
    }
  }, [recall_api]);
  return (
    <>
      {surveyBilling ? (
        <>
          <ProjectsurveyBilling
            show={surveyBilling}
            onHide={() => setSurveyBilling(false)}
            props={props}
            setSurveyBilling={setSurveyBilling}
            surveyInvoicedata={surveyInvoicedata}
            setProj_Invoiceids={setProj_Invoiceids}
            setSurveyInvoicedata={setSurveyInvoicedata}
            setInvoiceuploadids={props.setInvoiceuploadids}
            overwrite={overwrite}
            setREcall_Api={setREcall_Api}
            setProjectInvoiceuploadids={props.setProjectInvoiceuploadids}
            project_name={props.project_name}
            setCall_Api={props.setCall_Api}
          />
        </>
      ) : (
        <></>
      )}

      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className={
          show_proj_Invoiceids === true ? "opacity_show" : "opacity_hide"
        }
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "3px" }}
          >
            {props.project_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div>
              <Row>
                <Col lg={4} md={4} sm={4} xs={6}>
                  <div className="">
                    <div className="tooglecenter pswtchbtn screenswitchbtn">
                      <div className="redcss">Resp id</div>
                      <div style={{ height: "5px" }}></div>
                      <BootstrapSwitchButton
                        className="switch btn on btn-outline-success btn-xs"
                        width={60}
                        size="xs"
                        onlabel="ON "
                        offlabel="OFF"
                        checked={resId}
                        onChange={(e) => {
                          setResId(e);
                        }}
                        onstyle="outline-success"
                        offstyle="outline-danger"
                      />
                      <div style={{ height: "10px" }}></div>
                      {resId ? (
                        <>
                          {" "}
                          <div className="redcss">Approved</div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="redcss">Rejectd</div>{" "}
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                {resId ? (
                  <>
                    {" "}
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <div className="">
                        <div className="tooglecenter pswtchbtn screenswitchbtn">
                          <div className="redcss">Action</div>
                          <div
                            style={{
                              height: "5px",
                            }}
                          ></div>
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            width={60}
                            size="xs"
                            onlabel="ON "
                            offlabel="OFF"
                            checked={overwrite}
                            onChange={(e) => {
                              setOverwrite(e);
                            }}
                            onstyle="outline-success"
                            offstyle="outline-danger"
                          />
                          <div
                            style={{
                              height: "10px",
                            }}
                          ></div>
                          {overwrite ? (
                            <>
                              {" "}
                              <div className="redcss">
                                Overwrite Resid's
                              </div>{" "}
                            </>
                          ) : (
                            <>
                              {" "}
                              <div className="redcss">Append ResId’s</div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <> </>
                )}
                {resId ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <Col lg={4} md={4} sm={4} xs={6}>
                      <div className="">
                        <lable className="redcss">Billing on SC Count</lable>
                        <div className="tooglecenter pswtchbtn screenswitchbtn">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setBillingSC_Count(e.target.checked);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              <>
                <Row style={{ marginTop: "20px" }}>
                  <Col lg={12} md={12} sm={12} xs={12} className="">
                    <label className="formlabelviewzone">Email Content</label>
                    <textarea
                      {...register(`Emailcontent`, {})}
                      name=""
                      id=""
                      className="invoice_emailcont"
                      placeholder=""
                      onChange={(e) => {
                        setEmail_content(e.target.value);
                      }}
                    />

                    <br />
                    {errorMessage_status && uploadidsfile.length === 0 ? (
                      <>
                        {errorMessage && (
                          <div className="error-message_invoiceIDs fixErrorMsg">
                            {errorMessage}
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="col-3">
                    <Uploadattachment
                      survey_id={id}
                      setUploadAttachmentfile={setUploadAttachmentfile}
                    />
                    {resId ? (
                      <Uploadids
                        survey_id={id}
                        setUploadidesfile={setUploadidesfile}
                      />
                    ) : (
                      <> </>
                    )}
                  </Col>
                </Row>
                <Row></Row>
              </>
            </div>
            <Col lg={12} md={12} sm={12} xs={12} className="col-3">
              <button className="cbtn saveBtn">
                {isSubmitting && (
                  <Spinner
                    style={{ color: "white" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                Next
              </button>
            </Col>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Projectinvoiceids;
