import Header from "../../UI/Header/Header";
import { useNavigate, useLocation, useParams } from "react-router";
import userIco from "../../../assets/BidsIcons/client.svg";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import toast from "react-hot-toast";
import "./BidsForm.css";
import BidsSubHeader from "../../BidsModule/BidsSubHeader/BidsSubHeader";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import Bidstopheader from "./Bidstopheader";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../UI/Spinner";
import Bidstopheaderselect from "../BidsTable/BidsTopHeaderSelect";
import useAuth from "../../../hooks/useAuth";

const UpdateFinanceDetails = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  let { client } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyname, setCompanyName] = useState();
  const [billingPhoneNo, setBilllingPhone] = useState();
  const [clientCode, setClentCode] = useState();
  const [billingEmail, setBillingEmail] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [billingContact, setBillingContact] = useState();
  const [street, setStreet] = useState();
  const [poBox, setPoBox] = useState();
  const [city, setCity] = useState();
  const [zip, setZip] = useState();
  const [country, setCountry] = useState([]);
  const [selectedcountId, setSelectedcountId] = useState();
  const [selectedstateId, setSelectedstateId] = useState();
  const [stateId, setStateId] = useState();
  const [countryId, setCountryId] = useState();
  const [countryname, setCountryname] = useState();
  const [allState, setAllState] = useState([]);
  const [loder, setLoder] = useState(false);

  var localData = localStorage.getItem("clientname");
  const { auth } = useAuth();

  const superUser = auth.superuser;
  const all_permission = auth.permission;

  useEffect(() => {
    getClient();
    countryList();
  }, []);
  useEffect(() => {
    userSlectedState();
  });

  useEffect(() => {
    setValue("Country", selectedcountId);
    setValue("State", stateId);
  }, [selectedcountId, stateId]);

  const getClient = async () => {
    setLoder(false);
    const response = await axiosPrivate.get(
      `client/get-client-finance/${client}/${id}`,
      {}
    );

    let financeDetails = response.data.client_finance;
    if (financeDetails && Object.keys(financeDetails).length === 0) {
      setLoder(false);
      navigate("/404");
    } else {
      setCompanyName(response.data.client_finance.company_name);
      setBilllingPhone(response.data.client_finance.billing_phone_number);
      setClentCode(response.data.client_finance.client_code);
      setBillingEmail(response.data.client_finance.billing_email);
      setPaymentTerms(response.data.client_finance.payment_term);
      setBillingContact(response.data.client_finance.billing_contact);
      setStreet(response.data.client_finance.billing_address.street);
      setPoBox(response.data.client_finance.billing_address.po_box);
      setCity(response.data.client_finance.billing_address.city);
      setZip(response.data.client_finance.billing_address.zip);
      setSelectedcountId(
        response.data.client_finance.billing_address.country_id
      );
      setSelectedstateId(response.data.client_finance.billing_address.state_id);
      setStateId(response.data.client_finance.billing_address.state_id);
      setLoder(true);
    }
  };

  const countryList = async () => {
    try {
      const response = await axiosPrivate.get(`lookup/country-list`, {});
      let array = [];
      Object.values(response.data.all_countries).forEach(function (key) {
        array.push(key);
      });
      setCountry(array);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const userSlectedState = () => {
    country.map((e) => {
      if (e._id === selectedcountId) {
        setAllState(e.all_states);
        e.all_states.map((end) => {
          if (end.id === selectedstateId) {
            setCountryname(end.state_name);
          }
        });
      }
    });
  };

  const selectFun = (id) => {
    country.map((e) => {
      if (e._id == id) {
        setAllState(e.all_states);
      }
    });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validEmail = () => toast.error("use valid email");

  const notifysuccess = () =>
    toast.success("Finance details updated successfully", {});
  const supererror = (e) => toast.error(e);

  const newFinanceDetail = async (e) => {
    setIsSubmitting(true);

    if (e == undefined) {
      setIsSubmitting(false);
    }
    let counId;
    if (countryId) {
      counId = countryId;
    } else {
      counId = selectedcountId;
    }

    let steId;
    if (stateId) {
      steId = stateId;
    } else {
      steId = selectedstateId;
    }
    try {
      await axiosPrivate
        .patch(
          "client/update-client-finance",

          JSON.stringify({
            client_id: client,
            company_name: e.CompanyName,
            billing_phone_number: e.BillingPNumber,
            client_code: e.ClientCode,
            billing_email: e.BillingEmail,
            billing_contact: e.BillingContact,
            payment_term: e.PaymentTerms,
            billing_address: {
              street: e.Street,
              state_id: e.State,
              po_box: e.PoBox,
              zip: e.Zip,
              city: e.City,
              country_id: e.Country,
              finance_doc: "string",
            },
            status: 1,
            client_finance_id: id,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysuccess("Finance details updated successfully");
            navigate(`/finance/${client}`);
          }
        });
    } catch (err) {
      if (err.response.status === 409) {
      } else if (err.response.status === 422) {
      }
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const countryReg = register("Country", {
    required: "This field is Required",
  });

  const stateReg = register("State", {
    required: "This field is Required",
  });

  return (
    <>
      {/* <Header /> */}
      <BidsSubHeader pname="finance" />
      {loder ? (
        <>
          <div className="top_container">
            <div className="main_container ">
              <form onSubmit={handleSubmit(newFinanceDetail)}>
                <div className="user_serachbox">
                  <div style={{ float: "left" }}>
                    <h3 className="tableHeading">
                      <img src={userIco} className="headingIcons" alt="" />
                      {localData}
                    </h3>
                  </div>
                  <div className="right-pane">
                    <button
                      className="cbtn closeBtn"
                      onClick={() => {
                        navigate(`/finance/${client}`);
                      }}
                    >
                      {" "}
                      Close
                    </button>

                    {/* client.client_get_finance_list */}

                    {superUser === true ? (
                      <>
                        <button className="cbtn saveBtn">
                          {isSubmitting && (
                            <Spinner
                              style={{ color: "white" }}
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          &nbsp;&nbsp; Save
                        </button>
                      </>
                    ) : (
                      <>
                        {all_permission.includes(
                          "client.client_update_finance"
                        ) ? (
                          <>
                            <button className="cbtn saveBtn">
                              {isSubmitting && (
                                <Spinner
                                  style={{ color: "white" }}
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              &nbsp;&nbsp; Save
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <hr className="showingHeader" />
                {/* <Bidstopheader clientid={client} tid="2" /> */}
                <Bidstopheader
                  clientid={client}
                  tid="2"
                  className="showingHeader"
                />
                <Bidstopheaderselect clientid={client} namevalue={"finance"} />
                <div className="mainContent">
                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Company Name*</label>
                      <input
                        className="formtext"
                        {...register("CompanyName", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={companyname}
                      />
                      {errors.CompanyName && (
                        <small className="errmsg">
                          {errors.CompanyName.message}
                        </small>
                      )}
                    </Col>

                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Billing Phone Number*</label>
                      <input
                        className="formtext"
                        {...register("BillingPNumber", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0123456789]*$/,
                            message: "Only Numbers are allowed",
                          },
                        })}
                        defaultValue={billingPhoneNo}
                      />
                      {errors.BillingPNumber && (
                        <small className="errmsg">
                          {errors.BillingPNumber.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Client Code*</label>
                      <input
                        className="formtext"
                        {...register("ClientCode", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={clientCode}
                      />
                      {errors.ClientCode && (
                        <small className="errmsg">
                          {errors.ClientCode.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Billing Email*</label>
                      <input
                        className="formtext"
                        {...register("BillingEmail", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i,
                            message: "Enter Valid Email Id",
                          },
                        })}
                        defaultValue={billingEmail}
                      />
                      {errors.BillingEmail && (
                        <small className="errmsg">
                          {errors.BillingEmail.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Billing Contact*</label>
                      <input
                        className="formtext"
                        {...register("BillingContact", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0123456789]*$/,
                            message: "Only Numbers are allowed",
                          },
                        })}
                        defaultValue={billingContact}
                      />
                      {errors.BillingContact && (
                        <small className="errmsg">
                          {errors.BillingContact.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Payment Terms*</label>
                      <input
                        className="formtext"
                        {...register("PaymentTerms", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 ]*$/,
                            message: "letters and numbers are allowed",
                          },
                        })}
                        defaultValue={paymentTerms}
                      />
                      {errors.PaymentTerms && (
                        <small className="errmsg">
                          {errors.PaymentTerms.message}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <h5 className="headinginternal">Billing Address*</h5>

                  <Row>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Street*</label>
                      <input
                        className="formtext"
                        {...register("Street", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={street}
                      />
                      {errors.Street && (
                        <small className="errmsg">
                          {errors.Street.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Country*</label>
                      <select
                        {...countryReg}
                        className="formtext"
                        onChange={(e) => {
                          setSelectedcountId(e.target.value);
                          selectFun(e.target.value);
                          setStateId("");
                        }}
                        value={selectedcountId}
                      >
                        {country.map((e) => (
                          <option value={e._id}>{e.country_name}</option>
                        ))}
                      </select>
                      {errors.Country && (
                        <small className="errmsg">
                          {errors.Country.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">State</label>
                      <select
                        className="formtext"
                        {...stateReg}
                        onChange={(e) => {
                          setStateId(e.target.value);
                        }}
                        value={stateId}>
                        <option value="">Select</option>
                        {allState.map((state) => (
                          <option value={state.id}>{state.state_name}</option>
                        ))}
                      </select>
                      {errors.State && (
                        <small className="errmsg">{errors.State.message}</small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">City*</label>
                      <input className="formtext"
                        {...register("City", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                            message: "Only letters are allowed",
                          },
                        })}
                        defaultValue={city}
                      />
                      {errors.City && (
                        <small className="errmsg">{errors.City.message}</small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">PO Box*</label>
                      <input
                        className="formtext"
                        {...register("PoBox", {
                          required: "This field is Required",
                          pattern: {
                            value:
                              /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890 ]*$/,
                            message: "Only letters and numbers are allowed",
                          },
                        })}
                        defaultValue={poBox}
                      />
                      {errors.PoBox && (
                        <small className="errmsg">{errors.PoBox.message}</small>
                      )}
                    </Col>
                    <Col lg={3} md={4} sm={6} xs={12} className="col">
                      <label className="formlabel">Zip*</label>
                      <input
                        className="formtext"
                        {...register("Zip", {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0123456789]*$/,
                            message: "Only Numbers are allowed",
                          },
                        })}
                        defaultValue={zip}
                      />
                      {errors.Zip && (
                        <small className="errmsg">{errors.Zip.message}</small>
                      )}
                    </Col>
                  </Row>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <>
          <SpinnerLoader />
        </>
      )}
    </>
  );
};

export default UpdateFinanceDetails;
