import "./Screening.css";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";
import QuestionPopup from "./Questionpopup";
import TargetQuestionPopup from "./QuestionModule/TargetQuestionPopup";
import Select from "react-select";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import addIcon from "../../../assets/LoginIcons/add.svg";
import toast from "react-hot-toast";
import Spinner from "react-bootstrap/Spinner";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../UI/Spinner";
import { useParams } from "react-router";
import Viewpopup from "./Viewpopup";
import BuildClientUrlTest from "./BuildUrl/buildClientUrlTest";
import { useNavigate } from "react-router-dom";
import Screeninglogs from "./QuestionModule/Screeninglogs";
const Screening = (prop) => {
  const { id, layoutId, s_id, survey_Id } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [survey_id, setSurveyId] = useState(survey_Id);
  const [viewsurveyId, setViewsurveyId] = useState();
  const [surveyidselect, setSurveyIdselect] = useState([]);
  const [dropdownloader, setDropDownLoader] = useState(true);
  const [targetQuestionPopup, setTargetQuestionPopup] = useState(false);
  const [questionPopup, setQuestionPopup] = useState(false);
  const [buildurllive, setBuildUrllive] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingsecond, setIsSubmittingsecond] = useState(false);
  const [viwpopupshow, setViewpopshow] = useState(false);
  const [surveyname, setSurveyName] = useState("");
  const [loder, setLoder] = useState(true);
  const [screeningStatus, setScreeningStatus] = useState(false);
  const [surveySettingFeilds, setSurveySettingFeilds] = useState(false);
  const [checkBoxCapcule, setCheckBoxCapcule] = useState({
    screen_captcha: "false",
    research_defender: "false",
    speeder_terminate: "false",
    country_check: "false",
    proxy_check: "false",
    duplicate_ip: "false",
    sv_reject: "false",
    pre_screening: "false",
    qualification_screening: "false",
    red_herring_screen: "false",
  });
  const {
    register,

    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [surveyType, setSurveyType] = useState(null);
  const [deviceTarget, setDeviceTarget] = useState([]);
  const [softLaunch, setSoftLaunch] = useState("");
  const [testlink, setTestLink] = useState("");
  const [liveLink, setLiveLink] = useState("");
  const [softLounchQuota, setSoftLounchQuota] = useState(false);
  const [appendlink, setAppendlink] = useState(false);
  const [fileformdata, setFileFormdata] = useState();
  const [selectedItem, setSelectedItem] = useState([]);
  const [surveyclientlinkurl, setSurveyClientUrl] = useState(false);
  const [file_Name, setFile_Name] = useState("");
  const [fileName_status, setFileName_status] = useState(false);
  const [diviceTargetArray, setDiviceTargetArray] = useState([]);
  const [deviceTypeStorage, setDeviceTypestorage] = useState([]);
  const [surveyTypeStorage, setsurveyTypestorage] = useState([]);
  const [surveyStorage, setSurveyStorage] = useState("");
  const [tbuilurl, setTestBuildurl] = useState(false);
  const [statusName, setStatusName] = useState("");
  const [isScreening, setIsScreening] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [selected_SC_Id, setSelected_SC_Id] = useState("");

  useEffect(() => {
    get_DeviceType_api();
    get_SurveyType_api();
  }, []);

  const get_Screening_Question = async (sid) => {
    try {
      const response = await axiosPrivate.get(
        `screening/get-active-prescreening/${sid}`
      );

      if (response.data !== null && response.data.length !== 0) {
        setIsScreening(true);
      } else {
        setIsScreening(false);
      }
    } catch (err) {
      console.error(err);
      notifyerror(err.response.status);
    }
  };

  useEffect(() => {
    setValue("surveyType", surveyStorage);
    setValue("DeviceTarget", deviceTarget);
  }, [surveyType, deviceTarget]);

  const [container, setContainer] = useState({
    project_C: "show",
    project_I: "up",
    usGen_C: "show",
    usGen_I: "up",
    target_C: "show",
    target_I: "up",
    custom_C: "show",
    custom_I: "up",
    pre_C: "show",
    pre_I: "up",
  });

  const handleCustomBoxFeature = (Boxid) => {
    setQuestionPopup(true);
  };

  const RecallContainer = (e) => {
    if (e === "Project Setting") {
      setContainer((event) => ({
        ...event,
        project_C: "hide",
        project_I: "down",
      }));
    }
    if (e === "US Gen Pop 18+") {
      setContainer((event) => ({
        ...event,
        usGen_C: "hide",
        usGen_I: "down",
      }));
    }
    if (e === "Target Details") {
      setContainer((event) => ({
        ...event,
        target_C: "hide",
        target_I: "down",
      }));
    }
    if (e === "Custom Targeting") {
      setContainer((event) => ({
        ...event,
        custom_C: "hide",
        custom_I: "down",
      }));
    }
    if (e === "Pre Screening") {
      setContainer((event) => ({
        ...event,
        pre_C: "hide",
        pre_I: "down",
      }));
    }
  };

  const get_DeviceType_api = async () => {
    const response = await axiosPrivate.get("lookup/get-device-target ");
    setDeviceTypestorage([]);
    response.data.all_status.map((e) => {
      setDeviceTypestorage((val) => [...val, { label: e.name, value: e.id }]);
    });
  };

  useEffect(() => {
    const filteredData = deviceTypeStorage.filter((item) =>
      diviceTargetArray.includes(item.value)
    );

    const result1 = filteredData.map((item) => ({
      label: item.label,
      value: item.value,
    }));

    setDeviceTarget(result1);
  }, [deviceTypeStorage, diviceTargetArray]);

  useEffect(() => {
    get_Multiform_api();
    if (survey_Id != undefined && survey_Id != 1) {
      reset();
    }
  }, [id, survey_Id]);

  const get_SurveyType_api = async () => {
    try {
      const response = await axiosPrivate.get("lookup/get-survey-type ");
      setsurveyTypestorage([]);
      response.data.all_status.map((e) => {
        setsurveyTypestorage((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const get_Multiform_api = async () => {
    setLoder(true);
    setValue("fileuploadtext", "");
    setDropDownLoader(true);
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-project-id?project_id=${id}`
      );

      const surveyData = response.data.survey.map((e, index) => ({
        value: e.id,
        label: e.survey_name,
      }));

      if (survey_Id != undefined && survey_Id != 1) {
        const NotsurveyData = surveyData.filter(
          (item) => survey_Id === item.value
        );
        if (NotsurveyData.length !== 0) {
          const el = surveyData.filter((item) => survey_Id === item.value);
          get_radiobutton_data(survey_Id);
          setSelectedItem(el);
          prop.setSelectedElem(el.value);
          setSurveyIdselect(surveyData);
          setSurveyId(el[0].value);
        } else {
          setSelectedItem([]);
          setDropDownLoader(false);
          setSurveyIdselect([]);
          setLoder(false);
          setNoDataFound(true);
        }
        get_Screening_Question(survey_Id);
      } else {
        const el = surveyData.filter(
          (item) => surveyData[0].value === item.value
        );
        prop.setSelectedElem(el.value);
        setSelectedItem(el);
        setSurveyId(surveyData[0].value);

        get_Screening_Question(el[0].value);
        get_radiobutton_data(el[0].value);

        setSurveyIdselect(surveyData);
      }
    } catch (err) {
      setSelectedItem([]);
      setDropDownLoader(false);
      setSurveyIdselect([]);
      setLoder(false);
      setNoDataFound(true);
    }
    setDropDownLoader(false);
  };

  const handleSizeTest = () => {
    var element = document.getElementById("ClientTestLink");
    var eyeclose = document.getElementById("eyeclose");
    var eyeopen = document.getElementById("eyeopen");

    if (element.matches(".open")) {
      element.classList.remove("open");
      eyeclose.classList.add("open");
      eyeopen.classList.remove("open");
    } else {
      element.classList.add("open");
      eyeclose.classList.remove("open");
      eyeopen.classList.add("open");
    }
  };

  const handleSizelive = () => {
    var element = document.getElementById("ClientLiveLink");
    var eyeclose = document.getElementById("eyeclosel");
    var eyeopen = document.getElementById("eyeopenl");

    if (element.matches(".open")) {
      element.classList.remove("open");
      eyeclose.classList.add("open");
      eyeopen.classList.remove("open");
    } else {
      element.classList.add("open");
      eyeclose.classList.remove("open");
      eyeopen.classList.add("open");
    }
  };

  const csvfileDataLoading = (e) => {
    const fileName = e.target.files[0].name;
    setValue("fileuploadtext", e.target.files[0].name);
    setFile_Name(fileName);
    setFileName_status(true);
    setFileFormdata(e.target.files[0]);
    const file = e.target.files[0];
  };

  const notifyerror = (e) => toast.error(`${e} Error occured`);

  const handleRadioButton = async () => {
    setIsSubmitting(true);
    const payload = {
      security_check: {
        screen_captcha: checkBoxCapcule.screen_captcha,
        research_defender: checkBoxCapcule.research_defender,
        speeder_terminate: checkBoxCapcule.speeder_terminate,
        country_check: checkBoxCapcule.country_check,
        proxy_check: checkBoxCapcule.proxy_check,
        duplicate_ip: checkBoxCapcule.duplicate_ip,
        sv_reject: checkBoxCapcule.sv_reject,
      },
      screening: {
        pre_screening: checkBoxCapcule.pre_screening,
        qualification_screening: checkBoxCapcule.qualification_screening,
        red_herring_screen: checkBoxCapcule.red_herring_screen,
      },
    };
    try {
      await axiosPrivate
        .patch(`survey/security-setting/${survey_id}`, payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          toast.success("Survey setting updated successfully");
        });
    } catch (err) {
      notifyerror(err);
      notifyerror(err.response.status);
    }
    setIsSubmitting(false);
  };

  const get_radiobutton_data = async (sidname) => {
    setLoder(true);

    try {
      const response = await axiosPrivate.get(`survey/get-survey/${sidname}`);

      setSelected_SC_Id(response.data.survey.sc_id);
      setSurveyName(response.data.survey.survey_name);

      setStatusName(response.data.survey.survey_status.name);

      if (response.data.survey.security_check == null) {
        setNoDataFound(true);
        setLoder(false);
      } else {
        setCheckBoxCapcule((event) => ({
          ...event,
          screen_captcha: response.data.survey.security_check.screen_captcha,
          research_defender:
            response.data.survey.security_check.research_defender,
          speeder_terminate:
            response.data.survey.security_check.speeder_terminate,
          country_check: response.data.survey.security_check.country_check,
          proxy_check: response.data.survey.security_check.proxy_check,
          duplicate_ip: response.data.survey.security_check.duplicate_ip,
          sv_reject: response.data.survey.security_check.sv_reject,
          pre_screening: response.data.survey.screening.pre_screening,
          qualification_screening:
            response.data.survey.screening.qualification_screening,
          red_herring_screen: response.data.survey.screening.red_herring_screen,
        }));
      }
      if (response.data.survey.survey_setting !== null) {
        if (
          Object.keys(response.data.survey.survey_setting.survey_type)
            .length === 0 ||
          response.data.survey.survey_setting.survey_type.name === null
        ) {
          setSurveySettingFeilds(false);
        } else {
          setSurveySettingFeilds(true);
        }

        setSurveyType({
          label: response.data.survey.survey_setting.survey_type.name,
          value: response.data.survey.survey_setting.survey_type.id,
        });
        setSurveyStorage(response.data.survey.survey_setting.survey_type.id);
        setDiviceTargetArray(response.data.survey.survey_setting.device_target);
        if (response.data.survey.survey_setting.soft_launch.n === null) {
          setSoftLaunch("");
          setValue("softlaunchtext", "");
          setValue("testlink", "");
          setValue("livelink", "");
          setTestLink("");
          setLiveLink("");
        } else {
          setSoftLaunch(response.data.survey.survey_setting.soft_launch.n);
          setValue(
            "softlaunchtext",
            response.data.survey.survey_setting.soft_launch.n
          );
        }

        setSoftLounchQuota(
          response.data.survey.survey_setting.soft_launch.status
        );
        setValue(
          "testlink",
          response.data.survey.survey_setting.client_link.test_link
        );
        setValue(
          "livelink",
          response.data.survey.survey_setting.client_link.live_link
        );
        setTestLink(response.data.survey.survey_setting.client_link.test_link);
        setLiveLink(response.data.survey.survey_setting.client_link.live_link);
        if (
          response.data.survey.survey_setting.surveyclientlinkurl.length === 0
        ) {
          setSurveyClientUrl(false);
          setFileName_status(false);
        } else {
          setSurveyClientUrl(true);
          setFileName_status(true);
        }
      } else {
        setSurveyType(null);
        setSurveyStorage("");
        setDiviceTargetArray([]);
        setSoftLaunch("");
        setSoftLounchQuota(false);
        setTestLink("");
        setLiveLink("");
        setSurveyClientUrl(false);
        setSurveySettingFeilds(false);
      }
    } catch (err) {
      console.error(err);
      notifyerror(err.response.status);
    }
    setLoder(false);
  };

  const handleFormSubmit = async (e) => {
    const formfile = new FormData();
    formfile.append("clientsurveylink", fileformdata);
    setIsSubmittingsecond(true);
    let data = [];
    deviceTarget.map((e) => {
      data.push(e.value);
    });
    const payload = new FormData();
    payload.append("survey_type_id", surveyStorage);
    payload.append("device_target", data);
    payload.append(
      "soft_launch_status",
      softLounchQuota === undefined ? false : softLounchQuota
    );

    if (softLounchQuota === false || softLounchQuota === undefined) {
      payload.append("soft_launch_n", "");
    } else {
      payload.append("soft_launch_n", e.softlaunchtext);
    }

    if (e.livelink === undefined) {
      payload.append("client_link_live", "");
      payload.append("client_link_test", "");
    } else {
      payload.append("client_link_live", e.livelink);
      payload.append("client_link_test", e.testlink);
    }

    payload.append("is_append", appendlink);
    if (fileformdata === undefined) {
      payload.append("clientsurveylink", "");
    } else {
      payload.append("clientsurveylink", fileformdata);
    }

    try {
      await axiosPrivate

        .patch(`survey/survey-setting?id=${survey_id}`, payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((e) => {
          if (e.status === 200) {
            if (
              e.data.survey_setting.survey_setting.surveyclientlinkurl
                .length !== 0
            ) {
              setSurveyClientUrl(true);
            } else {
              setSurveyClientUrl(false);
            }

            toast.success("Survey link updated successfully");
          }
        });
    } catch (err) {
      notifyerror(err.response.data.return_status.message[0]);
    }
    setIsSubmittingsecond(false);
  };

  return (
    <>
      {tbuilurl ? (
        <>
          <BuildClientUrlTest
            show={tbuilurl}
            onHide={() => setTestBuildurl(false)}
            setbuildurledit={setTestBuildurl}
            settermination={setValue}
            keyvalue={"testlink"}
            setlink={setTestLink}
            baseurl={testlink}
          />
        </>
      ) : (
        <></>
      )}
      {buildurllive ? (
        <BuildClientUrlTest
          show={buildurllive}
          onHide={() => setBuildUrllive(false)}
          setbuildurledit={setBuildUrllive}
          settermination={setValue}
          keyvalue={"livelink"}
          setlink={setLiveLink}
          baseurl={liveLink}
        />
      ) : (
        <></>
      )}

      <TargetQuestionPopup
        popup={setTargetQuestionPopup}
        show={targetQuestionPopup}
        onHide={() => setTargetQuestionPopup(false)}
      />
      {questionPopup ? (
        <>
          <QuestionPopup
            show={questionPopup}
            onHide={() => setQuestionPopup(false)}
            selectedItem={selectedItem}
            setQuestionPopup={setQuestionPopup}
            setIsScreening={setIsScreening}
          />
        </>
      ) : (
        <></>
      )}
      {viwpopupshow ? (
        <>
          <Viewpopup
            show={viwpopupshow}
            onHide={() => setViewpopshow(false)}
            viewsurveyId={viewsurveyId}
            surveyStorage={surveyStorage}
          />
        </>
      ) : (
        <></>
      )}

      {screeningStatus ? (
        <>
          <Screeninglogs
            show={screeningStatus}
            onHide={() => setScreeningStatus(false)}
            survey_id={survey_id}
          />
        </>
      ) : (
        <></>
      )}

      <div className="saveclsebtn supply_btn"></div>
      <hr className="csHR mb-0" />

      <div className="csmulti padding-container pb-0 px-3">
        <Col lg={5} md={12} sm={12} xs={12} className="col-3 ">
          <div className="ProjectInput ">
            <label className="formlabel">Select Survey*</label>
            <Select
              className="pjt_select formtext"
              classNamePrefix="select"
              isDisabled={false}
              isLoading={dropdownloader}
              isRtl={false}
              isSearchable={true}
              name="color"
              value={selectedItem[0]}
              options={surveyidselect}
              onChange={(e) => {
                setSelectedItem(e);

                navigate(`/project-detail/${id}/${layoutId}/${e.value}`);

                setSurveyId(e.value);
                setSurveyName(e.label);
                prop.setSelectedElem(e.value);
                setFile_Name("");
                reset();
              }}
              noOptionsMessage={() => "No Data Found"}
            />
          </div>
        </Col>
      </div>

      {loder ? (
        <>
          <SpinnerLoader />
        </>
      ) : (
        <>
          {noDataFound == true && surveyidselect.length == 0 ? (
            <>
              {" "}
              <div
                className="alert alert-danger center-margin-container"
                role="alert"
              >
                No Survey Found!
              </div>
            </>
          ) : (
            <>
              {" "}
              <>
                <div className=" wrapperBelow">
                  <div className="RightContainer csmulti">
                    <div className="RightChild">
                      <div className="ProjectSetting pjt_arrow_icon flex justify-between">
                        <p className="w-auto">
                          Survey Setting - {selected_SC_Id}
                        </p>
                        <div>
                          <div className="rankPainA projbtns m-0 w-auto">
                            <button
                              style={{
                                cursor: "pointer",
                              }}
                              className="PSaveBtnA m-0"
                              onClick={handleRadioButton}
                            >
                              {isSubmitting && (
                                <Spinner
                                  style={{
                                    color: "white",
                                  }}
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              )}
                              Save
                            </button>
                          </div>
                          <span
                            className="projectarrow m-0"
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {container.project_I === "up" ? (
                              <KeyboardArrowUpIcon
                                onClick={() => {
                                  RecallContainer("Project Setting");
                                }}
                              />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={() => {
                                  setContainer((e) => ({
                                    ...e,
                                    project_I: "up",
                                    project_C: "show",
                                  }));
                                }}
                              />
                            )}
                          </span>
                        </div>
                      </div>

                      {container.project_C === "show" ? (
                        <>
                          <div className="ptoogle">
                            <Row
                              style={{
                                marginLeft: "-31px",
                              }}
                            >
                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    {" "}
                                    Screen Captcha
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      width={60}
                                      size="xs"
                                      onlabel="ON "
                                      offlabel="OFF"
                                      checked={checkBoxCapcule.screen_captcha}
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          screen_captcha: e,
                                        }));
                                      }}
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Research Defender
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      width={60}
                                      size="xs"
                                      checked={
                                        checkBoxCapcule.research_defender
                                      }
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          research_defender: e,
                                        }));
                                      }}
                                      onlabel="ON "
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Speeder Terminate
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      size="xs"
                                      width={60}
                                      onlabel="ON "
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          speeder_terminate: e,
                                        }));
                                      }}
                                      checked={
                                        checkBoxCapcule.speeder_terminate
                                      }
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Country Check
                                  </lable>

                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    {" "}
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs "
                                      width={60}
                                      size="xs"
                                      onlabel="ON "
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          country_check: e,
                                        }));
                                      }}
                                      checked={checkBoxCapcule.country_check}
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">Proxy Check</lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    {" "}
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs "
                                      width={60}
                                      size="xs"
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          proxy_check: e,
                                        }));
                                      }}
                                      checked={checkBoxCapcule.proxy_check}
                                      onlabel="ON "
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Duplicate Ip{" "}
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      width={60}
                                      size="xs"
                                      onlabel="ON "
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          duplicate_ip: e,
                                        }));
                                      }}
                                      checked={checkBoxCapcule.duplicate_ip}
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3 screenswitch_col"
                              >
                                <div className="screen">
                                  <lable className="redcss">Sv Reject</lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      width={60}
                                      size="xs"
                                      onlabel="ON "
                                      offlabel="OFF"
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          sv_reject: e,
                                        }));
                                      }}
                                      checked={checkBoxCapcule.sv_reject}
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3 screenswitch_col"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Pre Screening
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs"
                                      size="xs"
                                      width={60}
                                      onlabel="ON "
                                      offlabel="OFF"
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          pre_screening: e,
                                        }));
                                      }}
                                      checked={checkBoxCapcule.pre_screening}
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3 screenswitch_col"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Qualification Screening
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    {" "}
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs "
                                      width={60}
                                      size="xs"
                                      checked={
                                        checkBoxCapcule.qualification_screening
                                      }
                                      onlabel="ON "
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          qualification_screening: e,
                                        }));
                                      }}
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                md={3}
                                sm={6}
                                xs={6}
                                className="col-3 screenswitch_col"
                              >
                                <div className="screen">
                                  <lable className="redcss">
                                    Red Herring Screen
                                  </lable>
                                  <div className="tooglecenter pswtchbtn screenswitchbtn">
                                    {" "}
                                    <BootstrapSwitchButton
                                      className="switch btn on btn-outline-success btn-xs "
                                      width={60}
                                      size="xs"
                                      checked={
                                        checkBoxCapcule.red_herring_screen
                                      }
                                      onlabel="ON "
                                      onChange={(e) => {
                                        setCheckBoxCapcule((event) => ({
                                          ...event,
                                          red_herring_screen: e,
                                        }));
                                      }}
                                      offlabel="OFF"
                                      onstyle="outline-success"
                                      offstyle="outline-danger"
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <div className="">
                          <div className="ProjectSetting pjt_arrow_icon flex justify-between">
                            <p className="w-auto">{surveyname}</p>
                            <div className="rankPainA projbtns m-0 w-auto">
                              {statusName == "Live" ? (
                                <>
                                  <p className="liveBtn m-0">Live</p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Pending" ? (
                                <>
                                  <p className="pendingBtnagain m-0">Pending</p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Test Mode" ? (
                                <>
                                  <p className="testmodeBtnagain m-0">
                                    Test Mode
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Paused" ? (
                                <>
                                  <p className="pausedBtnagain m-0">Paused</p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Id Awaited" ? (
                                <>
                                  <p className="IdAwaitedBtnagain m-0">
                                    Id Awaited
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Billed" ? (
                                <>
                                  <p className="billedBtnagain m-0">Billed</p>
                                </>
                              ) : (
                                <></>
                              )}
                              {statusName == "Cancelled" ? (
                                <>
                                  <p className="cancelledBtnagain m-0">
                                    Cancelled
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}

                              <button
                                // style={{ marginRight: "1px" }}
                                className="PSaveBtnA m-0"
                              >
                                {isSubmittingsecond && (
                                  <Spinner
                                    style={{
                                      color: "white",
                                    }}
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                                Save
                              </button>
                            </div>

                            {container.usGen_I === "up" ? (
                              <>
                                <KeyboardArrowUpIcon
                                  className="arrowup"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    RecallContainer("US Gen Pop 18+");
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <KeyboardArrowDownIcon
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  className="arrowup"
                                  onClick={() => {
                                    setContainer((e) => ({
                                      ...e,
                                      usGen_C: "show",
                                      usGen_I: "up",
                                    }));
                                  }}
                                />
                              </>
                            )}
                          </div>
                          {container.usGen_C === "show" ? (
                            <>
                              <div className="container-fluid pb-2">
                                <Row>
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col-3 heightfix"
                                  >
                                    <label className="formlabelviewzone">
                                      Survey Type*
                                    </label>

                                    <Select
                                      {...register("surveyType", {
                                        required: "This field is Required",
                                      })}
                                      className="pjt_select formtext"
                                      Placeholder="select"
                                      options={surveyTypeStorage}
                                      defaultValue={
                                        surveyType.value === undefined ||
                                        surveyType.value === null ||
                                        surveyType.value === "undefined" ||
                                        surveyType.value === "null"
                                          ? []
                                          : surveyType
                                      }
                                      onChange={(e) => {
                                        setSurveyStorage(e.value);
                                        setSurveySettingFeilds(true);
                                        setSurveyType(e);

                                        if (e.value !== 1) {
                                          setFileName_status(false);
                                          setValue("fileuploadtext", "");
                                          setFile_Name("");
                                        }
                                      }}
                                    />

                                    {surveyStorage === "" ||
                                    surveyStorage === undefined ||
                                    surveyType.value === undefined ||
                                    surveyType.value === null ||
                                    surveyType.value === "undefined" ||
                                    surveyType.value === "null" ? (
                                      errors.surveyType && (
                                        <small className="screening_errormsg">
                                          {errors.surveyType.message}
                                        </small>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col-3 heightfix"
                                  >
                                    {" "}
                                    <label className="formlabelviewzone">
                                      Device Target*
                                    </label>
                                    <MultiSelect
                                      {...register("DeviceTarget", {
                                        required: "This field is Required",
                                      })}
                                      className="multiselectoption "
                                      options={deviceTypeStorage}
                                      value={deviceTarget}
                                      onChange={(e) => {
                                        setDeviceTarget(e);
                                      }}
                                      placeholder="Select..."
                                      labelledBy={"Select"}
                                      isCreatable={true}
                                      disableSearch
                                    />
                                    {deviceTarget.length === 0 ? (
                                      <>
                                        {errors.DeviceTarget && (
                                          <small className="screening_errormsg ">
                                            {errors.DeviceTarget.message}
                                          </small>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col-3 heightfix setW"
                                  >
                                    <lable className="redcss">
                                      Soft launch
                                    </lable>
                                    <div className="tooglecenter pswtchbtn sl_btn">
                                      <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        width={60}
                                        size="xs"
                                        checked={softLounchQuota}
                                        onChange={(e) => {
                                          setSoftLounchQuota(e);
                                        }}
                                        onlabel="ON "
                                        offlabel="OFF"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                      />
                                    </div>
                                  </Col>
                                  <Col
                                    lg={3}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col-3 heightfix"
                                  >
                                    {softLounchQuota ? (
                                      <>
                                        <label className="formlabelviewzone">
                                          Soft launch*
                                        </label>
                                        <input
                                          {...register("softlaunchtext", {
                                            required: "This field is Required",
                                            pattern: {
                                              value: /^[0-9]*$/,
                                              message:
                                                "Only numbers are allowed",
                                            },
                                          })}
                                          className="formtextviewzone"
                                          type="text"
                                          placeholder="Soft Launch"
                                          defaultValue={softLaunch}
                                        />

                                        {errors.softlaunchtext && (
                                          <small className="screening_errormsg">
                                            {errors.softlaunchtext.message}
                                          </small>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                                {(surveySettingFeilds === true &&
                                  surveyStorage) == 1 || surveyStorage == 3 ? (
                                  <>
                                    <Row className="flex-align-center-container">
                                      <Col
                                        lg={9}
                                        md={8}
                                        sm={12}
                                        xs={12}
                                        className="col"
                                      >
                                        <label className="formlabelviewzone clientlabel">
                                          Client Test Link*
                                        </label>

                                        <input
                                          {...register("testlink", {
                                            required: "This field is Required",
                                          })}
                                          id="ClientTestLink"
                                          className="formtextviewzone relative-overflow-container"
                                          placeholder="Client Test Link"
                                          type="text"
                                          onChange={(e) => {
                                            setTestLink(e.target.value);
                                          }}
                                          defaultValue={testlink}
                                        />
                                        <AiOutlineEye
                                          id="eyeclose"
                                          onClick={handleSizeTest}
                                          className="eyeclose eye-icon"
                                        />
                                        <AiOutlineEyeInvisible
                                          id="eyeopen"
                                          onClick={handleSizeTest}
                                          className="eyeopen eye-icon"
                                        />
                                        {testlink == "" ? (
                                          <>
                                            {errors.testlink && (
                                              <small className="screening_errormsg">
                                                {errors.testlink.message}
                                              </small>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>

                                      <Col
                                        lg={3}
                                        md={4}
                                        sm={12}
                                        xs={12}
                                        className="col colbtn"
                                      >
                                        <button
                                          className="cbtn saveBtn "
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setTestBuildurl(true);
                                          }}
                                        >
                                          Build Your URL
                                        </button>
                                      </Col>
                                    </Row>
                                    <Row className="flex-align-center-container">
                                      <Col
                                        lg={9}
                                        md={8}
                                        sm={12}
                                        xs={12}
                                        className="col"
                                      >
                                        <label
                                          className="formlabelviewzone clientlabel"
                                          id="ctl"
                                        >
                                          Client Live Link*
                                        </label>

                                        <input
                                          {...register("livelink", {
                                            required: "This field is Required",
                                          })}
                                          id="ClientLiveLink"
                                          className="formtextviewzone relative-overflow-container"
                                          placeholder="Client Live Link"
                                          type="text"
                                          onChange={(e) => {
                                            setLiveLink(e.target.value);
                                          }}
                                          defaultValue={liveLink}
                                        />
                                        <AiOutlineEye
                                          id="eyeclosel"
                                          onClick={handleSizelive}
                                          className="eyeclosel eye-icon"
                                        />
                                        <AiOutlineEyeInvisible
                                          id="eyeopenl"
                                          onClick={handleSizelive}
                                          className="eyeopenl eye-icon"
                                        />
                                        {liveLink == "" ? (
                                          <>
                                            {errors.livelink && (
                                              <small className="screening_errormsg">
                                                {errors.livelink.message}
                                              </small>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                      <Col
                                        lg={3}
                                        md={4}
                                        sm={12}
                                        xs={12}
                                        className="col colbtn"
                                      >
                                        <button
                                          className="cbtn saveBtn "
                                          style={{}}
                                          onClick={(e) => {
                                            setBuildUrllive(true);
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }}
                                        >
                                          Build Your URL
                                        </button>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <Row className="filename_row">
                                  {surveyStorage !== 1 &&
                                  surveySettingFeilds === true ? (
                                    <>
                                      <Col
                                        lg={6}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                      >
                                        <label className="formlabelviewzone">
                                          File Name*
                                        </label>
                                        <input
                                          {...(fileName_status === false
                                            ? register("fileuploadtext", {
                                                required:
                                                  "This field is Required",
                                              })
                                            : {})}
                                          id="fileUpload"
                                          value={file_Name}
                                          placeholder="File name"
                                          className="formtextviewzone"
                                          readOnly
                                        />

                                        {fileName_status === false ? (
                                          <>
                                            {errors.fileuploadtext && (
                                              <small className="screening_errormsg">
                                                {errors.fileuploadtext.message}
                                              </small>
                                            )}
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </Col>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <Col
                                    lg={6}
                                    md={8}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                  >
                                    {surveyStorage === 1 ||
                                    surveySettingFeilds === false ? (
                                      <></>
                                    ) : (
                                      <>
                                        {" "}
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div className="tooglecenter pswtchbtn flex flex-direction m-0">
                                            <label className="formlabelviewzone">
                                              Append Link
                                            </label>
                                            <BootstrapSwitchButton
                                              className="switch btn on btn-outline-success btn-xs"
                                              width={60}
                                              size="xs"
                                              checked={appendlink}
                                              onChange={(e) => {
                                                setAppendlink(e);
                                              }}
                                              onlabel="ON "
                                              offlabel="OFF"
                                              onstyle="outline-success"
                                              offstyle="outline-danger"
                                            />
                                          </div>

                                          <div
                                            style={{
                                              marginTop: "20px",
                                            }}
                                          >
                                            <label
                                              className="cbtn addBtn"
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img
                                                src={addIcon}
                                                className="headingIcons"
                                                alt=""
                                              />{" "}
                                              Upload File
                                              <input
                                                type="file"
                                                name="clientsurveylink"
                                                onChange={(e) => {
                                                  csvfileDataLoading(e);
                                                }}
                                                className="attachInput"
                                                accept=".csv"
                                                readOnly
                                              />
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </Col>
                                </Row>
                                &nbsp;
                                <Row>
                                  {surveyclientlinkurl === true &&
                                  surveyStorage !== 1 &&
                                  surveySettingFeilds === true ? (
                                    <>
                                      <Col
                                        lg={6}
                                        md={4}
                                        sm={6}
                                        xs={12}
                                        className="col"
                                      >
                                        <Button
                                          variant="contained"
                                          style={{
                                            height: "30px",
                                            width: "120px",
                                            backgroundColor: "black",
                                            textTransform: "none",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault(); // Prevent the default link behavior
                                            setViewsurveyId(survey_id);
                                            setViewpopshow(true);
                                          }}
                                        >
                                          <VisibilityIcon
                                            style={{ fontSize: "18px" }}
                                          />{" "}
                                          &nbsp; View File
                                        </Button>
                                      </Col>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Row>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </form>

                      <div>{/* <Customtarget /> */}</div>
                      <div>{/* <QuotaTarget />{" "} */}</div>
                      <div>
                        <div
                          className="ProjectSetting pjt_arrow_icon flex justify-between"
                          id="prescreening"
                        >
                          <p className="w-auto">Pre Screening</p>

                          <div className="rankPainA projbtns pswtchbtn prescreenLayout w-auto">
                            <button
                              className="PSaveBtnA m-0"
                              onClick={() => {
                                handleCustomBoxFeature();
                              }}
                            >
                              {isScreening ? "Edit" : "Add"}
                            </button>
                            <button
                              className="PSaveBtnA m-0"
                              onClick={() => {
                                setScreeningStatus(true);
                              }}
                            >
                              Logs
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </>
          )}
        </>
      )}
    </>
  );
};
export default Screening;
