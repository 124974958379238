import React, { useEffect, useState } from "react";
import "./Qualification.css";
import "../Common.css";
import { Container } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker from "react-datepicker";

import { useParams } from "react-router";
import Common from "../Common";

import "react-datepicker/dist/react-datepicker.css";
import { axiosPrivate } from "../../../api/axios";
import brandlogo from "../../../assets/SamplicityBlackLogo.svg";

const Qualification = () => {
  const { respid } = useParams();

  const [qData, setQData] = useState([]);
  const [qlist, setQlist] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [date, setDate] = React.useState(new Date(Date.now()));

  useEffect(() => {
    getQualification();
  }, []);

  const getQualification = async () => {
    setQData([]);
    try {
      const response = await axiosPrivate.get(`/get-qualification/${respid}`);
      setQData(response.data.qualification);

      let qData = [];
      response.data.qualification.map((e) => {
        if (e.q_type !== 1 && e.q_type !== 4) {
          qData.push(e.q_code);
        }
      });
      setQlist(qData);
    } catch (err) {
      // console.error(err);
    }
  };

  const handleChange = (dateChange) => {
    setValue("dateOfBirth", dateChange, {
      shouldDirty: true,
    });
    setDate(dateChange);
  };

  const formSubmit = async (formdata) => {
    let age = formdata.age;
    var datestring =
      age.getFullYear() + "-" + (age.getMonth() + 1) + "-" + age.getDate();

    let qualificationData = [];

    qlist.map((qid) => {
      let newobj = [];
      const qitem = formdata[qid];

      let result = Array.isArray(qitem);
      let anscode = [];
      let anstitle = [];
      if (result === true) {
        qitem.map((item) => {
          let itmsplit = item.split("_");
          anscode.push(itmsplit[0]);
          anstitle.push(itmsplit[1]);
        });
        newobj = {
          question_id: qid,
          ans_code: anscode,
          ans_title: anstitle,
        };
      } else {
        const qsplit = qitem.split("_");

        newobj = {
          question_id: qid,
          ans_code: qsplit[0],
          ans_title: qsplit[1],
        };
      }

      qualificationData.push(newobj);
    });

    const payload = {
      qualify_age: datestring,
      qualify_zipcode: formdata.ZIP,
      qualification: qualificationData,
    };

    const url = `qualification?respid=${respid}`;
    axiosPrivate
      .post(url, payload)
      .then((response) => {
        if (response.status === 200) {
          window.location.replace(response.data.url);
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  return (
    <>
      <Common />
      <div className="main qual_frontend">
        <Container>
          <form onSubmit={handleSubmit(formSubmit)}>
            {qData.map((qItem, index) => (
              <>
                <div className="qblock" key={qItem.q_code}>
                  {qItem.q_type === 4 || qItem.q_type === 1 ? (
                    <>
                      {/* if question type is age */}
                      {qItem.q_type === 4 ? (
                        <>
                          <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <p className="qtitle">
                                {qItem.question_title.title}
                              </p>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <Controller
                                name="age"
                                control={control}
                                defaultValue={date}
                                render={() => (
                                  <DatePicker
                                    selected={date}
                                    placeholderText="Select date"
                                    onChange={handleChange}
                                    showYearDropdown
                                    maxDate={new Date()}
                                  />
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          {/* if question type is zipcode  */}

                          <Row>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <p className="qtitle">
                                {qItem.question_title.title}
                              </p>
                            </Col>
                            <Col lg={6} md={6} sm={12} xs={12}>
                              <input
                                type="text"
                                {...register(qItem.q_name, {
                                  required: "This field is required",
                                })}
                              />

                              <ErrorMessage
                                errors={errors}
                                name={qItem.q_name}
                                render={({ message }) => (
                                  <span className="errMessage">{message}</span>
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {/* If question type is checkbox or radio */}
                      {qItem.q_type === 2 ? (
                        <>
                          <Row>
                            <Col>
                              <p className="qtitle">
                                {qItem.question_title.title}{" "}
                                <ErrorMessage
                                  errors={errors}
                                  name={`${qItem.q_code}`}
                                  render={({ message }) => (
                                    <span className="errMessage">
                                      {message}
                                    </span>
                                  )}
                                />
                              </p>
                            </Col>
                          </Row>

                          <Row>
                            {qItem.answer.map((answer, indx) => (
                              <>
                                {" "}
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="anscol"
                                  key={answer.ans_code}
                                >
                                  <input
                                    type="radio"
                                    value={`${answer.answer_title.ans_id}_${answer.answer_title.title}`}
                                    id={`${qItem.q_name}_${answer.ans_code}`}
                                    {...register(`${qItem.q_code}`, {
                                      required: "This field is required",
                                    })}
                                  />
                                  <label
                                    for={`${qItem.q_name}_${answer.ans_code}`}
                                  >
                                    {answer.answer_title.title}
                                  </label>
                                </Col>
                              </>
                            ))}
                          </Row>
                        </>
                      ) : (
                        <>
                          {/* if question type is checkbox  */}
                          <Row>
                            <Col>
                              <p className="qtitle">
                                {qItem.question_title.title}{" "}
                                <ErrorMessage
                                  errors={errors}
                                  name={`${qItem.q_code}`}
                                  render={({ message }) => (
                                    <span className="errMessage">
                                      {message}
                                    </span>
                                  )}
                                />
                              </p>{" "}
                            </Col>
                          </Row>
                          <Row>
                            {qItem.answer.map((answer, indx) => (
                              <>
                                {" "}
                                <Col
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="anscol"
                                >
                                  <input
                                    type="checkbox"
                                    id={`${qItem.q_name}_${answer.ans_code}`}
                                    {...register(`${qItem.q_code}`, {
                                      required: "This field is required",
                                    })}
                                    value={`${answer.answer_title.ans_id}_${answer.answer_title.title}`}
                                  />
                                  <label
                                    for={`${qItem.q_name}_${answer.ans_code}`}
                                  >
                                    {answer.answer_title.title}
                                  </label>
                                </Col>
                              </>
                            ))}
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            ))}

            {errors.answer?.type === "required" && (
              <p role="alert" className="errMsg">
                Please select atleast one option
              </p>
            )}
            <button className="btn sbmtbtn" type="submit">
              Submit
            </button>
          </form>
        </Container>
      </div>
    </>
  );
};

export default Qualification;
