// import { Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Select, { useStateManager } from "react-select";
import { useForm } from "react-hook-form";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";

function LinksModal(props) {
  const { register } = useForm();
  const [clientListStorage, setClientListStorage] = useState([]);
  const [dropdownloader, setDropDownLoader] = useState(true);
  const [linkData, setLinkData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [loder, setLoder] = useState(false);
  const [clientId, setClientId] = useState("");

  const closePopUp = () => {
    setLinkData([]);
    props.onHide(false);
  };

  useEffect(() => {
    get_ClintList_api();
  }, []);

  const get_ClintList_api = async () => {
    setDropDownLoader(true);
    try {
      const response = await axiosPrivate.get(`client/client-list`);
      response.data.all_client.map((e) => {
        setClientListStorage((val) => [
          ...val,
          { label: e.name, value: e._id },
        ]);
      });
    } catch (err) {
      console.error(err);
    }
    setDropDownLoader(false);
  };

  const get_ClintLink_api = async (id) => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `survey/client-redirects?client_id=${id}`
      );
      setLinkData(response.data.client_redirect_link);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={closePopUp}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          End Links for Client
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="linksWrapper">
          <Row className="flex items-center">
            <Col lg={2} md={4} sm={6} xs={12}>
              <label>Please select Client</label>
            </Col>
            <Col lg={4} md={8} sm={6} xs={12}>
              <Select
                {...register("clientname", {
                  required: "This field is Required",
                })}
                className="pjt_select formtext"
                classNamePrefix="select"
                isLoading={dropdownloader}
                options={clientListStorage}
                // onChange={handleClientName}
                onChange={(e) => {
                  get_ClintLink_api(e.value);
                  setClientId(e.value);
                }}
                noOptionsMessage={() => "No Data Found"}
              />
            </Col>
          </Row>
          {clientId != 0 ? (
            <>
              {loder ? (
                <>
                  {linkData.map((items, index) => (
                    <>
                      <div key={index}>
                        <Row className="flex items-center">
                          <Col lg={2} md={4} sm={12} xs={12}>
                            <label>{items.link_name}</label>
                          </Col>

                          <Col lg={9} md={8} sm={12} xs={12}>
                            <span>{items.link}</span>
                          </Col>
                        </Row>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <SpinnerLoader />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          // onClick={props.onHide}
          onClick={(e) => {
            closePopUp();
          }}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinksModal;
