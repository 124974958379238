import "../ProjectDetail/ProjectDetail.css";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SpinnerLoader from "../../UI/Spinner";
import SurveyTracking from "./Trackerpopup/SurveyTracking";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Trackersub from "./TrackerSub";
import surveyTrackingIcon from "../../../assets/CSicons/icons/survey tracking.svg";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
const Tracker = (props) => {
  const navigate = useNavigate();
  const [setting, setSetting] = useState(false);
  const [loder, setLoder] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [quotaData, setQuotaData] = useState([]);
  const { id, layoutId, survey_Id } = useParams();

  const [subQota, setSubQota] = useState();
  const [surveyTrackingId, setSurveyTrackingId] = useState("");

  const dropDownQuotaTable = (eid) => {
    let activeRow = document.getElementById(eid);
    document
      .querySelectorAll(".dropeTable")
      .forEach((el) => el.classList.remove("open"));
    if (activeRow.classList.contains("active")) {
      activeRow.classList.remove("active");
    } else {
      document
        .querySelectorAll(".mainrow")
        .forEach((el) => el.classList.remove("active"));
      activeRow.classList.add("active");
      document.querySelector("." + eid).classList.add("open");
    }
  };

  useEffect(() => {
    get_SurveyQuota_api();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (survey_Id !== "1") {
      if (loder === true) {
        dropDownQuotaTable(`iconSect${survey_Id}`);
        setSubQota(survey_Id);
      }
    } else if (survey_Id === "1") {
      if (loder === true) {
        dropDownQuotaTable(`iconSect${quotaData[0]._id}`);
        setSubQota(quotaData[0]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loder]);

  const get_SurveyQuota_api = async () => {
    setLoder(false);
    var temp;
    if (props.idFromFilter) {
      temp = props.idFromFilter;
    } else {
      temp = id;
    }
    try {
      const response = await axiosPrivate.get(
        `/survey/get-survey-quota/${temp}`
      );
      setQuotaData(response.data.survey);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };

  return (
    <>
      <SurveyTracking
        show={setting}
        setSetting={setSetting}
        onHide={() => setSetting(false)}
        surveyTrackingId={surveyTrackingId}
      />
      <hr className="csHR" />
      <div className="Sfirstbox">
        {loder ? (
          <>
            {quotaData.length === 0 ? (
              <>
                {" "}
                <div
                  className="alert alert-danger"
                  style={{ textAlign: "center" }}
                  role="alert"
                >
                  No Survey Found!
                </div>
              </>
            ) : (
              <>
                {" "}
                <Table className="Quotatable">
                  <thead className="quotaHead boxShadow-none">
                    <TableRow>
                      <TableCell>Survey(SC ID)</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Achieved</TableCell>
                      <TableCell>Quota</TableCell>
                      <TableCell>Margin</TableCell>
                      <TableCell>Survey Tracking</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </thead>
                  <tbody className="quotaCssBorder">
                    {quotaData.map((e) => (
                      <>
                        <tr
                          className="QArrowHeead mainrow"
                          id={`iconSect${e._id}`}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            dropDownQuotaTable(`iconSect${e._id}`);
                            setSubQota(e._id);
                            navigate(
                              `/project-detail/${id}/${layoutId}/${e._id}`
                            );
                          }}
                        >
                          <td>{e.sc_id}</td>
                          <td>
                            {e.survey_status.name.toString() === "Live" ? (
                              <>
                                <p className="quotaBtn m-l-0">Live</p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() === "Pending" ? (
                              <>
                                <p className="quotapendingBtnagain m-l-0">
                                  Pending
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() === "Test Mode" ? (
                              <>
                                <p className="quotatestmodeBtnagain m-l-0">
                                  Test Mode
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() === "Paused" ? (
                              <>
                                <p className="quotapausedBtnagain m-l-0">
                                  Paused
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() ===
                            "Id Awaited" ? (
                              <>
                                <p className="quotaIdAwaitedBtnagain m-l-0">
                                  Id Awaited
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() === "Billed" ? (
                              <>
                                <p className="quotabilledBtnagain m-l-0">
                                  Billed
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                            {e.survey_status.name.toString() === "Cancelled" ? (
                              <>
                                <p className="quotacancelledBtnagain m-l-0">
                                  Cancelled
                                </p>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>{e.achieved}</td>
                          <td>{e.n}</td>
                          <td>{e.margin}%</td>
                          <td>
                            <img
                              title="Survey Tracking"
                              src={surveyTrackingIcon}
                              alt="surveyTrackingIcon"
                              srcset=""
                              onClick={(end) => {
                                setSetting(true);
                                end.stopPropagation();
                                setSurveyTrackingId(e._id);
                              }}
                              style={{
                                width: "15px",
                              }}
                            />
                          </td>
                          <td>
                            <KeyboardArrowUpIcon className="IconUp" />
                            <KeyboardArrowDownIcon className="DropIcon" />
                          </td>
                        </tr>
                        <tr
                          className={`dropeTable iconSect${e._id}`}
                          id={`firstSect${e._id}`}
                        >
                          <td colSpan={7}>
                            <Trackersub subQota={subQota} />
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </>
        ) : (
          <SpinnerLoader />
        )}
      </div>
    </>
  );
};

export default Tracker;
