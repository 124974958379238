import Table from "react-bootstrap/Table";
import { TableCell, TableRow } from "@mui/material";
import "../../CSModule/ProjectDetail/ProjectDetail.css";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
const Trackersub = (props) => {
  const id = props.subQota;

  const [subQuotaData, setSubQuotaData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [loder, setLoder] = useState(true);

  useEffect(() => {
    if (id === undefined) {
    } else {
      get_SubQuota_api();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const get_SubQuota_api = async () => {
    setLoder(true);
    try {
      const response = await axiosPrivate.get(
        `/survey/get-survey-supplier/${id}`
      );
      setSubQuotaData(response.data.survey);
    } catch (err) {
      console.error(err);
    }
    setLoder(false);
  };

  return (
    <>
      <Table className="quotasub quotasubBorderColor">
        <thead>
          <TableRow style={{ backgroundColor: "#f5f3f3" }}>
            <TableCell></TableCell>
            <TableCell className="tabledata quotasub">Fielded | Goal</TableCell>
            <TableCell className="tabledata quotasub">CPI ($) </TableCell>
            <TableCell className="tabledata quotasub">
              Last Complete Date
            </TableCell>

            <TableCell className="tabledata quotasub">In Progress</TableCell>
          </TableRow>
        </thead>
        <tbody>
          {loder ? (
            <>
              <tr>
                <td colSpan={5}>
                  <SpinnerLoader />
                </td>
              </tr>
            </>
          ) : (
            <>
              {subQuotaData.length === 0 ? (
                <>
                  <tr>
                    <td colSpan={5}>
                      {" "}
                      <div
                        className="alert alert-danger"
                        style={{ textAlign: "center", margin: "15px" }}
                        role="alert"
                      >
                        No data Found!
                      </div>
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {" "}
                  {subQuotaData.map((e) => (
                    <tr>
                      <td>{e.name}</td>
                      <td>
                        {e.fielded} | {e.n}
                      </td>
                      <td>{e.supplier_cost}</td>
                      <td>{e.last_complete_date}</td>
                      <td>{e.in_progress}</td>
                    </tr>
                  ))}
                </>
              )}
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};
export default Trackersub;
