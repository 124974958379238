import Header from '../../UI/Header/Header';
import { useNavigate } from 'react-router';
import userIco from '../../../assets/BidsIcons/client.svg';

import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './BidsForm.css';
import BidsSubHeader from '../../BidsModule/BidsSubHeader/BidsSubHeader';
import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import Bidstopheader from './Bidstopheader';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../UI/Spinner';
import Bidstopheaderselect from '../BidsTable/BidsTopHeaderSelect';
import useAuth from '../../../hooks/useAuth';
const AddClientContact = () => {
    const navigate = useNavigate();

    const [name, SetName] = useState();
    const [email, setEmail] = useState();
    const [title, SetTitle] = useState();
    const [phoneNo, SetPhoneNo] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loder, setLoder] = useState(false);
    var localData = localStorage.getItem('clientname');
    const axiosPrivate = useAxiosPrivate();
    const { id } = useParams();
    const { clientid } = useParams();
    const { auth } = useAuth();

    const superUser = auth.superuser;
    const all_permission = auth.permission;
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        handlePlaceholder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePlaceholder = async () => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(`/client/get-client-contact/${id}/${clientid}`, {});

            SetName(response.data.client_contact.name);
            setEmail(response.data.client_contact.email);
            SetTitle(response.data.client_contact.title);
            SetPhoneNo(response.data.client_contact.phone);
        } catch (err) {
            if (err.code === 'ERR_NETWORK') {
                navigate('/404');
            } else if (err.response.data === undefined) {
                navigate('/404');
            }
        }
        setLoder(true);
    };

    const handleSaveButton = async (e) => {
        setIsSubmitting(true);

        if (e === undefined) {
            setIsSubmitting(false);
        }

        try {
            await axiosPrivate
                .patch(
                    '/client/update-client-contact',
                    JSON.stringify({
                        client_id: id,
                        name: e.Name,
                        email: e.Email,
                        title: e.Title,
                        phone: e.Phone,
                        status: 1,
                        client_contact_id: clientid,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((e) => {
                    if (e.statusText === 'OK') {
                        notifysuccess();
                        navigate(`/client-contact/${id}`);
                    } else {
                    }
                });
        } catch (err) {
            notifyerror(err.response.statusText);
        }
    };
    const notifyerror = (e) => toast.error(`${e} error occured`);
    const notifysuccess = () => toast.success('Client contact updated successfully');

    return (
        <>
            {/* <Header /> */}

            <BidsSubHeader pname="clientlist" />
            {loder ? (
                <>
                    <div className="top_container">
                        <div className="main_container ">
                            <form onSubmit={handleSubmit(handleSaveButton)}>
                                <div className="user_serachbox">
                                    <div style={{ float: 'left' }}>
                                        <h3 className="tableHeading">
                                            <img src={userIco} className="headingIcons" alt="" /> {localData}
                                        </h3>
                                    </div>
                                    <div className="right-pane">
                                        <button
                                            className="cbtn closeBtn"
                                            onClick={() => {
                                                navigate(`/client-contact/${id}`);
                                            }}
                                        >
                                            {' '}
                                            Close
                                        </button>

                                        {superUser === true ? (
                                            <>
                                                <button
                                                    className="cbtn saveBtn"
                                                    onClick={() => {
                                                        handleSaveButton();
                                                    }}
                                                >
                                                    {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                                    &nbsp;Save
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                {all_permission.includes('client.client_update_contact') ? (
                                                    <>
                                                        <button
                                                            className="cbtn saveBtn"
                                                            onClick={() => {
                                                                handleSaveButton();
                                                            }}
                                                        >
                                                            {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                                            &nbsp;Save
                                                        </button>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                <hr className="showingHeader" />

                                {/* <Bidstopheader tid="3" clientid={id} /> */}
                                <Bidstopheader clientid={id} tid="3" className="showingHeader" />
                                <Bidstopheaderselect clientid={id} namevalue={'client-contact'} />
                                <div className="mainContent">
                                    <Row>
                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Name*</label>
                                            <input
                                                className="formtext"
                                                {...register('Name', {
                                                    required: 'This field is Required',
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                                        message: 'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={name}
                                            />
                                            {errors.Name && <small className="errmsg">{errors.Name.message}</small>}
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Email*</label>
                                            <input
                                                className="formtext"
                                                {...register('Email', {
                                                    required: 'This field is Required',
                                                    pattern: {
                                                        value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\],;:\s@\"]+)*)|(.+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@]{2,})$/i, // eslint-disable-line
                                                        message: 'Enter Valid Email Id',
                                                    },
                                                })}
                                                defaultValue={email}
                                            />
                                            {errors.Email && <small className="errmsg">{errors.Email.message}</small>}
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Title</label>
                                            <input
                                                className="formtext"
                                                {...register('Title', {
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/, // eslint-disable-line
                                                        message: 'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={title}
                                            />
                                            {errors.Title && <small className="errmsg">{errors.Title.message}</small>}
                                        </Col>

                                        <Col lg={3} md={4} sm={6} xs={12} className="col">
                                            <label className="formlabel">Phone</label>
                                            <input
                                                className="formtext"
                                                {...register('Phone', {
                                                    pattern: {
                                                        value: /^[0987654321 ]*$/,
                                                        message: 'Only Numbers are allowed',
                                                    },
                                                })}
                                                defaultValue={phoneNo}
                                            />
                                            {errors.Phone && <small className="errmsg">{errors.Phone.message}</small>}
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <SpinnerLoader />
                </>
            )}
        </>
    );
};

export default AddClientContact;
