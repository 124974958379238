import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { Fragment, useState } from "react";

import "../Invoice.css";
import SpinnerLoader from "../../../UI/Spinner";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "./invoicepop.css";
const ProjectsurveyBilling = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleconfirm_post = async (status) => {
    setIsSubmitting(true);
    const surveyData = props.surveyInvoicedata;

    const payloads = surveyData.map((survey) => {
      const {
        survey_id,
        billing_sc_count,
        supplier_summary,
        client_summary,
        additional_billing,
        email_content,
        uploaded_status,
        email_attachment,
        billing_url,
      } = survey;

      return {
        survey_id,
        billing_sc_count,
        supplier_summary,
        client_summary,
        additional_billing,
        email_content,
        uploaded_status,
        email_attachment,
        billing_url: billing_url.length !== 0 ? [billing_url] : [],
        is_billed: status,
      };
    });

    for (const payload of payloads) {
      try {
        await axiosPrivate
          .patch(
            `invoice/survey-billed?survey_id=${payload.survey_id}`,
            payload,
            {
              headers: {
                accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              props.setSurveyBilling(false);
              props.setProj_Invoiceids(true);
              props.setProjectInvoiceuploadids(false);
              props.setCall_Api(true);
              toast.success("Updated successfully");
            }
          });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
    setIsSubmitting(false);
  };

  const RowComponentClientSummary = ({
    itemlist,
    itemIndex,
    survey_id,
    billing_url,
    overwrite,
    email_content,
    email_attachment,
    setREcall_Api,
  }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
    const onSubmit = async (data) => {
      const payload = {
        survey_id: survey_id,
        billing_url: [billing_url],
        cl_count: data.CLCOUNT[itemIndex],
        old_billing_cost: itemlist.cpi,
        new_billing_cost: data.NEWCPI[itemIndex],
        is_overwrite: overwrite,
        email_content: email_content,
        email_attachment: email_attachment,
      };

      try {
        await axiosPrivate
          .post(`invoice/update-billing-summary`, payload, {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setREcall_Api(true);
            }
          });
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)} className="tr row">
          <Col className="td">{survey_id}</Col>
          <Col className="td">{itemlist.sc_count}</Col>
          <Col className="td">{itemlist.cl_count}</Col>
          <Col className="td">{itemlist.cpi}</Col>
          <Col className="td">{itemlist.cl_count * itemlist.cpi}</Col>
          <Col className="td">
            <input
              {...register(`CLCOUNT.${itemIndex}`, {
                required: "This field is Required",
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/,
                  message: "Numbers are allowed",
                },
              })}
              placeholder="CL_COUNT"
              className="formtextviewzone"
              type="text"
            />
            {errors?.CLCOUNT?.[itemIndex]?.message && (
              <small className="invoice_errmsg_proj">
                {errors.CLCOUNT[itemIndex].message}
              </small>
            )}
          </Col>
          <Col className="td">
            <input
              {...register(`NEWCPI.${itemIndex}`, {
                required: "This field is Required",
                pattern: {
                  value: /^[0-9]*\.?[0-9]*$/,
                  message: "Numbers are allowed",
                },
              })}
              placeholder="NEW CPI"
              className="formtextviewzone"
              type="text"
            />
            {errors?.NEWCPI?.[itemIndex]?.message && (
              <small className="invoice_errmsg_proj">
                {errors.NEWCPI[itemIndex].message}
              </small>
            )}
          </Col>

          <Col className="p-1 td">
            <button className="add-Quota-btn p-set">Save</button>
            {/* Display error message for this row if exists */}
          </Col>
        </form>
      </>
    );
  };
  const RowComponentAdditionbilling = ({
    itemlist,

    survey_id,
  }) => {
    return (
      <>
        <div className="tr row">
          <Col className="td">{survey_id}</Col>
          <Col className="td">Added Billing</Col>
          <Col className="td">{itemlist.cl_count}</Col>
          <Col className="td">{itemlist.cpi}</Col>
          <Col className="td">{itemlist.cl_count * itemlist.cpi}</Col>
          <Col className="td">-</Col>
          <Col className="td">-</Col>
          <Col className="td">-</Col>
        </div>
      </>
    );
  };
  const handlclose = () => {
    props.setSurveyBilling(false);
    props.setProj_Invoiceids(true);
    props.setProjectInvoiceuploadids(false);
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handlclose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "3px" }}
          >
            {props.project_name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props.surveyInvoicedata.length !== 0 ? (
            <>
              <div className="table">
                <Row className="tr thead">
                  <Col className="td">Survey Id</Col>
                  <Col className="td">SC Count</Col>
                  <Col className="td activity_row">CL Count</Col>
                  <Col className="td">Billed CPI</Col>
                  <Col className="td">Billed Revenue</Col>
                  <Col className="td">CL Count</Col>
                  <Col className="td">NEW CPI</Col>
                  <Col className="td p-1">Action</Col>
                </Row>
                {props.surveyInvoicedata[0].client_summary.length !== 0 ||
                props.surveyInvoicedata[0].additional_billing.length !== 0 ? (
                  <>
                    {props.surveyInvoicedata.map((surveyData, dataIndex) => (
                      <Fragment key={dataIndex}>
                        {surveyData.client_summary.map(
                          (itemlist, itemIndex) => (
                            <RowComponentClientSummary
                              key={itemIndex}
                              itemlist={itemlist}
                              itemIndex={itemIndex}
                              survey_id={surveyData.survey_id}
                              billing_url={surveyData.billing_url}
                              overwrite={props.overwrite}
                              email_content={surveyData.email_content}
                              email_attachment={surveyData.email_attachment}
                              setSurveyInvoicedata={props.setSurveyInvoicedata}
                              setREcall_Api={props.setREcall_Api}
                            />
                          )
                        )}
                      </Fragment>
                    ))}
                    {props.surveyInvoicedata.map((surveyData, dataIndex) => (
                      <Fragment key={dataIndex}>
                        {surveyData.additional_billing.map(
                          (itemlist, itemIndex) => (
                            <RowComponentAdditionbilling
                              key={itemIndex}
                              itemlist={itemlist}
                              itemIndex={itemIndex}
                              survey_id={surveyData.survey_id}
                              billing_url={surveyData.billing_url}
                              overwrite={props.overwrite}
                              email_content={surveyData.email_content}
                              email_attachment={surveyData.email_attachment}
                              setSurveyInvoicedata={props.setSurveyInvoicedata}
                              setREcall_Api={props.setREcall_Api}
                            />
                          )
                        )}
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    <div
                      class="alert alert-danger center-margin-container invoice_table_nodata"
                      role="alert"
                    >
                      No Data Found!
                    </div>
                  </>
                )}
              </div>

              <Row style={{ marginTop: "10px" }}>
                <Col lg={12} md={12} sm={12} xs={12} className="">
                  <label className="formlabelviewzone">Email Content</label>
                  <textarea
                    type="text"
                    className="CsformText"
                    style={{
                      height: "200px",
                    }}
                    readOnly
                    value={props.surveyInvoicedata[0].email_content}
                  />

                  <br />
                </Col>
              </Row>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Col lg={12} md={12} sm={12} xs={12} className="col-3">
            <button
              className="cbtn saveBtn m-0"
              onClick={() => {
                if (props.surveyInvoicedata[0].client_summary.length !== 0) {
                  handleconfirm_post(true);
                }
              }}
            >
              {isSubmitting && (
                <Spinner
                  style={{ color: "white" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Confirm Billing
            </button>

            <button
              className="cbtn saveBtn m-0 me-2"
              onClick={() => {
                if (props.surveyInvoicedata[0].client_summary.length !== 0) {
                  handleconfirm_post(false);
                }
              }}
            >
              Bill Later
            </button>
            <button
              className="cbtn saveBtn m-0 me-2"
              onClick={() => {
                props.setSurveyBilling(false);
                props.setProj_Invoiceids(true);
                props.setProjectInvoiceuploadids(true);
              }}
            >
              Previous
            </button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectsurveyBilling;
