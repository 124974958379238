import "./Bidstopheader.css";
import { useNavigate } from "react-router";
import { useState } from "react";

const Bidstopheader = (props) => {
  const clientid = props.clientid;

  const navigate = useNavigate();
  const [toggle, setToggle] = useState(props.tid);

  const changeNavi = (name) => {
    if (name === "Add Client Contact") {
      navigate("/Clientcontactform");
    }
    if (name === "Add Finance Detail") {
      navigate("/financeform");
    }
    if (name === "Add Client") {
      navigate("/addclient");
    }
    if (name === "Create Bid") {
      navigate("/create_bid");
    }
  };
  return (
    <>
      {props.headerlength === 9 ? (
        <></>
      ) : (
        <>
          <div className="Deatail_to_client">
            {toggle === "4" ? (
              <div
                className={toggle === "4" ? "tabs active-tabss" : "tabs"}
                onClick={() => {
                }}>
                Details
              </div>
            ) : (
              <>
                <div
                  className={toggle === "1" ? "tabs active-tabss" : "tabs"}
                  onClick={() => {
                    navigate("/clients");
                  }}>
                  Details
                </div>
                <div
                  className={toggle === "2" ? "tabs active-tabss" : "tabs"}
                  onClick={() => {
                    navigate(`/finance/${clientid}`);
                  }}>
                  Finance details
                </div>
                <div
                  className={toggle === "3" ? "tabs active-tabss" : "tabs"}
                  onClick={() => {
                    navigate(`/client-contact/${clientid}`);
                  }}>
                  Client contacts
                </div>
                <div
                  className={toggle === "5" ? "tabs active-tabss" : "tabs"}
                  onClick={() => {
                    navigate(`/region/${clientid}`);
                  }}>
                  Regions
                </div>
              </>
            )}
          </div>
          <hr style={{ marginTop: "0px" }} />
        </>
      )}
    </>
  );
};
export default Bidstopheader;
