import { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const BidMarginPercent = (props) => {
    const [percent, setPercent] = useState();
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            try {
                const response = await axiosPrivate.get(
                    '/lookup/get-bid-margin-perc',
                    {}
                );

                let data = response.data.all_status;
                setPercent(data);
            } catch (err) {
                console.error(err);
            }
        };
        getData();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {percent?.length ? (
                <>
                    {percent.map((data, i) => {
                        if (data?.name === props.selectedVal) {
                            return (
                                <option value={data?.name} key={i} selected>
                                    {' '}
                                    {data?.name}%
                                </option>
                            );
                        } else {
                            return (
                                <option value={data?.name} key={i}>
                                    {' '}
                                    {data?.name}%
                                </option>
                            );
                        }
                    })}
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default BidMarginPercent;
