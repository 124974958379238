import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import userIco from '../../assets/LoginIcons/users.svg';
import { useNavigate } from 'react-router';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ResetSubHeader from './ResetSubHeader/ResetSubHeader';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const axiosPrivate = useAxiosPrivate();
    var getToken = localStorage.getItem('token');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleSaveButton = async (e) => {
        if (e.NewName !== e.ConfirmPassword) {
            notifyerrorpassword();
        } else {
            setIsSubmitting(true);
            try {
                await axiosPrivate
                    .post(
                        '/user/reset-password',
                        JSON.stringify({
                            token: getToken,
                            password: e.ConfirmPassword,
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    .then((e) => {
                        if (e.statusText === 'OK') {
                            changepassword();
                        } else {
                        }
                    });
            } catch (err) {
                notifyerror(err.response.statusText);
            }
            setIsSubmitting(false);
        }
    };

    const changepassword = () => toast.success('Password changed succesfully');
    const notifyerror = (e) => toast.error(`${e} Error occured`);
    const notifyerrorpassword = () => toast.error('New Password & Confirm Password Not Match');

    return (
        <>
            <ResetSubHeader pname="resetpassword" />
            <div className="top_container">
                <div className="main_container ">
                    <form onSubmit={handleSubmit(handleSaveButton)}>
                        <div className="user_serachbox">
                            <div style={{ float: 'left' }}>
                                <h3 className="tableHeading">
                                    <img src={userIco} className="headingIcons" alt="Add" /> Change Password
                                </h3>
                            </div>
                            <div className="right-pane">
                                <button
                                    className="cbtn closeBtn"
                                    onClick={() => {
                                        navigate('/dashboard');
                                    }}
                                >
                                    {' '}
                                    Close
                                </button>

                                <button disabled={isSubmitting} className="cbtn saveBtn">
                                    {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                    &nbsp; Save
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="mainContent pdbtm">
                            <p className="small note">* denotes required fields</p>
                            <Row>
                                <Col lg={3} md={4} sm={6} xs={12} className="col">
                                    <label className="formlabel">New Password*</label>
                                    <input
                                        type="password"
                                        placeholder="New Password"
                                        className="formtext"
                                        {...register('NewName', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^.{6,15}$/, // Matches any string with length between 6 and 15 characters
                                                message: 'Password must be between 6 and 15 characters long',
                                            },
                                        })}
                                    />{' '}
                                    {errors.NewName && <small className="errmsg errmsg2">{errors.NewName.message}</small>}
                                </Col>
                                <Col lg={3} md={4} sm={6} xs={12} className="col">
                                    <label className="formlabel">Confirm Password*</label>
                                    <input
                                        type="text"
                                        placeholder="Confirm Password"
                                        className="formtext"
                                        {...register('ConfirmPassword', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^.{6,15}$/, // Matches any string with length between 6 and 15 characters
                                                message: 'Password must be between 6 and 15 characters long',
                                            },
                                        })}
                                    />{' '}
                                    {errors.ConfirmPassword && <small className="errmsg errmsg2">{errors.ConfirmPassword.message}</small>}
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default ResetPassword;
