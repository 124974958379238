import Qualityheader from "./Qualityheader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useState } from "react";

import pageIcon from "../../assets/BidsIcons/bids.svg";

import Pmerrorlist from "./Pmerrorlist";
import Parametererrorlist from "./Parametererrorlist";
const Projectqualityreport = () => {
  const [buttonSwitch, setButtonSwitch] = useState(1);

  return (
    <>
      <Qualityheader pname={"ProjectQualityReport"} />
      <div className="top_container">
        <div className="main_container">
          <div className="maindiv">
            <div className="left">
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                {buttonSwitch === 1 ? "PM Error list" : "Parameter Error list"}
              </h3>
            </div>

            <div className="right">
              <div className="date-range">
                <label style={{ display: "block" }} className="formlabel">
                  Date Range
                </label>
                <DateRangePicker
                  initialSettings={{
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                    startDate: "2022-01-11",
                    endDate: "2022-01-12",
                  }}
                >
                  <input type="text" className="projectDetail_date" />
                </DateRangePicker>
                <button
                  style={{
                    background: " #1a1a1a",
                    color: "white",
                    border: "none",
                    height: "30px",
                    border: "none",
                    borderRadius: "0px 4px 4px 0px",
                    lineHeight: "20px",
                  }}
                  onClick={() => {
                    setButtonSwitch(2);
                  }}
                >
                  {" "}
                  Search
                </button>
              </div>

              <div className="Error-list">
                <button
                  className={
                    buttonSwitch === 2 ? "cbtn saveBtn" : "cbtn closeBtn"
                  }
                  onClick={() => {
                    setButtonSwitch(2);
                  }}
                >
                  {" "}
                  Parameter Error list
                </button>

                <button
                  className={
                    buttonSwitch === 1 ? "cbtn saveBtn" : "cbtn closeBtn"
                  }
                  onClick={() => {
                    setButtonSwitch(1);
                  }}
                >
                  PM Error list
                </button>
              </div>
            </div>
          </div>
          <hr />
          {buttonSwitch === 1 ? (
            <>
              <Pmerrorlist />
            </>
          ) : (
            <>
              <Parametererrorlist />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Projectqualityreport;
