import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import './ESupplier.css';
import SpinnerLoader from '../../../UI/Spinner';
import Spinner from 'react-bootstrap/Spinner';
import { MultiSelect } from 'react-multi-select-component';
import Setupsupplierdiy from './Setupsupplierdiy';
const AddDiysupplier = (props) => {
    const axiosPrivate = useAxiosPrivate();
    const [selected, setSelected] = useState([]);
    const [selectedmulti, setSelectedMulti] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notes, setNotes] = useState();
    const [loder, setLoder] = useState(false);
    const [showaddsetup, setShowAddSetup] = useState(false);
    const [childId, setChildData] = useState();
    const [supplier_name, setSupplier_name] = useState('');
    const [diysupplier, setDiy_supplier] = useState(false);

    useEffect(() => {
        get_Supplierlist_api();
    }, []);

    useEffect(() => {
        if (props.requestStatusId !== undefined) {
            get_Supplierlist_api();
            setSelected([]);
            setNotes('');
        }
    }, [props.requestStatusId]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    const notifysucces = () => toast.success('Added supplier successfully');
    const notifyfail = (message) => toast.error(message);

    const get_Supplierlist_api = async () => {
        setLoder(true);
        try {
            const response = await axiosPrivate.get('supplyinfo/active-suppliers?supplier_type_id=1');
            setSupplierList([]);
            response.data.supplier.map((e) => {
                setSupplierList((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            // console.error(err);
        }
        setLoder(false);
    };

    const handleMultiSelectChange = (selectedOptions) => {
        const currentQuota = getValues('n');
        const currentSn = getValues('SN');
        const currentCpi = getValues('CPI') || {};
        selected.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentSn[option.value];
            }
        });

        selected.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentQuota[option.value];
            }
        });

        selected.forEach((option) => {
            if (!selectedOptions.includes(option)) {
                delete currentCpi[option.value];
            }
        });
        setSelected(selectedOptions);
    };

    const handleSaveButton = async (e, data) => {
        setIsSubmitting(true);
        const { CPI, SN, n } = e;
        let supplier;
        if (SN === undefined || null || '') {
            supplier = [];
        } else {
            supplier = Object.keys(CPI).map((supplierId) => ({
                supplier_id: supplierId,
                n: n[supplierId],
                supplier_cost: parseFloat(CPI[supplierId]),
            }));
        }
        let bodydata;
        if (supplier.length === 0) {
            bodydata = {
                survey_id: props.supplierId,
                supplier: supplier,
                notes: '',
            };
        } else if (supplier.length !== 0) {
            bodydata = {
                survey_id: props.supplierId,
                supplier: supplier,
                notes: '',
            };
        }

        if (bodydata !== undefined || supplier.length !== 0) {
            // setChildData("65e34f18b643a1eaec96ec8c");
            // setSupplier_name("Fulcrum SH1");
            // props.setRefresh_api(true);

            try {
                await axiosPrivate
                    .post(`survey-supplier/add-survey-supplier`, JSON.stringify(bodydata), {
                        headers: { 'Content-Type': 'application/json' },
                    })
                    .then((res) => {
                        if (res.status === 200) {
                            if (res.data.survey_supplier[0].id) {
                            }

                            setChildData(res.data.survey_supplier[0].id);
                            setSupplier_name(res.data.survey_supplier[0].supplier.name);

                            notifysucces();
                        }
                    });
            } catch (err) {
                if (err.response.data?.detail) {
                    notifyfail(err.response.data.detail[0].msg);
                } else {
                    notifyfail(err.response.data.return_status.message[0]);
                }
            }
        } else {
            notifyfail('Please add supplier');
        }

        setIsSubmitting(false);
    };

    const handleClose = () => {
        const currentQuota = getValues('n');
        const currentSn = getValues('SN');
        const currentCpi = getValues('CPI');
        selectedmulti.forEach((option) => {
            delete currentSn[option.value];
            delete currentSn[option.label];
        });

        selectedmulti.forEach((option) => {
            delete currentQuota[option.value];
        });

        selectedmulti.forEach((option) => {
            delete currentCpi[option.value];
        });

        setSelected([]);

        props.setAddmanule(false);
    };
    useEffect(() => {
        if (diysupplier === true) {
            handleClose();
            setDiy_supplier(false);
            props.setRefresh_api(true);
        }
    }, [diysupplier]);
    useEffect(() => {
        if (supplier_name !== '' && childId !== '') {
            setShowAddSetup(true);
        }
    }, [supplier_name, childId]);
    return (
        <>
            {showaddsetup ? (
                <>
                    <Setupsupplierdiy childId={childId} show={showaddsetup} onHide={() => setShowAddSetup(false)} setShowAddSetup={setShowAddSetup} supplier_name={supplier_name} setDiy_supplier={setDiy_supplier} />
                </>
            ) : (
                <></>
            )}

            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '16px' }}>
                        + Add DIY Supplier
                    </Modal.Title>
                </Modal.Header>
                {loder ? (
                    <>
                        <SpinnerLoader />
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Body>
                            <form onSubmit={handleSubmit(handleSaveButton)}>
                                <Row>
                                    <Col lg={4} md={12} sm={12} xs={12} className="csmulti">
                                        <label className="formlabelviewzone">Supplier Name </label>
                                        <div>
                                            <MultiSelect
                                                className=""
                                                options={supplierList}
                                                value={selected}
                                                onChange={(e) => {
                                                    handleMultiSelectChange(e);
                                                    setSelectedMulti(e);
                                                }}
                                                labelledBy={'Select'}
                                                isCreatable={true}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {selected.map((e, index) => (
                                    <>
                                        {' '}
                                        <div style={{ height: '15px' }}></div>
                                        <Row
                                            style={{
                                                backgroundColor: 'whitesmoke',
                                                margin: '2px',
                                                paddingTop: '10px',
                                                paddingBottom: '20px',
                                            }}
                                            className="addcss"
                                        >
                                            <Col lg={4} md={4} sm={4} xs={12} className="addsupply_col">
                                                <label className="formlabelviewzone">Supplier Name</label>
                                                <input
                                                    {...register(`SN.${e.value}`, {
                                                        required: 'This field is Required',
                                                    })}
                                                    placeholder="Cint"
                                                    className="formtextviewzone"
                                                    type="text"
                                                    defaultValue={e.label}
                                                    readOnly
                                                />
                                                {errors?.SN?.[e.value] && <small className="addnew_supply ">{errors.SN[e.value].message}</small>}
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={12} className="addsupply_col">
                                                <label className="formlabelviewzone">CPI</label>
                                                <input
                                                    {...register(`CPI.${e.value}`, {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9.]*$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="CPI"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors?.CPI?.[e.value] && <small className="addnew_supply">{errors.CPI[e.value].message}</small>}
                                            </Col>
                                            <Col lg={4} md={4} sm={4} xs={12} className="addsupply_col">
                                                <label className="formlabelviewzone">Quota</label>
                                                <input
                                                    {...register(`n.${e.value}`, {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9.]*$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="Quota"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors?.n?.[e.value] && <small className="addnew_supply">{errors.n[e.value].message}</small>}
                                            </Col>
                                        </Row>
                                    </>
                                ))}
                                {selected.length > 0 ? (
                                    <>
                                        {' '}
                                        <Col>
                                            <button
                                                className="cbtn saveBtn"
                                                style={{
                                                    marginTop: '20px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                {isSubmitting && (
                                                    <Spinner
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                                &nbsp;&nbsp; Submit
                                            </button>
                                        </Col>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </form>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
};

export default AddDiysupplier;
