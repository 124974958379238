import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef, useEffect } from 'react';
import addIcon from '../../../../assets/LoginIcons/add.svg';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { CheckCircle, Delete, ErrorRounded } from '@mui/icons-material';
import '../Invoice.css';

const InvoicBillingattach = (props) => {
    const [test, setTest] = useState([]);
    const [message, setMessage] = useState([]);
    const [randomNum, setRandomNumber] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null);

    const fileupload = async (file, onUploadProgress) => {
        let formData = new FormData();

        formData.append('file', file);

        formData.append('uid', `${randomNum}`);

        setTest((value) => [...value, file]);
        await axiosPrivate.post('/mailbox', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    };

    useEffect(() => {
        let x = Math.floor(Math.random() * 100000000000 + 1);
        setRandomNumber(x);
    }, []);

    const upload = async (idx, file) => {
        let _progressInfos = [...progressInfosRef.current.val];
        return fileupload(file, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            setProgressInfos((vale) => ({ ...vale, val: _progressInfos }));
        })
            .then(() => {
                setMessage((prevMessage) => [...prevMessage, 'success']);
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                setProgressInfos({ val: _progressInfos });
                setMessage((prevMessage) => [...prevMessage, 'error']);
            });
    };

    const uploadFiles = async (event) => {
        const files = Array.from(event.target.files);
        let merged = files.concat(test);
        merged = merged.filter((item, index) => {
            return merged.indexOf(item) === index;
        });

        let _progressInfos = merged.map((file) => ({
            percentage: 0,
            fileName: file.name,
        }));
        progressInfosRef.current = {
            val: _progressInfos,
        };

        const uploadPromises = merged.map((file, i) => upload(i, file));

        setMessage([]);
    };

    const deleteHandler = async (i, e) => {
        const del = progressInfos.val.filter((User, index) => i !== index);
        const el = progressInfos.val.filter((User, index) => i === index);

        setProgressInfos({ val: del });
        const bar = test.filter((User, index) => i !== index);
        setTest(bar);
    };

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px', paddingLeft: '3px' }}
                >
                    Billing attach{' '}
                    {/* Survey Billing: SC106647 - Project 3 (US Gen Pop 18+) */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className="">
                        <label className="formlabelviewzone">
                            Email Content
                        </label>
                        <textarea
                            name=""
                            id=""
                            className="invoice_emailcont"
                            placeholder=""
                        ></textarea>

                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={6} sm={6} xs={12} className="col-3">
                        <div className="attachmentContainer">
                            {progressInfos &&
                                progressInfos.val.length > 0 &&
                                progressInfos.val.map((progressInfo, index) => (
                                    <div
                                        key={index}
                                        className="Invoice_attachmentItem"
                                    >
                                        <div className="attachmentContent">
                                            <span className="invoice_fileInfo">
                                                {progressInfo.fileName}
                                            </span>
                                            {message[index] === 'success' ? (
                                                <CheckCircle className="success" />
                                            ) : (
                                                <ErrorRounded className="error" />
                                            )}

                                            <div className="progress">
                                                <div
                                                    className="progress-bar progress-bar-default"
                                                    role="progressbar"
                                                    aria-valuenow={
                                                        progressInfo.percentage
                                                    }
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{
                                                        width:
                                                            progressInfo.percentage +
                                                            '%',
                                                    }}
                                                >
                                                    {progressInfo.percentage}%
                                                </div>
                                            </div>
                                            <Delete
                                                className="deleteAttachment"
                                                title="Remove Attachment"
                                                onClick={(e) =>
                                                    deleteHandler(index, e)
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <label
                            className="cbtn addBtn"
                            style={{
                                cursor: 'pointer',
                                float: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            <img
                                src={addIcon}
                                className="headingIcons"
                                alt=""
                            />{' '}
                            Attachment
                            <input
                                type="file"
                                multiple
                                onChange={uploadFiles}
                                className="attachInput"
                            />
                        </label>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Col lg={12} md={12} sm={12} xs={12} className="col-3">
                    <button
                        className="cbtn saveBtn"
                        type="submit"
                        onClick={() => {
                            props.setInvoiceidsShow(false);
                        }}
                    >
                        Send{' '}
                    </button>
                    <button
                        className="cbtn saveBtn"
                        type="submit"
                        onClick={() => {
                            props.props.setSurveyBilling(true);
                        }}
                    >
                        Previous
                    </button>
                </Col>
            </Modal.Footer>
        </Modal>
    );
};

export default InvoicBillingattach;
