import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import Table from "react-bootstrap/Table";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
function SupplierInfo(props) {
  const axiosPrivate = useAxiosPrivate();
  const [loder, setLoder] = useState(false);
  const [supplierinfodata, setSupplierinfodata] = useState([]);

  useEffect(() => {
    if (props.surveyIdm) {
      const get_Supplierlist_api = async (pr) => {
        setLoder(false);
        try {
          const response = await axiosPrivate.get(
            `survey/get-survey-supplier/${pr}`
          );

          setSupplierinfodata(response.data.survey);
        } catch (err) {
          // console.error(err);
        }
        setLoder(true);
      };

      get_Supplierlist_api(props.surveyIdm);
    }
  }, [props.surveyIdm]);

  return (
    <Modal
      {...props}
      size="lg"
      style={{ width: "60%", marginLeft: "20%" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "14px", paddingLeft: "3px" }}
        >
          Supplier Information
        </Modal.Title>
      </Modal.Header>
      {loder ? (
        <>
          <Modal.Body>
             {supplierinfodata.length!==0?(<>
                 <Table bordered={false} hover size="sm" className="customTable">
              <thead
                className="thead"
                style={{
                  fontSize: "13px",
                }}
              >
               
                <tr>
                  <td>Supplier Name</td>
                  <td> CPI $</td>
                  <td> Quota(n)</td>
                </tr>
              </thead>
              <tbody>
                
                {supplierinfodata.map((iteml) => (
                  <tr>
                    <td>{iteml.supplier.name}</td>
                    <td>{iteml.supplier_cost}</td>
                    <td>{iteml.n}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
             </>):(<>No data found</>)}
        
          </Modal.Body>
        </>
      ) : (
        <SpinnerLoader />
      )}
    </Modal>
  );
}
export default SupplierInfo;
