import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
import "./SurveyListing.css";
import moment from "moment";
function SurveyLogModal(props) {
  const [logData, setLogData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [logdata_value, setLogData_Value] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    log_data();
  }, []);
  const log_data = async () => {
    setLoader(true);
    try {
      const response = await axiosPrivate.get(
        `survey/get-survey-logs/${props.survey_Id}`
      );
      if (response.data.length !== 0) {
        setLogData(response.data);
        setLogData_Value(
          response.data.flatMap((item) => {
            return Object.entries(item.log_data).map(([key, value]) => ({
              key,
              value,
            }));
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
    setLoader(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Survey Log
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="overflow-auto"
        style={{
          minHeight: "100px",
          maxHeight: "400px",
        }}
      >
        {loader === true ? (
          <SpinnerLoader />
        ) : logData.length === 0 ? (
          <div
            className="alert alert-danger center-margin-container"
            role="alert"
          >
            No Logs Found!
          </div>
        ) : (
          <>
            <>
              <table className="main_log_table">
                <thead className="thead">
                  <tr>
                    <th>Date</th>
                    <th>PM</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {logData.map((log_Item, logIndex) => (
                    <tr key={logIndex} className="main_log_tr">
                      <td>
                        {moment(log_Item.created_date).format(
                          "D/MM/YYYY h:mm A"
                        )}
                      </td>
                      <td>{log_Item.created_by}</td>
                      {logdata_value.map((logItem, index) => (
                        <React.Fragment key={index}>
                          <table className="log_table">
                            {" "}
                            <tr>
                              <td className="log_first_td">{logItem.key}</td>
                              <td>{logItem.value}</td>
                            </tr>
                          </table>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SurveyLogModal;
