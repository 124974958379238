import Modal from "react-bootstrap/Modal";
import "../Popup/RequestStatus.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MultiSelect } from "react-multi-select-component";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import Select from "react-select";

function RequestStatus(props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [formData, setFormData] = useState({});
  const [userStatus, setUserStatus] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const notifysucces = () =>
    toast.success("Request status/owner updated successfully");
  const [userDept, setUserDept] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    getId();
    requestStatus();
  }, []);
  useEffect(() => {
    setValue("Requestowner", selectedUser);
    setValue("Requeststatus", selected);
  }, [selected, selectedUser]);
  useEffect(() => {
    setSelected([]);
    setSelectedUser([]);
  }, [props.userId, props.id]);

  const getId = async () => {
    try {
      const response = await axiosPrivate.get(`/user/user-department/9`, {});

      const userdata = response.data.all_users.map((e, index) => ({
        value: e._id,
        label: e.name,
      }));

      setUserDept(userdata);
    } catch (err) {
      console.error(err);
      if (err.response.status === 404) {
        navigate("/404");
      }
    }
  };
  const requestStatus = async () => {
    try {
      const response = await axiosPrivate.get(
        `/lookup/supplier-request-status`,
        {}
      );

      const userdata = response.data.all_status.map((e, index) => ({
        value: e.id,
        label: e.name,
      }));

      setUserStatus(userdata);
    } catch (err) {
      console.error(err);
      if (err.response.status === 404) {
        navigate("/404");
      }
    }
  };
  const handleSaveButton = async (e, data) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        .put(
          `/patner-request/update-patner-request-status?survey_id=${props.userId}`,
          // patner-request/update-patner-request-status?request_partner_id

          JSON.stringify({
            request_status_id: e.Requeststatus.value,
            request_owner_id: e.Requestowner.value,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
            props.setSetting(false);
            props.setRefreshApi(true);
          }
        });
      setIsSubmitting(false);
    } catch (err) {}
  };
  return (
    <Modal
      {...props}
      size="lg"
      style={{ width: "50%", marginLeft: "25%" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Request Status/Owner
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleSaveButton)}>
          <Row className="addcss">
            <Col
              className="csmulti addsupply_col"
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label className="formlabelviewzone">Request Status*</label>

              <Select
                placeholder="Select..."
                className="pjt_select formtext"
                classNamePrefix="select"
                {...register("Requeststatus", {
                  required: "This field is Required",
                })}
                options={userStatus}
                value={selected}
                onChange={setSelected}
                noOptionsMessage={() => "No Data Found"}
              />
              {selected.length !== 0 ? (
                <></>
              ) : (
                <>
                  {errors.Requeststatus && (
                    <small className="addnew_supply">
                      {errors.Requeststatus.message}
                    </small>
                  )}
                </>
              )}
            </Col>

            <Col
              className="csmulti addsupply_col"
              lg={6}
              md={6}
              sm={12}
              xs={12}
            >
              <label className="formlabelviewzone">Request Owner*</label>

              <Select
                placeholder="Select..."
                className="pjt_select formtext"
                classNamePrefix="select"
                {...register("Requestowner", {
                  required: "This field is Required",
                })}
                options={userDept}
                value={selectedUser}
                onChange={setSelectedUser}
                noOptionsMessage={() => "No Data Found"}
              />
              {selectedUser.length !== 0 ? (
                <></>
              ) : (
                <>
                  {errors.Requestowner && (
                    <small className="addnew_supply">
                      {errors.Requestowner.message}
                    </small>
                  )}
                </>
              )}
            </Col>
          </Row>
          <div style={{ height: "10px" }}></div>
          <Col>
            <button
              className="cbtn saveBtn"
              style={{
                marginTop: "20px",
                marginRight: "10px",
              }}
            >
              {isSubmitting && (
                <Spinner
                  style={{ color: "white" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              &nbsp;&nbsp; Submit
            </button>
          </Col>
        </form>
      </Modal.Body>
    </Modal>
  );
}
export default RequestStatus;
