import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';

const Countries = (props) => {
  const [countries, setCountries] = useState();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    let isMounted = true;
    const getData = async () => {
      try {
        const response = await axiosPrivate.get('/lookup/country-list', {
        });
          let data = response.data.all_countries
          isMounted && setCountries(data);
           } catch (err) {
        console.error(err);
      }
    }
    getData();
    return () => {
      isMounted = false;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
 
  return (
   
    <>
        {countries?.length
        ? (
          <>           
            {countries.map((country, i) =>{
              if (country?._id === props.selectedVal) {
                return <option value={country?._id}  key={i} selected> {country?.country_name}</option>
              }else{
                return <option value={country?._id}  key={i}> {country?.country_name}</option>
              }              
            }                              
            )}
            </> 
        ) :
        ""   
      }
      </>  
  
  )

}

export default Countries