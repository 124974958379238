import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import "../PopUpPage/ErrorCreated.css";
import { useState } from "react";
const DisputedError = (props) => {
  const [clients, setClients] = useState({
    data: [
      { label: "viewzone", value: "date" },
      { label: "viewzone1", value: "date1" },
      { label: "viewzone2", value: "date2" },
      { label: "viewzone3", value: "date3" },
      { label: "viewzone4", value: "date4" },
      { label: "viewzone5", value: "date5" },
      { label: "viewzone6", value: "date6" },
    ],
    isLoading: true,
  });

  const handleChange = (e) => {};
  return (
    <>
      <Modal
        className="popupmodehere"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px" }}
          >
            Disputed Audit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="" lg={6} md={6} sm={6} xs={12}>
              <label className="formlabelviewzone">PM</label>
              <Select
                className="pjt_select formtext"
                placeholder="Select Option"
                classNamePrefix="select"
                options={clients.data}
                onChange={handleChange}
                noOptionsMessage={() => "No Data Found"}
              />
            </Col>
            <Col className="" lg={6} md={6} sm={6} xs={12}>
              <label className="formlabelviewzone">Error Title</label>
              <input
                className="formtextviewzone"
                type="text"
                placeholder="Error Title"
              />
            </Col>
          </Row>
          <Row>
            <Col className="" lg={12} md={12} sm={12} xs={12}>
              <label className="formlabelviewzone">PM Comment</label>
              <textarea
                style={{ height: "100px" }}
                className="formtextviewzone"
                type="text"
                placeholder="PM Comment"
              />
            </Col>
          </Row>
          <Row>
            <Col className="" lg={12} md={12} sm={12} xs={12}>
              <label className="formlabelviewzone">QA Comment</label>
              <textarea
                style={{ height: "100px" }}
                className="formtextviewzone"
                type="text"
                placeholder="QA Comment"
              />
            </Col>
          </Row>
          <div style={{ height: "10px" }}></div>
          <Row>
            <Col className="" lg={3} md={3} sm={3} xs={4}>
              <input
                id="agree"
                name="radio"
                value="a"
                type="radio"
                className="qulaity_check"
              />
              <label style={{ fontSize: "12px", margin: "5px" }}>Agree</label>
            </Col>
            <Col className="" lg={3} md={3} sm={3} xs={4}>
              <input
                id="disagree"
                name="radio"
                value="a"
                type="radio"
                className="qulaity_check"
              />
              <label style={{ fontSize: "12px", margin: "5px" }}>
                Disagree
              </label>
            </Col>
          </Row>
          <Row>
            <Col>
              <button className="cbtn saveBtn">Submit</button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DisputedError;
