import React from 'react'

import brandlogo from "../../assets/SamplicityWhiteLogo.svg";
const Common = () => {
  return (
    <div className="header_wrapper">
        <img className="samplicity_logo" src={brandlogo} alt="Samplicity" />
      </div>
  )
}

export default Common