import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./ExcelGrid.css"; // You can define your own styles
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import DeleteIcon from "../../../assets/LoginIcons/delete.svg";
import { parse } from "papaparse";
import moment from "moment";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import addIcon from "../../../assets/LoginIcons/add.svg";
import pageIcon from "../../../assets/BidsIcons/bids.svg";
import plusIcon from "../../../assets/CSicons/icons/add.svg";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import SpinnerLoader from "../../UI/Spinner";
import ExcelGrid from "./ExcelGrid";
import { Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
const Pricetype = (props) => {
  const [gridshow, setGridshow] = useState(false);

  const [refreshtable, setRefreshtable] = useState(false);
  const [pricegridshow, setPriceGridshow] = useState(false);
  const [loder, setLoder] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();

  const [fileName, setFileNmae] = useState("");
  const [sratecard, setSRateCard] = useState([]);
  const [priceGrid, setPriceGRid] = useState([]);

  // Initialize a 2D array to store cell values

  // Initialize grid data when the component mounts

  // Render the grid

  useEffect(() => {
    PriceList();
  }, [refreshtable]);

  const PriceList = async () => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `/supplyinfo/get-supplier-ratecard?supplier_id=${id}`
      );

      setSRateCard(response.data.ratecard);
      setRefreshtable(false);
      //   setDataLoader(false);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };
  const handleDelete = async (id) => {
    const payload = {
      sup_ratecard_id: id,
    };

    try {
      await axiosPrivate
        .delete(
          "/supplyinfo/delete-supplier-ratecard",
          { data: payload },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((e) => {
          if (e.status === 200) {
            PriceList();
          }
        });
    } catch (err) { }
  };

 
  const updateStatus = async (ratecardid, status) => {
    try {
      await axiosPrivate
        .patch(
          "/supplyinfo/update-supplier-ratecard-status",
          JSON.stringify({
            supplier_rate_card_id: ratecardid,
            new_status: status === true ? 1 : 0,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          if (e.data.return_status.status_id === 1) {
            toast.success("Ratecard status updated successfully");
            
          }
        });
    } catch (err) {
      //notifyerror(err.response.statusText);
      toast.error(err);
    }
  };

  return (
    <>
      {gridshow ? (
        <>
          <Pricetypepopup
            show={gridshow}
            onHide={() => setGridshow(false)}
            setGridshow={setGridshow}
            setRefreshtable={setRefreshtable}
          />
        </>
      ) : (
        <></>
      )}
      {pricegridshow ? (
        <>
          <ExcelGrid
            show={pricegridshow}
            onHide={() => setPriceGridshow(false)}
            priceGrid={priceGrid}
            setPriceGridshow={setPriceGridshow}
          />
        </>
      ) : (
        <></>
      )}

      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Rate card
              </h3>
            </div>
            <div className="right-pane">
              <button
                className="cbtn closeBtn"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
              >
                {' '}
                Close{' '}
              </button>


              <div className="supplyInputBox" style={{}}>

                <img
                  title="Add"
                  src={plusIcon}
                  alt="plusIcon"
                  onClick={() => {
                    setGridshow(true);
                  }}
                  className="supplyIconColr"
                  style={{
                    float: "right",
                    marginRight: "8px",
                    marginTop: "0px",
                    cursor: "pointer",
                  }}
                />
              </div>

            </div>

          </div>
          <hr />
          {loder ? (
            <>
              <div className="mainContent mainContent2">
                <Table
                  bordered={false}
                  hover
                  size="sm"
                  className="customTable customTable2"
                >
                  <thead className="thead">
                    <tr>
                      <td>Supplier</td>
                      <td>Price Type</td>
                      <td>Price </td>
                      <td>Created Date</td>
                      <td>Status</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  {sratecard.length !== 0 ? (
                    <>
                      <tbody>
                        {sratecard.map((rateitem) => (
                          <>
                            <tr>
                              <td>{rateitem.supplier.name}</td>
                              <td>{rateitem.price_type.name}</td>
                              <td>
                                {rateitem.price_type.name === "Price Grid" ? (
                                  <a
                                    // href="*"
                                    onClick={() => {
                                      setPriceGRid(rateitem.price);

                                      setPriceGridshow(true);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  >
                                    View Price
                                  </a>
                                ) : rateitem.price_type.name ===
                                  "Revenue Share" ? (
                                  <>{rateitem.price}%</>
                                ) : (
                                  <> &#36; {rateitem.price}</>
                                )}
                              </td>
                              <td>
                                {moment(rateitem.created_at).format(
                                  "D/MM/YYYY"
                                )}
                              </td>
                              <td>
                                <BootstrapSwitchButton
                                  className="switch btn on btn-outline-success btn-xs"
                                  onChange={(e) => {
                                    updateStatus(rateitem._id, e);
                                  }}
                                  size="xs"
                                  width={80}
                                  onlabel="Active"
                                  offlabel="Inactive"
                                  onstyle="outline-success"
                                  offstyle="outline-danger"
                                  checked={rateitem.status.id === 0 ? false : true}
                                />
                              </td>
                              <td>
                                {rateitem.price_type.name === "Price Grid" ? (
                                  <>
                                    <img
                                      title="Delete"
                                      src={DeleteIcon}
                                      alt="DeleteIcon"
                                      className="SupIcon"
                                      onClick={() => {
                                        confirmAlert({
                                          message: "Are you sure to delete.",
                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () =>
                                                handleDelete(rateitem._id),
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                        });
                                      }}
                                    />
                                    <div></div>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      title="Delete"
                                      src={DeleteIcon}
                                      alt="DeleteIcon"
                                      srcset=""
                                      className="SupIcon"
                                      onClick={() => {
                                        confirmAlert({
                                          message: "Are you sure to delete.",
                                          buttons: [
                                            {
                                              label: "Yes",
                                              onClick: () =>
                                                handleDelete(rateitem._id),
                                            },
                                            {
                                              label: "No",
                                            },
                                          ],
                                        });
                                      }}
                                    />
                                    <img
                                      title="Edit"
                                      src={EditIcon}
                                      alt="EditIcon"
                                      srcset=""
                                      className="SupIcon"
                                      onClick={() => { }}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <>
                      <div>No Record Found</div>
                    </>
                  )}
                </Table>
              </div>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </div>
      </div>
    </>
  );
};

const Pricetypepopup = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pricetype, setPriceType] = useState([]);
  const [selectedPT, setSelectedPT] = useState([]);
  const [deleteselected, setDeleteselected] = useState([]);
  const [pgstatus, setPGStatus] = useState();
  const [fp, setFP] = useState();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const { id } = useParams();
  const [fileName_status, setFileName_status] = useState(true);
  const [vr_status, setVR_status] = useState(true);
  const [fileName, setFileNmae] = useState("");
  const [vir, setVIR] = useState();
  const [frinput, setFrINPUT] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = useForm();
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const get_ProjectType_api = async () => {
    try {
      const response = await axiosPrivate.get(`/lookup/price-type`);
      setPriceType([]);
      response.data.all_status.map((e) => {
        setPriceType((val) => [...val, { label: e.name, value: e.id }]);
      });

      //   setDataLoader(false);
    } catch (err) {
      console.error(err);
    }
  };
  const csvfileDataLoading = (e) => {
    const fileName = e.target.files[0];
    setFileNmae(fileName);
    setFileName_status(true);

    const file = e.target.files[0];
    parse(file, {
      complete: (results) => {
        csvErrorHnadling(results);
      },
      header: true,
      dynamicTyping: true,
    });
  };
  const csvErrorHnadling = (data) => {
    var keys = Object.keys(data.data[0]);
    var dataSHow = data.data.slice(0, 5);
  };

  const postData = async (e) => {
    if (fileName !== "") {
      setIsSubmitting(true);
      const newFormdata = new FormData();
      newFormdata.append("file", fileName);

      let IRV;
      let priceV;
      let VIR;
      if (frinput === "" || frinput === null || frinput === undefined) {
        IRV = 0;
      } else {
        IRV = parseInt(frinput);
      }
      if (
        e.pricetype === "" ||
        e.pricetype === null ||
        e.pricetype === undefined
      ) {
        priceV = 0;
      } else {
        priceV = e.pricetype;
      }
      if (vir === "" || vir === null || vir === undefined) {
        VIR = 0;
      } else {
        VIR = parseInt(vir);
      }

      try {
        await axiosPrivate
          .put(
            `/supplyinfo/supplier-ratecard?price_type=${selectedPT.value}&price=${IRV}&is_ir_vertical=${VIR}&supplier_id=${id}`,

            newFormdata,
            {
              headers: {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((e) => {
            if (e.status === 200) {
              toast.success("Ratecard added successfully");
              props.setRefreshtable(true);
              props.setGridshow(false);
            }
          });
      } catch (err) { }

      setIsSubmitting(false);
    } else if (selectedPT.value !== 3) {
      setIsSubmitting(true);
      const newFormdata = new FormData();
      newFormdata.append("file", fileName);

      let IRV;
      let priceV;
      let VIR;
      if (frinput === "" || frinput === null || frinput === undefined) {
        IRV = 0;
      } else {
        IRV = parseInt(frinput);
      }
      if (
        e.pricetype === "" ||
        e.pricetype === null ||
        e.pricetype === undefined
      ) {
        priceV = 0;
      } else {
        priceV = e.pricetype;
      }
      if (vir === "" || vir === null || vir === undefined) {
        VIR = 0;
      } else {
        VIR = parseInt(vir);
      }

      try {
        await axiosPrivate
          .put(
            `/supplyinfo/supplier-ratecard?price_type=${selectedPT.value}&price=${IRV}&is_ir_vertical=${VIR}&supplier_id=${id}`,

            newFormdata,
            {
              headers: {
                accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((e) => {
            if (e.status === 200) {
              toast.success("Ratecard added successfully");
              props.setRefreshtable(true);
              props.setGridshow(false);
            }
          });
      } catch (err) { }

      setIsSubmitting(false);
    } else {
      setFileName_status(false);
      setVR_status(false);
    }
  };
  const handleShow = () => {
    setFrINPUT("");
    setSelectedPT([]);
  };

  const handleClose = () => {
    setSelectedPT([]);
    props.setGridshow(false);
    reset();
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onShow={handleShow}
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "3px" }}
          >
            Price Type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="csmulti">
                <Select
                  placeholder="Select..."
                  className="pjt_select formtext"
                  classNamePrefix="select"
                  options={pricetype}
                  value={selectedPT}
                  onChange={(e) => {
                    setSelectedPT(e);
                    setDeleteselected(e);
                  }}
                  noOptionsMessage={() => "No Data Found"}
                />
              </Col>
            </Row>
            <Row
              style={{
                paddingTop: "5px",
                paddingBottom: "10px",
              }}
            >
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={
                  selectedPT.value === 1 || selectedPT.value === 2
                    ? "addbg"
                    : ""
                }
              >
                {selectedPT.value === 1 || selectedPT.value === 2 ? (
                  <form onSubmit={handleSubmit(postData)}>
                    <div
                      style={{
                        backgroundColor: "#f0efef",
                        padding: "10px 15px 10px 15px ",
                      }}
                    >
                      <input
                        placeholder={
                          selectedPT.value === 1
                            ? "Flate Rate"
                            : "Revenue Share"
                        }
                        className="formtextviewzone"
                        type="text"
                        {...register(`FandR.${selectedPT.value}`, {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        onChange={(e) => {
                          setFrINPUT(e.target.value);
                          setValue(`FandR.${selectedPT.value}`, e.target.value);
                        }}
                        defaultValue={frinput}
                      />

                      {errors?.FandR?.[selectedPT.value] && (
                        <small className="errmsg rateCardValFix addnew_supply">
                          {errors.FandR[selectedPT.value].message}
                        </small>
                      )}
                    </div>
                    <button
                      className="cbtn saveBtn"
                      style={{
                        marginRight: "0px",
                      }}
                      type="submit"
                    >
                      {isSubmitting && (
                        <Spinner
                          style={{ color: "white" }}
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      &nbsp;&nbsp; Submit
                    </button>
                  </form>
                ) : selectedPT.value === 3 ? (
                  <>
                    <form onSubmit={handleSubmit(postData)}>
                      <div
                        style={{
                          backgroundColor: "#f0efef",
                          padding: "10px 15px 40px 15px ",
                        }}
                      >
                        <select
                          className="formtext"
                          placeholder="Vertical IR"
                          {...register("VIr", {
                            // required: "This field is Required",
                          })}
                          onChange={(e) => {
                            // virticall_select.onChange(e);
                            setVIR(e.target.value);
                          }}
                          value={vir}
                        >
                          <option value="1">YES</option>
                          <option value="0">NO</option>
                        </select>
                        {/* {vr_status === false ? (
                          <>
                            <small className="errmsg">
                              "This field is Required"
                            </small>
                          </>
                        ) : (
                          <></>
                        )} */}
                        {/* {errors.VIr && (
                          <small className="errmsg">{errors.VIr.message}</small>
                        )} */}

                        <label
                          className="cbtn addBtn"
                          id="uploadMargin"
                          style={{
                            marginTop: "5px",
                          }}
                        >
                          <img src={addIcon} className="headingIcons" alt="" />{" "}
                          Upload File
                          <input
                            type="file"
                            accept=".csv"
                            onChange={(e) => {
                              csvfileDataLoading(e);
                            }}
                            className="attachInput"
                          />
                        </label>
                        {fileName_status === false ? (
                          <>
                            <small className="screening_errormsg">
                              Please upload the file
                            </small>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <button
                        className="cbtn saveBtn mt-3"
                        style={{
                          marginRight: "0px",
                        }}
                        type="submit"
                      >
                        {isSubmitting && (
                          <Spinner
                            style={{
                              color: "white",
                            }}
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                        &nbsp;&nbsp; Submit
                      </button>
                    </form>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {/* <button
                className="cbtn saveBtn"
                style={{
                  marginRight: "0px",
                }}
                type="submit"
              >
                {isSubmitting && (
                  <Spinner
                    style={{ color: "white" }}
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                &nbsp;&nbsp; Submit
              </button> */}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Pricetype;
