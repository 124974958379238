import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

import { useForm } from "react-hook-form";
import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
const Addagequota = (props) => {
  const [spinner, setSpinner] = useState(false);
  const notifySuccess = (e) => toast.success(`Age quota added successfully`);
  const [loder, setLoder] = useState(true);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const addSubmit = () => {
    if (totalPercentage == 100) {
      setSpinner(true);
      const allocatedAnsCode = [];
      const ansCode_send = [];

      // Iterate over addQuotaValues array
      props.addData.ansCode.forEach((item, index) => {
        // Push each item as an object to the allocatedAnsCode array
        allocatedAnsCode.push({
          min_age: parseInt(item.min_age),
          max_age: parseInt(item.max_age),
          status: 1,
          perc: parseInt(item.value),
        });
      });
      props.addData.ansCode.forEach((item, index) => {
        // Push each item as an object to the allocatedAnsCode array
        ansCode_send.push({
          min_age: parseInt(item.min_age),
          max_age: parseInt(item.max_age),
          status: 1,
        });
      });

      const url = "qualification/update-age-qualification";

      const requestBody = {
        qualify_id: props.addData.qualification_id,
        q_id: props.addData.q_id,
        ans_code: ansCode_send,
        is_allocated: true,
        allocated_ans_code: allocatedAnsCode,
        is_all_selected: true,
        order_by: 4,
      };

      axiosPrivate
        .post(url, requestBody)
        .then((response) => {
          if (response.status === 200) {
            props.setAgeQual_data((prevData) => {
              // Map over the previous data array
              return prevData.map((item, itemIndex) => {
                // If the qualification ID matches, update the is_allocated field
                if (item.qualification_id === props.addData.qualification_id) {
                  return { ...item, is_allocated: true };
                }
                return item; // Otherwise, return the item unchanged
              });
            });
            props.addData.ansCode.map((item, Item_index) => {
              const updatedValues = [...props.addData.ansCode];
              updatedValues[Item_index].value = "";
            });
            notifySuccess();
            props.setAdd_AgeQuotapopup(false);
          }
          setSpinner(false);
        })
        .catch((error) => {
          setSpinner(false);
          // Handle error
          console.error("Error:", error);
        });
    }
  };

  // Function to handle change in input values
  const handleInputChange = (event, index) => {
    if (
      parseFloat(event.target.value) < 0 ||
      parseFloat(event.target.value) > 100
    ) {
      event.target.value = "";
    } else {
      const newValue = parseFloat(event.target.value);
      const updatedValues = [...props.addData.ansCode];
      updatedValues[index].value = newValue;

      // Calculate the total sum
      let sum = 0;
      updatedValues.forEach((item) => (sum += parseFloat(item.value)));

      // Set total sum and check if it's 100%
      setTotalPercentage(sum);
    }
  };
  const handleClose = () => {
    props.addData.ansCode.map((item, Item_index) => {
      const updatedValues = [...props.addData.ansCode];
      updatedValues[Item_index].value = "";
    });

    props.setAdd_AgeQuotapopup(false);
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs-16">
            Add Quota - Age
          </Modal.Title>
        </Modal.Header>
        {loder ? (
          <>
            <form onSubmit={handleSubmit(addSubmit)}>
              <Modal.Body>
                <Row className="mb-2">
                  <Col lg={6} md={6} sm={6} xs={6}>
                    Age
                  </Col>

                  <Col lg={6} md={6} sm={6} xs={6} className="col">
                    <label className="formlabel m-0">Completes %</label>
                  </Col>
                </Row>

                {props.addData.ansCode.length === 1 ? (
                  <>
                    {props.addData.ansCode.map(
                      (addValuesItem, addValueIndex) => (
                        <Row key={addValueIndex} className="mb-2">
                          <Col lg={6} md={6} sm={6} xs={6} className="col">
                            <label className="formlabel m-0">
                              {addValuesItem.min_age}-{addValuesItem.max_age}
                            </label>
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={6} className="col">
                            <input
                              type="number"
                              min="0"
                              max="100"
                              placeholder="Age Perc"
                              className="formtextviewzone m-0"
                              defaultValue={addValuesItem.value}
                              onChange={(e) =>
                                handleInputChange(e, addValueIndex)
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[.,]/g,
                                  ""
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    )}
                    {totalPercentage !== 100 && (
                      <div className="error-message errmsg">
                        Total percentage must be 100%
                      </div>
                    )}
                  </>
                ) : props.addData.ansCode.length > 1 ? (
                  <>
                    {props.addData.ansCode.map(
                      (addValuesItem, addValueIndex) => (
                        <Row key={addValueIndex} className="mb-2">
                          <Col lg={6} md={6} sm={6} xs={6} className="col">
                            <label className="formlabel m-0">
                              {addValuesItem.min_age}-{addValuesItem.max_age}
                            </label>
                          </Col>
                          <Col lg={6} md={6} sm={6} xs={6} className="col">
                            <input
                              // placeholder={`${addValuesItem.min_age} - ${addValuesItem.max_age}`}
                              className="formtextviewzone m-0"
                              placeholder="Age Perc"
                              type="number"
                              min="0"
                              max="99"
                              defaultValue={addValuesItem.value}
                              onChange={(e) =>
                                handleInputChange(e, addValueIndex)
                              }
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[.,]/g,
                                  ""
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      )
                    )}
                    {totalPercentage !== 100 && (
                      <div className="error-message errmsg">
                        Total percentage must be 100%
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="cbtn closeBtn pbtn m-0 me-2"
                  onClick={(e) => {
                    handleClose();

                    props.setAdd_AgeQuotapopup(false);
                  }}
                >
                  Close
                </button>
                <button className="cbtn saveBtn m-0" type="submit">
                  {spinner === true && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  &nbsp; Submit
                </button>
              </Modal.Footer>
            </form>
          </>
        ) : (
          <SpinnerLoader />
        )}
      </Modal>
    </>
  );
};

export default Addagequota;
