import Header from '../../../UI/Header/Header';
import { useNavigate, useParams } from 'react-router';
import userIco from '../../../../assets/BidsIcons/client.svg';

import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BidsSubHeader from '../../../BidsModule/BidsSubHeader/BidsSubHeader';
import { useState } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import toast from 'react-hot-toast';
import Bidstopheader from '.././Bidstopheader';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Bidstopheaderselect from '../../BidsTable/BidsTopHeaderSelect';
const AddRegion = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    var localData = localStorage.getItem('clientname');
    const axiosPrivate = useAxiosPrivate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const handleSaveButton = async (e) => {
        setIsSubmitting(true);
        try {
            await axiosPrivate
                .post(
                    'client/add-client-region',
                    JSON.stringify({
                        client_id: id,
                        name: e.Name,
                        status: 1,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((e) => {
                    if (e.statusText === 'OK') {
                        clientadded();
                        navigate(-1);
                    } else {
                    }
                });
        } catch (err) {
            notifyerror(err.response.statusText);
        }
        setIsSubmitting(false);
    };

    const notifyerror = (e) => toast.error(`${e} Error occured`);
    const clientadded = () => toast.success('Region added successfully');

    return (
        <>
            <BidsSubHeader pname="region" />
            <form onSubmit={handleSubmit(handleSaveButton)}>
                <div className="top_container">
                    <div className="main_container ">
                        <div className="user_serachbox">
                            <div style={{ float: 'left' }}>
                                <h3 className="tableHeading">
                                    <img src={userIco} className="headingIcons" alt="" /> {localData}
                                </h3>
                            </div>
                            <div className="right-pane">
                                <button
                                    className="cbtn closeBtn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(-1);
                                    }}
                                >
                                    {' '}
                                    Close{' '}
                                </button>

                                <button className="cbtn saveBtn">
                                    {' '}
                                    {isSubmitting && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                    &nbsp;Save
                                </button>
                            </div>
                        </div>
                        <hr className="showingHeader" />

                        {/* <Bidstopheader tid="5" clientid={id} /> */}
                        <Bidstopheader clientid={id} tid="5" className="showingHeader" />
                        <Bidstopheaderselect clientid={id} namevalue={'region'} />
                        <div className="mainContent">
                            <Row>
                                <Col lg={3} md={4} sm={6} xs={12} className="col">
                                    <label className="formlabel">Region*</label>
                                    <input
                                        type="text"
                                        placeholder="Enter Region"
                                        className="formtext"
                                        {...register('Name', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ " "]*$/,
                                                message: 'Only letters are allowed',
                                            },
                                        })}
                                    />
                                    {errors.Name && <small className="errmsg">{errors.Name.message}</small>}
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default AddRegion;
