import './Filter.css';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MultiSelect } from 'react-multi-select-component';
import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';
import SpinnerLoader from '../../../UI/Spinner';
import { useParams } from 'react-router';
import toast from 'react-hot-toast';
const Filter = ({ sendDataToParent, setSurveyId, selectedelem, reRender, setReRender }) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [selected, setSelected] = useState([]);
    const [selecteduser, setSelectedUser] = useState([]);
    const [clientListStorage, setClientListStorage] = useState([]);
    const [projectStatusStorage, setProjetStatusStorage] = useState([]);
    const [apiFilterData, setApiFilterData] = useState([]);
    const [status_structure, setStatus_structure] = useState('');
    const [filter_use, setFilter_user] = useState('');
    const [activeSections, setActiveSections] = useState([]);
    const [childactive, setChildActive] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [pPManagerStorage, setPpManagerStorage] = useState([]);
    const { layoutId } = useParams();

    useEffect(() => {
        get_ClintList_api();
        get_pPManager_api();
        get_ProjectStatus_api();
        get_Client_filter_api();
    }, []);

    useEffect(() => {
        get_Client_filter_api();
        setReRender(false);
    }, [status_structure, filter_use, reRender]);

    useEffect(() => {
        handleStarReq(selectedelem);
    }, [selectedelem]);

    const handleStarReq = async (id) => {
        if (id !== undefined) {
            let eleement = document.getElementById('item' + id);
            if (eleement) {
                document.querySelectorAll('.survey_selected').forEach((el) => el.classList.remove('colorFilterselected'));
                eleement.classList.add('colorFilterselected');
            }
        }
    };

    const handleShowSection = (eid) => {
        let activeRow = document.getElementById(eid);
        document.querySelectorAll('.dropeTablefilter').forEach((el) => el.classList.remove('open'));

        if (activeRow.classList.contains('active')) {
            activeRow.classList.remove('active');
        } else {
            document.querySelectorAll('.mainrow').forEach((el) => el.classList.remove('active'));
            activeRow.classList.add('active');
            document.querySelector('.' + eid).classList.add('open');
        }

        const index = activeSections.indexOf(eid);
        if (index === -1) {
            setActiveSections([eid]);
        } else {
            activeSections.splice(index, 1);
            setActiveSections([activeSections]);
        }
    };

    const handleParents = (eid) => {
        let activeRow = document.getElementById(eid);

        document.querySelectorAll('.droptablechild1').forEach((el) => el.classList.remove('open'));

        if (activeRow.classList.contains('active')) {
            activeRow.classList.remove('active');
        } else {
            document.querySelectorAll('.mainrowchild1').forEach((el) => el.classList.remove('active'));
            activeRow.classList.add('active');
            document.querySelector('.' + eid).classList.add('open');
        }

        const index = childactive.indexOf(eid);
        if (index === -1) {
            setChildActive([eid]);
        } else {
            childactive.splice(index, 1);
            setChildActive([childactive]);
        }
    };

    const get_ClintList_api = async () => {
        try {
            const response = await axiosPrivate.get('client/client-list');

            response.data.all_client.map((e) => {
                setClientListStorage((val) => [...val, { label: e.name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
        }
    };

    const get_ProjectStatus_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-project-status');
            setProjetStatusStorage([]);
            response.data.all_status.map((e) => {
                setProjetStatusStorage((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
        }
    };

    const get_Client_filter_api = async () => {
        setSpinner(true);
        const response = await axiosPrivate.get(`client/filter-client?query_filter=users%3D${filter_use}%26survey_status%3D${status_structure}`);

        setApiFilterData(response.data.project);
        setSpinner(false);
    };

    const handleUpdateUrl = (projectID, surveyID) => {
        navigate(`/project-detail/${projectID}/${layoutId}/${surveyID}`);
    };

    const sendData = (projectID, surveyID) => {
        sendDataToParent(projectID, surveyID);
        handleUpdateUrl(projectID, surveyID);
    };

    const handleStatus = async (e) => {
        const data = e.map((prop) => prop.value);
        const userString = `${data.join(',')}`;
        setStatus_structure(userString);
    };

    const handleFilterUser = async (e) => {
        const data = e.map((prop) => prop.value);
        const userString = `${data.join(',')}`;
        setFilter_user(userString);
    };

    const get_pPManager_api = async () => {
        try {
            const response = await axiosPrivate.get('user/all-users');
            response.data.all_users.map((e) => {
                setPpManagerStorage((val) => [...val, { label: e.first_name + ' ' + e.last_name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const notifyerror = (e) => toast.error(`${e} Error occured`);

    return (
        <>
            <div className="fbs">Filter by Status</div>
            <div className="csmulti">
                <div style={{ position: 'relative' }}>
                    <MultiSelect
                        className="multiselectoption "
                        style={{ width: '29px' }}
                        options={projectStatusStorage}
                        value={selecteduser}
                        onChange={(e) => {
                            setSelectedUser(e);
                            handleStatus(e);
                        }}
                        labelledBy="Filter by Status"
                        isCreatable={true}
                    />
                </div>
                <div className="gap"></div>
                <div className="fbs">Filter by user</div>
                <div style={{ position: 'relative' }} className="">
                    <MultiSelect
                        className="multiselectoption "
                        style={{ width: '29px' }}
                        options={pPManagerStorage}
                        value={selected}
                        onChange={(e) => {
                            handleFilterUser(e);
                            setSelected(e);
                        }}
                        labelledBy="Filter by user"
                        isCreatable={true}
                    />
                </div>
                <div className="fbs clf" style={{ position: 'relative' }}>
                    <p className="clientFilter">Client Filter</p>
                </div>
                <>
                    <div className="optioncontainer">
                        <input
                            type="search"
                            style={{
                                width: '100%',
                            }}
                            placeholder="Client Filter"
                        />
                        {spinner ? (
                            <>
                                {' '}
                                <SpinnerLoader />
                            </>
                        ) : (
                            <>
                                {' '}
                                {apiFilterData.map((e, index) => (
                                    <>
                                        <div
                                            onClick={() => {
                                                handleShowSection(`iconSect${e._id}`);
                                            }}
                                            className="filterHeading mainrow"
                                            id={`iconSect${e._id}`}
                                            style={{ position: 'relative' }}
                                        >
                                            <p className="FilterPtag">{e.name}</p>
                                            <KeyboardArrowUpIcon
                                                className={`azurUpIcon icon-${e._id}-up`}
                                                id="updownIcon"
                                                style={{
                                                    display: activeSections.includes(`iconSect${e._id}`) ? 'block' : 'none',
                                                }}
                                            />
                                            <KeyboardArrowDownIcon
                                                KeyboardArrowUpIcon
                                                className={`azurDownIcon icon-${e._id}-down`}
                                                id="updownIcon"
                                                style={{
                                                    display: activeSections.includes(`iconSect${e._id}`) ? 'none' : 'block',
                                                }}
                                            />
                                        </div>
                                        <>
                                            <div className={`dropeTablefilter  Dynatasc iconSect${e._id}`}>
                                                <div>
                                                    {e.project.map((prop) => (
                                                        <div>
                                                            <div
                                                                id={`child1set${prop._id}`}
                                                                className="mainrowchild1 sc10 childrop"
                                                                style={{
                                                                    position: 'relative',
                                                                }}
                                                            >
                                                                {prop.project_status.name == 'Live' ? (
                                                                    <>
                                                                        <div className="linegreen">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {prop.project_status.name == 'Pending' ? (
                                                                    <>
                                                                        <div className="linegreenPending">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {prop.project_status.name == 'Test Mode' ? (
                                                                    <>
                                                                        <div className="linegreenTest">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {prop.project_status.name === 'Paused' ? <div className="linegreenPaused">.</div> : <></>}
                                                                {prop.project_status.name === 'Id Awaited' ? (
                                                                    <>
                                                                        <div className="linegreenAwaited">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {prop.project_status.name === 'Billed' ? (
                                                                    <>
                                                                        <div className="linegreenBilled">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                {prop.project_status.name === 'Cancelled' ? (
                                                                    <>
                                                                        <div className="linegreenCancelled">.</div>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <div>
                                                                    <div className={`SC106647 ${childactive.includes(`child1set${prop._id}`) ? 'active' : ''}`}>
                                                                        <span
                                                                            onClick={() => {
                                                                                sendData(prop._id, prop.surveychildren[0]._id);

                                                                                setSurveyId('');
                                                                            }}
                                                                            className="projectName"
                                                                        >
                                                                            {prop.project_name}
                                                                        </span>

                                                                        <KeyboardArrowUpIcon
                                                                            className={`scUpIcon child1set${prop._id}-up`}
                                                                            onClick={() => {
                                                                                handleParents(`child1set${prop._id}`);
                                                                            }}
                                                                            style={{
                                                                                display: childactive.includes(`child1set${prop._id}`) ? 'block' : 'none',
                                                                            }}
                                                                        />

                                                                        <KeyboardArrowDownIcon
                                                                            className={`scDownIcon child1set-${prop._id}-down`}
                                                                            onClick={() => {
                                                                                handleParents(`child1set${prop._id}`);
                                                                            }}
                                                                            style={{
                                                                                display: childactive.includes(`child1set${prop._id}`) ? 'none' : 'block',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <>
                                                                <div className={`parentsContant droptablechild1 child1set${prop._id}`}>
                                                                    {prop.surveychildren.map((event, surveyIndex) => (
                                                                        <>
                                                                            <div
                                                                                id={'item' + prop.surveychildren[surveyIndex]._id}
                                                                                className="sc101 survey_selected"
                                                                                style={{
                                                                                    position: 'relative',
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => {
                                                                                    sendData(prop._id, prop.surveychildren[surveyIndex]._id);
                                                                                    setSurveyId(prop.surveychildren[surveyIndex]._id);
                                                                                    handleStarReq(prop.surveychildren[surveyIndex]._id);
                                                                                }}
                                                                            >
                                                                                {event.survey_status.name === 'Live' && <FiberManualRecordIcon className="green-dot" />}

                                                                                {event.survey_status.name === 'Pending' && <FiberManualRecordIcon className="yellow-dot" />}

                                                                                {event.survey_status.name === 'Test Mode' && <FiberManualRecordIcon className="blue-dot" />}

                                                                                {event.survey_status.name === 'Paused' && <FiberManualRecordIcon className="orange-dot" />}

                                                                                {event.survey_status.name === 'Id Awaited' && <FiberManualRecordIcon className="gray-dot" />}

                                                                                {event.survey_status.name === 'Billed' && <FiberManualRecordIcon className="purple-dot" />}

                                                                                {event.survey_status.name === 'Cancelled' && <FiberManualRecordIcon className="red-dot" />}

                                                                                <span className="usgen">{event.survey_name}</span>
                                                                            </div>
                                                                        </>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    </>
                                ))}
                            </>
                        )}
                    </div>
                </>
            </div>
        </>
    );
};
export default Filter;
