import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import SpinnerLoader from '../../UI/Spinner';

import { useEffect } from 'react';
import { useState } from 'react';
import { ModalBody } from 'react-bootstrap';
function Editsupplyquality(props) {
    const axiosPrivate = useAxiosPrivate();
    const [projDetail, setProjDetail] = useState([]);
    const [surveyDetail, setSurveyDetail] = useState([]);
    const [loder, setLoder] = useState(false);

    useEffect(() => {
        if (props.sid !== undefined) {
            get_ProjectDetails_Api();
        }
    }, [props.sid]);
    const get_ProjectDetails_Api = async () => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(
                `patner-request/get-request-survey-detail?survey_id=${props.sid}`
            );
            setProjDetail(response.data.survey.project_details);
            setSurveyDetail(response.data.survey.survey_details);

            if (response == []) {
            } else {
                setLoder(true);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        // setLoder(true);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px', paddingLeft: '3px' }}
                >
                    Project Details
                </Modal.Title>
            </Modal.Header>

            <ModalBody>
                <>
                    <div>
                        <>
                            {loder ? (
                                <>
                                    <Row>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <div className="rrr"></div>
                                            <label className="formlabelviewzone">
                                                Project Name
                                            </label>
                                            <input
                                                placeholder="Project Name"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.project_name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Project Type
                                            </label>
                                            <input
                                                // placeholder={projDetail.project_type.name}
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.project_type.name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Client Name
                                            </label>
                                            <input
                                                placeholder="Client Name"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.client.name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Client Number (PO)#
                                            </label>
                                            <input
                                                placeholder="Client Number (PO)#"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.client
                                                        .client_number
                                                }
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Sales Person
                                            </label>
                                            <input
                                                placeholder="Sales Person"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.sale_representive
                                                        .name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Primary Project Manager
                                            </label>
                                            <input
                                                placeholder="Primary Project Manager"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.project_manager
                                                        .primary_project_manager
                                                        .name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Secondary Project Manager
                                            </label>
                                            <input
                                                placeholder="Secondary Project Manager"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.project_manager
                                                        .secondary_project_manager
                                                        .name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Status
                                            </label>
                                            <input
                                                placeholder="Status"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.project_status
                                                        .name
                                                }
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Start Date
                                            </label>
                                            <input
                                                placeholder="Start Date"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.start_date
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                End Date
                                            </label>
                                            <input
                                                placeholder="End Date"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.end_date
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Est. Revenue
                                            </label>
                                            <input
                                                placeholder="Est. Revenue"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.revenue
                                                        .est_revenue
                                                }
                                                readOnly
                                            />
                                        </Col>
                                        <Col lg={3} md={6} sm={6} xs={12}>
                                            <label className="formlabelviewzone">
                                                Revised Est. Revenue
                                            </label>
                                            <input
                                                placeholder="Revised Est. Revenue"
                                                className="formtextviewzone"
                                                type="text"
                                                defaultValue={
                                                    projDetail.revenue
                                                        .revised_est_revenue
                                                }
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                    <div style={{ height: '20px' }}></div>

                                    <Row
                                        style={{
                                            backgroundColor: '#979898',
                                            margin: '0px',
                                        }}
                                    >
                                        <Col
                                            style={{
                                                padding: '3px 15px 3px 16px',
                                                color: '#f1f1f1',
                                                fontSize: '12px',
                                                fontWeight: 'bolder',
                                                height: '30px',

                                                paddingTop: '8px',
                                            }}
                                        >
                                            {projDetail.project_name}
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            border: '1px solid #f2f0f0',
                                            margin: '0px',
                                        }}
                                    >
                                        <Col>
                                            <Row>
                                                {' '}
                                                <Col
                                                    style={{
                                                        backgroundColor:
                                                            '#f2f0f0',
                                                        margin: '15px',
                                                        padding: '5px',
                                                        fontSize: '12px',
                                                        fontWeight: 'bolder',
                                                        height: '30px',
                                                        marginTop: '15px',
                                                    }}
                                                >
                                                    {surveyDetail.survey_name}
                                                    <button
                                                        // className={
                                                        //   surveyDetail.survey_status.name === "Pending"
                                                        //     ? ("liveBtnA")
                                                        //     : (surveyDetail.survey_status.name) === "Live" ? (liveBtnA):()
                                                        // }
                                                        className="liveBtnA m-0 w-auto px-2"
                                                    >
                                                        {
                                                            surveyDetail
                                                                .survey_status
                                                                .name
                                                        }
                                                    </button>
                                                </Col>
                                                <Row style={{ margin: '0px' }}>
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            Country*
                                                        </label>
                                                        <input
                                                            placeholder="Country"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail
                                                                    .country
                                                                    .name
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>{' '}
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            Language*
                                                        </label>
                                                        <input
                                                            placeholder="Language"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail
                                                                    .language
                                                                    .name
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>{' '}
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            Type*
                                                        </label>
                                                        <input
                                                            placeholder="key required"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail
                                                                    .project_survey_type
                                                                    .name
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            IR*
                                                        </label>
                                                        <input
                                                            placeholder="IR"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail.ir
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        margin: '0px',
                                                        paddingBottom: '20px',
                                                    }}
                                                >
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            LOI*
                                                        </label>
                                                        <input
                                                            placeholder="LOI*"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail.loi
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>{' '}
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            CPI*
                                                        </label>
                                                        <input
                                                            placeholder="CPI"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail.cpi
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>{' '}
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            Quota*
                                                        </label>
                                                        <input
                                                            placeholder="Quota"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail.n
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>
                                                    <Col
                                                        lg={3}
                                                        md={6}
                                                        sm={6}
                                                        xs={12}
                                                    >
                                                        <label className="formlabelviewzone">
                                                            Margin Threshold
                                                        </label>
                                                        <input
                                                            placeholder="Margin Threshold"
                                                            className="formtextviewzone"
                                                            type="text"
                                                            defaultValue={
                                                                surveyDetail.margin_threshhold
                                                            }
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <SpinnerLoader />
                            )}
                        </>
                    </div>
                </>
            </ModalBody>
        </Modal>
    );
}
export default Editsupplyquality;
