import Supplyheader from './Supplyheader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import toast from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MultiSelect } from 'react-multi-select-component';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { useNavigate } from 'react-router';
import userIco from '../../assets/LoginIcons/users.svg';
import Select from 'react-select';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import SpinnerLoader from '../UI/Spinner';
import { Spinner } from 'react-bootstrap';
import './Supplypage.css';

function Editsupplierquality(props) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [prescreening, setprescreening] = useState();
    const [qualiﬁcation, setQualiﬁcation] = useState();
    const [screen, setScreen] = useState();
    const [herring, setHerring] = useState();
    const [researchdefender, setResearchdefender] = useState();
    const [speederterminate, setSpeederterminate] = useState();
    const [countrycheck, setCountrycheck] = useState();
    const [proxycheck, setProxycheck] = useState();
    const [duplicateip, setDuplicateip] = useState();
    const [duplicateoctete, setDuplicateoctate] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [countryselect, setCountrySelect] = useState();
    const [countryListStorage, setCountryListStorage] = useState([{}]);
    const [supplier_type, setSupplier_type] = useState([{}]);
    const [supplier_type_Select, setSupplier_type_Select] = useState();
    const [loder, setLoder] = useState(false);
    const [cloader, setCloader] = useState(false);
    const [formData, setFormData] = useState({
        fname: '',
        email: '',
        phone: '',
        billingAddress: '',
        countryid: '',
        countryname: '',
        state: '',
        city: '',
        zipcode: '',
        respIdVar: '',
        reconRespVar: '',
        cpiLogic: '',
        isAPI: '',
        isPublisher: '',
        vendorAbbr: '',
        preScreening: '',
        qualificationScreen: '',
        screenCaptcha: '',
        redHerringScreen: '',
        recontactOldIdVar: '',
        status: '',
        suppliername: '',
        supplierId: '',
    });
    const { id } = useParams();
    const pname = 'SupplierDetails';
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    useEffect(() => {
        get_Country_api();
        get_supplierApi();
    }, []);
    useEffect(() => {
        get_ProjectType_api();
    }, []);

    const notifysucces = () => toast.success('Supplier updated successfully');

    const get_ProjectType_api = async () => {
        setLoder(false);
        setCloader(true);
        try {
            const response = await axiosPrivate.get(
                `supplyinfo/get-supplier/${id}`
            );

            // setData(response.data.supplier);
            setFormData((prevData) => ({
                ...prevData,
                fname: response.data.supplier.name,
                email: response.data.supplier.email,
                phone: response.data.supplier.phone,
                billingAddress: response.data.supplier.address.billing_address,
                countryid: response.data.supplier.address.country.id,
                countryname: response.data.supplier.address.country.name,

                state: response.data.supplier.address.state,
                city: response.data.supplier.address.city,
                zipcode: response.data.supplier.address.zipcode,
                respIdVar: '',
                reconRespVar: '',
                cpiLogic: '',

                vendorAbbr: response.data.supplier.supplier_abbr,
                preScreening: response.data.supplier.screening.has_screening,
                qualificationScreen:
                    response.data.supplier.screening.has_qualification,

                screenCaptcha: response.data.supplier.screening.screen_captcha,
                redHerringScreen: response.data.supplier.screening.has_job_type,
                recontactOldIdVar: response.data.supplier.recon_old,
                status: response.data.supplier.status,
            }));
            if (response.data.supplier.supplier_type !== null) {
                setSupplier_type_Select(
                    response.data.supplier.supplier_type.id
                );
                setFormData((prevData) => ({
                    ...prevData,
                    supplierId: response.data.supplier.supplier_type.id,
                    suppliername: response.data.supplier.supplier_type.name,
                }));
            } else {
                setFormData((prevData) => ({
                    ...prevData,

                    suppliername: '',
                    supplierId: '',
                }));
            }

            setCountrySelect(response.data.supplier.address.country.id);

            setprescreening(response.data.supplier.screening.pre_screening);
            setQualiﬁcation(
                response.data.supplier.screening.qualification_screening
            );
            if (
                response.data.supplier.security_check === null ||
                response.data.supplier.security_check === undefined
            ) {
                setScreen(false);
                setResearchdefender(false);
                setSpeederterminate(false);
                setCountrycheck(false);
                setProxycheck(false);
                setDuplicateip(false);
                setDuplicateoctate(false);
            } else {
                setScreen(response.data.supplier.security_check.screen_captcha);
                setResearchdefender(
                    response.data.supplier.security_check.research_defender
                );
                setSpeederterminate(
                    response.data.supplier.security_check.speeder_terminate
                );
                setCountrycheck(
                    response.data.supplier.security_check.country_check
                );
                setProxycheck(
                    response.data.supplier.security_check.proxy_check
                );
                setDuplicateip(
                    response.data.supplier.security_check.duplicate_ip
                );
                setDuplicateoctate(
                    response.data.supplier.security_check.duplicate_ip3_octet
                );
            }

            setHerring(response.data.supplier.screening.red_herring_screen);

            setLoder(true);
            setCloader(false);
        } catch (err) {
            console.error(err);
        }
    };

    const get_Country_api = async () => {
        setCloader(true);
        try {
            const response = await axiosPrivate.get('lookup/country-list');
            response.data.all_countries.map((e) => {
                setCountryListStorage((val) => [
                    ...val,
                    { label: e.country_name, value: e._id },
                ]);
            });
            setCloader(false);
        } catch (err) {
            console.error(err);
        }
    };
    const get_supplierApi = async () => {
        setCloader(true);
        try {
            const response = await axiosPrivate.get('lookup/supplier-type');
            setSupplier_type([]);
            response.data.all_status.map((e) => {
                setSupplier_type((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
            setCloader(false);
        } catch (err) {
            console.error(err);
        }
    };
    useEffect(() => {
        setValue('Country', formData.countryid);
        setValue('SupplierType', formData.supplierId);
    }, [formData.countryid, formData.supplierId]);

    const handleSaveButton = async (e, data) => {
        setIsSubmitting(true);
        try {
            await axiosPrivate
                .post(
                    `supplyinfo/update-supplier/${id}`,
                    JSON.stringify({
                        name: e.firstName,
                        supplier_abbr: e.VendorAbbr,
                        email: e.email,
                        phone: e.contactNumber,
                        address: {
                            billing_address: e.BillingAddress,
                            country_id: countryselect,
                            state: e.state,
                            city: e.city,
                            zipcode: e.Zipcode,
                        },
                        screening: {
                            pre_screening: prescreening,
                            qualification_screening: qualiﬁcation,

                            red_herring_screen: herring,
                        },
                        security_check: {
                            screen_captcha: screen,
                            research_defender: researchdefender,
                            speeder_terminate: speederterminate,
                            country_check: countrycheck,
                            proxy_check: proxycheck,
                            duplicate_ip: duplicateip,
                            duplicate_ip3_octet: duplicateoctete,
                        },
                        supplier_type_id: supplier_type_Select,
                        status: e.Status,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((data) => {
                    if (data.status === 200) {
                        notifysucces();
                        navigate('/supplier-details');
                    }
                });
            setIsSubmitting(false);
        } catch (err) {}
    };

    return (
        <>
            <Supplyheader pname={pname} />
            {loder ? (
                <Row className="supplierParents csmulti ">
                    <form onSubmit={handleSubmit(handleSaveButton)}>
                        <Row
                            className="suppliyChild"
                            style={{
                                paddingBottom: '20px',
                            }}
                        >
                            <div className="user_serachbox">
                                <div style={{ float: 'left' }}>
                                    <h3 className="tableHeading">
                                        <img
                                            src={userIco}
                                            className="headingIcons"
                                            alt=""
                                        />
                                        {formData.fname}
                                    </h3>
                                </div>
                                <div className="right-pane">
                                    <button
                                        className="cbtn closeBtn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/supplier-details');
                                        }}
                                    >
                                        {' '}
                                        Close
                                    </button>

                                    <button
                                        disabled={isSubmitting}
                                        className="cbtn saveBtn"
                                        type="submit"
                                    >
                                        {isSubmitting && (
                                            <Spinner
                                                style={{ color: 'white' }}
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        &nbsp;&nbsp; &nbsp;&nbsp; Save
                                    </button>
                                </div>
                            </div>
                            <hr
                                style={{
                                    marginTop: '20px',
                                }}
                            />
                            {loder ? (
                                <>
                                    <Row
                                        style={{ margin: '0px' }}
                                        className="addcss flex rowGap-5"
                                    >
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Name*
                                            </label>
                                            <input
                                                placeholder="Name"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('firstName', {
                                                    required:
                                                        'This field is Required',
                                                    // pattern: {
                                                    //   value: /^[a-zA-Z\s]*$/,

                                                    //   message: "Only letters are allowed",
                                                    // },
                                                })}
                                                defaultValue={formData.fname}
                                            />
                                            {errors.firstName && (
                                                <small className="addnew_supply">
                                                    {errors.firstName.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Email*
                                            </label>
                                            <input
                                                placeholder="Email"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('email', {
                                                    required:
                                                        'This field is Required',
                                                    pattern: {
                                                        value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line
                                                        message:
                                                            'Enter Valid Email Id',
                                                    },
                                                })}
                                                defaultValue={formData.email}
                                            />
                                            {errors.email && (
                                                <small className="addnew_supply">
                                                    {errors.email.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Phone*
                                            </label>
                                            <input
                                                placeholder="Phone"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('contactNumber', {
                                                    required:
                                                        'This field is Required',
                                                    pattern: {
                                                        value: /^[0-9 ()-]*$/,

                                                        message:
                                                            'Only numbers are allowed',
                                                    },
                                                })}
                                                defaultValue={formData.phone}
                                            />
                                            {errors.contactNumber && (
                                                <small className="errmsg addnew_supply">
                                                    {
                                                        errors.contactNumber
                                                            .message
                                                    }
                                                </small>
                                            )}
                                        </Col>{' '}
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Billing Address*
                                            </label>
                                            <input
                                                placeholder="Billing Address"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('BillingAddress', {
                                                    required:
                                                        'This field is Required',
                                                })}
                                                defaultValue={
                                                    formData.billingAddress
                                                }
                                            />
                                            {errors.BillingAddress && (
                                                <small className="addnew_supply">
                                                    {
                                                        errors.BillingAddress
                                                            .message
                                                    }
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Country*
                                            </label>

                                            <Select
                                                placeholder="Select..."
                                                className="pjt_select formtext"
                                                classNamePrefix="select"
                                                {...register('Country', {
                                                    required:
                                                        'This field is Required',
                                                })}
                                                isLoading={cloader}
                                                options={countryListStorage.map(
                                                    (option) => ({
                                                        value: option.value,
                                                        label: option.label,
                                                        key: option.value, // Ensure each option has a unique key
                                                    })
                                                )}
                                                onChange={(e) => {
                                                    setCountrySelect(
                                                        e.value,
                                                        e.key
                                                    );

                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        countryid: e.value,
                                                    }));
                                                }}
                                                value={countryListStorage.find(
                                                    (option) =>
                                                        option.value ===
                                                        formData.countryid
                                                )} // Set the initial value and make the field editable
                                                noOptionsMessage={() =>
                                                    'No Data Found'
                                                }
                                            />

                                            {countryselect ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {errors.Country && (
                                                        <small className="errmsg perrmsg addnew_supply">
                                                            {
                                                                errors.Country
                                                                    .message
                                                            }
                                                        </small>
                                                    )}
                                                </>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                State
                                            </label>
                                            <input
                                                placeholder="State"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('state', {
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                                        message:
                                                            'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={formData.state}
                                            />
                                            {errors.state && (
                                                <small className="addnew_supply">
                                                    {errors.state.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                City
                                            </label>
                                            <input
                                                placeholder="City"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('city', {
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                                        message:
                                                            'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={formData.city}
                                            />
                                            {errors.city && (
                                                <small className="addnew_supply">
                                                    {errors.city.message}
                                                </small>
                                            )}
                                        </Col>{' '}
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Zipcode
                                            </label>
                                            <input
                                                placeholder="Zipcode"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('Zipcode', {
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message:
                                                            'Only numbers are allowed',
                                                    },
                                                })}
                                                defaultValue={formData.zipcode}
                                            />
                                            {errors.Zipcode && (
                                                <small className="addnew_supply">
                                                    {errors.Zipcode.message}
                                                </small>
                                            )}
                                        </Col>
                                    </Row>{' '}
                                    <Row
                                        style={{ margin: '0px' }}
                                        className="addcss mt-2"
                                    >
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Supplier Type*
                                            </label>

                                            <Select
                                                placeholder="Select..."
                                                className="pjt_select formtext"
                                                classNamePrefix="select"
                                                {...register('SupplierType', {
                                                    required:
                                                        'This field is Required',
                                                })}
                                                isLoading={cloader}
                                                options={supplier_type.map(
                                                    (option) => ({
                                                        value: option.value,
                                                        label: option.label,
                                                        key: option.value, // Ensure each option has a unique key
                                                    })
                                                )}
                                                onChange={(e) => {
                                                    setSupplier_type_Select(
                                                        e.value,
                                                        e.key
                                                    );

                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        supplierId: e.value,
                                                    }));
                                                }}
                                                value={supplier_type.find(
                                                    (option) =>
                                                        option.value ===
                                                        formData.supplierId
                                                )} // Set the initial value and make the field editable
                                                noOptionsMessage={() =>
                                                    'No Data Found'
                                                }
                                            />

                                            {supplier_type_Select ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {errors.SupplierType && (
                                                        <small className="errmsg perrmsg addnew_supply">
                                                            {
                                                                errors
                                                                    .SupplierType
                                                                    .message
                                                            }
                                                        </small>
                                                    )}
                                                </>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Supplier Abbr*
                                            </label>
                                            <input
                                                placeholder="Vendor Abbr"
                                                className="formtextviewzone"
                                                type="text"
                                                {...register('VendorAbbr', {
                                                    required:
                                                        'This field is Required',
                                                    pattern: {
                                                        value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ]*$/,
                                                        message:
                                                            'Only letters are allowed',
                                                    },
                                                })}
                                                defaultValue={
                                                    formData.vendorAbbr
                                                }
                                            />
                                            {errors.VendorAbbr && (
                                                <small className="addnew_supply">
                                                    {errors.VendorAbbr.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={12}
                                            className="addsupply_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Status
                                            </label>

                                            <select
                                                className="formtext"
                                                {...register('Status', {})}
                                                defaultValue={formData.status}
                                            >
                                                <option value="1">
                                                    Active
                                                </option>
                                                <option value="0">
                                                    Inactive
                                                </option>
                                            </select>
                                        </Col>
                                    </Row>{' '}
                                    <Row style={{ margin: '0px' }} className="">
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Pre-Screening
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setprescreening(e);
                                                    }}
                                                    checked={prescreening}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Qualiﬁcation screen
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register(
                                                        'Qualiﬁcation'
                                                    )}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setQualiﬁcation(e);
                                                    }}
                                                    checked={qualiﬁcation}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Screen Captcha
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setScreen(e);
                                                    }}
                                                    checked={screen}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Red Herring Screen
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setHerring(e);
                                                    }}
                                                    checked={herring}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Research defender
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setResearchdefender(e);
                                                    }}
                                                    checked={researchdefender}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Speeder Terminate
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setSpeederterminate(e);
                                                    }}
                                                    checked={speederterminate}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Country Check
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setCountrycheck(e);
                                                    }}
                                                    checked={countrycheck}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Proxy Check
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setProxycheck(e);
                                                    }}
                                                    checked={proxycheck}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Duplicate IP
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setDuplicateip(e);
                                                    }}
                                                    checked={duplicateip}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            sm={6}
                                            xs={6}
                                            className="supplytoggele_col"
                                        >
                                            <label className="formlabelviewzone">
                                                Duplicate Ip3 Octet
                                            </label>
                                            <div className="tooglecenter pswtchbtn supplytoggele">
                                                <BootstrapSwitchButton
                                                    {...register('Screening')}
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={60}
                                                    onlabel="On "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setDuplicateoctate(e);
                                                    }}
                                                    checked={duplicateoctete}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <SpinnerLoader />
                            )}
                        </Row>
                    </form>
                </Row>
            ) : (
                <SpinnerLoader />
            )}
        </>
    );
}
export default Editsupplierquality;
