import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import axios from "axios";
import "./ExclusionSetting.css";
import toast from "react-hot-toast";

const Hashing = () => {
  const navigate = useNavigate();
  const pname = "Hashing";
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showFormone, setShowFormone] = useState(false);
  const [showFormtwo, setShowFormtwo] = useState(false);
  const [inHashstatus, setInhashstatus] = useState(false);
  const [outHashstatus, setOuthashstatus] = useState(false);

  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [formData, setFormData] = useState({
    Instatus: "",
    InHash: "",
    Invar_f: "",
    InHasf: "",
    InSecretkey: "",
    Outstatus: "",
    OutHash: "",
    OutHasf: "",
    OutSecretkey: "",
    Outvar_f: "",
  });
  const notifysucces = () => toast.success("Updated successfully");
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);

      if (response.data.supplier.hashing !== null) {
        setFormData((prevData) => ({
          ...prevData,

          InHash:
            response.data.supplier.hashing.hash_variable.in_hash_var.hash_var,
          Invar_f:
            response.data.supplier.hashing.hash_variable.in_hash_var.var_format,

          InHasf:
            response.data.supplier.hashing.hash_variable.in_hash_var.hash_fn,

          InSecretkey:
            response.data.supplier.hashing.hash_variable.in_hash_var.secret_key,

          OutHash:
            response.data.supplier.hashing.hash_variable.out_hash_var.hash_var,
          OutHasf:
            response.data.supplier.hashing.hash_variable.out_hash_var.hash_fn,
          OutSecretkey:
            response.data.supplier.hashing.hash_variable.out_hash_var
              .secret_key,
          Outvar_f:
            response.data.supplier.hashing.hash_variable.out_hash_var
              .var_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          InHash: "",
          Invar_f: "",
          InHasf: "",
          InSecretkey: "",

          OutHash: "",
          OutHasf: "",
          OutSecretkey: "",
        }));
      }
      // setData(response.data.supplier);

      if (response.data.supplier.hashing !== null) {
        setShowFormone(
          response.data.supplier.hashing.hash_variable.in_hash_var.status
        );
      } else {
        setShowFormone(false);
      }
      if (response.data.supplier.hashing !== null) {
        setShowFormtwo(
          response.data.supplier.hashing.hash_variable.out_hash_var.status
        );
      } else {
        setShowFormtwo(false);
      }

      setLoder(true);
    } catch (err) {
      console.error(err);
    }
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        .post(
          `/supplyinfo/supplier-hashing/${id}`,
          JSON.stringify({
            hash_variable: {
              in_hash_var: {
                status: showFormone,
                hash_var: e.Hash,
                var_format: e.Variablef,
                secret_key: e.secret_key,
                hash_fn: e.hash_fn,
              },
              out_hash_var: {
                status: showFormtwo,
                hash_var: e.Hashkeyob,
                hash_fn: e.Function_outbound,
                var_format: e.var_format,
                secret_key: e.Secretkeyob,
              },
            },
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };
  return (
    <>
      <>
        <SettingHeader pname={pname} />
        <div className="top_container">
          <div className="main_container ">
            <form onSubmit={handleSubmit(postData)}>
              {/* <form onSubmit={handleSubmit(createDepartment)}> */}
              <div className="user_serachbox">
                <div style={{ float: "left" }}>
                  <h3 className="tableHeading">
                    <img
                      src={userIco}
                      className="headingIcons"
                      alt="Add Department"
                    />
                    Hashing
                  </h3>
                </div>

                <div className="right-pane">
                  <button
                    className="cbtn closeBtn"
                    onClick={() => {
                      navigate("/supplier-details");
                    }}
                  >
                    Close
                  </button>

                  <button disabled={isSubmitting} className="cbtn saveBtn">
                    {isSubmitting && <ButtonSpinner />} &nbsp; Save
                  </button>
                </div>
              </div>
              <hr />
              {loder ? (
                <>
                  <div
                    id="dividerow"
                    style={{ marginTop: "10px" }}
                    className="mainContent"
                  >
                    <>
                      <div className="settingHeading">
                        <p className="pBlockHead">Hash variable</p>
                      </div>
                      <Row
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <Col lg={4} md={4} xs={12} className="ssbtn sscolorbtn">
                          {" "}
                          <label
                            className="formlabel"
                            stsecret_keyyle={{
                              marginBottom: "10px",
                            }}
                          >
                            Status inbound*
                          </label>
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            onChange={(e) => {
                              setShowFormone(e);
                            }}
                            size="xs"
                            width={80}
                            onlabel="Active"
                            offlabel="Inactive"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            checked={showFormone}
                          />
                        </Col>
                      </Row>
                      {showFormone === true ? (
                        <>
                          <Row
                            style={{
                              marginTop: "20px",
                            }}
                          >
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Variable Inbound *
                              </label>
                              <input
                                {...register("Hash", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Hash Variable Inbound"
                                className="CsformText  supplier_setting"
                                defaultValue={formData.InHash}
                              />
                              {errors.Hash && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Hash.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Secret key Inbound *
                              </label>
                              <input
                                {...register("secret_key", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Secret key Inbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.InSecretkey}
                              />
                              {errors.secret_key && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.secret_key.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Function Inbound
                              </label>
                              <input
                                {...register("hash_fn")}
                                type="text"
                                placeholder="Hash function Inbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.InHasf}
                              />
                              {errors.hash_fn && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.hash_fn.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Variable format Inbound
                              </label>
                              <input
                                {...register("Variablef")}
                                type="text"
                                placeholder="Variable format Inbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.Invar_f}
                              />
                              {errors.Variablef && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Variablef.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}

                      <Row
                        style={{
                          marginTop: "15px",
                        }}
                      >
                        <Col lg={4} md={4} xs={12} className="ssbtn sscolorbtn">
                          {" "}
                          <label
                            className="formlabel"
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            Status outbound*
                          </label>
                          <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            onChange={(e) => {
                              setShowFormtwo(e);
                            }}
                            size="xs"
                            width={80}
                            onlabel="Active"
                            offlabel="Inactive"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            checked={showFormtwo}
                          />
                        </Col>
                      </Row>
                      {showFormtwo === true ? (
                        <>
                          <Row
                            style={{
                              marginTop: "25px",
                            }}
                          >
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Variable Outbound *
                              </label>
                              <input
                                {...register("Hashkeyob", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Hash variable outbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.OutHash}
                              />
                              {errors.Hashkeyob && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Hashkeyob.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Secret Key Outbound *
                              </label>
                              <input
                                {...register("Secretkeyob", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Secret key outbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.OutSecretkey}
                              />
                              {errors.Secretkeyob && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Secretkeyob.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Function Outbound*
                              </label>
                              <input
                                {...register("Function_outbound", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Function Outbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.OutHasf}
                              />
                              {errors.Function_outbound && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.Function_outbound.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={4} xs={12}>
                              <label className="formlabel">
                                Variable format Outbound *
                              </label>
                              <input
                                {...register("var_format", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Variable format outbound"
                                className="CsformText supplier_setting"
                                style={{
                                  // width: "445px",

                                  height: "30px",
                                }}
                                defaultValue={formData.Outvar_f}
                              />
                              {errors.var_format && (
                                <small
                                  className="supplier_setting_error"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {errors.var_format.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                </>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </>
    </>
  );
};

export default Hashing;
