import Button from 'react-bootstrap/Button';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Select from 'react-select';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import SpinnerLoader from '../../UI/Spinner';
import { useForm } from 'react-hook-form';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
function SurveyInnerModal(props) {
    const axiosPrivate = useAxiosPrivate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [softLounchQuota, setSoftLounchQuota] = useState(false);
    const [survey_data, setSurvey_data] = useState([]);
    const [loader, setLoader] = useState(true);
    const [selected_status, setSelected_status] = useState([]);
    const [projectStatusStorage, setProjetStatusStorage] = useState([]);
    const notifysucces = (e) => toast.success(`Updated successfully`);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    useEffect(() => {
        get_ProjectStatus_api();
        if (props.surveyInnerModal === true) {
            get_SurveyProject();
        }
    }, [props.survey_Id, props.surveyInnerModal]);

    const get_ProjectStatus_api = async () => {
        try {
            const response = await axiosPrivate.get(
                'lookup/get-project-status'
            );
            setProjetStatusStorage([]);
            response.data.all_status.map((e) => {
                setProjetStatusStorage((val) => [
                    ...val,
                    { label: e.name, value: e.id },
                ]);
            });
        } catch (err) {
            console.error(err);
        }
    };

    const get_SurveyProject = async (eid) => {
        setLoader(false);
        try {
            const response = await axiosPrivate.get(
                `survey/get-survey/${props.survey_Id}`
            );

            setSurvey_data([response.data.survey]);
            setValue('LOI', response.data.survey.loi);

            setSoftLounchQuota(
                response.data.survey.survey_setting !== null &&
                    response.data.survey.survey_setting.soft_launch !== null
                    ? response.data.survey.survey_setting.soft_launch.status
                    : false
            );
            setSelected_status(() => [
                {
                    label: response.data.survey.survey_status.name,
                    value: response.data.survey.survey_status.id,
                },
            ]);
            setValue('Status', {
                label: response.data.survey.survey_status.name,
                value: response.data.survey.survey_status.id,
            });
            // setSelected_status([response.data.survey.survey_status]);
        } catch (err) {
            console.error(err);
        }
        setLoader(true);
    };
    const postDataform = async (data) => {
        setIsSubmitting(true);

        try {
            await axiosPrivate
                .patch(
                    `survey/survey-track-update/${props.survey_Id}`,
                    JSON.stringify({
                        ir: data.IR,
                        loi: data.LOI,
                        survey_status_id: data.Status.value,
                        soft_launch: {
                            status: softLounchQuota,
                            n: data.N,
                        },
                        remark: data.Remarks,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((data) => {
                    if (data.status === 200) {
                        notifysucces();
                        props.setSurveyInnerModal(false);
                        props.props.setReloadAllTable(true);
                    }
                });
            setIsSubmitting(false);
        } catch (err) {}
    };
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="fs16"
                >
                    Survey Tracker
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loader ? (
                    <>
                        {survey_data.map((surveyItem, surIndex) => (
                            <>
                                <form onSubmit={handleSubmit(postDataform)}>
                                    <Row>
                                        <Col
                                            lg={3}
                                            md={3}
                                            xs={6}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                LOI
                                            </label>
                                            <input
                                                {...register(`LOI`, {
                                                    required:
                                                        'This field is Required',
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message:
                                                            'Only numbers are allowed',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="LOI"
                                                className="CsformText m-0"
                                                defaultValue={surveyItem.loi}
                                            />
                                            {errors.LOI && (
                                                <small className="errmsg errmsgFix m-0">
                                                    {errors.LOI.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={3}
                                            xs={6}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                IR
                                            </label>
                                            <input
                                                {...register(`IR`, {
                                                    required:
                                                        'This field is Required',
                                                    pattern: {
                                                        value: /^[0-9]+$/,
                                                        message:
                                                            'Only numbers are allowed',
                                                    },
                                                })}
                                                type="text"
                                                placeholder="IR"
                                                className="CsformText m-0"
                                                defaultValue={surveyItem.ir}
                                            />
                                            {errors.IR && (
                                                <small className="errmsg errmsgFix m-0">
                                                    {errors.IR.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={6}
                                            md={6}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Last Update Date
                                            </label>
                                            <input
                                                type="text"
                                                placeholder="Last Update Date"
                                                className="CsformText"
                                                defaultValue={
                                                    surveyItem.survey_track_time
                                                }
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={4} xs={12}>
                                            <label className="formlabel">
                                                Status
                                            </label>

                                            <Select
                                                {...register(`Status`, {
                                                    required:
                                                        'This field is Required',
                                                })}
                                                placeholder="Status"
                                                className="pjt_select formtext"
                                                classNamePrefix="select"
                                                options={projectStatusStorage}
                                                onChange={(e) => {
                                                    setSelected_status(e);
                                                    setValue(`Status`, e);
                                                }}
                                                defaultValue={selected_status}
                                                noOptionsMessage={() =>
                                                    'No Data Found'
                                                }
                                            />
                                            {errors?.Status && (
                                                <small className="errmsg errmsgFix m-0">
                                                    {errors.Status.message}
                                                </small>
                                            )}
                                        </Col>
                                        <Col
                                            lg={3}
                                            md={6}
                                            xs={12}
                                            className="col"
                                        >
                                            <label className="formlabel">
                                                Soft Launch
                                            </label>
                                            <div className="tooglecenter pswtchbtn screenswitchbtn">
                                                <BootstrapSwitchButton
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    width={60}
                                                    size="xs"
                                                    onlabel="ON "
                                                    offlabel="OFF"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    checked={softLounchQuota}
                                                    onChange={(e) => {
                                                        setSoftLounchQuota(e);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        {softLounchQuota ? (
                                            <>
                                                <Col
                                                    lg={4}
                                                    md={4}
                                                    xs={6}
                                                    className="col"
                                                >
                                                    <label className="formlabel">
                                                        Soft launch Quota
                                                    </label>
                                                    <input
                                                        {...register(`N`, {
                                                            required:
                                                                'This field is Required',
                                                            pattern: {
                                                                value: /^[0-9]+$/,
                                                                message:
                                                                    'Only numbers are allowed',
                                                            },
                                                        })}
                                                        type="text"
                                                        placeholder="Soft launch Quota"
                                                        className="CsformText m-0"
                                                        defaultValue={
                                                            survey_data[0]
                                                                .survey_setting !==
                                                                null &&
                                                            survey_data[0]
                                                                .survey_setting
                                                                .soft_launch !==
                                                                null
                                                                ? surveyItem
                                                                      .survey_setting
                                                                      .soft_launch
                                                                      .n
                                                                : ''
                                                        }
                                                    />
                                                    {errors.N && (
                                                        <small className="errmsg errmsgFix m-0">
                                                            {errors.N.message}
                                                        </small>
                                                    )}
                                                </Col>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                    <Row className="mt-2">
                                        <Col lg={12} md={12} xs={12}>
                                            <label className="formlabel">
                                                Remarks*
                                            </label>

                                            <textarea
                                                {...register(`Remarks`, {
                                                    required:
                                                        'This field is Required',
                                                })}
                                                rows={4}
                                                style={{ width: '100%' }}
                                                defaultValue={
                                                    surveyItem.remark.length !==
                                                    0
                                                        ? surveyItem.remark[
                                                              surveyItem.remark
                                                                  .length - 1
                                                          ].remark
                                                        : ''
                                                }
                                                className="mb--5px"
                                            />
                                            {errors.Remarks && (
                                                <small className="errmsg errmsgFix m-0">
                                                    {errors.Remarks.message}
                                                </small>
                                            )}
                                        </Col>
                                    </Row>
                                    <Modal.Footer className="pb-0 mt-2 pe-0">
                                        <button
                                            className="supply_setupbtn m-0"
                                            type="submit"
                                        >
                                            {isSubmitting && (
                                                <Spinner
                                                    style={{ color: 'white' }}
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            )}
                                            &nbsp; Save
                                        </button>
                                        <Button
                                            className="supply_setupbtn m-0 ms-2 btn-bg-grey"
                                            onClick={props.onHide}
                                        >
                                            Cancel
                                        </Button>
                                    </Modal.Footer>
                                </form>
                            </>
                        ))}
                    </>
                ) : (
                    <SpinnerLoader />
                )}
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
    );
}
export default SurveyInnerModal;
