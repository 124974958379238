import "../PopUp/SetupSupplier.css";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import SpinnerLoader from "../../../UI/Spinner";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const SupplyBuildUrl = (props, setTerminationlinkedit) => {
  const inputRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const [loader, setLoader] = useState(true);
  const [checkQmark, setCheckQmark] = useState(true);
  const [completeUrl, setCompleteUrl] = useState(props.baseurl);
  const [baseUrl, setBaseUrl] = useState(props.baseurl);
  const [multiselectValues, setMultiSelectValues] = useState([]);
  const [combinedIds, setCombinedIds] = useState([]);
  const [clientVar, setClientVar] = useState([]);
  const [fixedVar, setFixedVar] = useState([]);
  const [customVar, setCustomVar] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [ridStat, setRidStat] = useState(false);
  const [flag, setFlag] = useState(props.show);
  const [isBaseUrl, setIsBaseUrl] = useState(true);
  const [fixedIds, setFixedIds] = useState(true);
  const [allIds, setAllIds] = useState([]);
  const [checkFlag, setCheckFlag] = useState(false);
  const [showError, setShowError] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

 
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();


  const get_Supplierlist_api = async () => {
    
    try {
      const response = await axiosPrivate.get(
        `survey-supplier/exceptional-variable?survey_supplier_id=${props.supplierid}`
      );

      setClientVar([]);
      let fixedVarArr = [];
      let ids = [];
      setClientVar((prevData) => [
        ...prevData,
        {
          name: "tid",
          format: response.data.variable.tid.format,
          key: response.data.variable.tid.name,
        },
      ]);
      setFixedVar((prevData) => [
        ...prevData,
        {
          name: "tid",
          format: response.data.variable.tid.format,
          key: response.data.variable.tid.name,
        },
      ]);
      ids.push(response.data.variable.tid.format);
      fixedVarArr.push(response.data.variable.tid.format);
      if (response.data.variable.pid) {
        setClientVar((prevData) => [
          ...prevData,
          {
            name: "pid",
            format: response.data.variable.pid.format,
            key: response.data.variable.pid.name,
          },
        ]);
        setFixedVar((prevData) => [
          ...prevData,
          {
            name: "pid",
            format: response.data.variable.pid.format,
            key: response.data.variable.pid.name,
          },
        ]);
        ids.push(response.data.variable.pid.format);
        fixedVarArr.push(response.data.variable.pid.format);
      }

      setAllIds(ids);
      setFixedIds(fixedVarArr);
      const customArr = response.data.variable.custom;

      setCustomVar([]);
      if (response.data.variable.custom != "") {
        response.data.variable.custom.map((ite) => {
          setCustomVar((val) => [
            ...val,
            {
              name: ite.name,
              format: ite.format || "[%"+ite.name+"%]",
              key: ite.name,
            },
          ]);
          setMultiSelectValues((val) => [
            ...val,
            {
              name: ite.name,
              value: ite.format || "[%"+ite.name+"%]",
              label: ite.name,
            },
          ]);
          if(ite.format){
            ids.push(ite.format);
          }
          else{
            ids.push("[%"+ite.name+"%]");
          }
        });
      }



      setAllIds(ids);
      if (!checkQmark) {
        document.querySelectorAll(".vtttinput").forEach(function (el) {
          el.style.visibility = "hidden";
        });
      } else {
        document.querySelectorAll(".vtttinput").forEach(function (el) {
          el.style.visibility = "visible";
        });
      }
    } catch (err) {
      // console.error(err);
    }
    
    setLoader(false);
    makeCompleteUrl();


  };

  useEffect(() => {
    get_Supplierlist_api();
    
    if (
      baseUrl &&
      baseUrl != null &&
      baseUrl != "null" &&
      baseUrl != undefined
    ) {
      const checkq = baseUrl.includes("?");
      setIsBaseUrl(true);
      

      var url = "";
      var urlvariable = "";
      var keyvalpair = [];
      if (checkq) {
        setCheckQmark(true);
        var pathArray = baseUrl.split("?");
        url = pathArray[0];
        urlvariable = pathArray[1];
        keyvalpair = urlvariable.split("&");
        handleCombinedIds(keyvalpair, "", 1);
      } else {
        var pathArray = baseUrl.split("/");
        var protocol = pathArray[0];
        var host = pathArray[2];
        url = protocol + "//" + host;
        urlvariable = baseUrl.replace(url, "");
        var partBeforeBrackets = getUrlBeforeBrackets(urlvariable);
        url = url + partBeforeBrackets;
        if (pathArray.length > 3) {
          setCheckQmark(false);
          var keyvalpair = findAllElementsInBrackets(urlvariable);
        }
        handleCombinedIds("", keyvalpair, 1);
      }
      setBaseUrl(url);
      setCompleteUrl(baseUrl);
      if (urlvariable === "") {
        if (!checkQmark) {
          setCompleteUrl(baseUrl + "/[%tid%]");
        } else {
          setCompleteUrl(baseUrl + "?tid=[%tid%]");
        }
      }
    } else {
      setIsBaseUrl(false);
      setCompleteUrl(baseUrl);
    }
    setRidStat(true);

    
  }, []);

  function getUrlBeforeBrackets(str) {
    var parts = str.split("[");
    var partBeforeBrackets = parts[0].replace(/\/$/, "");
    return partBeforeBrackets;
  }

  function findAllElementsInBrackets(str) {
    var regex = /\[([^\]]+)\]/g;
    var matches = [];
    var match;
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[0]);
    }
    return matches;
  }

 

  useEffect(() => {
    
    combinedIds.forEach((combinedItem) => {
      if (combinedItem.key === undefined) {
        if (combinedItem.format === "[%tid%]") {
          combinedItem.key = clientVar.find(item => item.format === "[%tid%]")?.key;
          combinedItem.name= clientVar.find(item => item.format === "[%tid%]")?.name;
          
        } 
        
        else if (combinedItem.format === "[%pid%]") {
          combinedItem.key = clientVar.find(item => item.format === "[%pid%]")?.key;
          combinedItem.name= clientVar.find(item => item.format === "[%pyid%]")?.name;
          
        } else {
          multiselectValues.forEach((multiItem) => {
            if (combinedItem.format === multiItem.value) {
              combinedItem.key = multiItem.name;
            }
          });
        }
      }
      if (combinedItem.name === undefined) {
        if (combinedItem.format === "[%tid%]") {
          combinedItem.name= clientVar.find(item => item.format === "[%tid%]")?.name;
          
        } 
        
        else if (combinedItem.format === "[%pid%]") {
          combinedItem.name= clientVar.find(item => item.format === "[%pyid%]")?.name;
          
        } else {
          multiselectValues.forEach((multiItem) => {
            combinedItem.name= customVar.find(item => item.format === combinedItem.format)?.name;
          });
        }
      }
    });

  }, [multiselectValues, checkQmark]);


  useEffect(() => {
    makeCompleteUrl();
    if (!checkQmark) {
      document.querySelectorAll(".vtttinput").forEach(function (el) {
        el.style.visibility = "hidden";
      });
    } else {
      document.querySelectorAll(".vtttinput").forEach(function (el) {
        el.style.visibility = "visible";
      });
    }
    
  }, [checkQmark, loader, checkFlag]);

  const handleVarCheckbox = (event, format, name) => {
    const elem = event.target.name;
    const checked = event.target.checked;

    const value = document.getElementById("input_" + elem).value;

    setCombinedIds((prevCombinedId) => {
      let updatedCombinedId = [...prevCombinedId];
      if (checked) {
        const isValueExists = updatedCombinedId.some(
          (param) => param.value === value
        );
        if (!isValueExists) {
          const paramToAdd = {
            name,
            key: value,
            format,
          };
          updatedCombinedId.push(paramToAdd);
        }
      } else {
        updatedCombinedId = updatedCombinedId.filter(
          (param) => param.format !== format
        );
      }

      return updatedCombinedId;
    });
    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.format,
      })),
    ]);
    setCheckFlag(!checkFlag);
    makeCompleteUrl();
  };

  const handleVarPlaceholder = (event, format, name) => {
    const inputValue = event.target.value;
    setCombinedIds((prevCombinedId) => {
      const updatedCombinedId = prevCombinedId.map((option) => {
        if (option.format === format) {
          return {
            ...option,
            key: inputValue,
          };
        }
        return option;
      });
      return updatedCombinedId;
    });

    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.format,
      })),
    ]);
    setCheckFlag(!checkFlag);
    makeCompleteUrl();
  };

  const handleMultiSelectChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions).map(
      (option) => ({
        name: option.label,
        value: option.value,
      })
    );

    setCombinedIds((prevCombinedId) => [
      ...prevCombinedId,
      ...selectedOptions.map((option) => ({
        key: option.name,
        format: option.value,
        name: customVar.find((q) => q.format === option.value)?.name || null,
      })),
    ]);

    setSelectedIds((prevIds) => [
      ...prevIds,
      ...selectedOptions.map((option) => ({
        value: option.value,
      })),
    ]);
    setCheckFlag(!checkFlag);
    makeCompleteUrl();
  };

  const handleUrlInputChange = (event) => {
    setCompleteUrl(event.target.value);
    const inputValue = event.target.value;
    const paramValue = inputValue.split("?");
    const paramValuePairs = paramValue[1].split("&");
    handleCombinedIds(paramValuePairs, "", 0);
  };

  const handleCombinedIds = (valuepairs, singlevalue, makeurl) => {
    let valarray = [];
    let newIds = [];
    if (valuepairs !== "") {
      
      valuepairs.forEach((pair, i) => {
        if (pair != "") {
          const [param, value] = pair.split("=");
          if (value != undefined || value != "undefined") {
            valarray[i] = value;
          }
          const paramToAdd = {
            key: param,
            format: value,
            name: clientVar.find((q) => q.format === value)?.name,
          };
          newIds.push(paramToAdd);
        }
      });
    }
    if (singlevalue !== "") {
      singlevalue.forEach((value, i) => {
        if (value !== "") {
          valarray[i] = value;
        }
      
        const paramToAdd = {
          key:clientVar.find((q) => q.format === value)?.key||fixedVar.find((q) => q.format === value)?.key,
          format: value,
          name: clientVar.find((q) => q.format === value)?.name|| fixedVar.find((q) => q.format === value)?.name,
        };
        newIds.push(paramToAdd);
      });
    }


    newIds.forEach((combinedItem) => {
      if (newIds.key === undefined || newIds.name === undefined) {
        if (newIds.format === "[%tid%]") {
          newIds.key = fixedVar.find(item => item.format === "[%tid%]")?.key;
          newIds.name= fixedVar.find(item => item.format === "[%tid%]")?.name;
          
        } 
        
        else if (newIds.format === "[%pid%]") {
          newIds.key = fixedVar.find(item => item.format === "[%pid%]")?.key;
          newIds.name= fixedVar.find(item => item.format === "[%pyid%]")?.name;
          
        } else {
          multiselectValues.forEach((multiItem) => {
            if (newIds.format === multiItem.value) {
              newIds.key = multiItem.name;
            }
          });
        }
      }
    });

    


    setCombinedIds(newIds);
    setCheckedIds(valarray);
    setSelectedIds(() => [
      ...newIds.map((option) => ({
        value: option.format,
      })),
    ]);
    setCheckFlag(!checkFlag);
    if (makeurl) {
      makeCompleteUrl();
    }
  };

  const makeCompleteUrl = () => {

    setSelectedIds(() => [
      ...combinedIds.map((option) => ({
        value: option.format,
      })),
    ]);
    let url = baseUrl;
    const len = combinedIds.length;
    if (checkQmark) {
      if (len > 0) {
        url += "?";
      }
    } else {
      if (len > 0) {
        url = url.trim("/");
        url += "/";
      }
    }

    combinedIds.map((item, index) => {
      if (checkQmark) {
        url += item.key + "=" + item.format;
      } else {
        if (item !== "") {
          url += item.format;
        }
      }

      if (index < len - 1) {
        if (checkQmark) {
          url += "&";
        } else {
          url += "/";
        }
      }
    });

    setCompleteUrl(url);
  };

  const handleClose = () => {
    setCombinedIds([]);
    setBaseUrl("");
    props.setbuildurledit(false);
    props.setterminationlinkedit(completeUrl);
    props.seturl(props.baseurl);
    reset();
  };

  const formsubmission = (e) => {
    if (completeUrl) {
      props.settermination(`${props.keyvalue}`, completeUrl);
      props.seturl(completeUrl);
      props.setbuildurledit(false);
      reset();
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (completeUrl !== "") {
      setShowError(false);
    }
  }, [completeUrl]);

  const compUrl = register("compUrl", {
    required: "This field is Required",
  });

  const setBaseUrlfunction = (val) => {
    if (
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        val
      )
    ) {
      setBaseUrl(val);

      if (!checkQmark) {
        setCompleteUrl(val + "/[%tid%]");
      } else {
        let cvar = fixedVar.find(item => item.format === "[%tid%]")?.key;
        setCompleteUrl(val + "?"+cvar+"=[%tid%]");
      }
      setIsBaseUrl(true);
    }
    setCombinedIds([]);
    setCombinedIds((prevCombinedId) => {
      return [
        ...prevCombinedId,
        {
          format: clientVar[0].format,
          name: clientVar[0].name,
          key: clientVar[0].key,
        },
      ];
    });
    setSelectedIds([]);
    setSelectedIds((prevIds) => {
      return [
        ...prevIds,
        {
          value: clientVar[0].format,
        },
      ];
    });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", paddingLeft: "3px" }}
        >
          Build URL
        </Modal.Title>
      </Modal.Header>
      {loader ? (
        <SpinnerLoader />
      ) : (
        <>
          <Modal.Body>
            <Row>
              <Col lg={8} md={12} sm={12} xs={12} className="">
                <p
                  style={{
                    marginLeft: "0px",
                  }}
                >
                  What's your survey's URL?
                </p>
              </Col>
              <Col lg={4} md={12} sm={12} xs={12} className="">
                <input
                  type="checkbox"
                  placeholder=""
                  defaultChecked={checkQmark}
                  onChange={(e) => {
                    setCheckQmark(e.target.checked);
                    //handleQuestionmark(e);
                  }}
                />
                <label
                  className="formlabelviewzone"
                  style={{
                    width: "95%",
                    paddingLeft: "10px",
                  }}
                >
                  Check question mark format
                </label>
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="">
                {isBaseUrl ? (
                  <>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                      }}
                      // {...compUrl}
                      value={completeUrl}
                      onChange={(e) => {
                        //compUrl.onBlur(e);
                        handleUrlInputChange(e);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <small className="">
                      <font color="red">
                        Please enter a base url like "https://samplicity.com"
                      </font>
                    </small>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderColor: "red",
                      }}
                      onBlur={(event) => {
                        setBaseUrlfunction(event.target.value);
                      }}
                    />
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} sm={6} xs={12} className="col">
                <h6
                  style={{
                    fontSize: "12px",
                    marginTop: "10px",
                  }}
                >
                  Variables
                </h6>
                <p
                  style={{
                    margin: "10px 0px 20px 0px",
                  }}
                >
                  These are URL parameters for respondent or survey-level data
                  that will be passed along when a respondent gets sent to your
                  survey.
                </p>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={6}
                xs={12}
                className="col"
                style={{
                  marginTop: "40px",
                }}
              >
                {" "}
                {isBaseUrl ? (
                  <>
                    {customVar.length === 0 ? (
                      <></>
                    ) : (
                      <div className="variable_select">
                        <select
                          className="variable_select__select"
                          onChange={(event) => {
                            handleMultiSelectChange(event);
                          }}
                          defaultValue=""
                        >
                          <option value="" disabled hidden>
                            Select Variable
                          </option>
                          {multiselectValues.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                              disabled={combinedIds.some((element) => {
                                return element.format === option.value;
                              })}
                            >
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row>
              {isBaseUrl ? (
                <>
                  {fixedVar.map((item, index) => (
                    <Col
                      className="Quality_Col"
                      key={"var_" + item.value}
                      lg={2}
                      md={4}
                      sm={4}
                      xs={6}
                    >
                      <div className="">
                        <label
                          className="formlabel"
                          htmlFor={"check_" + item.value}
                        >
                          {item.name}
                        </label>
                        <input
                          className=" box_width "
                          type="checkbox"
                          id={"check_" + item.format}
                          name={item.format}
                          checked={selectedIds.some(
                            (sid) => sid.value === item.format
                          )}
                          onChange={(event) => {
                            handleVarCheckbox(event, item.format, item.name);
                          }}
                        />
                        <input
                          style={{
                            border: "1px solid green",
                          }}
                          className="vtttinput"
                          type="text"
                          name={"input_" + item.format}
                          id={"input_" + item.format}

                          value={
    combinedIds.find((q) => q.format === item.format)?.key !== undefined
      ? combinedIds.find((q) => q.format === item.format)?.key
      : item.key
  }
                          onChange={(event) => {
                            handleVarPlaceholder(event, item.format, item.name);
                          }}
                        />
                      </div>
                    </Col>
                  ))}

                  {combinedIds.map((option, index) =>
                    fixedIds.includes(option.format) ? (
                      <></>
                    ) : (
                      <>
                        {allIds.includes(option.format) ? (
                          <Col
                            className="Quality_Col"
                            lg={2}
                            md={4}
                            sm={4}
                            xs={6}
                          >
                            <div key={index}>
                              <label
                                className="formlabel"
                                style={{
                                  textTransform: "capitalize",
                                }}
                              >
                                {option.name}
                              </label>
                              <input
                                type="checkbox"
                                className=""
                                value={option.format}
                                name={option.format}
                                onChange={(event) => {
                                  handleVarCheckbox(
                                    event,
                                    option.format,
                                    option.name
                                  );
                                }}
                                checked={selectedIds.some(
                                  (item) => item.value === option.format
                                )}
                              />
                              <input
                                style={{
                                  border: "1px solid red",
                                }}
                                className="vtttinput"
                                type="text"
                                name={"input_" + option.format}
                                id={"input_" + option.format}
                                value={combinedIds[option.key] || option.key}
                                onChange={(event) => {
                                  handleVarPlaceholder(
                                    event,
                                    option.format,
                                    option.name
                                  );
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  )}
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="col"
                style={{
                  marginTop: "20px",
                }}
              >
                <form
                  onSubmit={handleSubmit(formsubmission)}
                  id="buildurl"
                  name="buildurl"
                >
                  <label className="formlabel">Completion Link</label>
                  <textarea
                    type="text"
                    style={{ width: "100%" }}
                    value={completeUrl}
                    readOnly={true}
                    {...register("surveyType", {
                      required: "This field is Required",
                    })}
                  />
                </form>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {showError ? (
              <>
                <p
                  style={{
                    textAlign: "left",
                    color: "#ff0000",
                  }}
                >
                  Completion Link cannot be left empty!
                </p>
              </>
            ) : (
              <></>
            )}
            <button
              className="cbtn saveBtn m-0"
              style={{
                marginBottom: "10px",
                marginRight: "15px",
              }}
              type="submit"
              form="buildurl"
              onClick={(event) => {
                formsubmission(event);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default SupplyBuildUrl;
