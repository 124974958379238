import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import toast from "react-hot-toast";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { BiPlus, BiEditAlt } from "react-icons/bi";
import Select from "react-select";
import Spinner from "react-bootstrap/Spinner";

import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import "./EditQuotaModal.css";

import { useForm, SubmitHandler } from "react-hook-form";
import EditConditionValues from "./EditConditionValues";

function EditQuotaModal(props) {
  const qitem = props.qitem;
  const notifyerror = (e) => toast.error(`${e}`);
  const axiosPrivate = useAxiosPrivate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    resetField,
    formState: { errors },
  } = useForm();

  const [flag, setFlag] = useState(true);
  let tstatus = props.tstatus;

  const [spinner, setSpinner] = useState(false);

  const editForm = async (data) => {
    setSpinner(true);
    try {
      await axiosPrivate
        .patch(
          `quota/update-quota/${qitem.id}`,
          JSON.stringify({
            name: data.qname,
            n: parseInt(data.qnumber),
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            toast.success("Quota updated successfully");
            if (props.flag) {
              props.setFlag(false);
            } else {
              props.setFlag(true);
            }
            props.onHide();
          }
          setSpinner(false);
        });
    } catch (err) {}
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Survey Quota - Edit ss
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <small>Step 1: Survey Quota</small>
          <form
            name="editQuota"
            id="editQuota"
            onSubmit={handleSubmit(editForm)}
          >
            <Row className="mt-2">
              <Col lg={4} md={4} xs={12} className="col">
                <label className="formlabel">Name*</label>
                <input
                  type="text"
                  placeholder="Name"
                  className="CsformText"
                  id="qname"
                  defaultValue={qitem.name}
                  {...register("qname", {
                    required: "This field is Required",
                  })}
                />
                {errors.qname && (
                  <small className="errmsg">{errors.qname.message}</small>
                )}
              </Col>
              <Col lg={2} md={3} xs={12} className="col">
                <label className="formlabel">Quota*</label>
                <input
                  type="number"
                  placeholder="Quota no."
                  className="CsformText"
                  defaultValue={qitem.n}
                  id="qnumber"
                  {...register("qnumber", {
                    required: "This field is Required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Only numbers are allowed",
                    },
                  })}
                />
                {errors.qnumber && (
                  <small className="errmsg">{errors.qnumber.message}</small>
                )}
              </Col>

              <Col lg={2} md={3} xs={12} className="col">
                <button
                  className="supply_setupbtn"
                  type="submit"
                  form="editQuota"
                >
                  {spinner && (
                    <Spinner
                      as="span"
                      style={{ color: "white" }}
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Save
                </button>
              </Col>
            </Row>
          </form>
        </div>
        <EditConditionValues
          sid={props.sid}
          qualdata={props.qualdata}
          qualification={props.qualification}
          qitem={props.qitem}
          tstatus={props.tstatus}
          allQuestions={props.allQuestions}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="supply_setupbtn m-0 ms-2 btn-bg-grey"
          onClick={props.onHide}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditQuotaModal;
