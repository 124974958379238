import Supplyheader from "./Supplyheader";
import "./Supplypage.css";
import { useState, useEffect } from "react";
import Paginations from "../Paginations/Paginations";
import Supplierpaginations from "../Paginations/Supplierpagination";
import Table from "react-bootstrap/Table";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import AddManualSupplier from "./Popup/AddManualSupplier";
import ProjectDetails from "./Popup/Editsupplyquality";
import RequestStatus from "./Popup/RequestStatus";
import SupplierInfo from "./Popup/SupplierInfo";
import { MultiSelect } from "react-multi-select-component";
import { Col, Row } from "react-bootstrap";
import EditIcon from "../../assets/LoginIcons/edit.svg";
import SpinnerLoader from "../UI/Spinner";
import { useForm } from "react-hook-form";
import { FiSettings, FiEye, FiUserPlus, FiFilter } from "react-icons/fi";
import { Spinner } from "react-bootstrap";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { BiReset } from "react-icons/bi";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableSortLabel } from "@mui/material";
import { TableCell, TableRow, TableBody } from "@mui/material";
import moment from "moment";

const Supplyreq = () => {
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [supplyReq, setSupplyReq] = useState([]);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [dataLength, setDataLength] = useState();
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [see, setSee] = useState(false);
  const [setting, setSetting] = useState(false);
  const [show, setShow] = useState(false);

  const [selecteduser, setSelectedUser] = useState([]);
  const [reqowner, setrequestOwner] = useState([]);

  const [projectowner, setProjectname] = useState([]);
  const pname = "SupplyReqDetails";
  const [userId, setUserId] = useState();
  const [id, setId] = useState();
  const [surveyId, setSurveyId] = useState();
  const [surveyIdm, setSurveyIdm] = useState();
  const [loder, setLoder] = useState(false);
  const [sc, setSC] = useState("");
  const [quota, setQUOTA] = useState("");
  const [userStatus, setUserStatus] = useState([]);
  const [userDept, setUserDept] = useState();
  const [selecteduser1, setSelectedUser1] = useState([]);
  const [refreshapi, setRefreshApi] = useState(false);
  const [sid, setSID] = useState();
  const [requestStatusId, setRequestStatusId] = useState();
  const [reverSign, setReverSign] = useState(0);
  const [filter_status, setFilter_status] = useState(false);
  const headers = [
    {
      id: "Priority",
      label: "Priority",
    },
    {
      id: "request_owner.name",
      label: "Req Owner",
    },
    {
      id: "request_status.name",
      label: "Req Status",
    },
    {
      id: "project_name",
      label: "Project Name",
    },
    {
      id: "sc_id",
      label: "SC #",
    },
    {
      id: "Survey Name",
      label: "Survey Name",
    },
    {
      id: "client.name",
      label: "Account Name",
    },
    {
      id: "PM",
      label: "PM",
    },

    {
      id: "Survey Status",
      label: "Survey Sta.",
    },
    {
      id: "n",
      label: "Quota",
    },
    {
      id: "Manual Supp",
      label: "MS",
    },
    {
      id: "Date Opened",
      label: "Date Opened",
    },
    {
      id: "Action",
      label: "Action",
    },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    get_SupplyReq_api();
    requestStatus();
    getId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter_status === false) {
      get_SupplyReq_api();
    } else {
      get_SupplyReq_api_form();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);

  useEffect(() => {
    if (refreshapi === true) {
      getfilterRefresh();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshapi]);

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };

  const requestStatus = async () => {
    try {
      const response = await axiosPrivate.get(
        `/lookup/supplier-request-status`,

        {}
      );

      const userdata = response.data.all_status.map((e, index) => ({
        value: e.id,
        label: e.name,
      }));

      setUserStatus(userdata);
    } catch (err) {
      console.error(err);
    }
  };
  const getId = async () => {
    try {
      const response = await axiosPrivate.get(`/user/user-department/9`, {});

      const userdata = response.data.all_users.map((e, index) => ({
        value: e._id,
        label: e.name,
      }));

      setUserDept(userdata);
    } catch (err) {
      console.error(err);
    }
  };
  const get_SupplyReq_api = async (e) => {
    setLoder(false);

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });

    try {
      const response = await axiosPrivate.get(
        `patner-request/get-all-patner-request?page=${nextPage}&size=${pagesize}&sort=-_id&query_filter=req_status%3D${reqstatus}%26req_owner%3D${reqowner}%26n%3D${quota}%26sc%3D${sc}`
      );
      setSupplyReq(response.data.items);
      setDataLength(response.data.total);
      setRefreshApi(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // }

    setLoder(true);
  };
  const get_SupplyReq_api_form = async (e) => {
    setLoder(false);
    setIsSubmitting(true);

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });
    let quota_value;
    if (e?.Quota && e?.Quota !== undefined) {
      quota_value = e?.Quota;
      setQUOTA(e?.Quota);
    } else if (e?.Quota === "") {
      quota_value = e?.Quota;
      setQUOTA(e?.Quota);
    } else {
      quota_value = quota;
    }
    let SC_value;
    if (e?.SC && e?.SC !== undefined) {
      SC_value = e?.SC;
      setSC(e?.SC);
    } else if (e?.SC === "") {
      SC_value = e?.SC;
      setSC(e?.SC);
    } else {
      SC_value = sc;
    }
    if (
      reqstatus.length !== 0 ||
      reqowner.length !== 0 ||
      quota_value !== "" ||
      SC_value !== ""
    ) {
      try {
        const response = await axiosPrivate.get(
          `patner-request/get-all-patner-request?page=${nextPage}&size=${pagesize}&sort=-_id&query_filter=req_status%3D${reqstatus}%26req_owner%3D${reqowner}%26n%3D${quota_value}%26sc%3D${SC_value}`
        );
        setSupplyReq(response.data.items);
        setDataLength(response.data.total);
        setRefreshApi(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    setLoder(true);
    setIsSubmitting(false);
  };
  const getfilterRefresh = async () => {
    setLoder(false);

    let reqstatus = selecteduser.map((iteml) => {
      return iteml.value;
    });
    let reqowner = selecteduser1.map((iteml) => {
      return iteml.value;
    });
    try {
      const response = await axiosPrivate.get(
        `patner-request/get-all-patner-request?page=${nextPage}&size=${pagesize}&sort=-_id`
      );
      setSupplyReq(response.data.items);
      setDataLength(response.data.total);
      setRefreshApi(false);
      setFilter_status(false);
      reset();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoder(true);
  };
  const handleSort = async (sorttype) => {
    setReverSign(reverSign + 1);
    if (reverSign === 0) {
      try {
        const response = await axiosPrivate.get(
          `patner-request/get-all-patner-request?page=${nextPage}&size=${pagesize}&sort=${sorttype}`,
          {}
        );
        setSupplyReq(response.data.items);
        setDataLength(response.data.total);

        setReverSign(1);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const response = await axiosPrivate.get(
          `patner-request/get-all-patner-request?page=${nextPage}&size=${pagesize}&sort=-${sorttype}`,
          {}
        );
        setSupplyReq(response.data.items);
        setDataLength(response.data.total);
        setReverSign(0);
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <>
      {setting ? (
        <>
          <RequestStatus
            show={setting}
            onHide={() => setSetting(false)}
            userId={userId}
            setSetting={setSetting}
            id={id}
            setRefreshApi={setRefreshApi}
          />
        </>
      ) : (
        <></>
      )}
      {add ? (
        <>
          <AddManualSupplier
            show={add}
            onHide={() => setAdd(false)}
            surveyId={surveyId}
            setAdd={setAdd}
            requestStatusId={requestStatusId}
            setRefreshApi={setRefreshApi}
          />
        </>
      ) : (
        <></>
      )}
      {update ? (
        <>
          <ProjectDetails
            show={update}
            onHide={() => setUpdate(false)}
            sid={sid}
          />
        </>
      ) : (
        <></>
      )}

      {see ? (
        <>
          <SupplierInfo
            show={see}
            onHide={() => setSee(false)}
            surveyIdm={surveyIdm}
          />
        </>
      ) : (
        <></>
      )}

      <Supplyheader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" />{" "}
                Supply-Req-Details
              </h3>
            </div>

            <FiFilter
              className="supplyIconColr"
              title="Filter"
              style={{
                float: "right",
                margin: "5px 0px",
                marginRight: "-5px",
                fontSize: "30px",
                padding: "2px 5px",
                color: "white",
                backgroundColor: "#1a1a1a",
                border: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => {
                setShow(!show);
              }}
            />
          </div>

          <hr />

          {show ? (
            <form onSubmit={handleSubmit(get_SupplyReq_api_form)}>
              <Row
                className="supply_filterbox"
                style={{
                  backgroundColor: "#f1efef",
                  // margin: "15px",
                  paddingBottom: "10px",
                }}
              >
                <Col lg={10} md={9} xs={11} className="supply_col">
                  {loder ? <></> : <></>}
                  <Row>
                    <Col lg={3} md={3} xs={6} className="col ">
                      <label
                        for="status"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Req Status
                      </label>
                      <MultiSelect
                        className="multiselectoption"
                        style={{ width: "45px" }}
                        options={userStatus}
                        value={selecteduser}
                        onChange={(e) => {
                          setSelectedUser(e);
                        }}
                        labelledBy={"Req Status"}
                        placeholder={"Req Status"}
                        isCreatable={true}
                        disableSearch
                      />
                    </Col>
                    <Col lg={3} md={3} xs={6} className="col">
                      <label
                        for="status"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Req Owner
                      </label>
                      <MultiSelect
                        className="multiselectoption"
                        style={{ width: "35px" }}
                        options={userDept}
                        value={selecteduser1}
                        onChange={(e) => {
                          setSelectedUser1(e);
                        }}
                        labelledBy={"Req Owner"}
                        isCreatable={true}
                        disableSearch
                      />
                    </Col>
                    <Col lg={3} md={3} xs={6} className="col">
                      <label
                        for="status"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        SC#
                      </label>
                      <input
                        className="formtext"
                        placeholder="SC"
                        {...register("SC", {
                          pattern: {
                            value: /^\s*[0-9]+\s*$/,

                            message: "Only Integers are allowed",
                          },
                        })}
                      />
                      {errors.SC && (
                        <small className="supplier_setting_error">
                          {errors.SC.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={3} md={3} xs={6} className="col">
                      <label
                        for="status"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Quota(result above entered value)
                      </label>
                      <input
                        className="formtext"
                        placeholder="Quota"
                        {...register("Quota", {
                          pattern: {
                            value: /^\s*[0-9]+\s*$/,
                            message: "Only Integer are allowed",
                          },
                        })}
                      />
                      {errors.Quota && (
                        <small className="supplier_setting_error">
                          {errors.Quota.message}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={2}
                  md={3}
                  xs={12}
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="cbtn saveBtn"
                    style={{
                      maxWidth: "100%",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      setFilter_status(true);
                      setNextPage(1);
                      setPagesize(10);
                    }}
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{ color: "white" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp; Submit
                  </button>
                  <BiReset
                    className="pointer"
                    title="Reset"
                    size={30}
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      setShow(!show);
                      getfilterRefresh();

                      setSelectedUser1([]);
                      setSelectedUser([]);
                      setQUOTA("");
                      setSC("");
                      setValue("SC", "");
                      setValue("Quota", "");
                      setFilter_status(false);
                    }}
                  />{" "}
                </Col>
              </Row>
            </form>
          ) : null}
          <div className="mainContent mainContent2">
            {loder ? (
              <>
                <div className="table_container">
                  <Table
                    bordered={false}
                    hover
                    size="sm"
                    className="customTable"
                  >
                    <thead className="thead">
                      <TableRow>
                        {headers.map((header) => {
                          return (
                            <TableCell key={header.id}>
                              {reverSign === 0 ? (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropUpIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropDownIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </thead>

                    <tbody>
                      {supplyReq.length === 0 ? (
                        <>
                          <tr>No data Found</tr>
                        </>
                      ) : (
                        supplyReq.map((item, itemIndex) => (
                          <tr key={itemIndex}>
                            {item.priority === undefined ||
                            item.priority === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <span className="stanBadge">
                                    {item.priority.name}
                                  </span>
                                </td>
                              </>
                            )}

                            {item.request_owner === undefined ||
                            item.request_owner === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{item.request_owner.name}</td>
                              </>
                            )}

                            {item.request_status.name === undefined ||
                            item.request_status.name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <span className="stanBadge">
                                    {item.request_status.name}
                                  </span>
                                </td>
                              </>
                            )}

                            {item.project_name === undefined ||
                            item.project_name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td title={item.project_name}>
                                  {item.project_name}
                                </td>
                              </>
                            )}

                            {item.sc_id === undefined || item.sc_id === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{item.sc_id}</td>
                              </>
                            )}

                            {item.survey_name === undefined ||
                            item.survey_name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td title={item.survey_name}>
                                  {item.survey_name}
                                </td>
                              </>
                            )}

                            {item.client.name === undefined ||
                            item.client.name === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{item.client.name.substr(0, 10)}</td>
                              </>
                            )}

                            {item.project_manager.primary_project_manager ===
                              undefined ||
                            item.project_manager.primary_project_manager ===
                              null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>
                                  {
                                    item.project_manager.primary_project_manager
                                      .name
                                  }
                                </td>
                              </>
                            )}
                            {item.survey_status === undefined ||
                            item.survey_status === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                {/* <td>{item.survey_status.name}</td> */}
                                <td>
                                  {item.survey_status.name === "Live" ? (
                                    <>
                                      <span className="live">Live</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Pending" ? (
                                    <>
                                      <span className="pending">Pending</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Test Mode" ? (
                                    <>
                                      <span className="testmode">
                                        Test Mode
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Paused" ? (
                                    <>
                                      <span className="Paused">Paused</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Id Awaited" ? (
                                    <>
                                      <span className="IdAwaited">
                                        Id Awaited
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Billed" ? (
                                    <>
                                      <span className="billed">Billed</span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {item.survey_status.name === "Cancelled" ? (
                                    <>
                                      <span className="cancelled">
                                        Cancelled
                                      </span>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </>
                            )}
                            {item.n === undefined || item.n === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{item.n}</td>
                              </>
                            )}

                            {item.manual_supp === undefined ||
                            item.manual_supp === null ? (
                              <>
                                <td>-</td>
                              </>
                            ) : (
                              <>
                                <td>{item.manual_supp}</td>
                              </>
                            )}

                            <td>
                              {item.survey_track_time === null ? (
                                <>-</>
                              ) : (
                                <>
                                  {" "}
                                  {moment(item.survey_track_time).format(
                                    "D/MM/YYYY"
                                  )}
                                </>
                              )}
                            </td>

                            <td>
                              <FiUserPlus
                                className="SupIcon sdIcon"
                                title="Add"
                                onClick={() => {
                                  setAdd(true);
                                  setSurveyId(item.survey_id);
                                  setRequestStatusId(item.request_owner.name);
                                }}
                              />
                              <img
                                src={EditIcon}
                                alt="EditIcon"
                                title="Project des."
                                className="SupIcon sdIcon"
                                onClick={() => {
                                  setUpdate(true);
                                  setSID(item.survey_id);
                                }}
                              />

                              <FiEye
                                className="SupIcon sdIcon"
                                title="Supplier Info."
                                onClick={() => {
                                  setSee(true);
                                  setSurveyIdm(item.survey_id);
                                }}
                              />
                              <FiSettings
                                className="SupIcon sdIcon"
                                title="Request S/O"
                                onClick={() => {
                                  setSetting(true);
                                  setUserId(item.survey_id);
                                  setId(item.survey_id);
                                }}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              <SpinnerLoader />
            )}
          </div>

          <Supplierpaginations
            userlist={dataLength}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
            page={nextPage}
            pagesize={pagesize}
          />
        </div>
      </div>
    </>
  );
};

export default Supplyreq;
