import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useEffect } from 'react';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { useForm } from 'react-hook-form';
import './ESupplier.css';
import SurveyTracking from '../../Tracker/Trackerpopup/SurveyTracking';
import SpinnerLoader from '../../../UI/Spinner';
import Terminationbuildurledit from './Terminationbuildurledit ';
import Spinner from 'react-bootstrap/Spinner';
import SupplyBuildUrl from './Supplybuildurl';

function EditSupplier(props) {
    const id = props.childId;
    const axiosPrivate = useAxiosPrivate();
    const [setting, setSetting] = useState(false);
    const [showlink, setShowlink] = useState(false);
    const [loder, setLoder] = useState(false);
    const [statusn, setStatusn] = useState('');
    const [userStatus, setUserStatus] = useState([
        { id: 1, name: 'Active' },
        { id: 0, name: 'InActive' },
    ]);

    const [userStatusid, setUserStatusid] = useState();
    const [screenCaptcha, setScreenCaptcha] = useState();
    const [redHiring, setRedhiring] = useState('');
    const [resercchDefence, setReserchDefence] = useState();
    const [compbuildurledit, setCompBuildUrledit] = useState(false);
    const [tbuildurledit, setTBuildUrledit] = useState(false);
    const [tbuilurl, setBuildurl] = useState(false);
    const [qbuildurledit, setQBuildUrledit] = useState(false);

    const [securityedit, setSecurityUrledit] = useState(false);
    const [complitionlinkedit, setComplitionlinkedit] = useState('');
    const [terminationlinkedit, setTerminationlinkedit] = useState('');
    const [quotafulllinkedit, setQuotafulllinkedit] = useState('');
    const [securityfulllinkedit, setSecurityfulllinkedit] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [supplierName, setSupplierName] = useState('');
    const [screen, setScreen] = useState('');
    const [country_check, setCountryCheck] = useState('');
    const [proxyCheck, setProxyCheck] = useState('');
    const [duplicateIp, setDuplicateIp] = useState('');
    const [qualificationScreen, setQualificationScreen] = useState('');
    const [redHerringData, setRedHerringData] = useState('');
    const [speederTerminate, setSpeederTerminate] = useState('');
    const [updateLink, setUpdateLink] = useState(false);
    const [linkButtonShow, setLinkButtonShow] = useState(true);

    const notifyerror = (e) => toast.error(`${e}`);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
    } = useForm();
    useEffect(() => {
        getSurveySupplier();
    }, [props.show]);

    const handleSaveButton = async (data) => {
        var statusid;

        if (statusn == '') {
            statusid = data.Status.value;
        } else {
            statusid = statusn;
        }

        setSpinner(true);
        var payload;

        if (updateLink) {
            payload = {
                status: data.Status,
                supplier_cost: data.SupplierCost,
                n: data.Quota,
                screening: {
                    pre_screening: screen,
                    qualification_screening: qualificationScreen,
                    red_herring_screen: redHiring,
                },
                security_check: {
                    screen_captcha: screenCaptcha,
                    research_defender: resercchDefence,
                    red_herring_screen: redHiring,
                    speeder_terminate: speederTerminate,
                    country_check: country_check,
                    proxy_check: proxyCheck,
                    duplicate_ip: duplicateIp,
                },
                has_exceptional_endlinks: showlink,
                link: {
                    supplier_link: {
                        complete: data.complinkUpdateLink,
                        terms: data.termlinkUpdateLink,
                        overquotas: data.quotalinkUpdateLink,
                        quality_terms: data.SecurityLinkUpdateLink,
                    },
                    supplier_exceptional_link: {
                        complete: data.Excomplink,
                        terms: data.Extermlink,
                        overquotas: data.Exquotalink,
                        quality_terms: data.ExSecurityLink,
                    },
                },
            };
        } else {
            payload = {
                status: data.Status,
                supplier_cost: data.SupplierCost,
                n: data.Quota,
                screening: {
                    pre_screening: screen,
                    qualification_screening: qualificationScreen,
                    red_herring_screen: redHiring,
                },
                security_check: {
                    screen_captcha: screenCaptcha,
                    research_defender: resercchDefence,
                    red_herring_screen: redHiring,
                    speeder_terminate: speederTerminate,
                    country_check: country_check,
                    proxy_check: proxyCheck,
                    duplicate_ip: duplicateIp,
                },
                has_exceptional_endlinks: showlink,
                link: {
                    supplier_link: {
                        complete: data.complink,
                        terms: data.termlink,
                        overquotas: data.quotalink,
                        quality_terms: data.SecurityLink,
                    },
                    supplier_exceptional_link: {
                        complete: data.Excomplink,
                        terms: data.Extermlink,
                        overquotas: data.Exquotalink,
                        quality_terms: data.ExSecurityLink,
                    },
                },
            };
        }

        try {
            await axiosPrivate
                .put(`survey-supplier/edit-survey-supplier?survey_supplier_id=${id}`, JSON.stringify(payload), {
                    headers: { 'Content-Type': 'application/json' },
                })
                .then((response) => {
                    if (response.status === 200) {
                        toast.success('Supplier updated successfully');
                        props.setEditSupplier(false);
                        props.setEdit_SetUp(true);
                    }
                });
        } catch (err) {
            notifyerror(err.response.statusText);
        }

        setSpinner(false);
    };

    const options = [
        {
            label: 'Live',
            value: 'Live',
        },

        {
            label: 'Pending',
            value: 'Pending',
        },
        {
            label: 'Oﬀ spec inquiry',
            value: 'Inquiry',
        },
        {
            label: 'Paused',
            value: 'Paused',
        },
    ];

    useEffect(() => {
        if (id == undefined) {
        } else {
            getSurveySupplier();
        }
    }, [id]);

    const functionCallUpdateLink = async () => {
        try {
            if (id != undefined) {
                const response = await axiosPrivate.get(`survey-supplier/get-setup-survey-supplier?survey_supplier_id=${id}`);
                setValue('complinkUpdateLink', response.data.survey_supplier.link.complete);
                setValue('termlinkUpdateLink', response.data.survey_supplier.link.terminate);
                setValue('quotalinkUpdateLink', response.data.survey_supplier.link.overquotas);
                setValue('SecurityLinkUpdateLink', response.data.survey_supplier.link.security_terminate);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getSurveySupplier = async () => {
        setLoder(true);

        try {
            if (id != undefined) {
                const response = await axiosPrivate.get(`survey-supplier/get-survey-supplier?survey_supplier_id=${id}`);

                setUserStatusid(response.data.survey_supplier.status.toString());

                setSupplierName(response.data.survey_supplier.supplier.name);

                setScreen(response.data.survey_supplier.screening.pre_screening);
                setQualificationScreen(response.data.survey_supplier.screening.qualification_screening);
                setProxyCheck(response.data.survey_supplier.security_check.proxy_check);
                setRedhiring(response.data.survey_supplier.screening.red_herring_screen);

                setDuplicateIp(response.data.survey_supplier.security_check.duplicate_ip);
                setCountryCheck(response.data.survey_supplier.security_check.country_check);
                setRedHerringData(response.data.survey_supplier.screening.red_herring_screen);
                setSpeederTerminate(response.data.survey_supplier.security_check.speeder_terminate);
                setScreenCaptcha(response.data.survey_supplier.security_check.screen_captcha);

                setReserchDefence(response.data.survey_supplier.security_check.research_defender);

                setValue('Status', response.data.survey_supplier.status.toString());
                setValue('Quota', response.data.survey_supplier.n);
                setValue('SupplierCost', response.data.survey_supplier.supplier_cost);
                setShowlink(response.data.survey_supplier.has_exceptional_endlinks);

                // if (response.data.survey_supplier.has_exceptional_endlinks == true) {

                if (response.data.survey_supplier.has_exceptional_endlinks) {
                    if (response.data.survey_supplier.link.supplier_exceptional_link.complete !== null) {
                        setValue('Excomplink', response.data.survey_supplier.link.supplier_exceptional_link.complete);
                        setComplitionlinkedit(response.data.survey_supplier.link.supplier_exceptional_link.complete);
                    } else {
                        setValue('Excomplink', '');
                        setComplitionlinkedit('');
                    }

                    if (response.data.survey_supplier.link.supplier_exceptional_link.terms !== null) {
                        setValue('Extermlink', response.data.survey_supplier.link.supplier_exceptional_link.terms);
                        setTerminationlinkedit(response.data.survey_supplier.link.supplier_exceptional_link.terms);
                    } else {
                        setValue('Extermlink', '');
                        setTerminationlinkedit('');
                    }
                    if (response.data.survey_supplier.link.supplier_exceptional_link.quality_terms !== null) {
                        setValue('ExSecurityLink', response.data.survey_supplier.link.supplier_exceptional_link.quality_terms);
                        setSecurityfulllinkedit(response.data.survey_supplier.link.supplier_exceptional_link.quality_terms);
                    } else {
                        setValue('ExSecurityLink', response.data.survey_supplier.link.supplier_exceptional_link.terms);
                        setSecurityfulllinkedit(response.data.survey_supplier.link.supplier_exceptional_link.terms);
                    }

                    if (response.data.survey_supplier.link.supplier_exceptional_link.overquotas !== null) {
                        setValue('Exquotalink', response.data.survey_supplier.link.supplier_exceptional_link.overquotas);
                        setQuotafulllinkedit(response.data.survey_supplier.link.supplier_exceptional_link.overquotas);
                    } else {
                        setValue('Exquotalink', '');
                        setQuotafulllinkedit('');
                    }
                } else {
                    setValue('Excomplink', '');
                    setValue('Extermlink', '');
                    setValue('ExSecurityLink', '');
                    setValue('Exquotalink', '');
                }

                // } else {
                setValue('complink', response.data.survey_supplier.link.supplier_link.complete);

                setValue('termlink', response.data.survey_supplier.link.supplier_link.terms);

                if (response.data.survey_supplier.link.supplier_link.quality_terms === null) {
                    setValue('SecurityLink', response.data.survey_supplier.link.supplier_link.terms);
                } else {
                    setValue('SecurityLink', response.data.survey_supplier.link.supplier_link.quality_terms);
                }

                setValue('quotalink', response.data.survey_supplier.link.supplier_link.overquotas);
                // }
            }
        } catch (err) {
            console.error(err);
        }
        setLoder(false);
    };
    const statusReg = register('Status', {
        required: 'This field is Required',
    });
    return (
        <>
            <SurveyTracking show={setting} setSetting={setSetting} onHide={() => setSetting(false)} />

            {compbuildurledit ? <SupplyBuildUrl show={compbuildurledit} onHide={() => setCompBuildUrledit(false)} setbuildurledit={setCompBuildUrledit} tbuildurledit={compbuildurledit} settermination={setValue} keyvalue={'Excomplink'} baseurl={complitionlinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setComplitionlinkedit} /> : <></>}
            {tbuilurl ? <SupplyBuildUrl show={tbuilurl} onHide={() => setBuildurl(false)} setbuildurledit={setBuildurl} tbuildurledit={tbuilurl} settermination={setValue} keyvalue={'Extermlink'} baseurl={terminationlinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setTerminationlinkedit} /> : <></>}

            {qbuildurledit ? <SupplyBuildUrl show={qbuildurledit} onHide={() => setQBuildUrledit(false)} setbuildurledit={setQBuildUrledit} tbuildurledit={qbuildurledit} settermination={setValue} keyvalue={'Exquotalink'} baseurl={quotafulllinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setQuotafulllinkedit} /> : <></>}
            {securityedit ? (
                <>
                    <SupplyBuildUrl show={securityedit} onHide={() => setSecurityUrledit(false)} setbuildurledit={setSecurityUrledit} tbuildurledit={securityedit} settermination={setValue} keyvalue={'ExSecurityLink'} baseurl={securityfulllinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setSecurityfulllinkedit} />
                </>
            ) : (
                <></>
            )}

            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '16px', paddingLeft: '10px' }}>
                        <ModeEditIcon style={{ fontSize: '20px' }} />
                        &nbsp; Edit Supplier - {supplierName}
                    </Modal.Title>
                </Modal.Header>
                {loder ? (
                    <>
                        <SpinnerLoader />
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Body>
                            <form onSubmit={handleSubmit(handleSaveButton)}>
                                <div className="csmulti">
                                    <div className="px-10">
                                        <Row>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Status</label>

                                                <select
                                                    className="formtext"
                                                    {...statusReg}
                                                    onChange={(e) => {
                                                        statusReg.onChange(e);
                                                        setUserStatusid(e.target.value);
                                                    }}
                                                    value={userStatusid}
                                                >
                                                    {userStatus.map((e) => (
                                                        <option value={e.id}>{e.name}</option>
                                                    ))}
                                                </select>
                                                {errors.Status && <small className="errmsg">{errors.Status.message}</small>}
                                            </Col>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Supplier Cost</label>
                                                <input
                                                    {...register('SupplierCost', {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9.]+$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="Supplier Cost"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors.SupplierCost && <small className="errmsg ">{errors.SupplierCost.message}</small>}
                                            </Col>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Quota</label>
                                                <input
                                                    {...register('Quota', {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="Quota"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors.Quota && <small className="errmsg">{errors.Quota.message}</small>}
                                            </Col>
                                            {linkButtonShow ? (
                                                <>
                                                    <Col lg={3} md={4} sm={4} xs={6}>
                                                        <div
                                                            className="pswtchbtn  q_edit_switcbtn"
                                                            // style={{
                                                            //   display: "flex",
                                                            //   justifyContent: "flex-end",
                                                            //   textAlign: "end",
                                                            //   // display: "none",
                                                            // }}
                                                        >
                                                            <div>
                                                                <label className="formlabelviewzone w-100">Update Link</label>
                                                                <BootstrapSwitchButton
                                                                    className="switch btn on btn-outline-success btn-xs"
                                                                    size="xs"
                                                                    width={60}
                                                                    checked={updateLink}
                                                                    onlabel="ON"
                                                                    offlabel="OFF"
                                                                    onstyle="outline-success"
                                                                    offstyle="outline-danger"
                                                                    onChange={(e) => {
                                                                        setUpdateLink(e);
                                                                        if (e) {
                                                                            functionCallUpdateLink();
                                                                        } else {
                                                                            getSurveySupplier();
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </Row>

                                        <Row className="py-10">
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Pre Screening</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={screen}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setScreen(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Country Check</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={country_check}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setCountryCheck(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Proxy Check</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={proxyCheck}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setProxyCheck(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Duplicate IP</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={duplicateIp}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setDuplicateIp(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Qualification Screen</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={qualificationScreen}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setQualificationScreen(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Screen Captcha</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={screenCaptcha}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setScreenCaptcha(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Red Herring Screen</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={redHiring}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setRedhiring(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Speeder Terminate</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={speederTerminate}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setSpeederTerminate(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6} className="suppliersetup m-0">
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Research Defender</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={resercchDefence}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setReserchDefence(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn s_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Exception Link</label>
                                                    <BootstrapSwitchButton
                                                        onChange={(e) => {
                                                            if (e === true) {
                                                                setShowlink(true);
                                                                setLinkButtonShow(false);
                                                            } else {
                                                                setShowlink(false);
                                                                setLinkButtonShow(true);
                                                            }
                                                        }}
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        checked={showlink}
                                                        width={60}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {showlink ? (
                                        <>
                                            <div className="buildURL_wrapper">
                                                <Row>
                                                    <label className="formlabelviewzone">Completion Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Excomplink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Completion Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setComplitionlinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {complitionlinkedit === '' ? <>{errors.Excomplink && <small className="supply_errormsg m-0">{errors.Excomplink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                setCompBuildUrledit(true);
                                                                // setTBuildUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label className="formlabelviewzone">Termination Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Extermlink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Termination Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setTerminationlinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {terminationlinkedit === '' ? <>{errors.Extermlink && <small className="supply_errormsg m-0">{errors.Extermlink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setBuildurl(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label className="formlabelviewzone">Quotafull Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Exquotalink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="  Quotafull Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setQuotafulllinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {quotafulllinkedit === '' ? <>{errors.Exquotalink && <small className="supply_errormsg m-0">{errors.Exquotalink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                setQBuildUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label className="formlabelviewzone editSupplierMarginIssue">Quality Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('ExSecurityLink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Quality Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setSecurityfulllinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {securityfulllinkedit === '' ? <>{errors.ExSecurityLink && <small className="supply_errormsg m-0">{errors.ExSecurityLink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                // setQBuildUrledit(true);
                                                                setSecurityUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {/* defaultttttttttttttttttttttttttttttt */}

                                            {updateLink ? (
                                                <>
                                                    <>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Completion Link</label>
                                                                <input
                                                                    {...register('complinkUpdateLink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Completion Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.complete}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Termination Link</label>
                                                                <input
                                                                    {...register('termlinkUpdateLink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Termiation Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.terminate}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Quotafull Link</label>
                                                                <input
                                                                    {...register('quotalinkUpdateLink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Quotafull Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.overquotas}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                            <Col> </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Quality Link</label>
                                                                <input
                                                                    {...register('SecurityLinkUpdateLink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Quality Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={"surveySupplierData.link.overquotas"}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                            <Col> </Col>
                                                        </Row>
                                                    </>
                                                </>
                                            ) : (
                                                <>
                                                    {' '}
                                                    <>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Completion Link</label>
                                                                <input
                                                                    {...register('complink', {
                                                                        // required: "This field is Required",
                                                                    })}
                                                                    placeholder="Completion Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.complete}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Termination Link</label>
                                                                <input
                                                                    {...register('termlink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Termiation Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.terminate}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Quotafull Link</label>
                                                                <input
                                                                    {...register('quotalink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Quotafull Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={surveySupplierData.link.overquotas}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                            <Col> </Col>
                                                        </Row>
                                                        <Row className="suppliersetup">
                                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                                <label className="formlabelviewzone">Quality Link</label>
                                                                <input
                                                                    {...register('SecurityLink', {
                                                                        required: 'This field is Required',
                                                                    })}
                                                                    placeholder="Quality Link"
                                                                    className="formtextviewzone"
                                                                    disabled={true}
                                                                    // defaultValue={"surveySupplierData.link.overquotas"}
                                                                    type="text"
                                                                />
                                                            </Col>
                                                            <Col> </Col>
                                                        </Row>
                                                    </>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                {/* <hr /> */}
                                <Modal.Footer className="mt-4">
                                    <button className="supply_setupbtn m-0">
                                        {spinner && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                        &nbsp; Submit
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
}
export default EditSupplier;
