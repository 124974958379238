import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import '../PopUp/SetupSupplier.css';
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../../UI/Spinner';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { event } from 'jquery';
const Quotafullbuildurl = (props) => {
    const inputRef = useRef(null);
    const axiosPrivate = useAxiosPrivate();
    const [devicetype, setDeviceTYpe] = useState([]);
    const [disabledOptions, setDisabledOptions] = useState([]);
    const [textareaValue, setTextareaValue] = useState();
    const [variableType, setVariableType] = useState([]);
    const [checkedState, setCheckedState] = useState({});
    const [questionstate, setQuestionstate] = useState(true);
    const [checkboxOptions, setCheckboxOptions] = useState([]);
    const [loder, setLoder] = useState(false);
    const [surveyUrl, setSurveyurl] = useState('');
    const [tid, setTid] = useState([]);
    const [vttt, setVtt] = useState([]);
    const [checkquestion, setCheckquestion] = useState(true);
    const [editedValues, setEditedValues] = useState({});
    const [baseUrl, setBaseUrl] = useState(props.quotafulllinkedit);
    const [complUrl, setCompUrl] = useState();
    const [combinedId, setCombinedId] = useState([]);
    const [combinedIdslash, setCombinedIdslash] = useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();
    useEffect(() => {
        if (props.qbuildurl === true) {
            const get_Supplierlist_api = async (pr) => {
                setLoder(false);
                try {
                    const response = await axiosPrivate.get(
                        `survey-supplier/exceptional-variable?survey_supplier_id=64fb591ea1f630b84383f94b`
                    );

                    setTid([]);

                    setTid((prevData) => [
                        ...prevData,
                        {
                            name: response.data.variable.tid.name,
                            value: response.data.variable.tid.format,
                            label: response.data.variable.tid.format,
                        },
                    ]);

                    setDeviceTYpe([]);
                    response.data.variable.custom.map((ite) => {
                        setDeviceTYpe((val) => [
                            ...val,
                            {
                                name: ite.name,
                                value: ite.name,
                                label: ite.name,
                            },
                        ]);
                    });
                    setVtt([]);
                    if (response.data.variable.pid) {
                        setVtt((prevData) => [
                            ...prevData,
                            {
                                name: response.data.variable.pid.name,
                                value: response.data.variable.pid.format,
                                label: response.data.variable.pid.name,
                            },
                        ]);
                    }
                } catch (err) {
                    // console.error(err);
                }
                setLoder(true);
            };

            get_Supplierlist_api();
        }
    }, [props.qbuildurl]);

    const handleMultiSelectChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map(
            (option) => ({
                name: option.label,
                value: option.value,
                label: option.label,
                disabled: false, // Enable the option when selected
            })
        );
        setVariableType((prevVariableType) => {
            const updatedVariableType = selectedOptions.filter(
                (option) =>
                    !prevVariableType.some(
                        (variable) => variable.value === option.value
                    )
            );
            return [...prevVariableType, ...updatedVariableType];
        });
        setCheckboxOptions((prevCheckboxOptions) => {
            const updatedCheckboxOptions = selectedOptions.filter(
                (option) =>
                    !prevCheckboxOptions.some(
                        (checkboxOption) =>
                            checkboxOption.value === option.value
                    )
            );
            return [...prevCheckboxOptions, ...updatedCheckboxOptions];
        });
        setCombinedId((prevCombinedId) => [
            ...prevCombinedId,
            ...selectedOptions.map((option) => ({
                formate: option.name,
                value: option.value,
            })),
        ]);
        setCombinedIdslash((prevCombinedId) => [
            ...prevCombinedId,
            ...selectedOptions.map((option) => ({
                formate: option.name,
                value: option.value,
            })),
        ]);

        // Disable the selected options
        setDisabledOptions((prevDisabledOptions) => [
            ...prevDisabledOptions,
            ...selectedOptions.map((option) => option.value),
        ]);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        // Enable or disable the selected option in the select element
        setDisabledOptions((prevDisabledOptions) => {
            if (!checked) {
                return prevDisabledOptions.filter((option) => option !== name);
            } else {
                return [...prevDisabledOptions];
            }
        });
        setVariableType((prevVariableType) =>
            prevVariableType.filter((variable) => variable.value !== name)
        );

        if (!checked) {
            // Remove the value from combinedId
            setCombinedId((prevCombinedId) => {
                const updatedCombinedId = prevCombinedId.filter(
                    (option) => option.value !== name
                );
                return updatedCombinedId;
            });
            setCombinedIdslash((prevCombinedId) => {
                const updatedCombinedId = prevCombinedId.filter(
                    (option) => option.value !== name
                );
                return updatedCombinedId;
            });
            setCheckboxOptions((prevCheckboxOptions) =>
                prevCheckboxOptions.filter((option) => option.value !== name)
            );
        } else {
            const selectedOption = devicetype.find(
                (option) => option.value === name
            );
            if (selectedOption) {
                setVariableType((prevVariableType) => [
                    ...prevVariableType,
                    selectedOption,
                ]);
            }

            // Append the value to combinedId
            setCombinedId((prevCombinedId) => [
                ...prevCombinedId,
                {
                    name: `&${selectedOption.name}=[%${selectedOption.value}%]`,
                    value: selectedOption.value,
                },
            ]);
            setCombinedIdslash((prevCombinedId) => [
                ...prevCombinedId,
                {
                    name: `&${selectedOption.name}=[%${selectedOption.value}%]`,
                    value: selectedOption.value,
                },
            ]);
        }
    };
    const handleInputsurvey = (e) => {
        setCombinedId((prevCombinedId) => {
            // Check if tid[0].value already exists in prevCombinedId
            const exists = prevCombinedId.some(
                (item) => item.value === tid[0].value
            );

            // If tid[0].value doesn't exist, add it to prevCombinedId
            if (!exists) {
                return [
                    ...prevCombinedId,
                    {
                        formate: tid[0].name,
                        value: tid[0].value,
                    },
                ];
            }

            // If tid[0].value already exists, return prevCombinedId unchanged
            return prevCombinedId;
        });
    };

    const handleTidChange = (event, value) => {
        const inputValue = event.target.value;

        setCombinedId((prevCombinedId) => {
            const updatedCombinedId = prevCombinedId.map((option) => {
                if (option.value === value) {
                    // Conditionally include the '=' sign
                    const name = inputValue
                        ? `${inputValue}=[%${option.value}%]`
                        : `[%${option.value}%]`;
                    return {
                        ...option,
                        name,
                    };
                }
                return option;
            });
            return updatedCombinedId;
        });
        setEditedValues((prevEditedValues) => ({
            ...prevEditedValues,
            [value]: inputValue,
        }));
    };

    const handleInputChange = (event, value) => {
        const { name, value: inputValue } = event.target;
        setCheckboxOptions((prevCheckboxOptions) => {
            return prevCheckboxOptions.map((option) => {
                if (option.value === value) {
                    return {
                        ...option,
                        name: inputValue,
                        place: inputValue,
                    };
                }
                return option;
            });
        });

        setCombinedId((prevCombinedId) => {
            const updatedCombinedId = prevCombinedId.map((option) => {
                if (option.value === value) {
                    // Conditionally include the '=' sign
                    const name = inputValue
                        ? `&${inputValue}=[%${option.value}%]`
                        : `/[%${option.value}%]`;
                    return {
                        ...option,
                        name,
                    };
                }
                return option;
            });
            return updatedCombinedId;
        });

        setEditedValues((prevEditedValues) => ({
            ...prevEditedValues,
            [value]: inputValue,
        }));
    };

    const handleVttttInputChange = (event, value) => {
        const { value: inputValue } = event.target;
        setVtt((prevVtt) => {
            const updatedVtt = prevVtt.map((item) => {
                if (item.value === value) {
                    return {
                        ...item,
                        name: inputValue,
                        place: inputValue,
                    };
                }
                return item;
            });
            return updatedVtt;
        });

        setCombinedId((prevCombinedId) => {
            const updatedCombinedId = prevCombinedId.map((option) => {
                if (option.value === value) {
                    // Conditionally include the '=' sign
                    const name = inputValue
                        ? `&${inputValue}=[%${option.value}%]`
                        : `/[%${option.value}%]`;
                    return {
                        ...option,
                        name,
                    };
                }
                return option;
            });
            return updatedCombinedId;
        });

        setEditedValues((prevEditedValues) => ({
            ...prevEditedValues,
            [value]: inputValue,
        }));
    };

    const handlesidChange = (event, index) => {
        const { checked } = event.target;
        const { name, value } = vttt[index];

        setCombinedId((prevCombinedId) => {
            let updatedCombinedId = [...prevCombinedId];

            if (checked) {
                // Append the value to the combinedId if it doesn't exist
                setCheckedState((prevData) => ({
                    ...prevData,
                    name: checked,
                }));
                const isValueExists = updatedCombinedId.some(
                    (param) => param.value === value
                );
                if (!isValueExists) {
                    const paramToAdd = {
                        name: `&${name}=[%${value}%]`,
                        value,
                    };
                    updatedCombinedId.push(paramToAdd);
                }
            } else {
                // Remove the value from the combinedId
                updatedCombinedId = updatedCombinedId.filter(
                    (param) => param.value !== value
                );
                setCheckedState((prevData) => ({
                    ...prevData,
                    name: false,
                }));
                setEditedValues((prevEditedValues) => ({
                    ...prevEditedValues,
                    [value]: name,
                }));
            }

            return updatedCombinedId;
        });
        setCombinedIdslash((prevCombinedId) => {
            let updatedCombinedId = [...prevCombinedId];

            if (checked) {
                // Append the value to the combinedId if it doesn't exist
                setCheckedState((prevData) => ({
                    ...prevData,
                    name: checked,
                }));
                const isValueExists = updatedCombinedId.some(
                    (param) => param.value === value
                );
                if (!isValueExists) {
                    const paramToAdd = {
                        name: `&${name}=[%${value}%]`,
                        value,
                    };
                    updatedCombinedId.push(paramToAdd);
                }
            } else {
                // Remove the value from the combinedId
                updatedCombinedId = updatedCombinedId.filter(
                    (param) => param.value !== value
                );
                setCheckedState((prevData) => ({
                    ...prevData,
                    name: false,
                }));
            }

            return updatedCombinedId;
        });
    };

    const [questionMarkPosition, setQuestionMarkPosition] = useState(-1); // Initialize with -1 to indicate no position

    const handleQuestionmark = (event, value) => {
        const inputValue = event.target.checked;
        const hasQuestionMark = surveyUrl.includes('?');
        setQuestionstate(inputValue);
        if (inputValue) {
            if (questionMarkPosition !== -1) {
                const updatedUrl =
                    surveyUrl.substring(0, questionMarkPosition) +
                    '?' +
                    surveyUrl.substring(questionMarkPosition + 1);
                setSurveyurl(updatedUrl);
                setQuestionMarkPosition(-1);
            }
        } else {
            if (hasQuestionMark) {
                const questionMarkIndex = surveyUrl.indexOf('?');
                setQuestionMarkPosition(questionMarkIndex);

                const updatedUrl =
                    surveyUrl.substring(0, questionMarkIndex) +
                    '/' +
                    surveyUrl.substring(questionMarkIndex + 1);
                setSurveyurl(updatedUrl);
            } else {
                // Append '/' at the end when there is no '?'
                setSurveyurl(surveyUrl + '/');
            }
        }

        // Update setCombinedIdslash conditionally based on matching name and value
        setCombinedIdslash((prevCombinedIdslash) => {
            return prevCombinedIdslash.map((item, index) => {
                const match = combinedId.find(
                    (combinedItem) => combinedItem.value === item.value
                );

                if (match) {
                    // If there's a match, check inputValue and conditionally update the item
                    if (!inputValue) {
                        const nameWithoutSelectedOption =
                            index === 0
                                ? item.name.includes('=')
                                    ? item.name.split('=')[1]
                                    : item.name
                                : item.name.replace(/&[^=]+=/g, '/');
                        return {
                            ...item,
                            name: nameWithoutSelectedOption,
                        };
                    } else {
                        // If inputValue is true, keep the item as-is
                        return item;
                    }
                } else {
                    // If there's no match, keep the item as-is
                    return item;
                }
            });
        });
    };

    const handlecheck = (event, index, isCheckbox) => {
        const { checked } = event.target;
        const { name, value } = isCheckbox
            ? checkboxOptions[index]
            : vttt[index];

        setDisabledOptions((prevDisabledOptions) => {
            if (!checked) {
                return prevDisabledOptions.filter((option) => option !== value);
            } else {
                return [...prevDisabledOptions];
            }
        });
        setVariableType((prevVariableType) =>
            prevVariableType.filter((variable) => variable.value !== name)
        );
        setCombinedId((prevCombinedId) => {
            let updatedCombinedId = [...prevCombinedId];

            if (checked) {
                // Append the value to the combinedId if it doesn't exist
                const isValueExists = updatedCombinedId.some(
                    (param) => param.value === value
                );
                if (!isValueExists) {
                    const paramToAdd = {
                        // name: `&${name}=[%${value}%]`,
                        value,
                        formate: `${name}`,
                    };
                    updatedCombinedId.push(paramToAdd);
                }
            } else {
                // Remove the value from the combinedId
                updatedCombinedId = updatedCombinedId.filter(
                    (param) => param.value !== value
                );
            }

            return updatedCombinedId;
        });

        setCombinedIdslash((prevCombinedId) => {
            let updatedCombinedId = [...prevCombinedId];

            if (checked) {
                // Append the value to the combinedId if it doesn't exist
                const isValueExists = updatedCombinedId.some(
                    (param) => param.value === value
                );
                if (!isValueExists) {
                    const paramToAdd = {
                        // name: `&${name}=[%${value}%]`,
                        value,
                        formate: `${name}`,
                    };
                    updatedCombinedId.push(paramToAdd);
                }
            } else {
                // Remove the value from the combinedId
                updatedCombinedId = updatedCombinedId.filter(
                    (param) => param.value !== value
                );
            }

            return updatedCombinedId;
        });

        // Additional logic based on isCheckbox parameter
        if (isCheckbox) {
            setVariableType((prevVariableType) =>
                checked
                    ? [...prevVariableType, checkboxOptions[index]]
                    : prevVariableType.filter(
                          (variable) => variable.value !== value
                      )
            );

            if (!checked) {
                // Remove the value from combinedId
                setCombinedId((prevCombinedId) =>
                    prevCombinedId.filter((option) => option.value !== value)
                );
                setCombinedIdslash((prevCombinedId) =>
                    prevCombinedId.filter((option) => option.value !== value)
                );
                setCheckboxOptions((prevCheckboxOptions) =>
                    prevCheckboxOptions.filter(
                        (option) => option.value !== value
                    )
                );
            } else {
                const selectedOption = checkboxOptions.find(
                    (option) => option.value === value
                );

                if (selectedOption) {
                    setVariableType((prevVariableType) => [
                        ...prevVariableType,
                        selectedOption,
                    ]);
                }

                // Append the value to combinedId
                setCombinedId((prevCombinedId) => [
                    ...prevCombinedId,
                    {
                        // name: `&${selectedOption.name}=[%${selectedOption.value}%]`,
                        value,
                        formate: `${selectedOption.name}`,
                    },
                ]);
                setCombinedIdslash((prevCombinedId) => [
                    ...prevCombinedId,
                    {
                        value,
                        formate: `${selectedOption.name}`,
                    },
                ]);
            }
        }
    };

    const handleDynamicInputChange = (event, value, isCheckbox) => {
        const { name, value: inputValue } = event.target;

        const optionsArray = isCheckbox ? checkboxOptions : vttt;

        // Update the options array
        const updatedOptions = optionsArray.map((option) => {
            if (option.value === value) {
                return {
                    ...option,
                    name: inputValue,
                    place: inputValue,
                };
            }
            return option;
        });

        // Update combinedId based on the options array
        setCombinedId((prevCombinedId) => {
            const updatedCombinedId = prevCombinedId.map((option) => {
                if (option.value === value) {
                    const name = inputValue;

                    return {
                        ...option,
                        formate: inputValue,
                    };
                }
                return option;
            });
            return updatedCombinedId;
        });

        // Update editedValues
        setEditedValues((prevEditedValues) => ({
            ...prevEditedValues,
            [value]: inputValue,
        }));

        // Update the specific state based on isCheckbox
        if (isCheckbox) {
            setCheckboxOptions(updatedOptions);
        } else {
            setVtt(updatedOptions);
        }
    };

    const formsubmission = (e) => {
        props.setQuotafulllink(surveyUrl + textareaValue);

        props.setQBuildUrl(false);
        // props.hideaddsupplier(true);
    };
    const handleClose = () => {
        setVariableType([]);
        setCheckboxOptions([]);
        setDisabledOptions([]);
        setCombinedId([]);
        setCombinedIdslash([]);
        setTextareaValue('');
        setSurveyurl('');
        setCheckedState({});
        props.setQBuildUrl(false);
        // props.hideaddsupplier(true);
    };
    useEffect(() => {
        if (questionstate === true) {
            let params = combinedId
                .map((item) => item.formate + '=' + item.value)
                .join('&');
            combinedId.map((item) => {});

            setTextareaValue(baseUrl + '?' + params);
            setCompUrl(baseUrl + '?' + params);
        } else {
            let params = combinedId.map((item) => '/' + item.value).join('/');
            combinedId.map((item) => {});

            setTextareaValue(baseUrl + '/' + params);
            setCompUrl(baseUrl + '/' + params);
        }
    }, [combinedId, combinedIdslash]);

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px', paddingLeft: '3px' }}
                >
                    Build Your URL mmmmmmmmmm
                </Modal.Title>
            </Modal.Header>
            {loder ? (
                <>
                    <form onSubmit={handleSubmit(formsubmission)}>
                        <Modal.Body>
                            <Row>
                                <Col
                                    lg={8}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    <p
                                        style={{
                                            marginLeft: '0px',
                                        }}
                                    >
                                        What's your survey's URL?
                                    </p>
                                </Col>
                                <Col
                                    lg={4}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    <input
                                        type="checkbox"
                                        placeholder=""
                                        checked={checkquestion}
                                        onChange={(e) => {
                                            setCheckquestion(e.target.checked);
                                            // handleQuestionmark(e);
                                        }}
                                    />
                                    <label
                                        className="formlabelviewzone"
                                        style={{
                                            width: '95%',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        Check question mark formate
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    <input
                                        type="text"
                                        style={{
                                            width: '100%',
                                        }}
                                        defaultValue={textareaValue}
                                        onChange={(e) => {
                                            handleInputsurvey();
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                >
                                    <h6
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        Variables
                                    </h6>
                                    <p
                                        style={{
                                            margin: '10px 0px 20px 0px',
                                        }}
                                    >
                                        These are URL parameters for respondent
                                        or survey-level data that will be passed
                                        along when a respondent gets sent to
                                        your survey.
                                    </p>
                                </Col>
                                <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                    style={{
                                        marginTop: '40px',
                                    }}
                                >
                                    {devicetype.length === 0 ? (
                                        <></>
                                    ) : (
                                        <div className="variable_select">
                                            <select
                                                className="variable_select__select"
                                                onChange={
                                                    handleMultiSelectChange
                                                }
                                                value=""
                                            >
                                                <option
                                                    value=""
                                                    disabled
                                                    hidden
                                                >
                                                    Select Variable
                                                </option>
                                                {devicetype.map((option) => (
                                                    <option
                                                        key={option.value}
                                                        value={option.value}
                                                        disabled={disabledOptions.includes(
                                                            option.value
                                                        )}
                                                    >
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    className="Quality_Col"
                                    lg={2}
                                    md={4}
                                    sm={4}
                                    xs={6}
                                >
                                    {tid.map((tidvar, indexi) => (
                                        <>
                                            <label className="formlabel">
                                                TID
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {' '}
                                                    *
                                                </span>
                                            </label>

                                            <div>
                                                <input
                                                    type="checkbox"
                                                    placeholder="RID"
                                                    checked={true}
                                                    className="box_width"
                                                />
                                                <input
                                                    className="vtttinput"
                                                    type="text"
                                                    defaultValue={
                                                        // editedValues[variableitem.value] !== ""
                                                        editedValues[
                                                            tidvar.value
                                                        ] || tidvar.name
                                                    }
                                                    name={tidvar.value}
                                                    onChange={(event) => {
                                                        handleDynamicInputChange(
                                                            event,
                                                            tidvar.value,
                                                            false
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </>
                                    ))}
                                </Col>
                                {vttt.map((variableitem, index) => (
                                    <Col
                                        className="Quality_Col"
                                        lg={2}
                                        md={4}
                                        sm={4}
                                        xs={6}
                                    >
                                        <div key={index} className="">
                                            <label
                                                className="formlabel"
                                                htmlFor={'variable1' + index}
                                            >
                                                {variableitem.label}
                                            </label>
                                            <input
                                                className=" box_width "
                                                type="checkbox"
                                                id={'variable1' + index}
                                                name={variableitem.value}
                                                onChange={(event) =>
                                                    handlecheck(
                                                        event,
                                                        index,
                                                        false
                                                    )
                                                }
                                                checked={checkedState.name}
                                            />

                                            {combinedId.some(
                                                (param) =>
                                                    param.value ===
                                                    variableitem.value
                                            ) && (
                                                <input
                                                    style={{
                                                        border: '1px solid green',
                                                    }}
                                                    className="vtttinput"
                                                    type="text"
                                                    // value={variableitem.name}
                                                    value={
                                                        // editedValues[variableitem.value] !== ""
                                                        editedValues[
                                                            variableitem.value
                                                        ] || variableitem.name
                                                    }
                                                    name={variableitem.value}
                                                    onChange={(event) =>
                                                        handleDynamicInputChange(
                                                            event,
                                                            variableitem.value,
                                                            false
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Col>
                                ))}

                                {checkboxOptions.map((option, index) => (
                                    <Col
                                        className="Quality_Col"
                                        lg={2}
                                        md={4}
                                        sm={4}
                                        xs={6}
                                    >
                                        <div key={option.value}>
                                            <label className="formlabel">
                                                {option.label}
                                            </label>
                                            <input
                                                type="checkbox"
                                                className=""
                                                value={option.value}
                                                name={option.value}
                                                // onChange={handleCheckboxChange}
                                                onChange={(event) =>
                                                    handlecheck(
                                                        event,
                                                        index,
                                                        true
                                                    )
                                                }
                                                checked={variableType.some(
                                                    (variable) =>
                                                        variable.value ===
                                                        option.value
                                                )}
                                            />
                                            {variableType.some(
                                                (variable) =>
                                                    variable.value ===
                                                    option.value
                                            ) && (
                                                <input
                                                    style={{
                                                        border: '1px solid red',
                                                    }}
                                                    className="vtttinput"
                                                    type="text"
                                                    value={option.name}
                                                    name={option.value}
                                                    onChange={(event) =>
                                                        handleDynamicInputChange(
                                                            event,
                                                            option.value,
                                                            true
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </Col>
                                ))}
                            </Row>

                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="col"
                                    style={{
                                        marginTop: '20px',
                                    }}
                                >
                                    <label className="formlabel">
                                        Quotafull Link
                                    </label>
                                    <textarea
                                        type="text"
                                        style={{ width: '100%' }}
                                        value={textareaValue}
                                        // onChange={handleTextareaChange}
                                        readOnly
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <button
                            className="cbtn saveBtn "
                            style={{
                                marginBottom: '10px',
                                marginRight: '15px',
                            }}
                            type="submit"
                        >
                            submit
                        </button>
                    </form>
                    <button onClick={() => {}}>nnnnnnnn</button>
                </>
            ) : (
                <SpinnerLoader />
            )}
        </Modal>
    );
};

export default Quotafullbuildurl;
