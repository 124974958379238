import { useState, useEffect } from "react";
import Invoiceids from "./popup/Invoiceids";
import InvoicBillingattach from "./popup/InvoiceBillingattach";
import Invoicesurveylogs from "./popup/InvoiceSurveylogs";
import { BiUpload, BiPurchaseTag } from "react-icons/bi";
import { Col, Row } from "react-bootstrap";
import { CiCircleMinus } from "react-icons/ci";
import { BiMinusCircle } from "react-icons/bi";
import { GrAddCircle } from "react-icons/gr";
import { useForm } from "react-hook-form";
import Table from "react-bootstrap/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "bootstrap/dist/css/bootstrap.css";
import "./Invoice.css";
import { MultiSelect } from "react-multi-select-component";
import EditIcon from "../../../assets/LoginIcons/edit.svg";
import Editpop from "./popup/Editpop";
import { Tooltip } from "@mui/material";
import SpinnerLoader from "../../UI/Spinner";
import { Button } from "@mui/material";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router";
const NewaddedBox = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  useEffect(() => {
    if (props.addedBoxes.length !== 0) {
      setData(props.addedBoxes[props.idb]);
    }
  }, [props.addedBoxes]);
  const handlesubfunc = async (e, id) => {
    props.setSwitchbtn((prevState) => {
      const newState = { ...prevState };

      if (!newState[props.idb]) {
        newState[props.idb] = [
          { id: `${props.idb}${props.boxIndex}`, state: true },
        ];
      } else {
        newState[props.idb][props.boxIndex].state = true;
      }

      return newState;
    });
    const payload = {
      id: id,
      survey_id: props.idb,
      cl_count: parseInt(e.CLCount[props.idb][props.boxIndex]),
      cpi: parseInt(e.CPI[props.idb][props.boxIndex]),
      remark: e.REMARKS[props.idb][props.boxIndex],
    };
    try {
      await axiosPrivate
        .post(`invoice/additional-cost`, payload, {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((e) => {
          if (e.status === 200) {
            props.setAddedBoxes((prevState) => {
              const newState = { ...prevState };

              newState[props.idb][props.boxIndex].id = e.data.survey.id;

              return newState;
            });
            get_single_stats(props.idb);
          }
        });
    } catch (err) {}
  };

  const deleteAddbox = async (additional_id) => {
    const payload = {
      survey_id: props.idb,
      additional_billing_id: additional_id,
    };
    props.setAddedBoxes((prevState) => {
      const newState = { ...prevState };

      newState[props.idb][props.boxIndex].delete = true;

      return newState;
    });
    try {
      await axiosPrivate
        .delete(
          `invoice/delete-additionl-cost`,
          { data: payload },
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((e) => {});
    } catch (err) {}
  };
  const get_single_stats = async (survey_id) => {
    props.setLoder_stats(false);
    try {
      const response = await axiosPrivate.get(
        `invoice/get-stats-survey-billing?survey_id=${survey_id}`
      );

      if (response.status === 200) {
        props.setData((prevData) => {
          const newData = [...prevData]; // Copy the previous data

          const index = newData.findIndex((item) => item.id === survey_id);

          if (index !== -1) {
            newData[index].additional_cost =
              response.data.billingsurvey.additional_cost;
            newData[index].total_summary =
              response.data.billingsurvey.total_summary;
          }

          return newData;
        });
      }
    } catch (err) {
      console.error(err);
    }
    props.setLoder_stats(true);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit((e) => {
          handlesubfunc(e, props.addedBoxes[props.idb][props.boxIndex].id);
        })}
      >
        <div className="">
          {data.length !== 0 ? (
            <>
              <Row className="Quality_sf">
                <Col
                  lg={12}
                  style={{
                    paddingBottom: "10px",
                  }}
                >
                  <Row>
                    <Col className="Quality_Col" lg={3} md={4} sm={4} xs={12}>
                      <label className="formlabel">CL Count</label>
                      <input
                        {...register(
                          `CLCount[${props.idb}][${props.boxIndex}]`,
                          {
                            required: "This field is Required",
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          }
                        )}
                        defaultValue={data[props.boxIndex].CLCount}
                        onChange={(e) => {
                          setValue(
                            `CLCount[${props.idb}][${props.boxIndex}]`,
                            e.target.value
                          );
                          data[props.boxIndex].CLCount = e.target.value;
                        }}
                        readOnly={
                          props.switchbtn[props.idb][props.boxIndex].id ===
                            props.idb + props.boxIndex &&
                          props.switchbtn[props.idb][props.boxIndex].state ===
                            true
                            ? true
                            : false
                        }
                        type="text"
                        className=""
                        style={{
                          width: "100%",
                        }}
                      />

                      {errors?.CLCount?.[props.idb]?.[props.boxIndex]
                        ?.message && (
                        <small className="invoice_errmsg">
                          {errors.CLCount[props.idb][props.boxIndex].message}
                        </small>
                      )}
                    </Col>
                    <Col className="Quality_Col" lg={3} md={4} sm={4} xs={12}>
                      <label className="formlabel">CPI</label>

                      <input
                        {...register(`CPI[${props.idb}][${props.boxIndex}]`, {
                          required: "This field is Required",
                          pattern: {
                            value: /^[0-9]+(\.[0-9]+)?$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        defaultValue={
                          props.addedBoxes[props.idb][props.boxIndex].CPI
                        }
                        readOnly={
                          props.switchbtn[props.idb][props.boxIndex].id ===
                            props.idb + props.boxIndex &&
                          props.switchbtn[props.idb][props.boxIndex].state ===
                            true
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          setValue(
                            `CPI[${props.idb}][${props.boxIndex}]`,
                            e.target.value
                          );
                          data[props.boxIndex].CPI = e.target.value;
                        }}
                        type="text"
                        className=""
                        style={{
                          width: "100%",
                        }}
                      />
                      {errors?.CPI?.[props.idb]?.[props.boxIndex]?.message && (
                        <small className="invoice_errmsg">
                          {errors.CPI[props.idb][props.boxIndex].message}
                        </small>
                      )}
                    </Col>
                    <Col className="Quality_Col" lg={4} md={4} sm={4} xs={12}>
                      <label className="formlabel">Remarks</label>

                      <input
                        {...register(
                          `REMARKS[${props.idb}][${props.boxIndex}]`,
                          {
                            required: "This field is Required",
                            pattern: {
                              value:
                                /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                              message: "Only letters are allowed",
                            },
                          }
                        )}
                        defaultValue={data[props.boxIndex].REMARKS}
                        onChange={(e) => {
                          setValue(
                            `REMARKS[${props.idb}][${props.boxIndex}]`,
                            e.target.value
                          );
                          data[props.boxIndex].REMARKS = e.target.value;
                        }}
                        readOnly={
                          props.switchbtn[props.idb][props.boxIndex].id ===
                            props.idb + props.boxIndex &&
                          props.switchbtn[props.idb][props.boxIndex].state ===
                            true
                            ? true
                            : false
                        }
                        type="text"
                        className=""
                        style={{
                          width: "100%",
                        }}
                      />

                      {errors?.REMARKS?.[props.idb]?.[props.boxIndex]
                        ?.message && (
                        <small className="invoice_errmsg">
                          {errors.REMARKS[props.idb][props.boxIndex].message}
                        </small>
                      )}
                    </Col>
                    <Col lg={2} md={4} sm={4} xs={12}>
                      <label className="formlabel"></label>
                      <div className="Quality_subbtn flex flex-direction items-center remove-mt">
                        <BiMinusCircle
                          title="Delete"
                          className="invoice_two_icon"
                          size={20}
                          onClick={(e) => {
                            deleteAddbox(
                              props.addedBoxes[props.idb][props.boxIndex].id
                            );
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                        />

                        {props.switchbtn[props.idb][props.boxIndex].id ===
                          props.idb + props.boxIndex &&
                        props.switchbtn[props.idb][props.boxIndex].state ===
                          true ? (
                          <>
                            <span className="actionButton">
                              <img
                                src={EditIcon}
                                className="editIconSize"
                                alt=""
                                onClick={() => {
                                  props.setSwitchbtn((prevState) => {
                                    const newState = {
                                      ...prevState,
                                    };

                                    if (!newState[props.idb]) {
                                      newState[props.idb] = [
                                        {
                                          id: `${props.idb}${props.boxIndex}`,
                                          state: false,
                                        },
                                      ];
                                    } else {
                                      newState[props.idb][
                                        props.boxIndex
                                      ].state = false;
                                    }

                                    return newState;
                                  });
                                }}
                              />
                            </span>
                          </>
                        ) : props.switchbtn[props.idb][props.boxIndex].id ===
                            props.idb + props.boxIndex &&
                          props.switchbtn[props.idb][props.boxIndex].state ===
                            false ? (
                          <>
                            <button
                              className="cbtn saveBtn"
                              onClick={(e) => {}}
                            >
                              Submit
                            </button>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </div>
      </form>
    </div>
  );
};

export default NewaddedBox;
