import React, { useEffect } from "react";
import "./Quota.css";
import { BiPlus, BiEditAlt } from "react-icons/bi";
import { useState } from "react";
import AddQuotaModal from "./popup/AddQuotaModal";
import EditQuotaModal from "./popup/EditQuotaModal";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { axiosPrivate } from "../../../../api/axios";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
function Quota(props) {
  const [addQuotaShow, setAddQuotaShow] = useState(false);
  const [editQuotaShow, setEditQuotaShow] = useState(false);
  const [quotaList, setQuotaList] = useState([]);
  const [currQuota, setCurrQuota] = useState([]);
  const [flag, setFlag] = useState(false);
  const [qualdata, setQualData] = useState([]);
  const notifyActive = () => toast.success("Quota is active");
  const notifyNotActive = () => toast.success("Quota is inactive");
  let qdata = props.qdata;
  if (props.age) {
    let age = props.age[0];
    const newArray = { ...age, q_name: "AGE" };
    qdata = [newArray, ...qdata];
  }
  if (props.zip) {
    let zip = props.zip[0];
    const newArray = { ...zip, q_name: "ZIPCODE" };
    qdata = [...qdata, newArray];
  }

  useEffect(() => {
    if (props.age) {
      let age = props.age[0];
      const newArray = { ...age, q_name: "AGE" };
      qdata = [newArray, ...qdata];
    }
    if (props.zip) {
      let zip = props.zip[0];
      const newArray = { ...zip, q_name: "ZIPCODE" };
      qdata = [...qdata, newArray];
    }
    getQuotaList();
    getQualifiacation();
  }, [props.sid, props.qdata, flag, props.age, props.zip, props.tstatus]);

  const getQuotaList = async () => {
    setQuotaList([]);
    try {
      const response = await axiosPrivate.get(
        `quota/get-quota-list/${props.sid}`
      );
      setQuotaList(response.data.quota);
    } catch (err) {}
  };

  const getQualifiacation = async () => {
    setQualData([]);
    try {
      const response = await axiosPrivate.get(
        `qualification/get-qualification/${props.sid}`
      );
      setQualData(response.data.qualification.qualify);
    } catch (err) {
      console.error(err);
    }
  };
  const editQuota = (qitem) => {
    setCurrQuota(qitem);
    setEditQuotaShow(true);
  };

  const handle_status = async (qid, status) => {
    if (props.tstatus) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        message: `Please pause qualification before making any changes`,
        messageStyle: {
          color: "#ff0000", // Set your desired color value
        },
        buttons: [
          {
            label: "OK",
            onClick: () => {
              setQuotaList((prevArray) =>
                prevArray.map((obj) =>
                  obj.id === qid ? { ...obj, status: !status } : obj
                )
              );
            },
          },
        ],
      });
    } else {
      const result = quotaList.filter(({ status }) => status === 1).length;

      if (result <= 1 && !status) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          message: `Atleast one quota should be active `,
          messageStyle: {
            color: "#ff0000", // Set your desired color value
          },
          buttons: [
            {
              label: "OK",
              onClick: () => {
                setQuotaList((prevArray) =>
                  prevArray.map((obj) =>
                    obj.id === qid ? { ...obj, status: 1 } : obj
                  )
                );
              },
            },
          ],
        });
      } else {
        try {
          await axiosPrivate
            .patch(
              "/quota/active-inactive-quota",
              JSON.stringify({
                quota_id: qid,
                new_status: status === true ? 1 : 0,
              }),
              {
                headers: { "Content-Type": "application/json" },
              }
            )
            .then((e) => {
              if (e.data.return_status.status_id === 1) {
                if (status) {
                  notifyActive();
                  setQuotaList((prevData) => {
                    // Map over the previous data array
                    return prevData.map((item) => {
                      // If the qualification ID matches, update the is_allocated field
                      if (item.id === qid) {
                        return { ...item, status: 1 };
                      }
                      return item;
                    });
                  });
                } else {
                  setQuotaList((prevData) => {
                    // Map over the previous data array
                    return prevData.map((item) => {
                      // If the qualification ID matches, update the is_allocated field
                      if (item.id === qid) {
                        return { ...item, status: 0 };
                      }
                      return item;
                    });
                  });
                  notifyNotActive();
                }
              } else {
                notifyerror("Unable to update status");
              }
            });
        } catch (err) {
          if (err.response.status === 400) {
            notifyerror(err.data.return_status.message);
          } else {
            notifyerror(err.response.statusText);
          }
        }
      }
    }
  };

  const notifyerror = (e) => toast.error(`${e} error occur`);
  return (
    <>
      <AddQuotaModal
        show={addQuotaShow}
        onHide={() => setAddQuotaShow(false)}
        sid={props.sid}
        flag={flag}
        setFlag={setFlag}
      />
      {editQuotaShow ? (
        <EditQuotaModal
          show={editQuotaShow}
          onHide={() => setEditQuotaShow(false)}
          qitem={currQuota}
          qualdata={qdata}
          sid={props.sid}
          qualification={qualdata}
          flag={flag}
          setFlag={setFlag}
          tstatus={props.tstatus}
          allQuestions={props.allQuestions}
        />
      ) : (
        <></>
      )}
      <div class="add-quota-container mt-2">
        <div className="quota-heading flex justify-between items-center pb-2">
          <h4 className="m-0 fs16">Add Quota:</h4>
          <button
            className="add-Quota-btn"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAddQuotaShow(true);
            }}
          >
            <a className="flex items-center">
              <BiPlus />
              Add Quota
            </a>
          </button>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead class="thead-bg-color">
              <tr>
                <th>Name</th>
                <th>Quota</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {quotaList.length !== 0 ? (
                <>
                  {quotaList.map((quotaItem, index) => (
                    <tr key={index}>
                      <td
                        className="pointer"
                        onClick={() => editQuota(quotaItem)}
                      >
                        {quotaItem.name}
                      </td>
                      <td>{quotaItem.n}</td>
                      <td>
                        <BootstrapSwitchButton
                          className="switch btn on btn-outline-success btn-xs"
                          width={60}
                          size="xs"
                          onlabel="ON "
                          offlabel="OFF"
                          onstyle="outline-success"
                          offstyle="outline-danger"
                          checked={
                            quotaItem.status === 1 || quotaItem.status
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            handle_status(quotaItem.id, e);
                          }}
                        />
                      </td>
                      <td>
                        <BiEditAlt
                          className="pointer"
                          onClick={() => editQuota(quotaItem)}
                          title="Edit Quota"
                        />
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <td colSpan={4}>
                      <center></center>
                      <div
                        className="alert alert-danger center-margin-container m-0"
                        role="alert"
                      >
                        No records found!
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Quota;
