import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { BsDownload } from 'react-icons/bs';
import '../PopUpPage/ErrorCreated.css';
import { CheckCircle, Delete, ErrorRounded } from '@mui/icons-material';
import addIcon from '../../../assets/LoginIcons/add.svg';
const ErrorCreated = (props) => {
    const [test, setTest] = useState([]);
    const [message, setMessage] = useState([]);
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const progressInfosRef = useRef(null);
    const [showpage, setShowpage] = useState('Mark error');
    const [randomNum, setRandomNumber] = useState();
    const axiosPrivate = useAxiosPrivate();

    const fileupload = async (file, onUploadProgress) => {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('uid', `${randomNum}`);
        setTest((value) => [...value, file]);
        await axiosPrivate.post('/mailbox', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    };

    useEffect(() => {
        let x = Math.floor(Math.random() * 100000000000 + 1);
        setRandomNumber(x);
    }, []);

    const upload = async (idx, file) => {
        let _progressInfos = [...progressInfosRef.current.val];
        return fileupload(file, (event) => {
            _progressInfos[idx].percentage = Math.round(
                (100 * event.loaded) / event.total
            );
            setProgressInfos((vale) => ({ ...vale, val: _progressInfos }));
        })
            .then(() => {
                setMessage((prevMessage) => [...prevMessage, 'success']);
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                setProgressInfos({ val: _progressInfos });
                setMessage((prevMessage) => [...prevMessage, 'error']);
            });
    };

    const uploadFiles = async (event) => {
        const files = Array.from(event.target.files);
        let merged = files.concat(test);
        merged = merged.filter((item, index) => {
            return merged.indexOf(item) === index;
        });

        let _progressInfos = merged.map((file) => ({
            percentage: 0,
            fileName: file.name,
        }));
        progressInfosRef.current = {
            val: _progressInfos,
        };

        const uploadPromises = merged.map((file, i) => upload(i, file));
        setMessage([]);
    };

    const deleteHandler = async (i, e) => {
        const del = progressInfos.val.filter((User, index) => i !== index);
        const el = progressInfos.val.filter((User, index) => i === index);

        setProgressInfos({ val: del });
        const bar = test.filter((User, index) => i !== index);
        setTest(bar);
    };
    return (
        <>
            {' '}
            <Modal
                className="popupmodehere"
                style={
                    {
                        // overflowY: "hidden",
                    }
                }
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{ fontSize: '16px' }}
                    >
                        Quality Audit
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg={4} md={12} sm={12} xs={12}>
                                <label className="formlabelviewzone">
                                    Type of audit
                                </label>
                                <select
                                    style={{ width: '100%' }}
                                    className="formtextviewzone"
                                    onClick={(e) => {
                                        setShowpage(e.target.value);
                                    }}
                                >
                                    <option value="Mark error">
                                        Mark error
                                    </option>
                                    <option value="NO error">No error</option>
                                </select>
                            </Col>
                        </Row>
                        {showpage === 'Mark error' ? (
                            <>
                                {' '}
                                <Row>
                                    <Col
                                        className=""
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <label className="formlabelviewzone">
                                            Subject line
                                        </label>
                                        <input
                                            className="formtextviewzone"
                                            type="text"
                                            placeholder="Subject line"
                                        />
                                    </Col>
                                </Row>
                                <div style={{ height: '10px' }}></div>
                                <Row>
                                    <Col lg={2} md={3} sm={3} xs={3}>
                                        <label className="formlabelviewzone">
                                            Receiver(TO)
                                        </label>
                                    </Col>
                                    <Col lg={10} md={9} sm={9} xs={9}>
                                        <input
                                            placeholder="sunny@torfac.com"
                                            style={{
                                                borderStyle: 'none none double',
                                            }}
                                            className="formtextviewzone"
                                            type="text"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={2} md={3} sm={3} xs={3}>
                                        <label className="formlabelviewzone">
                                            Receiver(TO)
                                        </label>
                                    </Col>
                                    <Col lg={10} md={9} sm={9} xs={9}>
                                        <input
                                            placeholder="kapil@torfac.com"
                                            style={{
                                                borderStyle: 'none none double',
                                            }}
                                            className="formtextviewzone"
                                            type="text"
                                        />
                                    </Col>
                                </Row>
                                <div style={{ height: '20px' }}></div>
                                <Row>
                                    <Col>
                                        <textarea
                                            style={{ height: '100px' }}
                                            className="formtextviewzone"
                                            type="text"
                                            placeholder="Email Content/QA Comments"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        className=""
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                        <label className="formlabelviewzone">
                                            Parameters
                                        </label>
                                        <input
                                            className="formtextviewzone"
                                            type="text"
                                            placeholder="Parameters"
                                        />
                                    </Col>
                                    <Col
                                        className=""
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}
                                    >
                                        <label className="formlabelviewzone">
                                            Error Weightage
                                        </label>
                                        <input
                                            className="formtextviewzone"
                                            type="text"
                                            placeholder="Error Weightage"
                                        />
                                    </Col>
                                </Row>
                                <hr></hr>
                                <Row>
                                    <Col
                                        className=""
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: 'auto',
                                            }}
                                        >
                                            <label className="formlabelviewzone">
                                                Error proof (attachment)
                                            </label>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '25px',
                                                width: 'auto',
                                            }}
                                        >
                                            {' '}
                                            <BsDownload />
                                        </div>
                                    </Col>
                                    {/* <Col className="" lg={6} md={6} sm={6} xs={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontSize: "25px",
                      }}
                    >
                      {" "}
                      <BsDownload />
                    </div>
                  </Col> */}
                                </Row>
                                <Row>
                                    {' '}
                                    <div className="attachmentContainer">
                                        {progressInfos &&
                                            progressInfos.val.length > 0 &&
                                            progressInfos.val.map(
                                                (progressInfo, index) => (
                                                    <div
                                                        key={index}
                                                        className="Invoice_attachmentItem"
                                                    >
                                                        <div className="attachmentContent">
                                                            <span className="invoice_fileInfo">
                                                                {
                                                                    progressInfo.fileName
                                                                }
                                                            </span>
                                                            {message[index] ===
                                                            'success' ? (
                                                                <CheckCircle className="success" />
                                                            ) : (
                                                                <ErrorRounded className="error" />
                                                            )}

                                                            <div className="progress">
                                                                <div
                                                                    className="progress-bar progress-bar-default"
                                                                    role="progressbar"
                                                                    aria-valuenow={
                                                                        progressInfo.percentage
                                                                    }
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{
                                                                        width:
                                                                            progressInfo.percentage +
                                                                            '%',
                                                                    }}
                                                                >
                                                                    {
                                                                        progressInfo.percentage
                                                                    }
                                                                    %
                                                                </div>
                                                            </div>
                                                            <Delete
                                                                className="deleteAttachment"
                                                                title="Remove Attachment"
                                                                onClick={(e) =>
                                                                    deleteHandler(
                                                                        index,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                    <label
                                        className="cbtn addBtn"
                                        style={{
                                            cursor: 'pointer',
                                            float: 'left',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <img
                                            src={addIcon}
                                            className="headingIcons"
                                            alt=""
                                        />{' '}
                                        Attachment
                                        <input
                                            type="file"
                                            multiple
                                            onChange={uploadFiles}
                                            className="attachInput"
                                        />
                                    </label>
                                </Row>
                                <Row>
                                    <Col>
                                        <button className="cbtn saveBtn">
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col
                                        className=""
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <label className="formlabelviewzone">
                                            Comment
                                        </label>
                                        <textarea
                                            style={{ height: '100px' }}
                                            className="formtextviewzone"
                                            type="text"
                                            placeholder="Comment"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <button
                                            className="cbtn saveBtn"
                                            style={{
                                                marginTop: '10px',
                                            }}
                                        >
                                            Submit
                                        </button>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ErrorCreated;
