import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import toast from 'react-hot-toast';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { BiPlus, BiEditAlt } from 'react-icons/bi';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';

import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import './EditQuotaModal.css';

import { useForm, SubmitHandler } from 'react-hook-form';

function AddConditionValues(props) {
    const notifyerror = (e) => toast.error(`${e}`);
    const axiosPrivate = useAxiosPrivate();
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        resetField,
        formState: { errors },
    } = useForm();

    const [addCondition, setAddCondition] = useState(false);
    const [qualData, setQualData] = useState(props.qualdata);

    const [dropdownloader, setDropDownLoader] = useState(true);
    const [selectedItem, setSelectedItem] = useState([]);
    const [currQuestion, setCurrQuestion] = useState('');
    const [questionList, setQuestionList] = useState(props.qList);
    const [answerList, setAnswerList] = useState([]);

    //   const [qualAnswers, setQualAnswers] = useState(props.qualAnswers);
    const [currAnswers, setCurrAnswers] = useState([]);
    const [flag, setFlag] = useState(true);
    const [urlAddLink, setUrlAddLink] = useState();

    const [addConditionspinner, setAddConditionSpinner] = useState(false);
    const [zipErrorAdd, setZipErrorAdd] = useState(false);

    const qualAnswers = props.qualAnswers;
    const qitem = props.qitem;

    useEffect(() => {
        setQualData(props.qualdata);
        setQuestionList(props.qList);
    }, [props.qList]);

    const addSubQuota = async (data) => {
        let url = '';
        let payload = [];
        if (data.qid === 1) {
            let agedata = '',
                ageitem = [];
            if (Array.isArray(data.age)) {
                const len = data.age.length;
                for (let i = 0; i < len; i++) {
                    agedata = data.age[i].toString();
                    let currage = agedata.split('-');
                    ageitem[i] = {
                        min_age: currage[0],
                        max_age: currage[1],
                        status: 1,
                    };
                }
            } else {
                let currage = data.age.split('-');
                ageitem[0] = {
                    min_age: currage[0],
                    max_age: currage[1],
                    status: 1,
                };
            }

            payload = {
                target_id: data.qid,
                q_id: data.qid,
                ans_code: ageitem,
                order_by: data.qid,
            };

            url = 'quota/add-age-quota/' + qitem.id;
        } else if (data.qid === 3) {
            if (!urlAddLink || urlAddLink === undefined) {
                setZipErrorAdd(true);
                return;
            }
            payload = {
                target_id: data.qid,
                q_id: data.qid,
                upload_zipcode: urlAddLink,
                is_all_selected: false,
                order_by: data.qid,
            };
            url = 'quota/add-zipcode/' + qitem.id;
        } else {
            let answers = [];
            if (Array.isArray(data.answer)) {
                answers = data.answer;
            } else {
                answers.push(data.answer);
            }

            payload = {
                target_id: data.qid,
                q_id: data.qid,
                ans_code: answers,
                is_all_selected: false,
                order_by: data.qid,
            };
            url = 'quota/add-general-quota/' + qitem.id;
        }

        if (data.qid === 3) {
            try {
                setAddConditionSpinner(true);
                await axiosPrivate
                    .post(url, payload, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((e) => {
                        if (e.status === 200) {
                            const updatedQuestion = questionList.filter((item) => item.value !== data.qid);
                            setQuestionList([]);

                            setSelectedItem([]);

                            setAddConditionSpinner(false);
                            reset();
                            setQuestionList(updatedQuestion);
                            setCurrQuestion('');

                            setAnswerList([]);

                            toast.success(`Quota Added Successfully`);
                            setUrlAddLink('');
                            setAddConditionSpinner(false);
                            props.setFlag(!props.flag);
                            // document.querySelector('#react-select .select__single-value.css-qc6sy-singleValue').innerHTML="Select...";
                        }
                    });
            } catch (err) {
                console.error(err);
                setAddConditionSpinner(false);
                if (err.response?.data?.return_status?.message) {
                    toast.error(err.response.data.return_status.message);
                } else if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Sorry! we couldn't process your request. Please Try again");
                }
            }
        } else {
            try {
                setAddConditionSpinner(true);
                await axiosPrivate
                    .patch(url, payload, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((e) => {
                        if (e.status === 200) {
                            const updatedQuestion = questionList.filter((item) => item.value !== data.qid);
                            reset();
                            setQuestionList(updatedQuestion);
                            setCurrQuestion('');
                            setAnswerList([]);
                            setSelectedItem([]);
                            setAddConditionSpinner(false);
                            toast.success(`Quota Added Successfully`);
                            props.setFlag(!props.flag);
                        }
                    });
            } catch (err) {
                console.error(err);
                setAddConditionSpinner(false);
                toast.error(err.response.data.return_status.message);
            }
        }
        setSelectedItem([]);
    };
    const getAnswerList = (e) => {
        setAnswerList([]);
        resetField('answer');
        resetField('qid');
        setValue('qid', e.value);
        if (e.value !== 3) {
            getFilteredAnswer(e.value);
        }
    };

    const getFilteredAnswer = (qid) => {
        setAnswerList([]);
        const filteredQual = qualAnswers.filter((item) => item.q_id === qid);
        setCurrAnswers(filteredQual[0].ans_code);
        const currentAns = filteredQual[0].ans_code;
        let answers = [];
        let index = qualData.findIndex((x) => x.q_id === qid);
        if (qid === 1) {
            answers = qualData[index].ansCode;
        } else {
            answers = qualData[index].allAnswers;
        }

        const filterdAnswer = answers.filter((ans) => currentAns.includes(ans.ans_code));

        if (qid === 1) {
            setAnswerList(currentAns);
        } else {
            setAnswerList(filterdAnswer);
        }
    };

    const uploadFile = (e, stype) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('zipcode_upload', e.target.files[0]);

        const url = `qualification/upload-files?survey_id=${props.sid}`;
        axiosPrivate
            .post(url, formData)
            .then((response) => {
                if (response.status === 200) {
                    setUrlAddLink(response.data.url);
                    setZipErrorAdd(false);

                    toast.success(`ZipCode File uploaded`);
                }
                // Handle successful response
            })
            .catch((error) => {
                // Handle error
                toast.error("Can't upload your file. Please try again");
                console.error('Error:', error);
            });
    };

    return (
        <>
            <div className="Quota-Condition">
                <div className="flex justify-between items-center mb-2">
                    <small>Step 2: Survey Quota Condition</small>
                    <button
                        className="add-Quota-btn"
                        disabled={props.tstatus || props.tstatus === 1 ? true : false}
                        onClick={() => {
                            setAddCondition(!addCondition);
                            setCurrQuestion('');
                            setAnswerList([]);
                        }}>
                        <a className="flex items-center">
                            <BiPlus />
                            Add Condition
                        </a>
                    </button>
                </div>
                {addCondition ? (
                    <div className="quota-condition-wrapper">
                        <div className="qcw_child">
                            <form onSubmit={handleSubmit(addSubQuota)}>
                                <Row>
                                    <Col lg={4} md={4} xs={12}>
                                        <label className="formlabel">Question Name*</label>
                                        <Select
                                            className="pjt_select formtext"
                                            {...register('qu')}
                                            classNamePrefix="select"
                                            isDisabled={false}
                                            // isLoading={dropdownloader}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="questionlist"
                                            value={selectedItem}
                                            options={questionList}
                                            onChange={(e) => {
                                                setSelectedItem(e);
                                                setCurrQuestion(e.value);
                                                getAnswerList(e);
                                            }}
                                            noOptionsMessage={() => 'No Data Found'}
                                        />
                                    </Col>
                                    <Col lg={8} md={8} xs={12}>
                                        <label className="formlabel">Please select the values or Type the values</label>
                                        <div className="condition_box" id="add_condition_box">
                                            <input
                                                type="hidden"
                                                {...register('qid', {
                                                    required: true,
                                                })}
                                                value={currQuestion}
                                                readOnly
                                            />
                                            {currQuestion === 3 ? (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <label className="custom-file-upload btnWidth_set m-0 border-0 w-auto rounded-1">
                                                                <input
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        uploadFile(e, 'add');
                                                                    }}
                                                                    accept=".csv"
                                                                />
                                                                {urlAddLink ? 'File Uploaded' : 'Upload ZipCode'}
                                                            </label>
                                                            {zipErrorAdd && (
                                                                <p role="alert" className="errMsg">
                                                                    Please upload a csv file
                                                                </p>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : (
                                                <>
                                                    {answerList ? (
                                                        answerList.map((item, index) => (
                                                            <>
                                                                {currQuestion === 1 ? (
                                                                    <>
                                                                        <Row key={`key${index}`}>
                                                                            <Col>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="answercheck"
                                                                                    {...register('age', {
                                                                                        required: true,
                                                                                    })}
                                                                                    value={`${item.min_age}-${item.max_age}`}
                                                                                    id={`item${index}`}
                                                                                />
                                                                                <label for={`item${index}`}>
                                                                                    {item.min_age} - {item.max_age}
                                                                                </label>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Row>
                                                                            <Col>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="answercheck"
                                                                                    {...register('answer', {
                                                                                        required: true,
                                                                                    })}
                                                                                    value={item.ans_code}
                                                                                    id={`item ${index}`}
                                                                                />
                                                                                <label for={`item${index}`}>
                                                                                    {item.label}
                                                                                </label>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                            </>
                                                        ))
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {errors.answer?.type === 'required' && (
                                                        <p role="alert" className="errMsg">
                                                            Please select atleast one option
                                                        </p>
                                                    )}
                                                    {errors.age?.type === 'required' && (
                                                        <p role="alert" className="errMsg">
                                                            please select atleast one option
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div className="flex justify-end mt-2">
                                    <button className="add-Quota-btn" type="submit">
                                        {addConditionspinner && (
                                            <Spinner
                                                as="span"
                                                style={{ color: 'white' }}
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        )}
                                        Save Condition Values
                                    </button>
                                    <button
                                        className="add-Quota-btn btn-bg-grey ms-2"
                                        onClick={() => setAddCondition(false)}>
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
}

export default AddConditionValues;
