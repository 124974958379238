import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "./ExcelGrid.css"; // You can define your own styles
import addIcon from "../../../assets/LoginIcons/add.svg";
import { parse } from "papaparse";
import plusIcon from "../../../assets/CSicons/icons/add.svg";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

import { useNavigate } from "react-router";

import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
const ExcelGrid = (props) => {
  // Initialize a 2D array to store cell values
  const [gridData, setGridData] = useState([]);
  const [gridshow, setGridshow] = useState(false);

  // Initialize grid data when the component mounts

  // useEffect(() => {
  //   const initialGridData = [];

  //   // Create the first row with "LOI/IR" and values from 1 to 60 horizontally
  //   const firstRow = [
  //     "IR/LOI",
  //     ...Array.from({ length: 60 }, (_, index) => index + 1),
  //   ];
  //   initialGridData.push(firstRow);

  //   // Create rows with vertical values (1 to 100) and fill the rest based on data
  //   for (let i = 1; i <= 100; i++) {
  //     const newRow = [i];
  //     for (let j = 1; j <= 60; j++) {
  //       const matchingData = props.priceGrid.find(
  //         (item) => item.loi === j && item.ir === i // Swap IR and LOI
  //       );
  //       newRow.push(matchingData ? matchingData.cpi : "");
  //     }
  //     initialGridData.push(newRow);
  //   }

  //   setGridData(initialGridData);
  // }, [props.priceGrid]);
  // Initialize grid data when the component mounts
  useEffect(() => {
    const initialGridData = [];

    // Find the maximum value of IR from the priceGrid
    const maxIR = Math.max(...props.priceGrid.map((item) => item.loi));

    // Create the first row with "IR/LOI" and values from 1 to maxIR horizontally
    const firstRow = [
      "IR/LOI",
      ...Array.from({ length: maxIR }, (_, index) => index + 1),
    ];
    initialGridData.push(firstRow);

    // Create rows with vertical values (1 to 100) and fill the rest based on data
    for (let i = 1; i <= 100; i++) {
      const newRow = [i];
      for (let j = 1; j <= maxIR; j++) {
        const matchingData = props.priceGrid.find(
          (item) => item.loi === j && item.ir === i
        );
        if (matchingData) {
          newRow.push(matchingData.cpi);
        }
      }
      if (newRow.length > 1) {
        initialGridData.push(newRow);
      }
    }

    setGridData(initialGridData);
  }, [props.priceGrid]);

  // Render the grid
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", paddingLeft: "3px" }}
          >
            Price Type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {gridData.length > 0 && ( // Conditionally render the table when gridData is not empty
            <>
              <div className="excel-grid">
                {gridData.map((rowData, rowIndex) => (
                  <div className="grid_tr" key={rowIndex}>
                    {rowData.map((cellValue, colIndex) => (
                      <td className="grid_td" key={colIndex}>
                        <input
                          type="text"
                          value={cellValue}
                          className="grid_input"
                          readOnly
                        />
                      </td>
                    ))}
                  </div>
                ))}
              </div>
            </>

            //   </tbody>
            // </table>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExcelGrid;
