import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../UI/Spinner";

import moment from "moment";
function LogsModal(props) {
  const [logData, setLogData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [logdata_value, setLogData_Value] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  useEffect(() => {
    log_data();
  }, []);
  const log_data = async () => {
    setLoader(true);
    try {
      const response = await axiosPrivate.get(
        `quota/get-quota-logs/${props.survey_ID}`
      );
      if (response.data.quota.length !== 0) {
        setLogData(response.data.quota);
      }
    } catch (err) {
      console.error(err);
    }
    setLoader(false);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Target Logs
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          overflow: "auto",
          minHeight: "auto",
          maxHeight: "400px",
        }}
      >
        {loader === true ? (
          <SpinnerLoader />
        ) : logData.length === 0 ? (
          <div class="alert alert-danger center-margin-container" role="alert">
            No Logs Found!
          </div>
        ) : (
          <>
            <>
              <table className="main_log_table">
                <thead className="thead">
                  <tr>
                    <th>Date(D-MM-YYYY)</th>
                    <th>PM</th>
                    <th>Details</th>
                    <th>Conditions</th>
                    <th>Quota Name</th>
                    <th>Quota</th>
                  </tr>
                </thead>
                <tbody>
                  {logData.map((log_Item, logIndex) => (
                    <tr key={logIndex} className="main_log_tr">
                      <td>
                        {moment(log_Item.created_date).format("D-MM-YYYY")}
                      </td>
                      <td>{log_Item.created_by}</td>

                      {log_Item.log_data?.questions?.title ? (
                        <>
                          <td>{log_Item.log_data.questions.title}</td>
                          {log_Item.log_data.questions.q_id === 1 ? (
                            <>
                              <td>
                                {log_Item.log_data.questions.answer_options.map(
                                  (answerItem) => (
                                    <>
                                      min_age-{answerItem.min_age}- max_age-
                                      {answerItem.max_age}
                                    </>
                                  )
                                )}
                              </td>

                              <td>{log_Item.log_data.questions.q_name}</td>
                              <td>-</td>
                            </>
                          ) : (
                            <>
                              <td>
                                {log_Item.log_data.questions.answer_options.map(
                                  (answerItem) => (
                                    <>{answerItem}</>
                                  )
                                )}
                              </td>
                              <td>{log_Item.log_data.questions.q_name}</td>
                              <td>-</td>
                            </>
                          )}
                        </>
                      ) : log_Item.log_data?.quota?.q_name ? (
                        <>
                          <td></td>
                          <td>{log_Item.log_data.quota.status}</td>
                          <td>{log_Item.log_data.quota.q_name}</td>
                          <td>{log_Item.log_data.quota.n}</td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogsModal;
