import "./App.css";
import Navigation from "./components/auth/Navigation";
import Header from "./components/UI/Header/Header";
import useAuth from "./hooks/useAuth";
import { Routes, Route } from "react-router-dom";
function App() {
  const { auth } = useAuth();
  return (
    <>
      <div className="App">
        {auth?.accessToken && <Header />}
        <Navigation />
      </div>
    </>
  );
}

export default App;
