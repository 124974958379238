import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './PRStatusModal.css';

function PRStatusModal(props) {
    const notesData = props.prioritydata.notes;
    const commentsData = props.prioritydata.comments;

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="fs16">
                    Partner Request Status
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row vcm-row">
                    <div className="column vcm-column">
                        <h6>Notes</h6>

                        <table className="vcm-table">
                            {notesData.length === 0 ? (
                                <>
                                    <div className="alert alert-danger center-margin-container m-0" role="alert">
                                        No data Found!
                                    </div>
                                </>
                            ) : (
                                <>
                                    <tr className="vcm-tr">
                                        <th>Name</th>
                                        <th>Notes</th>
                                        <th>Note date</th>
                                    </tr>
                                    {notesData?.map((item) => (
                                        <>
                                            <tr className="vcm-tr">
                                                <td>{item.user.name}</td>
                                                <td>{item.notes}</td>
                                                <td>{item.note_date}</td>
                                            </tr>
                                        </>
                                    ))}
                                </>
                            )}
                        </table>
                    </div>

                    <div className="column vcm-column">
                        <h6>Comments</h6>

                        <table className="vcm-table">
                            {commentsData.length === 0 ? (
                                <>
                                    {' '}
                                    <div className="alert alert-danger center-margin-container m-0" role="alert">
                                        No data Found!
                                    </div>
                                </>
                            ) : (
                                <>
                                    {' '}
                                    <tr className="vcm-tr">
                                        <th>Name</th>
                                        <th>Notes</th>
                                        <th>Note date</th>
                                    </tr>
                                    {commentsData.map((item) => (
                                        <>
                                            <tr className="vcm-tr">
                                                <td>{item.user.name}</td>
                                                <td>{item.comment}</td>
                                                <td>{item.comment_date}</td>
                                            </tr>
                                        </>
                                    ))}
                                </>
                            )}
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="vcmBtn" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PRStatusModal;
