import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Row, Col, Button } from "react-bootstrap";
import "./NotificationBell.css";
import { MdNotificationsNone } from "react-icons/md";
import Badge from "@mui/material/Badge";
import { axiosPrivate } from "../../../../api/axios";
import Spinner from "react-bootstrap/Spinner";
import { yellow } from "@mui/material/colors";
import ViewAllNotifiaction from "./ViewAllNotification";
const BasicMenu = ({
  anchorEl,
  handleClose,
  open,
  menuItems,
  notificationData,
}) => {
  const [selected, setSelected] = useState(0);
  const [viewallStatus, setViewAllStatus] = useState(false);
  const [updateNotification, setUpdateNotification] = useState();

  const [getNotificationData, setGetNotificationData] =
    useState(notificationData);

  const [getNotificationData_Second, setGetNotificationData_Second] = useState(
    []
  );
  const accesstoken = "Bearer " + localStorage.getItem("atoken");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    } else {
      setSelected(i);
    }
  };

  useEffect(() => {
    setGetNotificationData(notificationData);
  }, [notificationData]);

  useEffect(() => {
    getNotificationDataApi_second();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      getNotificationDataApi_second();
    }, 20000);

    return () => clearInterval(interval);
  });
  const getIdThenPost = async (id) => {
    setIsSubmitting(true);
    try {
      await axiosPrivate
        .post(
          "/notification/update-notification",
          JSON.stringify({
            notification_id: id,
            is_resolve: true,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((e) => {
          getNotificationDataApi();
        });
    } catch (err) {
      {
        console.error(err);
      }
    }
    setIsSubmitting(false);
  };

  const getNotificationDataApi = async () => {
    try {
      const response = await axiosPrivate.get(
        `notification/get-notification?notify_id=1`,
        {
          headers: {
            "Content-Type": "application/json",
            "access-token": accesstoken,
          },
        }
      );

      setGetNotificationData(response.data.notification);
    } catch (error) {
      console.error(error);
    }
  };

  const getNotificationDataApi_second = async () => {
    try {
      const response = await axiosPrivate.get(`notification/get-followup`, {
        headers: {
          "Content-Type": "application/json",
          "access-token": accesstoken,
        },
      });

      setGetNotificationData_Second(response.data.notification);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {viewallStatus ? (
        <>
          <ViewAllNotifiaction
            show={viewallStatus}
            onHide={() => setViewAllStatus(false)}
            handleClose={handleClose}
          />
        </>
      ) : (
        <></>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="notification_wrapper">
          <div className="notification_bells">
            <span>
              {getNotificationData.length === 0 ? (
                <>
                  <div className="NoNotificationFound">
                    No Notification Found!!
                  </div>
                </>
              ) : (
                <>
                  {getNotificationData.map((items, index) => (
                    <>
                      <Badge
                        badgeContent={
                          items.notification_band.filter(
                            (item) => item.is_resolve === false
                          ).length
                        }
                        color="error"
                        key={index}
                      >
                        <a
                          className={
                            selected === index
                              ? items.bg_color_code
                              : items.front_color_code
                          }
                          style={{
                            color:
                              selected === index
                                ? items.front_color_code
                                : items.bg_color_code,
                            backgroundColor:
                              selected === index
                                ? items.bg_color_code
                                : items.front_color_code,
                          }}
                          onClick={() => toggle(index)}
                        >
                          {items.length != 0 ? <MdNotificationsNone /> : null}
                        </a>
                      </Badge>
                    </>
                  ))}

                  <Badge
                    badgeContent={getNotificationData_Second.length}
                    color="error"
                  >
                    <a
                      style={{
                        color:
                          selected === getNotificationData_Second.length
                            ? "black"
                            : "red",
                        backgroundColor:
                          selected === getNotificationData_Second.length
                            ? "yellow"
                            : "white",
                      }}
                      onClick={() => toggle(getNotificationData_Second.length)}
                    >
                      {getNotificationData_Second.length != 0 ? (
                        <MdNotificationsNone />
                      ) : null}
                    </a>
                  </Badge>
                </>
              )}
            </span>
          </div>

          <hr className="setMarginHr" />

          {/* {redBellShow ? (
                <>
                  <div className="notificatiion_content_wrapper">
                    <div className="notificatiion_content mb-1">
                      <p className="m-0">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Lorem ipsum dolor sit, amet consectetur
                        adipisicing elit. Lorem ipsum dolor sit, amet
                        consectetur adipisicing elit. Lorem ipsum dolor sit,
                        amet consectetur adipisicing elit.
                      </p>
                      <Button className="m-0">Got It</Button>
                    </div>
                  </div>
                </>
              ) : null}

              {greenBellShow ? (
                <>
                  <div className="notificatiion_content_wrapper">
                    <div className="notificatiion_content mb-2">
                      <p className="m-0">
                        Lorem ipsum dolor sit, amet consectetur adipisicing
                        elit. Lorem ipsum dolor sit, amet consectetur
                        adipisicing elit.
                      </p>
                      <Button className="m-0">Got It</Button>
                    </div>
                  </div>
                </>
              ) : null} */}

          {getNotificationData.map((items, index) => (
            <>
              <div
                className={
                  selected === index
                    ? "notificatiion_content_wrapper show"
                    : "notificatiion_content_wrapper"
                }
              >
                {items.notification_band.map((item, index) => (
                  <>
                    {item.is_resolve === false ? (
                      <>
                        <div className="notificatiion_content mb-1">
                          <p className="m-0">
                            <>{item.notification.title}</>
                          </p>
                          <Button
                            onClick={() => getIdThenPost(item.id)}
                            className="m-0"
                          >
                            {isSubmitting && (
                              <Spinner
                                style={{ color: "white" }}
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                            Got It
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </div>
            </>
          ))}
          <div className="Viewallbtn_wrapper">
            {getNotificationData_Second.slice(0, 3).map((item, index) => (
              <>
                <div
                  className={
                    selected === getNotificationData_Second.length
                      ? "notificatiion_content_wrapper show"
                      : "notificatiion_content_wrapper"
                  }
                >
                  <div className="notificatiion_content mb-1">
                    <p className="m-0">
                      <>{item.message}</>
                    </p>
                  </div>
                </div>
              </>
            ))}
            <Button
              // className="m-0"
              className={
                selected === getNotificationData_Second.length
                  ? "Viewallbtn_wrapper viewAllBtn_show "
                  : "Viewallbtn_wrapper viewAllBtn_hide"
              }
              onClick={() => {
                setViewAllStatus(true);
              }}
            >
              {isSubmitting && (
                <Spinner
                  style={{ color: "white" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              View All
            </Button>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default BasicMenu;
