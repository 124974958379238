import { useEffect, useState } from 'react';
import './ProjectDetail.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';

import { MultiSelect } from 'react-multi-select-component';
const CloneEdit = (prop) => {
    const { id } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [spinnerLoder, setSpinnerLoder] = useState(false);
    const [toggeleSwitchButton, setToggleSwitchButton] = useState(prop.qualificationData[prop.dataIndex].clone[prop.count].clone_target_aud_status);

    useEffect(() => {
        setValue('MarginNewThreshold', prop.data.margin_threshhold);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop]);

    const [selected_ex_api, setSelected_ex_api] = useState(prop.selected_ex_api);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    const notifyerror = (e) => toast.error(`${e} Error occured`);

    const handleNewCloneFormSubmit = async (data) => {
        let ans_code = selected_ex_api.map((option) => option.value);
        setSpinnerLoder(true);
        if (data.toString() === undefined) {
        } else {
            const payload = {
                country_id: prop.dataStorage.country.id,
                language_id: 1,
                ir: Number(data.CloneNewIr),
                loi: Number(data.CloneNewLoi),
                n: Number(data.CloneNewQuota),
                cpi: Number(prop.dataStorage.cpi),
                margin_threshhold: data.MarginNewThreshold,
                survey_status_id: prop.dataStorage.survey_status.id,
                project_survey_type_id: prop.dataStorage.project_survey_type.id,
                exclude_api: ans_code,
            };

            try {
                await axiosPrivate
                    .patch(`survey/update-survey-project?survey_id=${data.sid}`, payload, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((e) => {
                        toast.success('Updated successfully');
                    });
            } catch (err) {
                notifyerror(err.response.status);
            }
        }
        setSpinnerLoder(false);
    };

    const handleTargetSwitch = async (e, ID) => {
        setToggleSwitchButton(e);
        const payload = {
            status: e ? 1 : 0,
        };
        try {
            await axiosPrivate
                .patch(`survey/target-audience/${ID}`, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        prop.setQualificationData((prevState) => {
                            const newState = [...prevState];

                            const index = newState[prop.dataIndex].clone.findIndex((item) => item.cloneid === ID);

                            if (index !== -1) {
                                newState[prop.dataIndex].clone[index].clone_target_aud_status = e;
                            }

                            return newState; // Return the updated state
                        });

                        toast.success('Project updated successfully');
                    }
                });
        } catch (err) {
            setToggleSwitchButton(prop.qualificationData[prop.dataIndex].clone[prop.count].clone_target_aud_status);
            let x = 500;
            if (err.response.status.toString() === x.toString()) {
                notifyerror('Service');
            } else {
                toast.error(err.response.data.return_status.message[0]);
            }
        }
    };

    useEffect(() => {
        if (prop.allclonedata.length !== 0) {
            if (prop.data.exclude_api !== null) {
                const filteredAnswers = prop.exclude_api
                    .filter((answer) => prop.data.exclude_api.includes(answer.value))
                    .map((answer) => ({
                        label: answer.label,
                        value: answer.value,
                    }));

                setSelected_ex_api(filteredAnswers);
            } else {
                setSelected_ex_api([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.allclonedata, prop.exclude_api]);
    return (
        <>
            <form onSubmit={handleSubmit(handleNewCloneFormSubmit)}>
                <div className="first firstone" id="boxContainerBorder">
                    <div className="ScreeningHead pjt_arrow_icon flex items-center">
                        <p className="pBlockHead m-0">{prop.dataStorage.sc_id} - Clone </p>
                        <div className="rankPain projbtns">
                            <button
                                type="button"
                                className="PSaveBtn m-0"
                                style={{ width: '125px' }}
                                onClick={() => {
                                    navigate(`/project-detail/${id}/8/${prop.data._id}`);
                                }}
                            >
                                Target Audience
                            </button>

                            <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                size="xs"
                                width={60}
                                onlabel="ON"
                                offlabel="OFF"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                                checked={toggeleSwitchButton}
                                onChange={(e) => {
                                    handleTargetSwitch(e, prop.qualificationData[prop.dataIndex].clone[prop.count].cloneid);
                                }}
                            />
                            <span>|</span>
                            <button className="PSaveBtn m-0" type="submit">
                                {spinnerLoder && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                                &nbsp; Save
                            </button>
                        </div>
                    </div>

                    <div>
                        <div
                            style={{
                                backgroundColor: 'white',
                            }}
                            className="csmulti"
                        >
                            <input type="hidden" value={prop.data._id} {...register('sid')} />
                            <Row className="prj_rowtwo">
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">IR*</label>
                                    <input
                                        {...register('CloneNewIr', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.data.ir}
                                        type="text"
                                        placeholder="IR"
                                        className="CsformText m-0"
                                    />
                                    {errors.CloneNewIr && <small className="supply_errormsg m-0">{errors.CloneNewIr.message}</small>}
                                </Col>
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">LOI*</label>
                                    <input
                                        {...register('CloneNewLoi', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.data.loi}
                                        type="text"
                                        placeholder="LOI"
                                        className="CsformText m-0"
                                    />
                                    {errors.CloneNewLoi && <small className="supply_errormsg m-0">{errors.CloneNewLoi.message}</small>}
                                </Col>
                                <Col lg={2} md={6} xs={12} className="col">
                                    <label className="formlabel">Quota*</label>
                                    <input
                                        {...register('CloneNewQuota', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Only numbers are allowed',
                                            },
                                        })}
                                        defaultValue={prop.data.n}
                                        type="text"
                                        placeholder="Quota"
                                        className="CsformText m-0"
                                    />
                                    {errors.CloneNewQuota && <small className="supply_errormsg m-0">{errors.CloneNewQuota.message}</small>}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label className="formlabel">Margin Threshold*</label>
                                    <input
                                        {...register('MarginNewThreshold', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^(?:100|[1-9][0-9]?)$/,
                                                message: 'Only numbers from 1 to 100 are allowed',
                                            },
                                        })}
                                        defaultValue={prop.data.margin_threshhold}
                                        type="text"
                                        placeholder="Margin Threshold"
                                        className="CsformText m-0"
                                    />
                                    {errors.MarginNewThreshold && <small className="supply_errormsg m-0">{errors.MarginNewThreshold.message}</small>}
                                    {/* <Select
                    {...register(`MarginNewThreshold`, {
                      required: "This field is Required",
                    })}
                    placeholder="Select..."
                    className="pjt_select formtext"
                    classNamePrefix="select"
                    defaultValue={{
                      label: prop.data.margin_threshhold,
                      value: prop.data.margin_threshhold,
                    }}
                    options={prop.surveyMargin}
                    onChange={(e) => {
                      setValue(`MarginNewThreshold`, e.value);
                    }}
                    noOptionsMessage={() => "No Data Found"}
                  /> */}
                                </Col>
                                <Col lg={3} md={6} xs={12} className="col">
                                    <label
                                        className="formlabel"
                                        style={{
                                            marginBottom: '0px',
                                        }}
                                    >
                                        Exclude api's
                                    </label>

                                    <MultiSelect
                                        className="multiselectoption "
                                        style={{ width: '29px' }}
                                        options={prop.exclude_api}
                                        value={selected_ex_api}
                                        onChange={(e) => {
                                            setSelected_ex_api(e);
                                        }}
                                        labelledBy="Filter by user"
                                        isCreatable={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default CloneEdit;
