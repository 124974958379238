import Table from "react-bootstrap/Table";

import { BsGraphUp } from "react-icons/bs";
import { useState } from "react";

import BarChart from "./QualityChart";

import docIcon from "../../assets/images/icons8-google-docs-48.png";

import Paginations from "../Paginations/Paginations";
const Parametererrorlist = () => {
  const [graph, setGraphState] = useState(false);
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const getPageSize = (size) => {
    setPagesize(size);
  };
  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  return (
    <>
      <div className="mainContent mainContent2">
        <div className="table_container">
          {graph === true ? (
            <>
              <Table bordered={false} hover size="sm" className="customTable">
                <thead className="thead">
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <img
                        src={docIcon}
                        alt=""
                        srcset=""
                        className={
                          graph === false
                            ? "supplyIconColr"
                            : "SupIcon_notActive"
                        }
                        onClick={() => {
                          setGraphState(false);
                        }}
                      />
                      <BsGraphUp
                        style={{
                          fill: "black",
                        }}
                        className={
                          graph === true
                            ? "supplyIconColr"
                            : "SupIcon_notActive"
                        }
                        onClick={() => {
                          setGraphState(true);
                        }}
                      />
                    </td>
                  </tr>
                </thead>
              </Table>
              <>
                <BarChart />
              </>
            </>
          ) : (
            <>
              <Table bordered={false} hover size="sm" className="customTable">
                <thead className="thead">
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Error Parameter
                    </td>
                    <td>Error Counts</td>
                    <td>Error weightage</td>

                    <td>No. of audits</td>

                    <td>
                      <img
                        src={docIcon}
                        alt=""
                        srcset=""
                        className={
                          graph === false
                            ? "supplyIconColr"
                            : "SupIcon_notActive"
                        }
                        onClick={() => {
                          setGraphState(false);
                        }}
                      />
                      <BsGraphUp
                        style={{
                          fontSIze: "20px",
                          fill: "black",
                        }}
                        className={
                          graph === true
                            ? "supplyIconColr"
                            : "SupIcon_notActive"
                        }
                        onClick={() => {
                          setGraphState(true);
                        }}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>
                      <div className="circle-singleline">2</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>
                      <div className="circle-singleline">2</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>
                      <div className="circle-singleline">2</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>
                      <div className="circle-singleline">2</div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>-</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "50%",
                      }}
                    >
                      Basic Setup / Qualiﬁcations / Targeting / Links Error
                    </td>
                    <td>59</td>
                    <td>38.25</td>
                    <td>-</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </div>
      </div>
      {graph === true ? (
        <></>
      ) : (
        <>
          <Paginations
            userlist={10}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
          />
        </>
      )}
    </>
  );
};
export default Parametererrorlist;
