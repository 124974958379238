import userIco from '../../../assets/LoginIcons/users.svg';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import './Update_department.css';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import ButtonSpinner from '../../UI/ButtonSpinner';
import { useForm } from 'react-hook-form';

const NewDepartment = () => {
    const navigate = useNavigate();

    const axiosPrivate = useAxiosPrivate();
    const [users, setUsers] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const notifyerr = () => toast.error('Department already exists');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const createDepartment = async (e) => {
        setIsSubmitting(true);
        const payload = {
            name: e.Name,
            description: e.Description,
            head_of_dept: e.HeadofDepartment || 0,
        };
        try {
            await axiosPrivate
                .post('/department/create', payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    if (e.status === 200) {
                        localStorage.setItem('newdept', '1');
                        navigate('/department');
                    }
                });
        } catch (err) {
            if (err.response.status === 409) {
                notifyerr();
            }
        }

        setIsSubmitting(false);
    };

    const getUser = async () => {
        let isMounted = true;
        try {
            const response = await axiosPrivate.get('user/head-department', {});
            isMounted && setUsers(response.data.all_users);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="top_container">
                <div className="main_container ">
                    <form onSubmit={handleSubmit(createDepartment)}>
                        <div className="user_serachbox">
                            <div style={{ float: 'left' }}>
                                <h3 className="tableHeading">
                                    <img
                                        src={userIco}
                                        className="headingIcons"
                                        alt="Add Department"
                                    />{' '}
                                    New Department
                                </h3>
                            </div>
                            <div className="right-pane">
                                <button
                                    className="cbtn closeBtn"
                                    onClick={() => {
                                        navigate('/department');
                                    }}
                                >
                                    {' '}
                                    Close
                                </button>

                                <button
                                    disabled={isSubmitting}
                                    className="cbtn saveBtn"
                                >
                                    {isSubmitting && <ButtonSpinner />} &nbsp;
                                    Save
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="mainContent">
                            <p className="small note">
                                * denotes required fields
                            </p>
                            <Row>
                                <Col
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                >
                                    <label className="formlabel">Name*</label>
                                    <input
                                        type="text"
                                        placeholder="Department Name"
                                        className="formtext"
                                        {...register('Name', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                                message:
                                                    'Only letters are allowed',
                                            },
                                        })}
                                    />
                                    {errors.Name && (
                                        <small className="errmsg">
                                            {errors.Name.message}
                                        </small>
                                    )}
                                </Col>
                                <Col
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                >
                                    <label className="formlabel">
                                        Description*
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Description"
                                        className="formtext"
                                        {...register('Description', {
                                            required: 'This field is Required',
                                            pattern: {
                                                value: /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ ]*$/,
                                                message:
                                                    'Only letters are allowed',
                                            },
                                        })}
                                    />
                                    {errors.Description && (
                                        <small className="errmsg">
                                            {errors.Description.message}
                                        </small>
                                    )}
                                </Col>

                                <Col
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                >
                                    <label className="formlabel">
                                        Head of Department*
                                    </label>
                                    <select
                                        style={{ background: 'white' }}
                                        className="formtext"
                                        {...register('HeadofDepartment', {
                                            required: 'This field is Required',
                                        })}
                                    >
                                        <option value="" style={{}}>
                                            Select Option
                                        </option>
                                        {users.map((user) => (
                                            <option
                                                key={user.first_name}
                                                value={user._id}
                                            >
                                                {user.name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.HeadofDepartment && (
                                        <small className="errmsg">
                                            {errors.HeadofDepartment.message}
                                        </small>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default NewDepartment;
