import pageIcon from '../../../assets/BidsIcons/client.svg';
import addIcon from '../../../assets/BidsIcons/add.svg';
import BidsSubHeader from '../../BidsModule/BidsSubHeader/BidsSubHeader';
import Bidstopheader from './Bidstopheader';
import Header from '../../UI/Header/Header';
import { useNavigate, useParams } from 'react-router';
import DataTable from 'react-data-table-component';
import SpinnerLoader from '../../UI/Spinner';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import EditIcon from '../../../assets/LoginIcons/edit.svg';
import DeleteIcon from '../../../assets/LoginIcons/delete.svg';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import Bidstopheaderselect from '../BidsTable/BidsTopHeaderSelect';
import '../../AdminModule/Tables/StyleDatatable.css';
import useAuth from '../../../hooks/useAuth';
const Clientcontact = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(5);
    const [filterUser, setFilterUser] = useState(null);
    const [clientContactList, setClientContactList] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    var localData = localStorage.getItem('clientname');
    const [pending, setPending] = useState(true);

    const { id } = useParams();
    const { auth } = useAuth();

    const superUser = auth.superuser;
    const all_permission = auth.permission;

    useEffect(() => {
        get_CLientContact_AllList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get_CLientContact_AllList = async () => {
        try {
            const response = await axiosPrivate.get(`/client/client-contact-list/${id}`, {});
            let data = response.data.all_client_contact;
            setClientContactList(data);
            setPending(false);
        } catch (err) {
            console.error(err);
        }
        return () => {};
    };

    const handleDelete = async (cid) => {
        const payload = {
            client_id: id,
            client_contact_id: cid,
        };
        try {
            await axiosPrivate.delete(
                '/client/delete-client-contact',
                { data: payload },
                {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );
        } catch (err) {}
        const del = clientContactList.filter((User) => cid !== User.id);
        setClientContactList(del);
        userDeleted();
    };

    const userDeleted = () => toast.success('Client contact deleted successfully');
    const notifyActive = () => toast.success('Client contact is active');
    const notifyNotActive = () => toast.success('Client contact is inactive');

    const handle_status = async (cid, status, e) => {
        try {
            await axiosPrivate
                .patch(
                    '/client/active-inactive-client-contact',
                    JSON.stringify({
                        client_id: id,
                        client_contact_id: cid,
                        new_status: e,
                    }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                )
                .then((e) => {
                    if (e.data.new_status === 1) {
                        notifyActive();
                    } else {
                        notifyNotActive();
                    }
                });
        } catch (err) {
            notifyerror(err.response.statusText);
        }
    };

    const notifyerror = (e) => toast.error(`${e} error occured`);
    const columns = [
        {
            id: 'sno',
            name: 'Sno.',
            selector: (row, i) => currentRowsPerPage * (currentPage - 1) + (i + 1),
            width: '100px',
        },
        {
            id: 'name',
            name: 'Client Contact Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'Status',
            cell: (row) => (
                <>
                    {superUser === true ? (
                        <>
                            <BootstrapSwitchButton
                                className="switch btn on btn-outline-success btn-xs"
                                checked={row.status === 1 ? true : false}
                                onChange={(e) => {
                                    handle_status(row.id, row.status, e);
                                }}
                                size="xs"
                                width={80}
                                onlabel="Active"
                                offlabel="Inactive"
                                onstyle="outline-success"
                                offstyle="outline-danger"
                            />
                        </>
                    ) : (
                        <>
                            {all_permission.includes('client.client_change_contact_status') ? (
                                <>
                                    {' '}
                                    <BootstrapSwitchButton
                                        className="switch btn on btn-outline-success btn-xs"
                                        checked={row.status === 1 ? true : false}
                                        onChange={(e) => {
                                            handle_status(row.id, row.status, e);
                                        }}
                                        size="xs"
                                        width={80}
                                        onlabel="Active"
                                        offlabel="Inactive"
                                        onstyle="outline-success"
                                        offstyle="outline-danger"
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            ),
            width: '120px',
        },
        {
            name: 'Action',
            selector: (row) => (
                <td>
                    {superUser === true ? (
                        <>
                            <span
                                className="actionButton"
                                onClick={() => {
                                    navigate(`/update-client-contact/${id}/${row.id}`);
                                }}
                            >
                                <img src={EditIcon} alt="" />
                            </span>
                        </>
                    ) : (
                        <>
                            {all_permission.includes('client.client_update_contact') || all_permission.includes('client.client_get_contact_list') ? (
                                <>
                                    {' '}
                                    <span
                                        className="actionButton"
                                        onClick={() => {
                                            navigate(`/update-client-contact/${id}/${row.id}`);
                                        }}
                                    >
                                        <img src={EditIcon} alt="" />
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}

                    {superUser === true ? (
                        <>
                            <span className="actionButton">
                                <img
                                    src={DeleteIcon}
                                    alt=""
                                    onClick={() => {
                                        confirmAlert({
                                            message: 'Do you want to delete ' + row.name,
                                            buttons: [
                                                {
                                                    label: 'Yes',
                                                    onClick: () => handleDelete(row.id),
                                                },
                                                {
                                                    label: 'No',
                                                },
                                            ],
                                        });
                                    }}
                                />
                            </span>
                        </>
                    ) : (
                        <>
                            {all_permission.includes('client.client_delete_contact') ? (
                                <>
                                    {' '}
                                    <span className="actionButton">
                                        <img
                                            src={DeleteIcon}
                                            alt=""
                                            onClick={() => {
                                                confirmAlert({
                                                    message: 'Do you want to delete ' + row.name,
                                                    buttons: [
                                                        {
                                                            label: 'Yes',
                                                            onClick: () => handleDelete(row.id),
                                                        },
                                                        {
                                                            label: 'No',
                                                        },
                                                    ],
                                                });
                                            }}
                                        />
                                    </span>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </td>
            ),
            width: '110px',
        },
    ];
    const showSearch = (searchText) => {
        const copyOfUsers = [...clientContactList];
        setSearch(searchText);

        const result = copyOfUsers.filter((points) => {
            if (points.title == null || 'null') {
                return points.name.toString().toLowerCase().match(searchText.toString().toLowerCase());
            } else {
                points.name.toString().toLowerCase().match(searchText.toString().toLowerCase()) || points.title.toString().toLowerCase().match(searchText.toString().toLowerCase());
            }
        });

        setFilterUser(result);
    };
    const customStyles = {
        rows: {
            style: {
                minHeight: '40px',
            },
        },
    };
    return (
        <>
            {/* <Header /> */}
            <BidsSubHeader pname="clientlist" />

            <div className="top_container">
                <div className="main_container ">
                    <div className="user_serachbox">
                        <div style={{ float: 'left' }}>
                            <h3 className="tableHeading">
                                <img src={pageIcon} className="headingIcons" alt="" /> {localData}
                            </h3>
                        </div>
                        <input type="search" className="tableSearch" placeholder="Search..." value={search} onChange={(e) => showSearch(e.target.value)} />

                        {superUser === true ? (
                            <>
                                <button
                                    className="cbtn addBtn"
                                    onClick={() => {
                                        navigate(`/add-client-contact/${id}`);
                                    }}
                                >
                                    <img src={addIcon} alt="" /> &nbsp; Add Client Contact
                                </button>
                            </>
                        ) : (
                            <>
                                {all_permission.includes('client.client_add_contact') ? (
                                    <>
                                        <button
                                            className="cbtn addBtn"
                                            onClick={() => {
                                                navigate(`/add-client-contact/${id}`);
                                            }}
                                        >
                                            <img src={addIcon} alt="" /> &nbsp; Add Client Contact
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                    <hr className="showingHeader" />
                    {/* <Bidstopheader buttonName={buttonName} tid="3" clientid={id} /> */}
                    <Bidstopheader clientid={id} tid="3" className="showingHeader" />
                    <Bidstopheaderselect clientid={id} namevalue={'client-contact'} />
                    <div className="mainContent dTable bids">
                        <DataTable
                            columns={columns}
                            data={filterUser || clientContactList}
                            pagination
                            fixedHeader
                            highlightOnHover
                            onChangePage={(page, totalRows) => {
                                setCurrentPage(page);
                            }}
                            onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                                setCurrentRowsPerPage(currentRowsPerPage);
                            }}
                            defaultSortFieldId="name"
                            defaultSortAsc={true}
                            progressPending={pending}
                            customStyles={customStyles}
                            progressComponent={<SpinnerLoader />}
                            noDataComponent="No Data Found"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Clientcontact;
