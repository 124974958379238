import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useNavigate } from "react-router";
import SettingHeader from "./SettingHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useState, useEffect } from "react";
import ButtonSpinner from "../../UI/ButtonSpinner";
import userIco from "../../../assets/LoginIcons/users.svg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";

import toast from "react-hot-toast";
import "./ExclusionSetting.css";
const VariableMapping = () => {
  const navigate = useNavigate();
  const pname = "Variable Mapping";
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVariableIncome, setShowVariableIncome] = useState([1]);
  const [showVariableOutgoing, setShowVariableOutgoing] = useState([1]);
  const [loder, setLoder] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [formData, setFormData] = useState({
    usT: "",
    usVF: "",
    upT: "",
    upVF: "",
    recontact_VF: "",
    recontact_PID: "",
    recontact_TID: "",
    upcv1: "",
    upcf1: "",
    upcv2: "",
    upcf2: "",
    upcv3: "",
    upcf3: "",
    upcv4: "",
    upcf4: "",
    upcv5: "",
    upcf5: "",
    upcv6: "",
    upcf6: "",
    outgoingUS: "",
    outgoingUP: "",
    outgoingCV1: "",
    outgoingCV2: "",
    outgoingCV3: "",
    outgoingCV4: "",
    outgoingCV5: "",
    outgoingCV6: "",
  });
  useEffect(() => {
    get_ProjectType_api();
  }, []);
  const notifysucces = () => toast.success("Updated successfully");
  const handleVariable = (e) => {
    e.stopPropagation();
    if (showVariableIncome.length >= 6) {
    } else {
      setShowVariableIncome((e) => [...e, 1]);
    }
  };
  const handleVariableOutGOing = () => {
    if (showVariableOutgoing.length >= 6) {
    } else {
      setShowVariableOutgoing((e) => [...e, 1]);
    }
  };

  const get_ProjectType_api = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(`supplyinfo/get-supplier/${id}`);
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_1 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv1:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_1.custom_variable,
          upcf1:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_1.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv1: "",
          upcf1: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_2 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv2:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_2.custom_variable,
          upcf2:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_2.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv2: "",
          upcf2: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_3 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv3:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_3.custom_variable,
          upcf3:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_3.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv3: "",
          upcf3: "",
        }));
      }

      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_4 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv4:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_4.custom_variable,
          upcf4:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_4.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv4: "",
          upcf4: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_5 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv5:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_5.custom_variable,
          upcf5:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_5.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv5: "",
          upcf5: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_recontact !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          recontact_VF:
            response.data.supplier.variable_mapping.incoming.in_recontact
              .var_name,
          recontact_PID:
            response.data.supplier.variable_mapping.incoming.in_recontact
              .panelist_id,
          recontact_TID:
            response.data.supplier.variable_mapping.incoming.in_recontact
              .transaction_id,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          recontact_VF: "",
          recontact_TID: false,
          recontact_PID: false,
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_custom_variable
          .custom_variable_6 !== null
      ) {
        setFormData((prevData) => ({
          ...prevData,

          upcv6:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_6.custom_variable,
          upcf6:
            response.data.supplier.variable_mapping.incoming.in_custom_variable
              .custom_variable_6.custom_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          upcv6: "",
          upcf6: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_unique_panelist !==
          null
      ) {
        setFormData((prevData) => ({
          ...prevData,
          upT: response.data.supplier.variable_mapping.incoming
            .in_unique_panelist.panelist_id,
          upVF: response.data.supplier.variable_mapping.incoming
            .in_unique_panelist.var_format,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          usT: "",
          usVF: "",
          upT: "",
          upVF: "",
          upcv1: "",
          upcf1: "",
          upcv2: "",
          upcf2: "",
          upcv3: "",
          upcf3: "",
          upcv4: "",
          upcf4: "",
          upcv5: "",
          upcf5: "",
          upcv6: "",
          upcf6: "",
          outgoingUS: "",
          outgoingUP: "",
          outgoingCV1: "",
          outgoingCV2: "",
          outgoingCV3: "",
          outgoingCV4: "",
          outgoingCV5: "",
          outgoingCV6: "",
        }));
      }
      if (
        response.data.supplier.variable_mapping !== null &&
        response.data.supplier.variable_mapping.incoming.in_unique_session !==
          null
      ) {
        setFormData((prevData) => ({
          ...prevData,
          usT: response.data.supplier.variable_mapping.incoming
            .in_unique_session.transaction_id,
          usVF: response.data.supplier.variable_mapping.incoming
            .in_unique_session.var_format,

          outgoingUS:
            response.data.supplier.variable_mapping.outgoing.out_unique_session,
          outgoingUP:
            response.data.supplier.variable_mapping.outgoing
              .out_unique_panelist,
          outgoingCV1:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_1,
          outgoingCV2:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_2,

          outgoingCV3:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_3,

          outgoingCV4:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_4,
          outgoingCV5:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_5,
          outgoingCV6:
            response.data.supplier.variable_mapping.outgoing.out_custom_variable
              .custom_variable_6,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,

          usT: "",
          usVF: "",

          outgoingUS: "",
          outgoingUP: "",
          outgoingCV1: "",
          outgoingCV2: "",
          outgoingCV3: "",
          outgoingCV4: "",
          outgoingCV5: "",
          outgoingCV6: "",
        }));
      }

      setLoder(true);
    } catch (err) {
      console.error(err);
    }
  };

  const postData = async (e) => {
    setIsSubmitting(true);

    try {
      await axiosPrivate
        .post(
          `/supplyinfo/supplier-variable-mapping/${id}`,
          JSON.stringify({
            incoming: {
              in_unique_session: {
                transaction_id: e.usT,
                var_format: e.usVF,
              },
              in_unique_panelist: {
                panelist_id: e.upT,
                var_format: e.upVF,
              },
              in_recontact: {
                old_var: e.recontact_vat,
                transaction_id: e.recontact_TID,
                panelist_id: e.recontact_PID,
              },
              in_custom_variable: {
                custom_variable_1: {
                  custom_variable: e.upcv1,
                  custom_format: e.upcf1,
                },
                custom_variable_2: {
                  custom_variable: e.upcv2,
                  custom_format: e.upcf2,
                },
                custom_variable_3: {
                  custom_variable: e.upcv3,
                  custom_format: e.upcf3,
                },
                custom_variable_4: {
                  custom_variable: e.upcv4,
                  custom_format: e.upcf4,
                },
                custom_variable_5: {
                  custom_variable: e.upcv5,
                  custom_format: e.upcf5,
                },
                custom_variable_6: {
                  custom_variable: e.upcv6,
                  custom_format: e.upcf6,
                },
              },
            },
            outgoing: {
              out_unique_session: e.outgoingUS,
              out_unique_panelist: e.outgoingUP,
              out_custom_variable: {
                custom_variable_1: e.outgoingCV1,
                custom_variable_2: e.outgoingCV2,
                custom_variable_3: e.outgoingCV3,
                custom_variable_4: e.outgoingCV4,
                custom_variable_5: e.outgoingCV5,
                custom_variable_6: e.outgoingCV6,
              },
            },
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((data) => {
          if (data.status === 200) {
            notifysucces();
          }
        });
    } catch (err) {}
    setIsSubmitting(false);
  };

  const handleDeleteClientLive = (index) => {
    setShowVariableIncome((prevClientlive) => {
      const updatedClientlive = [...prevClientlive];
      updatedClientlive.splice(index, 1);
      return updatedClientlive;
    });
  };
  const handleDelete = (index) => {
    setShowVariableOutgoing((prevClientlive) => {
      const updatedClientlive = [...prevClientlive];
      updatedClientlive.splice(index, 1);
      return updatedClientlive;
    });
  };

  return (
    <>
      <SettingHeader pname={pname} />

      <div className="top_container">
        <div className="main_container ">
          <form onSubmit={handleSubmit(postData)}>
            {/* <form onSubmit={handleSubmit(createDepartment)}> */}
            <div className="user_serachbox">
              <div style={{ float: "left" }}>
                <h3 className="tableHeading">
                  <img
                    src={userIco}
                    className="headingIcons"
                    alt="Add Department"
                  />{" "}
                  Variable Mapping
                </h3>
              </div>

              <div className="right-pane">
                <button
                  className="cbtn closeBtn"
                  onClick={() => {
                    navigate("/supplier-details");
                  }}
                >
                  {" "}
                  Close
                </button>

                <button disabled={isSubmitting} className="cbtn saveBtn">
                  {isSubmitting && <ButtonSpinner />} &nbsp; Save
                </button>
              </div>
            </div>
            <hr />
            {loder ? (
              <>
                <div
                  id="dividerow"
                  style={{ marginTop: "10px" }}
                  className="mainContent"
                >
                  <div className="settingHeading">
                    <p className="pBlockHead">Incoming</p>
                  </div>
                  <div style={{ border: "1px solid whiteSmoke" }}>
                    <div className="rowContant">
                      <Row>
                        <Col lg={6} md={6} xs={12} className="col">
                          <label
                            className="formlabel"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Unique Session*
                          </label>
                          <Row>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">Transaction*</label>
                              <input
                                {...register("usT", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder="Transaction Id"
                                className="CsformText mb-0"
                                defaultValue={formData.usT}
                              />
                              {errors.usT && (
                                <small className="supplier_setting_error">
                                  {errors.usT.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Variable Format*
                              </label>
                              <input
                                {...register("usVF", {
                                  required: "This field is Required",
                                })}
                                type="text"
                                placeholder=" Variable Format"
                                className="CsformText mb-0"
                                defaultValue={formData.usVF}
                              />
                              {errors.usVF && (
                                <small className="supplier_setting_error">
                                  {errors.usVF.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="col">
                          <label
                            className="formlabel"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Unique Panelist
                          </label>
                          <Row>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">Panelist</label>
                              <input
                                {...register("upT")}
                                type="text"
                                placeholder=" Transaction Id"
                                className="CsformText"
                                defaultValue={formData.upT}
                              />
                              {errors.upT && (
                                <small className="supplier_setting_error">
                                  {errors.upT.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={6} md={6} xs={12} className="col">
                              <label className="formlabel">
                                Variable Format
                              </label>
                              <input
                                {...register("upVF")}
                                type="text"
                                placeholder="Variable Format"
                                className="CsformText"
                                defaultValue={formData.upVF}
                              />
                              {errors.upVF && (
                                <small className="supplier_setting_error">
                                  {errors.upVF.message}
                                </small>
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} md={6} xs={12} className="col">
                          <label
                            className="formlabel"
                            style={{
                              fontWeight: "bold",
                            }}
                          >
                            Recontact
                          </label>
                          <Row>
                            <Col lg={4} md={6} xs={12} className="col">
                              <label className="formlabel">Old Var</label>
                              <input
                                {...register("recontact_vat")}
                                type="text"
                                placeholder="Old Var"
                                className="CsformText"
                                defaultValue={formData.recontact_VF}
                              />
                              {errors.recontact_vat && (
                                <small className="supplier_setting_error">
                                  {errors.recontact_vat.message}
                                </small>
                              )}
                            </Col>
                            <Col lg={4} md={6} xs={12} className="col">
                              <label className="formlabel">Transaction</label>
                              <input
                                {...register("recontact_TID")}
                                type="checkbox"
                                defaultChecked={formData.recontact_TID}
                                // onChange={(e) => {
                                //   // setBillingSC_Count(e.target.checked);
                                // }}
                              />
                            </Col>
                            <Col lg={4} md={6} xs={12} className="col">
                              <label className="formlabel">Panelist</label>
                              <input
                                {...register("recontact_PID")}
                                type="checkbox"
                                defaultChecked={formData.recontact_PID}
                                // onChange={(e) => {
                                //   // setBillingSC_Count(e.target.checked);
                                // }}
                              />
                            </Col>
                          </Row>
                        </Col>

                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Custom Variable 1</label>
                          <input
                            {...register("upcv1")}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.upcv1}
                          />
                          {errors.upcv1 && (
                            <small className="supplier_setting_error">
                              {errors.upcv1.message}
                            </small>
                          )}
                          <input
                            {...register("upcf1")}
                            type="text"
                            placeholder="Custom Variable Format"
                            className="CsformText"
                            defaultValue={formData.upcf1}
                          />
                          {errors.upcf1 && (
                            <small className="supplier_setting_error">
                              {errors.upcf1.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Custom Variable 2</label>
                          <input
                            {...register("upcv2")}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.upcv2}
                          />
                          {errors.upcv2 && (
                            <small className="supplier_setting_error">
                              {errors.upcv2.message}
                            </small>
                          )}
                          <input
                            {...register("upcf2")}
                            type="text"
                            placeholder="Custom Variable Format"
                            className="CsformText"
                            defaultValue={formData.upcf2}
                          />
                          {errors.upcf2 && (
                            <small className="supplier_setting_error">
                              {errors.upcf2.message}
                            </small>
                          )}
                        </Col>
                        {showVariableIncome.length >= 2 ||
                        formData.upcv3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 3
                            </label>
                            <input
                              {...register("upcv3")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv3}
                            />

                            {errors.upcv3 && (
                              <small className="supplier_setting_error">
                                {errors.upcv3.message}
                              </small>
                            )}
                            <input
                              {...register("upcf3")}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf3}
                            />

                            {errors.upcf3 && (
                              <small className="supplier_setting_error">
                                {errors.upcf3.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 3 ||
                        formData.upcv4 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 4
                            </label>
                            <input
                              {...register("upcv4")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv4}
                            />

                            {errors.upcv4 && (
                              <small className="supplier_setting_error">
                                {errors.upcv4.message}
                              </small>
                            )}
                            <input
                              {...register("upcf4")}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf4}
                            />

                            {errors.upcf4 && (
                              <small className="supplier_setting_error">
                                {errors.upcf4.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 4 ||
                        formData.upcv5 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 5
                            </label>
                            <input
                              {...register("upcv5")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv5}
                            />

                            {errors.upcv5 && (
                              <small className="supplier_setting_error">
                                {errors.upcv5.message}
                              </small>
                            )}
                            <input
                              {...register("upcf5")}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf5}
                            />

                            {errors.upcf5 && (
                              <small className="supplier_setting_error">
                                {errors.upcf5.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableIncome.length >= 5 ||
                        formData.upcv6 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 6
                            </label>
                            <input
                              {...register("upcv6")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.upcv6}
                            />

                            {errors.upcv6 && (
                              <small className="supplier_setting_error">
                                {errors.upcv6.message}
                              </small>
                            )}
                            <input
                              {...register("upcf6")}
                              type="text"
                              placeholder="Custom Variable Format"
                              className="CsformText"
                              defaultValue={formData.upcf6}
                            />

                            {errors.upcf6 && (
                              <small className="supplier_setting_error">
                                {errors.upcf6.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col>
                          {showVariableIncome.length >= 3 ? (
                            <>
                              <button
                                className="cbtn closeBtn addbutton"
                                onClick={(e) => {
                                  handleDeleteClientLive();
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          {showVariableIncome.length !== 5 ? (
                            <>
                              <button
                                className="cbtn saveBtn addbutton"
                                onClick={(e) => {
                                  handleVariable(e);
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Add
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div id="dividerow" className="mainContent">
                  <div className="settingHeading">
                    <p className="pBlockHead">Outgoing</p>
                  </div>
                  <div style={{ border: "1px solid whiteSmoke" }}>
                    <div className="rowContant">
                      <Row>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Unique Sessions*</label>
                          <input
                            {...register("outgoingUS", {
                              required: "This field is Required",
                            })}
                            type="text"
                            placeholder="Unique Sessions"
                            className="CsformText mb-0"
                            defaultValue={formData.outgoingUS}
                          />
                          {errors.outgoingUS && (
                            <small className="supplier_setting_error">
                              {errors.outgoingUS.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Unique Panelist</label>
                          <input
                            {...register("outgoingUP")}
                            type="text"
                            placeholder="Unique Panelist"
                            className="CsformText"
                            defaultValue={formData.outgoingUP}
                          />
                          {errors.outgoingUP && (
                            <small className="supplier_setting_error">
                              {errors.outgoingUP.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Custom Variable 1</label>
                          <input
                            {...register("outgoingCV1")}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.outgoingCV1}
                          />
                          {errors.outgoingCV1 && (
                            <small className="supplier_setting_error">
                              {errors.outgoingCV1.message}
                            </small>
                          )}
                        </Col>
                        <Col lg={3} md={6} xs={12} className="col">
                          <label className="formlabel">Custom Variable 2</label>
                          <input
                            {...register("outgoingCV2")}
                            type="text"
                            placeholder="Custom Variable"
                            className="CsformText"
                            defaultValue={formData.outgoingCV2}
                          />
                          {errors.outgoingCV2 && (
                            <small className="supplier_setting_error">
                              {errors.outgoingCV2.message}
                            </small>
                          )}
                        </Col>
                        {showVariableOutgoing.length >= 2 ||
                        formData.outgoingCV3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 3
                            </label>
                            <input
                              {...register("outgoingCV3")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV3}
                            />
                            {errors.outgoingCV3 && (
                              <small className="supplier_setting_error">
                                {errors.outgoingCV3.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 3 ||
                        formData.outgoingCV3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 4
                            </label>
                            <input
                              {...register("outgoingCV4")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV4}
                            />
                            {errors.outgoingCV4 && (
                              <small className="supplier_setting_error">
                                {errors.outgoingCV4.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 4 ||
                        formData.outgoingCV3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 5
                            </label>
                            <input
                              {...register("outgoingCV5")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV5}
                            />
                            {errors.outgoingCV5 && (
                              <small className="supplier_setting_error">
                                {errors.outgoingCV5.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}
                        {showVariableOutgoing.length >= 5 ||
                        formData.outgoingCV3 !== null ? (
                          <Col lg={3} md={6} xs={12} className="col">
                            <label className="formlabel">
                              Custom Variable 6
                            </label>
                            <input
                              {...register("outgoingCV6")}
                              type="text"
                              placeholder="Custom Variable"
                              className="CsformText"
                              defaultValue={formData.outgoingCV6}
                            />
                            {errors.outgoingCV6 && (
                              <small className="supplier_setting_error">
                                {errors.outgoingCV6.message}
                              </small>
                            )}
                          </Col>
                        ) : (
                          <></>
                        )}

                        <Col>
                          {showVariableOutgoing.length >= 2 ? (
                            <>
                              <button
                                className="cbtn closeBtn addbutton"
                                style={{
                                  marginRight: "10px",
                                }}
                                onClick={(e) => {
                                  handleDelete();
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                Remove
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                          {showVariableOutgoing.length !== 5 ? (
                            <>
                              <button
                                className="cbtn saveBtn addbutton"
                                style={{}}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleVariableOutGOing();

                                  e.preventDefault();
                                }}
                              >
                                Add
                              </button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default VariableMapping;
