import React, { useState, useEffect } from 'react';
import './SurveyListing.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import '../../UI/DateRangePicker.css';
import SLicon from './Assets/survey-listing-icon.svg';
import { Col, Row } from 'react-bootstrap';
import SurveyInnerTable from './SurveyInnerTable';
import Select from 'react-select';
import Paginations from '../../Paginations/Paginations';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { MultiSelect } from 'react-multi-select-component';
import SpinnerLoader from '../../UI/Spinner';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import { BASE_URL } from '../../../api/axios';
import { IoMdRefresh } from 'react-icons/io';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AllStatusModify from './AllStatusModified';
import Tooltip from '@mui/material/Tooltip';
import { BiPlus, BiSearch, BiFileBlank, BiDownload, BiEditAlt, BiLink } from 'react-icons/bi';
import LinksModal from './LinksModal';
import Spinner from 'react-bootstrap/Spinner';
import Surveysupplier from './Surveysupplier';
function SurveyListing() {
    const [projId, setProjId] = useState('');
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [loder, setLoder] = useState(false);
    const [pagesize, setPagesize] = useState(10);
    const [nextPage, setNextPage] = useState(1);
    const [dataLength, setDataLength] = useState();
    const [clientListStorage, setClientListStorage] = useState([]);
    const [clientNameValue, setClientNameValue] = useState([]);
    const [projectTypeStorage, setProjectTypeStorage] = useState([]);
    const [projectTypeValue, setProjectTypeValue] = useState([]);
    const [projectManagerStorage, setProjectManagerStorage] = useState([]);
    const [projectManagerValue, setProjectManagerValue] = useState([]);
    const [projectStatusStorage, setProjectStatusStorage] = useState([]);
    const [projectStatusValue, setProjectStatusValue] = useState([]);
    const [links, setLinks] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search_name, setSearch_name] = useState('');
    const [hrefValue, setHrefValue] = useState('#');
    const [reloadPagination, setReloadPagination] = useState(false);
    const [label_status, setLabel_Status] = useState(true);
    const [label_num, setLabel_num] = useState('');
    const [selected_filter, setSelected_filter] = useState('');
    const [surveysuppliershow, setSurveySuppliershow] = useState(false);
    const { register, handleSubmit, formState, setValue } = useForm();
    const [selecteddate_option, setSelectedDate_Option] = useState([]);
    const [allStatusChnages, setAllStatusChanges] = useState(false);
    const [project_id, setProject_id] = useState(null);
    const [projectStatus, setProjectStatus] = useState(null);
    const [callApi, setCallApi] = useState(false);
    const dateRange_list = [
        {
            label: 'Filter By Created Date',
            value: 'created_date_filter',
        },
        {
            label: 'Filter By Launch Date',
            value: 'launch_date_filter',
        },
        {
            label: 'Filter By Last Updated Date',
            value: 'lastupdate_date_filter',
        },
        {
            label: 'Filter By Billed Date',
            value: 'billed_date_filter',
        },
    ];
    let project_status;
    let userString;
    let project_type_string;
    let project_manage_string;
    const notifyerror = (e) => toast.error(`${e} Error occured`);

    useEffect(() => {
        get_ClintList_api();
        get_ProjectType_api();
        get_ProjectManager_api();
        get_ProjectStatus_api();
    }, []);

    const getPageSize = (size) => {
        setPagesize(size);
        setNextPage(1);
    };

    const handleRow = (eid, id) => {
        setProjId(id);
        let activeRow = document.getElementById(eid);
        document.querySelectorAll('.inner-border').forEach((el) => el.classList.remove('open'));
        if (activeRow.classList.contains('active')) {
            activeRow.classList.remove('active');
        } else {
            document.querySelectorAll('.mainrow').forEach((el) => el.classList.remove('active'));
            activeRow.classList.add('active');
            document.querySelector('.' + eid).classList.add('open');
        }
    };

    const handlePageChnage = (pagesize) => {
        setNextPage(pagesize + 1);
    };

    const get_ClintList_api = async () => {
        try {
            const response = await axiosPrivate.get('client/client-list');
            response.data.all_client.forEach((e) => {
                setClientListStorage((val) => [...val, { label: e.name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    const get_ProjectType_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-project-type');
            response.data.all_status.forEach((e) => {
                setProjectTypeStorage((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    const get_ProjectManager_api = async () => {
        try {
            const response = await axiosPrivate.get('user/all-users');
            response.data.all_users.forEach((e) => {
                setProjectManagerStorage((val) => [...val, { label: e.first_name + ' ' + e.last_name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    const get_ProjectStatus_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-project-status');
            response.data.all_status.forEach((e) => {
                setProjectStatusStorage((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const get_ProjectList_api = async () => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(`project/project-list?page=${nextPage}&size=${pagesize}&sort=-_id`);

            setDataLength(response.data.total);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setLoder(true);
    };

    const [selectedDate_start, setSelectedDate_start] = useState('');
    const [selectedDate_end, setSelectedDate_end] = useState('');
    const [filterClint, setFilterClint] = useState('');
    const [filterProjectType, setFilterProjectType] = useState('');
    const [filterProjectManager, setFilterProjectManager] = useState('');
    const [filterProjectStatus, setFilterProjectStatus] = useState('');
    const [filterSearch, setFilterSearch] = useState('');
    const [reloadAllTable, setReloadAllTable] = useState(false);

    useEffect(() => {
        fetchFirstTime();
    }, []);

    useEffect(() => {
        if (callApi) {
            setCallApi(false);
            fetchFirstTime();
        }
    }, [callApi]);

    useEffect(() => {
        fetchFirstTime();
    }, [nextPage, pagesize, reloadAllTable]);

    const fetchFirstTime = async (nPage, nPageSize) => {
        setLoder(false);
        var page;
        var pSize;
        if (nPage === undefined) {
            page = nextPage;
            pSize = pagesize;
        } else {
            page = nPage;
            pSize = nPageSize;
        }
        try {
            const response = await axiosPrivate.get(`project/project-list?page=${page}&size=${pSize}&sort=-_id&query_filter=start_date%3D${selectedDate_start}%26end_date%3D${selectedDate_end}%26client_id%3D${filterClint}%26project_type%3D${filterProjectType}%26project_manager%3D${filterProjectManager}%26project_status%3D${filterProjectStatus}%26search%3D${filterSearch}%26filter_by%3D${label_num}%26survey_filter_by%3D${selected_filter}`);

            setTableData(response.data.items);
            setDataLength(response.data.total);
        } catch (error) {
            setTableData([]);
            notifyerror('Server');
            console.error('Error fetching data:', error);
        }
        setLoder(true);
        setIsSubmitting(false);
        setReloadAllTable(false);
    };

    const handleFilterSearch = () => {
        setIsSubmitting(true);
        setPagesize(10);
        setNextPage(1);
        fetchFirstTime(1, 10);
    };

    const refreshFilter = () => {
        setSelectedDate_start('');
        setSelectedDate_end('');
        setFilterClint('');
        setFilterProjectType('');
        setFilterProjectManager('');
        setFilterProjectStatus('');
        setFilterSearch('');
        setPagesize(10);
        setNextPage(1);
        setShow(false);
        setValue('Search_name', '');
        setClientNameValue([]);
        setProjectTypeValue([]);
        setProjectManagerValue([]);
        setProjectStatusValue([]);
        setHrefValue('#');
        fetchResetTime();
    };

    const fetchResetTime = async (nPage) => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(`project/project-list?page=1&size=10&sort=-_id`);
            setTableData(response.data.items);
            setDataLength(response.data.total);
            setLoder(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoder(true);
        setIsSubmitting(false);
    };

    return (
        <>
            <LinksModal show={links} onHide={() => setLinks(false)} />

            {surveysuppliershow ? (
                <>
                    <Surveysupplier show={surveysuppliershow} onHide={() => setSurveySuppliershow(false)} setSurveySuppliershow={setSurveySuppliershow} selectedDate_start={selectedDate_start} selectedDate_end={selectedDate_end} clientNameValue={clientNameValue} projectTypeValue={projectTypeValue} projectManagerValue={projectManagerValue} projectStatusValue={projectStatusValue} search_name={search_name} />
                </>
            ) : (
                <></>
            )}

            <AllStatusModify project_id={project_id} show={allStatusChnages} setCallApi={setCallApi} projectStatus={projectStatus} onHide={() => setAllStatusChanges(false)} />

            <section className="content_wrapper">
                <div className="containerbox">
                    <div className="data_wrapper">
                        <div className="inner-header flex items-center justify-between">
                            <div className="inner-left flex items-center">
                                <img src={SLicon} />
                                <h3>Project Listing</h3>
                            </div>
                            <div className="inner-right flex items-center">
                                <span className="action-icons flex items-center">
                                    <a className="search-filter-toggle pointer" onClick={() => setShow(!show)} title="Filter Project">
                                        <BiSearch />
                                    </a>

                                    <a className="pointer" title="Links" onClick={() => setLinks(true)}>
                                        <BiLink />
                                    </a>
                                    <a title="Add" className="pointer">
                                        <BiPlus />
                                    </a>
                                    <a
                                        title="Docs"
                                        className="pointer"
                                        onClick={() => {
                                            setSurveySuppliershow(true);
                                        }}
                                    >
                                        <BiFileBlank />
                                    </a>
                                    <a
                                        href={hrefValue}
                                        className="pointer"
                                        title="Download"
                                        onClick={() => {
                                            if (clientNameValue.length !== 0) {
                                                const data = clientNameValue.map((prop) => prop.value);
                                                userString = `${data.join(',')}`;
                                            } else {
                                                userString = '';
                                            }
                                            if (projectTypeValue.length !== 0) {
                                                const project_type = projectTypeValue.map((prop) => prop.value);
                                                project_type_string = `${project_type.join(',')}`;
                                            } else {
                                                project_type_string = '';
                                            }
                                            if (projectManagerValue.length !== 0) {
                                                const project_manag = projectManagerValue.map((prop) => prop.value);

                                                project_manage_string = `${project_manag.join(',')}`;
                                            } else {
                                                project_manage_string = '';
                                            }
                                            if (projectStatusValue.length !== 0) {
                                                const project_manag = projectStatusValue.map((prop) => prop.value);

                                                project_status = `${project_manag.join(',')}`;
                                            } else {
                                                project_status = '';
                                            }
                                            if (selectedDate_start == '' && clientNameValue.length === 0 && projectTypeValue.length === 0 && projectManagerValue.length === 0 && projectStatusValue.length === 0) {
                                                confirmAlert({
                                                    closeOnEscape: false,
                                                    closeOnClickOutside: false,
                                                    message: `Please select one filter`,
                                                    messageStyle: {
                                                        color: '#ff0000', // Set your desired color value
                                                    },
                                                    buttons: [
                                                        {
                                                            label: 'OK',
                                                            onClick: () => setHrefValue('#'),
                                                        },
                                                    ],
                                                });
                                            } else {
                                                const newHrefValue = BASE_URL + `/project/project-supplier-list-export?query_filter=start_date%3D${selectedDate_start}%26end_date%3D${selectedDate_end}%26client_id%3D${userString}%26project_type%3D${project_type_string}%26project_manager%3D${project_manage_string}%26project_status%3D${project_status}%26search%3D${search_name}`;
                                                setHrefValue(newHrefValue);
                                            }
                                        }}
                                    >
                                        <BiDownload />
                                    </a>
                                </span>
                            </div>
                        </div>

                        {/*-------- Search Filter--------- */}
                        {show ? (
                            <form onSubmit={handleSubmit(handleFilterSearch)}>
                                <div className="search-filter csmulti">
                                    <Row className="sf mb-1">
                                        <Col lg={10} md={10} sm={10} xs={10}>
                                            <Row>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="input-row-child flex flex-direction">
                                                        <label> Date Range</label>
                                                        <DateRangePicker
                                                            initialSettings={{
                                                                autoUpdateInput: false,
                                                                locale: {
                                                                    cancelLabel: 'Clear',
                                                                    format: 'YYYY-MM-DD',
                                                                },
                                                            }}
                                                            defaultValue={null}
                                                            onApply={(event, picker) => {
                                                                setSelectedDate_start(`${picker.startDate.format('YYYY-MM-DD')}`);
                                                                setSelectedDate_end(`${picker.endDate.format('YYYY-MM-DD')}`);
                                                            }}
                                                            onCancel={(event, picker) => {
                                                                setSelectedDate_start('');
                                                                setSelectedDate_end('');
                                                            }}
                                                        >
                                                            <input type="text" className="form-control" value={`${selectedDate_start}-${selectedDate_end}`} />
                                                        </DateRangePicker>
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="flex flex-direction">
                                                        <label htmlFor="status">Client</label>

                                                        <MultiSelect
                                                            {...register('ClientName')}
                                                            placeholder="Select..."
                                                            className="pjt_select "
                                                            classNamePrefix="select"
                                                            options={clientListStorage}
                                                            value={clientNameValue}
                                                            onChange={(e) => {
                                                                setClientNameValue(e);
                                                                var initialFilterValues = e.map((item) => item.value);
                                                                initialFilterValues = initialFilterValues.join(',');
                                                                setFilterClint(initialFilterValues);
                                                            }}
                                                            labelledBy="Filter by Client"
                                                            isCreatable={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="flex flex-direction">
                                                        <label htmlFor="status">Project Type</label>

                                                        <MultiSelect
                                                            {...register('Project_Type')}
                                                            placeholder="Select..."
                                                            className="pjt_select "
                                                            classNamePrefix="select"
                                                            options={projectTypeStorage}
                                                            value={projectTypeValue}
                                                            onChange={(e) => {
                                                                setProjectTypeValue(e);

                                                                var initialFilterValues = e.map((item) => item.value);
                                                                initialFilterValues = initialFilterValues.join(',');
                                                                setFilterProjectType(initialFilterValues);
                                                            }}
                                                            labelledBy="Filter by Project Type"
                                                            isCreatable={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="flex flex-direction">
                                                        <label for="status">Project Manager</label>
                                                        <MultiSelect
                                                            {...register('Project_Manager')}
                                                            placeholder="Select..."
                                                            className="pjt_select "
                                                            classNamePrefix="select"
                                                            options={projectManagerStorage}
                                                            value={projectManagerValue}
                                                            onChange={(e) => {
                                                                setProjectManagerValue(e);
                                                                var initialFilterValues = e.map((item) => item.value);
                                                                initialFilterValues = initialFilterValues.join(',');
                                                                setFilterProjectManager(initialFilterValues);
                                                            }}
                                                            labelledBy="Filter by Project Manager"
                                                            isCreatable={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="flex flex-direction">
                                                        <label for="status">Status</label>

                                                        <MultiSelect
                                                            {...register('Status')}
                                                            placeholder="Select..."
                                                            className="pjt_select "
                                                            classNamePrefix="select"
                                                            options={projectStatusStorage}
                                                            value={projectStatusValue}
                                                            onChange={(e) => {
                                                                setProjectStatusValue(e);
                                                                var initialFilterValues = e.map((item) => item.value);
                                                                initialFilterValues = initialFilterValues.join(',');
                                                                setFilterProjectStatus(initialFilterValues);
                                                            }}
                                                            labelledBy="Filter by Status"
                                                            isCreatable={true}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={2} md={4} sm={6} xs={12}>
                                                    <div className="flex flex-direction">
                                                        <label for="status">Search</label>

                                                        <input
                                                            {...register('Search_name')}
                                                            type="search"
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                            onChange={(e) => {
                                                                setSearch_name(e.target.value);
                                                                setFilterSearch(e.target.value);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2} md={2} sm={2} xs={2} className="flex items-end">
                                            <Row className="w-100">
                                                <Col lg={8} md={8} sm={8} xs={8}>
                                                    <button className="supply_setupbtn m-0 flex pointer">
                                                        {isSubmitting && (
                                                            <Spinner
                                                                style={{
                                                                    color: 'white',
                                                                }}
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        )}
                                                        Submit
                                                    </button>
                                                </Col>
                                                <Col lg={4} md={4} sm={4} xs={4}>
                                                    <IoMdRefresh
                                                        title="Reset Form"
                                                        className="fs-30 pointer"
                                                        onClick={() => {
                                                            refreshFilter();
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={1} md={2} sm={3} xs={3}>
                                            <div className="flex flex-direction">
                                                <label htmlFor="status">Filter By</label>
                                                <BootstrapSwitchButton
                                                    className="switch btn on btn-outline-success btn-xs"
                                                    size="xs"
                                                    width={80}
                                                    checked={label_status}
                                                    onlabel="Project"
                                                    offlabel="Survey"
                                                    onstyle="outline-success"
                                                    offstyle="outline-danger"
                                                    onChange={(e) => {
                                                        setLabel_Status(e);
                                                        if (e === false) {
                                                            setLabel_num(2);
                                                        } else {
                                                            setLabel_num(1);
                                                            setSelected_filter('');
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        {label_status === false ? (
                                            <>
                                                <Col lg={3} md={3} sm={9} xs={9}>
                                                    <div className="input-row-child flex flex-direction">
                                                        <label>Surveys Filter By</label>
                                                        <Select
                                                            className="pjt_select formtext"
                                                            classNamePrefix="select"
                                                            isDisabled={false}
                                                            isRtl={false}
                                                            isSearchable={true}
                                                            name="color"
                                                            value={selecteddate_option}
                                                            options={dateRange_list}
                                                            onChange={(e) => {
                                                                setSelectedDate_Option(e);

                                                                setSelected_filter(e.value);
                                                            }}
                                                            noOptionsMessage={() => 'No Data Found'}
                                                        />
                                                    </div>
                                                </Col>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </div>
                            </form>
                        ) : null}

                        {/*-------- Table--------- */}
                        {loder ? (
                            <>
                                <div className="overflow-x">
                                    <table>
                                        <tbody>
                                            <tr className="table-heading">
                                                <th>
                                                    <span title="Action">Act</span>
                                                </th>
                                                <th>
                                                    <span title="Survey Status">Status</span>
                                                </th>
                                                <th>
                                                    <span title="Project Name">Project</span>
                                                </th>
                                                <th>
                                                    <span title="Project Number">WS#</span>
                                                </th>
                                                <th>
                                                    <span title="Client Project Number/Client Code">CN#/CC</span>
                                                </th>
                                                <th>
                                                    <span title="Country">Ctry</span>
                                                </th>
                                                <th>
                                                    <span title="Survey Id">SC#</span>
                                                </th>
                                                <th>
                                                    <span>CPI</span>
                                                </th>
                                                <th>
                                                    <span title="Complete/Quota">CO/Quota</span>
                                                </th>
                                                <th>
                                                    <span title="Current IR">IR%</span>
                                                </th>
                                                <th className="w-set">
                                                    <span title="Conversion">CO%</span>
                                                </th>
                                                <th className="w-set">
                                                    <span title="Conversion in last 24 hour">CO24%</span>
                                                </th>
                                                <th>
                                                    <span title="Completes in last 24 hours">LC24</span>
                                                </th>
                                                <th>
                                                    <span title="Terminates in last 24 hours">LT24</span>
                                                </th>
                                                <th>
                                                    <span title="Quotafull in last 24 hours">LQ24</span>
                                                </th>
                                                <th>
                                                    <span title="Last Update Date">LU Date</span>
                                                </th>
                                                <th>
                                                    <span title="Project Manager/Sales Lead">PM/SL</span>
                                                </th>
                                                <th>
                                                    <span title="Margin">MR</span>
                                                </th>
                                                <th>
                                                    <span title="Unbilled Revenue">UR</span>
                                                </th>
                                                <th>
                                                    <span title="Billed Revenue">BR</span>
                                                </th>
                                            </tr>

                                            {tableData.length == 0 ? (
                                                <>
                                                    <td colspan="20">
                                                        <div
                                                            className="alert alert-danger"
                                                            style={{
                                                                textAlign: 'center',
                                                                marginBottom: '0px',
                                                            }}
                                                            role="alert"
                                                        >
                                                            No Project Found!
                                                        </div>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    {tableData.map((items, index) => (
                                                        <>
                                                            <tr onClick={(e) => handleRow(`Row${index}`, items._id)} id={`Row${index}`} className="mainrow pointer">
                                                                <td>
                                                                    <BiEditAlt
                                                                        title="Edit Project"
                                                                        className="fs pointer"
                                                                        onClick={() => {
                                                                            navigate(`/project-detail/${items._id}/1/1`);
                                                                        }}
                                                                    />
                                                                    &nbsp;
                                                                    <span
                                                                        title="Project Tracker
"
                                                                    >
                                                                        <AutorenewIcon
                                                                            title="Survey TRacker"
                                                                            sx={{
                                                                                fontSize: '15px',
                                                                            }}
                                                                            onClick={() => {
                                                                                setAllStatusChanges(true);
                                                                                setProject_id(items._id);
                                                                                setProjectStatus(items.project_status);
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </td>

                                                                {tableData ? (
                                                                    <>
                                                                        <td>
                                                                            {items.project_status === 'Live' ? (
                                                                                <>
                                                                                    <span className="live">Live</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Pending' ? (
                                                                                <>
                                                                                    <span className="pending">Pending</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Test Mode' ? (
                                                                                <>
                                                                                    <span className="testmode">Test Mode</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Paused' ? (
                                                                                <>
                                                                                    <span className="Paused">Paused</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Id Awaited' ? (
                                                                                <>
                                                                                    <span className="IdAwaited">Id Awaited</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Billed' ? (
                                                                                <>
                                                                                    <span className="billed">Billed</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            {items.project_status === 'Cancelled' ? (
                                                                                <>
                                                                                    <span className="cancelled">Cancelled</span>
                                                                                </>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </td>
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                                <td className="PN_dimension content_ellipsis" title={items.project_name}>
                                                                    {items.project_name}
                                                                </td>
                                                                <td>{items.pro_sc}</td>
                                                                <td>{items.cn_cc}</td>
                                                                <td>{items.project_country}</td>
                                                                <td>{items.pro_ws}</td>
                                                                <td>{items.pro_cpi}</td>
                                                                <td>{items.comp_quota}</td>
                                                                <td>{items.ir_percent}</td>
                                                                <td>{items.co_percent}</td>
                                                                <td>{items.co_24_percent}</td>
                                                                <td>{items.lc24}</td>
                                                                <td>{items.lt24}</td>
                                                                <td>{items.lq24}</td>
                                                                <td>{items.LUdate}</td>
                                                                <td>{items.pm_sl}</td>
                                                                <td>{items.mr_percent}</td>
                                                                <td>{items.unbilled_revenue}</td>
                                                                <td>{items.billed_revenue}</td>
                                                            </tr>
                                                            <tr className={`inner-border Row${index}`}>
                                                                <td colspan="20" className="indent">
                                                                    {items._id == projId ? (
                                                                        <>
                                                                            <SurveyInnerTable projId={projId} selectedDate_start={selectedDate_start} selectedDate_end={selectedDate_end} clientNameValue={clientNameValue} projectTypeValue={projectTypeValue} projectManagerValue={projectManagerValue} projectStatusValue={projectStatusValue} search_name={search_name} label_num={label_num} selected_filter={selected_filter} setReloadAllTable={setReloadAllTable} />
                                                                        </>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (
                            <SpinnerLoader />
                        )}

                        <Paginations userlist={dataLength} getPageSize={getPageSize} handlePageChnage={handlePageChnage} page={nextPage} pagesize={pagesize} reloadPagination={reloadPagination} setReloadPagination={setReloadPagination} />
                    </div>
                </div>
            </section>
        </>
    );
}

export default SurveyListing;
