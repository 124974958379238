import Supplyheader from "./Supplyheader";
import "./Supplypage.css";
import axios from "axios";
import { useState, useEffect } from "react";
import Paginations from "../Paginations/Paginations";
import Supplierpaginations from "../Paginations/Supplierpagination";
import Table from "react-bootstrap/Table";
import pageIcon from "../../assets/BidsIcons/bids.svg";
import { useNavigate } from "react-router";
import EditIcon from "../../assets/LoginIcons/edit.svg";
import DeleteIcon from "../../assets/LoginIcons/delete.svg";
import plusIcon from "../../assets/CSicons/icons/add.svg";
import { FaTelegramPlane } from "react-icons/fa";
import AddManualSupplier from "./Popup/AddManualSupplier";
import Editsupplierquality from "./Editsupplierquality";
import RequestStatus from "./Popup/RequestStatus";
import SupplierInfo from "./Popup/SupplierInfo";
import { AiOutlineSetting } from "react-icons/ai";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SpinnerLoader from "../UI/Spinner";
import ExcelGrid from "./Popup/ExcelGrid";
import { confirmAlert } from "react-confirm-alert";
import { FiFilter } from "react-icons/fi";
import toast from "react-hot-toast";
import { Col, Row } from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TableSortLabel } from "@mui/material";
import { TableCell, TableRow, TableBody } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";
import {
  AiOutlineDollarCircle,
  AiOutlineDollar,
  AiFillDollarCircle,
} from "react-icons/ai";
import Pricetype from "./Popup/Pricetype";
const Supplierdetail = () => {
  const axiosPrivate = useAxiosPrivate();
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [add, setAdd] = useState(false);
  const [filter_status, setFilter_status] = useState(false);
  const [see, setSee] = useState(false);
  const [setting, setSetting] = useState(false);
  const [show, setShow] = useState(false);
  const [tabledata, setTableData] = useState([]);
  const [dataLength, setDataLength] = useState();
  const [dataLoader, setDataLoader] = useState(true);
  const [excelshow, setExcelShow] = useState(false);
  const [pricetype, setPriceType] = useState(false);
  const [supplierID, setSupplierID] = useState();
  const [loder, setLoder] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apifilter, setApifilter] = useState("");
  const [publishfilter, setPublishfilter] = useState("");
  const [supplierfilter, setSupplierFilter] = useState("");
  const [reverSign, setReverSign] = useState(0);
  const [supplier_Type, setSupplier_type] = useState([]);
  const [selected_supplier, setSelected_Supplier] = useState([]);
  const pname = "SupplierDetails";

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const headers = [
    {
      id: "supplier_abbr",
      label: "Supplier Abbr",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "phone",
      label: "Phone",
    },
    {
      id: "address.country.name",
      label: "Country",
    },
    {
      id: "status",
      label: "Status",
    },

    {
      id: "Action",
      label: "Action",
    },
  ];
  const userDeleted = () => toast.success("Supplier deleted successfully");
  useEffect(() => {
    if (filter_status === false) {
      get_ProjectType_api("_id");
    } else {
      get_ProjectType_api_filter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagesize, nextPage]);

  useEffect(() => {
    get_ProjectType_api("_id");
    get_supplierApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPageSize = (size) => {
    setPagesize(size);
    setNextPage(1);
  };
  const get_supplierApi = async () => {
    try {
      const response = await axiosPrivate.get("lookup/supplier-type");
      setSupplier_type([]);
      response.data.all_status.map((e) => {
        setSupplier_type((val) => [...val, { label: e.name, value: e.id }]);
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  const get_ProjectType_api = async (sorttype) => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=${sorttype}`
      );

      setTableData(response.data.items);

      setDataLength(response.data.total);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };
  const get_ProjectType_api_filter = async (e) => {
    let supplier_id;
    if (selected_supplier.length !== 0) {
      supplier_id = selected_supplier.value;
    } else {
      supplier_id = "";
    }

    setLoder(false);
    setIsSubmitting(true);
    if (supplierfilter !== "" || selected_supplier.length !== 0) {
      try {
        const response = await axiosPrivate.get(
          `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=_id&query_filter=name%3D${supplierfilter}%26api%3D${apifilter}%26supplier_type%3D${supplier_id}`
        );

        setNextPage(nextPage);
        setPagesize(pagesize);
        setTableData(response.data.items);

        setDataLength(response.data.total);
      } catch (err) {
        console.error(err);
      }
    }

    setLoder(true);
    setIsSubmitting(false);
  };
  const refreshfilter = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=_id`
      );

      setTableData(response.data.items);
      setFilter_status(false);
      setDataLength(response.data.total);
      setSelected_Supplier([]);
    } catch (err) {
      console.error(err);
    }
    setLoder(true);
  };
  const handleDeleted = async (id) => {
    const payload = {
      supplier_id: id,
    };

    try {
      await axiosPrivate.delete(
        "/supplyinfo/delete-supplier",
        { data: payload },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      userDeleted();
      get_ProjectType_api();
    } catch (err) {}
    // const del = User.filter((User) => id !== User._id);
    // setUser(del);
  };
  const handleSort = async (sorttype) => {
    setReverSign(reverSign + 1);
    if (reverSign === 0) {
      try {
        const response = await axiosPrivate.get(
          `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=${sorttype}`,
          {}
        );

        setTableData(response.data.items);

        setDataLength(response.data.total);

        setReverSign(1);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const response = await axiosPrivate.get(
          `supplyinfo/supplier-list?&page=${nextPage}&size=${pagesize}&sort=-${sorttype}`,
          {}
        );

        setTableData(response.data.items);

        setDataLength(response.data.total);
        setReverSign(0);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const supplier_name = register("Survey_name", {});
  return (
    <>
      {setting ? (
        <>
          <RequestStatus show={setting} onHide={() => setSetting(false)} />
        </>
      ) : (
        <></>
      )}

      {add ? (
        <>
          <AddManualSupplier show={add} onHide={() => setAdd(false)} />
        </>
      ) : (
        <></>
      )}

      {see ? (
        <>
          <SupplierInfo show={see} onHide={() => setSee(false)} />
        </>
      ) : (
        <></>
      )}

      {/* {(<></>):(<></>)} */}
      <Supplyheader pname={pname} />
      <div className="top_container">
        <div className="main_container ">
          <div className="user_serachboxSupply">
            <div style={{ float: "left" }}>
              <h3 className="tableHeading">
                <img src={pageIcon} className="headingIcons" alt="" /> Suppliers
              </h3>
            </div>
            <div className="supplyInputBox" style={{}}>
              <FiFilter
                title="Filter"
                className="supplyIconColr"
                style={{
                  float: "right",

                  fontSize: "30px",
                  padding: "2px 5px",
                  color: "white",
                  backgroundColor: "#1a1a1a",
                  border: "1px solid black",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShow(!show);
                }}
              />
              <img
                src={plusIcon}
                alt="plusIcon"
                title="Add Supplier"
                onClick={() => {
                  navigate("/supplier");
                }}
                className="supplyIconColr"
                style={{
                  float: "right",
                  marginRight: "3px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <hr />
          {show ? (
            <form onSubmit={handleSubmit(get_ProjectType_api_filter)}>
              <Row
                className="supply_filterbox"
                style={{
                  backgroundColor: "#f1efef",
                  // margin: "15px",
                  paddingBottom: "10px",
                }}
              >
                <Col lg={10} md={9} xs={11} className="supply_col">
                  <Row>
                    <Col lg={6} md={6} xs={4} className="col">
                      <label
                        for="status"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Supplier Name
                      </label>
                      <input
                        className="formtext"
                        {...register("Survey_name", {})}
                        placeholder="Supplier Name"
                        onChange={(e) => {
                          supplier_name.onChange(e);
                          setSupplierFilter(e.target.value);
                          setValue("Survey_name", e.target.value);
                        }}
                      />
                      {errors.Survey_name && (
                        <small className="errmsg">
                          {errors.Survey_name.message}
                        </small>
                      )}
                    </Col>
                    <Col lg={6} md={6} xs={4} className="col">
                      <label className="formlabelviewzone">Supplier Type</label>
                      <Select
                        className="pjt_select formtext"
                        classNamePrefix="select"
                        isDisabled={false}
                        isLoading={false}
                        isRtl={false}
                        isSearchable={true}
                        name="color"
                        value={selected_supplier}
                        options={supplier_Type}
                        onChange={(e) => {
                          setSelected_Supplier(e);
                        }}
                        noOptionsMessage={() => "No Data Found"}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={2}
                  md={3}
                  xs={12}
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <button
                    className="cbtn saveBtn"
                    style={{
                      maxWidth: "100%",
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      setFilter_status(true);
                      setNextPage(1);
                      setPagesize(10);
                    }}
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{ color: "white" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp; Submit
                  </button>
                  <BiReset
                    title="Reset"
                    size={30}
                    style={{
                      marginTop: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShow(!show);
                      refreshfilter();
                      setApifilter("");
                      setPublishfilter("");
                      setSupplierFilter("");
                      setValue("Survey_name", "");
                      setSelected_Supplier([]);
                      setFilter_status(false);
                    }}
                  />
                </Col>
              </Row>
            </form>
          ) : null}
          <div className="mainContent supplymaincontent">
            {loder ? (
              <>
                <div className="table_container">
                  <Table
                    bordered={false}
                    hover
                    size="sm"
                    className="customTable "
                  >
                    <thead className="thead">
                      <TableRow>
                        {headers.map((header) => {
                          return (
                            <TableCell key={header.id}>
                              {reverSign === 0 ? (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropUpIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <TableSortLabel
                                    active={false}
                                    direction={"asc"}
                                    onClick={() => {
                                      handleSort(header.id);
                                    }}
                                    IconComponent={ArrowDropDownIcon}
                                  >
                                    {header.label}
                                  </TableSortLabel>
                                </>
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </thead>

                    <tbody>
                      {tabledata.length === 0 ? (
                        <tr>No data Found</tr>
                      ) : (
                        tabledata.map((items) => (
                          <>
                            <tr>
                              {items.supplier_abbr === undefined ||
                              items.supplier_abbr === null ? (
                                <>
                                  <td>-</td>
                                </>
                              ) : (
                                <>
                                  <td>{items.supplier_abbr} </td>
                                </>
                              )}

                              {items.name === undefined ||
                              items.name === null ? (
                                <>
                                  <td>-</td>
                                </>
                              ) : (
                                <>
                                  <td> {items.name}</td>
                                </>
                              )}
                              {items.email === undefined ||
                              items.email === null ? (
                                <>
                                  <td>-</td>
                                </>
                              ) : (
                                <>
                                  <td>{items.email}</td>
                                </>
                              )}
                              {items.phone === undefined ||
                              items.phone === null ? (
                                <>
                                  <td>-</td>
                                </>
                              ) : (
                                <>
                                  <td>{items.phone}</td>
                                </>
                              )}

                              {/* {items.address === undefined ? (
                            <>
                              <td>NA</td>
                            </>
                          ) : (
                            <>{<td> {items.address.billing_address} </td>}</>
                          )} */}

                              {items.address.country.name === null ? (
                                <>
                                  <td>NA</td>
                                </>
                              ) : (
                                <>
                                  {
                                    <td className="country_col">
                                      {" "}
                                      {items.address.country.name}{" "}
                                    </td>
                                  }
                                </>
                              )}

                              {/* <td>KR</td> */}
                              {items.status == 1 ? (
                                <>
                                  <td className="supplyinfo_text">
                                    <span className="supply_active-badge">
                                      Active
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td className="supplyinfo_text ">
                                    <span className="supply_inactive-badge">
                                      In-Active
                                    </span>
                                  </td>
                                </>
                              )}
                              <td className="sd_action">
                                <AiFillDollarCircle
                                  size={17}
                                  className="SupIcon sdIcon"
                                  title="Price type"
                                  onClick={() => {
                                    setPriceType(true);
                                    // setSupplierID(items._id);
                                    navigate(`/priceratecard/${items._id}`);
                                  }}
                                />
                                <img
                                  src={EditIcon}
                                  alt="EditIcon"
                                  title="Edit"
                                  className="SupIcon sdIcon"
                                  onClick={() => {
                                    navigate(`/edit_supplier/${items._id}`);
                                  }}
                                />
                                <FaTelegramPlane
                                  className="SupIcon sdIcon"
                                  onClick={() => {
                                    setExcelShow(true);
                                  }}
                                />
                                <img
                                  src={DeleteIcon}
                                  alt="DeleteIcon"
                                  title="Delete"
                                  className="SupIcon sdIcon"
                                  onClick={() => {
                                    confirmAlert({
                                      message:
                                        "Do you want to delete " + items.name,
                                      buttons: [
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            handleDeleted(items._id),
                                        },
                                        {
                                          label: "No",
                                        },
                                      ],
                                    });
                                  }}
                                />
                                <AiOutlineSetting
                                  className="SupIcon sdIcon"
                                  title="Setting"
                                  size={17}
                                  onClick={() => {
                                    navigate(`/exclusion-setting/${items._id}`);
                                  }}
                                />
                              </td>
                            </tr>
                          </>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
                <Supplierpaginations
                  userlist={dataLength}
                  getPageSize={getPageSize}
                  handlePageChnage={handlePageChnage}
                  page={nextPage}
                  pagesize={pagesize}
                />
              </>
            ) : (
              <SpinnerLoader />
            )}

            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Supplierdetail;
