import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import useAxiosPrivate from "./../../../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../../../UI/Spinner";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
// import Multiselect from "../../../../Multiselect/Multiselect";
import { MultiSelect } from "react-multi-select-component";
import { components } from "react-select";
import Spinner from "react-bootstrap/Spinner";
import toast from "react-hot-toast";
const Addmodalselect = (props) => {
  const axiosPrivate = useAxiosPrivate();
  const [spinner, setSpinner] = useState(false);
  const notifySuccess = (e) =>
    toast.success(`Qualification added successfully`);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    setError,
    trigger,
  } = useForm();
  const [answersPer, setAnswersPer] = useState([]);

  const [totalPercentage, setTotalPercentage] = useState(0);
  const [all_data, setAll_data] = useState([]);
  const [data, setData] = useState([]);
  const [qualification, setQualification] = useState([]);
  const [questions, setQuestion] = useState([]);
  const [questionsExisting, setQuestionExisting] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filteredAnswer, setFilteredAnswers] = useState([]);
  const [loder, setLoder] = useState(false);
  const [selectedQuest, setSelectedQuest] = useState([]);
  const [selectedQuestexist, setSelectedQuestexist] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [question_error, setQuestion_Error] = useState(false);
  const [answer_error, setAnswer_Error] = useState(false);

  useEffect(() => {
    if (props.selected_Quali_id !== "" && props.survey_ID !== undefined) {
      getData();
    } else if (
      props.selected_Quali_id === "" &&
      props.survey_ID !== undefined
    ) {
      getQuestionList();
    }
  }, [props.selected_Quali_id, props.survey_ID]);

  const fetchAnswers = async (questionId, ansCode) => {
    setLoder(false);

    try {
      // Make a GET request using Axios with headers
      const response = await axiosPrivate.get(
        `qualification/get-answer-list/${props.survey_ID}/${questionId}`
      );

      const allAnswers = response.data.answer.map((answer) => ({
        label: answer.answer_title.en_title,
        value: answer.ans_code,
        ans_code: answer.ans_code,
        questionId: answer.q_id,
      }));

      if (ansCode !== undefined) {
        //   Filtered answers based on ansCode
        const filteredAnswers = response.data.answer
          .filter((answer) => ansCode.includes(answer.ans_code))
          .map((answer) => ({
            label: answer.answer_title.en_title,
            value: answer.ans_code,
            ans_code: answer.ans_code,
            questionId: answer.q_id,
          }));
        setFilteredAnswers(filteredAnswers);
      } else {
        setFilteredAnswers([]);
      }

      setAnswers(allAnswers);
    } catch (error) {
      console.error("Error fetching answers:", error);
    }
    setLoder(true);
  };
  const getQuestionList = async (id, ansCode) => {
    try {
      const response = await axiosPrivate.get(
        `qualification/get-question-list/${props.survey_ID}`
      );

      setQuestion([]);
      const allQuestion = response.data.question
        .filter((question) => !props.excludedIds.includes(question.q_code))
        .map((question) => ({
          label: question.q_name,
          value: question.q_code,
        }));

      setQuestion((prevData) => [...prevData, ...allQuestion]);

      const el = allQuestion.filter((item) => id === item.value);
      setSelectedQuest(el);
      await fetchAnswers(id, ansCode);
    } catch (error) {
      console.error("Error fetching question:", error);
      return null;
    }
  };

  const SubmitAdddefination = (data) => {
    const questionId = filteredAnswer[0]?.questionId;

    if (questionId) {
      if (!props.excludedIds.includes(questionId)) {
        props.setExcludedIds((prevIds) => [...prevIds, questionId]);
      }
      setQuestion_Error(false);

      if (filteredAnswer.length === 0) {
        setAnswer_Error(true);
      } else {
        setAnswer_Error(false);
      }
    } else {
      setQuestion_Error(true);
      setAnswer_Error(true);
    }
    if (props.selected_Quali_id !== "" && filteredAnswer.length !== 0) {
      setSpinner(true);

      const url = "qualification/update-qualification";

      const requestBody = {
        qualify_id: props.selected_Quali_id,
        q_id: questionId,
        ans_code: filteredAnswer.map((item) => item.ans_code),
        is_allocated: false,
        is_all_selected: false,
        order_by: 4,
      };

      axiosPrivate
        .patch(url, requestBody)
        .then((response) => {
          if (response.status === 200) {
            props.setAll_data((prevData) => {
              return prevData.map((item) => {
                if (item.qualification_id === props.selected_Quali_id) {
                  return { ...item, filteredAnswers: filteredAnswer };
                }
                return item;
              });
            });
            notifySuccess();
            props.setIsOpen(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setSpinner(false); // Setting spinner to false after the call completes
        });
    } else if (props.selected_Quali_id === "" && filteredAnswer.length !== 0) {
      setSpinner(true);

      const url = "qualification/add-general-qualification";

      // Define the request body
      const requestBody = {
        survey_id: props.survey_ID,
        q_id: filteredAnswer[0]?.questionId,
        ans_code: filteredAnswer.map((item) => item.ans_code),
        is_allocated: false,
        is_all_selected:
          answers.length === filteredAnswer.length ? true : false,
        order_by: 4,
      };

      // Make the POST request using Axios
      axiosPrivate
        .post(url, requestBody)
        .then((response) => {
          if (response.status === 200) {
            // props.setApiCall(true);

            // props.showAddquotapopup(false);
            const newData = {
              qualification_id: response.data.qualification._id,
              is_default_qualify:
                response.data.qualification.is_default_qualify,
              is_all_selected: response.data.qualification.is_all_selected,
              is_allocated: response.data.qualification.is_allocated,
              order_by: response.data.qualification.order_by,
              q_id: response.data.qualification.q_id,
              q_name: selectedQuest.label,
              filteredAnswers: filteredAnswer,
              allAnswers: answers,
            };
            props.setAll_data((prevData) => [...prevData, newData]);
            props.setExcludedIds((prevIds) => [
              ...prevIds,
              response.data.qualification.q_id,
            ]);

            const defaultQualifyCount = props.allData.filter(
              (item) => item.is_default_qualify === false
            ).length;

            if (defaultQualifyCount > props.emptyCount) {
              props.setEmptycount(1);
            } else {
              props.setEmptycount(props.emptyCount - 1);
            }

            props.setIsOpen(false);
          }
          // Handle successful response
        })
        .catch((error) => {
          // Handle error
          console.error("Error:", error);
        })
        .finally(() => {
          setSpinner(false); // Setting spinner to false after the call completes
          notifySuccess();
        });
    }
  };

  const getQuestionListExisting = async (id, ansCode) => {
    try {
      const response = await axiosPrivate.get(
        `qualification/get-question-list/${props.survey_ID}`
      );

      setQuestionExisting([]);
      const allQuestion = response.data.question
        .filter(
          (question) =>
            !props.excludedIds.includes(question.q_code) ||
            question.q_code === id
        )
        .map((question) => ({
          label: question.q_name,
          value: question.q_code,
        }));

      setQuestionExisting((prevData) => [...prevData, ...allQuestion]);

      const el = allQuestion.filter((item) => id === item.value);
      setSelectedQuestexist(el);
      await fetchAnswers(id, ansCode);
    } catch (error) {
      console.error("Error fetching question:", error);
      return null;
    }
  };

  const getData = async () => {
    setLoder(false);

    try {
      const response = await axiosPrivate.get(
        `qualification/get-general-qualification/${props.selected_Quali_id}`
      );

      if (response.status === 200) {
        setData(response.data.qualification.allocated_ans_code);

        const q_name = await getQuestionListExisting(
          response.data.qualification.q_id,
          response.data.qualification.ans_code
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoder(true);
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="fs16">
            Question Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 scrollYRemove">
          {loder ? (
            <>
              <form onSubmit={handleSubmit(SubmitAdddefination)}>
                {props.selected_Quali_id ? (
                  <>
                    <Row>
                      <Col lg={6} md={6} sm={6} xs={12} className="col">
                        <Select
                          className="pjt_select formtext"
                          classNamePrefix="sel"
                          isDisabled={true}
                          // isLoading={dropdownloader}
                          isRtl={false}
                          placeholder="bscjksdb"
                          isSearchable={true}
                          name="color"
                          value={selectedQuestexist}
                          options={questions}
                          onChange={(e) => {}}
                          noOptionsMessage={() => "No Data Found"}
                        />
                      </Col>

                      <Col lg={6} md={6} sm={6} xs={12} className="col">
                        <MultiSelect
                          className="multiselectoption h-28 fs14 targetDropdownHeight"
                          style={{ width: "29px" }}
                          options={answers}
                          value={filteredAnswer}
                          onChange={(e) => {
                            setFilteredAnswers(e);
                            setAnswer_Error(false);
                          }}
                          isCreatable={true}
                          overrideStrings={{
                            selectSomeItems: "Select Answers...",
                          }}
                        />
                        {answer_error === true ? (
                          <>
                            <small
                              className="topzero supply_errormsg m-0"
                              id="topzero"
                            >
                              Please select atleast one answer.
                            </small>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="row-gap">
                      <Col lg={6} md={6} sm={6} xs={12} className="col">
                        <Select
                          className="pjt_select formtext"
                          classNamePrefix="select"
                          placeholder="Select Question..."
                          isDisabled={false}
                          // isLoading={dropdownloader}
                          isRtl={false}
                          isSearchable={true}
                          name="color"
                          value={selectedQuest}
                          options={questions}
                          onChange={(e) => {
                            setSelectedQuest(e);
                            fetchAnswers(e.value);
                            setIsDisabled(false);
                            setQuestion_Error(false);
                          }}
                          noOptionsMessage={() => "No Data Found"}
                        />
                        {question_error === true ? (
                          <>
                            <small
                              className="topzero supply_errormsg m-0"
                              id="topzero"
                            >
                              &nbsp; &nbsp; PLease select a question
                            </small>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col lg={6} md={6} sm={6} xs={12} className="col">
                        <MultiSelect
                          className="multiselectoption h-28 fs14 targetDropdownHeight"
                          placeholder="Select Answers"
                          style={{ width: "29px" }}
                          options={answers}
                          value={filteredAnswer}
                          onChange={(e) => {
                            setFilteredAnswers(e);
                            setAnswer_Error(false);
                          }}
                          labelledBy="Select Answers"
                          overrideStrings={{
                            selectSomeItems: "Select Answers...",
                          }}
                          isCreatable={true}
                          disabled={isDisabled}
                        />
                        {answer_error === true ? (
                          <>
                            <small
                              className="topzero supply_errormsg m-0"
                              id="topzero"
                            >
                              Please select atleast one answer.
                            </small>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
                <Modal.Footer className="mt-3 pe-0">
                  <button className="supply_setupbtn m-0" type="submit">
                    {spinner === true ? (
                      <Spinner
                        as="span"
                        style={{ color: "white" }}
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <></>
                    )}
                    &nbsp; Submit
                  </button>
                  <button
                    className="supply_setupbtn m-0 ms-2 btn-bg-grey"
                    onClick={(e) => {
                      e.preventDefault();
                      props.setIsOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                </Modal.Footer>
              </form>
            </>
          ) : (
            <SpinnerLoader />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Addmodalselect;
