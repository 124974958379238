import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import Supplybuildurl from './Supplybuildurl';
import Quotafullbuildurl from './Quotafullbuildurl';
import Terminationbuildurl from './Terminationbuildurl';
import { useForm } from 'react-hook-form';
import '../PopUp/SetupSupplier.css';
import SpinnerLoader from '../../../UI/Spinner';
import Spinner from 'react-bootstrap/Spinner';
import Editsupplybuildurl from './Editsupplybuildurl';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Terminationbuildurledit from './Terminationbuildurledit ';
import SupplyBuildUrl from './Supplybuildurl';
function Setupsupplierdiy(props) {
    const id = props.childId;
    const axiosPrivate = useAxiosPrivate();
    const [showlink, setShowlink] = useState(false);
    const [userStatus, setUserStatus] = useState([
        { id: 1, name: 'Active' },
        { id: 0, name: 'InActive' },
    ]);
    const [userStatusid, setUserStatusid] = useState();
    const [buildurl, setBuildUrl] = useState(false);
    const [tbuildurl, setTBuildUrl] = useState(false);
    const [qbuildurl, setQBuildUrl] = useState(false);
    const [terminationlink, setTerminationlink] = useState('');
    const [quotafulllink, setQuotafulllink] = useState('');
    const [complitionlink, setComplitionlink] = useState('');
    const [securityurl, setSecurityUrl] = useState(false);
    const [loder, setLoder] = useState(false);
    const [screenCaptcha, setScreenCaptcha] = useState();
    const [redHiring, setRedhiring] = useState();
    const [resercchDefence, setReserchDefence] = useState();

    const [buildurledit, setBuildUrledit] = useState(false);
    const [screen, setScreen] = useState();
    const [country_check, setCountryCheck] = useState('');
    const [proxyCheck, setProxyCheck] = useState('');
    const [duplicateIp, setDuplicateIp] = useState('');
    const [qualificationScreen, setQualificationScreen] = useState('');
    const [speederTerminate, setSpeederTerminate] = useState('');
    const [compbuildurledit, setCompBuildUrledit] = useState(false);
    const [tbuildurledit, setTBuildUrledit] = useState(false);
    const [tbuilurl, setBuildurl] = useState(false);
    const [qbuildurledit, setQBuildUrledit] = useState(false);

    const [securityedit, setSecurityUrledit] = useState(false);
    const [complitionlinkedit, setComplitionlinkedit] = useState('');
    const [terminationlinkedit, setTerminationlinkedit] = useState('');
    const [quotafulllinkedit, setQuotafulllinkedit] = useState('');
    const [securityfulllinkedit, setSecurityfulllinkedit] = useState('');
    const [spinner, setSpinner] = useState(false);
    const notifyerror = (e) => toast.error(`${e}`);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm();

    useEffect(() => {
        if (id == undefined) {
        } else {
            getSurveySupplier();
        }

        if (props.show == false) {
            setShowlink(false);
            setValue('ExSecurityLink', '');
            setValue('Exquotalink', '');
            setValue('Extermlink', '');
            setValue('Excomplink', '');
        }
    }, [props.show]);

    const handleSaveButton = async (data) => {
        setSpinner(true);
        if (showlink) {
            try {
                await axiosPrivate
                    .put(
                        `survey-supplier/setup-survey-supplier?survey_supplier_id=${id}`,
                        JSON.stringify({
                            status: data.Status,
                            supplier_cost: data.SupplierCost,
                            n: data.Quota,
                            screening: {
                                pre_screening: screen,
                                qualification_screening: qualificationScreen,
                                red_herring_screen: redHiring,
                            },
                            security_check: {
                                screen_captcha: screenCaptcha,
                                research_defender: resercchDefence,
                                red_herring_screen: redHiring,
                                speeder_terminate: speederTerminate,
                                country_check: country_check,
                                proxy_check: proxyCheck,
                                duplicate_ip: duplicateIp,
                            },
                            has_exceptional_endlinks: showlink,
                            link: {
                                supplier_link: {
                                    complete: data.complink,
                                    terms: data.termlink,
                                    overquotas: data.quotalink,
                                    security_terms: data.SecurityLink,
                                },
                                supplier_exceptional_link: {
                                    complete: data.Excomplink,
                                    terms: data.Extermlink,
                                    overquotas: data.Exquotalink,
                                    security_terms: data.ExSecurityLink,
                                },
                            },
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    .then((data) => {
                        if (data.status === 200) {
                            toast.success('Supplier updated successfully');
                            setSpinner(false);
                            props.setShowAddSetup(false);

                            props.setDiy_supplier(true);
                        }
                    });
            } catch (err) {
                notifyerror(err.response.statusText);
            }
            setSpinner(false);
        } else {
            try {
                await axiosPrivate
                    .put(
                        `survey-supplier/setup-survey-supplier?survey_supplier_id=${id}`,
                        JSON.stringify({
                            status: data.Status,
                            supplier_cost: data.SupplierCost,
                            n: data.Quota,
                            screening: {
                                pre_screening: screen,
                                qualification_screening: qualificationScreen,
                                red_herring_screen: redHiring,
                            },
                            security_check: {
                                screen_captcha: screenCaptcha,
                                research_defender: resercchDefence,
                                red_herring_screen: redHiring,
                                speeder_terminate: speederTerminate,
                                country_check: country_check,
                                proxy_check: proxyCheck,
                                duplicate_ip: duplicateIp,
                            },
                            has_exceptional_endlinks: showlink,
                            link: {
                                supplier_link: {
                                    complete: data.complink,
                                    terms: data.termlink,
                                    overquotas: data.quotalink,
                                    security_terms: data.SecurityLink,
                                },
                                supplier_exceptional_link: {
                                    complete: data.Excomplink,
                                    terms: data.Extermlink,
                                    overquotas: data.Exquotalink,
                                    security_terms: data.ExSecurityLink,
                                },
                            },
                        }),
                        {
                            headers: { 'Content-Type': 'application/json' },
                        }
                    )
                    .then((data) => {
                        if (data.status === 200) {
                            toast.success('Supplier updated successfully');
                            setSpinner(false);
                            props.setShowAddSetup(false);

                            props.setDiy_supplier(true);
                        }
                    });
            } catch (err) {
                notifyerror(err.response.statusText);
            }
            setSpinner(false);
        }
        setSpinner(false);
    };

    useEffect(() => {
        if (id == undefined) {
        } else {
            getSurveySupplier();
        }
    }, [id]);

    const getSurveySupplier = async () => {
        setLoder(true);
        try {
            const response = await axiosPrivate.get(`survey-supplier/get-setup-survey-supplier?survey_supplier_id=${id}`);

            setUserStatusid(response.data.survey_supplier.status.toString());
            setScreen(response.data.survey_supplier.screening.pre_screening);
            setQualificationScreen(response.data.survey_supplier.screening.qualification_screening);
            setProxyCheck(response.data.survey_supplier.security_check.proxy_check);
            setRedhiring(response.data.survey_supplier.screening.red_herring_screen);
            setDuplicateIp(response.data.survey_supplier.security_check.duplicate_ip);
            setCountryCheck(response.data.survey_supplier.security_check.country_check);

            setSpeederTerminate(response.data.survey_supplier.security_check.speeder_terminate);
            setScreenCaptcha(response.data.survey_supplier.security_check.screen_captcha);
            setReserchDefence(response.data.survey_supplier.security_check.research_defender);
            setValue('Status', response.data.survey_supplier.status.toString());
            setValue('Quota', response.data.survey_supplier.n);
            setValue('SupplierCost', response.data.survey_supplier.supplier_cost);
            setValue('complink', response.data.survey_supplier.link.complete);
            setValue('termlink', response.data.survey_supplier.link.terminate);
            setValue('SecurityLink', response.data.survey_supplier.link.security_terminate);
            setValue('quotalink', response.data.survey_supplier.link.overquotas);
        } catch (err) {
            console.error(err);
        }
        setLoder(false);
    };

    const handleClose = () => {
        props.setDiy_supplier(true);
    };

    const statusReg = register('Status', {
        required: 'This field is Required',
    });

    return (
        <>
            <Editsupplybuildurl show={buildurledit} onHide={() => setBuildUrledit(false)} setBuildUrledit={setBuildUrledit} buildurledit={buildurledit} setComplitionlinkedit={setComplitionlinkedit} complitionlinkedit={complitionlinkedit} hideaddsupplier={props.setShowAddSetup} />
            {compbuildurledit ? <SupplyBuildUrl show={compbuildurledit} onHide={() => setCompBuildUrledit(false)} setbuildurledit={setCompBuildUrledit} tbuildurledit={compbuildurledit} settermination={setValue} keyvalue={'Excomplink'} baseurl={complitionlinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setComplitionlinkedit} /> : <></>}
            {tbuilurl ? <SupplyBuildUrl show={tbuilurl} onHide={() => setBuildurl(false)} setbuildurledit={setBuildurl} tbuildurledit={tbuilurl} settermination={setValue} keyvalue={'Extermlink'} baseurl={terminationlinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setTerminationlinkedit} /> : <></>}

            {qbuildurledit ? <SupplyBuildUrl show={qbuildurledit} onHide={() => setQBuildUrledit(false)} setbuildurledit={setQBuildUrledit} tbuildurledit={qbuildurledit} settermination={setValue} keyvalue={'Exquotalink'} baseurl={quotafulllinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setQuotafulllinkedit} /> : <></>}
            {securityedit ? <SupplyBuildUrl show={securityedit} onHide={() => setSecurityUrledit(false)} setbuildurledit={setSecurityUrledit} tbuildurledit={securityedit} settermination={setValue} keyvalue={'ExSecurityLink'} baseurl={securityfulllinkedit} hideaddsupplier={props.setShowAddSetup} supplierid={id} seturl={setSecurityfulllinkedit} /> : <></>}
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '16px', paddingLeft: '10px' }}>
                        <ModeEditIcon style={{ fontSize: '20px' }} />
                        &nbsp; Setup Supplier -{props.supplier_name}
                    </Modal.Title>
                </Modal.Header>
                {loder ? (
                    <>
                        <SpinnerLoader />
                    </>
                ) : (
                    <>
                        {' '}
                        <Modal.Body>
                            <form onSubmit={handleSubmit(handleSaveButton)}>
                                <div className="csmulti">
                                    <div className="px-10">
                                        <Row>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Status</label>

                                                <select
                                                    className="formtext"
                                                    {...statusReg}
                                                    // {...register("statusReg", {
                                                    //   required: "This field is Required",
                                                    // })}
                                                    onChange={(e) => {
                                                        statusReg.onChange(e);
                                                        setUserStatusid(e.target.value);
                                                    }}
                                                    value={userStatusid}
                                                >
                                                    {userStatus.map((e) => (
                                                        <option value={e.id}>{e.name}</option>
                                                    ))}
                                                </select>
                                                {errors.Status && <small className="errmsg">{errors.Status.message}</small>}
                                            </Col>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Supplier Cost</label>
                                                <input
                                                    {...register('SupplierCost', {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9.]+$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="Supplier Cost"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors.SupplierCost && <small className="errmsg editsuperror">{errors.SupplierCost.message}</small>}
                                            </Col>
                                            <Col className="col" lg={3} md={3} sm={6} xs={12}>
                                                <label className="formlabelviewzone">Quota</label>
                                                <input
                                                    {...register('Quota', {
                                                        required: 'This field is Required',
                                                        pattern: {
                                                            value: /^[0-9]+$/,
                                                            message: 'Only numbers are allowed',
                                                        },
                                                    })}
                                                    placeholder="Quota"
                                                    className="formtextviewzone"
                                                    type="text"
                                                />
                                                {errors.Quota && <small className="errmsg editsuperror">{errors.Quota.message}</small>}
                                            </Col>
                                        </Row>

                                        <Row className="pt-2 pb-4">
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Pre Screening</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={screen}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setScreen(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Country Check</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={country_check}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setCountryCheck(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Proxy Check</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={proxyCheck}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setProxyCheck(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Duplicate IP</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={duplicateIp}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setDuplicateIp(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Qualification Screen</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={qualificationScreen}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setQualificationScreen(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Screen Captcha</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={screenCaptcha}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setScreenCaptcha(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Red Herring Screen</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={redHiring}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setRedhiring(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Speeder Terminate</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={speederTerminate}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setSpeederTerminate(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6} className="suppliersetup m-0">
                                                <div className="rrr"></div>
                                                <div className="pswtchbtn  q_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Research Defender</label>
                                                    <BootstrapSwitchButton
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        width={60}
                                                        checked={resercchDefence}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                        onChange={(e) => {
                                                            setReserchDefence(e);
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2} md={4} sm={4} xs={6}>
                                                <div className="pswtchbtn s_edit_switcbtn">
                                                    <label className="formlabelviewzone w-100">Exception Link</label>
                                                    <BootstrapSwitchButton
                                                        onChange={(e) => {
                                                            if (e === true) {
                                                                setShowlink(true);
                                                            } else {
                                                                setShowlink(false);
                                                            }
                                                        }}
                                                        className="switch btn on btn-outline-success btn-xs"
                                                        size="xs"
                                                        checked={showlink}
                                                        width={60}
                                                        onlabel="ON"
                                                        offlabel="OFF"
                                                        onstyle="outline-success"
                                                        offstyle="outline-danger"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    {showlink ? (
                                        <>
                                            <div className="buildURL_wrapper">
                                                <Row>
                                                    <label className="formlabelviewzone">Completion Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Excomplink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Completion Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setComplitionlinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {complitionlinkedit === '' ? <>{errors.Excomplink && <small className="supply_errormsg m-0">{errors.Excomplink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                setCompBuildUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <label className="formlabelviewzone">Termination Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Extermlink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Termination Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setTerminationlinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {terminationlinkedit === '' ? <>{errors.Extermlink && <small className="supply_errormsg m-0">{errors.Extermlink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();
                                                                setBuildurl(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label className="formlabelviewzone">Quotafull Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('Exquotalink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="  Quotafull Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setQuotafulllinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {quotafulllinkedit === '' ? <>{errors.Exquotalink && <small className="supply_errormsg m-0">{errors.Exquotalink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                setQBuildUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <label className="formlabelviewzone">Quality Link*</label>
                                                    <Col lg={10} md={12} sm={12} xs={12} className="mb-1">
                                                        <input
                                                            {...register('ExSecurityLink', {
                                                                required: 'This field is Required',
                                                            })}
                                                            placeholder="Security Link"
                                                            type="text"
                                                            onChange={(e) => {
                                                                setSecurityfulllinkedit(e.target.value);
                                                            }}
                                                            className="w-100"
                                                        />
                                                        {securityfulllinkedit === '' ? <>{errors.ExSecurityLink && <small className="supply_errormsg m-0">{errors.ExSecurityLink.message}</small>}</> : <></>}
                                                    </Col>
                                                    <Col lg={2} md={12} sm={12} xs={12}>
                                                        <button
                                                            className="cbtn saveBtn m-0"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                setSecurityUrledit(true);
                                                            }}
                                                        >
                                                            Build Your URL
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <Row className="suppliersetup">
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <label className="formlabelviewzone">Completion Link</label>
                                                    <input
                                                        {...register('complink', {
                                                            required: 'This field is Required',
                                                        })}
                                                        placeholder="Completion Link"
                                                        className="formtextviewzone"
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                    {errors.complink && <small className="supply_errormsg m-0">{errors.complink.message}</small>}
                                                </Col>
                                            </Row>
                                            <Row className="suppliersetup">
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <label className="formlabelviewzone">Termination Link</label>
                                                    <input
                                                        {...register('termlink', {
                                                            required: 'This field is Required',
                                                        })}
                                                        placeholder="Termiation Link"
                                                        className="formtextviewzone"
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                    {errors.termlink && <small className="supply_errormsg m-0">{errors.termlink.message}</small>}
                                                </Col>
                                            </Row>
                                            <Row className="suppliersetup">
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <label className="formlabelviewzone">Quotafull Link</label>
                                                    <input
                                                        {...register('quotalink', {
                                                            required: 'This field is Required',
                                                        })}
                                                        placeholder="Quotafull Link"
                                                        className="formtextviewzone"
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                    {errors.quotalink && <small className="supply_errormsg m-0">{errors.quotalink.message}</small>}
                                                </Col>
                                                <Col> </Col>
                                            </Row>
                                            <Row className="suppliersetup">
                                                <Col lg={12} md={12} sm={12} xs={12}>
                                                    <label className="formlabelviewzone">Quality Link</label>
                                                    <input
                                                        {...register('Quality Link', {
                                                            required: 'This field is Required',
                                                        })}
                                                        placeholder="Security Link"
                                                        className="formtextviewzone"
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col> </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>

                                <Modal.Footer className="mt-4">
                                    <button className="supply_setupbtn m-0">
                                        {spinner && <Spinner as="span" style={{ color: 'white' }} animation="grow" size="sm" role="status" aria-hidden="true" />}
                                        &nbsp; Submit
                                    </button>
                                </Modal.Footer>
                            </form>
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </>
    );
}
export default Setupsupplierdiy;
