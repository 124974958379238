import Modal from "react-bootstrap/Modal";
import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import SupplierLink from "./SupplierLInk";
import EditSupplier from "../../SupplyInfo/PopUp/EditSupplier";
import { useState } from "react";
import "./SurveyTracking.css";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SpinnerLoader from "../../../UI/Spinner";
import linkIcon from "../../../../assets/CSicons/icons/link.svg";
import Download from "../../../../assets/CSicons/icons/download.svg";
import { BiEditAlt } from "react-icons/bi";
import { BASE_URL } from "../../../../api/axios";
function SurveyTracking(props) {
  const [editsupplier, setEditSupplier] = useState(false);
  const [supplierLink, setSupplierLink] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [surveyTrackingData, setSurveyTrackingData] = useState([]);
  const [childId, setChildData] = useState();
  const [loder, setLoder] = useState(true);

  useEffect(() => {
    if (supplierLink === false) {
      setLinkData({});
    }
  }, [supplierLink]);

  useEffect(() => {
    if (props.surveyTrackingId !== undefined) {
      get_SurveyTracking();
    }
  }, [props.surveyTrackingId]);

  const get_SurveyTracking = async () => {
    if (props.surveyTrackingId !== "") {
      setLoder(true);
      try {
        const response = await axiosPrivate.get(
          `survey/get-survey-tracking/${props.surveyTrackingId}`
        );
        setSurveyTrackingData(response.data.survey_tracking);
        setLoder(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const [linkData, setLinkData] = useState({
    completeLink: "",
    terminationLink: "",
    quotafullLink: "",
    securityLink: "",
  });
  const handleCallLink = async (id) => {
    const response = await axiosPrivate.get(
      `survey-supplier/get-survey-supplier?survey_supplier_id=${id}`
    );

    if (response.data.survey_supplier.has_exceptional_endlinks) {
      setLinkData({
        completeLink:
          response.data.survey_supplier.link.supplier_exceptional_link.complete,
        terminationLink:
          response.data.survey_supplier.link.supplier_exceptional_link.terms,
        quotafullLink:
          response.data.survey_supplier.link.supplier_exceptional_link
            .overquotas,
        securityLink:
          response.data.survey_supplier.link.supplier_exceptional_link
            .security_terms,
      });
    } else {
      setLinkData({
        completeLink: response.data.survey_supplier.link.supplier_link.complete,
        terminationLink: response.data.survey_supplier.link.supplier_link.terms,
        quotafullLink:
          response.data.survey_supplier.link.supplier_link.overquotas,
        securityLink:
          response.data.survey_supplier.link.supplier_link.security_terms,
      });
    }
  };

  return (
    <>
      {editsupplier ? (
        <>
          <EditSupplier
            show={editsupplier}
            onHide={() => setEditSupplier(false)}
            setEditSupplier={setEditSupplier}
            childId={childId}
            setLinkData={"setLinkData"}
          />
        </>
      ) : (
        <></>
      )}

      <SupplierLink
        linkData={linkData}
        show={supplierLink}
        onHide={() => setSupplierLink(false)}
        props={props}
        setSupplierLink={setSupplierLink}
      />
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="quota_survey_track_title"
            id="contained-modal-title-vcenter"
            style={{
              fontSize: "14px",
            }}
          >
            <div className="saveBtn">
              <button className="RespData">Respondent Data</button>
              <button className="Screendata">Screening Data</button>
            </div>
            {surveyTrackingData.suplier_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loder ? (
            <SpinnerLoader />
          ) : (
            <>
              {surveyTrackingData.length === 1 ? (
                <>
                  {" "}
                  <div
                    className="alert alert-danger"
                    style={{
                      textAlign: "center",
                      margin: "15px",
                    }}
                    role="alert"
                  >
                    No Data Found!
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="table-responsive scrollXNone">
                    <Table>
                      <thead>
                        <Row className="fixedXScroll">
                          <Col
                            lg={2}
                            md={5}
                            sm={5}
                            xs={6}
                            className="col tablescroll"
                            style={{
                              padding: "0px",
                              paddingLeft: "10px",
                            }}
                          >
                            <Table>
                              <tr>
                                <>
                                  <td className="heightcss">
                                    <Table
                                      responsive="sm"
                                      className="spaceBetween unicknew"
                                    >
                                      {" "}
                                      <tr
                                        className="removeBorder"
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <td> Survey Parameter</td>
                                      </tr>
                                      <tr className="removeBorder headerlineheihgt">
                                        .
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> PreScreen</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td>Started</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td> Incomplete SC/CL </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td> Reached Client Survey </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td> Return from Client </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td>Complete</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        {" "}
                                        <td> Terminate SC/CL </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Quotafull</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Quality Terminate</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Duplicate </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Speeder Terminate</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td>Invalid IP(Coun. Check)</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> WiseCheck(Dup IP) </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Fraudulent </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> SC Close</td>{" "}
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> IP Octet</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Abandon % (SC/CL)</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Conversion(%)</td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Overall Conversion(%) </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td>Incidence Rate % </td>
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Quotafull% </td>{" "}
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> Margin(%)</td>{" "}
                                      </tr>
                                      <tr className="removeBorder">
                                        <td> .</td>{" "}
                                      </tr>
                                    </Table>
                                  </td>
                                </>
                              </tr>
                            </Table>
                          </Col>

                          <Col
                            lg={10}
                            md={7}
                            sm={7}
                            xs={6}
                            className="col tablescroll"
                            style={{
                              padding: "0px",
                            }}
                          >
                            <Table>
                              <tr>
                                {surveyTrackingData.map((e, index) => (
                                  <>
                                    <td className="heightcss">
                                      <Table
                                        responsive="sm"
                                        className="spaceBetween unick"
                                      >
                                        <tr className="supplierNameagain">
                                          {e.supplier_name === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.supplier_name}</td>
                                          )}
                                        </tr>
                                        <tr className="secoundLineHeight ">
                                          {" "}
                                          <td>0/15</td>
                                        </tr>
                                        <tr>
                                          <td>
                                            {e.prescreener === "Inactive" ||
                                            e.prescreener === "OFF" ? (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: "red",
                                                    borderRadius: "4px",
                                                    color: "white",
                                                  }}
                                                >
                                                  {e.prescreener}
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                <span
                                                  style={{
                                                    backgroundColor: "#00a31b",

                                                    borderRadius: "4px",
                                                    color: "white",
                                                  }}
                                                >
                                                  {e.prescreener}
                                                </span>
                                              </>
                                            )}
                                          </td>
                                        </tr>
                                        <tr>
                                          {e.summary.started === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.summary.started}</td>
                                          )}
                                        </tr>
                                        {/* Incomplete */}
                                        <tr>
                                          {e.stats["1"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.stats["51"]}/{e.stats["1"]}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Reached Client Survey */}
                                        <tr>
                                          {e.summary.reached_client_survey ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.summary.reached_client_survey}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Return from Client */}
                                        <tr>
                                          {e.summary.return_from_client ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.summary.return_from_client}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Complete */}
                                        <tr>
                                          {e.stats["2"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["2"]}</td>
                                          )}
                                        </tr>
                                        {/* Terminate */}
                                        <tr>
                                          {e.stats["3"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.stats["52"]}/{e.stats["3"]}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Quotafull */}
                                        <tr>
                                          {e.stats["4"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["4"]}</td>
                                          )}
                                        </tr>
                                        {/* Quality Terninate */}
                                        <tr>
                                          {e.stats["5"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["5"]}</td>
                                          )}
                                        </tr>
                                        {/* Duplicate */}
                                        <tr>
                                          {e.stats["0"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["0"]}</td>
                                          )}
                                        </tr>
                                        {/* Speeder Terminate */}
                                        <tr>
                                          {" "}
                                          {e.stats["6"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["6"]}</td>
                                          )}
                                        </tr>
                                        {/* Invalid IP(Coun. Check) */}
                                        <tr>
                                          {e.stats["60"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["60"]}</td>
                                          )}
                                        </tr>
                                        {/* WiseCheck(Dup IP) */}
                                        <tr>
                                          {e.stats["59"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["59"]}</td>
                                          )}
                                        </tr>
                                        {/* Fraudulent */}
                                        <tr>
                                          {" "}
                                          {e.stats["73"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["73"]}</td>
                                          )}
                                        </tr>
                                        {/* SC Close */}
                                        <tr>
                                          {" "}
                                          {e.stats["56"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["56"]}</td>
                                          )}
                                        </tr>
                                        {/* IP Octet */}
                                        <tr>
                                          {" "}
                                          {e.stats["0"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["57"]}</td>
                                          )}
                                        </tr>
                                        {/* Abandon % (SC/CL) */}
                                        <tr>
                                          {e.summary.abandon_sc_perc ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.summary.abandon_sc_perc}/
                                              {e.summary.abandon_cl_perc}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Conversion */}
                                        <tr>
                                          {" "}
                                          {e.summary.conversion_perc ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.summary.conversion_perc}</td>
                                          )}
                                        </tr>
                                        {/* Overall Conversion(%) */}
                                        <tr>
                                          {" "}
                                          {e.summary.overall_conversion_perc ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {
                                                e.summary
                                                  .overall_conversion_perc
                                              }
                                            </td>
                                          )}
                                        </tr>
                                        {/* Incidene Rate % */}
                                        <tr>
                                          {e.summary.incidene_rate_perc ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>
                                              {e.summary.incidene_rate_perc}
                                            </td>
                                          )}
                                        </tr>
                                        {/* Quotafull% */}
                                        <tr>
                                          {e.stats["53"] === undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.stats["53"]}</td>
                                          )}
                                        </tr>
                                        {/* Margin(%) */}
                                        <tr>
                                          {e.summary.margin_perc ===
                                          undefined ? (
                                            <td>KR</td>
                                          ) : (
                                            <td>{e.summary.margin_perc}</td>
                                          )}
                                        </tr>
                                        {index === 0 ? (
                                          <>
                                            <tr>.</tr>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <tr>
                                              <td className="ps-0">
                                                <a
                                                  href={
                                                    BASE_URL +
                                                    `/project/survey-supplier-respondent-export/${props.surveyTrackingId}/${e.supplier_id}`
                                                  }
                                                >
                                                  <img
                                                    title="Download"
                                                    // style={{
                                                    //     width: '30px',
                                                    // }}
                                                    src={Download}
                                                    alt="linkIcon"
                                                    srcset=""
                                                    className="quota_iconBtn "
                                                  />
                                                </a>
                                                <img
                                                  title="Link"
                                                  style={{
                                                    width: "30px",
                                                  }}
                                                  src={linkIcon}
                                                  alt="linkIcon"
                                                  srcset=""
                                                  onClick={() => {
                                                    setSupplierLink(true);
                                                    props.setSetting(false);
                                                    handleCallLink(e.id);
                                                  }}
                                                  className="quota_iconBtn "
                                                />
                                                &nbsp;
                                                <BiEditAlt
                                                  title="Edit"
                                                  alt="EditIcon"
                                                  srcset=""
                                                  onClick={() => {
                                                    setEditSupplier(true);
                                                    props.setSetting(false);
                                                    setChildData(e.id);
                                                  }}
                                                  className="quota_iconBtn editicon-css"
                                                />
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                      </Table>
                                    </td>
                                  </>
                                ))}
                              </tr>
                            </Table>
                          </Col>
                        </Row>
                      </thead>
                    </Table>
                  </div>
                </>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SurveyTracking;
