import { useState, useEffect } from "react";

import "../../UI/DateRangePicker.css";
import Table from "react-bootstrap/Table";

import "./BidReport.css";

import { Col, Row } from "react-bootstrap";
import useAuth from "../../../hooks/useAuth";
import { FiFilter } from "react-icons/fi";
import pageIcon from "../../../assets/BidsIcons/bids.svg";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { BiReset } from "react-icons/bi";
import Select from "react-select";
import axios from "../../../api/axios";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
const BidBookingAmount = () => {
  const LOGIN_URL = "/auth/login";

  const { setAuth, setPersist } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [dataLength, setDataLength] = useState();
  const [pagesize, setPagesize] = useState(10);
  const [nextPage, setNextPage] = useState(1);
  const [filter_status, setFilter_status] = useState(false);
  const [loder, setLoder] = useState(true);
  const [selectedDate_start, setSelectedDate_start] = useState("");
  const [selectedDate_end, setSelectedDate_end] = useState("");
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getPageSize = (size) => {
    setPagesize(size);
  };

  const handlePageChnage = (pagesize) => {
    setNextPage(pagesize + 1);
  };
  useEffect(() => {
    handleSubmitButton();
  }, []);
  const handleSubmitButton = async (e) => {
    setLoder(false);
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email: "techops@torfac.com",
          password: "techops@123",
          // password: "Tech@2024",
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      const accessToken = response?.data?.access_token;
      const refreshToken = response?.data?.refresh_token;
      const permission = response.data.permissions.perm_list;
      const superuser = response.data.permissions.is_superuser;

      setAuth({ accessToken, refreshToken, permission, superuser });

      setPersist(true);
      localStorage.setItem("persist", true);
      localStorage.setItem("token", refreshToken);
      setLoder(true);
    } catch (err) {}
  };

  const getBidder = async () => {
    setIsSubmitting(true);
    if (selectedDate_end !== "" && selectedDate_start !== "") {
      try {
        const response = await axiosPrivate.get(
          `bid/booking-amount-salesrep-report?query_filter=start_date%3D${selectedDate_start}%26end_date%3D${selectedDate_end}`
        );
        setData(response.data.bid);
        setDataLength(response.data.bid.length);
      } catch (err) {
        console.error(err);
      }
    } else {
      toast.error("Please select a date range");
    }

    setIsSubmitting(false);
  };

  const refreshFilter = () => {
    setData([]);
    setDataLength(1);
  };
  const Link_value = [
    {
      value: "sale-report",
      label: "Sale Report",
    },
    {
      value: "bid-volume",
      label: "Bid Volume",
    },
    {
      value: "bid-booking",
      label: "Booking amount",
    },
    {
      value: "top-ten-accounts",
      label: "Top Ten Accounts",
    },
  ];
  return (
    <div className="top_container">
      <div className="main_container ">
        <div className="user_serachboxSupply">
          <div style={{ float: "left" }}>
            <h3 className="tableHeading">
              <img src={pageIcon} className="headingIcons" alt="" /> Bid Booking
              Amount
            </h3>
          </div>
          <div
            style={{
              float: "right",
              width: "30%",
            }}
          >
            <FiFilter
              className="supplyIconColr"
              style={{
                float: "right",
                margin: "5px 0px",
                marginRight: "-5px",
                fontSize: "30px",
                padding: "2px 5px",
                color: "white",
                backgroundColor: "#1a1a1a",
                border: "1px solid black",
                cursor: "pointer",
              }}
              onClick={() => {
                setShow(!show);
              }}
            />
            <div
              style={{
                textAlign: "right",
                marginRight: "10px",
                float: "right",
                marginTop: "8px",
                display: "flex",
              }}
            >
              <label
                className="formlabel"
                style={{
                  width: "30%",
                  marginTop: "15px",
                }}
              >
                Links &nbsp;
              </label>
              <select
                value={params["*"]}
                onChange={(e) => {
                  navigate(`/${e.target.value}`);
                }}
              >
                {Link_value.map((item) => (
                  <option value={item.value}>{item.label}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <hr />
        {show ? (
          <>
            <form onSubmit={handleSubmit()}>
              <Row
                className="supply_filterbox"
                style={{
                  backgroundColor: "#f1efef",
                  paddingBottom: "10px",
                }}
              >
                <Col lg={10} md={9} xs={11} className="supply_col">
                  <div class="input-row-child flex flex-direction">
                    <label> Date Range</label>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: {
                          cancelLabel: "Clear",
                          format: "YYYY-MM-DD",
                        },
                      }}
                      defaultValue={null}
                      onApply={(event, picker) => {
                        setSelectedDate_start(
                          `${picker.startDate.format("YYYY-MM-DD")}`
                        );
                        setSelectedDate_end(
                          `${picker.endDate.format("YYYY-MM-DD")}`
                        );
                      }}
                      onCancel={(event, picker) => {
                        setSelectedDate_start("");
                        setSelectedDate_end("");
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={`${selectedDate_start}-${selectedDate_end}`}
                      />
                    </DateRangePicker>
                  </div>
                </Col>
                <Col
                  lg={2}
                  md={3}
                  xs={12}
                  className="col"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="cbtn saveBtn"
                    style={{
                      maxWidth: "100%",
                      marginTop: "25px",
                    }}
                    onClick={(e) => {
                      getBidder();
                      setFilter_status(true);
                      setNextPage(1);
                      setPagesize(10);
                      e.stopPropagation();
                    }}
                  >
                    {isSubmitting && (
                      <Spinner
                        style={{ color: "white" }}
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    &nbsp;&nbsp; Submit
                  </button>
                  <BiReset
                    size={30}
                    style={{
                      marginTop: "25px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      refreshFilter();
                      setSelectedDate_start("");
                      setSelectedDate_end("");
                    }}
                  />{" "}
                </Col>
              </Row>
            </form>
          </>
        ) : null}
        <div className="mainContent mainContent2">
          {loder ? (
            data.length !== 0 ? (
              <div className="table_container ">
                <Table
                  bordered={false}
                  hover
                  size="sm"
                  className="customTable_bid_report"
                >
                  <thead className="thead">
                    <tr>
                      <td>Sales Rep.</td>
                      <td>Target</td>
                      <td>Actual</td>
                      <td>% Contribution</td>
                      <td>Surplus/Deficit</td>
                      <td>%Achieved</td>
                    </tr>
                  </thead>

                  <tbody>
                    {data.map((item, index) => (
                      <tr key={item.id}>
                        {item?.sales_rep ? (
                          <td>{item.sales_rep}</td>
                        ) : (
                          <td>0</td>
                        )}
                        {item?.target ? <td>{item.target}</td> : <td>0</td>}
                        {item?.actual ? <td>{item.actual}</td> : <td>0</td>}
                        {item?.contribution ? (
                          <td>{item.contribution} %</td>
                        ) : (
                          <td>0 %</td>
                        )}
                        {item?.surplus_deficit ? (
                          <td>{item.surplus_deficit}</td>
                        ) : (
                          <td>0</td>
                        )}
                        {item?.achived ? (
                          <td>{item.achived} %</td>
                        ) : (
                          <td>0 %</td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div
                className="alert alert-danger center-margin-container"
                role="alert"
              >
                Please select date range
              </div>
            )
          ) : (
            <SpinnerLoader />
          )}

          {/* <Paginations
            userlist={dataLength}
            getPageSize={getPageSize}
            handlePageChnage={handlePageChnage}
            page={nextPage}
            pagesize={pagesize}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default BidBookingAmount;
