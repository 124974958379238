import { useEffect, useState } from 'react';
import './ProjectDetail.css';
import 'bootstrap/dist/css/bootstrap.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import Select from 'react-select';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CloneNew from './CloneNew';
import CloneEdit from './CloneEdit';
import { MultiSelect } from 'react-multi-select-component';

const ProjectDetailMulipalForm = (props) => {
    const { id } = useParams();
    const [cloneBox, setCloneBox] = useState([]);
    const [newClonedId, setNewClonedId] = useState();
    const { layoutId } = useParams();
    var index = props.index;
    const [surveyData, setsurveyData] = useState(props.surveyData);
    const [dataStorage, setDataStorage] = useState(surveyData[index]);
    const [alertLoder, setAlertLoder] = useState(false);
    const { survey_Id } = useParams();
    const [exclude_api, setExclude_api] = useState([]);
    const [selected_ex_api, setSelected_ex_api] = useState([]);
    const [flag, setFlag] = useState(false);
    const [toggeleSwitchButton, setToggleSwitchButton] = useState(props.qualificationData[index].target_aud_status);
    const [marginError, setMarginError] = useState(null);

    const navigate = useNavigate();
    const [container, setContainer] = useState({
        usGen_C: 'hide',
        usGen_I: 'down',
    });

    useEffect(() => {
        setsurveyData(props.surveyData);

        index = props.index;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        if (surveyData[index].id.toString() === survey_Id.toString()) {
            setContainer((event) => ({
                ...event,
                usGen_C: 'show',
                usGen_I: 'up',
            }));
        } else {
            setContainer((event) => ({
                ...event,
                usGen_C: 'hide',
                usGen_I: 'down',
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [survey_Id]);

    var marginModify = props.marginModify;
    const axiosPrivate = useAxiosPrivate();
    const [surveyId, setSurveyId] = useState('');
    const [payloadIndex, setPayloadIndex] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [language, setLanguage] = useState([]);
    const [selected_language, setSeletedLanguage] = useState([props.modifyDataLanguage[index]]);
    const notifyerror = (e) => toast.error(`${e} Error occured`);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
    } = useForm();

    const RecallContainer = (e) => {
        if (e === 'Container_first') {
            setContainer((event) => ({
                ...event,
                usGen_C: 'show',
                usGen_I: 'up',
            }));
        }
    };

    const [projectTypeStorage, setProjectTypeStorage] = useState([{}]);
    const [countryListStorage, setCountryListStorage] = useState([{}]);
    const [surveyMargin, setSurveyMargin] = useState([{}]);

    useEffect(() => {
        get_ProjectType_api();
        get_Country_api();
        get_SurveyMargin_api();
        get_exclude_apis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.flag]);

    useEffect(
        () => {
            setValue(`Country[${index}]`, props.modifyData[index]);
            setValue(`Language[${index}]`, props.modifyDataLanguage[index]);
            setValue(`status_Type[${index}]`, props.surveyType[index]);
            setValue(`MarginThreshold[${index}]`, marginModify[index].value);
            setValue(`Status[${index}]`, props.statusdata[index]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [props.modifyData[index]],
        props.surveyType[index],
        props.modifyDataLanguage[index],
        props.marginModify[index],
        props.surveyData[index],
        props.statusdata[index]
    );

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleYes = async () => {
        setAlertLoder(true);

        const payload = {
            survey_id: surveyId,
        };

        let storeId;
        try {
            await axiosPrivate
                .post('survey/create-clone', payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((e) => {
                    setNewClonedId(e.data.survey.id);
                    storeId = e.data.survey.id;
                    toast.success('Survey cloned successfully');
                });
        } catch (err) {
            notifyerror(err.response.status);
        }

        setCloneBox([...cloneBox, storeId]);
        setShow(false);
        setAlertLoder(false);
    };

    const get_ProjectType_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-service-type');

            response.data.all_status.forEach((e) => {
                setProjectTypeStorage((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    const get_SurveyMargin_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/get-survey-margin');
            response.data.all_status.forEach((e) => {
                setSurveyMargin((val) => [...val, { label: e.id, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    const language_api = async (country_id) => {
        setLanguage([]);

        setSeletedLanguage([]);
        try {
            const response = await axiosPrivate.get(`lookup/country-language?country_id=${country_id}`);

            response.data.language.forEach((e) => {
                setLanguage((val) => [...val, { label: e.name, value: e.id }]);
            });
            setValue(`Language[${index}]`, '');
        } catch (err) {
            console.error(err);
        }
    };

    const get_Country_api = async () => {
        try {
            const response = await axiosPrivate.get('lookup/country-list');
            response.data.all_countries.forEach((e) => {
                setCountryListStorage((val) => [...val, { label: e.country_name, value: e._id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    const get_exclude_apis = async () => {
        try {
            const response = await axiosPrivate.get('supplyinfo/active-suppliers?supplier_type_id=3');

            setExclude_api([]);
            response.data.supplier.map((e) => {
                setExclude_api((val) => [...val, { label: e.name, value: e.id }]);
            });
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };
    useEffect(() => {
        if (props.surveyData.length !== 0) {
            if (props.surveyData[index].exclude_api !== null) {
                const filteredAnswers = exclude_api
                    .filter((answer) => props.surveyData[index].exclude_api.includes(answer.value))
                    .map((answer) => ({
                        label: answer.label,
                        value: answer.value,
                    }));

                setSelected_ex_api(filteredAnswers);
            } else {
                setSelected_ex_api([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.surveyData, exclude_api]);

    const handleFormSubmit = async (data) => {
        let ans_code = selected_ex_api.map((option) => option.value);

        setIsSubmitting(true);
        if (data.toString() === undefined) {
        } else {
            const payload = {
                country_id: data.Country[payloadIndex].value,
                language_id: data.Language[payloadIndex].value,
                ir: Number(data.IR[payloadIndex]),
                loi: Number(data.Loi[payloadIndex]),
                n: Number(data.Quota[payloadIndex]),
                cpi: Number(data.Cpi[payloadIndex]),
                margin_threshhold: data.MarginThreshold[payloadIndex],
                survey_status_id: data.Status[payloadIndex].value,
                project_survey_type_id: data.status_Type[payloadIndex].value,
                exclude_api: ans_code,
            };

            try {
                await axiosPrivate
                    .patch(`survey/update-survey-project?survey_id=${surveyId}`, payload, {
                        headers: {
                            accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((e) => {
                        toast.success('Survey updated successfully');
                        props.setFlag(!props.flag);
                        setFlag(!flag);
                    });
            } catch (err) {
                notifyerror(err.response.status);
            }
        }
        setIsSubmitting(false);
    };

    const handleStatus = (e, prop_index) => {
        const updatedDemo = [...surveyData];
        updatedDemo[prop_index] = {
            ...updatedDemo[prop_index],
            survey_status: { id: e.value, name: e.label },
        };
        setsurveyData(updatedDemo);
    };
    const handleTargetSwitch = async (e, ID) => {
        setToggleSwitchButton(e);

        const payload = {
            status: e ? 1 : 0,
        };

        try {
            await axiosPrivate
                .patch(`survey/target-audience/${ID}`, payload, {
                    headers: {
                        accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        props.setQualificationData((prevData) => {
                            const newData = [...prevData];
                            const index = newData.findIndex((item) => item.id === ID);

                            if (index !== -1) {
                                newData[index].target_aud_status = e;
                            }

                            return newData;
                        });
                        toast.success('Project updated successfully');
                    }
                });
        } catch (err) {
            setToggleSwitchButton(props.qualificationData[index].target_aud_status);
            if (err.response.status === 500) {
                notifyerror('Service');
            } else {
                toast.error(err.response.data.return_status.message[0]);
            }
        }
    };

    const [inputValue, setInputValue] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);

    const handleChangeMargin = (index, e) => {
        setInputValue(e.target.value);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        const newTimeoutId = setTimeout(() => {
            marginThresoldApi(index, e.target.value);
        }, 1000);
        setTimeoutId(newTimeoutId);
    };

    const marginThresoldApi = async (index, data) => {
        try {
            const response = await axiosPrivate.get(`setting/get-margin-threshold?margin_threshhold=${data}`);

            if (response.data.marginthreshold.error === false) {
            } else {
                setMarginError(response.data.marginthreshold.message);
            }
        } catch (err) {
            console.error(err);
            notifyerror(err.response.status);
        }
    };

    return (
        <>
            <div className="first firstone" id="boxContainerBorder">
                <div
                    className="ScreeningHead pjt_arrow_icon flex items-center"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        container.usGen_C.toString() === 'show' ? (
                            <>
                                {setContainer((event) => ({
                                    ...event,
                                    usGen_C: 'hide',
                                    usGen_I: 'down',
                                }))}
                            </>
                        ) : (
                            <>
                                {setContainer((event) => ({
                                    ...event,
                                    usGen_C: 'show',
                                    usGen_I: 'up',
                                }))}
                            </>
                        );
                    }}
                >
                    {container.usGen_I === 'down' ? (
                        <>
                            <KeyboardArrowDownIcon
                                className="pointer"
                                onClick={(e) => {
                                    navigate(`/project-detail/${id}/${layoutId}/${surveyData[index].id}`);
                                    e.stopPropagation();
                                    RecallContainer('Container_first');
                                    e.preventDefault();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <KeyboardArrowUpIcon
                                className="pointer"
                                onClick={(e) => {
                                    navigate(`/project-detail/${id}/${layoutId}/1`);
                                    setContainer((event) => ({
                                        ...event,
                                        usGen_C: 'hide',
                                        usGen_I: 'down',
                                    }));
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                            />
                        </>
                    )}
                    <p className="pBlockHead m-0 w-auto">
                        {surveyData[index].sc_id} - {surveyData[index].survey_name}
                    </p>
                    <div
                        style={{ cursor: 'auto' }}
                        className="rankPain projbtns"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <button
                            type="button"
                            className="PSaveBtn m-0"
                            style={{ width: '120px' }}
                            onClick={(e) => {
                                navigate(`/project-detail/${id}/8/${surveyData[index].id}`);
                                e.stopPropagation();
                            }}
                        >
                            Target Audience
                        </button>
                        <BootstrapSwitchButton
                            className="switch btn on btn-outline-success btn-xs"
                            size="xs"
                            width={60}
                            onlabel="ON"
                            offlabel="OFF"
                            onstyle="outline-success"
                            offstyle="outline-danger"
                            checked={toggeleSwitchButton}
                            onChange={(e) => {
                                handleTargetSwitch(e, surveyData[index].id);
                                e.stopPropagation();
                            }}
                        />
                        <span>|</span>
                        {surveyData[index].survey_status.name.toString() === 'Live' ? (
                            <>
                                <p className="liveBtn m-0">Live</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Pending' ? (
                            <>
                                <p className="pendingBtnagain m-0">Pending</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Test Mode' ? (
                            <>
                                <p className="testmodeBtnagain m-0">Test Mode</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Paused' ? (
                            <>
                                <p className="pausedBtnagain m-0">Paused</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Id Awaited' ? (
                            <>
                                <p className="IdAwaitedBtnagain m-0">Id Awaited</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Billed' ? (
                            <>
                                <p className="billedBtnagain m-0">Billed</p>
                            </>
                        ) : (
                            <></>
                        )}
                        {surveyData[index].survey_status.name.toString() === 'Cancelled' ? (
                            <>
                                <p className="cancelledBtnagain m-0">Cancelled</p>
                            </>
                        ) : (
                            <></>
                        )}
                        <button
                            type="button"
                            className="PSaveBtn m-0"
                            style={{ width: '85px' }}
                            onClick={() => {
                                props.props.setSurveyId(surveyData[index]._id);

                                navigate(`/project-detail/${id}/2/${surveyData[index].id}`);
                            }}
                        >
                            Screening
                        </button>
                        <button
                            className="PSaveBtn m-0"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSurveyId(surveyData[index].id);
                                handleShow();
                            }}
                        >
                            Clone
                        </button>

                        <button
                            className="PSaveBtn m-0"
                            onClick={(e) => {
                                setPayloadIndex(index);
                                setSurveyId(surveyData[index].id);
                                e.stopPropagation();
                            }}
                            form={`mainForm${index}`}
                            type="submit"
                        >
                            {isSubmitting && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                            &nbsp; Save
                        </button>
                    </div>
                </div>

                {container.usGen_C === 'show' ? (
                    <>
                        <div
                            style={{
                                backgroundColor: 'white',
                            }}
                            className="csmulti"
                        >
                            <form onSubmit={handleSubmit(handleFormSubmit)} name={`mainForm${index}`} id={`mainForm${index}`}>
                                <Row className="prj_rowtwo">
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">Country*</label>
                                        <Select
                                            {...register(`Country[${index}]`, {
                                                required: 'This field is Required',
                                            })}
                                            placeholder="Select..."
                                            className="pjt_select formtext"
                                            classNamePrefix="select"
                                            defaultValue={props.modifyData[index]}
                                            options={countryListStorage}
                                            onChange={(e) => {
                                                setValue(`Country[${index}]`, e);
                                                language_api(e.value);
                                            }}
                                            noOptionsMessage={() => 'No Data Found'}
                                        />
                                        {errors?.Country?.[index] && <small className="errmsg">{errors.Country[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">Language*</label>
                                        <Select
                                            {...register(`Language[${index}]`, {
                                                required: 'This field is Required',
                                            })}
                                            placeholder="Select..."
                                            className="pjt_select formtext"
                                            classNamePrefix="select"
                                            value={selected_language}
                                            options={language}
                                            onChange={(e) => {
                                                setSeletedLanguage(e);
                                                setValue(`Language[${index}]`, e);
                                            }}
                                            noOptionsMessage={() => 'No Data Found'}
                                        />
                                        {errors?.Language?.[index] && <small className="errmsg">{errors.Language[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">Type*</label>

                                        <Select
                                            {...register(`status_Type[${index}]`, {
                                                required: 'This field is Required',
                                            })}
                                            placeholder="Type"
                                            className="pjt_select formtext"
                                            classNamePrefix="select"
                                            defaultValue={props.surveyType[index]}
                                            options={projectTypeStorage}
                                            onChange={(e) => {
                                                setValue(`status_Type[${index}]`, e);
                                                trigger(`status_Type[${index}]`);
                                            }}
                                            noOptionsMessage={() => 'No Data Found'}
                                        />
                                        {errors?.status_Type?.[index] && <small className="errmsg">{errors.status_Type[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">IR*</label>
                                        <input
                                            {...register(`IR[${index}]`, {
                                                required: 'This field is Required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Only numbers are allowed',
                                                },
                                            })}
                                            type="text"
                                            defaultValue={surveyData[index].ir}
                                            onChange={(e) => {
                                                setValue(`IR[${index}]`, e.target.value);
                                                trigger(`IR[${index}]`);
                                            }}
                                            className="CsformText"
                                        />
                                        {errors.IR && errors.IR[index] && <small className="errmsg">{errors.IR[index].message}</small>}
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: '0px',
                                    }}
                                    className="prj_rowtwo"
                                >
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">LOI*</label>
                                        <input
                                            {...register(`Loi[${index}]`, {
                                                required: 'This field is Required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Only numbers are allowed',
                                                },
                                            })}
                                            type="text"
                                            defaultValue={surveyData[index].loi}
                                            placeholder="Loi"
                                            onChange={(e) => {
                                                setValue(`Loi[${index}]`, e.target.value);
                                                trigger(`Loi[${index}]`);
                                            }}
                                            className="CsformText"
                                        />
                                        {errors.Loi && errors.Loi[index] && <small className="errmsg">{errors.Loi[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">CPI*</label>
                                        <input
                                            {...register(`Cpi[${index}]`, {
                                                required: 'This field is Required',
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]+)?$/,
                                                    message: 'Only numbers are allowed',
                                                },
                                            })}
                                            type="text"
                                            defaultValue={surveyData[index].cpi}
                                            placeholder="CPI"
                                            onChange={(e) => {
                                                setValue(`Cpi[${index}]`, e.target.value);
                                                trigger(`Cpi[${index}]`);
                                            }}
                                            className="CsformText"
                                        />
                                        {errors.Cpi && errors.Cpi[index] && <small className="errmsg">{errors.Cpi[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">Quota*</label>
                                        <input
                                            {...register(`Quota[${index}]`, {
                                                required: 'This field is Required',
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Only numbers are allowed',
                                                },
                                            })}
                                            type="text"
                                            placeholder="Quota"
                                            defaultValue={surveyData[index].n}
                                            className="CsformText"
                                            onChange={(e) => {
                                                setValue(`Quota[${index}]`, e.target.value);
                                                trigger(`Quota[${index}]`);
                                            }}
                                        />
                                        {errors.Quota && errors.Quota[index] && <small className="errmsg">{errors.Quota[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label className="formlabel">Margin Threshold*</label>

                                        <input
                                            {...register(`MarginThreshold[${index}]`, {
                                                required: 'This field is Required',
                                                pattern: {
                                                    value: /^(?:100|[1-9][0-9]?)$/,
                                                    message: 'Only numbers from 1 to 100 are allowed',
                                                },
                                            })}
                                            type="text"
                                            defaultValue={marginModify[index].value}
                                            placeholder="MarginThreshold"
                                            onChange={(e) => {
                                                setValue(`MarginThreshold[${index}]`, e.target.value);
                                                trigger(`MarginThreshold[${index}]`);
                                                {
                                                    e.target.value > 100 || e.target.value === '' ? <></> : <>{marginThresoldApi(index, e.target.value)}</>;
                                                }

                                                setMarginError(null);
                                            }}
                                            className="CsformText"
                                        />
                                        {marginError === null ? (
                                            <>{errors?.MarginThreshold?.[index] && <small className="errmsg">{errors.MarginThreshold[index].message}</small>}</>
                                        ) : (
                                            <>
                                                <small className="errmsg">{marginError}</small>
                                            </>
                                        )}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label
                                            className="formlabel"
                                            style={{
                                                marginBottom: '0px',
                                            }}
                                        >
                                            Status*
                                        </label>

                                        <Select
                                            {...register(`Status[${index}]`, {
                                                required: 'This field is Required',
                                            })}
                                            placeholder="Select..."
                                            className="pjt_select formtext"
                                            classNamePrefix="select"
                                            defaultValue={props.statusdata[index]}
                                            options={props.projectStatusStorage}
                                            noOptionsMessage={() => 'No Data Found'}
                                            onChange={(e) => {
                                                setValue(`Status[${index}]`, e);
                                                handleStatus(e, index);
                                            }}
                                        />
                                        {errors?.Status?.[index] && <small className="errmsg">{errors.Status[index].message}</small>}
                                    </Col>
                                    <Col lg={3} md={6} xs={12} className="col">
                                        <label
                                            className="formlabel"
                                            style={{
                                                marginBottom: '0px',
                                            }}
                                        >
                                            Exclude api's
                                        </label>

                                        <MultiSelect
                                            className="multiselectoption "
                                            style={{ width: '29px' }}
                                            options={exclude_api}
                                            value={selected_ex_api}
                                            onChange={(e) => {
                                                setSelected_ex_api(e);
                                            }}
                                            labelledBy="Filter by user"
                                            isCreatable={true}
                                        />
                                    </Col>
                                </Row>
                            </form>

                            <div className="cloneContainer">
                                {props.surveyData[index].clone_survey.length === 0 ? (
                                    <></>
                                ) : (
                                    <>
                                        {props.surveyData[index].clone_survey.map((data, count) => (
                                            <>
                                                <CloneEdit key={count} data={data} dataStorage={dataStorage} allclonedata={props.surveyData[index].clone_survey} dataIndex={index} count={count} setsurveyData={props.setsurveyData} setQualificationData={props.setQualificationData} qualificationData={props.qualificationData} exclude_api={exclude_api} selected_ex_api={selected_ex_api} surveyMargin={surveyMargin} />
                                            </>
                                        ))}
                                    </>
                                )}
                                {cloneBox.map((e, index) => (
                                    <>
                                        <CloneNew survey_id={e} dataStorage={dataStorage} sid={newClonedId} exclude_api={exclude_api} selected_ex_api={selected_ex_api} newClonedId={newClonedId} surveyMargin={surveyMargin} defaultValueMargin={marginModify[index]} />
                                    </>
                                ))}
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>

            <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className="fs16"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="fs16">Do you want to clone</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleClose} className="supply_setupbtn me-2 btn-bg-grey hoverBgColorGrey m-0">
                        No
                    </Button>
                    <Button variant="" className="supply_setupbtn m-0 hoverBgColorBlack" onClick={handleYes}>
                        {alertLoder && <Spinner style={{ color: 'white' }} as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ProjectDetailMulipalForm;
