import PercentageSpinner from '../../UI/Graphs/PercentageSpinner';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
const AccountWiseReport = (props) => {
    const [data, setData] = useState([]);
    const [bidStatus, setBiStatus] = useState([]);
    const numberbid = [1, 2, 3, 4, 5, 6];

    let newCurrentDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    let newEndDate = moment().format('YYYY-MM-DD');
    const axiosPrivate = useAxiosPrivate();

    let startD = useRef();
    let endD = useRef();
    useEffect(() => {
        if (props.account === undefined) {
            startD.current = newCurrentDate;
            endD.current = newEndDate;
        } else {
            startD.current = props.account.CurrentDate;
            endD.current = props.account.EndDate;
        }
        if (props.data) {
            props.setFlag(false);
            let user = '';
            props.data.map((e) => {
                if (props.data[props.data.length - 1]._id !== e._id) {
                    user = user + `user${e._id}&`;
                } else {
                    user = user + `user${e._id}`;
                }
            });

            getData(startD, endD, user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.account, props.data]);
    useEffect(() => {
        if (props.account === undefined) {
            startD.current = newCurrentDate;
            endD.current = newEndDate;
        } else {
            startD.current = props.account.CurrentDate;
            endD.current = props.account.EndDate;
        }
        getDataUser(startD, endD);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.account]);
    const getData = async (startD, endD, user) => {
        try {
            const response = await axiosPrivate.get(
                `bid-dashboard/account-wise-report?start_time=${startD.current}&end_time=${endD.current}&${user}`,
                {}
            );

            setData(response.data.account_wise_report);
            let data = response.data.account_wise_report;

            const newData = Object.keys(data).reduce((result, currentKey) => {
                let fdata = data[currentKey];
                fdata.ckey = currentKey;
                result.push(fdata);
                return result;
            }, []);

            Object.values(newData).map((event) =>
                setBiStatus((oldArray) => [...oldArray, event.bid_status])
            );
        } catch (err) {}
    };
    const getDataUser = async (startD, endD) => {
        try {
            const response = await axiosPrivate.get(
                `bid-dashboard/account-wise-report?start_time=${startD.current}&end_time=${endD.current}`,
                {}
            );

            setData(response.data.account_wise_report);
            let data = response.data.account_wise_report;

            const newData = Object.keys(data).reduce((result, currentKey) => {
                let fdata = data[currentKey];
                fdata.ckey = currentKey;
                result.push(fdata);
                return result;
            }, []);

            Object.values(newData).map((event) =>
                setBiStatus((oldArray) => [...oldArray, event.bid_status])
            );
        } catch (err) {}
    };

    return (
        <table className="dbTable awrTable">
            <thead>
                <tr>
                    <th>Account Name</th>
                    <th colSpan={7}>Record Count</th>
                </tr>
            </thead>
            <tbody>
                {data.map((e, i) => (
                    <tr>
                        <td>{e.name}</td>
                        {numberbid.map((num) => (
                            <td>
                                <p className="small">
                                    {bidStatus[i][num].name}
                                </p>{' '}
                                <PercentageSpinner
                                    percent={bidStatus[i][num].perc}
                                    type={bidStatus[i][num].name}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AccountWiseReport;
