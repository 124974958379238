import './RedHering.css';
import '../Common.css';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Common from '../Common';
import brandlogo from '../../../assets/SamplicityWhiteLogo.svg';
import { Navbar } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import CommonFooter from '../CommonFooter';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import userIco from '../../../assets/LoginIcons/users.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { Redirect } from 'react-router-dom';
import SpinnerLoader from '../../UI/Spinner';

const RedHearing = () => {
    const { respondent_id } = useParams();

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loader, setLoder] = useState(false);
    const [showError, setShowError] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger,
    } = useForm();

    const [questionData, setQuestionData] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [stateList, setStateList] = useState([]);

    const [month, setMonth] = useState([
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' },
    ]);

    const [day, setDay] = useState([
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' },
        { value: 6, label: '6' },
        { value: 7, label: '7' },
        { value: 8, label: '8' },
        { value: 9, label: '9' },
        { value: 10, label: '10' },
        { value: 11, label: '11' },
        { value: 12, label: '12' },
        { value: 13, label: '13' },
        { value: 14, label: '14' },
        { value: 15, label: '15' },
        { value: 16, label: '16' },
        { value: 17, label: '17' },
        { value: 18, label: '18' },
        { value: 19, label: '19' },
        { value: 20, label: '20' },
        { value: 21, label: '21' },
        { value: 22, label: '22' },
        { value: 23, label: '23' },
        { value: 24, label: '24' },
        { value: 25, label: '25' },
        { value: 26, label: '26' },
        { value: 27, label: '27' },
        { value: 28, label: '28' },
        { value: 29, label: '29' },
        { value: 30, label: '30' },
        { value: 31, label: '31' },
    ]);

    const [year, setYear] = useState([
        { value: 1990, label: '1990' },
        { value: 1991, label: '1991' },
        { value: 1992, label: '1992' },
        { value: 1993, label: '1993' },
        { value: 1994, label: '1994' },
        { value: 1995, label: '1995' },
        { value: 1996, label: '1996' },
        { value: 1997, label: '1997' },
        { value: 1998, label: '1998' },
        { value: 1999, label: '1999' },
        { value: 2000, label: '2000' },
        { value: 2001, label: '2001' },
        { value: 2002, label: '2002' },
        { value: 2003, label: '2003' },
        { value: 2004, label: '2004' },
        { value: 2005, label: '2005' },
        { value: 2006, label: '2006' },
        { value: 2007, label: '2007' },
        { value: 2008, label: '2008' },
        { value: 2009, label: '2009' },
        { value: 2010, label: '2010' },
        { value: 2011, label: '2011' },
        { value: 2012, label: '2012' },
        { value: 2013, label: '2013' },
        { value: 2014, label: '2014' },
        { value: 2015, label: '2015' },
        { value: 2016, label: '2016' },
        { value: 2017, label: '2017' },
        { value: 2018, label: '2018' },
        { value: 2019, label: '2019' },
        { value: 2020, label: '2020' },
        { value: 2021, label: '2021' },
        { value: 2022, label: '2022' },
        { value: 2023, label: '2023' },
    ]);

    const handleSaveButton = async (prop) => {
        setIsSubmitting(true);

        const question = questionData[0];
        let answer = '';

        switch (question.question_type) {
            case 'S':
                answer = prop.singleselect;
                break;
            case 'M':
                answer = question.mandatory ? prop.multiselect.join(',') : '';
                break;
            case 'E':
                answer = prop.email;
                break;
            case 'P':
                answer = prop.phoneNumber;
                break;
            case 'A':
                answer = `${prop.address1}<!>${prop.address2}<!>${prop.city}<!>${prop.state}<!>${prop.zipcode}`;
                break;
            case 'I':
                answer = 'True';
                break;
            case 'N':
                answer = prop.FirstName + ' ' + prop.LastName;
                break;
            case 'O':
                answer = prop.Suggestion;
                break;
            case 'B':
                answer = `${prop.year}-${prop.months}-${prop.day}`;
                break;
            default:
                // handle unsupported question types
                break;
        }

        try {
            await axiosPrivate
                .patch(
                    `save-redherring-response/${respondent_id}?question_id=${question.question_id}&answer_id=${answer}`
                )
                .then((data) => {
                    if (data.data.step === 'continue') {
                        handleGetQuestion();
                    } else if (data.data.step === 'terminate') {
                        window.location.href = data.data.url;
                    }
                });
        } catch (err) {}
    };

    useEffect(() => {
        handleGetQuestion();
    }, []);

    const handleGetQuestion = async () => {
        setLoder(false);
        try {
            const response = await axiosPrivate.get(
                `get-redherring-question/${respondent_id}`
            );
            setValue('FirstName', '');
            setValue('LastName', '');
            setValue('address1', '');
            setValue('address2', '');
            setValue('city', '');
            setValue('zipcode', '');
            setValue('state', '');
            setValue('day', '');
            setValue('months', '');
            setValue('year', '');
            setValue('phoneNumber', '');
            setValue('email', '');
            setValue('Suggestion', '');
            setValue('singleselect', '');
            setValue('multiselect', '');
            setIsSubmitting(false);

            if (response.data.url) {
                window.location.href = response.data.url;
            } else {
                setQuestionData([response.data]);

                if (response.data.states.length > 0) {
                    response.data.states.map((e) => {
                        setStateList((val) => [
                            ...val,
                            { label: e.state_name, value: e.state_name },
                        ]);
                    });
                }
            }
            setLoder(true);
        } catch (err) {
            setShowError(true);
            setLoder(true);
        }
        setLoder(true);
    };

    return (
        <>
            <div className="header">
                <Navbar
                    className="color-nav fixed"
                    inverse={'true'}
                    collapseOnSelect
                >
                    <div>
                        <img
                            className="header_samplicity_logo"
                            src={brandlogo}
                            alt="Samplicity"
                        />
                    </div>
                </Navbar>
            </div>

            <div className="top_container">
                <div
                    className="main_container "
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    {/* <div className="user_serachbox">
                        <div className="headerQuestion">
                            <h3 className="tableHeading">
                                <img
                                    src={userIco}
                                    className="headingIcons"
                                    alt="Add"
                                />{' '}
                                Redherring
                            </h3>
                        </div>
                    </div>
                    <hr /> */}
                    &nbsp; &nbsp; &nbsp;
                    {loader ? (
                        <>
                            {' '}
                            {questionData.length == 0 ? (
                                <></>
                            ) : (
                                <>
                                    <div
                                        className="mainContent"
                                        style={{ height: '300px' }}
                                    >
                                        {questionData[0].question_type ==
                                        'N' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row id="QuestioName">
                                                    <Col
                                                        lg={3}
                                                        md={3}
                                                        sm={1}
                                                        xs={12}
                                                        className="col"
                                                    ></Col>
                                                    <Col
                                                        lg={3}
                                                        md={3}
                                                        sm={5}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            First Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="First Name"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'FirstName',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z]{3,}$/,
                                                                                      message:
                                                                                          'Only letters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'FirstName',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z]{3,}$/,
                                                                                      message:
                                                                                          'Only letters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.FirstName && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .FirstName
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={3}
                                                        md={3}
                                                        sm={5}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Last Name*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Last Name"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'LastName',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z]{3,}$/,
                                                                                      message:
                                                                                          'Only letters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'LastName',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z]{3,}$/,
                                                                                      message:
                                                                                          'Only letters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.LastName && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .LastName
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={3}
                                                        md={3}
                                                        sm={1}
                                                        xs={12}
                                                        className="col"
                                                    ></Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'A' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row id="QuestioName">
                                                    <Col
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Address 1*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="address1"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'address1',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[0-9a-zA-Z\s,_.'-]+$/,
                                                                                      message:
                                                                                          'Invalid address format',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'address1',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[0-9a-zA-Z\s,_.'-]+$/,
                                                                                      message:
                                                                                          'Invalid address format',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.address1 && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .address1
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={6}
                                                        md={6}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Address 2*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Address 2"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'address2',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[0-9a-zA-Z\s,_.'-]+$/,
                                                                                      message:
                                                                                          'Invalid address format',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'address2',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[0-9a-zA-Z\s,_.'-]+$/,
                                                                                      message:
                                                                                          'Invalid address format',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.address2 && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .address2
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row className="makesomePadding">
                                                    <Col
                                                        lg={4}
                                                        md={4}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            City*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="City"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'city',
                                                                          {
                                                                              required:
                                                                                  'This field is required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z\s'-]+$/,
                                                                                      message:
                                                                                          'Invalid city name format',
                                                                                  },
                                                                              minLength:
                                                                                  {
                                                                                      value: 2,
                                                                                      message:
                                                                                          'City name must be at least 2 characters long',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'city',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z\s'-]+$/,
                                                                                      message:
                                                                                          'Invalid city name format',
                                                                                  },
                                                                              minLength:
                                                                                  {
                                                                                      value: 2,
                                                                                      message:
                                                                                          'City name must be at least 2 characters long',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />

                                                        {errors.city && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors.city
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={4}
                                                        md={4}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Zip Code*
                                                        </label>
                                                        <input
                                                            type="text"
                                                            placeholder="Zip Code"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'zipcode',
                                                                          {
                                                                              // required: "This field is Required",
                                                                              // pattern: {
                                                                              //   value: /^\d{0,0}(?:-\d{4})?$/,
                                                                              //   message:
                                                                              //     "Invalid zip code format (2 to 5 digits allowed)",
                                                                              // },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'zipcode',
                                                                          {
                                                                              // pattern: {
                                                                              //   value: /^\d{0,9}(?:-\d{4})?$/,
                                                                              //   message:
                                                                              //     "Invalid zip code format (2 to 5 digits allowed)",
                                                                              // },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.zipcode && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .zipcode
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={4}
                                                        md={4}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            State*
                                                        </label>

                                                        {stateList.length >
                                                        0 ? (
                                                            <>
                                                                {' '}
                                                                <Select
                                                                    {...(questionData[0]
                                                                        .mandatory ==
                                                                    true
                                                                        ? {
                                                                              ...register(
                                                                                  'state',
                                                                                  {
                                                                                      required:
                                                                                          'This field is Required',
                                                                                  }
                                                                              ),
                                                                          }
                                                                        : {
                                                                              ...register(
                                                                                  'state',
                                                                                  {}
                                                                              ),
                                                                          })}
                                                                    placeholder="Select State"
                                                                    className="pjt_select formtext"
                                                                    classNamePrefix="select"
                                                                    options={
                                                                        stateList
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setValue(
                                                                            'state',
                                                                            e.value
                                                                        );
                                                                        trigger();
                                                                    }}
                                                                    noOptionsMessage={() =>
                                                                        'No Data Found'
                                                                    }
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {' '}
                                                                <input
                                                                    type="text"
                                                                    placeholder="State"
                                                                    className="formtext"
                                                                    {...(questionData[0]
                                                                        .mandatory ==
                                                                    true
                                                                        ? {
                                                                              ...register(
                                                                                  'state',
                                                                                  {
                                                                                      required:
                                                                                          'This field is Required',
                                                                                  }
                                                                              ),
                                                                          }
                                                                        : {
                                                                              ...register(
                                                                                  'state',
                                                                                  {}
                                                                              ),
                                                                          })}
                                                                />
                                                            </>
                                                        )}

                                                        {errors.state && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors.state
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            // disabled={isSubmitting}
                                                            className="respondentsave"
                                                            type="submit"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'B' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row id="QuestioName">
                                                    <Col
                                                        lg={3}
                                                        md={1}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    ></Col>
                                                    <Col
                                                        lg={2}
                                                        md={3}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Day*
                                                        </label>

                                                        <Select
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'day',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'day',
                                                                          {}
                                                                      ),
                                                                  })}
                                                            placeholder="Select Day"
                                                            className="pjt_select formtext"
                                                            classNamePrefix="select"
                                                            options={day}
                                                            onChange={(e) => {
                                                                setValue(
                                                                    'day',
                                                                    e.value
                                                                );
                                                                trigger();
                                                            }}
                                                            noOptionsMessage={() =>
                                                                'No Data Found'
                                                            }
                                                        />
                                                        {errors.day && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors.day
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={2}
                                                        md={3}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Months*
                                                        </label>

                                                        <Select
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'months',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'months',
                                                                          {}
                                                                      ),
                                                                  })}
                                                            placeholder="Select Months"
                                                            className="pjt_select formtext"
                                                            classNamePrefix="select"
                                                            options={month}
                                                            onChange={(e) => {
                                                                setValue(
                                                                    'months',
                                                                    e.value
                                                                );
                                                                trigger();
                                                            }}
                                                            noOptionsMessage={() =>
                                                                'No Data Found'
                                                            }
                                                        />

                                                        {errors.months && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .months
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={2}
                                                        md={3}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    >
                                                        <label className="formlabel">
                                                            Year*
                                                        </label>

                                                        <Select
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'year',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'year',
                                                                          {}
                                                                      ),
                                                                  })}
                                                            placeholder="Select Year"
                                                            className="pjt_select formtext"
                                                            classNamePrefix="select"
                                                            options={year}
                                                            onChange={(e) => {
                                                                setValue(
                                                                    'year',
                                                                    e.value
                                                                );
                                                                trigger();
                                                            }}
                                                            noOptionsMessage={() =>
                                                                'No Data Found'
                                                            }
                                                        />
                                                        {errors.year && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors.year
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                    <Col
                                                        lg={3}
                                                        md={1}
                                                        sm={12}
                                                        xs={12}
                                                        className="col"
                                                    ></Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'P' ? (
                                            <>
                                                <form
                                                    onSubmit={handleSubmit(
                                                        handleSaveButton
                                                    )}
                                                >
                                                    <h5
                                                        id="QuestioName"
                                                        className="questionPosition"
                                                    >
                                                        {
                                                            questionData[0]
                                                                .question_title
                                                        }
                                                    </h5>
                                                    <Row>
                                                        <Col
                                                            md={{
                                                                span: 4,
                                                                offset: 4,
                                                            }}
                                                            sm={{
                                                                span: 8,
                                                                offset: 2,
                                                            }}
                                                            xs={12}
                                                            className="text-center d-flex justify-content-center"
                                                            style={{
                                                                flexDirection:
                                                                    'column',
                                                            }}
                                                        >
                                                            <input
                                                                type="text"
                                                                placeholder="Phone Number"
                                                                className="formtext"
                                                                {...(questionData[0]
                                                                    .mandatory ==
                                                                true
                                                                    ? {
                                                                          ...register(
                                                                              'phoneNumber',
                                                                              {
                                                                                  required:
                                                                                      'This field is Required',
                                                                                  pattern:
                                                                                      {
                                                                                          value: /^\d{10,}$/,
                                                                                          message:
                                                                                              'Invalid phone number format',
                                                                                      },
                                                                              }
                                                                          ),
                                                                      }
                                                                    : {
                                                                          ...register(
                                                                              'phoneNumber',
                                                                              {
                                                                                  pattern:
                                                                                      {
                                                                                          value: /^\d{10,}$/,
                                                                                          message:
                                                                                              'Invalid phone number format',
                                                                                      },
                                                                              }
                                                                          ),
                                                                      })}
                                                            />
                                                            {errors.phoneNumber && (
                                                                <small className="errmsg">
                                                                    {
                                                                        errors
                                                                            .phoneNumber
                                                                            .message
                                                                    }
                                                                </small>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <div className="parent-container toppadding">
                                                        <div className="">
                                                            <button
                                                                type="submit"
                                                                className="respondentsave"
                                                            >
                                                                {isSubmitting && (
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                )}
                                                                &nbsp; Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'E' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row>
                                                    <Col
                                                        md={{
                                                            span: 4,
                                                            offset: 4,
                                                        }}
                                                        sm={{
                                                            span: 8,
                                                            offset: 2,
                                                        }}
                                                        xs={12}
                                                        className="text-center d-flex justify-content-center"
                                                        style={{
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <input
                                                            type="text"
                                                            placeholder="Email"
                                                            className="formtext"
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'email',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                                                      message:
                                                                                          'Only letters and Special Characters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'email',
                                                                          {
                                                                              pattern:
                                                                                  {
                                                                                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                                                      message:
                                                                                          'Only letters and Special Characters are allowed',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  })}
                                                        />
                                                        {errors.email && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors.email
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'O' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row>
                                                    <Col
                                                        md={{
                                                            span: 4,
                                                            offset: 4,
                                                        }}
                                                        sm={{
                                                            span: 8,
                                                            offset: 2,
                                                        }}
                                                        xs={12}
                                                        className="text-center d-flex justify-content-center"
                                                        style={{
                                                            flexDirection:
                                                                'column',
                                                        }}
                                                    >
                                                        <textarea
                                                            placeholder="Enter your text here..."
                                                            {...(questionData[0]
                                                                .mandatory ==
                                                            true
                                                                ? {
                                                                      ...register(
                                                                          'Suggestion',
                                                                          {
                                                                              required:
                                                                                  'This field is Required',
                                                                              minLength:
                                                                                  {
                                                                                      value: 15,
                                                                                      message:
                                                                                          'Please enter at least 15 characters.',
                                                                                  },
                                                                          }
                                                                      ),
                                                                  }
                                                                : {
                                                                      ...register(
                                                                          'Suggestion',
                                                                          {}
                                                                      ),
                                                                  })}
                                                            rows="7"
                                                            cols="120"
                                                        ></textarea>
                                                        {errors.Suggestion && (
                                                            <small className="errmsg">
                                                                {
                                                                    errors
                                                                        .Suggestion
                                                                        .message
                                                                }
                                                            </small>
                                                        )}
                                                    </Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            // disabled={isSubmitting}
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'I' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <Row>
                                                    <Col
                                                        md={{
                                                            span: 4,
                                                            offset: 4,
                                                        }}
                                                        sm={{
                                                            span: 8,
                                                            offset: 2,
                                                        }}
                                                        xs={12}
                                                        className="text-center d-flex justify-content-center"
                                                    >
                                                        <textarea
                                                            placeholder="Enter your text here..."
                                                            register=""
                                                            rows="7"
                                                            cols="120"
                                                            defaultValue={
                                                                questionData[0]
                                                                    .question_title
                                                            }
                                                            readOnly
                                                        ></textarea>
                                                    </Col>
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            // disabled={isSubmitting}
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                        {questionData[0].question_type ==
                                        'S' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row>
                                                    {questionData[0].answer_options.map(
                                                        (prop) => (
                                                            <>
                                                                <Col
                                                                    md={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    sm={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    xs={12}
                                                                    className="text-center d-flex "
                                                                >
                                                                    <input
                                                                        className="pointer"
                                                                        type="radio"
                                                                        id={`radio-${prop.answer_id}`}
                                                                        name="fav_language"
                                                                        value={
                                                                            prop.answer_id
                                                                        }
                                                                        {...(questionData[0]
                                                                            .mandatory
                                                                            ? register(
                                                                                  'singleselect',
                                                                                  {
                                                                                      required:
                                                                                          'This field is Required',
                                                                                  }
                                                                              )
                                                                            : register(
                                                                                  'singleselect',
                                                                                  {}
                                                                              ))}
                                                                    />
                                                                    <div className="gaprespondan"></div>
                                                                    <label
                                                                        className="pointer"
                                                                        htmlFor={`radio-${prop.answer_id}`}
                                                                    >
                                                                        {
                                                                            prop.answer_title
                                                                        }
                                                                    </label>
                                                                </Col>
                                                            </>
                                                        )
                                                    )}
                                                    {errors.singleselect && (
                                                        <small className="errmsg">
                                                            {
                                                                errors
                                                                    .singleselect
                                                                    .message
                                                            }
                                                        </small>
                                                    )}
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            // disabled={isSubmitting}
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}

                                        {questionData[0].question_type ==
                                        'M' ? (
                                            <form
                                                onSubmit={handleSubmit(
                                                    handleSaveButton
                                                )}
                                            >
                                                <h5
                                                    id="QuestioName"
                                                    className="questionPosition"
                                                >
                                                    {
                                                        questionData[0]
                                                            .question_title
                                                    }
                                                </h5>
                                                <Row>
                                                    {/* {questionData[0].answer_options.map(
                                                        (prop) => (
                                                            <>
                                                                <Col
                                                                    md={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    sm={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    xs={12}
                                                                    className="text-center d-flex "
                                                                >
                                                                    <input
                                                                        className="pointer"
                                                                        type="checkbox"
                                                                        value={
                                                                            prop.answer_id
                                                                        }
                                                                        id={`checkbox-${prop.value}`}
                                                                        {...(questionData[0]
                                                                            .mandatory
                                                                            ? register(
                                                                                  'multiselect',
                                                                                  {
                                                                                      required:
                                                                                          'This field is Required',
                                                                                  }
                                                                              )
                                                                            : register(
                                                                                  'multiselect',
                                                                                  {}
                                                                              ))}
                                                                    />
                                                                    <div className="gaprespondan"></div>
                                                                    <label
                                                                        className="pointer"
                                                                        htmlFor={`checkbox-${prop.value}`}
                                                                    >
                                                                        {
                                                                            prop.answer_title
                                                                        }
                                                                    </label>
                                                                </Col>
                                                            </>
                                                        )
                                                    )} */}
                                                    {questionData[0].answer_options.map(
                                                        (
                                                            {
                                                                answer_id,
                                                                index,
                                                                answer_title,
                                                            },
                                                            idx
                                                        ) => {
                                                            const uniqueId = `checkbox-${index}-${idx}`; // Ensure a unique id for each input

                                                            return (
                                                                <Col
                                                                    key={idx}
                                                                    md={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    sm={{
                                                                        span: 5,
                                                                        offset: 5,
                                                                    }}
                                                                    xs={12}
                                                                    className="text-center d-flex"
                                                                >
                                                                    <input
                                                                        className="pointer"
                                                                        type="checkbox"
                                                                        value={
                                                                            answer_id
                                                                        }
                                                                        id={
                                                                            uniqueId
                                                                        }
                                                                        {...register(
                                                                            'multiselect',
                                                                            {
                                                                                required:
                                                                                    questionData[0]
                                                                                        .mandatory
                                                                                        ? 'This field is Required'
                                                                                        : false,
                                                                            }
                                                                        )}
                                                                    />
                                                                    <div className="gaprespondan"></div>
                                                                    <label
                                                                        className="pointer"
                                                                        htmlFor={
                                                                            uniqueId
                                                                        }
                                                                    >
                                                                        {
                                                                            answer_title
                                                                        }
                                                                    </label>
                                                                </Col>
                                                            );
                                                        }
                                                    )}
                                                    {errors.multiselect && (
                                                        <small className="errmsg">
                                                            {
                                                                errors
                                                                    .multiselect
                                                                    .message
                                                            }
                                                        </small>
                                                    )}
                                                </Row>
                                                <div className="parent-container toppadding">
                                                    <div className="">
                                                        <button
                                                            // disabled={isSubmitting}
                                                            type="submit"
                                                            className="respondentsave"
                                                        >
                                                            {isSubmitting && (
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            &nbsp; Next
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <SpinnerLoader />
                        </>
                    )}
                </div>
            </div>

            <CommonFooter />
        </>
    );
};

export default RedHearing;
