import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SpinnerLoader from "../../UI/Spinner";

function ClientLinkModal(props) {
  const id = props.clientId;
  const axiosPrivate = useAxiosPrivate();
  const [allLink, setAllLink] = useState([]);
  const [loder, setLoder] = useState(false);

  useEffect(() => {
    if (id == undefined) {
    } else getClientLink();
  }, [id]);

  const getClientLink = async () => {
    setLoder(false);
    try {
      const response = await axiosPrivate.get(
        `survey/survey-client-link/${id}`
      );
      setAllLink(response.data);
    } catch (err) {}
    setLoder(true);
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="fs16">
          Client Link
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="linksWrapper">
          {loder ? (
            <>
              {allLink.length == 0 ? (
                <>
                  {" "}
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{ textAlign: "center" }}
                  >
                    No data Found!
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  {allLink.map((items) => (
                    <>
                      <Row className="flex items-center">
                        <Col
                          lg={2}
                          md={4}
                          sm={12}
                          xs={12}
                          className="flex flex-direction"
                        >
                          <label>Client Link</label>
                        </Col>

                        <Col lg={9} md={8} sm={12} xs={12}>
                          <span>{items.link}</span>
                        </Col>
                      </Row>
                    </>
                  ))}
                </>
              )}
            </>
          ) : (
            <>
              <SpinnerLoader />
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          className="supply_setupbtn btn-bg-grey m-0 btn btn-primary"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ClientLinkModal;
