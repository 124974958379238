import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { TableCell, TableRow, TableBody } from "@mui/material";

const Reconcilitionlogs = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "14px", paddingLeft: "10px" }}
          >
            <i className="bi bi-person-plus"></i>
            Reconciliation Log 20010001US
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Row> */}
          {/* <Col lg={1} md={7} sm={12} xs={12}> */}
          <Table>
            <thead
              className="thead"
              style={{
                fontSize: "13px",
              }}
            >
              <tr>
                <td>SC ID</td>
                <td>Project Name</td>
              </tr>
            </thead>
            <tbody
              style={{
                fontSize: "13px",
              }}
            >
              <tr>
                <td>20010001US</td>
                <td>ORD-465481-L9D7 - Insurance Broker</td>
              </tr>
            </tbody>
          </Table>
          <p
            style={{
              fontSize: "11px",
            }}
          >
            Log(s) not created for this Project. Please contact IT team
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Reconcilitionlogs;
