import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState, useRef, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import '../PopUp/SetupSupplier.css';
import { useForm } from 'react-hook-form';
import SpinnerLoader from '../../../UI/Spinner';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { event } from 'jquery';
import { combineReducers } from 'redux';

const Terminationbuildurledit = (props, setTerminationlinkedit) => {
    const inputRef = useRef(null);

    const axiosPrivate = useAxiosPrivate();
    const [loader, setLoader] = useState(true);
    const [checkQmark, setCheckQmark] = useState(true);
    const [completeUrl, setCompleteUrl] = useState(props.baseUrl);
    const [baseUrl, setBaseUrl] = useState(props.baseUrl);
    const [combinedIds, setCombinedIds] = useState([]);
    const [tid, setTid] = useState([]);
    const [pid, setPid] = useState([]);
    const [customVar, setCustomVar] = useState([]);
    const [checkedIds, setCheckedIds] = useState([]);
    const [fixedIds, setFixedIds] = useState(['[%pid%]', '[%tid%]']);
    const [ridStat, setRidStat] = useState(false);
    const [flag, setFlag] = useState(false);
    const [isBaseUrl, setIsBaseUrl] = useState(true);

    const [selectedIds, setSelectedIds] = useState([]);

    const multiselectValues = [
        {
            name: 'age',
            value: '[%age%]',
            label: 'Age',
        },
        {
            name: 'gender',
            value: '[%gender%]',
            label: 'Gender',
        },
    ];

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        setValue,
    } = useForm();

    useEffect(() => {
        get_Supplierlist_api();

        if (
            baseUrl &&
            baseUrl != null &&
            baseUrl != 'null' &&
            baseUrl != undefined
        ) {
            const checkq = baseUrl.includes('?');
            setIsBaseUrl(true);
            if (!checkQmark) {
                setCompleteUrl(baseUrl + '/[%tid%]');
            } else {
                setCompleteUrl(baseUrl + '?rid=[%tid%]');
            }
        } else {
            setIsBaseUrl(false);
        }

        setCompleteUrl(baseUrl);
        setRidStat(true);
    }, []);
    const get_Supplierlist_api = async () => {
        try {
            const response = await axiosPrivate.get(
                `survey-supplier/exceptional-variable?survey_supplier_id=${props.supplierId}`
            );

            setTid([]);
            setTid((prevData) => [
                ...prevData,
                {
                    name: response.data.variable.tid.name,
                    value: response.data.variable.tid.format,
                    label: response.data.variable.tid.format,
                },
            ]);
            setCombinedIds((prevCombinedId) => {
                return [
                    ...prevCombinedId,
                    {
                        formate: response.data.variable.tid.name,
                        value: response.data.variable.tid.format,
                    },
                ];
            });
            setCustomVar([]);
            response.data.variable.custom.map((ite) => {
                setCustomVar((val) => [
                    ...val,
                    {
                        name: ite.name,
                        value: ite.name,
                        label: ite.name,
                    },
                ]);
            });

            setPid([]);
            if (response.data.variable.pid) {
                setPid((prevData) => [
                    ...prevData,
                    {
                        name: response.data.variable.pid.name,
                        value: response.data.variable.pid.format,
                        label: response.data.variable.pid.name,
                    },
                ]);
            }

            if (!checkQmark) {
                document.querySelectorAll('.vtttinput').forEach(function (el) {
                    el.style.visibility = 'hidden';
                });
            } else {
                document.querySelectorAll('.vtttinput').forEach(function (el) {
                    el.style.visibility = 'visible';
                });
            }
        } catch (err) {
            // console.error(err);
        }
        setLoader(false);

        makeCompleteUrl();
    };

    useEffect(() => {
        makeCompleteUrl();
        if (!checkQmark) {
            document.querySelectorAll('.vtttinput').forEach(function (el) {
                el.style.visibility = 'hidden';
            });
        } else {
            document.querySelectorAll('.vtttinput').forEach(function (el) {
                el.style.visibility = 'visible';
            });
        }
    }, [checkQmark, loader, selectedIds]);

    const handleVarCheckbox = (event, name) => {
        const value = event.target.name;
        const checked = event.target.checked;

        setCombinedIds((prevCombinedId) => {
            let updatedCombinedId = [...prevCombinedId];

            if (checked) {
                // Append the value to the combinedId if it doesn't exist
                const isValueExists = updatedCombinedId.some(
                    (param) => param.value === value
                );
                if (!isValueExists) {
                    const paramToAdd = {
                        // name: `&${name}=[%${value}%]`,
                        value,
                        formate: `${name}`,
                    };
                    updatedCombinedId.push(paramToAdd);
                }
            } else {
                // Remove the value from the combinedId
                updatedCombinedId = updatedCombinedId.filter(
                    (param) => param.value !== value
                );
            }
            setSelectedIds(() => [
                ...combinedIds.map((option) => ({
                    value: option.value,
                })),
            ]);

            return updatedCombinedId;
        });

        makeCompleteUrl();
    };

    const handleVarPlaceholder = (event, value) => {
        const inputValue = event.target.value;
        setCombinedIds((prevCombinedId) => {
            const updatedCombinedId = prevCombinedId.map((option) => {
                if (option.value === value) {
                    const name = inputValue;

                    return {
                        ...option,
                        formate: inputValue,
                    };
                }
                return option;
            });
            return updatedCombinedId;
        });

        setSelectedIds(() => [
            ...combinedIds.map((option) => ({
                value: option.value,
            })),
        ]);

        makeCompleteUrl();
    };

    const handleMultiSelectChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map(
            (option) => ({
                name: option.label,
                value: option.value,
            })
        );

        setCombinedIds((prevCombinedId) => [
            ...prevCombinedId,
            ...selectedOptions.map((option) => ({
                formate: option.name,
                value: option.value,
            })),
        ]);

        setSelectedIds(() => [
            ...selectedOptions.map((option) => ({
                value: option.value,
            })),
        ]);
        makeCompleteUrl();
    };

    const handleUrlInputChange = (event) => {
        setCompleteUrl(event.target.value);
        const inputValue = event.target.value;

        const paramValue = inputValue.split('?');
        const paramValuePairs = paramValue[1].split('&');

        let valarray = [];
        let newIds = [];
        paramValuePairs.forEach((pair, i) => {
            if (pair != '') {
                // Split each pair into parameter and value
                const [param, value] = pair.split('=');
                if (value != undefined || value != 'undefined') {
                    valarray[i] = value;
                }
                const paramToAdd = {
                    value,
                    formate: param,
                };
                newIds.push(paramToAdd);
            }
        });

        setCombinedIds(newIds);
        setCheckedIds(valarray);
        setSelectedIds(() => [
            ...combinedIds.map((option) => ({
                value: option.value,
            })),
        ]);
    };

    const makeCompleteUrl = () => {
        let url = baseUrl;

        const len = combinedIds.length;
        if (checkQmark) {
            if (len > 0) {
                url += '?';
            }
        } else {
            if (len > 0) {
                url = url.trim('/');
                url += '/';
            }
        }

        combinedIds.map((item, index) => {
            if (checkQmark) {
                url += item.formate + '=' + item.value;
            } else {
                url += item.value;
            }

            if (index < len - 1) {
                if (checkQmark) {
                    url += '&';
                } else {
                    url += '/';
                }
            }
        });

        setCompleteUrl(url);
    };

    const handleClose = () => {
        setCombinedIds([]);
        setBaseUrl('');
        props.setTBuildUrledit(false);
        props.setTerminationlinkedit(completeUrl);

        reset();
    };

    const formsubmission = (e) => {
        // props.setQuotafulllink(completeUrl);

        // props.setTermination(completeUrl);
        props.setTermination(`${props.keyValue}`, completeUrl);
        props.setTBuildUrledit(false);

        reset();
    };

    const compUrl = register('compUrl', {
        required: 'This field is Required',
    });

    const setBaseUrlfunction = (val) => {
        if (
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
                val
            )
        ) {
            setBaseUrl(val);

            if (!checkQmark) {
                setCompleteUrl(baseUrl + '/[%tid%]');
            } else {
                setCompleteUrl(baseUrl + '?rid=[%tid%]');
            }
            setIsBaseUrl(true);
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: '16px', paddingLeft: '3px' }}
                >
                    Build Your URL+++ {props.supplierId}
                </Modal.Title>
            </Modal.Header>
            {loader ? (
                <SpinnerLoader />
            ) : (
                <>
                    <form onSubmit={handleSubmit(formsubmission)}>
                        <Modal.Body>
                            <Row>
                                <Col
                                    lg={8}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    <p
                                        style={{
                                            marginLeft: '0px',
                                        }}
                                    >
                                        What's your survey's URL?
                                    </p>
                                </Col>
                                <Col
                                    lg={4}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    <input
                                        type="checkbox"
                                        placeholder=""
                                        defaultChecked={checkQmark}
                                        onChange={(e) => {
                                            setCheckQmark(e.target.checked);
                                            //handleQuestionmark(e);
                                        }}
                                    />
                                    <label
                                        className="formlabelviewzone"
                                        style={{
                                            width: '95%',
                                            paddingLeft: '10px',
                                        }}
                                    >
                                        Check question mark format
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className=""
                                >
                                    {isBaseUrl ? (
                                        <>
                                            <input
                                                type="text"
                                                style={{
                                                    width: '100%',
                                                }}
                                                {...compUrl}
                                                value={completeUrl}
                                                onChange={(e) => {
                                                    compUrl.onChange(e);
                                                    handleUrlInputChange(e);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <small className="">
                                                <font color="red">
                                                    Please enter a base url like
                                                    "https://samplicity.com"
                                                </font>
                                            </small>
                                            <input
                                                type="text"
                                                style={{
                                                    width: '100%',
                                                    borderColor: 'red',
                                                }}
                                                onBlur={(event) => {
                                                    setBaseUrlfunction(
                                                        event.target.value
                                                    );
                                                }}
                                            />
                                        </>
                                    )}
                                </Col>
                            </Row>

                            <Row>
                                <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                >
                                    <h6
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                        }}
                                    >
                                        Variables
                                    </h6>
                                    <p
                                        style={{
                                            margin: '10px 0px 20px 0px',
                                        }}
                                    >
                                        These are URL parameters for respondent
                                        or survey-level data that will be passed
                                        along when a respondent gets sent to
                                        your survey.
                                    </p>
                                </Col>
                                <Col
                                    lg={6}
                                    md={6}
                                    sm={6}
                                    xs={12}
                                    className="col"
                                    style={{
                                        marginTop: '40px',
                                    }}
                                >
                                    {' '}
                                    {isBaseUrl ? (
                                        <>
                                            {customVar.length === 0 ? (
                                                <></>
                                            ) : (
                                                <div className="variable_select">
                                                    <select
                                                        className="variable_select__select"
                                                        onChange={(event) => {
                                                            handleMultiSelectChange(
                                                                event
                                                            );
                                                        }}
                                                        defaultValue=""
                                                    >
                                                        <option
                                                            value=""
                                                            disabled
                                                            hidden
                                                        >
                                                            Select Variable
                                                        </option>
                                                        {/* {customVar.map((option) => ( */}
                                                        {multiselectValues.map(
                                                            (option) => (
                                                                <option
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    disabled={combinedIds.some(
                                                                        (
                                                                            element
                                                                        ) => {
                                                                            return (
                                                                                element.value ===
                                                                                option.value
                                                                            );
                                                                        }
                                                                    )}
                                                                >
                                                                    {
                                                                        option.label
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    className="Quality_Col"
                                    lg={2}
                                    md={4}
                                    sm={4}
                                    xs={6}
                                >
                                    {tid.map((item) => (
                                        <>
                                            <label className="formlabel">
                                                Samplicity Id
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '14px',
                                                    }}
                                                >
                                                    {' '}
                                                    *
                                                </span>
                                            </label>

                                            <div>
                                                <input
                                                    type="checkbox"
                                                    placeholder="RID"
                                                    checked={true}
                                                    name={item.value}
                                                    id={'check_' + item.value}
                                                    className="box_width"
                                                    //   onChange={(event) => {
                                                    //   handleVarCheckbox(event, item.name);
                                                    // }}
                                                    readOnly
                                                />
                                                <input
                                                    className="vtttinput"
                                                    type="text"
                                                    name={'input_' + item.value}
                                                    id={'input_' + item.value}
                                                    defaultValue={item.name}
                                                />
                                            </div>
                                        </>
                                    ))}
                                </Col>
                                {isBaseUrl ? (
                                    <>
                                        {pid.map((item, index) => (
                                            <Col
                                                className="Quality_Col"
                                                lg={2}
                                                md={4}
                                                sm={4}
                                                xs={6}
                                            >
                                                <div key={index} className="">
                                                    <label
                                                        className="formlabel"
                                                        htmlFor={
                                                            'check_' +
                                                            item.value
                                                        }
                                                    >
                                                        {/* {item.label} */}
                                                        Panelist Id
                                                    </label>
                                                    <input
                                                        className=" box_width "
                                                        type="checkbox"
                                                        id={
                                                            'check_' +
                                                            item.value
                                                        }
                                                        name={item.value}
                                                        onChange={(event) => {
                                                            handleVarCheckbox(
                                                                event,
                                                                item.name
                                                            );
                                                        }}
                                                        // checked = {3=== item.value)}
                                                    />
                                                    {/* {combinedIds.some(
                        (param) => param.value === item.value
                      ) && ( */}
                                                    <input
                                                        style={{
                                                            border: '1px solid green',
                                                        }}
                                                        className="vtttinput"
                                                        type="text"
                                                        name={
                                                            'input_' +
                                                            item.value
                                                        }
                                                        id={
                                                            'input_' +
                                                            item.value
                                                        }
                                                        value={
                                                            combinedIds[
                                                                item.value
                                                            ] || item.name
                                                        }
                                                        onChange={(event) => {
                                                            handleVarPlaceholder(
                                                                event,
                                                                item.value
                                                            );
                                                        }}
                                                    />
                                                    {/* )} */}
                                                </div>
                                            </Col>
                                        ))}

                                        {combinedIds.map((option) =>
                                            fixedIds.includes(option.value) ? (
                                                <></>
                                            ) : (
                                                <Col
                                                    className="Quality_Col"
                                                    lg={2}
                                                    md={4}
                                                    sm={4}
                                                    xs={6}
                                                >
                                                    <div key={option.value}>
                                                        <label className="formlabel">
                                                            {multiselectValues.find(
                                                                (obj) =>
                                                                    obj.value ==
                                                                    option.value
                                                            )?.label ||
                                                                option.value}
                                                        </label>
                                                        <input
                                                            type="checkbox"
                                                            className=""
                                                            value={option.value}
                                                            name={option.value}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleVarCheckbox(
                                                                    event
                                                                );
                                                            }}
                                                            checked={selectedIds.some(
                                                                (item) =>
                                                                    item.value ===
                                                                    option.value
                                                            )}
                                                        />

                                                        <input
                                                            style={{
                                                                border: '1px solid red',
                                                            }}
                                                            className="vtttinput"
                                                            type="text"
                                                            // value={option.formate}
                                                            value={
                                                                combinedIds[
                                                                    option.value
                                                                ] ||
                                                                option.formate
                                                            }
                                                            name={option.value}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleVarPlaceholder(
                                                                    event,
                                                                    option.value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            )
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Row>
                            <Row>
                                <Col
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    className="col"
                                    style={{
                                        marginTop: '20px',
                                    }}
                                >
                                    <label className="formlabel">
                                        Completion Link
                                    </label>
                                    <textarea
                                        type="text"
                                        style={{ width: '100%' }}
                                        value={completeUrl}
                                        readOnly={true}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <button
                            className="cbtn saveBtn "
                            style={{
                                marginBottom: '10px',
                                marginRight: '15px',
                            }}
                            type="submit"
                        >
                            submit
                        </button>
                    </form>
                </>
            )}
        </Modal>
    );
};

export default Terminationbuildurledit;
